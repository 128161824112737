import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import {
    getCompanyConfigKaitenAction,
    getCompanyConfigKaitenFailureAction,
    getCompanyConfigKaitenSuccessAction,
    updateCompanyConfigKaitenAction,
    updateCompanyConfigKaitenFailureAction,
    updateCompanyConfigKaitenSuccessAction
} from '@app-client/store/company/actions/company-config-kaiten.action';
import { CompanyConfigService } from '@app-client/store/company/services/company-config.service';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class CompanyConfigKaitenEffect {
    constructor(
        private actions: Actions,
        private companyConfigService: CompanyConfigService,
        private toastr: ToastrService,
        private translate: TranslateService,
    ) {}

    getCompanyConfigKaitenAction$ = createEffect(() => {
        return this.actions.pipe(
            ofType(getCompanyConfigKaitenAction),
            switchMap(() => {
                return this.companyConfigService.getCompanyConfigKaiten().pipe(
                    map((companyConfigKaiten) => {
                        return getCompanyConfigKaitenSuccessAction({ companyConfigKaiten } );
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(getCompanyConfigKaitenFailureAction({ backendErrors }));
                    })
                );
            })
        );
    });

    getCompanyConfigKaitenFailureAction$ = createEffect(() => {
        return this.actions.pipe(
            ofType(getCompanyConfigKaitenFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('COMPANY_SETTINGS.COMPANY.COMPANY_DATA.NOTIFICATIONS.GET_COMPANY_DATA_FAIL.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    updateCompanyConfigKaitenAction$ = createEffect(() => {
        return this.actions.pipe(
            ofType(updateCompanyConfigKaitenAction),
            switchMap(({ companyConfigKaiten }) => {
                return this.companyConfigService.updateCompanyConfigKaiten(companyConfigKaiten).pipe(
                    map(() => {
                        return updateCompanyConfigKaitenSuccessAction();
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(updateCompanyConfigKaitenFailureAction({ backendErrors }));
                    })
                );
            })
        );
    });

    updateCompanyConfigKaitenSuccessAction$ = createEffect(() => {
        return this.actions.pipe(
            ofType(updateCompanyConfigKaitenSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('COMPANY_CONFIG.NOTIFICATIONS.SUCCESS.MESSAGE'),
                    this.translate.instant('COMPANY_CONFIG.NOTIFICATIONS.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    updateCompanyConfigKaitenFailureAction$ = createEffect(() => {
        return this.actions.pipe(
            ofType(updateCompanyConfigKaitenFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('COMPANY_SETTINGS.COMPANY.COMPANY_DATA.NOTIFICATIONS.GET_COMPANY_DATA_FAIL.TITLE'),
                );
            }),
        );
    }, { dispatch: false });
}
