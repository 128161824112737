import { createAction, props } from '@ngrx/store';

import { EffectivityAppListQueryParamsInterface } from '@app-client/effectivity/types/app/effectivity-app-list-query-params.interface';
import { TrackApplicationInterface } from '@app-client/effectivity/types/app/track-application.interface';
import { EffectivityCategoryCreateInterface } from '@app-client/effectivity/types/category/effectivity-category-create.interface';
import { EffectivityCategoryUpdateInterface } from '@app-client/effectivity/types/category/effectivity-category-update-interface';
import { EffectivityCategoryInterface } from '@app-client/effectivity/types/category/effectivity-category.interface';
import { EffectivitySubcategoryCreateInterface } from '@app-client/effectivity/types/subcategory/effectivity-subcategory-create.interface';
import { EffectivitySubcategoryUpdateInterface } from '@app-client/effectivity/types/subcategory/effectivity-subcategory-update-interface';
import { EffectivitySubcategoryInterface } from '@app-client/effectivity/types/subcategory/effectivity-subcategory.interface';
import { ActionTypes } from '@app-client/store/effectivity-categories/action-types';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';


export const getEffectivityCategoryListAction = createAction(
    ActionTypes.GET_CATEGORY_LIST,
);

export const getEffectivityCategoryListSuccessAction = createAction(
    ActionTypes.GET_CATEGORY_LIST_SUCCESS,
    props<{ response: ResponseInterface<Array<EffectivityCategoryInterface>> }>(),
);

export const getEffectivityCategoryListFailureAction = createAction(
    ActionTypes.GET_CATEGORY_LIST_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const createEffectivityCategoryAction = createAction(
    ActionTypes.CREATE_CATEGORY,
    props<{ newEffectivityCategory: EffectivityCategoryCreateInterface }>(),
);

export const createEffectivityCategorySuccessAction = createAction(
    ActionTypes.CREATE_CATEGORY_SUCCESS,
    props<{ newEffectivityCategory: EffectivityCategoryInterface }>(),
);

export const createEffectivityCategoryFailureAction = createAction(
    ActionTypes.CREATE_CATEGORY_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const updateEffectivityCategoryAction = createAction(
    ActionTypes.UPDATE_CATEGORY,
    props<{ id: string, updatedEffectivityCategory: EffectivityCategoryUpdateInterface }>(),
);

export const updateEffectivityCategorySuccessAction = createAction(
    ActionTypes.UPDATE_CATEGORY_SUCCESS,
    props<{ updatedEffectivityCategory: EffectivityCategoryInterface }>(),
);

export const updateEffectivityCategoryFailureAction = createAction(
    ActionTypes.UPDATE_CATEGORY_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const removeEffectivityCategoryAction = createAction(
    ActionTypes.REMOVE_CATEGORY,
    props<{ id: string }>(),
);

export const removeEffectivityCategorySuccessAction = createAction(
    ActionTypes.REMOVE_CATEGORY_SUCCESS,
    props<{ removedEffectivityCategoryId: string }>(),
);

export const removeEffectivityCategoryFailureAction = createAction(
    ActionTypes.REMOVE_CATEGORY_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const createEffectivitySubcategoryAction = createAction(
    ActionTypes.CREATE_SUBCATEGORY,
    props<{ createSubcategory: EffectivitySubcategoryCreateInterface }>(),
);

export const createEffectivitySubcategorySuccessAction = createAction(
    ActionTypes.CREATE_SUBCATEGORY_SUCCESS,
    props<{ categoryId: string, createdSubcategory: EffectivitySubcategoryInterface }>(),
);

export const createEffectivitySubcategoryFailureAction = createAction(
    ActionTypes.CREATE_SUBCATEGORY_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const updateEffectivitySubcategoryAction = createAction(
    ActionTypes.UPDATE_SUBCATEGORY,
    props<{ updateSubcategoryId: string, updateSubcategory: EffectivitySubcategoryUpdateInterface }>(),
);

export const updateEffectivitySubcategorySuccessAction = createAction(
    ActionTypes.UPDATE_SUBCATEGORY_SUCCESS,
    props<{ newCategoryId: string, updatedSubcategory: EffectivitySubcategoryInterface }>(),
);

export const updateEffectivitySubcategoryFailureAction = createAction(
    ActionTypes.UPDATE_SUBCATEGORY_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const removeEffectivitySubcategoryAction = createAction(
    ActionTypes.REMOVE_SUBCATEGORY,
    props<{ removeSubcategoryId: string }>(),
);

export const removeEffectivitySubcategorySuccessAction = createAction(
    ActionTypes.REMOVE_SUBCATEGORY_SUCCESS,
    props<{ removedSubcategoryId: string }>(),
);

export const removeEffectivitySubcategoryFailureAction = createAction(
    ActionTypes.REMOVE_SUBCATEGORY_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const setEffectivityAppQueryParamsAction = createAction(
    ActionTypes.SET_APP_LIST_QUERY_PARAMS,
    props<{ params: EffectivityAppListQueryParamsInterface }>(),
);

export const getEffectivityAppListAction = createAction(
    ActionTypes.GET_APP_LIST,
);

export const getEffectivityAppListSuccessAction = createAction(
    ActionTypes.GET_APP_LIST_SUCCESS,
    props<{ response: ResponseInterface<Array<TrackApplicationInterface>> }>(),
);

export const getEffectivityAppListFailureAction = createAction(
    ActionTypes.GET_APP_LIST_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const batchUpdateAppSubcategoryAction = createAction(
    ActionTypes.BATCH_UPDATE_APP_SUBCATEGORY,
    props<{ apps: Array<number>, subcategoryId: string | null }>(),
);

export const batchUpdateAppSubcategorySuccessAction = createAction(
    ActionTypes.BATCH_UPDATE_APP_SUBCATEGORY_SUCCESS,
    props<{ apps: Array<number>, subcategoryId: string | null }>(),
);

export const batchUpdateAppSubcategoryFailureAction = createAction(
    ActionTypes.BATCH_UPDATE_APP_SUBCATEGORY_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
