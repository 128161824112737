import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { AccessRequestService } from '@app-client/auth/services/access-request.service';
import {
    accessRequestSend,
    accessRequestSendFailure,
    accessRequestSendSuccess,
} from '@app-client/store/auth/actions/access-request.action';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class AccessRequestEffect {
    accessRequestSend$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(accessRequestSend),
            switchMap(({ accessRequestData }) => {
                return this.accessRequestService.accessRequestSend(accessRequestData).pipe(
                    map(() => {
                        return accessRequestSendSuccess();
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(accessRequestSendFailure({ backendErrors }));
                    }),
                );
            }),
        );
    });

    accessRequestFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(accessRequestSendFailure),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('LOGIN.ACCESS_REQUEST_FORM.NOTIFICATIONS.REQUEST_SEND.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    constructor(
        private actions$: Actions,
        private accessRequestService: AccessRequestService,
        private toastr: ToastrService,
        private translate: TranslateService,
    ) {}
}
