import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ProjectPositionsStateInterface } from '@app-client/store/project-posiitons/project-positions-state.interface';


export const projectPositionFeatureSelector = createFeatureSelector<ProjectPositionsStateInterface>('projectPositions');

export const projectPositionListSelector = createSelector(
    projectPositionFeatureSelector,
    state => state.projectPositionList,
);

export const isProjectPositionListLoadingSelector = createSelector(
    projectPositionFeatureSelector,
    state => state.isProjectPositionListLoading,
);

export const hasMoreProjectPositionSelector = createSelector(
    projectPositionFeatureSelector,
    state => state.hasLoadingMore,
);

export const projectPositionListQueryParamsSelector = createSelector(
    projectPositionFeatureSelector,
    state => state.listQueryParams,
);

export const isCreateProjectPositionInProgressSelector = createSelector(
    projectPositionFeatureSelector,
    state => state.isCreateProjectPositionInProgress,
);

export const createProjectPositionBackendErrorsSelector = createSelector(
    projectPositionFeatureSelector,
    state => state.createProjectPositionBackendErrors,
);

export const isUpdateProjectPositionInProgressSelector = createSelector(
    projectPositionFeatureSelector,
    state => state.isUpdateProjectPositionInProgress,
);

export const updateProjectPositionBackendErrorsSelector = createSelector(
    projectPositionFeatureSelector,
    state => state.updateProjectPositionBackendErrors,
);

export const isRemoveProjectPositionInProgress = createSelector(
    projectPositionFeatureSelector,
    state => state.isRemoveProjectPositionInProgress,
);
