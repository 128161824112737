import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NewProjectSpaceInterface } from '@app-client/project-space/types/new-project-space.interface';
import { ProjectSpaceInterface } from '@app-client/project-space/types/project-space.interface';
import { ProjectSpacesQueryConfigInterface } from '@app-client/project-space/types/project-spaces-query-config.interface';
import { PageResponseInterface } from '@app-shared/types/page-response.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';

import { environment } from '@env-client/environment';


@Injectable()
export class ProjectSpacesService {
    constructor(private httpClient: HttpClient) {}

    getProjectSpaces(config: ProjectSpacesQueryConfigInterface)
        : Observable<ResponseInterface<PageResponseInterface<ProjectSpaceInterface>>>
    {
        let params = new HttpParams()
            .set('orderBy', config.orderBy)
            .set('orderDirection', config.orderDirection)
            .set('page', config.page.toString())
            .set('limit', (config.limit || 10).toString());
        if ((config.search || '').trim()) {
            params = params.set('search', (config.search || '').trim());
        }
        if (config.archived !== undefined) {
            params = params.set('archived', config.archived.toString());
        }
        if (config.whereImExists !== undefined) {
            params = params.set('whereImExists', config.whereImExists ? '1' : '0');
        }
        const url = environment.apiBaseUrl + '/project-spaces';
        return this.httpClient.get<ResponseInterface<PageResponseInterface<ProjectSpaceInterface>>>(url, { params });
    }

    getArchivedProjectSpaces(config: ProjectSpacesQueryConfigInterface): Observable<PageResponseInterface<ProjectSpaceInterface>> {
        let params = new HttpParams()
            .set('orderBy', config.orderBy)
            .set('orderDirection', config.orderDirection)
            .set('page', config.page.toString())
            .set('limit', (config.limit || 10).toString())
            .set('archived', '2');
        if ((config.search || '').trim()) {
            params = params.set('search',  (config.search || '').trim());
        }
        const url = environment.apiBaseUrl + '/project-spaces';
        return this.httpClient.get<ResponseInterface<PageResponseInterface<ProjectSpaceInterface>>>(url, { params }).pipe(
            map(response => response.body),
        );
    }

    getProjectSpace(id: string): Observable<ResponseInterface<ProjectSpaceInterface>> {
        const url = environment.apiBaseUrl + '/project-spaces/' + id;
        return this.httpClient.get<ResponseInterface<ProjectSpaceInterface>>(url);
    }

    restoreProjectSpace(id: string): Observable<ResponseInterface<ProjectSpaceInterface>> {
        const url = environment.apiBaseUrl + '/project-spaces/' + id + '/restore';
        return this.httpClient.patch<ResponseInterface<ProjectSpaceInterface>>(url, {});
    }

    createProjectSpace(newProjectSpace: NewProjectSpaceInterface): Observable<ResponseInterface<ProjectSpaceInterface>> {
        const url = environment.apiBaseUrl + '/project-spaces';
        return this.httpClient.post<ResponseInterface<ProjectSpaceInterface>>(url, newProjectSpace);
    }

    archiveProjectSpace(id: string): Observable<ResponseInterface<any>> {
        const url = environment.apiBaseUrl + '/project-spaces/' + id;
        return this.httpClient.delete<ResponseInterface<any>>(url);
    }

    updateProjectSpace(id: string, newProjectSpace: NewProjectSpaceInterface): Observable<ResponseInterface<ProjectSpaceInterface>> {
        const url = environment.apiBaseUrl + '/project-spaces/' + id;
        return this.httpClient.patch<ResponseInterface<ProjectSpaceInterface>>(url, newProjectSpace);
    }
}
