import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { clearForgotPasswordRequestAction, forgotPasswordRequestAction } from '@app-client/store/auth/actions/forgot-password.action';
import {
    forgotPasswordBackendErrorsSelector,
    isForgotPasswordRequestInProgressSelector,
    isForgotPasswordRequestSuccessSelector,
} from '@app-client/store/auth/selectors/auth.selectors';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';
import { emailValidator } from '@app-shared/validators/email.validator';


@UntilDestroy()
@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
    changePasswordRequestInProgress$: Observable<boolean>;
    isForgotPasswordRequestSuccess$: Observable<boolean>;
    backendErrors$: Observable<BackendErrorsInterface>;
    form: FormGroup;

    constructor(
        private store: Store,
        private fb: FormBuilder,
    ) { }

    get email() { return this.form.get('email'); }

    ngOnInit(): void {
        this.initializeForm();
        this.initializeValues();
        this.initializeBackendErrorsWatcher();
    }

    initializeForm(): void {
        this.form = this.fb.group({
            email: ['', [Validators.required, emailValidator]],
        });
    }

    initializeValues(): void {
        this.changePasswordRequestInProgress$ = this.store.select(isForgotPasswordRequestInProgressSelector).pipe(
            untilDestroyed(this),
            tap((inProgress) => {
                if (inProgress) {
                    this.form.disable();
                } else {
                    this.form.enable();
                }
            }),
        );
        this.isForgotPasswordRequestSuccess$ = this.store.select(isForgotPasswordRequestSuccessSelector);
        this.backendErrors$ = this.store.select(forgotPasswordBackendErrorsSelector);
    }

    submit(): void {
        if (this.form.invalid) {
            return;
        }

        this.store.dispatch(forgotPasswordRequestAction({ email: this.email.value }));
    }

    ngOnDestroy(): void {
        this.store.dispatch(clearForgotPasswordRequestAction());
    }

    private initializeBackendErrorsWatcher(): void {
        this.store.select(forgotPasswordBackendErrorsSelector).pipe(
            untilDestroyed(this),
        ).subscribe(backendErrors => {
            if (!backendErrors || !Object.keys(backendErrors).length) {
                return;
            }

            for (const key of Object.keys(backendErrors)) {
                const control = this.form.get(key);

                if (!control) {
                    continue;
                }

                if (backendErrors[key]) {
                    control.setErrors({
                        ...control.errors,
                        backendErrors: backendErrors[key],
                    });
                }

                if (control.errors && !Object.keys(control.errors)) {
                    control.setErrors(null);
                }
            }
        });
    }
}
