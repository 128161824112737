import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { ProjectSpacesQueryConfigInterface } from '@app-client/project-space/types/project-spaces-query-config.interface';
import { AppStateInterface } from '@app-client/shared/types/app-state.interface';
import {
    archiveProjectSpaceAction,
    archiveProjectSpaceFailureAction,
    archiveProjectSpaceSuccessAction,
    createProjectSpaceAction,
    createProjectSpaceFailureAction,
    createProjectSpaceSuccessAction,
    getArchivedProjectSpaceAction,
    getArchivedProjectSpaceFailureAction,
    getArchivedProjectSpacesAction,
    getArchivedProjectSpacesFailureAction,
    getArchivedProjectSpacesSuccessAction,
    getArchivedProjectSpaceSuccessAction,
    getProjectSpaceAction,
    getProjectSpaceFailureAction,
    getProjectSpacesAction,
    getProjectSpacesFailureAction,
    getProjectSpacesSuccessAction,
    getProjectSpaceSuccessAction,
    restoreArchivedProjectSpaceAction,
    restoreArchivedProjectSpaceFailureAction,
    restoreArchivedProjectSpaceSuccessAction,
    setArchivedProjectSpacesQueryConfigAction,
    setProjectSpacesQueryConfigAction,
    updateProjectSpaceAction,
    updateProjectSpaceFailureAction,
    updateProjectSpaceSuccessAction,
} from '@app-client/store/project-spaces/actions/project-spaces.action';
import {
    archivedProjectSpacesQueryConfigSelector,
    projectSpacesQueryConfigSelector,
} from '@app-client/store/project-spaces/selectors/project-spaces.selector';
import { ProjectSpacesService } from '@app-client/store/project-spaces/services/project-spaces.service';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class ProjectSpacesEffect {
    constructor(
        private actions$: Actions,
        private projectSpacesService: ProjectSpacesService,
        private store$: Store<AppStateInterface>,
        private toastr: ToastrService,
        private translate: TranslateService,
        private router: Router,
    ) {}

    // -----get----- //
    getArchivedProjectSpaces$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getArchivedProjectSpacesAction),
            withLatestFrom(this.store$.select(archivedProjectSpacesQueryConfigSelector)),
            switchMap(([, config]) => {
                return this.projectSpacesService.getArchivedProjectSpaces(config).pipe(
                    switchMap(response => {
                        return of(getArchivedProjectSpacesSuccessAction({ response }));
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(getArchivedProjectSpacesFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getArchivedProjectSpacesFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getArchivedProjectSpacesFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('PROJECT_SPACES.NOTIFICATIONS.GET_ARCHIVE_FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    // -----get one----- //
    getArchiveProjectSpace$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getArchivedProjectSpaceAction),
            switchMap(({ id }) => {
                return this.projectSpacesService.getProjectSpace(id).pipe(
                    switchMap(response => {
                        return of(getArchivedProjectSpaceSuccessAction({ response }));
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(getArchivedProjectSpaceFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getArchiveProjectSpaceFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getArchivedProjectSpaceFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('PROJECT_SPACES.NOTIFICATIONS.GET_ONE_ARCHIVE_FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    // -----restore----- //
    restoreArchivedProjectSpace$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(restoreArchivedProjectSpaceAction),
            switchMap(({ id }) => {
                return this.projectSpacesService.restoreProjectSpace(id).pipe(
                    map(() => {
                        return restoreArchivedProjectSpaceSuccessAction();
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(restoreArchivedProjectSpaceFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    restoreArchivedProjectSpaceSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(restoreArchivedProjectSpaceSuccessAction),
            switchMap(() => {
                this.toastr.success(
                    this.translate.instant('PROJECT_SPACES.NOTIFICATIONS.RESTORE_SUCCESS.MESSAGE'),
                    this.translate.instant('PROJECT_SPACES.NOTIFICATIONS.RESTORE_SUCCESS.TITLE'),
                );
                this.router.navigate(['/project-spaces/archived']);
                return this.store$.select(archivedProjectSpacesQueryConfigSelector).pipe(
                    take(1),
                    switchMap(config => {
                        const newConfig: ProjectSpacesQueryConfigInterface = {
                            ...config,
                            page: 1,
                        };
                        return [
                            setArchivedProjectSpacesQueryConfigAction({ config: newConfig }),
                            getArchivedProjectSpacesAction(),
                        ];
                    }),
                );
            }),
        );
    });

    restoreArchivedProjectSpaceFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(restoreArchivedProjectSpaceFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('PROJECT_SPACES.NOTIFICATIONS.RESTORE_FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    getProjectSpaces$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getProjectSpacesAction),
            withLatestFrom(this.store$.select(projectSpacesQueryConfigSelector)),
            switchMap(([, config]) => {
                return this.projectSpacesService.getProjectSpaces(config).pipe(
                    switchMap(response => {
                        return of(getProjectSpacesSuccessAction({ response }));
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(getProjectSpacesFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getProjectSpacesFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getProjectSpacesFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('PROJECT_SPACES.NOTIFICATIONS.GET_CURRENT_FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    // ---get one---
    getProjectSpace$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getProjectSpaceAction),
            switchMap(({ id }) => {
                return this.projectSpacesService.getProjectSpace(id).pipe(
                    switchMap(response => {
                        return of(getProjectSpaceSuccessAction({ response }));
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(getProjectSpaceFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getProjectSpaceFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getProjectSpaceFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('PROJECT_SPACES.NOTIFICATIONS.GET_ONE_CURRENT_FAILURE.TITLE'),
                );
                this.router.navigate([this.router.url.split('/').slice(0, -1).join('/')]);
            }),
        );
    }, { dispatch: false });

    // ---create---
    createProjectSpace$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createProjectSpaceAction),
            switchMap(({ newProjectSpace }) => {
                return this.projectSpacesService.createProjectSpace(newProjectSpace).pipe(
                    switchMap((response) => {
                        return of(createProjectSpaceSuccessAction({ projectSpace: response.body }));
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(createProjectSpaceFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    createProjectSpaceFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createProjectSpaceFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('PROJECT_SPACES.NOTIFICATIONS.CREATE_FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    createProjectSpaceSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createProjectSpaceSuccessAction),
            tap(({ projectSpace }) => {
                this.toastr.success(
                    this.translate.instant('PROJECT_SPACES.NOTIFICATIONS.CREATE_SUCCESS.MESSAGE'),
                    this.translate.instant('PROJECT_SPACES.NOTIFICATIONS.CREATE_SUCCESS.TITLE'),
                );
                this.router.navigate(['/project-spaces', projectSpace.id]);
            }),
        );
    }, { dispatch: false });

    // ---archive---
    archiveProjectSpace$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(archiveProjectSpaceAction),
            switchMap(({ id }) => {
                return this.projectSpacesService.archiveProjectSpace(id).pipe(
                    switchMap((response) => {
                        return of(archiveProjectSpaceSuccessAction());
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(archiveProjectSpaceFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    archiveProjectSpaceSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(archiveProjectSpaceSuccessAction),
            switchMap(() => {
                this.toastr.success(
                    this.translate.instant('PROJECT_SPACES.NOTIFICATIONS.ARCHIVE_SUCCESS.MESSAGE'),
                    this.translate.instant('PROJECT_SPACES.NOTIFICATIONS.ARCHIVE_SUCCESS.TITLE'),
                );
                this.router.navigate(['/project-spaces']);
                return this.store$.select(projectSpacesQueryConfigSelector).pipe(
                    take(1),
                    switchMap(config => {
                        const newConfig: ProjectSpacesQueryConfigInterface = {
                            ...config,
                            page: 1,
                        };
                        return [
                            setProjectSpacesQueryConfigAction({
                                config: newConfig,
                            }),
                            getProjectSpacesAction(),
                        ];
                    }),
                );
            }),
        );
    });

    archiveProjectSpaceFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(archiveProjectSpaceFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('PROJECT_SPACES.NOTIFICATIONS.ARCHIVE_FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    // ---update---
    updateProjectSpace$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateProjectSpaceAction),
            switchMap(({ id, newProjectSpace }) => {
                return this.projectSpacesService.updateProjectSpace(id, newProjectSpace).pipe(
                    switchMap(() => {
                        return of(updateProjectSpaceSuccessAction());
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(updateProjectSpaceFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    updateProjectSpaceFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateProjectSpaceFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('PROJECT_SPACES.NOTIFICATIONS.UPDATE_FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    updateProjectSpaceSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateProjectSpaceSuccessAction),
            switchMap(() => {
                this.toastr.success(
                    this.translate.instant('PROJECT_SPACES.NOTIFICATIONS.UPDATE_SUCCESS.MESSAGE'),
                    this.translate.instant('PROJECT_SPACES.NOTIFICATIONS.UPDATE_SUCCESS.TITLE'),
                );
                this.router.navigate([this.router.url.split('/').slice(0, -1).join('/')]);
                return this.store$.select(projectSpacesQueryConfigSelector).pipe(
                    take(1),
                    switchMap(config => {
                        const newConfig: ProjectSpacesQueryConfigInterface = {
                            ...config,
                            page: 1,
                        };
                        return [
                            setProjectSpacesQueryConfigAction({
                                config: newConfig,
                            }),
                            getProjectSpacesAction(),
                        ];
                    }),
                );
            }),
        );
    });
}
