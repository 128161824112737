export enum ProjectMembersActionTypes {
    SET_LIST_QUERY_PARAMS = '[ProjectMembers] Set list query params',

    GET_LIST = '[ProjectMembers] Get list',
    GET_LIST_SUCCESS = '[ProjectMembers] Get list success',
    GET_LIST_FAILURE = '[ProjectMembers] Get list failure',

    CREATE = '[ProjectMembersF] Create',
    CREATE_SUCCESS = '[ProjectMembers] Create success',
    CREATE_FAILURE = '[ProjectMembers] Create failure',

    UPDATE = '[ProjectMembers] Update',
    UPDATE_SUCCESS = '[ProjectMembers] Update success',
    UPDATE_FAILURE = '[ProjectMembers] Update failure',

    REMOVE = '[ProjectMembers] Remove',
    REMOVE_SUCCESS = '[ProjectMembers] Remove success',
    REMOVE_FAILURE = '[ProjectMembers] Remove failure',

    GET_ONE = '[ProjectMembers] Get one',
    GET_ONE_SUCCESS = '[ProjectMembers] Get one success',
    GET_ONE_FAILURE = '[ProjectMembers] Get one failure',

    BATCH_UPDATE_PERMISSION = '[ProjectMembers] Batch update permission',
    BATCH_UPDATE_PERMISSION_SUCCESS = '[ProjectMembers] Batch update permission success',
    BATCH_UPDATE_PERMISSION_FAILURE = '[ProjectMembers] Batch update permission failure',

    GET_REQUIRES_DELETE_CONFIRMATION = '[ProjectMembers] Get requires delete confirmation',

}
