import { NgModule } from '@angular/core';

import { CalcPercentagePerHourPipe } from '@app-client/shared/modules/app-pipe/pipes/calc-percentage-per-hour.pipe';
import { EffectivityStatusToColorPipe } from '@app-client/shared/modules/app-pipe/pipes/effectivity-status-to-color.pipe';
import { EffectivityStatusToTextPipe } from '@app-client/shared/modules/app-pipe/pipes/effectivity-status-to-text.pipe';
import { MillisToTimePipe } from '@app-client/shared/modules/app-pipe/pipes/millis-to-time.pipe';
import { MomentToSecondsPipe } from '@app-client/shared/modules/app-pipe/pipes/moment-to-seconds.pipe';
import { PadStartPipe } from '@app-client/shared/modules/app-pipe/pipes/pad-start.pipe';
import { SecondsToDurationStringPipe } from '@app-client/shared/modules/app-pipe/pipes/seconds-to-duration-string.pipe';
import { SecondsToTimeStringPipe } from '@app-client/shared/modules/app-pipe/pipes/seconds-to-time-string.pipe';

import { CombinePipe } from './pipes/combine.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { ObjectValuesPipe } from './pipes/object-values.pipe';


@NgModule({
    imports: [],
    exports: [
        MillisToTimePipe,
        EffectivityStatusToColorPipe,
        EffectivityStatusToTextPipe,
        CalcPercentagePerHourPipe,
        PadStartPipe,
        MomentToSecondsPipe,
        SecondsToDurationStringPipe,
        SecondsToTimeStringPipe,
        ObjectValuesPipe,
        FilterPipe,
        CombinePipe,
    ],
    declarations: [
        MillisToTimePipe,
        EffectivityStatusToColorPipe,
        EffectivityStatusToTextPipe,
        CalcPercentagePerHourPipe,
        PadStartPipe,
        MomentToSecondsPipe,
        SecondsToDurationStringPipe,
        SecondsToTimeStringPipe,
        ObjectValuesPipe,
        FilterPipe,
        CombinePipe,
    ],
    providers: [
        MomentToSecondsPipe,
        SecondsToDurationStringPipe,
        SecondsToTimeStringPipe,
        ObjectValuesPipe,
        FilterPipe,
        CombinePipe,
    ],
})
export class AppPipeModule {}
