import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, RouteConfigLoadStart, Router, RouterEvent } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { delay, map, skipWhile, take } from 'rxjs/operators';

import { PlanSelectorLayoutComponent } from '@app-client/layout/components/plan-selector/layout/plan-selector-layout.component';
import { currentUserIsAdminSelector, currentUserSelector } from '@app-client/store/auth/selectors/auth.selectors';
import { CurrentUserInterface } from '@app-client/store/auth/types/current-user.interface';
import {
    marketplaceModulesIsEnabledLoadingSelector,
    marketplaceModulesIsModuleEnabledSelector,
} from '@app-client/store/marketplace/modules/selectors/marketplace-modules.selectors';
import { AppModulesEnum } from '@app-shared/types/app-modules.enum';
import { NewYearService } from '@app-client/new-year/new-year.service';


@UntilDestroy()
@Component({
    selector: 'app-layout',
    templateUrl: 'layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
    currentUser$: Observable<CurrentUserInterface>;
    isRouterNavigationInProgress: boolean;
    isModulesLoading$: Observable<boolean>;

    constructor(
        private store: Store,
        private router: Router,
        private dialog: MatDialog,
        private meta: Meta,
        @Inject(DOCUMENT) private document: Document,
        public newYearService: NewYearService,
    ) {}

    ngOnInit(): void {
        this.meta.updateTag({ name: 'viewport', content: '' });
        this.document.body.style.background = 'white';
        this.initializeValues();
    }

    initializeValues(): void {
        this.currentUser$ = this.store.select(currentUserSelector);
        this.router.events.pipe(
            untilDestroyed(this),
            map((event: RouterEvent) => {
                if (event instanceof RouteConfigLoadStart) {
                    this.isRouterNavigationInProgress = true;
                } else if (event instanceof NavigationEnd) {
                    this.isRouterNavigationInProgress = false;
                }
            }),
        ).subscribe();
        this.isModulesLoading$ = this.store.select(marketplaceModulesIsEnabledLoadingSelector);

        combineLatest([
            this.store.select(marketplaceModulesIsModuleEnabledSelector(AppModulesEnum.CORE)),
            this.isModulesLoading$,
            this.store.select(currentUserIsAdminSelector)
        ]).pipe(
            untilDestroyed(this),
            skipWhile(([_, isModulesLoading]) => {
                return isModulesLoading;
            }),
            take(1),
            delay(100),
        ).subscribe(([isCoreEnabled, _, isAdmin]) => {
            if (isCoreEnabled === false && isAdmin === true) {
                this.dialog.open(PlanSelectorLayoutComponent, {
                    width: '80vw',
                    disableClose: true,
                });
            }
        });
    }
}
