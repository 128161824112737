import { Pipe, PipeTransform } from '@angular/core';
import { Moment } from 'moment-timezone';


@Pipe({
    name: 'calcPercentagePerHour',
})
export class CalcPercentagePerHourPipe implements PipeTransform {
    transform(value: Moment, startOf: Moment = value.clone().startOf('hour')): number {
        return value.diff(startOf, 'milliseconds') / (60 * 60 * 1000) * 100;
    }
}
