export enum MarketplaceAccountActionsEnum {
    GET_DATA = '[MarketplaceAccount] Get data',
    GET_DATA_SUCCESS = '[MarketplaceAccount] Get data success',
    GET_DATA_FAILURE = '[MarketplaceAccount] Get data failure',

    GET_CARDS = '[MarketplaceAccount] Get cards',
    GET_CARDS_SUCCESS = '[MarketplaceAccount] Get cards success',
    GET_CARDS_FAILURE = '[MarketplaceAccount] Get cards failure',

    REMOVE_CARD = '[MarketplaceAccount] Remove card',
    REMOVE_CARD_SUCCESS = '[MarketplaceAccount] Remove card success',
    REMOVE_CARD_FAILURE = '[marketplaceAccount] Remove card failure',

    GET_INIT_KEY = '[MarketplaceAccount] Get init key',
    GET_INIT_KEY_SUCCESS = '[MarketplaceAccount] Get init key success',
    GET_INIT_KEY_FAILURE = '[MarketplaceAccount] Get init key failure',

    PAY = '[MarketplaceAccount] Pay',
    PAY_SUCCESS = '[MarketplaceAccount] Pay success',
    PAY_FAILURE = '[MarketplaceAccount] Pay failure',

    CHARGE = '[MarketplaceAccount] Charge',
    CHARGE_SUCCESS = '[MarketplaceAccount] Charge success',
    CHARGE_FAILURE = '[MarketplaceAccount] Charge failure',
}
