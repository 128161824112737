export enum ReportUserActivityActionTypes {
    GET_REPORT = '[Report users activity] Get report',
    GET_REPORT_SUCCESS = '[Report users activity] Get report success',
    GET_REPORT_FAILURE = '[Report users activity] Get report failure',

    EXPORT_GOOGLE_SPREADSHEET = '[Report users activity] Export Google spreadsheet',
    EXPORT_GOOGLE_SPREADSHEET_SUCCESS = '[Report users activity] Export Google spreadsheet success',
    EXPORT_GOOGLE_SPREADSHEET_FAILURE = '[Report users activity] Export Google spreadsheet failure',

    CLEAR_DATA = '[Report users activity] Clear data',
}
