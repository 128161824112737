import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { EffectivityApplicationStatusEnum } from '@app-client/effectivity/types/app/effectivity-application-status.enum';


@Pipe({
    name: 'effectivityStatusToText'
})
export class EffectivityStatusToTextPipe implements PipeTransform {
    constructor(
        private translateService: TranslateService
    ) {}

    transform(value: number): Observable<string> {
        switch (value) {
            case EffectivityApplicationStatusEnum.EFFECTIVE: return this.translateService.stream('GLOBAL.EFFECTIVITY_STATUS.EFFECTIVE');
            case EffectivityApplicationStatusEnum.INEFFECTIVE: return this.translateService.stream('GLOBAL.EFFECTIVITY_STATUS.INEFFECTIVE');
            case EffectivityApplicationStatusEnum.NEUTRAL: return this.translateService.stream('GLOBAL.EFFECTIVITY_STATUS.NEUTRAL');
        }
    }
}
