import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { marketplaceModulesIsModuleEnabledSelector } from '@app-client/store/marketplace/modules/selectors/marketplace-modules.selectors';
import { AppModulesEnum } from '@app-shared/types/app-modules.enum';


@Component({
    selector: 'app-plan-selector-layout',
    templateUrl: './plan-selector-layout.component.html',
    styleUrls: ['./plan-selector-layout.component.scss'],
})
export class PlanSelectorLayoutComponent implements OnInit {
    isCoreEnabled$: Observable<boolean>;

    constructor(
        private store: Store,
    ) {}

    ngOnInit(): void {
        this.initializeVariables();
    }

    private initializeVariables(): void {
        this.isCoreEnabled$ = this.store.select(marketplaceModulesIsModuleEnabledSelector(AppModulesEnum.CORE));
    }
}
