<div class="modal-form notranslate">
    <div class="modal-content">
        <div class="modal-header">
            <div class="welcome-demo-dialog-picture">
                <mat-icon svgIcon="attrack-skipped-demo-dialog-picture" aria-hidden="false"></mat-icon>
            </div>
            <div class="modal-close" (click)="close()">
                <div class="material-icons">close</div>
            </div>
        </div>
        <div class="modal-body">
            <div class="text">
                <b>{{ 'ACTIVITY.DEMO.DELETE_DEMO_DIALOG.TEXT' | translate }}</b>
            </div>
        </div>
        <div class="modal-footer">
            <div class="actions">
                <button
                    ui-button
                    (click)="submit()"
                    size="large"
                    color="yellow"
                >
                    <span>
                        {{ 'ACTIVITY.DEMO.DELETE_DEMO_DIALOG.DELETE' | translate }}
                    </span>
                </button>
            </div>
        </div>
    </div>
</div>
