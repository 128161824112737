import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { skipWhile, switchMap } from 'rxjs/operators';

import { UserRoleEnum } from '@app-client/shared/types/user-role.enum';
import {
    currentUserSelector,
    isCurrentUserLoadingSelector,
} from '@app-client/store/auth/selectors/auth.selectors';


@Injectable()
export class ProjectCreateGuardService implements CanActivate {
    constructor(
        private store: Store,
        private router: Router,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return combineLatest([
            this.store.select(currentUserSelector),
            this.store.select(isCurrentUserLoadingSelector),
        ]).pipe(
            skipWhile(([, isUserLoading]) => isUserLoading || isUserLoading === null),
            switchMap(([user]) => {
                const forbid = () => {
                    this.router.navigate(['/']);

                    return of(false);
                };

                const allow = () => {
                    return of(true);
                };

                return (user.role === UserRoleEnum.ROLE_ADMIN || user?.permissions?.canCreateProjects)
                    ? allow()
                    : forbid();
            }),
        );
    }
}
