import { createReducer, on } from '@ngrx/store';

import {
    marketplaceAccountChargeAction, marketplaceAccountChargeFailureAction, marketplaceAccountChargeSuccessAction,
    marketplaceAccountPayAction, marketplaceAccountPayFailureAction, marketplaceAccountPaySuccessAction,
    marketplaceCardsGetInitKeyAction, marketplaceCardsGetInitKeyFailureAction, marketplaceCardsGetInitKeySuccessAction,
    marketplaceCardsGetListAction,
    marketplaceCardsGetListFailureAction,
    marketplaceCardsGetListSuccessAction,
    marketplaceCardsRemoveAction, marketplaceCardsRemoveFailureAction,
    marketplaceCardsRemoveSuccessAction,
    marketplaceGetAccountAction,
    marketplaceGetAccountFailureAction,
    marketplaceGetAccountSuccessAction,
} from '@app-client/store/marketplace/account/actions/marketplace-account.actions';
import { MarketplaceAccountStateInterface } from '@app-client/store/marketplace/account/marketplace-account-state.interface';


const initialState: MarketplaceAccountStateInterface = {
    account: null,
    isDataLoading: false,

    isCardsLoading: false,
    cards: [],
    isCardRemoveInProgress: false,
    isInitKeyLoading: false,
    initKey: null,
    isPayInProgress: false,
    isChargeInProgress: false,
    chargeSecret: null,
    payInfo: null,
};


const reducer = createReducer(
    initialState,
    on(
        marketplaceGetAccountAction,
        (state) => ({
            ...state,
            isDataLoading: true,
        }),
    ),
    on(
        marketplaceGetAccountSuccessAction,
        (state, action) => ({
            ...state,
            account: action.account,
            isDataLoading: false,
        }),
    ),
    on(
        marketplaceGetAccountFailureAction,
        (state) => ({
            ...state,
            account: null,
            isDataLoading: false,
        }),
    ),
    on(
        marketplaceCardsGetListAction,
        (state) => ({
            ...state,
            isCardsLoading: true,
            cards: [],
        }),
    ),
    on(
        marketplaceCardsGetListSuccessAction,
        (state, action) => ({
            ...state,
            isCardsLoading: false,
            cards: action.cards,
        }),
    ),
    on(
        marketplaceCardsGetListFailureAction,
        (state) => ({
            ...state,
            isCardsLoading: false,
        }),
    ),
    on(
        marketplaceCardsRemoveAction,
        (state) => ({
            ...state,
            isCardRemoveInProgress: true,
        }),
    ),
    on(
        marketplaceCardsRemoveSuccessAction,
        (state, action) => ({
            ...state,
            isCardRemoveInProgress: false,
            cards: state.cards.filter((card) => {
                return card.id !== action.id;
            }),
        }),
    ),
    on(
        marketplaceCardsRemoveFailureAction,
        (state) => ({
            ...state,
            isCardRemoveInProgress: false,
        }),
    ),
    on(
        marketplaceCardsGetInitKeyAction,
        (state) => ({
            ...state,
            isInitKeyLoading: true,
            initKey: null,
        }),
    ),
    on(
        marketplaceCardsGetInitKeySuccessAction,
        (state, action) => ({
            ...state,
            isInitKeyLoading: false,
            initKey: action.initKey,
        }),
    ),
    on(
        marketplaceCardsGetInitKeyFailureAction,
        (state) => ({
            ...state,
            isInitKeyLoading: false,
        }),
    ),
    on(
        marketplaceAccountPayAction,
        (state) => ({
            ...state,
            isPayInProgress: true,
            payInfo: null,
        }),
    ),
    on(
        marketplaceAccountPaySuccessAction,
        (state, action) => ({
            ...state,
            isPayInProgress: false,
            payInfo: action.payInfo,
        }),
    ),
    on(
        marketplaceAccountPayFailureAction,
        (state) => ({
            ...state,
            isPayInProgress: false,
        }),
    ),
    on(
        marketplaceAccountChargeAction,
        (state) => ({
            ...state,
            isChargeInProgress: true,
            chargeSecret: null,
        }),
    ),
    on(
        marketplaceAccountChargeSuccessAction,
        (state, action) => ({
            ...state,
            isChargeInProgress: false,
            chargeSecret: action.chargeSecret,
        }),
    ),
    on(
        marketplaceAccountChargeFailureAction,
        (state) => ({
            ...state,
            isChargeInProgress: false,
            chargeSecret: null,
        }),
    ),
);

export const marketplaceAccountReducer = (state, action) => reducer(state, action);
