import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';

import {
    signatureGetDocumentAction,
    signatureGetDocumentFailureAction,
    signatureGetDocumentSuccessAction,
} from './signature-document.actions';
import { SignatureDocumentService } from './signature-document.service';


@Injectable()
export class SignatureDocumentEffects {

    constructor(
        private actions$: Actions,
        private signatureDocumentService: SignatureDocumentService,
        private toastr: ToastrService,
        private translate: TranslateService,
    ) {}

    getDocumentEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(signatureGetDocumentAction),
            switchMap(() => {
                return this.signatureDocumentService.getDocument().pipe(
                    map((document) => {
                        return signatureGetDocumentSuccessAction({ document });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse.error.message,
                            errors: httpErrorResponse.error.errors,
                        };

                        return of(signatureGetDocumentFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getDocumentFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(signatureGetDocumentFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('SIGNATURE.NOTIFICATIONS.FAILURE.DOCUMENT'),
                );
            }),
        );
    }, { dispatch: false });
}
