import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActivityStateInterface } from '@app-client/store/activity/activity-state.interface';


export const activityFeatureSelector = createFeatureSelector<ActivityStateInterface>('activity');

export const isTimelineDataLoadingSelector = createSelector(
    activityFeatureSelector,
    state =>
        state.isProjectTaskMemoDataLoading ||
        state.isWindowDataLoading ||
        state.isScreenshotDataLoading ||
        state.isDailyStatisticsLoading,
);

export const isTimelineDataPersistSelector = createSelector(
    activityFeatureSelector,
    state => state.isTimelineDataPersist,
);

// filters
export const displayFiltersSelector = createSelector(
    activityFeatureSelector,
    state => state.displayFilters,
);

// data
export const dailyStatisticsSelector = createSelector(
    activityFeatureSelector,
    state => state.dailyStatistics,
);
export const weeklyStatisticsSelector = createSelector(
    activityFeatureSelector,
    state => state.weeklyStatistics,
);
export const monthlyStatisticsSelector = createSelector(
    activityFeatureSelector,
    state => state.monthlyStatistics,
);
export const projectTaskMemoDataSelector = createSelector(
    activityFeatureSelector,
    state => state.projectTaskMemoData,
);
export const windowDataSelector = createSelector(
    activityFeatureSelector,
    state => state.windowData,
);
export const screenshotDataSelector = createSelector(
    activityFeatureSelector,
    state => state.screenshotData,
);

export const getStatisticPerDaySelector = createSelector(
    activityFeatureSelector,
    state => state.statisticPerDay,
);
