import { createAction, props } from '@ngrx/store';

import { CompanyConfigActionEnum } from '@app-client/store/company/company-config-action.enum';
import { CompanyConfigSignatureInterface } from '@app-client/store/company/company-config.interface';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';

export const getCompanyConfigSignatureAction = createAction(
    CompanyConfigActionEnum.GET_COMPANY_CONFIG_SIGNATURE,
);

export const getCompanyConfigSignatureSuccessAction = createAction(
    CompanyConfigActionEnum.GET_COMPANY_SIGNATURE_SUCCESS,
    props<{ companyConfigSignature: CompanyConfigSignatureInterface }>(),
);

export const getCompanyConfigSignatureFailureAction = createAction(
    CompanyConfigActionEnum.GET_COMPANY_SIGNATURE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const updateCompanyConfigSignatureAction = createAction(
    CompanyConfigActionEnum.UPDATE_COMPANY_SIGNATURE_SCREENSHOTS,
    props<{ companyConfigSignature: CompanyConfigSignatureInterface }>(),
);

export const updateCompanyConfigSignatureSuccessAction = createAction(
    CompanyConfigActionEnum.UPDATE_COMPANY_SIGNATURE_SUCCESS
);

export const updateCompanyConfigSignatureFailureAction = createAction(
    CompanyConfigActionEnum.UPDATE_COMPANY_SIGNATURE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
