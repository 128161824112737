import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { OnboardingDataStatusEnum } from '@app-client/shared/types/onboarding-data-status.enum';
import { OnboardingStatusEnum } from '@app-client/shared/types/onboarding-status.enum';
import { logoutAction } from '@app-client/store/auth/actions/login.action';
import {
    currentCompanySelector,
    currentUserIsAdminSelector,
    currentUserIsHeadSelector,
    currentUserSelector,
} from '@app-client/store/auth/selectors/auth.selectors';
import { CurrentUserInterface } from '@app-client/store/auth/types/current-user.interface';
import { onboardingStatusSelector } from '@app-client/store/tour/selectors/tour.selector';
import { DeleteDemoDialogComponent } from '@app-client/tour/components/delete-demo-dialog/delete-demo-dialog.component';
import { RestartDemoDialogComponent } from '@app-client/tour/components/restart-demo-dialog/restart-demo-dialog.component';
import { WelcomeDemoDialogComponent } from '@app-client/tour/components/welcome-demo-dialog/welcome-demo-dialog.component';

import { environment } from '@env-client/environment';

const ATTRACK_LOGO_URL = '/assets/images/AtTrackLogoDark.svg';
const ROMDO_LOGO_URL = '/assets/images/RomdoLogoDark.svg';

const TRACKER_SUB_DOMAIN = 'tracker';


@Component({
    selector: 'app-header',
    templateUrl: 'header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    appDownloadUrl: string;
    currentUser$: Observable<CurrentUserInterface>;
    isAdmin$: Observable<boolean>;
    isHead$: Observable<boolean>;
    isStartDemo$: Observable<boolean>;
    isOnboardingDataLoaded$: Observable<boolean>;
    logoUrl$: Observable<string>;

    constructor(
        private store: Store,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private matDialog: MatDialog,
    ) {
        this.iconRegistry.addSvgIcon(
            'attrack-logout',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/header/logout_icon.svg'));
        this.iconRegistry.addSvgIcon(
            'attrack-profile',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/header/profile_icon.svg'));
        this.iconRegistry.addSvgIcon(
            'attrack-download',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/header/download_icon.svg'));
        this.iconRegistry.addSvgIcon(
            'attrack-demo',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/header/start_demo.svg'));
        this.iconRegistry.addSvgIcon(
            'attrack-delete-demo-data',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/header/delete_demo_data.svg'));
        this.iconRegistry.addSvgIcon(
            'attrack-employees',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/header/employees.svg'));
    }

    logout() {
        this.store.dispatch(logoutAction());
    }

    ngOnInit() {
        this.initializeValues();
    }

    initializeValues() {
        this.currentUser$ = this.store.select(currentUserSelector);
        this.isAdmin$ = this.store.select(currentUserIsAdminSelector);
        this.isHead$ = this.store.select(currentUserIsHeadSelector);
        this.appDownloadUrl = environment.appDownloadUrl;

        this.isStartDemo$ = this.currentUser$.pipe(
            map((currentUser) => currentUser.onboardingStatus === OnboardingStatusEnum.NOT_STARTED),
        );

        this.isOnboardingDataLoaded$ = this.store.select(onboardingStatusSelector)
            .pipe(
                map((status) => status === OnboardingDataStatusEnum.LOADED),
            );
        this.logoUrl$ = this.store.select(currentCompanySelector).pipe(
            map((company) => {
                if (company.subDomain === TRACKER_SUB_DOMAIN) {
                    return ROMDO_LOGO_URL;
                }

                return ATTRACK_LOGO_URL;
            }),
        );
    }

    startDemo() {
        this.matDialog.open(WelcomeDemoDialogComponent, {
            panelClass: 'no-padding-dialog-container'
        });
    }

    restartDemo() {
        this.matDialog.open(RestartDemoDialogComponent, {
            panelClass: 'no-padding-dialog-container'
        });
    }

    deleteDemoData() {
        this.matDialog.open(DeleteDemoDialogComponent, {
            panelClass: 'no-padding-dialog-container'
        });
    }
}
