<div *ngIf="formControlRef.errors?.required">
    {{ 'GLOBAL.VALIDATION.REQUIRED' | translate:{ fieldName: controlName, gender: controlNameGender, multiple: controlNameIsMultiple } }}
</div>
<div *ngIf="formControlRef.errors?.email">
    {{ 'GLOBAL.VALIDATION.EMAIL' | translate:{ fieldName: controlName, gender: controlNameGender, multiple: controlNameIsMultiple } }}
</div>
<div *ngIf="formControlRef.errors?.pattern">
    {{ 'GLOBAL.VALIDATION.PATTERN' | translate:{ fieldName: controlName, gender: controlNameGender, multiple: controlNameIsMultiple } }}
</div>
<div *ngIf="formControlRef.errors?.minlength">
    {{ 'GLOBAL.VALIDATION.LENGTH.MIN' | translate:{ fieldName: controlName, count: formControlRef.errors?.minlength.requiredLength, gender: controlNameGender, multiple: controlNameIsMultiple } }}
</div>
<div *ngIf="formControlRef.errors?.maxlength">
    {{ 'GLOBAL.VALIDATION.LENGTH.MAX' | translate:{ fieldName: controlName, count: formControlRef.errors?.maxlength.requiredLength, gender: controlNameGender, multiple: controlNameIsMultiple } }}
</div>
<div *ngIf="formControlRef.errors?.arrayMinLength">
    {{ 'GLOBAL.VALIDATION.ARRAY_LENGTH.MIN' | translate:{ fieldName: controlName, count: formControlRef.errors?.arrayMinLength.requiredLength, gender: controlNameGender, multiple: controlNameIsMultiple } }}
</div>
<div *ngIf="formControlRef.errors?.arrayMaxLength">
    {{ 'GLOBAL.VALIDATION.ARRAY_LENGTH.MAX' | translate:{ fieldName: controlName, count: formControlRef.errors?.arrayMaxLength.requiredLength, gender: controlNameGender, multiple: controlNameIsMultiple } }}
</div>
<div *ngIf="formControlRef.errors?.passwordMatches">
    {{ 'GLOBAL.VALIDATION.INVALID_CONFIRM_PASSWORD' | translate:{ fieldName: controlName, gender: controlNameGender, multiple: controlNameIsMultiple } }}
</div>
<div *ngIf="formControlRef.errors?.strongPassword">
    {{ 'GLOBAL.VALIDATION.STRONG_PASSWORD_VALIDATION' | translate:{ fieldName: controlName, gender: controlNameGender, multiple: controlNameIsMultiple } }}
</div>
<div *ngIf="formControlRef.errors?.strongPasswordForbiddenCharacters">
    {{ 'GLOBAL.VALIDATION.STRONG_PASSWORD_FORBIDDEN_CHARACTERS_VALIDATION' | translate:{ fieldName: controlName, gender: controlNameGender, multiple: controlNameIsMultiple } }}
</div>
<div *ngIf="formControlRef.errors?.domain">
    {{ 'GLOBAL.VALIDATION.DOMAIN' | translate:{ fieldName: controlName, gender: controlNameGender, multiple: controlNameIsMultiple } }}
</div>
<div *ngIf="formControlRef.errors?.fromLessThanTo">
    {{ 'GLOBAL.VALIDATION.INTERVAL_FROM_LESS_THAN_TO' | translate:{ fieldName: controlName, gender: controlNameGender, multiple: controlNameIsMultiple } }}
</div>
<div *ngIf="formControlRef.errors?.timeFormat">
    {{ 'GLOBAL.VALIDATION.TIME_FORMAT' | translate:{ fieldName: controlName, gender: controlNameGender, multiple: controlNameIsMultiple } }}
</div>
<div *ngIf="formControlRef.errors?.intervalsHasIntersections">
    {{ 'GLOBAL.VALIDATION.INTERVALS_HAS_INTERSECTIONS' | translate:{ fieldName: controlName, gender: controlNameGender, multiple: controlNameIsMultiple } }}
</div>
<div *ngIf="formControlRef.errors?.intervalsHasIntersectionsWithExistingTime">
    {{ 'GLOBAL.VALIDATION.INTERVALS_HAS_INTERSECTIONS_WITH_EXISTING_TIME' | translate:{ fieldName: controlName, gender: controlNameGender, multiple: controlNameIsMultiple } }}
</div>
<div *ngIf="formControlRef.errors?.intervalsInClosedPeriod">
    {{ 'GLOBAL.VALIDATION.INTERVALS_IN_CLOSED_PERIOD' | translate:{ fieldName: controlName, gender: controlNameGender, multiple: controlNameIsMultiple } }}
</div>
<div *ngIf="formControlRef.errors?.projectMemberExsists">
    {{ 'GLOBAL.VALIDATION.PROJECT_MEMBER_EXISTS' | translate:{ fieldName: controlName, gender: controlNameGender, multiple: controlNameIsMultiple } }}
</div>
<div *ngIf="formControlRef.errors?.backendErrors">
    <div *ngFor="let error of formControlRef.errors.backendErrors">
        {{ error }}
    </div>
</div>
