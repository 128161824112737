import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { skipWhile, switchMap, take } from 'rxjs/operators';

import { ProjectRoleEnum } from '@app-client/project/types/project-role.enum';
import { UserRoleEnum } from '@app-client/shared/types/user-role.enum';
import { currentUserSelector, isCurrentUserLoadingSelector } from '@app-client/store/auth/selectors/auth.selectors';
import { marketplaceModulesIsEnabledLoadingSelector, marketplaceModulesIsModuleEnabledSelector } from '@app-client/store/marketplace/modules/selectors/marketplace-modules.selectors';
import { AppModulesEnum } from '@app-shared/types/app-modules.enum';


@Injectable()
export class DailyResultGuard implements CanActivate {
    constructor(
        private store: Store,
        private router: Router,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return combineLatest([
            this.store.select(currentUserSelector),
            this.store.select(isCurrentUserLoadingSelector),
            this.store.select(marketplaceModulesIsModuleEnabledSelector(AppModulesEnum.DAILY_RESULT)),
            this.store.select(marketplaceModulesIsEnabledLoadingSelector),
        ]).pipe(
            skipWhile(([, isUserLoading, , isEnabledLoading]) => isUserLoading || isEnabledLoading || isUserLoading === null),
            take(1),
            switchMap(([user, , isEnabled]) => {
                const forbid = () => {
                    this.router.navigate(['/']);

                    return of(false);
                };

                const allow = () => {
                    return of(true);
                };

                if (!isEnabled) {
                    return forbid();
                }

                if (!user) {
                    return forbid();
                }

                if (UserRoleEnum.ROLE_ADMIN === user.role) {
                    return allow();
                }

                if (user.projectRoles.length === 1 && ProjectRoleEnum.ROLE_OBSERVER === user.projectRoles[0]) {
                    return forbid();
                }

                return allow();
            }),
        );
    }
}
