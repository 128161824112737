import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { catchError, last, switchMap } from 'rxjs/operators';

import { PersistenceService } from '@app-client/shared/services/persistence.service';
import { logoutAction } from '@app-client/store/auth/actions/login.action';
import { AuthService } from '@app-client/store/auth/services/auth.service';
import { CredentialsInterface } from '@app-client/store/auth/types/credentials.interface';

import { environment } from '@env-client/environment';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = null;

    constructor(
        private authService: AuthService,
        private persistenceService: PersistenceService,
        private store: Store,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = this.addLocale(request);

        // if this is refresh token request - not handle it
        if (request.params.has('refresh_token')) {
            return next.handle(request);
        }

        const credentials: CredentialsInterface = this.persistenceService.get('credentials');

        if (credentials) {
            request = this.attachAccessToken(request, credentials.token);
        }

        return next.handle(request).pipe(
            catchError(error => {
                if (error instanceof HttpErrorResponse && error.status === 401 && credentials) {
                    return this.handle401Error(request, next);
                }

                if (error instanceof HttpErrorResponse && error.status === 401 && !credentials) {
                    this.store.dispatch(logoutAction());
                }

                return throwError(error);
            }),
        );
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (this.isRefreshing) {
            return this.refreshTokenSubject.asObservable().pipe(
                last(),
                switchMap(jwt => {
                    if (!jwt) {
                        // do not spam with 401 status errors
                        return EMPTY;
                    }

                    return next.handle(this.attachAccessToken(request, jwt));
                }),
            );
        }

        this.isRefreshing = true;
        this.refreshTokenSubject = new BehaviorSubject<any>(null);
        const credentials: CredentialsInterface = this.persistenceService.get('credentials');

        return this.authService.refreshToken(credentials.refresh_token).pipe(
            switchMap((newCredentials: CredentialsInterface) => {
                this.persistenceService.set('credentials', newCredentials);
                this.isRefreshing = false;
                this.refreshTokenSubject.next(newCredentials.token);
                this.refreshTokenSubject.complete();

                return next.handle(this.attachAccessToken(request, newCredentials.token));
            }),
            catchError((err: any) => {
                this.isRefreshing = false;
                this.refreshTokenSubject.next(null);
                this.refreshTokenSubject.complete();
                this.store.dispatch(logoutAction());

                return throwError(err);
            }),
        );
    }

    private attachAccessToken(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });
    }

    private addLocale(request: HttpRequest<any>): HttpRequest<any> {
        const supportedLangs = environment.locales;

        let storedLanguage = this.persistenceService.get('Accept-Language');
        if (!supportedLangs.includes(storedLanguage)) {
            storedLanguage = null;
        }

        const browserLang = window.navigator.languages.map(lang => lang.slice(0, 2)).find(lang => supportedLangs.includes(lang));

        return request.clone({
            setHeaders: {
                'Accept-Language': storedLanguage || browserLang || environment.defaultLocale || 'en',
            },
        });
    }
}
