import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProcessCommand } from '@app-client/report/by-projects/components/report-filters/commands';
import { ReportFiltersInterface } from '@app-client/report/by-projects/types/report-filters.interface';
import { ReportInterface } from '@app-client/report/by-projects/types/report.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';

import { environment } from '@env-client/environment';


@Injectable()
export class ReportService {
    constructor(private httpClient: HttpClient) {}

    getReport(
        filters: ReportFiltersInterface,
    ): Observable<ReportInterface> {
        const url = environment.apiBaseUrl + '/reports';

        return this.httpClient.post<ResponseInterface<ReportInterface>>(url, filters).pipe(
            map((response: ResponseInterface<ReportInterface>) => {
                return response.body;
            })
        );
    }

    requestGoogleSpreadsheet(
        filters: ReportFiltersInterface,
        isLightStyles: boolean,
        processPipe: Array<ProcessCommand>,
    ): Observable<void> {
        const url = environment.apiBaseUrl + '/reports/google';

        return this.httpClient.post<void>(url, { ...filters, lightStyles: isLightStyles, processPipeKeys: processPipe.map(x => x.getLabel()) });
    }
}
