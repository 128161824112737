import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, fromEvent, merge, Observable, of } from 'rxjs';
import { delay, map, shareReplay, startWith, switchMap } from 'rxjs/operators';

import { currentCompanySelector } from '@app-client/store/auth/selectors/auth.selectors';


const AVAILABLE_SUBDOMAINS = ['empty', 'uit-attrack-v2-dev', 'uit-attrack-v2-sg', 'tracker'];


@Injectable({ providedIn: 'root' })
export class NewYearService {
    isSnowEnabled$ = new BehaviorSubject(true);
    isWreathEnabled$ = new BehaviorSubject(true);

    isSnowVisible$: Observable<boolean>;
    isWreathVisible$: Observable<boolean>;

    isAllow$: Observable<boolean>;

    constructor(private store$: Store) {
        this.isSnowEnabled$.next(localStorage.getItem('NEW_YEAR_SNOW') !== 'HIDE');
        this.isWreathEnabled$.next(localStorage.getItem('NEW_YEAR_WREATH') !== 'HIDE');
        this.isSnowEnabled$.subscribe((value) => {
            if (true === value) {
                localStorage.removeItem('NEW_YEAR_SNOW');
            } else {
                localStorage.setItem('NEW_YEAR_SNOW', 'HIDE');
            }
        });
        this.isWreathEnabled$.subscribe((value) => {
            if (true === value) {
                localStorage.removeItem('NEW_YEAR_WREATH');
            } else {
                localStorage.setItem('NEW_YEAR_WREATH', 'HIDE');
            }
        });
        this.isSnowVisible$ = combineLatest(
            merge(
                fromEvent(document, 'keydown'),
                fromEvent(document, 'mousemove'),
                fromEvent(document, 'mousedown'),
                fromEvent(document, 'mousewheel'),
            ).pipe(
                startWith(null),
                switchMap(() => {
                    return of(true).pipe(
                        delay(5000),
                        startWith(false),
                    );
                }),
            ),
            this.isSnowEnabled$,
        ).pipe(
            map(([timeout, isEnabled]) => {
                return timeout && isEnabled;
            }),
            shareReplay(),
        );
        this.isWreathVisible$ = this.isWreathEnabled$.asObservable()
            .pipe(
                shareReplay()
            );
        this.isAllow$ = this.store$.select(currentCompanySelector).pipe(
            map((currentCompany) => {
                const now = new Date();

                return AVAILABLE_SUBDOMAINS.includes(currentCompany.subDomain)
                    && ((now.getMonth() === 11 && now.getDate() >= 25) || (now.getMonth() === 0 && now.getDate() <= 14));
            }),
            shareReplay(),
        );
    }
}
