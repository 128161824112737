import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeRu from '@angular/common/locales/ru';
import { ErrorHandler, Injector, NgModule } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatCheckboxDefaultOptions, MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MissingTranslationHandler, TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { UIModule } from '@umbrella-web/attrack-ui-module';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

import { AppComponent } from '@app-client/app.component';
import { AuthModule } from '@app-client/auth/auth.module';
import { LayoutModule } from '@app-client/layout/layout.module';
import { LoggedAsModule } from '@app-client/logged-as/logged-as.module';
import { SENTRY_DSN } from '@app-client/shared/injection-tokens/sentry-dsn';
import { AppPipeModule } from '@app-client/shared/modules/app-pipe/app-pipe.module';
import { AuthGuardService } from '@app-client/shared/services/auth-guard.service';
import { CoreTariffGuardService } from '@app-client/shared/services/core-tariff-guard.service';
import { PersistenceService } from '@app-client/shared/services/persistence.service';
import { ProjectCreateGuardService } from '@app-client/shared/services/project-create-guard.service';
import { RoleGuardService } from '@app-client/shared/services/role-guard.service';
import { SettingsRoleGuardService } from '@app-client/shared/services/settings-role-guard.service';
import { UnauthorizedGuardService } from '@app-client/shared/services/unauthorized-guard.service';
import { UrlService } from '@app-client/shared/services/url.service';
import { UserIsObserverOnlyGuardService } from '@app-client/shared/services/user-is-observer-only-guard.service';
import { WebpackTranslateLoader } from '@app-client/shared/translation-loaders/webpack-translate.loader';
import { AppStoreModule } from '@app-client/store/app-store.module';
import { TourService } from '@app-client/tour/services/tour.service';
import { TourModule } from '@app-client/tour/tour.module';
import { AppErrorHandler } from '@app-shared/handlers/app-error-handler.service';
import { FormErrorsModule } from '@app-shared/modules/form-errors/form-errors.module';
import { MissingTranslationService } from '@app-shared/translator/missing-translation.service';

import { NewYearModule } from './new-year/new-year.module';
import { HappinessModuleGuardService } from './shared/services/happiness-module-guard.service';

import { environment } from '@env-client/environment';


registerLocaleData(localeRu);
registerLocaleData(localeEn);

const supportedLangs = environment.locales;
const browserLang = window.navigator.languages.map(lang => lang.slice(0, 2)).find(lang => supportedLangs.includes(lang));
let localStorageLang: string;

try {
    localStorageLang = JSON.parse(window.localStorage.getItem('Accept-Language'));
    if (!supportedLangs.includes(localStorageLang)) {
        localStorageLang = null;
    }
} catch (ex) {
    console.warn(ex);
    localStorageLang = null;
    window.localStorage.removeItem('Accept-Language');
}

const storedLanguage = (localStorageLang && localStorageLang.length > 0) ? localStorageLang : null;

const options: Partial<IConfig> = null;

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        RouterModule.forRoot([], {scrollPositionRestoration: 'top'}),
        StoreRouterConnectingModule.forRoot(),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: WebpackTranslateLoader,
            },
            compiler: {
                provide: TranslateCompiler,
                useClass: TranslateMessageFormatCompiler,
            },
            missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MissingTranslationService },
            useDefaultLang: true,
            defaultLanguage: storedLanguage || browserLang || environment.defaultLocale || 'en',
        }),
        HttpClientModule,
        AppPipeModule,
        AuthModule,
        LayoutModule,
        UIModule,
        FormErrorsModule,
        AppStoreModule,
        LoggedAsModule,
        NgxMaskModule.forRoot(options),
        TourModule,
        NewYearModule,
    ],
    providers: [
        PersistenceService,
        AuthGuardService,
        UnauthorizedGuardService,
        ProjectCreateGuardService,
        RoleGuardService,
        HappinessModuleGuardService,
        SettingsRoleGuardService,
        UserIsObserverOnlyGuardService,
        CoreTariffGuardService,
        UrlService,
        TourService,
        { provide: MESSAGE_FORMAT_CONFIG, useValue: { locales: supportedLangs } },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        {
            provide: MAT_DATE_FORMATS,
            useValue: Object.assign(Object.assign({}, MAT_MOMENT_DATE_FORMATS), {
                display: {
                    ...MAT_MOMENT_DATE_FORMATS.display,
                    dateInput: 'L',
                },
            }),
        },
        { provide: MAT_DATE_LOCALE, useValue: storedLanguage || browserLang || environment.defaultLocale || 'en' },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
            multi: false,
        },
        { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { color: 'primary' } as MatCheckboxDefaultOptions },
        { provide: SENTRY_DSN, useValue: environment.sentryDsn },
        { provide: ErrorHandler, deps: [SENTRY_DSN, Injector], useClass: AppErrorHandler },
    ],
    bootstrap: [AppComponent],
    exports: [],
})
export class AppModule {}
