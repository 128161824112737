export enum ActionTypes {
    SET_LIST_QUERY_PARAMS = '[Projects] Set list query params',

    GET_LIST = '[Projects] Get list current projects',
    GET_LIST_SUCCESS = '[Projects] Get list current projects success',
    GET_LIST_FAILURE = '[Projects] Get list current projects failure',

    SET_LIST_QUERY_PARAMS_FOR_EDIT_TIME = '[Projects] Set list query params for edit time',

    GET_LIST_FOR_EDIT_TIME = '[Projects] Get list projects for edit time',
    GET_LIST_FOR_EDIT_TIME_SUCCESS = '[Projects] Get list projects for edit time success',
    GET_LIST_FOR_EDIT_TIME_FAILURE = '[Projects] Get list projects for edit time failure',

    GET_ONE = '[Projects] Get one current project',
    GET_ONE_SUCCESS = '[Projects] Get one current project success',
    GET_ONE_FAILURE = '[Projects] Get one current project failure',

    CREATE = '[Projects] Create project',
    CREATE_SUCCESS = '[Projects] Create project success',
    CREATE_FAILURE = '[Projects] Create project failure',

    UPDATE = '[Projects] Update project',
    UPDATE_SUCCESS = '[Projects] Update project success',
    UPDATE_FAILURE = '[Projects] Update project failure',

    ARCHIVE = '[Projects] Archive project',
    ARCHIVE_SUCCESS = '[Projects] Archive project success',
    ARCHIVE_FAILURE = '[Projects] Archive project failure',

    RESTORE = '[Projects] Restore project',
    RESTORE_SUCCESS = '[Projects] Restore project success',
    RESTORE_FAILURE = '[Projects] Restore project failure',
}
