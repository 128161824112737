import { createAction, props } from '@ngrx/store';

import { EffectivityTemplateCreateInterface } from '@app-client/effectivity/types/template/effectivity-template-create.interface';
import { EffectivityTemplateDetailsInterface } from '@app-client/effectivity/types/template/effectivity-template-details.interface';
import { EffectivityTemplateListQueryParamsInterface } from '@app-client/effectivity/types/template/effectivity-template-list-query-params.interface';
import { EffectivityTemplateUpdateInterface } from '@app-client/effectivity/types/template/effectivity-template-update.interface';
import { EffectivityTemplateInterface } from '@app-client/effectivity/types/template/effectivity-template.interface';
import { ActionTypes } from '@app-client/store/effectivity-template/action-types';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';
import { PageResponseInterface } from '@app-shared/types/page-response.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';


export const setEffectivityTemplateQueryParamsAction = createAction(
    ActionTypes.SET_LIST_QUERY_PARAMS,
    props<{ params: EffectivityTemplateListQueryParamsInterface }>()
);

export const getEffectivityTemplateListAction = createAction(
    ActionTypes.GET_LIST,
);

export const getEffectivityTemplateListSuccessAction = createAction(
    ActionTypes.GET_LIST_SUCCESS,
    props<{ response: PageResponseInterface<EffectivityTemplateInterface> }>()
);

export const getEffectivityTemplateListFailureAction = createAction(
    ActionTypes.GET_LIST_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>()
);

export const getEffectivityTemplateDetailsAction = createAction(
    ActionTypes.GET_DETAILS,
    props<{ id: string }>()
);

export const getEffectivityTemplateDetailsSuccessAction = createAction(
    ActionTypes.GET_DETAILS_SUCCESS,
    props<{ response: ResponseInterface<EffectivityTemplateDetailsInterface> }>()
);

export const getEffectivityTemplateDetailsFailureAction = createAction(
    ActionTypes.GET_DETAILS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>()
);

export const createEffectivityTemplateAction = createAction(
    ActionTypes.CREATE,
    props<{ effectivityTemplate: EffectivityTemplateCreateInterface }>()
);

export const createEffectivityTemplateSuccessAction = createAction(
    ActionTypes.CREATE_SUCCESS,
    props<{ response: ResponseInterface<EffectivityTemplateInterface> }>()
);

export const createEffectivityTemplateFailureAction = createAction(
    ActionTypes.CREATE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>()
);

export const updateEffectivityTemplateAction = createAction(
    ActionTypes.UPDATE,
    props<{ id: string, effectivityTemplate: EffectivityTemplateUpdateInterface, successCallback?: () => void }>()
);

export const updateEffectivityTemplateSuccessAction = createAction(
    ActionTypes.UPDATE_SUCCESS,
    props<{ response: ResponseInterface<EffectivityTemplateInterface>, successCallback?: () => void }>()
);

export const updateEffectivityTemplateFailureAction = createAction(
    ActionTypes.UPDATE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>()
);

export const deleteEffectivityTemplateAction = createAction(
    ActionTypes.DELETE,
    props<{ id: string }>()
);

export const deleteEffectivityTemplateSuccessAction = createAction(
    ActionTypes.DELETE_SUCCESS,
);

export const deleteEffectivityTemplateFailureAction = createAction(
    ActionTypes.DELETE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>()
);
