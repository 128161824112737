export enum TimeRequestActionTypes {
    SET_LIST_QUERY_PARAMS = '[TimeRequests] Set requests list query params',

    GET_LIST = '[TimeRequests] Get requests list',
    GET_LIST_SUCCESS = '[TimeRequests] Get requests list success',
    GET_LIST_FAILURE = '[TimeRequests] Get requests list failure',

    APPROVE = '[TimeRequests] Approve request',
    APPROVE_SUCCESS = '[TimeRequests] Approve request success',
    APPROVE_FAILURE = '[TimeRequests] Approve request failure',

    REJECT = '[TimeRequests] Reject request',
    REJECT_SUCCESS = '[TimeRequests] Reject request success',
    REJECT_FAILURE = '[TimeRequests] Reject request failure',
}
