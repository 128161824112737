import { createAction, props } from '@ngrx/store';

import { ActionTypes } from '@app-client/store/auth/action-types';
import { CurrentUserInterface } from '@app-client/store/auth/types/current-user.interface';


export const getCurrentUserAction = createAction(ActionTypes.GET_CURRENT_USER);

export const getCurrentUserSuccessAction = createAction(
    ActionTypes.GET_CURRENT_USER_SUCCESS,
    props<{ currentUser: CurrentUserInterface }>(),
);

export const getCurrentUserFailureAction = createAction(ActionTypes.GET_CURRENT_USER_FAILURE);
