import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { OnboardingDataStatusEnum } from '@app-client/shared/types/onboarding-data-status.enum';
import { currentUserSelector } from '@app-client/store/auth/selectors/auth.selectors';
import { CurrentUserInterface } from '@app-client/store/auth/types/current-user.interface';
import { onboardingStatusSelector } from '@app-client/store/tour/selectors/tour.selector';
import { collapseAnimation } from '@app-shared/animations/collapse.animation';


@UntilDestroy()
@Component({
    selector: 'app-skipped-demo-dialog',
    templateUrl: './skipped-demo-dialog.component.html',
    styleUrls: ['./skipped-demo-dialog.component.scss'],
    animations: [
        collapseAnimation,
    ],
})
export class SkippedDemoDialogComponent implements OnInit {
    readonly OnboardingDataStatus = OnboardingDataStatusEnum;
    currentUser$: Observable<CurrentUserInterface>;
    onboardingDataStatus$: Observable<OnboardingDataStatusEnum>;

    constructor(
        public dialogRef: MatDialogRef<SkippedDemoDialogComponent>,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private store: Store,
    ) {
        this.iconRegistry.addSvgIcon(
            'attrack-skipped-demo-dialog-picture',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/exit-demo-dialog-picture.svg'));
    }

    ngOnInit() {
        this.initializeVariables();
    }

    private initializeVariables() {
        this.currentUser$ = this.store.select(currentUserSelector);
        this.onboardingDataStatus$ = this.store.select(onboardingStatusSelector);
    }

    close(): void {
        this.dialogRef.close(false);
    }

}
