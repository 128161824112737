import { createAction, props } from '@ngrx/store';

import { ActionTypes } from '@app-client/store/user-profile/action-types';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


export const changePasswordAction = createAction(
    ActionTypes.CHANGE_PASSWORD,
    props<{ plainPassword: { first: string, second: string } }>(),
);

export const changePasswordSuccessAction = createAction(
    ActionTypes.CHANGE_PASSWORD_SUCCESS,
);

export const changePasswordFailureAction = createAction(
    ActionTypes.CHANGE_PASSWORD_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
