import { createAction, props } from '@ngrx/store';

import { ReportUsingApplicationsFiltersInterface } from '@app-client/report/using-applications/types/report-using-applications-filters.interface';
import { ReportUsingApplicationsReportInterface } from '@app-client/report/using-applications/types/report-using-applications-report.interface';
import { ReportUsingApplicationActionTypes } from '@app-client/store/report/using-applications/report-using-aplications-types';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


export const reportUsingApplicationsGetReportAction = createAction(
    ReportUsingApplicationActionTypes.GET_REPORT,
    props<{ filters: ReportUsingApplicationsFiltersInterface }>(),
);

export const reportUsingApplicationsGetReportSuccessAction = createAction(
    ReportUsingApplicationActionTypes.GET_REPORT_SUCCESS,
    props<{ report: ReportUsingApplicationsReportInterface, filters: ReportUsingApplicationsFiltersInterface }>(),
);

export const reportUsingApplicationsGetReportFailureAction = createAction(
    ReportUsingApplicationActionTypes.GET_REPORT_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const reportUsingApplicationsClearData = createAction(
    ReportUsingApplicationActionTypes.CLEAR_DATA,
);

export const reportUsingApplicationsExportGoogleSpreadsheetAction = createAction(
    ReportUsingApplicationActionTypes.EXPORT_GOOGLE_SPREADSHEET,
    props<{ filters: ReportUsingApplicationsFiltersInterface }>(),
);

export const reportUsingApplicationsExportGoogleSpreadsheetSuccessAction = createAction(
    ReportUsingApplicationActionTypes.EXPORT_GOOGLE_SPREADSHEET_SUCCESS,
);

export const reportUsingApplicationsExportGoogleSpreadsheetFailureAction = createAction(
    ReportUsingApplicationActionTypes.EXPORT_GOOGLE_SPREADSHEET_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
