export enum SettingsActionTypes {
    GET_COMPANY_SETTINGS = '[Company/Settings] Get company settings',
    GET_COMPANY_SETTINGS_SUCCESS = '[Company/Settings] Get company settings success',
    GET_COMPANY_SETTINGS_FAILURE = '[Company/Settings] Get company settings failure',

    UPDATE_COMPANY_SETTINGS = '[Company/Settings] Update company settings',
    UPDATE_COMPANY_SETTINGS_SUCCESS = '[Company/Settings] Update company settings success',
    UPDATE_COMPANY_SETTINGS_FAILURE = '[Company/Settings] Update company settings failure',

    UPDATE_COMPANY_AVATAR = '[Company/Settings] Update company avatar',
    UPDATE_COMPANY_AVATAR_SUCCESS = '[Company/Settings] Update company avatar success',
    UPDATE_COMPANY_AVATAR_FAILURE = '[Company/Settings] Update company avatar failure',

    GET_COMPANY_DOMAINS = '[Company/Settings] Get company domains',
    GET_COMPANY_DOMAINS_SUCCESS = '[Company/Settings] Get company domains success',
    GET_COMPANY_DOMAINS_FAILURE = '[Company/Settings] Get company domains failure',

    CREATE_COMPANY_DOMAIN = '[Company/Settings] Create company domain',
    CREATE_COMPANY_DOMAIN_SUCCESS = '[Company/Settings] Create company domain success',
    CREATE_COMPANY_DOMAIN_FAILURE = '[Company/Settings] Create company domain failure',

    DELETE_COMPANY_DOMAIN = '[Company/Settings] Delete company domain',
    DELETE_COMPANY_DOMAIN_SUCCESS = '[Company/Settings] Delete company domain success',
    DELETE_COMPANY_DOMAIN_FAILURE = '[Company/Settings] Delete company domain failure',

    GET_COMPANY_CLOSE_PERIOD = '[Company/Settings] Get company close period',
    GET_COMPANY_CLOSE_PERIOD_SUCCESS = '[Company/Settings] Get company close period success',
    GET_COMPANY_CLOSE_PERIOD_FAILURE = '[Company/Settings] Get company close period failure',
}
