import { createReducer, on } from '@ngrx/store';

import {
    effectivityConfigBulkUpdateAction, effectivityConfigBulkUpdateFailureAction, effectivityConfigBulkUpdateSuccessAction,
    getEffectivityConfigDetailsByProjectAndUserAction,
    getEffectivityConfigDetailsFailureAction,
    getEffectivityConfigDetailsSuccessAction,
    setEffectivityConfigByProjectMemberAction,
    setEffectivityConfigFailureAction,
    setEffectivityConfigSuccessAction,
} from '@app-client/store/effectivity-config/actions/effectivity-config.actions';
import { EffectivityConfigStateInterface } from '@app-client/store/effectivity-config/effectivity-config-state.interface';


const initialState: EffectivityConfigStateInterface = {
    effectivityConfigDetails: undefined,
    isSetEffectivityConfigInProgress: false,
    isEffectivityConfigDetailsLoading: false,
    isEffectivityConfigBulkUpdateInProgress: false,
};

const reducer = createReducer(
    initialState,
    on(
        getEffectivityConfigDetailsByProjectAndUserAction
        || getEffectivityConfigDetailsByProjectAndUserAction,
        (state) => ({
            ...state,
            effectivityConfigDetails: null,
            isEffectivityConfigDetailsLoading: true,
        }),
    ),

    on(
        getEffectivityConfigDetailsSuccessAction,
        (state, action) => ({
            ...state,
            effectivityConfigDetails: action.response.body,
            isEffectivityConfigDetailsLoading: false,
        }),
    ),

    on (
        getEffectivityConfigDetailsFailureAction,
        (state) => ({
            ...state,
            isEffectivityConfigDetailsLoading: false,
        }),
    ),

    on(
        setEffectivityConfigByProjectMemberAction,
        (state) => ({
            ...state,
            isSetEffectivityConfigInProgress: true,
        }),
    ),

    on(
        setEffectivityConfigSuccessAction,
        setEffectivityConfigFailureAction,
        (state) => ({
            ...state,
            isSetEffectivityConfigInProgress: false,
        }),
    ),

    on(
        effectivityConfigBulkUpdateAction,
        (state) => ({
            ...state,
            isEffectivityConfigBulkUpdateInProgress: true,
        }),
    ),

    on(
        effectivityConfigBulkUpdateSuccessAction,
        effectivityConfigBulkUpdateFailureAction,
        (state) => ({
            ...state,
            isEffectivityConfigBulkUpdateInProgress: false,
        }),
    ),
);

export const effectivityConfigReducer = (state, action) => reducer(state, action);
