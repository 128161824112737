import { createReducer, on } from '@ngrx/store';

import {
    getCompanyConfigSignatureAction,
    getCompanyConfigSignatureFailureAction,
    getCompanyConfigSignatureSuccessAction,
    updateCompanyConfigSignatureAction,
    updateCompanyConfigSignatureFailureAction,
    updateCompanyConfigSignatureSuccessAction
} from '@app-client/store/company/actions/company-config-signature.action';
import { CompanyConfigStateInterface } from '@app-client/store/company/company-config.interface';


const initialState: CompanyConfigStateInterface = {
    companyConfigSignature:  {
        templateDocumentId: null,
        googleDiskDirectoryId: null,
        googleServiceAccountEmail: null,
    },

    isGetCompanyConfigInProgress: false,

    isUpdateCompanyConfigInProgress: false,
    updateCompanyConfigBackendErrors: null,
};

const reducer = createReducer(
    initialState,
    on(
        getCompanyConfigSignatureAction,
        state => ({
            ...state,
            isGetCompanyConfigInProgress: true,
        }),
    ),
    on(
        getCompanyConfigSignatureSuccessAction,
        (state, action) => ({
            ...state,
            isGetCompanyConfigInProgress: false,
            companyConfigSignature: action.companyConfigSignature,
        }),
    ),
    on(
        getCompanyConfigSignatureFailureAction,
        (state) => ({
            ...state,
            isGetCompanyConfigInProgress: false,
        }),
    ),
    on(
        updateCompanyConfigSignatureAction,
        (state) => ({
            ...state,
            isUpdateCompanyConfigInProgress: true,
        }),
    ),
    on(
        updateCompanyConfigSignatureSuccessAction,
        (state) => ({
            ...state,
            isUpdateCompanyConfigInProgress: false
        }),
    ),
    on(
        updateCompanyConfigSignatureFailureAction,
        (state) => ({
            ...state,
            isUpdateCompanyConfigInProgress: false,
        }),
    ),
);

export function companyConfigSignatureReducer(state, action) {
    return reducer(state, action);
}
