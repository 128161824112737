import { Pipe, PipeTransform } from '@angular/core';

import { EffectivityApplicationStatusEnum } from '@app-client/effectivity/types/app/effectivity-application-status.enum';
import { WindowEffectivityEnum } from '@app-client/store/activity/types/data-chunks/window-effectivity.enum';


@Pipe({
    name: 'effectivityStatusToColor',
})
export class EffectivityStatusToColorPipe implements PipeTransform {
    transform(value: EffectivityApplicationStatusEnum | WindowEffectivityEnum): string {
        switch (value) {
            case EffectivityApplicationStatusEnum.EFFECTIVE:
                return '#C2E380';
            case EffectivityApplicationStatusEnum.INEFFECTIVE:
                return '#FFDFB2';
            case EffectivityApplicationStatusEnum.NEUTRAL:
                return '#F1F0F6';
        }
    }
}
