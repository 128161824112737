import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, zip } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { JiraProjectInterface } from '@app-client/jira/types/filter-types/jira-project.interface';
import { JiraAuthTypeEnum } from '@app-client/jira/types/jira-auth-type.enum';
import {
    jiraAttachAction,
    jiraAttachFailureAction,
    jiraAttachSuccessAction,
    jiraDetachAction,
    jiraDetachFailureAction,
    jiraDetachSuccessAction,
    jiraGetBoardsAction,
    jiraGetBoardsFailureAction,
    jiraGetBoardsSuccessAction,
    jiraGetEpicsAction,
    jiraGetEpicsFailureAction,
    jiraGetEpicsSuccessAction,
    jiraGetIssueTypesAction,
    jiraGetIssueTypesFailureAction,
    jiraGetIssueTypesSuccessAction,
    jiraGetProjectsAction,
    jiraGetProjectsFailureAction,
    jiraGetProjectsSuccessAction,
    jiraGetProjectStatusAction,
    jiraGetProjectStatusFailureAction,
    jiraGetProjectStatusSuccessAction,
    jiraGetSprintsAction,
    jiraGetSprintsFailureAction,
    jiraGetSprintsSuccessAction,
    jiraGetStatusesAction,
    jiraGetStatusesFailureAction,
    jiraGetStatusesSuccessAction, jiraGetSyncStatusAction, jiraGetSyncStatusFailureAction, jiraGetSyncStatusSuccessAction,
    jiraStartSyncAction,
    jiraStartSyncFailureAction,
    jiraStartSyncSuccessAction,
    jiraStartSyncTimeFailureAction,
    jiraStartSyncTimeSuccessAction,
    jiraUpdateAction,
    jiraUpdateFailureAction,
    jiraUpdateSuccessAction,
    jiraUpdateSyncTimeSettingsAction, jiraUpdateSyncTimeSettingsFailureAction, jiraUpdateSyncTimeSettingsSuccessAction,
} from '@app-client/store/jira/actions/jira-connection.actions';
import { jiraConnectionsSelector } from '@app-client/store/jira/selectors/jira-auth.selectors';
import { JiraConnectionService } from '@app-client/store/jira/services/jira-connection.service';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class JiraConnectionEffect {
    constructor(
        private connectionService: JiraConnectionService,
        private actions$: Actions,
        private translate: TranslateService,
        private toastr: ToastrService,
        private store$: Store,
    ) {}

    getProjectStatusEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraGetProjectStatusAction),
            switchMap(({ projectId }) => {
                return this.connectionService.getProjectStatus(projectId).pipe(
                    map((projectStatus) => {
                        return jiraGetProjectStatusSuccessAction({ projectStatus });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(jiraGetProjectStatusFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getProjectStatusFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraGetProjectStatusFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('JIRA.CONNECTION.NOTIFICATIONS.GET_PROJECT_STATUS.FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    getProjectsEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraGetProjectsAction),
            withLatestFrom(this.store$.select(jiraConnectionsSelector)),
            switchMap(([{ connectionId }, connections]) => {
                let request: Observable<Array<JiraProjectInterface>>;
                const connectionObject = connections.find((connection) => {
                    return connection.id === connectionId;
                });
                if (connectionObject.authType === JiraAuthTypeEnum.OAUTH_1) {
                    request = zip(
                        this.connectionService.checkPermissions(connectionId),
                        this.connectionService.getProjects(connectionId),
                    ).pipe(
                        map(([{ getProject }, projects]) => {
                            if (!getProject) {
                                throw new HttpErrorResponse({
                                    error: {
                                        message: this.translate.instant('JIRA.CONNECTION.NOTIFICATIONS.GET_PROJECTS.FAILURE.MESSAGE.WRONG_SETTINGS'),
                                    }
                                });
                            }
                            return projects;
                        }),
                    );
                } else {
                    request = this.connectionService.getProjects(connectionId).pipe(
                        catchError((httpErrorResponse: HttpErrorResponse) => {
                            if (httpErrorResponse?.error?.detail === 'Jira API Token is not specified.') {
                                throw new HttpErrorResponse({
                                    error: {
                                        message: this.translate.instant('JIRA.CONNECTION.NOTIFICATIONS.GET_PROJECTS.FAILURE.MESSAGE.TOKEN_NOT_FOUND'),
                                    }
                                });
                            } else {
                                throw new HttpErrorResponse({ ...httpErrorResponse });
                            }
                        }),
                    );
                }

                return request.pipe(
                    map((projects)  => {
                        return jiraGetProjectsSuccessAction({ projects });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(jiraGetProjectsFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getProjectsFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraGetProjectsFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('JIRA.CONNECTION.NOTIFICATIONS.GET_PROJECTS.FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    getStatusesEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraGetStatusesAction),
            switchMap(({ connectionId, projectKey }) => {
                return this.connectionService.getStatuses(connectionId, projectKey).pipe(
                    map((statuses) => {
                        return jiraGetStatusesSuccessAction({ statuses });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(jiraGetStatusesFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getStatusesFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraGetStatusesFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('JIRA.CONNECTION.NOTIFICATIONS.GET_STATUSES.FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    getIssueTypesEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraGetIssueTypesAction),
            switchMap(({ connectionId, projectKey }) => {
                return this.connectionService.getIssueTypes(connectionId, projectKey).pipe(
                    map((issueTypes) => {
                        return jiraGetIssueTypesSuccessAction({ issueTypes });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(jiraGetIssueTypesFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getIssueTypesFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraGetIssueTypesFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('JIRA.CONNECTION.NOTIFICATIONS.GET_ISSUE_TYPES.FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    getBoardsEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraGetBoardsAction),
            switchMap(({ connectionId, projectKey }) => {
                return this.connectionService.getBoards(connectionId, projectKey).pipe(
                    map((boards) => {
                        return jiraGetBoardsSuccessAction({ boards });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(jiraGetBoardsFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getBoardsFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraGetBoardsFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('JIRA.CONNECTION.NOTIFICATIONS.GET_BOARDS.FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    getEpicsEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraGetEpicsAction),
            switchMap(({ connectionId, boardIds }) => {
                return this.connectionService.getEpics(connectionId, boardIds).pipe(
                    map((epics) => {
                        return jiraGetEpicsSuccessAction({ epics });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(jiraGetEpicsFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getEpicsFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraGetEpicsFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('JIRA.CONNECTION.NOTIFICATIONS.GET_EPICS.FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    getSprintsEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraGetSprintsAction),
            switchMap(({ connectionId, boardIds }) => {
                return this.connectionService.getSprints(connectionId, boardIds).pipe(
                    map((sprints) => {
                        return jiraGetSprintsSuccessAction({ sprints });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(jiraGetSprintsFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getSprintsFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraGetSprintsFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('JIRA.CONNECTION.NOTIFICATIONS.GET_SPRINTS.FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    attachProjectEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraAttachAction),
            switchMap(({ projectId, filters }) => {
                return this.connectionService.attachProject(projectId, filters).pipe(
                    map(() => {
                        return jiraAttachSuccessAction({ projectId });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(jiraAttachFailureAction({ projectId, backendErrors }));
                    }),
                );
            }),
        );
    });

    attachProjectSuccessEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraAttachSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('JIRA.CONNECTION.NOTIFICATIONS.ATTACH.SUCCESS.MESSAGE'),
                    this.translate.instant('JIRA.CONNECTION.NOTIFICATIONS.ATTACH.SUCCESS.TITLE'),
                );
            }),
            map(({ projectId }) => {
                return jiraGetProjectStatusAction({ projectId });
            }),
        );
    });

    attachProjectFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraAttachFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('JIRA.CONNECTION.NOTIFICATIONS.ATTACH.FAILURE.TITLE'),
                );
            }),
            map(({ projectId }) => {
                return jiraGetProjectStatusAction({ projectId });
            }),
        );
    });

    detachProjectProjectEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraDetachAction),
            switchMap(({ projectId }) => {
                return this.connectionService.detachProject(projectId).pipe(
                    map(() => {
                        return jiraDetachSuccessAction({ projectId });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(jiraDetachFailureAction({ projectId, backendErrors }));
                    }),
                );
            }),
        );
    });

    detachProjectSuccessEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraDetachSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('JIRA.CONNECTION.NOTIFICATIONS.DETACH.SUCCESS.MESSAGE'),
                    this.translate.instant('JIRA.CONNECTION.NOTIFICATIONS.DETACH.SUCCESS.TITLE'),
                );
            }),
            map(({ projectId }) => {
                return jiraGetProjectStatusAction({ projectId });
            }),
        );
    });

    detachProjectFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraDetachFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('JIRA.CONNECTION.NOTIFICATIONS.DETACH.FAILURE.TITLE'),
                );
            }),
            map(({ projectId }) => {
                return jiraGetProjectStatusAction({ projectId });
            }),
        );
    });


    updateEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraUpdateAction),
            switchMap(({ projectId, filters }) => {
                return this.connectionService.updateProject(projectId, filters).pipe(
                    map(() => {
                        return jiraUpdateSuccessAction({ projectId });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(jiraUpdateFailureAction({ projectId, backendErrors }));
                    }),
                );
            }),
        );
    });

    updateProjectSuccessEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraUpdateSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('JIRA.CONNECTION.NOTIFICATIONS.UPDATE.SUCCESS.TITLE'),
                    this.translate.instant('JIRA.CONNECTION.NOTIFICATIONS.UPDATE.SUCCESS.TITLE'),
                );
            }),
            map(({ projectId }) => {
                return jiraGetProjectStatusAction({ projectId });
            }),
        );
    });

    updateProjectFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraUpdateFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('JIRA.CONNECTION.NOTIFICATIONS.UPDATE.FAILURE.TITLE'),
                );
            }),
            map(({ projectId }) => {
                return jiraGetProjectStatusAction({ projectId });
            }),
        );
    });


    startSyncEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraStartSyncAction),
            switchMap(({ projectId }) => {
                return this.connectionService.startSync(projectId).pipe(
                    map(() => {
                        return jiraStartSyncSuccessAction();
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(jiraStartSyncFailureAction({ projectId, backendErrors }));
                    }),
                );
            }),
        );
    });

    getSyncStatusEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraGetSyncStatusAction),
            switchMap(({ projectId }) => {
                return this.connectionService.getSyncStatus(projectId).pipe(
                    map((syncStatus) => {
                        return jiraGetSyncStatusSuccessAction({ syncStatus });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        this.toastr.error(
                            backendErrors.message,
                            this.translate.instant('JIRA.CONNECTION.SYNC_TIME.NOTIFICATIONS.FAILURE.TITLE'),
                        );

                        return of(jiraGetSyncStatusFailureAction({ projectId, backendErrors }));
                    }),
                );
            })
        );
    });

    updateSyncTimeSettingsEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraUpdateSyncTimeSettingsAction),
            switchMap(({ projectId, syncMemo, sumTimeByTask, from, to}) => {
                return this.connectionService.updateSyncTimeSettings(projectId, syncMemo, sumTimeByTask).pipe(
                    map(() => {
                        return jiraUpdateSyncTimeSettingsSuccessAction({ projectId, from, to });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(jiraUpdateSyncTimeSettingsFailureAction({ projectId, backendErrors }));
                    }),
                );
            }),
        );
    });

    updateSyncTimeSettingsFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraUpdateSyncTimeSettingsFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('JIRA.CONNECTION.SYNC_TIME.NOTIFICATIONS.FAILURE.TITLE'),
                );
            }),
            map(({ projectId }) => {
                return jiraGetProjectStatusAction({ projectId });
            }),
        );
    });

    startSyncTimeEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraUpdateSyncTimeSettingsSuccessAction),
            switchMap(({projectId, from, to}) => {
                return this.connectionService.startSyncTime(projectId, from, to).pipe(
                    map(() => {
                        return jiraStartSyncTimeSuccessAction({ projectId });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(jiraStartSyncTimeFailureAction({ projectId, backendErrors }));
                    }),
                );
            }),
        );
    });
}
