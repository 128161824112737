import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import {
    createCompanyDomainAction,
    createCompanyDomainFailureAction,
    createCompanyDomainSuccessAction,
    deleteCompanyDomainAction,
    deleteCompanyDomainFailureAction,
    deleteCompanyDomainSuccessAction,
    getCompanyClosePeriodAction,
    getCompanyClosePeriodFailureAction,
    getCompanyClosePeriodSuccessAction,
    getCompanyDomainsAction,
    getCompanyDomainsFailureAction,
    getCompanyDomainsSuccessAction,
    getCompanySettingsAction,
    getCompanySettingsFailureAction,
    getCompanySettingsSuccessAction,
    updateCompanyAvatarAction,
    updateCompanyAvatarFailureAction,
    updateCompanyAvatarSuccessAction,
    updateCompanySettingsAction,
    updateCompanySettingsFailureAction,
    updateCompanySettingsSuccessAction,
} from '@app-client/store/company/actions/company-settings.action';
import { CompanySettingsService } from '@app-client/store/company/services/company-settings.service';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class CompanySettingsEffect {
    getCompanySettings$ = createEffect(() => {
        return this.actions.pipe(
            ofType(getCompanySettingsAction),
            switchMap(() => {
                return this.companySettingsService.getCompanySettings().pipe(
                    map((companySettings) => {
                        return getCompanySettingsSuccessAction({ companySettings });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(getCompanySettingsFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getCompanySettingsFailure$ = createEffect(() => {
        return this.actions.pipe(
            ofType(getCompanySettingsFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('COMPANY_SETTINGS.COMPANY.COMPANY_DATA.NOTIFICATIONS.GET_COMPANY_DATA_FAIL.TITLE'),
                );

                // redirect, because the target page will not be available without this data
                this.router.navigate(['/']);
            }),
        );
    }, { dispatch: false });

    // -------------------------------------------------------------------------------------------------------------------------------------

    updateCompanySettings$ = createEffect(() => {
        return this.actions.pipe(
            ofType(updateCompanySettingsAction),
            switchMap(({ companySettings }) => {
                return this.companySettingsService.updateCompanySettings(companySettings).pipe(
                    map((updatedCompanySettings) => {
                        return updateCompanySettingsSuccessAction({ companySettings: updatedCompanySettings });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(updateCompanySettingsFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    updateCompanySettingsSuccess$ = createEffect(() => {
        return this.actions.pipe(
            ofType(updateCompanySettingsSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('COMPANY_SETTINGS.COMPANY.COMPANY_DATA.NOTIFICATIONS.UPDATE_COMPANY_DATA.SUCCESS.MESSAGE'),
                    this.translate.instant('COMPANY_SETTINGS.COMPANY.COMPANY_DATA.NOTIFICATIONS.UPDATE_COMPANY_DATA.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    updateCompanySettingsFailure$ = createEffect(() => {
        return this.actions.pipe(
            ofType(updateCompanySettingsFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('COMPANY_SETTINGS.COMPANY.COMPANY_DATA.NOTIFICATIONS.UPDATE_COMPANY_DATA.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    // -------------------------------------------------------------------------------------------------------------------------------------

    updateCompanyAvatar$ = createEffect(() => {
        return this.actions.pipe(
            ofType(updateCompanyAvatarAction),
            switchMap(({ avatar }) => {
                return this.companySettingsService.updateCompanyAvatar(avatar).pipe(
                    map((updatedCompanySettings) => {
                        return updateCompanyAvatarSuccessAction({ companySettings: updatedCompanySettings });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(updateCompanyAvatarFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    updateCompanyAvatarSuccess$ = createEffect(() => {
        return this.actions.pipe(
            ofType(updateCompanyAvatarSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('COMPANY_SETTINGS.COMPANY.LOGO.NOTIFICATIONS.UPDATE_AVATAR.SUCCESS.MESSAGE'),
                    this.translate.instant('COMPANY_SETTINGS.COMPANY.LOGO.NOTIFICATIONS.UPDATE_AVATAR.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    updateCompanyAvatarFailure$ = createEffect(() => {
        return this.actions.pipe(
            ofType(updateCompanyAvatarFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('COMPANY_SETTINGS.COMPANY.LOGO.NOTIFICATIONS.UPDATE_AVATAR.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    // -------------------------------------------------------------------------------------------------------------------------------------

    getCompanyDomains$ = createEffect(() => {
        return this.actions.pipe(
            ofType(getCompanyDomainsAction),
            switchMap(() => {
                return this.companySettingsService.getCompanyDomains().pipe(
                    map((domains) => {
                        return getCompanyDomainsSuccessAction({ domains });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(getCompanyDomainsFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getCompanyDomainsFailure$ = createEffect(() => {
        return this.actions.pipe(
            ofType(getCompanyDomainsFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('COMPANY_SETTINGS.COMPANY.DOMAINS.NOTIFICATIONS.GET_DOMAINS.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    // -------------------------------------------------------------------------------------------------------------------------------------

    createCompanyDomain$ = createEffect(() => {
        return this.actions.pipe(
            ofType(createCompanyDomainAction),
            switchMap(({ name, successCallback }) => {
                return this.companySettingsService.createCompanyDomain({ name }).pipe(
                    map((createdDomain) => {
                        return createCompanyDomainSuccessAction({ createdDomain, successCallback });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(createCompanyDomainFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    createCompanyDomainSuccess$ = createEffect(() => {
        return this.actions.pipe(
            ofType(createCompanyDomainSuccessAction),
            tap(({ successCallback }) => {
                if (successCallback && typeof successCallback === 'function') {
                    successCallback();
                }

                this.toastr.success(
                    this.translate.instant('COMPANY_SETTINGS.COMPANY.DOMAINS.NOTIFICATIONS.CREATE_DOMAIN.SUCCESS.MESSAGE'),
                    this.translate.instant('COMPANY_SETTINGS.COMPANY.DOMAINS.NOTIFICATIONS.CREATE_DOMAIN.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    createCompanyDomainFailure$ = createEffect(() => {
        return this.actions.pipe(
            ofType(createCompanyDomainFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('COMPANY_SETTINGS.COMPANY.DOMAINS.NOTIFICATIONS.CREATE_DOMAIN.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    // -------------------------------------------------------------------------------------------------------------------------------------

    deleteCompanyDomain$ = createEffect(() => {
        return this.actions.pipe(
            ofType(deleteCompanyDomainAction),
            switchMap(({ id }) => {
                return this.companySettingsService.deleteCompanyDomain(id).pipe(
                    map((createdDomain) => {
                        return deleteCompanyDomainSuccessAction({ id });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(deleteCompanyDomainFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    deleteCompanyDomainSuccess$ = createEffect(() => {
        return this.actions.pipe(
            ofType(deleteCompanyDomainSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('COMPANY_SETTINGS.COMPANY.DOMAINS.NOTIFICATIONS.DELETE_DOMAIN.SUCCESS.MESSAGE'),
                    this.translate.instant('COMPANY_SETTINGS.COMPANY.DOMAINS.NOTIFICATIONS.DELETE_DOMAIN.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    deleteCompanyDomainFailure$ = createEffect(() => {
        return this.actions.pipe(
            ofType(deleteCompanyDomainFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('COMPANY_SETTINGS.COMPANY.DOMAINS.NOTIFICATIONS.DELETE_DOMAIN.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    // -------------------------------------------------------------------------------------------------------------------------------------

    getCompanyClosePeriod$ = createEffect(() => {
        return this.actions.pipe(
            ofType(getCompanyClosePeriodAction),
            switchMap(() => {
                return this.companySettingsService.getCompanyClosePeriod().pipe(
                    map((date) => {
                        return getCompanyClosePeriodSuccessAction({ closePeriod: date });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(getCompanyClosePeriodFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getCompanyClosePeriodFailure$ = createEffect(() => {
        return this.actions.pipe(
            ofType(getCompanyClosePeriodFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('COMPANY_SETTINGS.COMPANY.CLOSE_PERIOD.NOTIFICATIONS.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    constructor(
        private actions: Actions,
        private companySettingsService: CompanySettingsService,
        private toastr: ToastrService,
        private translate: TranslateService,
        private router: Router,
    ) {}
}
