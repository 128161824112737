import { createAction, props } from '@ngrx/store';

import { OnboardingDataStatusEnum } from '@app-client/shared/types/onboarding-data-status.enum';
import { TourActionTypes } from '@app-client/store/tour/tour-action-types';
import { WelcomeDemoDialogData } from '@app-client/tour/components/welcome-demo-dialog/welcome-demo-dialog.component';
import { TourSectionsEnum } from '@app-client/tour/types/tour-sections.enum';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


export const startTourSectionAction = createAction(
    TourActionTypes.SET_TOUR_SECTION,
    props<{ section: TourSectionsEnum | null,
            showCompleteDialog?: boolean,
            loadDemoData?: boolean,
            finishTutorialAfterThisSection?: boolean
    }>(),
);

export const nextTourStepAction = createAction(
    TourActionTypes.NEXT_TOUR_STEP,
);

export const endTourAction = createAction(
    TourActionTypes.END_TOUR,
);

export const sendOnboardingStatusStartedAction = createAction(
    TourActionTypes.TOUR_SEND_STATUS_STARTED,
);

export const onboardingStatusRestartedAction = createAction(
    TourActionTypes.TOUR_STATUS_RESTARTED,
);

export const sendOnboardingStatusStartedSuccessAction = createAction(
    TourActionTypes.TOUR_SEND_STATUS_STARTED_SUCCESS,
);

export const sendOnboardingStatusStartedFailedAction = createAction(
    TourActionTypes.TOUR_SEND_STATUS_STARTED_FAILED,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const sendOnboardingStatusFinishedAction = createAction(
    TourActionTypes.TOUR_SEND_STATUS_FINISHED,
);

export const sendOnboardingStatusFinishedSuccessAction = createAction(
    TourActionTypes.TOUR_SEND_STATUS_FINISHED_SUCCESS,
);

export const sendOnboardingStatusFinishedFailedAction = createAction(
    TourActionTypes.TOUR_SEND_STATUS_FINISHED_FAILED,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const loadCompanyOnboardingDataAction = createAction(
    TourActionTypes.LOAD_COMPANY_ONBOARDING_DATA,
);

export const loadCompanyOnboardingDataSuccessAction = createAction(
    TourActionTypes.LOAD_COMPANY_ONBOARDING_DATA_SUCCESS,
);

export const loadCompanyOnboardingDataFailedAction = createAction(
    TourActionTypes.LOAD_COMPANY_ONBOARDING_DATA_FAILED,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const deleteCompanyOnboardingDataAction = createAction(
    TourActionTypes.DELETE_COMPANY_ONBOARDING_DATA,
);

export const deleteCompanyOnboardingDataSuccessAction = createAction(
    TourActionTypes.DELETE_COMPANY_ONBOARDING_DATA_SUCCESS,
);

export const deleteCompanyOnboardingDataFailedAction = createAction(
    TourActionTypes.DELETE_COMPANY_ONBOARDING_DATA_FAILED,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const checkCompanyOnboardingDataAction = createAction(
    TourActionTypes.CHECK_COMPANY_ONBOARDING_DATA,
);

export const checkCompanyOnboardingDataSuccessAction = createAction(
    TourActionTypes.CHECK_COMPANY_ONBOARDING_DATA_SUCCESS,
    props<{ status: OnboardingDataStatusEnum }>(),
);

export const checkCompanyOnboardingDataFailedAction = createAction(
    TourActionTypes.CHECK_COMPANY_ONBOARDING_DATA_FAILED,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const openWelcomeDialogAction = createAction(
    TourActionTypes.TOUR_OPEN_WELCOME_DIALOG,
    props<{ data: WelcomeDemoDialogData }>(),
);

export const openRestartDialogAction = createAction(
    TourActionTypes.TOUR_OPEN_RESTART_DIALOG,
);

export const disableDailyResultSandbox = createAction(
    TourActionTypes.DISABLE_DAILY_RESULT_SANDBOX,
);

export const enableDailyResultSandbox = createAction(
    TourActionTypes.ENABLE_DAILY_RESULT_SANDBOX,
);