import { HttpErrorResponse } from '@angular/common/http';
import { Compiler, Injectable, Injector } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import {
    signatureUnsignedIntervalsGetListAction,
    signatureUnsignedIntervalsGetListFailureAction,
    signatureUnsignedIntervalsGetListSuccessAction,
    signatureUnsignedIntervalsSignAction,
    signatureUnsignedIntervalsSignFailureAction,
    signatureUnsignedIntervalsSignSuccessAction,
} from '@app-client/store/signature/unsiged-intervals/signature-unsigned-intervals.actions';
import { SignatureUnsignedIntervalsService } from '@app-client/store/signature/unsiged-intervals/signature-unsigned-intervals.service';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class SignatureUnsignedIntervalsEffects {

    constructor(
        private actions$: Actions,
        private signatureUnsignedIntervalsService: SignatureUnsignedIntervalsService,
        private compiler: Compiler,
        private injector: Injector,
        private toastr: ToastrService,
        private translate: TranslateService,
    ) {}

    getListEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(signatureUnsignedIntervalsGetListAction),
            switchMap(() => {
                return this.signatureUnsignedIntervalsService.getList().pipe(
                    map((list) => {
                        return signatureUnsignedIntervalsGetListSuccessAction({ list });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse.error.message,
                            errors: httpErrorResponse.error.errors,
                        };

                        return of(signatureUnsignedIntervalsGetListFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getListSuccessEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(signatureUnsignedIntervalsGetListSuccessAction),
            tap(async ({ list }) => {
                if (list.length > 0) {
                    const { SignatureModule } = await import('@app-client/signature/signature.module');
                    const moduleFactory = await this.compiler.compileModuleAsync(SignatureModule);
                    const moduleRef = moduleFactory.create(this.injector);
                    moduleRef.instance.init(list);
                }
            }),
        );
    }, { dispatch: false });

    signEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(signatureUnsignedIntervalsSignAction),
            switchMap(({ signRequest }) => {
                return this.signatureUnsignedIntervalsService.sign(signRequest).pipe(
                    map(() => {
                        return signatureUnsignedIntervalsSignSuccessAction();
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse.error.message,
                            errors: httpErrorResponse.error.errors,
                        };

                        return of(signatureUnsignedIntervalsSignFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    signFailureEffect = createEffect(() => {
        return this.actions$.pipe(
            ofType(signatureUnsignedIntervalsSignFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('SIGNATURE.NOTIFICATIONS.FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });
}
