import { createFeatureSelector, createSelector } from '@ngrx/store';

import { TimeRequestStateInterface } from '@app-client/store/time-request/time-request.state.interface';
import { AuthStateInterface } from '@app-client/store/auth/auth-state.interface';
import { ProjectRoleEnum } from '@app-client/project/types/project-role.enum';

export const authFeatureSelector = createFeatureSelector<AuthStateInterface>('auth');

export const timeRequestsFeatureSelector = createFeatureSelector<TimeRequestStateInterface>('timeRequests');

export const timeRequestListQueryParamsSelector = createSelector(
    timeRequestsFeatureSelector,
    state => state.listQueryParams,
);

export const timeRequestListSelector = createSelector(
    timeRequestsFeatureSelector,
    state => state.timeRequestList,
);

export const isTimeRequestListLoadingSelector = createSelector(
    timeRequestsFeatureSelector,
    state => state.isListLoading,
);

export const hasMoreTimeRequestsSelector = createSelector(
    timeRequestsFeatureSelector,
    state => state.listHasLoadingMore,
);

export const isApproveRejectInProgressSelector = createSelector(
    timeRequestsFeatureSelector,
    state => state.isApproveRejectInProgress,
);

export const currentUserIsManagerSelector = createSelector(
    authFeatureSelector,
    (state: AuthStateInterface) => state.currentUser?.projectRoles.includes(ProjectRoleEnum.ROLE_MANAGER),
);