import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EffectivityAppListQueryParamsInterface } from '@app-client/effectivity/types/app/effectivity-app-list-query-params.interface';
import { TrackApplicationInterface } from '@app-client/effectivity/types/app/track-application.interface';
import { EffectivityCategoryCreateInterface } from '@app-client/effectivity/types/category/effectivity-category-create.interface';
import { EffectivityCategoryUpdateInterface } from '@app-client/effectivity/types/category/effectivity-category-update-interface';
import { EffectivityCategoryInterface } from '@app-client/effectivity/types/category/effectivity-category.interface';
import { EffectivitySubcategoryCreateInterface } from '@app-client/effectivity/types/subcategory/effectivity-subcategory-create.interface';
import { EffectivitySubcategoryUpdateInterface } from '@app-client/effectivity/types/subcategory/effectivity-subcategory-update-interface';
import { EffectivitySubcategoryInterface } from '@app-client/effectivity/types/subcategory/effectivity-subcategory.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';

import { environment } from '@env-client/environment';


@Injectable()
export class EffectivityCategoryService {
    private readonly colorMap = [
        '#85DAE5',
        '#A3D3FF',
        '#91A1FA',
        '#D0C4EB',
        '#E8B1B7',
        '#FCE9A7',
        '#ECF6AA',
        '#D0F3EB',
        '#99C0E4',
        '#99A5D2',
        '#D0A3E4',
        '#DBBECF',
        '#FA9079',
        '#FDFFA9',
        '#C5E3BA',
        '#C6FFFC',
        '#81B3FF',
        '#A7B5E8',
        '#FFD9FB',
        '#FFD8D8',
        '#E39F89',
        '#EED67F',
        '#D0EBFE',
        '#7E82E7',
        '#C4A7FF',
        '#FFADAD',
    ];

    constructor(
        private http: HttpClient,
    ) {}

    getEffectivityCategoryList(): Observable<ResponseInterface<Array<EffectivityCategoryInterface>>> {
        return this.http.get<ResponseInterface<Array<EffectivityCategoryInterface>>>(
            environment.apiBaseUrl + '/effectivity/categories'
        ).pipe(
            map((body) => {
                body.body.forEach((item, index) => {
                    item.color = this.colorMap[index % this.colorMap.length];
                });
                return body;
            }),
        );
    }

    createEffectivityCategory(
        newEffectivityCategory: EffectivityCategoryCreateInterface,
    ): Observable<ResponseInterface<EffectivityCategoryInterface>> {
        return this.http.post<ResponseInterface<EffectivityCategoryInterface>>(
            environment.apiBaseUrl + '/effectivity/categories', newEffectivityCategory
        );
    }

    updateEffectivityCategory(
        id: string,
        updatedEffectivityCategory: EffectivityCategoryUpdateInterface,
    ): Observable<ResponseInterface<EffectivityCategoryInterface>> {
        return this.http.patch<ResponseInterface<EffectivityCategoryInterface>>(
            environment.apiBaseUrl + '/effectivity/categories/' + id, updatedEffectivityCategory
        );
    }

    removeEffectivityCategory(id: string): Observable<ResponseInterface<null>> {
        return this.http.delete<ResponseInterface<null>>(
            environment.apiBaseUrl + '/effectivity/categories/' + id
        );
    }

    createEffectivitySubcategory(
        newEffectivitySubcategory: EffectivitySubcategoryCreateInterface,
    ): Observable<ResponseInterface<EffectivitySubcategoryInterface>> {
        return this.http.post<ResponseInterface<EffectivitySubcategoryInterface>>(
            environment.apiBaseUrl + '/effectivity/subcategories', newEffectivitySubcategory
        );
    }

    updateEffectivitySubcategory(
        id: string,
        updatedEffectivitySubcategory: EffectivitySubcategoryUpdateInterface,
    ): Observable<ResponseInterface<EffectivitySubcategoryInterface>> {
        return this.http.patch<ResponseInterface<EffectivitySubcategoryInterface>>(
            environment.apiBaseUrl + '/effectivity/subcategories/' + id, updatedEffectivitySubcategory
        );
    }

    removeEffectivitySubcategory(id: string): Observable<ResponseInterface<null>> {
        return this.http.delete<ResponseInterface<null>>(
            environment.apiBaseUrl + '/effectivity/subcategories/' + id
        );
    }

    public getAppList(
        queryParams: EffectivityAppListQueryParamsInterface,
    ): Observable<ResponseInterface<Array<TrackApplicationInterface>>> {
        const params = new HttpParams()
            .set('categorized', queryParams.categorized.toString());

        return this.http.get<ResponseInterface<Array<TrackApplicationInterface>>>(
            environment.apiBaseUrl + '/applications', { params }
        ).pipe(
            map((response) => {
                return {
                    ...response,
                    body: response.body.map((item) => {
                        return {
                            ...item,
                            icon: item.icon ?? item.url ? '/assets/images/icons/site-icon.svg' : '/assets/images/icons/app-icon.svg',
                        };
                    }),
                };
            })
        );
    }

    public batchUpdateSubcategory(
        subcategoryId: string,
        apps: Array<number>,
    ): Observable<ResponseInterface<null>> {
        return this.http.patch<ResponseInterface<null>>(
            environment.apiBaseUrl + '/applications/batch-update-subcategory',
            {
                apps,
                subcategoryId,
            }
        );
    }
}
