import { createAction, props } from '@ngrx/store';

import { InvoicingInvoicesActionTypesEnum } from '@app-client/store/invoicing/invoices/invoicing-invoices-action-types.enum';
import { InvoicingInvoicesCalculateRequestInterface } from '@app-client/store/invoicing/invoices/types/invoicing-invoices-calculate-request.interface';
import { InvoicingInvoicesDetailsResponseInterface } from '@app-client/store/invoicing/invoices/types/invoicing-invoices-details-response.interface';
import { InvoicingInvoicesListItemResponseInterface } from '@app-client/store/invoicing/invoices/types/invoicing-invoices-list-item-response.interface';
import { InvoicingInvoicesListRequestParamsInterface } from '@app-client/store/invoicing/invoices/types/invoicing-invoices-list-request-params.interface';
import { InvoicingInvoicesRecalculateInterface } from '@app-client/store/invoicing/invoices/types/invoicing-invoices-recalculate.interface';
import { InvoicingInvoicesSaveRequestInterface } from '@app-client/store/invoicing/invoices/types/invoicing-invoices-save-request.interface';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';
import { PageResponseInterface } from '@app-shared/types/page-response.interface';


export const invoicingInvoicesGetListAction = createAction(
    InvoicingInvoicesActionTypesEnum.GET_LIST,
    props<{ requestParams: InvoicingInvoicesListRequestParamsInterface }>(),
);
export const invoicingInvoicesGetListSuccessAction = createAction(
    InvoicingInvoicesActionTypesEnum.GET_LIST_SUCCESS,
    props<{ response: PageResponseInterface<InvoicingInvoicesListItemResponseInterface> }>(),
);
export const invoicingInvoicesGetListFailureAction = createAction(
    InvoicingInvoicesActionTypesEnum.GET_LIST_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const invoicingInvoicesGenerateAction = createAction(
    InvoicingInvoicesActionTypesEnum.GENERATE,
    props<{ settings: InvoicingInvoicesCalculateRequestInterface }>(),
);
export const invoicingInvoicesGenerateSuccessAction = createAction(
    InvoicingInvoicesActionTypesEnum.GENERATE_SUCCESS,
    props<{ response: InvoicingInvoicesDetailsResponseInterface, settings: InvoicingInvoicesCalculateRequestInterface }>(),
);
export const invoicingInvoicesGenerateFailureAction = createAction(
    InvoicingInvoicesActionTypesEnum.GENERATE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const invoicingInvoicesRecalculateAction = createAction(
    InvoicingInvoicesActionTypesEnum.RECALCULATE,
    props<{ settings: InvoicingInvoicesRecalculateInterface }>(),
);
export const invoicingInvoicesRecalculateSuccessAction = createAction(
    InvoicingInvoicesActionTypesEnum.RECALCULATE_SUCCESS,
    props<{ response: InvoicingInvoicesDetailsResponseInterface}>(),
);
export const invoicingInvoicesRecalculateFailureAction = createAction(
    InvoicingInvoicesActionTypesEnum.RECALCULATE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const invoicingInvoicesClearCalculateAction = createAction(
    InvoicingInvoicesActionTypesEnum.CLEAR_CALCULATE,
);

export const invoicingInvoicesSaveAction = createAction(
    InvoicingInvoicesActionTypesEnum.SAVE,
    props<{ body: InvoicingInvoicesSaveRequestInterface }>(),
);
export const invoicingInvoicesSaveSuccessAction = createAction(
    InvoicingInvoicesActionTypesEnum.SAVE_SUCCESS,
    props<{ response: InvoicingInvoicesDetailsResponseInterface }>(),
);
export const invoicingInvoicesSaveFailureAction = createAction(
    InvoicingInvoicesActionTypesEnum.SAVE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const invoicingInvoicesUpdateAction = createAction(
    InvoicingInvoicesActionTypesEnum.UPDATE,
    props<{ id: string, body: InvoicingInvoicesSaveRequestInterface }>(),
);
export const invoicingInvoicesUpdateSuccessAction = createAction(
    InvoicingInvoicesActionTypesEnum.UPDATE_SUCCESS,
    props<{ response: InvoicingInvoicesDetailsResponseInterface }>(),
);
export const invoicingInvoicesUpdateFailureAction = createAction(
    InvoicingInvoicesActionTypesEnum.UPDATE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const invoicingInvoicesDeleteAction = createAction(
    InvoicingInvoicesActionTypesEnum.DELETE,
    props<{ id: string }>(),
);
export const invoicingInvoicesDeleteSuccessAction = createAction(
    InvoicingInvoicesActionTypesEnum.DELETE_SUCCESS,
);
export const invoicingInvoicesDeleteFailureAction = createAction(
    InvoicingInvoicesActionTypesEnum.DELETE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const invoicingInvoicesGetDetailsAction = createAction(
    InvoicingInvoicesActionTypesEnum.GET_DETAILS,
    props<{ id: string }>(),
);
export const invoicingInvoicesGetDetailsSuccessAction = createAction(
    InvoicingInvoicesActionTypesEnum.GET_DETAILS_SUCCESS,
    props<{ response: InvoicingInvoicesDetailsResponseInterface }>(),
);
export const invoicingInvoicesGetDetailsFailureAction = createAction(
    InvoicingInvoicesActionTypesEnum.GET_DETAILS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const invoicingInvoicesExportToGoogleSheetAction = createAction(
    InvoicingInvoicesActionTypesEnum.EXPORT_TO_GOOGLE_SHEET,
    props<{ id: string }>(),
);
export const invoicingInvoicesExportToGoogleSheetSuccessAction = createAction(
    InvoicingInvoicesActionTypesEnum.EXPORT_TO_GOOGLE_SHEET_SUCCESS,
);
export const invoicingInvoicesExportToGoogleSheetFailureAction = createAction(
    InvoicingInvoicesActionTypesEnum.EXPORT_TO_GOOGLE_SHEET_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
