import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import { deleteCompanyOnboardingDataAction } from '@app-client/store/tour/actions/tour.action';
import { collapseAnimation } from '@app-shared/animations/collapse.animation';


@UntilDestroy()
@Component({
    selector: 'app-delete-demo-dialog',
    templateUrl: './delete-demo-dialog.component.html',
    styleUrls: ['./delete-demo-dialog.component.scss'],
    animations: [
        collapseAnimation,
    ],
})
export class DeleteDemoDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<DeleteDemoDialogComponent>,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private store: Store,
    ) {
        this.iconRegistry.addSvgIcon(
            'attrack-skipped-demo-dialog-picture',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/exit-demo-dialog-picture.svg'));
    }

    submit() {
        this.store.dispatch(deleteCompanyOnboardingDataAction());
        this.close();
    }

    close(): void {
        this.dialogRef.close(false);
    }

}
