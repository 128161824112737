import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import {
    effectivityConfigBulkUpdateAction, effectivityConfigBulkUpdateFailureAction, effectivityConfigBulkUpdateSuccessAction,
    getEffectivityConfigDetailsByProjectAndUserAction,
    getEffectivityConfigDetailsByProjectMemberAction,
    getEffectivityConfigDetailsFailureAction,
    getEffectivityConfigDetailsSuccessAction,
    setEffectivityConfigByProjectMemberAction, setEffectivityConfigFailureAction, setEffectivityConfigSuccessAction,
} from '@app-client/store/effectivity-config/actions/effectivity-config.actions';
import { EffectivityConfigService } from '@app-client/store/effectivity-config/services/effectivity-config.service';
import {
    getProjectMemberListAction,
    setProjectMembersListQueryParamsAction,
} from '@app-client/store/project-members/actions/project-members.action';
import {
    projectMemberListQueryParamsSelector,
} from '@app-client/store/project-members/selectors/project-members.selector';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class EffectivityConfigEffects {
    constructor(
        private actions$: Actions,
        private effectivityConfigService: EffectivityConfigService,
        private toastr: ToastrService,
        private translate: TranslateService,
        private store$: Store,
    ) {}

    getEffectivityConfigDetailsByProjectAndUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getEffectivityConfigDetailsByProjectAndUserAction),
            switchMap(({ projectId, userId }) => {
                return this.effectivityConfigService.getEffectivityConfigDetailsByProjectAndUser(projectId, userId).pipe(
                    map(response => {
                        return getEffectivityConfigDetailsSuccessAction({ response });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            errors: httpErrorResponse.error.errors,
                            message: httpErrorResponse.error.message,
                        };

                        return of(getEffectivityConfigDetailsFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getEffectivityConfigDetailsByProjectMember$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getEffectivityConfigDetailsByProjectMemberAction),
            switchMap(({ projectMemberId }) => {
                return this.effectivityConfigService.getEffectivityConfigDetailsByProjectMember( projectMemberId).pipe(
                    map(response => {
                        return getEffectivityConfigDetailsSuccessAction({ response });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            errors: httpErrorResponse.error.errors,
                            message: httpErrorResponse.error.message,
                        };

                        return of(getEffectivityConfigDetailsFailureAction( { backendErrors }));
                    }),
                );
            }),
        );
    });

    getEffectivityConfigDetailsFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getEffectivityConfigDetailsFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('EFFECTIVITY_CONFIG.NOTIFICATIONS.GET_ONE.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    setEffectivityConfig$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(setEffectivityConfigByProjectMemberAction),
            switchMap(({ projectMemberId, config, successCallback }) => {
                return this.effectivityConfigService.setEffectivityConfigByProjectMember(projectMemberId, config).pipe(
                    map(() => {
                        return setEffectivityConfigSuccessAction({ successCallback });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            errors: httpErrorResponse.error.errors,
                            message: httpErrorResponse.error.message,
                        };

                        return of(setEffectivityConfigFailureAction( { backendErrors }));
                    }),
                );
            }),
        );
    });

    setEffectivityConfigSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(setEffectivityConfigSuccessAction),
            tap(({ successCallback }) => {
                this.toastr.success(
                    this.translate.instant('EFFECTIVITY_CONFIG.NOTIFICATIONS.SET.SUCCESS.MESSAGE'),
                    this.translate.instant('EFFECTIVITY_CONFIG.NOTIFICATIONS.SET.SUCCESS.TITLE'),
                );
                if (!!successCallback) {
                    successCallback();
                }
            }),
        );
    }, { dispatch: false });

    setEffectivityConfigFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(setEffectivityConfigFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('EFFECTIVITY_CONFIG.NOTIFICATIONS.SET.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    effectivityConfigBulkUpdate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(effectivityConfigBulkUpdateAction),
            switchMap(({ bulkUpdate }) => {
                return this.effectivityConfigService.effectivityConfigBulkUpdate(bulkUpdate).pipe(
                    map(() => {
                        return effectivityConfigBulkUpdateSuccessAction();
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            errors: httpErrorResponse.error.errors,
                            message: httpErrorResponse.error.message,
                        };

                        return of(effectivityConfigBulkUpdateFailureAction( { backendErrors }));
                    }),
                );
            }),
        );
    });

    effectivityConfigBulkUpdateSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(effectivityConfigBulkUpdateSuccessAction),
            withLatestFrom(this.store$.select(projectMemberListQueryParamsSelector)),
            switchMap(([, listParams]) => {
                this.toastr.success(
                    this.translate.instant('EFFECTIVITY_CONFIG.NOTIFICATIONS.BULK_UPDATE.SUCCESS.MESSAGE'),
                    this.translate.instant('EFFECTIVITY_CONFIG.NOTIFICATIONS.BULK_UPDATE.SUCCESS.TITLE'),
                );
                return of(
                    setProjectMembersListQueryParamsAction({
                        queryParams: {
                            ...listParams,
                            page: 1,
                        }
                    }),
                    getProjectMemberListAction(),
                );
            }),
        );
    });

    effectivityConfigBulkUpdateFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(effectivityConfigBulkUpdateFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('EFFECTIVITY_CONFIG.NOTIFICATIONS.BULK_UPDATE.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });
}
