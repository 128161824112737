import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import {
    marketplaceAccountChargeAction, marketplaceAccountChargeFailureAction, marketplaceAccountChargeSuccessAction,
    marketplaceAccountPayAction, marketplaceAccountPayFailureAction, marketplaceAccountPaySuccessAction,
    marketplaceCardsGetInitKeyAction, marketplaceCardsGetInitKeyFailureAction, marketplaceCardsGetInitKeySuccessAction,
    marketplaceCardsGetListAction,
    marketplaceCardsGetListFailureAction,
    marketplaceCardsGetListSuccessAction,
    marketplaceCardsRemoveAction, marketplaceCardsRemoveFailureAction,
    marketplaceCardsRemoveSuccessAction,
    marketplaceGetAccountAction,
    marketplaceGetAccountFailureAction,
    marketplaceGetAccountSuccessAction,
} from '@app-client/store/marketplace/account/actions/marketplace-account.actions';
import { MarketplaceAccountService } from '@app-client/store/marketplace/account/services/marketplace-account.service';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class MarketplaceAccountEffects {
    constructor(
        private actions$: Actions,
        private toastr: ToastrService,
        private translate: TranslateService,
        private marketplaceAccountService: MarketplaceAccountService,
    ) {}

    getAccountEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(marketplaceGetAccountAction),
            switchMap(() => {
                return this.marketplaceAccountService.getMarketplaceAccountData().pipe(
                    map((account) => {
                        return marketplaceGetAccountSuccessAction({ account });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(marketplaceGetAccountFailureAction({ backendErrors }));
                    }),
                );
            })
        );
    });

    getCardsListEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(marketplaceCardsGetListAction),
            switchMap(() => {
                return this.marketplaceAccountService.getCards().pipe(
                    map((cards) => {
                        return marketplaceCardsGetListSuccessAction({ cards });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(marketplaceCardsGetListFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    removeCardEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(marketplaceCardsRemoveAction),
            switchMap(({ id }) => {
                return this.marketplaceAccountService.removeCard(id).pipe(
                    map(() => {
                        return marketplaceCardsRemoveSuccessAction({ id });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(marketplaceCardsRemoveFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    removeCardSuccessEffect = createEffect(() => {
        return this.actions$.pipe(
            ofType(marketplaceCardsRemoveSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('MARKETPLACE.PAYMENT.OVERVIEW.REMOVE_CARD.NOTIFICATIONS.SUCCESS.MESSAGE'),
                    this.translate.instant('MARKETPLACE.PAYMENT.OVERVIEW.REMOVE_CARD.NOTIFICATIONS.SUCCESS.TITLE'),
                );
            }),
            map(() => {
                return marketplaceCardsGetListAction();
            }),
        );
    });

    removeCardFailureEffect = createEffect(() => {
        return this.actions$.pipe(
            ofType(marketplaceCardsRemoveFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('MARKETPLACE.PAYMENT.OVERVIEW.REMOVE_CARD.NOTIFICATIONS.FAILURE.TITLE'),
                );
            }),
            map(() => {
                return marketplaceCardsGetListAction();
            }),
        );
    });

    getInitKeyEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(marketplaceCardsGetInitKeyAction),
            switchMap(() => {
                return this.marketplaceAccountService.getInitKey().pipe(
                    map((initKey) => {
                        return marketplaceCardsGetInitKeySuccessAction({ initKey });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(marketplaceCardsGetInitKeyFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    payEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(marketplaceAccountPayAction),
            switchMap(({ cardId, amount }) => {
                return this.marketplaceAccountService.pay(cardId, amount).pipe(
                    map((resultAmount) => {
                        return marketplaceAccountPaySuccessAction({ payInfo: resultAmount });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(marketplaceAccountPayFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    payFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(marketplaceAccountPayFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('MARKETPLACE.PAYMENT.OVERVIEW.ADD_MONEY.NOTIFICATIONS.FAILURE.TITLE'),
                );
            }),
            map(() => {
                return marketplaceGetAccountAction();
            }),
        );
    });

    chargeEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(marketplaceAccountChargeAction),
            switchMap(({ amount }) => {
                return this.marketplaceAccountService.charge(amount).pipe(
                    map((chargeSecret) => {
                        return marketplaceAccountChargeSuccessAction({ chargeSecret });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(marketplaceAccountChargeFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getCardsListFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(marketplaceCardsGetListFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('MARKETPLACE.PAYMENT.OVERVIEW.ADD_CARD.NOTIFICATIONS.FAILURE.TITLE'),
                );
            }),
            map(() => {
                return marketplaceGetAccountAction();
            }),
        );
    });
}
