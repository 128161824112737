<div class="page page_login">
    <div class="login-form__wrapper">
        <div class="login-form__container">
            <ng-container *ngIf="company$ | async">
                <div class="login-form__logo-container">
                    <img class="login-form__logo" [src]="logoUrl$ | async" alt="App" />
                    <ng-container *ngIf="false === (isTrackerDomainDetected$ | async)">
                        <span class="login-form__logo__text">{{ 'LOGIN.FOR_COMPANY' | translate:{ company: (company$ | async).name } }}</span>
                    </ng-container>
                </div>

                <router-outlet></router-outlet>

            </ng-container>
            <ng-container *ngIf="null === (company$ | async)">
                <div class="company-not-found">{{ 'COMPANY.NOT_FOUND_MESSAGE' | translate }}</div>
            </ng-container>
        </div>
    </div>
</div>
