import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import {
    marketplaceInvoicesGetDetailsAction,
    marketplaceInvoicesGetDetailsFailureAction,
    marketplaceInvoicesGetDetailsSuccessAction,
    marketplaceInvoicesGetListAction,
    marketplaceInvoicesGetListFailureAction,
    marketplaceInvoicesGetListSuccessAction,
    marketplaceInvoicesGetPdfAction,
    marketplaceInvoicesGetPdfFailureAction,
    marketplaceInvoicesGetPdfSuccessAction,
    marketplaceInvoicesPayAction,
    marketplaceInvoicesPayFailureAction,
    marketplaceInvoicesPaySuccessAction
} from '@app-client/store/marketplace/invoices/actions/marketplace-invoices.actions';
import { MarketplaceInvoicesService } from '@app-client/store/marketplace/invoices/services/marketplace-invoices.service';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class MarketplaceInvoicesEffect {
    constructor(
        private actions: Actions,
        private marketplaceInvoicesService: MarketplaceInvoicesService,
        private toastr: ToastrService,
        private translate: TranslateService,
    ) {}

    getListEffect$ = createEffect(() => {
        return this.actions.pipe(
            ofType(marketplaceInvoicesGetListAction),
            switchMap(() => {
                return this.marketplaceInvoicesService.getList().pipe(
                    map((list) => {
                        return marketplaceInvoicesGetListSuccessAction({ list });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(marketplaceInvoicesGetListFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getListFailureEffect$ = createEffect(() => {
        return this.actions.pipe(
            ofType(marketplaceInvoicesGetListFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('MARKETPLACE.PAYMENT.INVOICES.NOTIFICATIONS.GET_LIST.FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    getDetailsEffect$ = createEffect(() => {
        return this.actions.pipe(
            ofType(marketplaceInvoicesGetDetailsAction),
            switchMap(({ id }) => {
                return this.marketplaceInvoicesService.getDetails(id).pipe(
                    map((details) => {
                        return marketplaceInvoicesGetDetailsSuccessAction({ details });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(marketplaceInvoicesGetDetailsFailureAction({ backendErrors }));
                    }),
                );
            })
        );
    });

    getDetailsFailureEffect$ = createEffect(() => {
        return this.actions.pipe(
            ofType(marketplaceInvoicesGetDetailsFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('MARKETPLACE.PAYMENT.INVOICES.NOTIFICATIONS.GET_DETAILS.FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    payEffect$ = createEffect(() => {
        return this.actions.pipe(
            ofType(marketplaceInvoicesPayAction),
            switchMap(({ id }) => {
                return this.marketplaceInvoicesService.pay(id).pipe(
                    map(() => {
                        return marketplaceInvoicesPaySuccessAction({ id });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(marketplaceInvoicesPayFailureAction({ backendErrors, id }));
                    }),
                );
            })
        );
    });

    paySuccessEffect$ = createEffect(() => {
        return this.actions.pipe(
            ofType(marketplaceInvoicesPaySuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('MARKETPLACE.PAYMENT.INVOICES.NOTIFICATIONS.PAY_DIALOG.SUCCESS.MESSAGE'),
                    this.translate.instant('MARKETPLACE.PAYMENT.INVOICES.NOTIFICATIONS.PAY_DIALOG.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    payFailureEffect$ = createEffect(() => {
        return this.actions.pipe(
            ofType(marketplaceInvoicesPayFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('MARKETPLACE.PAYMENT.INVOICES.NOTIFICATIONS.PAY_DIALOG.FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    getPdfEffect$ = createEffect(() => {
        return this.actions.pipe(
            ofType(marketplaceInvoicesGetPdfAction),
            switchMap(({ id }) => {
                return this.marketplaceInvoicesService.getPdf(id).pipe(
                    map((response) => {
                        return marketplaceInvoicesGetPdfSuccessAction({ response });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(marketplaceInvoicesGetPdfFailureAction({ backendErrors, id }));
                    }),
                );
            })
        );
    });

    getPdfSuccessEffect$ = createEffect(() => {
        return this.actions.pipe(
            ofType(marketplaceInvoicesGetPdfSuccessAction),
            tap(({response}) => {
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(response.data);
                downloadLink.setAttribute('download', response.filename);
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }),
        );
    }, { dispatch: false });

    getPdfFailureEffect$ = createEffect(() => {
        return this.actions.pipe(
            ofType(marketplaceInvoicesGetPdfFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('MARKETPLACE.PAYMENT.INVOICES.NOTIFICATIONS.GET_PDF.FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });
}
