import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CompanyTrackingConfigInterface } from '@app-client/store/auth/types/company-tracking-config.interface';
import { CredentialsInterface } from '@app-client/store/auth/types/credentials.interface';
import { CurrentCompanyInterface } from '@app-client/store/auth/types/current-company.interface';
import { CurrentUserInterface } from '@app-client/store/auth/types/current-user.interface';
import { UserResponseInterface } from '@app-client/user/types/user-response.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';

import { environment } from '@env-client/environment';


@Injectable()
export class AuthService {
    constructor(private httpClient: HttpClient) {}

    getCurrentUser(): Observable<CurrentUserInterface> {
        const url = environment.apiBaseUrl + '/me';

        return this.httpClient.get(url).pipe(map((response: ResponseInterface<UserResponseInterface>) => {
            return this.transformResponse(response.body);
        }));
    }

    getCurrentCompany(subDomain): Observable<CurrentCompanyInterface> {
        const url = 'https://' + environment.apiAuthUrl + '/api/resolve-company-by-domain';
        const params = (new HttpParams()).set('subDomain', subDomain);

        return this.httpClient.get(url, { params }).pipe(map((response: ResponseInterface<CurrentCompanyInterface>) => {
            return response.body;
        }));
    }

    getCompanyTrackingSettings(): Observable<CompanyTrackingConfigInterface> {
        const url = environment.apiBaseUrl + '/trk-config';

        return this.httpClient.get(url).pipe(map((response: ResponseInterface<CompanyTrackingConfigInterface>) => {
            return response.body;
        }));
    }

    refreshToken(refreshToken: string): Observable<CredentialsInterface> {
        const url = environment.apiBaseUrl + '/auth/refresh-token';
        const params = (new HttpParams()).set('refresh_token', refreshToken);

        return this.httpClient.get<CredentialsInterface>(url, { params });
    }

    login(loginCredentials: { username: string, password: string }) {
        const url = environment.apiBaseUrl + '/auth/login';

        return this.httpClient.post<CredentialsInterface>(url, loginCredentials);
    }

    changePasswordRequest(email: string) {
        const url = environment.apiBaseUrl + '/auth/change-password/request';

        return this.httpClient.post(url, { email });
    }

    changePasswordValidate(token: string): Observable<{ email: string }> {
        const url = environment.apiBaseUrl + '/auth/change-password/validate';

        return this.httpClient.post(url, { token }).pipe(
            map((response: ResponseInterface<{ email: string }>) => {
                return response.body;
            }),
        );
    }

    changePasswordSaveNewPassword(token: string, plainPassword: { first: string, second: string }) {
        const url = environment.apiBaseUrl + '/auth/change-password/submit';

        return this.httpClient.post(url, { plainPassword, token });
    }

    loginUnderUser(id: string): Observable<CredentialsInterface> {
        const url = environment.apiBaseUrl + '/users/' + id + '/credentials';

        return this.httpClient.get(url).pipe(
            map((response: ResponseInterface<CredentialsInterface>) => {
                return response.body;
            }),
        );
    }

    private transformResponse(response: UserResponseInterface): CurrentUserInterface {
        const permissions = {};
        if (response.hasOwnProperty('permissions')) {
            response.permissions.map(v => {
                permissions[v] = true;
            });
        }
        return {
            ...response,
            permissions
        } as CurrentUserInterface;
    }
}
