import { createAction, props } from '@ngrx/store';

import { TrelloActionTypes } from '@app-client/store/trello/trello-action-types';
import { TrelloBoardColumnSettingsInterface } from '@app-client/trello/types/trello-board-column-settings.interface';
import { TrelloBoardColumnInterface } from '@app-client/trello/types/trello-board-column.interface';
import { TrelloBoardInterface } from '@app-client/trello/types/trello-board.interface';
import { TrelloProjectStatusInterface } from '@app-client/trello/types/trello-project-status.interface';
import { TrelloUserTokenDataInterface } from '@app-client/trello/types/trello-user-token-data.interface';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


export const trelloModuleDisabledAction = createAction(
    TrelloActionTypes.MODULE_DISABLED,
);

export const trelloAuthRequestAction = createAction(
    TrelloActionTypes.AUTH_REQUEST,
    props<{ redirectUrl: string }>(),
);
export const trelloAuthRequestSuccessAction = createAction(
    TrelloActionTypes.AUTH_REQUEST_SUCCESS,
    props<{ url: string }>(),
);
export const trelloAuthRequestFailureAction = createAction(
    TrelloActionTypes.AUTH_REQUEST_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const trelloAuthVerifyAction = createAction(
    TrelloActionTypes.AUTH_VERIFY,
    props<{ token: string }>(),
);
export const trelloAuthVerifySuccessAction = createAction(
    TrelloActionTypes.AUTH_VERIFY_SUCCESS,
);
export const trelloAuthVerifyFailureAction = createAction(
    TrelloActionTypes.AUTH_VERIFY_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const trelloGetUserTokenDataAction = createAction(
    TrelloActionTypes.GET_USER_TOKEN_DATA,
);
export const trelloGetUserTokenDataSuccessAction = createAction(
    TrelloActionTypes.GET_USER_TOKEN_DATA_SUCCESS,
    props<{ userTokenData: TrelloUserTokenDataInterface }>(),
);
export const trelloGetUserTokenDataFailureAction = createAction(
    TrelloActionTypes.GET_USER_TOKEN_DATA_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const trelloLogoutAction = createAction(
    TrelloActionTypes.LOGOUT,
);
export const trelloLogoutSuccessAction = createAction(
    TrelloActionTypes.LOGOUT_SUCCESS,
);
export const trelloLogoutFailureAction = createAction(
    TrelloActionTypes.AUTH_VERIFY_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const trelloGetProjectStatusAction = createAction(
    TrelloActionTypes.GET_PROJECT_STATUS,
    props<{ projectId: string }>(),
);
export const trelloGetProjectStatusSuccessAction = createAction(
    TrelloActionTypes.GET_PROJECT_STATUS_SUCCESS,
    props<{ projectId: string, projectStatus: TrelloProjectStatusInterface }>(),
);
export const trelloGetProjectStatusFailureAction = createAction(
    TrelloActionTypes.GET_PROJECT_STATUS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const trelloGetUserBoardsAction = createAction(
    TrelloActionTypes.GET_USER_BOARDS,
);
export const trelloGetUserBoardsSuccessAction = createAction(
    TrelloActionTypes.GET_USER_BOARDS_SUCCESS,
    props<{ boards: TrelloBoardInterface[] }>(),
);
export const trelloGetUserBoardsFailureAction = createAction(
    TrelloActionTypes.GET_USER_BOARDS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const trelloGetBoardColumnsAction = createAction(
    TrelloActionTypes.GET_BOARD_COLUMNS,
    props<{ boardId: string }>(),
);
export const trelloGetBoardColumnsSuccessAction = createAction(
    TrelloActionTypes.GET_BOARD_COLUMNS_SUCCESS,
    props<{ columns: TrelloBoardColumnInterface[] }>(),
);
export const trelloGetBoardColumnsFailureAction = createAction(
    TrelloActionTypes.GET_BOARD_COLUMNS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const trelloGetProjectColumnsAction = createAction(
    TrelloActionTypes.GET_PROJECT_COLUMNS,
    props<{ projectId: string }>(),
);
export const trelloGetProjectColumnsSuccessAction = createAction(
    TrelloActionTypes.GET_PROJECT_COLUMNS_SUCCESS,
    props<{ columns: TrelloBoardColumnInterface[] }>(),
);
export const trelloGetProjectColumnsFailureAction = createAction(
    TrelloActionTypes.GET_PROJECT_COLUMNS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface, projectId: string }>(),
);

export const trelloAttachBoardToProjectAction = createAction(
    TrelloActionTypes.ATTACH_BOARD_TO_PROJECT,
    props<{
        projectId: string,
        boardId: string,
        columnSettings: TrelloBoardColumnSettingsInterface
    }>(),
);
export const trelloAttachBoardToProjectSuccessAction = createAction(
    TrelloActionTypes.ATTACH_BOARD_TO_PROJECT_SUCCESS,
    props<{ projectId: string }>(),
);
export const trelloAttachBoardToProjectFailureAction = createAction(
    TrelloActionTypes.ATTACH_BOARD_TO_PROJECT_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const trelloUpdateProjectColumnSettingsAction = createAction(
    TrelloActionTypes.UPDATE_PROJECT_COLUMN_SETTINGS,
    props<{ projectId: string, columnSettings: TrelloBoardColumnSettingsInterface }>(),
);
export const trelloUpdateProjectColumnSettingsSuccessAction = createAction(
    TrelloActionTypes.UPDATE_PROJECT_COLUMN_SETTINGS_SUCCESS,
    props<{ projectId: string }>(),
);
export const trelloUpdateProjectColumnSettingsFailureAction = createAction(
    TrelloActionTypes.UPDATE_PROJECT_COLUMN_SETTINGS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface, projectId: string }>(),
);

export const trelloDetachBoardFromProjectAction = createAction(
    TrelloActionTypes.DETACH_BOARD_FROM_PROJECT,
    props<{ projectId: string }>(),
);
export const trelloDetachBoardFromProjectSuccessAction = createAction(
    TrelloActionTypes.DETACH_BOARD_FROM_PROJECT_SUCCESS,
    props<{ projectId: string }>(),
);
export const trelloDetachBoardFromProjectFailureAction = createAction(
    TrelloActionTypes.DETACH_BOARD_FROM_PROJECT_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const trelloStartSyncAction = createAction(
    TrelloActionTypes.START_SYNC,
    props<{ projectId: string }>(),
);
export const trelloStartSyncSuccessAction = createAction(
    TrelloActionTypes.START_SYNC_SUCCESS,
    props<{ projectId: string }>(),
);
export const trelloStartSyncFailureAction = createAction(
    TrelloActionTypes.START_SYNC_FAILURE,
    props<{ backendErrors: BackendErrorsInterface, projectId: string }>(),
);

export const trelloStopSyncAction = createAction(
    TrelloActionTypes.STOP_SYNC,
    props<{ projectId: string }>(),
);
export const trelloStopSyncSuccessAction = createAction(
    TrelloActionTypes.STOP_SYNC_SUCCESS,
    props<{ projectId: string }>(),
);
export const trelloStopSyncFailureAction = createAction(
    TrelloActionTypes.STOP_SYNC_FAILURE,
    props<{ backendErrors: BackendErrorsInterface, projectId: string }>(),
);
