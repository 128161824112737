import { createReducer, on } from '@ngrx/store';

import {
    getCompanyConfigKaitenAction,
    getCompanyConfigKaitenFailureAction,
    getCompanyConfigKaitenSuccessAction,
    updateCompanyConfigKaitenAction,
    updateCompanyConfigKaitenFailureAction,
    updateCompanyConfigKaitenSuccessAction
} from '@app-client/store/company/actions/company-config-kaiten.action';
import { CompanyConfigStateInterface } from '@app-client/store/company/company-config.interface';


const initialState: CompanyConfigStateInterface = {
    companyConfigKaiten:  {
        kaitenCompanyDomain: null
    },

    isGetCompanyConfigInProgress: false,

    isUpdateCompanyConfigInProgress: false,
    updateCompanyConfigBackendErrors: null,
};

const reducer = createReducer(
    initialState,
    on(
        getCompanyConfigKaitenAction,
        state => ({
            ...state,
            isGetCompanyConfigInProgress: true,
        }),
    ),
    on(
        getCompanyConfigKaitenSuccessAction,
        (state, action) => ({
            ...state,
            isGetCompanyConfigInProgress: false,
            companyConfigKaiten: action.companyConfigKaiten,
        }),
    ),
    on(
        getCompanyConfigKaitenFailureAction,
        (state) => ({
            ...state,
            isGetCompanyConfigInProgress: false,
        }),
    ),
    on(
        updateCompanyConfigKaitenAction,
        (state) => ({
            ...state,
            isUpdateCompanyConfigInProgress: true,
        }),
    ),
    on(
        updateCompanyConfigKaitenSuccessAction,
        (state) => ({
            ...state,
            isUpdateCompanyConfigInProgress: false
        }),
    ),
    on(
        updateCompanyConfigKaitenFailureAction,
        (state) => ({
            ...state,
            isUpdateCompanyConfigInProgress: false,
        }),
    ),
);

export function companyConfigKaitenReducer(state, action) {
    return reducer(state, action);
}
