import { createAction, props } from '@ngrx/store';

import { TimeRequestActionTypes } from '@app-client/store/time-request/time-request-action-types';
import { TimeRequestQueryParamsInterface } from '@app-client/time-request/types/time-request-query-params.interface';
import { TimeRequestInterface } from '@app-client/time-request/types/time-request.interface';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';
import { PageResponseInterface } from '@app-shared/types/page-response.interface';


export const setTimeRequestListQueryParamsAction = createAction(
    TimeRequestActionTypes.SET_LIST_QUERY_PARAMS,
    props<{ params: TimeRequestQueryParamsInterface }>(),
);
export const getTimeRequestListAction = createAction(
    TimeRequestActionTypes.GET_LIST,
);
export const getTimeRequestListSuccessAction = createAction(
    TimeRequestActionTypes.GET_LIST_SUCCESS,
    props<{ response: PageResponseInterface<TimeRequestInterface> }>(),
);
export const getTimeRequestListFailureAction = createAction(
    TimeRequestActionTypes.GET_LIST_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const approveTimeRequestAction = createAction(
    TimeRequestActionTypes.APPROVE,
    props<{ id: number, time: string, user: string, successCallback?: () => void }>(),
);
export const approveTimeRequestSuccessAction = createAction(
    TimeRequestActionTypes.APPROVE_SUCCESS,
    props<{ time: string, user: string, successCallback?: () => void }>(),
);
export const approveTimeRequestFailureAction = createAction(
    TimeRequestActionTypes.APPROVE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const rejectTimeRequestAction = createAction(
    TimeRequestActionTypes.REJECT,
    props<{ id: number, comment: string, successCallback?: () => void }>(),
);
export const rejectTimeRequestSuccessAction = createAction(
    TimeRequestActionTypes.REJECT_SUCCESS,
    props<{ successCallback?: () => void }>(),
);
export const rejectTimeRequestFailureAction = createAction(
    TimeRequestActionTypes.REJECT_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
