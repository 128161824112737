import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import {
    invoicingCustomersCreateAction,
    invoicingCustomersCreateFailureAction,
    invoicingCustomersCreateSuccessAction,
    invoicingCustomersDeleteAction,
    invoicingCustomersDeleteFailureAction,
    invoicingCustomersDeleteSuccessAction,
    invoicingCustomersGetDetailsAction,
    invoicingCustomersGetDetailsFailureAction,
    invoicingCustomersGetDetailsSuccessAction,
    invoicingCustomersGetListAction,
    invoicingCustomersGetListFailureAction,
    invoicingCustomersGetListSuccessAction,
    invoicingCustomersUpdateAction,
    invoicingCustomersUpdateFailureAction,
    invoicingCustomersUpdateSuccessAction,
} from '@app-client/store/invoicing/customers/actions/invoicing-customers.actions';
import { InvoicingCustomersService } from '@app-client/store/invoicing/customers/services/invoicing-customers.service';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class InvoicingCustomersEffects {
    constructor(
        private actions$: Actions,
        private invoicingCustomersService: InvoicingCustomersService,
        private router: Router,
        private translate: TranslateService,
        private toastr: ToastrService,
    ) {}

    getListEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingCustomersGetListAction),
            switchMap(({ requestParams }) => {
                return this.invoicingCustomersService.getList(requestParams).pipe(
                    map((response) => {
                        return invoicingCustomersGetListSuccessAction({ response });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(invoicingCustomersGetListFailureAction({ backendErrors }));
                    }),
                );
            })
        );
    });

    getListFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingCustomersGetListFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('INVOICING.CUSTOMERS.NOTIFICATIONS.GET_LIST.FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    getDetailsEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingCustomersGetDetailsAction),
            switchMap(({ id }) => {
                return this.invoicingCustomersService.getDetails(id).pipe(
                    map((response) => {
                        return invoicingCustomersGetDetailsSuccessAction({ response });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(invoicingCustomersGetDetailsFailureAction({ backendErrors }));
                    }),
                );
            })
        );
    });

    getDetailsFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingCustomersGetDetailsFailureAction),
            tap(({ backendErrors }) => {
                this.router.navigate(['invoicing', 'customers']);
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('INVOICING.CUSTOMERS.NOTIFICATIONS.GET_DETAILS.FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    createEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingCustomersCreateAction),
            switchMap(({ data }) => {
                return this.invoicingCustomersService.create(data).pipe(
                    map((id) => {
                        return invoicingCustomersCreateSuccessAction({ id });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(invoicingCustomersCreateFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    createSuccessEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingCustomersCreateSuccessAction),
            tap(({ id }) => {
                this.router.navigate(['invoicing', 'customers', id]);
                this.toastr.success(
                    this.translate.instant('INVOICING.CUSTOMERS.NOTIFICATIONS.CREATE.SUCCESS.MESSAGE'),
                    this.translate.instant('INVOICING.CUSTOMERS.NOTIFICATIONS.CREATE.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    createFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingCustomersCreateFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('INVOICING.CUSTOMERS.NOTIFICATIONS.CREATE.FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    updateEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingCustomersUpdateAction),
            switchMap(({ id, data }) => {
                return this.invoicingCustomersService.update(id, data).pipe(
                    map((id) => {
                        return invoicingCustomersUpdateSuccessAction({ id });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(invoicingCustomersUpdateFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    updateSuccessEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingCustomersUpdateSuccessAction),
            tap(({ id }) => {
                this.router.navigate(['invoicing', 'customers', id]);
                this.toastr.success(
                    this.translate.instant('INVOICING.CUSTOMERS.NOTIFICATIONS.UPDATE.SUCCESS.MESSAGE'),
                    this.translate.instant('INVOICING.CUSTOMERS.NOTIFICATIONS.UPDATE.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    updateFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingCustomersUpdateFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('INVOICING.CUSTOMERS.NOTIFICATIONS.UPDATE.FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    deleteEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingCustomersDeleteAction),
            switchMap(({ id }) => {
                return this.invoicingCustomersService.delete(id).pipe(
                    map(() => {
                        return invoicingCustomersDeleteSuccessAction();
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(invoicingCustomersDeleteFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    deleteSuccessEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingCustomersDeleteSuccessAction),
            tap(() => {
                this.router.navigate(['invoicing', `customers`]);
                this.toastr.success(
                    this.translate.instant('INVOICING.CUSTOMERS.NOTIFICATIONS.DELETE.SUCCESS.MESSAGE'),
                    this.translate.instant('INVOICING.CUSTOMERS.NOTIFICATIONS.DELETE.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    deleteFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingCustomersDeleteFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('INVOICING.CUSTOMERS.NOTIFICATIONS.DELETE.FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });
}
