import { createReducer, on } from '@ngrx/store';

import {
    trelloAttachBoardToProjectAction,
    trelloAttachBoardToProjectFailureAction,
    trelloAttachBoardToProjectSuccessAction,
    trelloAuthRequestAction,
    trelloAuthRequestFailureAction,
    trelloAuthRequestSuccessAction,
    trelloAuthVerifyAction,
    trelloAuthVerifyFailureAction,
    trelloAuthVerifySuccessAction,
    trelloDetachBoardFromProjectAction,
    trelloDetachBoardFromProjectFailureAction,
    trelloDetachBoardFromProjectSuccessAction,
    trelloGetBoardColumnsAction,
    trelloGetBoardColumnsFailureAction,
    trelloGetBoardColumnsSuccessAction,
    trelloGetProjectColumnsAction,
    trelloGetProjectColumnsFailureAction,
    trelloGetProjectColumnsSuccessAction,
    trelloGetProjectStatusAction,
    trelloGetProjectStatusFailureAction,
    trelloGetProjectStatusSuccessAction,
    trelloGetUserBoardsAction,
    trelloGetUserBoardsFailureAction,
    trelloGetUserBoardsSuccessAction,
    trelloGetUserTokenDataAction,
    trelloGetUserTokenDataFailureAction,
    trelloGetUserTokenDataSuccessAction,
    trelloLogoutAction,
    trelloLogoutFailureAction,
    trelloLogoutSuccessAction,
    trelloStartSyncAction,
    trelloStartSyncFailureAction,
    trelloStartSyncSuccessAction,
    trelloStopSyncAction,
    trelloStopSyncFailureAction,
    trelloStopSyncSuccessAction,
    trelloUpdateProjectColumnSettingsAction,
    trelloUpdateProjectColumnSettingsFailureAction,
    trelloUpdateProjectColumnSettingsSuccessAction,
} from '@app-client/store/trello/actions/trello.action';
import { TrelloStateInterface } from '@app-client/store/trello/trello.state.interface';
import { TrelloProjectSyncStatusEnum } from '@app-client/trello/types/trello-project-status.interface';


const initialState: TrelloStateInterface = {
    isAuthRequestInProgress: false,
    isAuthVerifyInProgress: false,
    isGetUserTokenDataInProgress: false,
    userTokenData: null,
    isLogoutInProgress: false,
    isGetProjectStatusInProgress: false,
    projectStatuses: {},
    isGetUserBoardsInProgress: false,
    isGetBoardColumnsInProgress: false,
    isGetProjectColumnsInProgress: false,
    userBoards: [],
    columns: [],
    isAttachBoardToProjectInProgress: false,
    isUpdateColumnSettingsInProgress: false,
    isDetachBoardFromProjectInProgress: false,
    isStartSyncInProgress: false,
    isStopSyncInProgress: false,
};

const reducer = createReducer(
    initialState,
    on(
        trelloAuthRequestAction,
        (state) => ({
            ...state,
            isAuthRequestInProgress: true,
        }),
    ),
    on(
        trelloAuthRequestSuccessAction,
        (state) => ({
            ...state,
            isAuthRequestInProgress: false,
        }),
    ),
    on(
        trelloAuthRequestFailureAction,
        (state) => ({
            ...state,
            isAuthRequestInProgress: false,
        }),
    ),
    on(
        trelloAuthVerifyAction,
        (state) => ({
            ...state,
            isAuthVerifyInProgress: true,
        }),
    ),
    on(
        trelloAuthVerifySuccessAction,
        (state) => ({
            ...state,
            isAuthVerifyInProgress: false,
        }),
    ),
    on(
        trelloAuthVerifyFailureAction,
        (state) => ({
            ...state,
            isAuthVerifyInProgress: false,
        }),
    ),
    on(
        trelloGetUserTokenDataAction,
        (state) => ({
            ...state,
            isGetUserTokenDataInProgress: true,
            userTokenData: null,
        }),
    ),
    on(
        trelloGetUserTokenDataSuccessAction,
        (state, { userTokenData }) => ({
            ...state,
            isGetUserTokenDataInProgress: false,
            userTokenData,
        }),
    ),
    on(
        trelloGetUserTokenDataFailureAction,
        (state) => ({
            ...state,
            isGetUserTokenDataInProgress: false,
        }),
    ),
    on(
        trelloLogoutAction,
        (state) => ({
            ...state,
            isLogoutInProgress: true,
        }),
    ),
    on(
        trelloLogoutSuccessAction,
        (state) => ({
            ...state,
            isLogoutInProgress: false,
        }),
    ),
    on(
        trelloLogoutFailureAction,
        (state) => ({
            ...state,
            isLogoutInProgress: false,
        }),
    ),
    on(
        trelloGetProjectStatusAction,
        (state) => ({
            ...state,
            isGetProjectStatusInProgress: true,
        }),
    ),
    on(
        trelloGetProjectStatusSuccessAction,
        (state, { projectId, projectStatus }) => ({
            ...state,
            isGetProjectStatusInProgress: false,
            projectStatuses: {
                ...state.projectStatuses,
                [projectId]: projectStatus,
            },
        }),
    ),
    on(
        trelloGetProjectStatusFailureAction,
        (state) => ({
            ...state,
            isGetProjectStatusInProgress: false,
        }),
    ),
    on(
        trelloGetUserBoardsAction,
        (state) => ({
            ...state,
            isGetUserBoardsInProgress: true,
            userBoards: [],
            columns: [],
        }),
    ),
    on(
        trelloGetUserBoardsSuccessAction,
        (state, { boards }) => ({
            ...state,
            isGetUserBoardsInProgress: false,
            userBoards: [
                ...boards,
            ],
        }),
    ),
    on(
        trelloGetUserBoardsFailureAction,
        (state) => ({
            ...state,
            isGetUserBoardsInProgress: false,
        }),
    ),
    on(
        trelloGetBoardColumnsAction,
        (state) => ({
            ...state,
            isGetBoardColumnsInProgress: true,
        }),
    ),
    on(
        trelloGetBoardColumnsSuccessAction,
        (state, { columns }) => ({
            ...state,
            columns,
            isGetBoardColumnsInProgress: false,
        }),
    ),
    on(
        trelloGetBoardColumnsFailureAction,
        (state) => ({
            ...state,
            isGetBoardColumnsInProgress: false,
        }),
    ),
    on(
        trelloGetProjectColumnsAction,
        (state) => ({
            ...state,
            isGetProjectColumnsInProgress: true,
        }),
    ),
    on(
        trelloGetProjectColumnsSuccessAction,
        (state, { columns }) => ({
            ...state,
            isGetProjectColumnsInProgress: false,
            columns,
        }),
    ),
    on(
        trelloGetProjectColumnsFailureAction,
        (state) => ({
            ...state,
            isGetProjectColumnsInProgress: false,
        }),
    ),
    on(
        trelloAttachBoardToProjectAction,
        (state) => ({
            ...state,
            isAttachBoardToProjectInProgress: true,
        }),
    ),
    on(
        trelloAttachBoardToProjectSuccessAction,
        (state) => ({
            ...state,
            isAttachBoardToProjectInProgress: false,
        }),
    ),
    on(
        trelloAttachBoardToProjectFailureAction,
        (state) => ({
            ...state,
            isAttachBoardToProjectInProgress: false,
        }),
    ),
    on(
        trelloUpdateProjectColumnSettingsAction,
        (state) => ({
            ...state,
            isUpdateColumnSettingsInProgress: true,
        }),
    ),
    on(
        trelloUpdateProjectColumnSettingsSuccessAction,
        (state) => ({
            ...state,
            isUpdateColumnSettingsInProgress: false,
        }),
    ),
    on(
        trelloUpdateProjectColumnSettingsFailureAction,
        (state) => ({
            ...state,
            isUpdateColumnSettingsInProgress: false,
        }),
    ),
    on(
        trelloDetachBoardFromProjectAction,
        (state) => ({
            ...state,
            isDetachBoardFromProjectInProgress: true,
        }),
    ),
    on(
        trelloDetachBoardFromProjectSuccessAction,
        (state) => ({
            ...state,
            isDetachBoardFromProjectInProgress: false,
        }),
    ),
    on(
        trelloDetachBoardFromProjectFailureAction,
        (state) => ({
            ...state,
            isDetachBoardFromProjectInProgress: false,
        }),
    ),
    on(
        trelloStartSyncAction,
        (state) => ({
            ...state,
            isStartSyncInProgress: true,
        }),
    ),
    on(
        trelloStartSyncSuccessAction,
        (state, { projectId }) => ({
            ...state,
            isStartSyncInProgress: false,
            projectStatuses: {
                ...state.projectStatuses,
                [projectId]: {
                    ...state.projectStatuses[projectId],
                    syncStatus: TrelloProjectSyncStatusEnum.IN_PROGRESS,
                },
            },
        }),
    ),
    on(
        trelloStartSyncFailureAction,
        (state) => ({
            ...state,
            isStartSyncInProgress: false,
        }),
    ),
    on(
        trelloStopSyncAction,
        (state) => ({
            ...state,
            isStopSyncInProgress: true,
        }),
    ),
    on(
        trelloStopSyncSuccessAction,
        (state, { projectId }) => ({
            ...state,
            isStopSyncInProgress: false,
            projectStatuses: {
                ...state.projectStatuses,
                [projectId]: {
                    ...state.projectStatuses[projectId],
                    syncStatus: TrelloProjectSyncStatusEnum.IDLE,
                },
            },
        }),
    ),
    on(
        trelloStopSyncFailureAction,
        (state, { projectId }) => ({
            ...state,
            isStopSyncInProgress: false,
            projectStatuses: {
                ...state.projectStatuses,
                [projectId]: {
                    ...state.projectStatuses[projectId],
                    syncStatus: TrelloProjectSyncStatusEnum.IDLE,
                },
            },
        }),
    ),
);

export const trelloReducer = (state, action) => reducer(state, action);
