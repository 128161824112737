import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { PersistenceService } from '@app-client/shared/services/persistence.service';
import { getCompanyTrackingSettingsAction } from '@app-client/store/auth/actions/get-company-tracking-settings.action';
import { getCurrentUserAction } from '@app-client/store/auth/actions/get-current-user.action';
import { loginAction, loginFailureAction, loginSuccessAction, logoutAction } from '@app-client/store/auth/actions/login.action';
import { AuthService } from '@app-client/store/auth/services/auth.service';
import { CredentialsInterface } from '@app-client/store/auth/types/credentials.interface';
import { marketplaceModulesGetEnabledAction } from '@app-client/store/marketplace/modules/actions/marketplace-modules.actions';
import { clearSharedDataFiltersAction } from '@app-client/store/shared-data-filters/actions/shared-data-filters.action';
import { updateEmailConfirmSuccessAction } from '@app-client/store/user-profile/actions/update-email.action';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class LoginEffect {
    login$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loginAction),
            switchMap(({ username, password }) => {
                return this.authService.login({ username, password }).pipe(
                    map((credentials: CredentialsInterface) => {
                        return loginSuccessAction({ credentials });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: null,
                        };

                        return of(loginFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    loginSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loginSuccessAction),
            tap(({ credentials }) => {
                this.persistenceService.set('credentials', credentials);
                this.store.dispatch(getCurrentUserAction());
                this.store.dispatch(getCompanyTrackingSettingsAction());
                this.store.dispatch(marketplaceModulesGetEnabledAction());
                this.store.dispatch(clearSharedDataFiltersAction());
                this.router.navigate(['/activity']);
            }),
        );
    }, { dispatch: false });

    logout$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(logoutAction),
            tap(() => {
                this.persistenceService.remove('credentials');
                this.router.navigate(['/login']);
            }),
        );
    }, { dispatch: false });

    updateEmailConfirmSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateEmailConfirmSuccessAction),
            tap(({ credentials }) => {
                this.persistenceService.set('credentials', credentials);
            }),
            map(() => {
                return getCurrentUserAction();
            }),
        );
    });

    constructor(
        private actions$: Actions,
        private router: Router,
        private persistenceService: PersistenceService,
        private authService: AuthService,
        private store: Store,
    ) {}

}
