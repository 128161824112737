import { createAction, props } from '@ngrx/store';

import { CreateProjectInterface } from '@app-client/project/types/create-project.interface';
import { ProjectListQueryParamsInterface } from '@app-client/project/types/project-list-query-params.interface';
import { ProjectInterface } from '@app-client/project/types/project.interface';
import { ActionTypes } from '@app-client/store/projects/action-types';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';
import { PageResponseInterface } from '@app-shared/types/page-response.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';


export const setProjectListQueryParamsAction = createAction(
    ActionTypes.SET_LIST_QUERY_PARAMS,
    props<{ params: ProjectListQueryParamsInterface }>(),
);

export const getProjectListAction = createAction(
    ActionTypes.GET_LIST,
);

export const getProjectListSuccessAction = createAction(
    ActionTypes.GET_LIST_SUCCESS,
    props<{ response: PageResponseInterface<ProjectInterface> }>(),
);

export const getProjectListFailureAction = createAction(
    ActionTypes.GET_LIST_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const setProjectListForEditTimeQueryParamsAction = createAction(
    ActionTypes.SET_LIST_QUERY_PARAMS_FOR_EDIT_TIME,
    props<{ params: ProjectListQueryParamsInterface }>(),
);

export const getProjectListForEditTimeAction = createAction(
    ActionTypes.GET_LIST_FOR_EDIT_TIME,
);

export const getProjectListForEditTimeSuccessAction = createAction(
    ActionTypes.GET_LIST_FOR_EDIT_TIME_SUCCESS,
    props<{ response: PageResponseInterface<ProjectInterface> }>(),
);

export const getProjectListForEditTimeFailureAction = createAction(
    ActionTypes.GET_LIST_FOR_EDIT_TIME_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const getProjectAction = createAction(
    ActionTypes.GET_ONE,
    props<{ id: string, includePermissions?: 0 | 1; }>(),
);

export const getProjectSuccessAction = createAction(
    ActionTypes.GET_ONE_SUCCESS,
    props<{ response: ResponseInterface<ProjectInterface> }>(),
);

export const getProjectFailureAction = createAction(
    ActionTypes.GET_ONE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const createProjectAction = createAction(
    ActionTypes.CREATE,
    props<{ project: CreateProjectInterface }>(),
);

export const createProjectSuccessAction = createAction(
    ActionTypes.CREATE_SUCCESS,
    props<{ response: ResponseInterface<ProjectInterface> }>(),
);

export const createProjectFailureAction = createAction(
    ActionTypes.CREATE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const updateProjectAction = createAction(
    ActionTypes.UPDATE,
    props<{ id: string, project: CreateProjectInterface }>(),
);

export const updateProjectSuccessAction = createAction(
    ActionTypes.UPDATE_SUCCESS,
    props<{ response: ResponseInterface<ProjectInterface> }>(),
);

export const updateProjectFailureAction = createAction(
    ActionTypes.UPDATE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const archiveProjectAction = createAction(
    ActionTypes.ARCHIVE,
    props<{ id: string, successCallback?: () => void }>(),
);

export const archiveProjectSuccessAction = createAction(
    ActionTypes.ARCHIVE_SUCCESS,
    props<{ response: ResponseInterface<null>, successCallback?: () => void }>(),
);

export const archiveProjectFailureAction = createAction(
    ActionTypes.ARCHIVE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const restoreProjectAction = createAction(
    ActionTypes.RESTORE,
    props<{ id: string, successCallback?: () => void }>(),
);

export const restoreProjectSuccessAction = createAction(
    ActionTypes.RESTORE_SUCCESS,
    props<{ response: ResponseInterface<ProjectInterface>, successCallback?: () => void  }>(),
);

export const restoreProjectFailureAction = createAction(
    ActionTypes.RESTORE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
