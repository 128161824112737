import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { AmplitudeService } from '@app-client/shared/services/amplitude.service';
import {
    createUserAction,
    createUserFailureAction,
    createUserSuccessAction,
    deleteUserAction,
    deleteUserFailureAction,
    deleteUserSuccessAction,
    getEmployeesAction,
    getEmployeesFailureAction,
    getEmployeesSuccessAction,
    getUserDetailsAction,
    getUserDetailsFailureAction,
    getUserDetailsSuccessAction,
    getUsersAction,
    getUsersFailureAction,
    getUsersSuccessAction, updateUserAction, updateUserFailureAction, updateUserSuccessAction,
    usersActivateAction, usersActivateFailureAction, usersActivateSuccessAction,
    usersDeactivateAction,
    usersDeactivateFailureAction,
    usersDeactivateSuccessAction,
    usersGetTotalCountAction, usersGetTotalCountFailureAction, usersGetTotalCountSuccessAction
} from '@app-client/store/users/actions/users.action';
import { usersQueryConfigSelector } from '@app-client/store/users/selectors/users.selector';
import { UsersService } from '@app-client/store/users/services/users.service';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class UsersEffect {

    getUsers$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getUsersAction),
            withLatestFrom(this.store$.select(usersQueryConfigSelector)),
            switchMap(([, queryParams]) => {
                return this.usersService.getUsers(queryParams).pipe(
                    map((pageResponse) => {
                        return getUsersSuccessAction({ pageResponse });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(getUsersFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getUsersFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getUsersFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('USERS.NOTIFICATIONS.GET_USERS_LIST.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    createUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createUserAction),
            switchMap(({ userData }) => {
                return this.usersService.createUser(userData).pipe(
                    map((newUser) => {
                        return createUserSuccessAction({ newUser });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(createUserFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    createUserSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createUserSuccessAction),
            tap(({ newUser }) => {
                this.amplitudeService.addedCurrentCompanyUser(newUser);
                this.toastr.success(
                    this.translate.instant('USERS.NOTIFICATIONS.CREATE_USER.SUCCESS.MESSAGE'),
                    this.translate.instant('USERS.NOTIFICATIONS.CREATE_USER.SUCCESS.TITLE'),
                );

                this.router.navigate(['users']);
            }),
        );
    }, { dispatch: false });

    createUserFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createUserFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('USERS.NOTIFICATIONS.CREATE_USER.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    updateUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateUserAction),
            switchMap(({ id, userData }) => {
                return this.usersService.updateUser(id, userData).pipe(
                    map((updatedUser) => {
                        return updateUserSuccessAction({ updatedUser });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(updateUserFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    updateUserSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateUserSuccessAction),
            tap(({ updatedUser }) => {
                this.toastr.success(
                    this.translate.instant('USERS.NOTIFICATIONS.UPDATE_USER.SUCCESS.MESSAGE'),
                    this.translate.instant('USERS.NOTIFICATIONS.UPDATE_USER.SUCCESS.TITLE'),
                );

                this.router.navigate(['users', updatedUser.id, 'detail']);
            }),
        );
    }, { dispatch: false });

    updateUserFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateUserFailureAction),
            tap(({ backendErrors }) => {
                for (const [, errors] of Object.entries(backendErrors.errors)) {
                    errors.map(error => {
                        this.toastr.error(
                            error,
                            this.translate.instant('USERS.NOTIFICATIONS.UPDATE_USER.ERROR.TITLE'),
                        );
                    });
                }
            }),
        );
    }, { dispatch: false });

    deleteUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteUserAction),
            switchMap(({ id }) => {
                return this.usersService.deleteUser(id).pipe(
                    map(() => {
                        return deleteUserSuccessAction({ id });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(deleteUserFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    deleteUserSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteUserSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('USERS.NOTIFICATIONS.DELETE.SUCCESS.MESSAGE'),
                    this.translate.instant('USERS.NOTIFICATIONS.DELETE.SUCCESS.TITLE'),
                );
                this.router.navigate(['users/archived']);
            }),
        );
    }, { dispatch: false });

    deleteUserFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteUserFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('USERS.NOTIFICATIONS.DELETE.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    getUserDetails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getUserDetailsAction),
            switchMap(({ id }) => {
                return this.usersService.getUserById(id).pipe(
                    map((user) => {
                        return getUserDetailsSuccessAction({ userData: user });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(getUserDetailsFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getUserDetailsFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getUserDetailsFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('USERS.NOTIFICATIONS.GET_USER_DETAILS.ERROR.TITLE'),
                );

                this.router.navigate(['users']);
            }),
        );
    }, { dispatch: false });

    getTotalCountEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(usersGetTotalCountAction),
            switchMap(() => {
                return this.usersService.getTotalCount().pipe(
                    map((count) => {
                        return usersGetTotalCountSuccessAction({ count });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(usersGetTotalCountFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    deactivateEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(usersDeactivateAction),
            switchMap(({ id, isForce }) => {
                return this.usersService.deactivate(id, isForce ?? false).pipe(
                    map(() => {
                        return usersDeactivateSuccessAction({ id });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(usersDeactivateFailureAction({
                            backendErrors,
                            isNeedForce: httpErrorResponse.status === 409,
                        }));
                    }),
                );
            }),
        );
    });

    deactivateSuccessEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(usersDeactivateSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('USERS.NOTIFICATIONS.DEACTIVATE.SUCCESS.MESSAGE'),
                    this.translate.instant('USERS.NOTIFICATIONS.DEACTIVATE.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    deactivateFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(usersDeactivateFailureAction),
            tap(({ backendErrors, isNeedForce }) => {
                if (false === isNeedForce) {
                    this.toastr.error(
                        backendErrors.message,
                        this.translate.instant('USERS.NOTIFICATIONS.DEACTIVATE.ERROR.TITLE'),
                    );
                }
            }),
        );
    }, { dispatch: false });

    activateEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(usersActivateAction),
            switchMap(({ id }) => {
                return this.usersService.activate(id).pipe(
                    map(() => {
                        return usersActivateSuccessAction({ id });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(usersActivateFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    activateSuccessEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(usersActivateSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('USERS.NOTIFICATIONS.ACTIVATE.SUCCESS.MESSAGE'),
                    this.translate.instant('USERS.NOTIFICATIONS.ACTIVATE.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    activateFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(usersActivateFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('USERS.NOTIFICATIONS.ACTIVATE.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    getEmployees$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getEmployeesAction),
            withLatestFrom(this.store$.select(usersQueryConfigSelector)),
            switchMap(([queryParams]) => {
                return this.usersService.getEmployees(queryParams.params).pipe(
                    map((employees) => {
                        return getEmployeesSuccessAction({ employees });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(getEmployeesFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getEmployeesFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getEmployeesFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('USERS.NOTIFICATIONS.GET_EMPLOYEES_LIST.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    constructor(
        private actions$: Actions,
        private usersService: UsersService,
        private store$: Store,
        private toastr: ToastrService,
        private translate: TranslateService,
        private router: Router,
        private amplitudeService: AmplitudeService,
    ) {}
}
