<ui-select
    [items]="list"
    [(ngModel)]="selected"
    [placeholder]="('USERS_SELECTOR.PLACEHOLDER.' + (true === isMultiple ? 'MULTIPLE' : 'SINGLE')) | translate"
    [multiple]="true === isMultiple"
    [clearable]="true === isMultiple"
    [closeOnSelect]="false === isMultiple"
    (scrollToEnd)="loadMore()"
    [loading]="true === isLoading"
    [loadingText]="'USERS_SELECTOR.LOADING' | translate"
    [searchFn]="searchFn"
    (search)="search($event.term)"
    [notFoundText]="'USERS_SELECTOR.NOT_FOUND' | translate"
    [compareWith]="compareWith"
    [disabled]="isDisabled"
    (blur)="search('')"
    (clear)="search('')"
>
    <ng-container *ngIf="true === isAllowArchived">
        <ng-template ui-header-tmp>
            <mat-checkbox
                [checked]="listQueryParams$.value.archived === QueryArchivedEnum.INCLUDE"
                (click)="$event.preventDefault();toggleArchived()"
            >
                {{ 'USERS_SELECTOR.SHOW_ARCHIVED' | translate }}
            </mat-checkbox>
        </ng-template>
    </ng-container>
    <ng-container *ngIf="true === isMultiple">
        <ng-template
            ui-option-tmp
            let-user="item"
            let-user$="item$"
        >
                <mat-checkbox
                    [checked]="user$.selected"
                    (click)="$event.preventDefault()"
                >
                    {{ true === !!user.archivedAt ? ('GLOBAL.ENTITY.ARCHIVED_PREFIX' | translate) : '' }}
                    <ng-container *ngIf="user === MY_DEPARTMENT_ELEMENT">
                        {{ 'DAILY_RESULT.EMPLOYEE.FILTERS.USER.MY_DEPARTMENT' | translate }}
                    </ng-container>
                    <ng-container *ngIf="user !== MY_DEPARTMENT_ELEMENT">
                        {{ user.firstName }}
                        {{ user.lastName }}
                    </ng-container>
                </mat-checkbox>
        </ng-template>
        <ng-template
            ui-label-tmp
            let-user="item"
            let-clear="clear"
        >
            <span class="ui-value-label">
                {{ true === !!user.archivedAt ? ('GLOBAL.ENTITY.ARCHIVED_PREFIX' | translate) : '' }}
                <ng-container *ngIf="user === MY_DEPARTMENT_ELEMENT">
                    {{ 'DAILY_RESULT.EMPLOYEE.FILTERS.USER.MY_DEPARTMENT' | translate }}
                </ng-container>
                <ng-container *ngIf="user !== MY_DEPARTMENT_ELEMENT">
                    {{ user.firstName }}
                    {{ user.lastName }}
                </ng-container>
            </span>
            <span
                class="ui-value-icon right"
                (click)="clear(user)"
            >
                ×
            </span>
        </ng-template>
    </ng-container>

    <ng-container *ngIf="false === isMultiple">
        <ng-template
            ui-option-tmp
            ui-label-tmp
            let-user="item"
        >
            <ng-container *ngIf="USERS_SELECTOR_ALL_ELEMENT === user">
                {{ 'GLOBAL.INPUT.ALL' | translate }}
            </ng-container>
            <ng-container *ngIf="USERS_SELECTOR_ALL_ELEMENT !== user">
                {{ true === !!user.archivedAt ? ('GLOBAL.ENTITY.ARCHIVED_PREFIX' | translate) : '' }}
                {{ user.firstName }}
                {{ user.lastName }}
            </ng-container>
        </ng-template>
    </ng-container>
</ui-select>
