import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import {
    getReportAction,
    getReportFailureAction,
    getReportSuccessAction,
    requestGoogleSpreadsheetReportAction,
    requestGoogleSpreadsheetReportFailureAction,
    requestGoogleSpreadsheetReportSuccessAction,
} from '@app-client/store/report/by-project/actions/report.action';
import { ReportService } from '@app-client/store/report/by-project/services/report.service';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class ReportEffect {

    getReport = createEffect( () => {
        return this.actions$.pipe(
            ofType(getReportAction),
            switchMap(({ filters }) => {
                return this.reportService.getReport(filters).pipe(
                    map(response => {
                        return getReportSuccessAction({ report: response });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse.error.message,
                            errors: httpErrorResponse.error.errors,
                        };

                        return of(getReportFailureAction({ backendErrors }));
                    }),
                );
            })
        );
    });

    getReportSuccess = createEffect(() => {
        return this.actions$.pipe(
            ofType(getReportSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('REPORT.NOTIFICATIONS.GET_REPORT.SUCCESS.MESSAGE'),
                    this.translate.instant('REPORT.NOTIFICATIONS.GET_REPORT.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    getReportFailure = createEffect(() => {
        return this.actions$.pipe(
            ofType(getReportFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('REPORT.NOTIFICATIONS.GET_REPORT.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    requestGoogleSpreadsheetReport$ = createEffect( () => {
        return this.actions$.pipe(
            ofType(requestGoogleSpreadsheetReportAction),
            switchMap(({ filters, isLightStyles, processPipe }) => {
                return this.reportService.requestGoogleSpreadsheet(filters, isLightStyles, processPipe).pipe(
                    map(response => {
                        return requestGoogleSpreadsheetReportSuccessAction();
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse.error.message,
                            errors: httpErrorResponse.error.errors,
                        };

                        return of(requestGoogleSpreadsheetReportFailureAction({ backendErrors }));
                    }),
                );
            })
        );
    });

    requestGoogleSpreadsheetReportSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(requestGoogleSpreadsheetReportSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('REPORT.NOTIFICATIONS.REQUEST_GOOGLE_REPORT.SUCCESS.MESSAGE'),
                    this.translate.instant('REPORT.NOTIFICATIONS.REQUEST_GOOGLE_REPORT.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    requestGoogleSpreadsheetReportFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(requestGoogleSpreadsheetReportFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('REPORT.NOTIFICATIONS.REQUEST_GOOGLE_REPORT.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    constructor(
        private actions$: Actions,
        private reportService: ReportService,
        private translate: TranslateService,
        private toastr: ToastrService,
    ) {}
}
