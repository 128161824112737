import { createReducer, on } from '@ngrx/store';

import { clearProfileBackendErrorsAction } from '@app-client/store/user-profile/actions/backend-errors.action';
import {
    changePasswordAction,
    changePasswordFailureAction,
    changePasswordSuccessAction,
} from '@app-client/store/user-profile/actions/change-password.action';
import {
    updateEmailCancelAction,
    updateEmailCancelFailureAction,
    updateEmailCancelSuccessAction,
    updateEmailConfirmAction,
    updateEmailConfirmFailureAction,
    updateEmailConfirmSuccessAction,
    updateEmailRequestAction,
    updateEmailRequestFailureAction,
    updateEmailRequestSuccessAction,
} from '@app-client/store/user-profile/actions/update-email.action';
import {
    updateAvatarAction,
    updateAvatarFailureAction,
    updateAvatarSuccessAction,
} from '@app-client/store/user-profile/actions/upload-avatar.action';
import {
    updateUserProfileDataAction,
    updateUserProfileDataFailureAction,
    updateUserProfileDataSuccessAction,
} from '@app-client/store/user-profile/actions/user-profile-data.action';
import { UserProfileStateInterface } from '@app-client/store/user-profile/user-profile-state.interface';


const initialState: UserProfileStateInterface = {
    isAvatarChangeRequestInProgress: false,
    isPasswordChangeRequestInProgress: false,
    isEmailUpdateRequestInProgress: false,
    isEmailUpdateConfirmInProgress: false,
    isEmailUpdateCancelInProgress: false,
    isProfileDataUpdateInProgress: false,
    backendErrors: {},
};

const reducer = createReducer(
    initialState,
    on(
        updateAvatarAction,
        state => ({
            ...state,
            isAvatarChangeRequestInProgress: true,
        }),
    ),
    on(
        updateAvatarSuccessAction,
        state => ({
            ...state,
            isAvatarChangeRequestInProgress: false,
        }),
    ),
    on(
        updateAvatarFailureAction,
        state => ({
            ...state,
            isAvatarChangeRequestInProgress: false,
        }),
    ),

    on(
        changePasswordAction,
        state => ({
            ...state,
            isPasswordChangeRequestInProgress: true,
        }),
    ),
    on(
        changePasswordSuccessAction,
        state => ({
            ...state,
            isPasswordChangeRequestInProgress: false,
        }),
    ),
    on(
        changePasswordFailureAction,
        state => ({
            ...state,
            isPasswordChangeRequestInProgress: false,
        }),
    ),

    on(
        updateUserProfileDataAction,
        state => ({
            ...state,
            isProfileDataUpdateInProgress: true,
        }),
    ),
    on(
        updateUserProfileDataSuccessAction,
        state => ({
            ...state,
            isProfileDataUpdateInProgress: false,
        }),
    ),
    on(
        updateUserProfileDataFailureAction,
        state => ({
            ...state,
            isProfileDataUpdateInProgress: false,
        }),
    ),

    on(
        updateEmailRequestAction,
        state => ({
            ...state,
            isEmailUpdateRequestInProgress: true,
        }),
    ),
    on(
        updateEmailRequestSuccessAction,
        state => ({
            ...state,
            isEmailUpdateRequestInProgress: false,
        }),
    ),
    on(
        updateEmailRequestFailureAction,
        state => ({
            ...state,
            isEmailUpdateRequestInProgress: false,
        }),
    ),

    on(
        updateEmailConfirmAction,
        state => ({
            ...state,
            isEmailUpdateConfirmInProgress: true,
        }),
    ),
    on(
        updateEmailConfirmSuccessAction,
        state => ({
            ...state,
            isEmailUpdateConfirmInProgress: false,
        }),
    ),
    on(
        updateEmailConfirmFailureAction,
        state => ({
            ...state,
            isEmailUpdateConfirmInProgress: false,
        }),
    ),

    on(
        updateEmailCancelAction,
        state => ({
            ...state,
            isEmailUpdateCancelInProgress: true,
        }),
    ),
    on(
        updateEmailCancelSuccessAction,
        state => ({
            ...state,
            isEmailUpdateCancelInProgress: false,
        }),
    ),
    on(
        updateEmailCancelFailureAction,
        state => ({
            ...state,
            isEmailUpdateCancelInProgress: false,
        }),
    ),

    on(
        clearProfileBackendErrorsAction,
        (state, action) => {
            if (action.fieldKey) {
                return {
                    ...state,
                    backendErrors: {
                        ...state.backendErrors,
                        [action.fieldKey]: undefined,
                    },
                };
            }

            return {
                ...state,
                backendErrors: {},
            };
        },
    ),
    on(
        updateAvatarFailureAction,
        updateEmailRequestFailureAction,
        updateEmailConfirmFailureAction,
        updateEmailCancelFailureAction,
        changePasswordFailureAction,
        updateUserProfileDataFailureAction,
        (state, action) => {
            return {
                ...state,
                backendErrors: {
                    ...state.backendErrors,
                    ...action.backendErrors.errors,
                },
            };
        },
    ),
);

export function userProfileReducer(state, action) {
    return reducer(state, action);
}
