import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AuthStateInterface } from '@app-admin/store/auth/auth-state.interface';


export const authFeatureSelector = createFeatureSelector<AuthStateInterface>('auth');

export const currentUserSelector = createSelector(
    authFeatureSelector,
    (state: AuthStateInterface) => state.currentUser,
);

export const isSubmittingSelector = createSelector(
    authFeatureSelector,
    (state: AuthStateInterface) => state.isSubmitting,
);

export const backendErrorsSelector = createSelector(
    authFeatureSelector,
    (state: AuthStateInterface) => state.backendErrors,
);

export const isLoginUnderClientInProgress = createSelector(
    authFeatureSelector,
    (state: AuthStateInterface) => state.loginUnderCompanyInProgress,
);
