<ui-select
    [items]="list"
    [(ngModel)]="selected"
    [placeholder]="('TASKS_SELECTOR.PLACEHOLDER.' + (true === isMultiple ? 'MULTIPLE' : 'SINGLE')) | translate"
    [multiple]="true === isMultiple"
    [clearable]="true === isMultiple"
    [closeOnSelect]="false === isMultiple"
    (scrollToEnd)="loadMore()"
    [loading]="true === isLoading"
    [loadingText]="'TASKS_SELECTOR.LOADING' | translate"
    [searchFn]="searchFn"
    (search)="search($event.term)"
    [notFoundText]="'TASKS_SELECTOR.NOT_FOUND' | translate"
    [compareWith]="compareWith"
    [disabled]="true === isDisabled"
    (blur)="search('')"
    (clear)="search('')"
>
    <ng-container *ngIf="true === isAllowArchived">
        <ng-template ui-header-tmp>
            <mat-checkbox
                [checked]="listQueryParams$.value.archived === QueryArchivedEnum.INCLUDE"
                (click)="$event.preventDefault();toggleArchived()"
            >
                {{ 'TASKS_SELECTOR.SHOW_ARCHIVED' | translate }}
            </mat-checkbox>
        </ng-template>
    </ng-container>
    <ng-container *ngIf="true === isMultiple">
        <ng-template
            ui-option-tmp
            let-task="item"
            let-task$="item$"
        >
                <mat-checkbox
                    [checked]="task$.selected"
                    (click)="$event.preventDefault()"
                >
                    {{ true === !!task.archivedAt ? ('GLOBAL.ENTITY.ARCHIVED_PREFIX' | translate) : '' }}
                    {{ task.isDefault? ('GLOBAL.ENTITY.DEFAULT.TASK.NAME' | translate) : task.name }}
                </mat-checkbox>
        </ng-template>
        <ng-template
            ui-label-tmp
            let-task="item"
            let-clear="clear"
        >
            <span class="ui-value-label">
                {{ true === !!task.archivedAt ? ('GLOBAL.ENTITY.ARCHIVED_PREFIX' | translate) : '' }}
                {{ task.isDefault? ('GLOBAL.ENTITY.DEFAULT.TASK.NAME' | translate) : task.name }}
            </span>
            <span
                class="ui-value-icon right"
                (click)="clear(task)"
            >
                ×
            </span>
        </ng-template>
    </ng-container>

    <ng-container *ngIf="false === isMultiple">
        <ng-template
            ui-option-tmp
            ui-label-tmp
            let-task="item"
        >
            <ng-container *ngIf="TASKS_SELECTOR_ALL_ELEMENT === task">
                {{ 'GLOBAL.INPUT.ALL' | translate }}
            </ng-container>
            <ng-container *ngIf="TASKS_SELECTOR_ALL_ELEMENT !== task">
                {{ true === !!task.archivedAt ? ('GLOBAL.ENTITY.ARCHIVED_PREFIX' | translate) : '' }}
                {{ task.isDefault? ('GLOBAL.ENTITY.DEFAULT.TASK.NAME' | translate) : task.name }}
            </ng-container>
        </ng-template>
    </ng-container>
</ui-select>
