import { createAction, props } from '@ngrx/store';

import { NewProjectSpaceInterface } from '@app-client/project-space/types/new-project-space.interface';
import { ProjectSpaceInterface } from '@app-client/project-space/types/project-space.interface';
import { ProjectSpacesQueryConfigInterface } from '@app-client/project-space/types/project-spaces-query-config.interface';
import { ActionTypes } from '@app-client/store/project-spaces/action-types';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';
import { PageResponseInterface } from '@app-shared/types/page-response.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';


export const getArchivedProjectSpacesAction = createAction(ActionTypes.GET_ARCHIVED_PROJECT_SPACES);

export const getArchivedProjectSpacesSuccessAction = createAction(
    ActionTypes.GET_ARCHIVED_PROJECT_SPACES_SUCCESS,
    props<{ response: PageResponseInterface<ProjectSpaceInterface> }>(),
);

export const getArchivedProjectSpacesFailureAction = createAction(
    ActionTypes.GET_ARCHIVED_PROJECT_SPACES_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const setArchivedProjectSpacesQueryConfigAction = createAction(
    ActionTypes.SET_ARCHIVED_PROJECT_SPACES_QUERY_CONFIG,
    props<{ config: ProjectSpacesQueryConfigInterface }>(),
);

export const getArchivedProjectSpaceAction = createAction(
    ActionTypes.GET_ARCHIVED_PROJECT_SPACE,
    props<{ id: string }>(),
);

export const getArchivedProjectSpaceSuccessAction = createAction(
    ActionTypes.GET_ARCHIVED_PROJECT_SPACE_SUCCESS,
    props<{ response: ResponseInterface<ProjectSpaceInterface> }>(),
);

export const getArchivedProjectSpaceFailureAction = createAction(
    ActionTypes.GET_ARCHIVED_PROJECT_SPACE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const restoreArchivedProjectSpaceAction = createAction(
    ActionTypes.RESTORE_ARCHIVED_PROJECT_SPACE,
    props<{ id: string }>(),
);

export const restoreArchivedProjectSpaceSuccessAction = createAction(
    ActionTypes.RESTORE_ARCHIVED_PROJECT_SPACE_SUCCESS,
);

export const restoreArchivedProjectSpaceFailureAction = createAction(
    ActionTypes.RESTORE_ARCHIVED_PROJECT_SPACE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const getProjectSpacesAction = createAction(
    ActionTypes.GET_PROJECT_SPACES,
);

export const getProjectSpacesSuccessAction = createAction(
    ActionTypes.GET_PROJECT_SPACES_SUCCESS,
    props<{ response: ResponseInterface<PageResponseInterface<ProjectSpaceInterface>> }>(),
);

export const getProjectSpacesFailureAction = createAction(
    ActionTypes.GET_PROJECT_SPACES_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const setProjectSpacesQueryConfigAction = createAction(
    ActionTypes.SET_PROJECT_SPACES_QUERY_CONFIG,
    props<{ config: ProjectSpacesQueryConfigInterface }>(),
);

export const getProjectSpaceAction = createAction(
    ActionTypes.GET_PROJECT_SPACE,
    props<{ id: string }>(),
);

export const getProjectSpaceSuccessAction = createAction(
    ActionTypes.GET_PROJECT_SPACE_SUCCESS,
    props<{ response: ResponseInterface<ProjectSpaceInterface> }>(),
);

export const getProjectSpaceFailureAction = createAction(
    ActionTypes.GET_PROJECT_SPACE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const createProjectSpaceAction = createAction(
    ActionTypes.CREATE_PROJECT_SPACE,
    props<{ newProjectSpace: NewProjectSpaceInterface }>(),
);

export const createProjectSpaceSuccessAction = createAction(
    ActionTypes.CREATE_PROJECT_SPACE_SUCCESS,
    props<{ projectSpace: ProjectSpaceInterface }>(),
);

export const createProjectSpaceFailureAction = createAction(
    ActionTypes.CREATE_PROJECT_SPACE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const updateProjectSpaceAction = createAction(
    ActionTypes.UPDATE_PROJECT_SPACE,
    props<{ id: string, newProjectSpace: NewProjectSpaceInterface }>(),
);

export const updateProjectSpaceSuccessAction = createAction(
    ActionTypes.UPDATE_PROJECT_SPACE_SUCCESS,
);

export const updateProjectSpaceFailureAction = createAction(
    ActionTypes.UPDATE_PROJECT_SPACE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const archiveProjectSpaceAction = createAction(
    ActionTypes.ARCHIVE_PROJECT_SPACE,
    props<{ id: string }>(),
);

export const archiveProjectSpaceSuccessAction = createAction(
    ActionTypes.ARCHIVE_PROJECT_SPACE_SUCCESS,
);

export const archiveProjectSpaceFailureAction = createAction(
    ActionTypes.ARCHIVE_PROJECT_SPACE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
