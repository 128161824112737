import { createReducer, on } from '@ngrx/store';

import { SignatureUnsignedIntervalsStateInterface } from '@app-client/store/signature/unsiged-intervals/signature-unsigned-intervals-state.interface';
import {
    signatureUnsignedIntervalsGetListAction,
    signatureUnsignedIntervalsGetListSuccessAction,
    signatureUnsignedIntervalsSignAction,
    signatureUnsignedIntervalsSignFailureAction,
    signatureUnsignedIntervalsSignSuccessAction,
} from '@app-client/store/signature/unsiged-intervals/signature-unsigned-intervals.actions';


const initialState: SignatureUnsignedIntervalsStateInterface = {
    isListLoading: false,
    list: null,

    isSignCompleted: false,
    isSignInProgress: false,
};

const reducer = createReducer(
    initialState,
    on(
        signatureUnsignedIntervalsGetListAction,
        (state) => ({
            ...state,
            isListLoading: true,
        }),
    ),
    on(
        signatureUnsignedIntervalsGetListSuccessAction,
        (state, { list }) => ({
            ...state,
            isListLoading: false,
            list,
        }),
    ),
    on(
        signatureUnsignedIntervalsGetListSuccessAction,
        (state, { list }) => ({
            ...state,
            isListLoading: false,
            list: null,
        }),
    ),

    on(
        signatureUnsignedIntervalsSignAction,
        (state) => ({
            ...state,
            isSignCompleted: false,
            isSignInProgress: true,
        }),
    ),
    on(
        signatureUnsignedIntervalsSignSuccessAction,
        (state) => ({
            ...state,
            isSignCompleted: true,
            isSignInProgress: false,
        }),
    ),
    on(
        signatureUnsignedIntervalsSignFailureAction,
        (state) => ({
            ...state,
            isSignCompleted: false,
            isSignInProgress: false,
        }),
    ),
);

export const signatureUnsignedIntervalsReducer = (state, action) => reducer(state, action);
