import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { UrlHelper } from '@app-client/shared/utility/url.helper';
import { loginAction, loginSuccessAction } from '@app-client/store/auth/actions/login.action';
import { backendErrorsSelector, isCurrentUserLoadingSelector, isSubmittingSelector } from '@app-client/store/auth/selectors/auth.selectors';
import { CredentialsInterface } from '@app-client/store/auth/types/credentials.interface';
import { showLoggedAsAction } from '@app-client/store/logged-as/actions/logged-as.action';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';
import { jwtDecode } from '@app-shared/utils/jwtDecode';
import { Utility } from '@app-shared/utils/utility';
import { emailValidator } from '@app-shared/validators/email.validator';


@UntilDestroy()
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    isRequestInProgress = false;
    form: FormGroup;
    isSubmitting$: Observable<boolean>;
    backendErrors$: Observable<BackendErrorsInterface>;
    isUserLoading$: Observable<boolean>;
    isShowLoader: boolean;

    constructor(
        private formBuilder: FormBuilder,
        private store: Store,
    ) {}

    get username() { return this.form.get('username'); }

    get password() { return this.form.get('password'); }

    ngOnInit(): void {
        this.initializeForm();
        this.initializeValues();
        this.oauth2Login();
    }

    initializeForm(): void {
        this.form = this.formBuilder.group({
            username: ['', [Validators.required, emailValidator]],
            password: ['', Validators.required],
        });

        this.username.disable();
    }

    initializeValues(): void {
        this.isSubmitting$ = this.store.select(isSubmittingSelector).pipe(
            untilDestroyed(this),
            tap((inProgress) => {
                if (inProgress) {
                    this.form.disable();
                } else {
                    this.form.enable();
                }
            }),
        );
        this.backendErrors$ = this.store.select(backendErrorsSelector);
        this.isSubmitting$
            .pipe(untilDestroyed(this))
            .subscribe((isSubmitting: boolean): void => {
                isSubmitting ? this.form.disable() : this.form.enable();
            });
        this.isUserLoading$ = this.store.select(isCurrentUserLoadingSelector);
        this.isShowLoader = false;
    }

    onSubmit(): void {
        Utility.markAllFormFieldsAsDirty(this.form);

        this.form.markAsUntouched();
        this.form.markAsPristine();

        if (this.form.valid) {
            this.store.dispatch(loginAction(this.form.value));
        }
    }

    authorizeBy(type: string): void {
        this.isRequestInProgress = true;

        switch (type) {
            case 'google':
                window.location.href = UrlHelper.getOauth2Link('google', 'profile');
                break;
        }
    }

    oauth2Login(): void {
        const credentialsString: string = UrlHelper.getQueryParam('credentials');
        if (null === credentialsString) {
            return;
        }

        try {
            const credentials: CredentialsInterface = JSON.parse(credentialsString);
            if (!credentials) {
                return;
            }

            if (credentials.token && credentials.refresh_token) {
                this.isShowLoader = true;
                this.store.dispatch(loginSuccessAction({ credentials }));

                if (UrlHelper.getQueryParam('is_show_logged_as_panel') === '1') {
                    const { email } = jwtDecode(credentials.token);

                    this.store.dispatch(showLoggedAsAction({
                        showLoggedAsParams: {
                            loggedAsName: email
                        },
                    }));
                }
            }
        } catch (e) {}
    }
}
