export enum TimeActionTypes {
    ADD_TIME = '[Activity/TimeRequests] Add time',
    ADD_TIME_SUCCESS = '[Activity/TimeRequests] Add time success',
    ADD_TIME_FAILURE = '[Activity/TimeRequests] Add time failure',

    EDIT_TIME = '[Activity/TimeRequests] Edit time',
    EDIT_TIME_SUCCESS = '[Activity/TimeRequests] Edit time success',
    EDIT_TIME_FAILURE = '[Activity/TimeRequests] Edit time failure',

    DELETE_TIME = '[Activity/TimeRequests] Delete time',
    DELETE_TIME_SUCCESS = '[Activity/TimeRequests] Delete time success',
    DELETE_TIME_FAILURE = '[Activity/TimeRequests] Delete time failure',
}
