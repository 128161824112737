import { createFeatureSelector, createSelector } from '@ngrx/store';

import { JiraAuthStateInterface } from '@app-client/store/jira/jira-auth-state.interface';


export const jiraAuthFeatureSelector = createFeatureSelector<JiraAuthStateInterface>('jiraAuth');

export const jiraConnectionsSelector = createSelector(
    jiraAuthFeatureSelector,
    (state) => state.connections,
);

export const jiraIsConnectionsLoadingSelector = createSelector(
    jiraAuthFeatureSelector,
    (state) => state.isConnectionsLoading,
);

export const jiraIsGetCloudOauth2RedirectUrlInProgressSelector = createSelector(
    jiraAuthFeatureSelector,
    (state) => state.isGetCloudOauth2RedirectUrlInProgress,
);

export const jiraIsVerifyCloudOauth2InProgressSelector = createSelector(
    jiraAuthFeatureSelector,
    (state) => state.isVerifySelfHostedOauth1InProgress,
);

export const jiraIsAttachCloudApiTokenInProgressSelector = createSelector(
    jiraAuthFeatureSelector,
    (state) => state.isAttachCloudApiTokenInProgress,
);

export const jiraIsGetSelfHostedOauth1RedirectUrlInProgressSelector = createSelector(
    jiraAuthFeatureSelector,
    (state) => state.isGetSelfHostedOauth1RedirectUrlInProgress,
);

export const jiraIsVerifySelfHostedOauth1InProgressSelector = createSelector(
    jiraAuthFeatureSelector,
    (state) => state.isVerifySelfHostedOauth1InProgress,
);

export const jiraIsAuthSelfHostedBasicInProgressSelector = createSelector(
    jiraAuthFeatureSelector,
    (state) => state.isAuthSelfHostedBasicInProgress,
);

export const jiraIsLogoutInProgressSelector = createSelector(
    jiraAuthFeatureSelector,
    (state) => state.isLogoutInProgress,
);
