import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';

import { KaitenProjectSyncStatusEnum } from '@app-client/kaiten/types/kaiten-project-status.interface';
import {
    kaitenAttachSpaceToProjectAction,
    kaitenAttachSpaceToProjectFailureAction,
    kaitenAttachSpaceToProjectSuccessAction,
    kaitenDeleteKeyAction,
    kaitenDeleteKeyFailureAction,
    kaitenDeleteKeySuccessAction,
    kaitenDetachSpaceFromProjectAction,
    kaitenDetachSpaceFromProjectFailureAction,
    kaitenDetachSpaceFromProjectSuccessAction,
    kaitenGetProjectColumnsAction,
    kaitenGetProjectColumnsFailureAction,
    kaitenGetProjectColumnsSuccessAction,
    kaitenGetProjectStatusAction,
    kaitenGetProjectStatusFailureAction,
    kaitenGetProjectStatusSuccessAction,
    kaitenGetUserBoardsAction,
    kaitenGetUserBoardsFailureAction,
    kaitenGetUserBoardsSuccessAction,
    kaitenGetUserDataAction,
    kaitenGetUserDataFailureAction,
    kaitenGetUserDataSuccessAction,
    kaitenGetUserSpacesAction,
    kaitenGetUserSpacesFailureAction,
    kaitenGetUserSpacesSuccessAction,
    kaitenModuleDisabledAction,
    kaitenStartSpaceBoardsListSyncAction,
    kaitenStartSpaceBoardsListSyncFailureAction,
    kaitenStartSpaceBoardsListSyncSuccessAction,
    kaitenStartSpacesListSyncAction,
    kaitenStartSpacesListSyncFailureAction,
    kaitenStartSpacesListSyncSuccessAction,
    kaitenStartSyncAction,
    kaitenStartSyncFailureAction,
    kaitenStartSyncSuccessAction,
    kaitenStopSyncAction,
    kaitenStopSyncFailureAction,
    kaitenStopSyncSuccessAction,
    kaitenUpdateKeyAction,
    kaitenUpdateKeyFailureAction,
    kaitenUpdateKeySuccessAction,
    kaitenUpdateProjectColumnSettingsAction,
    kaitenUpdateProjectColumnSettingsFailureAction,
    kaitenUpdateProjectColumnSettingsSuccessAction,
} from '@app-client/store/kaiten/actions/kaiten.action';
import { KaitenErrorsEnum } from '@app-client/store/kaiten/kaiten-errors.enum';
import { KaitenService } from '@app-client/store/kaiten/services/kaiten.service';
import { marketplaceModulesGetEnabledAction } from '@app-client/store/marketplace/modules/actions/marketplace-modules.actions';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class KaitenEffect {
    kaitenGetProjectStatus$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenGetProjectStatusAction),
            switchMap(({ projectId }) => {
                return this.kaitenService.getProjectStatus(projectId).pipe(
                    map((projectStatus) => {
                        return kaitenGetProjectStatusSuccessAction({ projectStatus, projectId });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        if (httpErrorResponse.status === 404) {
                            return of(kaitenModuleDisabledAction());
                        }

                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(kaitenGetProjectStatusFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    kaitenGetProjectStatusSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenGetProjectStatusSuccessAction),
            switchMap(({ projectId, projectStatus }) => {
                if (projectStatus?.syncStatus === KaitenProjectSyncStatusEnum.IN_PROGRESS) {
                    return of(kaitenGetProjectStatusAction({ projectId })).pipe(delay(10 * 1000));
                }

                return [];
            }),
        );
    });

    kaitenGetProjectStatusFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenGetProjectStatusFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('KAITEN.GET_PROJECT_STATUS.ERROR.TITLE'),
                );
            })
        );
    }, { dispatch: false });

    kaitenGetUserSpaces$ = createEffect(() => {
        return this.actions$.pipe(ofType(kaitenGetUserSpacesAction),
        switchMap(() => {
            return this.kaitenService.getUserKaitenSpaces().pipe(
                map((spaces) => {
                    return kaitenGetUserSpacesSuccessAction({ spaces });
                }),
                catchError((httpErrorResponse: HttpErrorResponse) => {
                    if (httpErrorResponse.status === 404) {
                        return of(kaitenModuleDisabledAction());
                    }

                    const backendErrors: BackendErrorsInterface = {
                        message: httpErrorResponse?.error?.message,
                        errors: httpErrorResponse?.error?.errors,
                    };

                    return of(kaitenGetUserSpacesFailureAction({ backendErrors }));
                })
            );
        }));
    });

    kaitenGetUserSpacesFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenGetUserSpacesFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('KAITEN.GET_USER_SPACES.ERROR.TITLE'),
                );
            })
        );
    }, { dispatch: false });

    kaitenGetUserBoards$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenGetUserBoardsAction),
            switchMap(({spaceId}) => {
                return this.kaitenService.getUserKaitenBoards(spaceId).pipe(
                    map((boards) => {
                        return kaitenGetUserBoardsSuccessAction({ boards });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        if (httpErrorResponse.status === 404) {
                            return of(kaitenModuleDisabledAction());
                        }

                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(kaitenGetUserBoardsFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    kaitenGetUserBoardsFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenGetUserBoardsFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('KAITEN.GET_USER_BOARDS.ERROR.TITLE'),
                );
            })
        );
    }, { dispatch: false });

    kaitenGetUserData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenGetUserDataAction),
            switchMap(() => {
                return this.kaitenService.getKaitenUserData().pipe(
                    map((kaitenUserData) => {
                        return kaitenGetUserDataSuccessAction({kaitenUserData});
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        // if (httpErrorResponse.status === 404) {
                        //     return of(kaitenModuleDisabledAction());
                        // }

                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(kaitenGetUserDataFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    kaitenGetUserDataFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenGetUserDataFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('KAITEN.GET_KEY.ERROR.TITLE'),
                );
            })
        );
    }, { dispatch: false });

    kaitenUpdateKey$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenUpdateKeyAction),
            switchMap(({ kaitenApiKey }) => {
                return this.kaitenService.updateKaitenKeyId(kaitenApiKey).pipe(
                    map(() => {
                        return kaitenUpdateKeySuccessAction();
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(kaitenUpdateKeyFailureAction({ backendErrors }));
                    })
                );
            })
        );
    });

    kaitenUpdateKeySuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenUpdateKeySuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('KAITEN.UPDATE_KEY.SUCCESS.MESSAGE'),
                    this.translate.instant('KAITEN.UPDATE_KEY.SUCCESS.TITLE'),
                );
            }),
            map(() => {
                return kaitenGetUserDataAction();
            }),
        );
    });

    kaitenUpdateKeyFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenUpdateKeyFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('KAITEN.UPDATE_KEY.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    kaitenDeleteKey$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenDeleteKeyAction),
            switchMap(() => {
                return this.kaitenService.deleteKaitenKey().pipe(
                    map(() => {
                        return kaitenDeleteKeySuccessAction();
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(kaitenDeleteKeyFailureAction({ backendErrors }));
                    })
                );
            })
        );
    });

    kaitenDeleteKeySuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenDeleteKeySuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('KAITEN.DELETE_KEY.SUCCESS.MESSAGE'),
                    this.translate.instant('KAITEN.DELETE_KEY.SUCCESS.TITLE'),
                );
            }),
            map(() => {
                return kaitenGetUserDataAction();
            }),
        );
    });

    kaitenDeleteKeyFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenDeleteKeyFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('KAITEN.DELETE_KEY.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    kaitenGetProjectColumns$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenGetProjectColumnsAction),
            switchMap(({ projectId }) => {
                return this.kaitenService.getBoardColumnsByProject(projectId).pipe(
                    map((columns) => {
                        return kaitenGetProjectColumnsSuccessAction({ columns });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        if (httpErrorResponse.status === 404) {
                            return of(kaitenModuleDisabledAction());
                        }

                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(kaitenGetProjectColumnsFailureAction({ backendErrors, projectId }));
                    }),
                );
            }),
        );
    });

    kaitenGetProjectColumnsFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenGetProjectColumnsFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('KAITEN.GET_PROJECT_COLUMNS.ERROR.TITLE'),
                );
            }),
            switchMap(({ backendErrors, projectId }) => {
                const actions = [];

                actions.push(kaitenGetProjectStatusAction({ projectId }));

                return actions;
            }),
        );
    });

    kaitenAttachSpaceToProject$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenAttachSpaceToProjectAction),
            switchMap(({ projectId, spaceId, boardId, columnSettings }) => {
                return this.kaitenService.attachKaitenSpaceToProject(
                    projectId,
                    spaceId,
                    boardId,
                    columnSettings.columnFilteringType,
                    columnSettings.columns,
                ).pipe(
                    map(() => {
                        return kaitenAttachSpaceToProjectSuccessAction({ projectId });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        if (httpErrorResponse.status === 404) {
                            return of(kaitenModuleDisabledAction());
                        }

                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(kaitenAttachSpaceToProjectFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    kaitenAttachSpaceToProjectSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenAttachSpaceToProjectSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('KAITEN.ATTACH_SPACE_TO_PROJECT.SUCCESS.MESSAGE'),
                    this.translate.instant('KAITEN.ATTACH_SPACE_TO_PROJECT.SUCCESS.TITLE'),
                );
            }),
            map(({ projectId }) => {
                return kaitenGetProjectStatusAction({ projectId });
            }),
        );
    });

    kaitenAttachSpaceToProjectFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenAttachSpaceToProjectFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('KAITEN.ATTACH_SPACE_TO_PROJECT.ERROR.TITLE'),
                );
            }),
            switchMap(({ backendErrors }) => {
                const actions = [];
                const errorCode = backendErrors.errors[0];

                if (KaitenErrorsEnum.SPACE_NOT_FOUND === errorCode) {
                    actions.push(kaitenGetUserSpacesAction());
                }

                return actions;
            }),
        );
    });

    kaitenUpdateColumnSettings$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenUpdateProjectColumnSettingsAction),
            switchMap(({ projectId, spaceId, boardId, columnSettings }) => {
                return this.kaitenService.updateColumnSettings(projectId, spaceId, boardId, columnSettings).pipe(
                    map(() => {
                        return kaitenUpdateProjectColumnSettingsSuccessAction({ projectId });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        if (httpErrorResponse.status === 404) {
                            return of(kaitenModuleDisabledAction());
                        }

                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(kaitenUpdateProjectColumnSettingsFailureAction({ projectId, backendErrors }));
                    }),
                );
            }),
        );
    });

    kaitenUpdateColumnSettingsSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenUpdateProjectColumnSettingsSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('KAITEN.UPDATE_COLUMN_SETTINGS.SUCCESS.MESSAGE'),
                    this.translate.instant('KAITEN.UPDATE_COLUMN_SETTINGS.SUCCESS.TITLE'),
                );
            }),
            map(({ projectId }) => {
                return kaitenGetProjectStatusAction({ projectId });
            }),
        );
    });

    kaitenUpdateColumnSettingsFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenUpdateProjectColumnSettingsFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('KAITEN.UPDATE_COLUMN_SETTINGS.ERROR.TITLE'),
                );
            }),
            switchMap(({ backendErrors, projectId }) => {
                const actions = [];
                const errorCode = backendErrors.errors[0];

                if (KaitenErrorsEnum.BOARD_NOT_FOUND === errorCode) {
                    actions.push(kaitenGetProjectStatusAction({ projectId }));
                }

                return actions;
            }),
        );
    });

    kaitenDetachSpaceFromProject$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenDetachSpaceFromProjectAction),
            switchMap(({ projectId }) => {
                return this.kaitenService.detachKaitenSpaceFromProject(projectId).pipe(
                    map(() => {
                        return kaitenDetachSpaceFromProjectSuccessAction({ projectId });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        if (httpErrorResponse.status === 404) {
                            return of(kaitenModuleDisabledAction());
                        }

                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(kaitenDetachSpaceFromProjectFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    kaitenDetachSpaceFromProjectSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenDetachSpaceFromProjectSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('KAITEN.DETACH_SPACE_FROM_PROJECT.SUCCESS.MESSAGE'),
                    this.translate.instant('KAITEN.DETACH_SPACE_FROM_PROJECT.SUCCESS.TITLE'),
                );
            }),
            switchMap(({ projectId }) => {
                return [
                    kaitenGetProjectStatusAction({ projectId }),
                ];
            }),
        );
    });

    kaitenDetachSpaceFromProjectFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenDetachSpaceFromProjectFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('KAITEN.DETACH_SPACE_FROM_PROJECT.ERROR.TITLE'),
                );
            })
        );
    }, { dispatch: false });

    kaitenStartSync$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenStartSyncAction),
            switchMap(({ projectId }) => {
                return this.kaitenService.startSync(projectId).pipe(
                    map(() => {
                        return kaitenStartSyncSuccessAction({ projectId });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        if (httpErrorResponse.status === 404) {
                            return of(kaitenModuleDisabledAction());
                        }

                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(kaitenStartSyncFailureAction({ projectId, backendErrors }));
                    }),
                );
            }),
        );
    });

    kaitenStartSyncSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenStartSyncSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('KAITEN.START_SYNC.SUCCESS.MESSAGE'),
                    this.translate.instant('KAITEN.START_SYNC.SUCCESS.TITLE'),
                );
            }),
            map(({ projectId }) => {
                return kaitenGetProjectStatusAction({ projectId });
            }),
        );
    });

    kaitenStartSyncFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenStartSyncFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('KAITEN.START_SYNC.ERROR.TITLE'),
                );
            }),
            switchMap(({ backendErrors, projectId }) => {
                const actions = [];

                actions.push(kaitenGetProjectStatusAction({ projectId }));

                return actions;
            }),
        );
    });

    kaitenStopSync$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenStopSyncAction),
            switchMap(({ projectId }) => {
                return this.kaitenService.stopSync(projectId).pipe(
                    map(() => {
                        return kaitenStopSyncSuccessAction({ projectId });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        if (httpErrorResponse.status === 404) {
                            return of(kaitenModuleDisabledAction());
                        }

                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };


                        return of(kaitenStopSyncFailureAction({ backendErrors, projectId }));
                    }),
                );
            }),
        );
    });

    kaitenStopSyncSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenStopSyncSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('KAITEN.STOP_SYNC.SUCCESS.MESSAGE'),
                    this.translate.instant('KAITEN.STOP_SYNC.SUCCESS.TITLE'),
                );
            }),
            map(({ projectId }) => {
                return kaitenGetProjectStatusAction({ projectId });
            }),
        );
    });

    kaitenStopSyncFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenStopSyncFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('KAITEN.STOP_SYNC.ERROR.TITLE'),
                );
            }),
            map(({ projectId }) => {
                return kaitenGetProjectStatusAction({ projectId });
            }),
        );
    });

    kaitenModuleDisabled$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenModuleDisabledAction),
            tap(() => {
                this.toastr.error(
                    this.translate.instant('KAITEN.MODULE_DISABLED_ERROR.MESSAGE'),
                    this.translate.instant('KAITEN.MODULE_DISABLED_ERROR.TITLE'),
                );
            }),
            map(() => {
                return marketplaceModulesGetEnabledAction();
            }),
        );
    });

    kaitenStartSpacesListSync$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenStartSpacesListSyncAction),
            switchMap(() => {
                return this.kaitenService.startSyncSpacesList().pipe(
                    map(() => {
                        return kaitenStartSpacesListSyncSuccessAction();
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        if (httpErrorResponse.status === 404) {
                            return of(kaitenModuleDisabledAction());
                        }

                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(kaitenStartSpacesListSyncFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    kaitenStartSpacesListSyncSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenStartSpacesListSyncSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('KAITEN.START_SPACES_LIST_SYNC.SUCCESS.MESSAGE'),
                    this.translate.instant('KAITEN.START_SPACES_LIST_SYNC.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    kaitenStartSpacesListSyncFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenStartSpacesListSyncFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('KAITEN.START_SPACE_LIST_SYNC.ERROR.TITLE'),
                );
            })
        );
    }, { dispatch: false });

    kaitenStartSpaceBoardsListSync$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenStartSpaceBoardsListSyncAction),
            switchMap(({spaceId}) => {
                return this.kaitenService.startSpaceBoardsListSync(spaceId).pipe(
                    map(() => {
                        return kaitenStartSpaceBoardsListSyncSuccessAction();
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        if (httpErrorResponse.status === 404) {
                            return of(kaitenModuleDisabledAction());
                        }
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(kaitenStartSpaceBoardsListSyncFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    kaitenStartSpaceBoardsListSyncSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenStartSpaceBoardsListSyncSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('KAITEN.START_SPACE_BOARDS_LIST_SYNC.SUCCESS.MESSAGE'),
                    this.translate.instant('KAITEN.START_SPACE_BOARDS_LIST_SYNC.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    kaitenStartSpaceBoardsListSyncFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(kaitenStartSpaceBoardsListSyncFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('KAITEN.START_SPACE_BOARDS_LIST_SYNC.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    constructor(
        private actions$: Actions,
        private kaitenService: KaitenService,
        private translate: TranslateService,
        private toastr: ToastrService,
    ) {}
}
