import { createReducer, on } from '@ngrx/store';

import {
    createCompanyPositionAction,
    createCompanyPositionFailureAction,
    createCompanyPositionSuccessAction,
    deleteCompanyPositionAction,
    deleteCompanyPositionFailureAction,
    deleteCompanyPositionSuccessAction,
    getCompanyPositionsAction,
    getCompanyPositionsFailureAction,
    getCompanyPositionsSuccessAction,
    setCompanyPositionsQueryParamsAction,
    updateCompanyPositionAction,
    updateCompanyPositionFailureAction,
    updateCompanyPositionSuccessAction,
} from '@app-client/store/company/actions/company-positions.action';
import { CompanyPositionsStateInterface } from '@app-client/store/company/company-positions-state.interface';


const initialState: CompanyPositionsStateInterface = {
    companyPositions: [],
    isListLoading: false,
    hasLoadingMore: true,
    queryConfig: null,
    isCreateInProgress: false,
    isUpdateInProgress: false,
    isDeleteInProgress: false,
    backendErrors: null,
};

const reducer = createReducer(
    initialState,
    on(
        getCompanyPositionsAction,
        state => ({
            ...state,
            isListLoading: true,
            companyPositions: state.queryConfig.page === 1 ? [] : state.companyPositions,
            hasLoadingMore: true,
        }),
    ),
    on(
        getCompanyPositionsSuccessAction,
        (state, action) => ({
            ...state,
            companyPositions: [...state.companyPositions, ...action.response.body.items],
            isListLoading: false,
            hasLoadingMore: action.response.body.page < action.response.body.totalPages,
        }),
    ),
    on(
        getCompanyPositionsFailureAction,
        state => ({
            ...state,
            isListLoading: false,
            hasLoadingMore: false,
        }),
    ),

    on(
        setCompanyPositionsQueryParamsAction,
        (state, action) => ({
            ...state,
            queryConfig: action.queryParams,
        }),
    ),

    on(
        createCompanyPositionAction,
        state => ({
            ...state,
            isCreateInProgress: true,
            backendErrors: null,
        }),
    ),
    on(
        createCompanyPositionSuccessAction,
        state => ({
            ...state,
            isCreateInProgress: false,
        }),
    ),
    on(
        createCompanyPositionFailureAction,
        (state, action) => ({
            ...state,
            isCreateInProgress: false,
            backendErrors: action.backendErrors,
        }),
    ),

    on(
        updateCompanyPositionAction,
        state => ({
            ...state,
            isUpdateInProgress: true,
            backendErrors: null,
        }),
    ),
    on(
        updateCompanyPositionSuccessAction,
        state => ({
            ...state,
            isUpdateInProgress: false,
        }),
    ),
    on(
        updateCompanyPositionFailureAction,
        (state, action) => ({
            ...state,
            isUpdateInProgress: false,
            backendErrors: action.backendErrors,
        }),
    ),

    on(
        deleteCompanyPositionAction,
        state => ({
            ...state,
            isDeleteInProgress: true,
        }),
    ),
    on(
        deleteCompanyPositionSuccessAction,
        state => ({
            ...state,
            isDeleteInProgress: false,
        }),
    ),
    on(
        deleteCompanyPositionFailureAction,
        state => ({
            ...state,
            isDeleteInProgress: false,
        }),
    ),
);

export const companyPositionsReducer = (state, action) => reducer(state, action);
