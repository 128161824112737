<ng-container *ngIf="false === (isAccessRequestSendInProgress$ | async)">
    <ng-container *ngIf="false === (accessRequestSendSuccess$ | async)">
        <p class="subtitle mb-16 center">
            {{ 'LOGIN.ACCESS_REQUEST_FORM.TITLE' | translate }}
        </p>
        <form [formGroup]="form" (ngSubmit)="submit()" novalidate>
            <div class="row mb-40">
                <ui-form-group [has-errors]="firstName.invalid && (firstName.dirty || firstName.touched)">
                    <label ui-label for="firstName">
                        {{ 'LOGIN.ACCESS_REQUEST_FORM.FIRST_NAME.LABEL' | translate }}
                    </label>
                    <input
                        ui-input
                        formControlName="firstName"
                        id="firstName"
                        type="text"
                        placeholder="{{ 'LOGIN.ACCESS_REQUEST_FORM.FIRST_NAME.PLACEHOLDER' | translate }}"
                        autocomplete="off"
                        trim="blur"
                    />
                    <div
                        *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)"
                        ui-hint
                        app-form-errors
                        [formControlRef]="firstName"
                        controlName="{{ 'LOGIN.ACCESS_REQUEST_FORM.FIRST_NAME.LABEL' | translate }}"
                        controlNameGender="other"
                    ></div>
                </ui-form-group>
                <ui-form-group [has-errors]="lastName.invalid && (lastName.dirty || lastName.touched)">
                    <label ui-label for="lastName">
                        {{ 'LOGIN.ACCESS_REQUEST_FORM.LAST_NAME.LABEL' | translate }}
                    </label>
                    <input
                        ui-input
                        formControlName="lastName"
                        id="lastName"
                        type="text"
                        placeholder="{{ 'LOGIN.ACCESS_REQUEST_FORM.LAST_NAME.PLACEHOLDER' | translate }}"
                        autocomplete="off"
                        trim="blur"
                    />
                    <div
                        *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)"
                        ui-hint
                        app-form-errors
                        [formControlRef]="lastName"
                        controlName="{{ 'LOGIN.ACCESS_REQUEST_FORM.LAST_NAME.LABEL' | translate }}"
                        controlNameGender="female"
                    ></div>
                </ui-form-group>
                <ui-form-group [has-errors]="email.invalid && (email.dirty || email.touched)">
                    <label ui-label for="email">
                        {{ 'LOGIN.ACCESS_REQUEST_FORM.EMAIL.LABEL' | translate }}
                    </label>
                    <input
                        ui-input
                        formControlName="email"
                        id="email"
                        type="email"
                        placeholder="{{ 'LOGIN.ACCESS_REQUEST_FORM.EMAIL.PLACEHOLDER' | translate }}"
                        autocomplete="off"
                        trim
                    />
                    <div
                        *ngIf="email.invalid && (email.dirty || email.touched)"
                        ui-hint
                        app-form-errors
                        [formControlRef]="email"
                        controlName="{{ 'LOGIN.ACCESS_REQUEST_FORM.EMAIL.LABEL' | translate }}"
                        controlNameGender="male"
                    ></div>
                </ui-form-group>
                <ui-form-group class="button-margin">
                    <button
                        ui-button
                        class="full-width"
                        size="large"
                        type="submit"
                        color="yellow"
                        [disabled]="(isAccessRequestSendInProgress$ | async) || form.invalid"
                    >
                        {{ 'LOGIN.ACCESS_REQUEST_FORM.SEND_REQUEST_BUTTON' | translate }}
                    </button>
                </ui-form-group>
            </div>
        </form>
        <div class="reduced-text center">
            {{ 'LOGIN.ACCESS_REQUEST_FORM.I_ALREADY_HAVE_ACCESS' | translate }}&nbsp;
            <a routerLink="/login" class="link">
                {{ 'LOGIN.ACCESS_REQUEST_FORM.SIGN_IN' | translate }}&nbsp;
            </a>
        </div>
    </ng-container>

    <div *ngIf="accessRequestSendSuccess$ | async" class="row mb-0">
        <div class="input-field col s12">
            <p class="reduced-text mb-40 center">
                {{ 'LOGIN.ACCESS_REQUEST_FORM.SUCCESS.TITLE' | translate }}
            </p>
            <p class="reduced-text mb-40 center">
                {{ 'LOGIN.ACCESS_REQUEST_FORM.SUCCESS.MESSAGE' | translate }}
            </p>
            <button
                ui-button
                size="large"
                color="yellow"
                routerLink="/login"
                class="full-width"
            >
                {{ 'LOGIN.ACCESS_REQUEST_FORM.SUCCESS.SIGN_IN' | translate }}
            </button>
        </div>
    </div>
</ng-container>

<div *ngIf="true === (isAccessRequestSendInProgress$ | async)" class="spinner-wrapper">
    <ui-spinner></ui-spinner>
</div>
