import { createAction, props } from '@ngrx/store';

import { InvoicingCustomersActionTypesEnum } from '@app-client/store/invoicing/customers/invoicing-customers-action-types.enum';
import { InvoicingCustomersCreateInterface } from '@app-client/store/invoicing/customers/types/invoicing-customers-create.interface';
import { InvoicingCustomersDetailsResponseInterface } from '@app-client/store/invoicing/customers/types/invoicing-customers-details-response.interface';
import { InvoicingCustomersListItemResponseInterface } from '@app-client/store/invoicing/customers/types/invoicing-customers-list-item-response.interface';
import { InvoicingCustomersListRequestParamsInterface } from '@app-client/store/invoicing/customers/types/invoicing-customers-list-request-params.interface';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';
import { PageResponseInterface } from '@app-shared/types/page-response.interface';


export const invoicingCustomersGetListAction = createAction(
    InvoicingCustomersActionTypesEnum.GET_LIST,
    props<{ requestParams: InvoicingCustomersListRequestParamsInterface }>(),
);
export const invoicingCustomersGetListSuccessAction = createAction(
    InvoicingCustomersActionTypesEnum.GET_LIST_SUCCESS,
    props<{ response: PageResponseInterface<InvoicingCustomersListItemResponseInterface> }>(),
);
export const invoicingCustomersGetListFailureAction = createAction(
    InvoicingCustomersActionTypesEnum.GET_LIST_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const invoicingCustomersGetDetailsAction = createAction(
    InvoicingCustomersActionTypesEnum.GET_DETAILS,
    props<{ id: string }>(),
);
export const invoicingCustomersGetDetailsSuccessAction = createAction(
    InvoicingCustomersActionTypesEnum.GET_DETAILS_SUCCESS,
    props<{ response: InvoicingCustomersDetailsResponseInterface }>(),
);
export const invoicingCustomersGetDetailsFailureAction = createAction(
    InvoicingCustomersActionTypesEnum.GET_DETAILS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const invoicingCustomersCreateAction = createAction(
    InvoicingCustomersActionTypesEnum.CREATE,
    props<{ data: InvoicingCustomersCreateInterface }>(),
);
export const invoicingCustomersCreateSuccessAction = createAction(
    InvoicingCustomersActionTypesEnum.CREATE_SUCCESS,
    props<{ id: string }>(),
);
export const invoicingCustomersCreateFailureAction = createAction(
    InvoicingCustomersActionTypesEnum.CREATE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const invoicingCustomersUpdateAction = createAction(
    InvoicingCustomersActionTypesEnum.UPDATE,
    props<{ id: string, data: InvoicingCustomersCreateInterface }>(),
);
export const invoicingCustomersUpdateSuccessAction = createAction(
    InvoicingCustomersActionTypesEnum.UPDATE_SUCCESS,
    props<{ id: string }>(),
);
export const invoicingCustomersUpdateFailureAction = createAction(
    InvoicingCustomersActionTypesEnum.UPDATE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const invoicingCustomersDeleteAction = createAction(
    InvoicingCustomersActionTypesEnum.DELETE,
    props<{ id: string }>(),
);
export const invoicingCustomersDeleteSuccessAction = createAction(
    InvoicingCustomersActionTypesEnum.DELETE_SUCCESS,
);
export const invoicingCustomersDeleteFailureAction = createAction(
    InvoicingCustomersActionTypesEnum.DELETE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
