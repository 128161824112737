import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'combine',
    pure: false,
})
export class CombinePipe implements PipeTransform {
    transform<T>(value: Array<T>, items: number): Array<Array<T>> {
        const res = [];

        for (let i = 0; i < value.length; i += items) {
            const newItem = [];

            for (let j = i; j < (i + items) && j < value.length; j++) {
                newItem.push(value[j]);
            }

            res.push(newItem);
        }

        return res;
    }
}
