<div class="demo-popup" (clickOutside)="nextStep()">
    <div class="demo-header">
        <h5>{{ (currentStepTranslations$ | async)?.header | translate }}</h5>
    </div>
    <div class="demo-body">
        <div [innerHTML]="(currentStepTranslations$ | async)?.text | translate"></div>

        <ng-container *ngIf="((currentStepTranslations$ | async)?.helpLink | translate) !== (currentStepTranslations$ | async)?.helpLink">
            <div class="more-about-this-step">
                <a [href]="(currentStepTranslations$ | async)?.helpLink | translate"
                   target="_blank">
                    <b>{{ (currentStepTranslations$ | async)?.helpText | translate }}</b>
                </a>
                <mat-icon>keyboard_arrow_right</mat-icon>
            </div>
        </ng-container>
    </div>
    <div class="demo-footer">
            <span (click)="skipTour()"
                  class="skip-demo">
                {{ 'TUTORIAL.TOOLTIP.SKIP_BUTTON_TEXT' | translate }}
            </span>
        <button ui-button
                (click)="nextStep()"
                class="next-step-button"
                size="large"
                color="yellow">
            {{ 'TUTORIAL.TOOLTIP.NEXT_BUTTON_TEXT' | translate }}
        </button>
    </div>
</div>
