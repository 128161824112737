export enum ActionTypes {
    GET_ARCHIVED_PROJECT_SPACES = '[ProjectSpaces] Get archived project spaces',
    GET_ARCHIVED_PROJECT_SPACES_SUCCESS = '[ProjectSpaces] Get archived project spaces success',
    GET_ARCHIVED_PROJECT_SPACES_FAILURE = '[ProjectSpaces] Get archived project spaces failure',

    SET_ARCHIVED_PROJECT_SPACES_QUERY_CONFIG = '[ProjectSpaces] Set archived project spaces query config',

    GET_ARCHIVED_PROJECT_SPACE = '[ProjectSpaces] Get archived project space',
    GET_ARCHIVED_PROJECT_SPACE_SUCCESS = '[ProjectSpaces] Get archived project space success',
    GET_ARCHIVED_PROJECT_SPACE_FAILURE = '[ProjectSpaces] Get archived project space failure',

    RESTORE_ARCHIVED_PROJECT_SPACE = '[ProjectSpaces] Restore archived project space',
    RESTORE_ARCHIVED_PROJECT_SPACE_SUCCESS = '[ProjectSpaces] Restore archived project space success',
    RESTORE_ARCHIVED_PROJECT_SPACE_FAILURE = '[ProjectSpaces] Restore archived project space failure',

    GET_PROJECT_SPACES = '[ProjectSpaces] Get project spaces',
    GET_PROJECT_SPACES_SUCCESS = '[ProjectSpaces] Get project spaces success',
    GET_PROJECT_SPACES_FAILURE = '[ProjectSpaces] Get project spaces failure',

    SET_PROJECT_SPACES_QUERY_CONFIG = '[ProjectSpaces] Set project spaces query config',

    GET_PROJECT_SPACE = '[ProjectSpaces] Get  project space',
    GET_PROJECT_SPACE_SUCCESS = '[ProjectSpaces] Get project space success',
    GET_PROJECT_SPACE_FAILURE = '[ProjectSpaces] Get project space failure',

    ARCHIVE_PROJECT_SPACE = '[ProjectSpaces] Archive project space',
    ARCHIVE_PROJECT_SPACE_SUCCESS = '[ProjectSpaces] Archive project space success',
    ARCHIVE_PROJECT_SPACE_FAILURE = '[ProjectSpaces] Archive project space failure',

    CREATE_PROJECT_SPACE = '[ProjectSpaces] Create project space',
    CREATE_PROJECT_SPACE_SUCCESS = '[ProjectSpaces] Create project space success',
    CREATE_PROJECT_SPACE_FAILURE = '[ProjectSpaces] Create project space failure',

    UPDATE_PROJECT_SPACE = '[ProjectSpaces] Update project space',
    UPDATE_PROJECT_SPACE_SUCCESS = '[ProjectSpaces] Update project space success',
    UPDATE_PROJECT_SPACE_FAILURE = '[ProjectSpaces] Update project space failure',
}
