import { createAction, props } from '@ngrx/store';

import { UserRequestActionType } from '@app-client/store/users/user-request-action-type';
import { CreateUserInterface } from '@app-client/user/types/create-user.interface';
import { UserRequestListQueryInterface } from '@app-client/user/types/user-request-list-query.interface';
import { UserRequestInterface } from '@app-client/user/types/user-request.interface';
import { UserInterface } from '@app-client/user/types/user.interface';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';
import { PageResponseInterface } from '@app-shared/types/page-response.interface';


export const getUserRequestListAction = createAction(
    UserRequestActionType.GET_USER_REQUEST_LIST
);
export const getUserRequestListSuccessAction = createAction(
    UserRequestActionType.GET_USER_REQUEST_LIST_SUCCESS,
    props<{ response: PageResponseInterface<UserRequestInterface> }>(),
);
export const getUserRequestListFailureAction = createAction(
    UserRequestActionType.GET_USER_REQUEST_LIST_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const deleteUserRequestAction = createAction(
    UserRequestActionType.DELETE_USER_REQUEST,
    props<{ id: string }>(),
);
export const deleteUserRequestSuccessAction = createAction(
    UserRequestActionType.DELETE_USER_REQUEST_SUCCESS,
);
export const deleteUserRequestFailureAction = createAction(
    UserRequestActionType.DELETE_USER_REQUEST_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const linkUserRequestAction = createAction(
    UserRequestActionType.LINK_USER_REQUEST,
    props<{ id: string, userId: string }>(),
);
export const linkUserRequestSuccessAction = createAction(
    UserRequestActionType.LINK_USER_REQUEST_SUCCESS,
);
export const linkUserRequestFailureAction = createAction(
    UserRequestActionType.LINK_USER_REQUEST_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const setUserRequestListQueryParamsAction = createAction(
    UserRequestActionType.SET_USER_REQUEST_LIST_QUERY_PARAMS,
    props<{ queryParams: UserRequestListQueryInterface }>(),
);

export const createUserFromUserRequestAction = createAction(
    UserRequestActionType.CREATE_USER_FROM_USER_REQUEST,
    props<{ userData: CreateUserInterface }>(),
);
export const createUserFromUserRequestSuccessAction = createAction(
    UserRequestActionType.CREATE_USER_FROM_USER_REQUEST_SUCCESS,
    props<{ newUser: UserInterface }>(),
);
export const createUserFromUserRequestFailureAction = createAction(
    UserRequestActionType.CREATE_USER_FROM_USER_REQUEST_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
