import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import {
    forgotPasswordRequestAction,
    forgotPasswordRequestFailureAction,
    forgotPasswordRequestSuccessAction,
    forgotPasswordSaveNewPasswordAction,
    forgotPasswordSaveNewPasswordFailureAction,
    forgotPasswordSaveNewPasswordSuccessAction,
    forgotPasswordValidateTokenAction,
    forgotPasswordValidateTokenFailureAction,
    forgotPasswordValidateTokenSuccessAction,
} from '@app-client/store/auth/actions/forgot-password.action';
import { AuthService } from '@app-client/store/auth/services/auth.service';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class ForgotPasswordEffect {

    forgotPasswordRequest$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(forgotPasswordRequestAction),
            switchMap(({ email }) => {
                return this.authService.changePasswordRequest(email).pipe(
                    map(() => {
                        return forgotPasswordRequestSuccessAction();
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(forgotPasswordRequestFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    forgotPasswordRequestFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(forgotPasswordRequestFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('LOGIN.FORGOT_PASSWORD_FORM.NOTIFICATIONS.REQUEST.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    // -----------------------------------------------------------------------------------------------------------------

    forgotPasswordValidateToken$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(forgotPasswordValidateTokenAction),
            switchMap(({ token }) => {
                return this.authService.changePasswordValidate(token).pipe(
                    map(({ email }) => {
                        return forgotPasswordValidateTokenSuccessAction({ email });
                    }),
                    catchError(() => {
                        return of(forgotPasswordValidateTokenFailureAction());
                    }),
                );
            }),
        );
    });

    forgotPasswordValidateTokenFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(forgotPasswordValidateTokenFailureAction),
            tap(() => {
                this.toastr.error(
                    this.translate.instant('LOGIN.FORGOT_PASSWORD_FORM.NOTIFICATIONS.VALIDATE_TOKEN.ERROR.MESSAGE'),
                    this.translate.instant('LOGIN.FORGOT_PASSWORD_FORM.NOTIFICATIONS.VALIDATE_TOKEN.ERROR.TITLE'),
                );
            }),
            tap(() => {
                this.router.navigate(['/login']);
            }),
        );
    }, { dispatch: false });

    // -----------------------------------------------------------------------------------------------------------------

    forgotPasswordSaveNewPassword$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(forgotPasswordSaveNewPasswordAction),
            switchMap(({ token, plainPassword }) => {
                return this.authService.changePasswordSaveNewPassword(token, plainPassword).pipe(
                    map(() => {
                        return forgotPasswordSaveNewPasswordSuccessAction();
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(forgotPasswordSaveNewPasswordFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    forgotPasswordSaveNewPasswordSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(forgotPasswordSaveNewPasswordSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('LOGIN.FORGOT_PASSWORD_FORM.NOTIFICATIONS.SAVE_NEW_PASSWORD.SUCCESS.MESSAGE'),
                    this.translate.instant('LOGIN.FORGOT_PASSWORD_FORM.NOTIFICATIONS.SAVE_NEW_PASSWORD.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    forgotPasswordSaveNewPasswordFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(forgotPasswordSaveNewPasswordFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('LOGIN.FORGOT_PASSWORD_FORM.NOTIFICATIONS.SAVE_NEW_PASSWORD.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    constructor(
        private actions$: Actions,
        private router: Router,
        private authService: AuthService,
        private translate: TranslateService,
        private toastr: ToastrService,
    ) {}

}
