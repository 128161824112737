import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import {
    getCompanyTrackingSettingsAction,
    getCompanyTrackingSettingsFailureAction,
    getCompanyTrackingSettingsSuccessAction,
} from '@app-client/store/auth/actions/get-company-tracking-settings.action';
import { AuthService } from '@app-client/store/auth/services/auth.service';
import { CompanyTrackingConfigInterface } from '@app-client/store/auth/types/company-tracking-config.interface';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class GetCompanyTrackingSettingsEffect {
    getCompanyTrackingSettings$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getCompanyTrackingSettingsAction),
            switchMap(() => {
                return this.authService.getCompanyTrackingSettings().pipe(
                    map((config: CompanyTrackingConfigInterface) => {
                        return getCompanyTrackingSettingsSuccessAction({ config });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(getCompanyTrackingSettingsFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getCompanyTrackingSettingsFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getCompanyTrackingSettingsFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('COMPANY.TRACKING_SETTINGS.NOTIFICATIONS.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private toastr: ToastrService,
        private translate: TranslateService,
    ) {}
}
