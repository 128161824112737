import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CompanyPositionListQueryInterface } from '@app-client/company/types/position/company-position-list-query.interface';
import { CompanyPositionInterface } from '@app-client/company/types/position/company-position.interface';
import { PageResponseInterface } from '@app-shared/types/page-response.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';

import { environment } from '@env-client/environment';


@Injectable()
export class CompanyPositionsService {
    constructor(private httpClient: HttpClient) {}

    getCompanyPositions(
        params: CompanyPositionListQueryInterface,
    ): Observable<ResponseInterface<PageResponseInterface<CompanyPositionInterface>>> {
        let httpParams = new HttpParams()
            .set('limit', (params.limit || 10).toString())
            .set('orderBy', params.orderBy)
            .set('orderDirection', params.orderDirection)
            .set('page', params.page.toString())
            .set('globalPositions', '2')
        ;
        if ((params.search || '').trim()) {
            httpParams = httpParams.set('search', (params.search || '').trim());
        }
        const url = environment.apiBaseUrl + '/project-positions';

        return this.httpClient.get<ResponseInterface<PageResponseInterface<CompanyPositionInterface>>>(url, { params: httpParams });
    }

    createCompanyPosition(positionData: { name: string }): Observable<CompanyPositionInterface> {
        const url = environment.apiBaseUrl + '/project-positions';

        return this.httpClient.post(url, { name: positionData.name }).pipe(map((response: ResponseInterface<CompanyPositionInterface>) => {
            return response.body;
        }));
    }

    updateCompanyPosition(id: string, positionData: { name: string }): Observable<CompanyPositionInterface> {
        const url = environment.apiBaseUrl + '/project-positions/' + id;

        return this.httpClient.patch(url, { name: positionData.name }).pipe(map((response: ResponseInterface<CompanyPositionInterface>) => {
            return response.body;
        }));
    }

    deleteCompanyPosition(id: string) {
        const url = environment.apiBaseUrl + '/project-positions/' + id;

        return this.httpClient.delete(url);
    }
}
