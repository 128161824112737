import { createReducer, on } from '@ngrx/store';

import {
    createCompanyDomainAction,
    createCompanyDomainFailureAction,
    createCompanyDomainSuccessAction,
    deleteCompanyDomainAction,
    deleteCompanyDomainFailureAction,
    deleteCompanyDomainSuccessAction, getCompanyClosePeriodSuccessAction,
    getCompanyDomainsAction,
    getCompanyDomainsFailureAction,
    getCompanyDomainsSuccessAction,
    getCompanySettingsAction,
    getCompanySettingsFailureAction,
    getCompanySettingsSuccessAction,
    updateCompanyAvatarAction,
    updateCompanyAvatarFailureAction,
    updateCompanyAvatarSuccessAction,
    updateCompanySettingsAction,
    updateCompanySettingsFailureAction,
    updateCompanySettingsSuccessAction,
} from '@app-client/store/company/actions/company-settings.action';
import { CompanySettingsStateInterface } from '@app-client/store/company/company-settings-state.interface';


const initialState: CompanySettingsStateInterface = {
    companySettings: null,
    companyDomains: null,
    closePeriod: null,

    isGetCompanySettingsInProgress: false,
    isGetCompanyDomainInProgress: false,

    isUpdateCompanySettingsInProgress: false,
    updateCompanySettingsBackendErrors: null,

    isUpdateCompanyAvatarInProgress: false,
    updateCompanyAvatarBackendErrors: null,

    isCreateCompanyDomainInProgress: false,
    isDeleteCompanyDomainInProgress: false,

    createCompanyDomainBackendErrors: null,
};

const reducer = createReducer(
    initialState,
    on(
        getCompanySettingsAction,
        state => ({
            ...state,
            isGetCompanySettingsInProgress: true,
        }),
    ),
    on(
        getCompanySettingsSuccessAction,
        (state, action) => ({
            ...state,
            isGetCompanySettingsInProgress: false,
            companySettings: action.companySettings,
        }),
    ),
    on(
        getCompanySettingsFailureAction,
        state => ({
            ...state,
            isGetCompanySettingsInProgress: false,
        }),
    ),

    on(
        updateCompanySettingsAction,
        state => ({
            ...state,
            isUpdateCompanySettingsInProgress: true,
            updateCompanySettingsBackendErrors: null,
        }),
    ),
    on(
        updateCompanySettingsSuccessAction,
        (state, action) => ({
            ...state,
            isUpdateCompanySettingsInProgress: false,
            companySettings: action.companySettings,
        }),
    ),
    on(
        updateCompanySettingsFailureAction,
        (state, action) => ({
            ...state,
            isUpdateCompanySettingsInProgress: false,
            updateCompanySettingsBackendErrors: action.backendErrors,
        }),
    ),

    on(
        updateCompanyAvatarAction,
        state => ({
            ...state,
            isUpdateCompanyAvatarInProgress: true,
            updateCompanyAvatarBackendErrors: null,
        }),
    ),
    on(
        updateCompanyAvatarSuccessAction,
        (state, action) => ({
            ...state,
            isUpdateCompanyAvatarInProgress: false,
            companySettings: action.companySettings,
        }),
    ),
    on(
        updateCompanyAvatarFailureAction,
        (state, action) => ({
            ...state,
            isUpdateCompanyAvatarInProgress: false,
            updateCompanyAvatarBackendErrors: action.backendErrors,
        }),
    ),

    on(
        getCompanyDomainsAction,
        state => ({
            ...state,
            isGetCompanyDomainInProgress: true,
        }),
    ),
    on(
        getCompanyDomainsSuccessAction,
        (state, action) => ({
            ...state,
            isGetCompanyDomainInProgress: false,
            companyDomains: action.domains,
        }),
    ),
    on(
        getCompanyDomainsFailureAction,
        state => ({
            ...state,
            isGetCompanyDomainInProgress: false,
        }),
    ),

    on(
        createCompanyDomainAction,
        state => ({
            ...state,
            isCreateCompanyDomainInProgress: true,
            createCompanyDomainBackendErrors: null,
        }),
    ),
    on(
        createCompanyDomainSuccessAction,
        (state, action) => ({
            ...state,
            isCreateCompanyDomainInProgress: false,
            companyDomains: [
                ...state.companyDomains,
                action.createdDomain,
            ],
        }),
    ),
    on(
        createCompanyDomainFailureAction,
        (state, action) => ({
            ...state,
            isCreateCompanyDomainInProgress: false,
            createCompanyDomainBackendErrors: action.backendErrors,
        }),
    ),

    on(
        deleteCompanyDomainAction,
        state => ({
            ...state,
            isDeleteCompanyDomainInProgress: true,
        }),
    ),
    on(
        deleteCompanyDomainSuccessAction,
        (state, action) => ({
            ...state,
            isDeleteCompanyDomainInProgress: false,
            companyDomains: state.companyDomains.filter(d => d.id !== action.id),
        }),
    ),
    on(
        deleteCompanyDomainFailureAction,
        state => ({
            ...state,
            isDeleteCompanyDomainInProgress: false,
        }),
    ),
    on(
        getCompanyClosePeriodSuccessAction,
        (state, action) => ({
            ...state,
            closePeriod: action.closePeriod
        })
    )
);

export function companySettingsReducer(state, action) {
    return reducer(state, action);
}
