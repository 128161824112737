<div *ngIf="isVisible$ | async" class="logged-as z-depth-1 flex" [class.collapsed]="isExpanded$ | async">
    <div class="control">
        <button ui-button size="flat" (click)="toggleExpand()" class="grey-text" [class.reverse]="false === (isExpanded$ | async)">
            <i class="material-icons">play_arrow</i>
        </button>
    </div>
    <div class="logged-info">
        <div>
            {{ 'LOGGED_AS.TITLE' | translate }}
        </div>
        <div class="logged-as-email">
            {{ name$ | async }}
        </div>
        <div>
            <a (click)="logout()">
                {{ 'LOGGED_AS.LOGOUT' | translate }}
            </a>
        </div>
    </div>
</div>
