import { createAction, props } from '@ngrx/store';

import { DisplayFiltersInterface } from '@app-client/activity/types/display-filters.interface';
import { ActivityFiltersActionTypes } from '@app-client/store/activity/activity-filters-action-types';


export const updateDisplayFiltersAction = createAction(
    ActivityFiltersActionTypes.UPDATE_DISPLAY_FILTERS,
    props<{ newFilters: DisplayFiltersInterface }>(),
);
