import { createReducer, on } from '@ngrx/store';

import {
    createProjectTaskAction,
    createProjectTaskFailureAction,
    createProjectTaskSuccessAction,
    getProjectTaskListAction,
    getProjectTaskListFailureAction,
    getProjectTaskListForEditTimeAction,
    getProjectTaskListForEditTimeFailureAction,
    getProjectTaskListForEditTimeSuccessAction,
    getProjectTaskListSuccessAction,
    removeProjectTaskAction,
    removeProjectTaskFailureAction,
    removeProjectTaskSuccessAction,
    restoreProjectTaskAction,
    restoreProjectTaskFailureAction,
    restoreProjectTaskSuccessAction,
    setProjectTaskListForEditTimeQueryParamsAction,
    setProjectTaskListQueryParamsAction,
    updateProjectTaskAction,
    updateProjectTaskFailureAction,
    updateProjectTaskSuccessAction,
} from '@app-client/store/project-tasks/actions/project-tasks.action';
import { ProjectTasksStateInterface } from '@app-client/store/project-tasks/project-tasks-state.interface';


const initialState: ProjectTasksStateInterface = {
    isRestoreProjectTaskInProgress: false,
    projectTaskList: [],
    createProjectTaskBackendErrors: undefined,
    hasMoreProjectTask: false,
    isCreateProjectTaskInProgress: false,
    isProjectTaskListLoading: false,
    isRemoveProjectTaskInProgress: false,
    isUpdateProjectTaskInProgress: false,
    listQueryParams: undefined,
    updateProjectTaskBackendErrors: undefined,
    listQueryParamsForEditTime: undefined,
    projectTaskListForEditTime: [],
    isProjectTaskListForEditTimeLoading: false,
    hasMoreProjectTaskForEditTime: false,
};

const reducer = createReducer(
    initialState,
    on(
        setProjectTaskListQueryParamsAction,
        (state, action) => ({
            ...state,
            listQueryParams: action.queryParams,
        }),
    ),
    on(
        getProjectTaskListAction,
        (state) => ({
            ...state,
            isProjectTaskListLoading: true,
            hasMoreProjectTask: false,
            projectTaskList: state.listQueryParams.page === 1 ? [] : [...state.projectTaskList],
        }),
    ),
    on(
        getProjectTaskListSuccessAction,
        (state, action) => ({
            ...state,
            isProjectTaskListLoading: false,
            hasMoreProjectTask: action.response.page < action.response.totalPages,
            projectTaskList: [...state.projectTaskList, ...action.response.items],
        }),
    ),
    on(
        getProjectTaskListFailureAction,
        (state) => ({
            ...state,
            isProjectTaskListLoading: false,
            hasMoreProjectTask: false,
            projectTaskList: [],
        }),
    ),

    on(
        setProjectTaskListForEditTimeQueryParamsAction,
        (state, action) => ({
            ...state,
            listQueryParamsForEditTime: action.queryParams,
        }),
    ),
    on(
        getProjectTaskListForEditTimeAction,
        (state) => ({
            ...state,
            isProjectTaskListForEditTimeLoading: true,
            hasMoreProjectTaskForEditTime: false,
        }),
    ),
    on(
        getProjectTaskListForEditTimeSuccessAction,
        (state, action) => ({
            ...state,
            isProjectTaskListForEditTimeLoading: false,
            hasMoreProjectTaskForEditTime: action.response.page < action.response.totalPages,
            projectTaskListForEditTime: action.response.page === 1
                ? action.response.items
                : [...state.projectTaskListForEditTime, ...action.response.items],
        }),
    ),
    on(
        getProjectTaskListForEditTimeFailureAction,
        (state, action) => ({
            ...state,
            isProjectTaskListForEditTimeLoading: false,
            hasMoreProjectTaskForEditTime: false,
            projectTaskListForEditTime: [],
        }),
    ),

    on(
        createProjectTaskAction,
        (state) => ({
            ...state,
            isCreateProjectTaskInProgress: true,
        }),
    ),
    on(
        createProjectTaskSuccessAction,
        (state) => ({
            ...state,
            isCreateProjectTaskInProgress: false,
        }),
    ),
    on(
        createProjectTaskFailureAction,
        (state, action) => ({
            ...state,
            isCreateProjectTaskInProgress: false,
            createProjectTaskBackendErrors: action.backendErrors,
        }),
    ),
    on(
        updateProjectTaskAction,
        (state) => ({
            ...state,
            isUpdateProjectTaskInProgress: true,
        }),
    ),
    on(
        updateProjectTaskSuccessAction,
        (state) => ({
            ...state,
            isUpdateProjectTaskInProgress: false,
        }),
    ),
    on(
        updateProjectTaskFailureAction,
        (state, action) => ({
            ...state,
            isUpdateProjectTaskInProgress: false,
            updateProjectTaskBackendErrors: action.backendErrors,
        }),
    ),
    on(
        removeProjectTaskAction,
        (state) => ({
            ...state,
            isRemoveProjectTaskInProgress: true,
        }),
    ),
    on(
        removeProjectTaskSuccessAction,
        (state) => ({
            ...state,
            isRemoveProjectTaskInProgress: false,
        }),
    ),
    on(
        removeProjectTaskFailureAction,
        (state) => ({
            ...state,
            isRemoveProjectTaskInProgress: false,
        }),
    ),
    on(
        restoreProjectTaskAction,
        (state) => ({
            ...state,
            isRestoreProjectTaskInProgress: true,
        }),
    ),
    on(
        restoreProjectTaskSuccessAction,
        (state) => ({
            ...state,
            isRestoreProjectTaskInProgress: false,
        }),
    ),
    on(
        restoreProjectTaskFailureAction,
        (state) => ({
            ...state,
            isRestoreProjectTaskInProgress: false,
        }),
    ),
);

export const projectTasksReducer = (state, action) => reducer(state, action);
