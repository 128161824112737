import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';

import { JiraAddAuthStepsEnum } from '@app-client/jira/types/jira-add-auth-steps.enum';
import {
    jiraAttachCloudApiTokenAction,
    jiraAttachCloudApiTokenFailureAction,
    jiraAttachCloudApiTokenSuccessAction,
    jiraAuthSelfHostedBasicAction,
    jiraAuthSelfHostedBasicFailureAction,
    jiraAuthSelfHostedBasicSuccessAction,
    jiraGetCloudOauth2RedirectUrlAction,
    jiraGetCloudOauth2RedirectUrlFailureAction,
    jiraGetCloudOauth2RedirectUrlSuccessAction,
    jiraGetConnectionsAction,
    jiraGetConnectionsFailureAction,
    jiraGetConnectionsSuccessAction,
    jiraGetSelfHostedOauth1RedirectUrlAction,
    jiraGetSelfHostedOauth1RedirectUrlFailureAction,
    jiraGetSelfHostedOauth1RedirectUrlSuccessAction, jiraLogoutAction, jiraLogoutFailureAction, jiraLogoutSuccessAction,
    jiraVerifyCloudOauth2Action,
    jiraVerifyCloudOauth2FailureAction,
    jiraVerifyCloudOauth2SuccessAction,
    jiraVerifySelfHostedOauth1Action,
    jiraVerifySelfHostedOauth1FailureAction,
    jiraVerifySelfHostedOauth1SuccessAction,
} from '@app-client/store/jira/actions/jira-auth.actions';
import { JiraAuthService } from '@app-client/store/jira/services/jira-auth.service';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class JiraAuthEffect {
    constructor(
        private authService: JiraAuthService,
        private actions$: Actions,
        private translate: TranslateService,
        private toastr: ToastrService,
        private router: Router,
    ) {}


    getConnectionsEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraGetConnectionsAction),
            exhaustMap(() => {
                return this.authService.getConnections().pipe(
                    map((connections) => {
                        return jiraGetConnectionsSuccessAction({ connections });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(jiraGetConnectionsFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getConnectionsFailureEffects$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraGetConnectionsFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('JIRA.AUTH.NOTIFICATIONS.GET_CONNECTIONS.FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    getCloudOauth2RedirectUrlEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraGetCloudOauth2RedirectUrlAction),
            switchMap(({ projectId }) => {
                return this.authService.getCloudOauth2RedirectUrl(projectId).pipe(
                    map((url) => {
                        return jiraGetCloudOauth2RedirectUrlSuccessAction({ url });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(jiraGetConnectionsFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getCloudOauth2RedirectUrlSuccessEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraGetCloudOauth2RedirectUrlSuccessAction),
            map(({ url }) => {
                window.location.assign(url);
            }),
        );
    }, { dispatch: false });

    getCloudOauth2RedirectUrlFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraGetCloudOauth2RedirectUrlFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('JIRA.AUTH.NOTIFICATIONS.GET_CLOUD_OAUTH2_REDIRECT_URL.FAILURE.TITLE'),
                );
                const url = this.router.url.split('?')[0]
                    + `?jira-add-auth-step=${JiraAddAuthStepsEnum.CHOSE_JIRA_TYPE}`;
                this.router.navigateByUrl(url);
            })
        );
    }, { dispatch: false });

    verifyCloudOauth2Effect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraVerifyCloudOauth2Action),
            switchMap(({ code, error, state }) => {
                return this.authService.verifyCloudOauth2(code, error, state).pipe(
                    map(() => {
                        return jiraVerifyCloudOauth2SuccessAction();
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(jiraVerifyCloudOauth2FailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    verifyCloudOauth2SuccessEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraVerifyCloudOauth2SuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('JIRA.AUTH.NOTIFICATIONS.VERIFY_CLOUD_OAUTH2.SUCCESS.MESSAGE'),
                    this.translate.instant('JIRA.AUTH.NOTIFICATIONS.VERIFY_CLOUD_OAUTH2.SUCCESS.TITLE'),
                );
                const url = this.router.url.split('?')[0];
                this.router.navigateByUrl(url);
            }),
            map(() => {
                return jiraGetConnectionsAction();
            }),
        );
    });

    verifyCloudOauth2FailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraVerifyCloudOauth2FailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('JIRA.AUTH.NOTIFICATIONS.VERIFY_CLOUD_OAUTH2.FAILURE.TITLE'),
                );
                const url = this.router.url.split('?')[0]
                    + `?jira-add-auth-step=${JiraAddAuthStepsEnum.CHOSE_JIRA_TYPE}`;
                this.router.navigateByUrl(url);
            }),
        );
    }, { dispatch: false });

    attachCloudApiTokenEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraAttachCloudApiTokenAction),
            switchMap(({ apiToken, connectionId }) => {
                return this.authService.attachCloudApiToken(apiToken, connectionId).pipe(
                    map(() => {
                        return jiraAttachCloudApiTokenSuccessAction();
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(jiraAttachCloudApiTokenFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    attachCloudApiTokenSuccessEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraAttachCloudApiTokenSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('JIRA.AUTH.NOTIFICATIONS.ATTACH_API_TOKEN.SUCCESS.MESSAGE'),
                    this.translate.instant('JIRA.AUTH.NOTIFICATIONS.ATTACH_API_TOKEN.SUCCESS.TITLE'),
                );
                const url = this.router.url.split('?')[0];
                this.router.navigateByUrl(url);
            }),
        );
    }, { dispatch: false });

    attachCloudApiTokenFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraAttachCloudApiTokenFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('JIRA.AUTH.NOTIFICATIONS.ATTACH_API_TOKEN.FAILURE.TITLE'),
                );
                const url = this.router.url.split('?')[0];
                this.router.navigateByUrl(url);
            }),
        );
    }, { dispatch: false });

    getSelfHostedOauth1RedirectUrlEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraGetSelfHostedOauth1RedirectUrlAction),
            switchMap(({ host, projectId }) => {
                return this.authService.getSelfHostedOauth1RedirectUrl(host, projectId).pipe(
                    map((url) => {
                        return jiraGetSelfHostedOauth1RedirectUrlSuccessAction({ url });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(jiraGetSelfHostedOauth1RedirectUrlFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getSelfHostedOauth1RedirectUrlSuccessEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraGetSelfHostedOauth1RedirectUrlSuccessAction),
            tap(({ url }) => {
                window.location.assign(url);
            }),
        );
    }, { dispatch: false });

    getSelfHostedOauth1RedirectUrlFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraGetSelfHostedOauth1RedirectUrlFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('JIRA.AUTH.NOTIFICATIONS.GET_SELF_HOSTED_OAUTH1_REDIRECT_URL.FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    verifySelfHostedOauth1Effect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraVerifySelfHostedOauth1Action),
            switchMap(({ token, project, sessionState }) => {
                return this.authService.verifySelfHostedOauth1(token, project, sessionState).pipe(
                    map(() => {
                        return jiraVerifySelfHostedOauth1SuccessAction();
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(jiraVerifySelfHostedOauth1FailureAction({ backendErrors }));
                    }),
                );
            })
        );
    });

    verifySelfHostedOauth1SuccessEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraVerifySelfHostedOauth1SuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('JIRA.AUTH.NOTIFICATIONS.VERIFY_SELF_HOSTED_OAUTH_1.SUCCESS.MESSAGE'),
                    this.translate.instant('JIRA.AUTH.NOTIFICATIONS.VERIFY_SELF_HOSTED_OAUTH_1.SUCCESS.TITLE'),
                );
                const url = this.router.url.split('?')[0];
                this.router.navigateByUrl(url);
            }),
            map(() => {
                return jiraGetConnectionsAction();
            }),
        );
    });

    verifySelfHostedOauth1FailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraVerifySelfHostedOauth1FailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('JIRA.AUTH.NOTIFICATIONS.VERIFY_SELF_HOSTED_OAUTH_1.FAILURE.TITLE'),
                );
                const url = this.router.url.split('?')[0];
                this.router.navigateByUrl(url);
            }),
        );
    }, { dispatch: false });

    authSelfHostedBasicEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraAuthSelfHostedBasicAction),
            switchMap(({ host, login, password, projectId }) => {
                return this.authService.authSelfHostedBasic(host, login, password, projectId).pipe(
                    map(() => {
                        return jiraAuthSelfHostedBasicSuccessAction();
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(jiraAuthSelfHostedBasicFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    authSelfHostedBasicSuccessEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraAuthSelfHostedBasicSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('JIRA.AUTH.NOTIFICATIONS.AUTH_SELF_HOSTED_BASIC.SUCCESS.MESSAGE'),
                    this.translate.instant('JIRA.AUTH.NOTIFICATIONS.AUTH_SELF_HOSTED_BASIC.SUCCESS.TITLE'),
                );
                const url = this.router.url.split('?')[0];
                this.router.navigateByUrl(url);
            }),
            map(() => {
                return jiraGetConnectionsAction();
            }),
        );
    });

    authSelfHostedBasicFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraAuthSelfHostedBasicFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('JIRA.AUTH.NOTIFICATIONS.AUTH_SELF_HOSTED_BASIC.FAILURE.TITLE'),
                );
                const url = this.router.url.split('?')[0];
                this.router.navigateByUrl(url);
            }),
        );
    }, { dispatch: false });

    logoutEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraLogoutAction),
            switchMap(({ connectionId }) => {
                return this.authService.logout(connectionId).pipe(
                    map(() => {
                        return jiraLogoutSuccessAction({ connectionId });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(jiraLogoutFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    logoutSuccessEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraLogoutSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('JIRA.AUTH.NOTIFICATIONS.LOGOUT.SUCCESS.MESSAGE'),
                    this.translate.instant('JIRA.AUTH.NOTIFICATIONS.LOGOUT.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    logoutFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(jiraLogoutFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('JIRA.AUTH.NOTIFICATIONS.LOGOUT.FAILURE.TITLE'),
                );
            }),
            map(() => {
                return jiraGetConnectionsAction();
            }),
        );
    });
}
