import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import {
    getCompanyConfigScreenshotsAction,
    getCompanyConfigScreenshotsFailureAction,
    getCompanyConfigScreenshotsSuccessAction,
    updateCompanyConfigScreenshotsAction,
    updateCompanyConfigScreenshotsFailureAction,
    updateCompanyConfigScreenshotsSuccessAction
} from '@app-client/store/company/actions/company-config-screenshots.action';
import { CompanyConfigService } from '@app-client/store/company/services/company-config.service';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class CompanyConfigScreenshotsEffect {
    constructor(
        private actions: Actions,
        private companyConfigService: CompanyConfigService,
        private toastr: ToastrService,
        private translate: TranslateService,
    ) {}

    getCompanyConfigScreenshots$ = createEffect(() => {
        return this.actions.pipe(
            ofType(getCompanyConfigScreenshotsAction),
            switchMap(() => {
                return this.companyConfigService.getCompanyConfigScreenshots().pipe(
                    map((companyConfigScreenshots) => {
                        return getCompanyConfigScreenshotsSuccessAction({ companyConfigScreenshots } );
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(getCompanyConfigScreenshotsFailureAction({ backendErrors }));
                    })
                );
            })
        );
    });

    getCompanyConfigScreenshotsFailure$ = createEffect(() => {
        return this.actions.pipe(
            ofType(getCompanyConfigScreenshotsFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('COMPANY_SETTINGS.COMPANY.COMPANY_DATA.NOTIFICATIONS.GET_COMPANY_DATA_FAIL.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    updateCompanyScreenshotsConfig$ = createEffect(() => {
        return this.actions.pipe(
            ofType(updateCompanyConfigScreenshotsAction),
            switchMap(({ companyConfigScreenshots }) => {
                return this.companyConfigService.updateCompanyConfigScreenshots(companyConfigScreenshots).pipe(
                    map(() => {
                        return updateCompanyConfigScreenshotsSuccessAction();
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(updateCompanyConfigScreenshotsFailureAction({ backendErrors }));
                    })
                );
            })
        );
    });

    updateCompanyConfigFailureScreenshots$ = createEffect(() => {
        return this.actions.pipe(
            ofType(updateCompanyConfigScreenshotsFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('COMPANY_SETTINGS.COMPANY.COMPANY_DATA.NOTIFICATIONS.GET_COMPANY_DATA_FAIL.TITLE'),
                );
            }),
        );
    }, { dispatch: false });
}
