import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AccessRequestInterface } from '@app-client/store/auth/access-request.interface';

import { environment } from '@env-client/environment';


@Injectable()
export class AccessRequestService {
    constructor(private httpClient: HttpClient) {}

    accessRequestSend(accessRequestData: AccessRequestInterface) {
        const url = environment.apiBaseUrl + '/user-requests';

        if (accessRequestData.id) {
            const id = accessRequestData.id;
            const data = {
                ...accessRequestData,
                id: undefined,
                email: undefined,
            };

            return this.httpClient.patch(url + '/' + id, { ...data });
        } else {
            return this.httpClient.post(url, { ...accessRequestData });
        }
    }
}
