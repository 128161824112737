import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment-timezone';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CompanyDomainInterface } from '@app-client/company/types/settings/company-domain.interface';
import { CompanySettingsInterface } from '@app-client/company/types/settings/company-settings.interface';
import { UpdateCompanySettingsInterface } from '@app-client/company/types/settings/update-company-settings.interface';
import { CompanyClosePeriodInterface } from '@app-client/store/company/company-close-period.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';

import { environment } from '@env-client/environment';


@Injectable()
export class CompanySettingsService {
    constructor(private httpClient: HttpClient) {}

    getCompanySettings(): Observable<CompanySettingsInterface> {
        const url = environment.apiBaseUrl + '/my-company';

        return this.httpClient.get(url).pipe(map((response: ResponseInterface<CompanySettingsInterface>) => {
            return response.body;
        }));
    }

    updateCompanySettings(settings: UpdateCompanySettingsInterface): Observable<CompanySettingsInterface> {
        const url = environment.apiBaseUrl + '/my-company';

        return this.httpClient.patch(url, { ...settings }).pipe(map((response: ResponseInterface<CompanySettingsInterface>) => {
            return response.body;
        }));
    }

    updateCompanyAvatar(avatar: string): Observable<CompanySettingsInterface> {
        const url = environment.apiBaseUrl + '/my-company/avatar';

        return this.httpClient.post(url, { avatar }).pipe(map((response: ResponseInterface<CompanySettingsInterface>) => {
            return response.body;
        }));
    }

    getCompanyDomains(): Observable<Array<CompanyDomainInterface>> {
        const url = environment.apiBaseUrl + '/company-domains';

        return this.httpClient.get(url).pipe(map((response: ResponseInterface<Array<CompanyDomainInterface>>) => {
            return response.body;
        }));
    }

    createCompanyDomain(domainData: { name: string }): Observable<CompanyDomainInterface> {
        const url = environment.apiBaseUrl + '/company-domains';

        return this.httpClient.post(url, domainData).pipe(map((response: ResponseInterface<CompanyDomainInterface>) => {
            return response.body;
        }));
    }

    deleteCompanyDomain(id: string): Observable<any> {
        const url = environment.apiBaseUrl + '/company-domains';

        return this.httpClient.delete(url + '/' + id);
    }

    getCompanyClosePeriod(): Observable<Moment> {
        const url = environment.apiBaseUrl + '/my-company/close-period';

        return this.httpClient.get(url).pipe(
            map((response: ResponseInterface<CompanyClosePeriodInterface>) => response.body),
            map(({ timestamp }) => timestamp ? moment(timestamp * 1000) : null)
        );
    }
}
