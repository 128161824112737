import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TimeFormatEnum } from '@app-client/report/by-projects/types/report-filters.interface';
import { ReportUsingApplicationsFiltersInterface } from '@app-client/report/using-applications/types/report-using-applications-filters.interface';
import { ReportUsingApplicationsReportInterface } from '@app-client/report/using-applications/types/report-using-applications-report.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';

import { environment } from '@env-client/environment';


@Injectable()
export class ReportUsingApplicationsService {
    constructor(
        private http: HttpClient,
    ) {}

    getReport(filters: ReportUsingApplicationsFiltersInterface): Observable<ReportUsingApplicationsReportInterface> {
        const url = `${environment.apiBaseUrl}/reports/app-use`;
        const body = {
            from: filters.interval.from.unix(),
            to: filters.interval.to.unix(),
            projectSpaces: filters.projectSpaces.map((projectSpace) => {
                return projectSpace.id;
            }),
            projects: filters.projects.map((project) => {
                return project.id;
            }),
            users: filters.userIds,
            groupBy: filters.groupBy,
            showDiagram: filters.showDiagram,
            hideIneffective: filters.hideIneffective,
            hideNeutral: filters.hideNeutral,
            hideEffective: filters.hideEffective,
            myEmployees: filters.myEmployees,
        };

        return this.http.post<ResponseInterface<ReportUsingApplicationsReportInterface>>(url, body).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    exportGoogleSpreadsheet(filters: ReportUsingApplicationsFiltersInterface): Observable<void> {
        const url = `${environment.apiBaseUrl}/reports/app-use/google`;
        const body = {
            from: filters.interval.from.unix(),
            to: filters.interval.to.unix(),
            projectSpaces: filters.projectSpaces.map((projectSpace) => {
                return projectSpace.id;
            }),
            projects: filters.projects.map((project) => {
                return project.id;
            }),
            users: filters.userIds,
            groupBy: filters.groupBy,
            showDiagram: filters.showDiagram,
            hideIneffective: filters.hideIneffective,
            hideNeutral: filters.hideNeutral,
            hideEffective: filters.hideEffective,
            timeFormat: ((format: TimeFormatEnum) => {
                switch (format) {
                    case TimeFormatEnum.HUMAN:
                        return 0;
                    case TimeFormatEnum.HOURS:
                        return 1;
                    case TimeFormatEnum.MINUTES:
                        return 2;
                    case TimeFormatEnum.HOURS_WITHOUT_LABELS:
                        return 3;
                }
            })(filters.timeFormat),
            myEmployees: filters.myEmployees,
        };

        return this.http.post<ResponseInterface<void>>(url, body).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }
}
