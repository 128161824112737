import { createAction, props } from '@ngrx/store';

import { ProjectTaskInterface } from '@app-client/project/types/project-task.interface';
import { ProjectInterface } from '@app-client/project/types/project.interface';
import { SharedDataFiltersActionTypes } from '@app-client/store/shared-data-filters/shared-data-filters-action-types';
import { UserInterface } from '@app-client/user/types/user.interface';


export const setSharedDataFiltersAction = createAction(
    SharedDataFiltersActionTypes.SET_SHARED_DATA_FILTERS,
    props<{ user: UserInterface, project: ProjectInterface, task: ProjectTaskInterface }>(),
);

export const setSharedDataFiltersUserAction = createAction(
    SharedDataFiltersActionTypes.SET_SHARED_DATA_FILTERS_USER,
    props<{ user: UserInterface }>(),
);

export const setSharedDataFiltersProjectAction = createAction(
    SharedDataFiltersActionTypes.SET_SHARED_DATA_FILTERS_PROJECT,
    props<{ project: ProjectInterface }>(),
);

export const setSharedDataFiltersTaskAction = createAction(
    SharedDataFiltersActionTypes.SET_SHARED_DATA_FILTERS_TASK,
    props<{ task: ProjectTaskInterface }>(),
);

export const setSharedDataFiltersDayAction = createAction(
    SharedDataFiltersActionTypes.SET_SHARED_DATA_FILTERS_DAY,
    props<{ day: string }>(),
);

export const clearSharedDataFiltersAction = createAction(
    SharedDataFiltersActionTypes.CLEAR_SHARED_DATA_FILTERS,
);
