import { createAction, props } from '@ngrx/store';

import { ProjectSpaceInterface } from '@app-client/project-space/types/project-space.interface';
import { ProjectTaskInterface } from '@app-client/project/types/project-task.interface';
import { ProjectInterface } from '@app-client/project/types/project.interface';
import { SharedReportFiltersTypesEnum } from '@app-client/store/report/shared-filters/shared-report-filters-types.enum';
import { UserInterface } from '@app-client/user/types/user.interface';


export const sharedReportFiltersSetFiltersAction = createAction(
    SharedReportFiltersTypesEnum.SET_FILTERS,
    props<{
        projectSpaces: Array<ProjectSpaceInterface>,
        projects: Array<ProjectInterface>,
        users: Array<UserInterface>,
        tasks: Array<ProjectTaskInterface>,
        from: string,
        to: string,
    }>(),
);
