import { createAction, props } from '@ngrx/store';

import { ActivatedUserResponseInterface } from '@app-client/marketplace/modules/details/types/activated-user-response.interface';
import { CompanyModuleResponseInterface } from '@app-client/marketplace/modules/details/types/company-module-response.interface';
import { ModuleDetailsResponseInterface } from '@app-client/marketplace/modules/details/types/module-details-response.interface';
import { EnabledModuleResponseInterface } from '@app-client/marketplace/modules/list/types/enabled-module-response.interface';
import { ModuleResponseInterface } from '@app-client/marketplace/modules/list/types/module-response.interface';
import { MarketplaceModulesActionsEnum } from '@app-client/store/marketplace/modules/marketplace-modules-actions.enum';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


export const marketplaceModulesGetListAction = createAction(
    MarketplaceModulesActionsEnum.GET_LIST,
);

export const marketplaceModulesGetListSuccessAction = createAction(
    MarketplaceModulesActionsEnum.GET_LIST_SUCCESS,
    props<{ modules: Array<ModuleResponseInterface> }>(),
);

export const marketplaceModulesGetListFailureAction = createAction(
    MarketplaceModulesActionsEnum.GET_LIST_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const marketplaceModulesGetDetailsAction = createAction(
    MarketplaceModulesActionsEnum.GET_DETAILS,
    props<{ id: string }>(),
);

export const marketplaceModulesGetDetailsSuccessAction = createAction(
    MarketplaceModulesActionsEnum.GET_DETAILS_SUCCESS,
    props<{ details: ModuleDetailsResponseInterface }>(),
);

export const marketplaceModulesGetDetailsFailureAction = createAction(
    MarketplaceModulesActionsEnum.GET_DETAILS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const marketplaceModulesInstallAction = createAction(
    MarketplaceModulesActionsEnum.INSTALL,
    props<{ moduleId: string, tariffId: string, forAll: boolean | null }>(),
);

export const marketplaceModulesInstallSuccessAction = createAction(
    MarketplaceModulesActionsEnum.INSTALL_SUCCESS,
    props<{ moduleId: string, companyModule: CompanyModuleResponseInterface }>(),
);

export const marketplaceModulesInstallFailureAction = createAction(
    MarketplaceModulesActionsEnum.INSTALL_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const marketplaceModulesUninstallAction = createAction(
    MarketplaceModulesActionsEnum.UNINSTALL,
    props<{ moduleId: string }>(),
);

export const marketplaceModulesUninstallSuccessAction = createAction(
    MarketplaceModulesActionsEnum.UNINSTALL_SUCCESS,
    props<{ moduleId: string }>(),
);

export const marketplaceModulesUninstallFailureAction = createAction(
    MarketplaceModulesActionsEnum.UNINSTALL_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const marketplaceModulesUpdatePlanAction = createAction(
    MarketplaceModulesActionsEnum.UPDATE_TARIFF,
    props<{ moduleId: string, tariffId: string }>(),
);

export const marketplaceModulesUpdatePlanSuccessAction = createAction(
    MarketplaceModulesActionsEnum.UPDATE_TARIFF_SUCCESS,
    props<{ moduleId: string, companyModule: CompanyModuleResponseInterface }>(),
);

export const marketplaceModulesUpdatePlanFailureAction = createAction(
    MarketplaceModulesActionsEnum.UPDATE_PLAN_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const marketplaceModulesUpdateSettingsAction = createAction(
    MarketplaceModulesActionsEnum.UPDATE_SETTINGS,
    props<{
        moduleId: string,
        forAll: boolean,
        users: Array<ActivatedUserResponseInterface>,
    }>(),
);

export const marketplaceModulesUpdateSettingsSuccessAction = createAction(
    MarketplaceModulesActionsEnum.UPDATE_SETTINGS_SUCCESS,
    props<{
        moduleId: string,
        companyModule: CompanyModuleResponseInterface,
        usersToAdd: Array<ActivatedUserResponseInterface>,
        usersToDelete: Array<ActivatedUserResponseInterface>,
    }>(),
);

export const marketplaceModulesUpdateSettingsFailureAction = createAction(
    MarketplaceModulesActionsEnum.UPDATE_SETTINGS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const marketplaceModulesGetActivatedUsersAction = createAction(
    MarketplaceModulesActionsEnum.GET_ACTIVATED_USERS,
);

export const marketplaceModulesGetActivatedUsersSuccessAction = createAction(
    MarketplaceModulesActionsEnum.GET_ACTIVATED_USERS_SUCCESS,
    props<{ activatedUsers: Array<ActivatedUserResponseInterface> }>(),
);

export const marketplaceModulesGetActivatedUsersFailureAction = createAction(
    MarketplaceModulesActionsEnum.GET_ACTIVATED_USERS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const marketplaceModulesGetEnabledAction = createAction(
    MarketplaceModulesActionsEnum.GET_ENABLED,
);

export const marketplaceModulesGetEnabledSuccessAction = createAction(
    MarketplaceModulesActionsEnum.GET_ENABLED_SUCCESS,
    props<{ enabled: Array<EnabledModuleResponseInterface> }>(),
);

export const marketplaceModulesGetEnabledFailureAction = createAction(
    MarketplaceModulesActionsEnum.GET_ENABLED_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const marketplaceModulesGetFreeTariffsAction = createAction(
    MarketplaceModulesActionsEnum.GET_FREE_TARIFFS,
);

export const marketplaceModulesGetFreeTariffsSuccessAction = createAction(
    MarketplaceModulesActionsEnum.GET_FREE_TARIFFS_SUCCESS,
    props<{ freeTariffs: Array<string> }>(),
);

export const marketplaceModulesGetFreeTariffsFailureAction = createAction(
    MarketplaceModulesActionsEnum.GET_FREE_TARIFFS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
