import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TimeFormatEnum } from '@app-client/report/by-projects/types/report-filters.interface';
import { isUseTimeslotsSelector } from '@app-client/store/auth/selectors/auth.selectors';


export interface SecondsToTimeStringPipeOptionsInterface {
    hideSeconds?: boolean;
    format?: TimeFormatEnum;
}

const defaultOptions: SecondsToTimeStringPipeOptionsInterface = {
    hideSeconds: false,
    format: TimeFormatEnum.COLON,
};

@Pipe({
    name: 'secondsToDurationString',
})
export class SecondsToDurationStringPipe implements PipeTransform {
    constructor(
        private store: Store,
        private translate: TranslateService,
    ) {}

    transform(value: number, options?: SecondsToTimeStringPipeOptionsInterface): Observable<string> {
        return combineLatest([
            this.store.select(isUseTimeslotsSelector),
            this.translate.stream('REPORT.FILTERS.TIME_FORMAT.UNITS.HOUR'),
            this.translate.stream('REPORT.FILTERS.TIME_FORMAT.UNITS.MINUTE'),
            this.translate.stream('REPORT.FILTERS.TIME_FORMAT.UNITS.SECOND'),
        ]).pipe(
            map(([isUseTimeslots, hourUnit, minuteUnit, secondUnit]) => {
                options = {
                    ...defaultOptions,
                    hideSeconds: isUseTimeslots,
                    ...options,
                };

                if (undefined === value) {
                    value = 0;
                }

                switch (options.format) {
                    case TimeFormatEnum.COLON: {
                        const hours = Math.trunc(value / 3600);
                        const minutes = Math.trunc(value / 60) % 60;
                        let seconds: number;
                        if (options.hideSeconds === false) {
                            seconds = value % 60;
                        }
                        let result = this.normalizeNumber(hours) + ':' + this.normalizeNumber(minutes);
                        if (seconds !== undefined) {
                            result += ':' + this.normalizeNumber(seconds);
                        }

                        return result;
                    }
                    case TimeFormatEnum.HUMAN: {
                        const hours = Math.trunc(value / 3600);
                        const minutes = Math.trunc(value / 60) % 60;
                        let seconds: number;
                        if (options.hideSeconds === false) {
                            seconds = value % 60;
                        }
                        let result = hours + ' ' + hourUnit + ' ' + minutes + ' ' + minuteUnit;
                        if (seconds !== undefined) {
                            result += ' ' + seconds + ' ' + secondUnit;
                        }

                        return result;
                    }
                    case TimeFormatEnum.HUMAN_SHORT: {
                        const hours = Math.trunc(value / 3600);
                        const minutes = Math.trunc(value / 60) % 60;
                        let seconds: number;
                        if (options.hideSeconds === false) {
                            seconds = value % 60;
                        }
                        let result = '';
                        if (hours !== undefined && hours !== 0) {
                            result += hours + ' ' + hourUnit;
                        }
                        if (minutes !== 0) {
                            result += ' ' + minutes + ' ' + minuteUnit;
                        }
                        if (seconds !== undefined && seconds !== 0) {
                            result += ' ' + seconds + ' ' + secondUnit;
                        }
                        if (result === undefined) {
                            result = '0 ' + options.hideSeconds ? minuteUnit : secondUnit;
                        }

                        return result;
                    }
                    case TimeFormatEnum.MINUTES: {
                        const minutes = value / 60;
                        return minutes.toFixed(2) + ' ' + minuteUnit;
                    }
                    case TimeFormatEnum.HOURS: {
                        const hours = value / 3600;
                        return hours.toFixed(2) + ' ' + hourUnit;
                    }
                    case TimeFormatEnum.HOURS_WITHOUT_LABELS: {
                        const hours = value / 3600;
                        return hours.toFixed(2);
                    }
                }
            })
        );
    }

    normalizeNumber(value: number): string {
        let result = value.toString();
        if (value < 10) {
            result = '0' + result;
        }

        return result;
    }
}
