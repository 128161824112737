import { createReducer, on } from '@ngrx/store';

import {
    approveTimeRequestAction,
    approveTimeRequestFailureAction,
    approveTimeRequestSuccessAction,
    getTimeRequestListAction,
    getTimeRequestListFailureAction,
    getTimeRequestListSuccessAction,
    rejectTimeRequestAction,
    rejectTimeRequestFailureAction,
    rejectTimeRequestSuccessAction,
    setTimeRequestListQueryParamsAction,
} from '@app-client/store/time-request/actions/time-request.action';
import { TimeRequestStateInterface } from '@app-client/store/time-request/time-request.state.interface';


const initialState: TimeRequestStateInterface = {
    listQueryParams: null,
    timeRequestList: [],
    isListLoading: false,
    listHasLoadingMore: false,
    isApproveRejectInProgress: false,
};

const reducer = createReducer(
    initialState,
    on(
        setTimeRequestListQueryParamsAction,
        (state, action) => ({
            ...state,
            listQueryParams: action.params,
        }),
    ),
    on(
        getTimeRequestListAction,
        state => ({
            ...state,
            isListLoading: true,
            timeRequestList: state.listQueryParams.page === 1 ? [] : state.timeRequestList,
        }),
    ),
    on(
        getTimeRequestListSuccessAction,
        (state, action) => ({
            ...state,
            isListLoading: false,
            timeRequestList: [...state.timeRequestList, ...action.response.items],
            listHasLoadingMore: action.response.page < action.response.totalPages,
        }),
    ),
    on(
        getTimeRequestListFailureAction,
        state => ({
            ...state,
            isListLoading: false,
            listHasLoadingMore: false,
        }),
    ),

    on(
        approveTimeRequestAction,
        rejectTimeRequestAction,
        state => ({
            ...state,
            isApproveRejectInProgress: true,
        }),
    ),
    on(
        approveTimeRequestSuccessAction,
        approveTimeRequestFailureAction,
        rejectTimeRequestSuccessAction,
        rejectTimeRequestFailureAction,
        state => ({
            ...state,
            isApproveRejectInProgress: false,
        }),
    ),
);

export const timeRequestsReducer = (state, action) => reducer(state, action);
