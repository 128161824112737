import { createAction, props } from '@ngrx/store';

import { AccountResponseInterface } from '@app-client/payment/overview/types/account-response.interface';
import { CardResponseInterface } from '@app-client/payment/overview/types/card-response.interface';
import { PayResponseInterface } from '@app-client/payment/overview/types/pay-response.interface';
import { MarketplaceAccountActionsEnum } from '@app-client/store/marketplace/account/marketplace-account-actions.enum';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


export const marketplaceGetAccountAction = createAction(
    MarketplaceAccountActionsEnum.GET_DATA,
);

export const marketplaceGetAccountSuccessAction = createAction(
    MarketplaceAccountActionsEnum.GET_DATA_SUCCESS,
    props<{ account: AccountResponseInterface }>(),
);

export const marketplaceGetAccountFailureAction = createAction(
    MarketplaceAccountActionsEnum.GET_DATA_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const marketplaceCardsGetListAction = createAction(
    MarketplaceAccountActionsEnum.GET_CARDS,
);

export const marketplaceCardsGetListSuccessAction = createAction(
    MarketplaceAccountActionsEnum.GET_CARDS_SUCCESS,
    props<{ cards: Array<CardResponseInterface> }>(),
);

export const marketplaceCardsGetListFailureAction = createAction(
    MarketplaceAccountActionsEnum.GET_CARDS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const marketplaceCardsRemoveAction = createAction(
    MarketplaceAccountActionsEnum.REMOVE_CARD,
    props<{ id: string }>(),
);

export const marketplaceCardsRemoveSuccessAction = createAction(
    MarketplaceAccountActionsEnum.REMOVE_CARD_SUCCESS,
    props<{ id: string }>(),
);

export const marketplaceCardsRemoveFailureAction = createAction(
    MarketplaceAccountActionsEnum.REMOVE_CARD_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const marketplaceCardsGetInitKeyAction = createAction(
    MarketplaceAccountActionsEnum.GET_INIT_KEY,
);

export const marketplaceCardsGetInitKeySuccessAction = createAction(
    MarketplaceAccountActionsEnum.GET_INIT_KEY_SUCCESS,
    props<{ initKey: string }>(),
);

export const marketplaceCardsGetInitKeyFailureAction = createAction(
    MarketplaceAccountActionsEnum.GET_INIT_KEY_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const  marketplaceAccountPayAction = createAction(
    MarketplaceAccountActionsEnum.PAY,
    props<{ cardId: string, amount: number }>(),
);

export const marketplaceAccountPaySuccessAction = createAction(
    MarketplaceAccountActionsEnum.PAY_SUCCESS,
    props<{ payInfo: PayResponseInterface }>(),
);

export const marketplaceAccountPayFailureAction = createAction(
    MarketplaceAccountActionsEnum.PAY_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const marketplaceAccountChargeAction = createAction(
    MarketplaceAccountActionsEnum.CHARGE,
    props<{ amount: number }>(),
);


export const marketplaceAccountChargeSuccessAction = createAction(
    MarketplaceAccountActionsEnum.CHARGE_SUCCESS,
    props<{ chargeSecret: string }>(),
);

export const marketplaceAccountChargeFailureAction = createAction(
    MarketplaceAccountActionsEnum.CHARGE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
