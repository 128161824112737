<ng-container *ngIf="(currentUser$ | async)">
   <ng-container *ngIf="newYearService.isAllow$ | async">
       <new-year></new-year>
   </ng-container>
    <app-header></app-header>
    <div class="main-container">

        <app-sidebar></app-sidebar>

        <div class="page-content">

            <router-outlet></router-outlet>

            <div *ngIf="isRouterNavigationInProgress" class="route-loading">
                <ui-spinner></ui-spinner>
                <div>
                    {{ 'GLOBAL.LOADING' | translate }}…
                </div>
            </div>

        </div>

    </div>
</ng-container>

<div *ngIf="null === (currentUser$ | async) || (isModulesLoading$ | async) === true" class="loading">
    <ui-spinner></ui-spinner>
    <div>
        {{ 'GLOBAL.LOADING' | translate }}…
    </div>
</div>

<app-logged-as></app-logged-as>
