import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { JiraConnectionInterface } from '@app-client/jira/types/jira-connection.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';

import { environment } from '@env-client/environment';


@Injectable()
export class JiraAuthService {
    constructor(
        private http: HttpClient,
    ) {}

    getConnections(): Observable<Array<JiraConnectionInterface>> {
        const url = `${environment.apiBaseUrl}/jira/auth/all-saved`;

        return this.http.get<ResponseInterface<Array<JiraConnectionInterface>>>(url).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    getCloudOauth2RedirectUrl(projectId: string): Observable<string> {
        const url = `${environment.apiBaseUrl}/jira/auth/oauth2`;
        let params = new HttpParams();
        params = params.set('projectId', projectId);

        return this.http.get<ResponseInterface<{ url: string }>>(url, { params }).pipe(
            map((response) => {
                return response.body.url;
            }),
        );
    }

    verifyCloudOauth2(code: string | undefined, error: string | undefined, state: string): Observable<void> {
        const url = `${environment.apiBaseUrl}/jira/auth/oauth2/verify`;
        let params = new HttpParams();
        if (!!code) {
            params = params.set('code', code);
        }
        if (!!error) {
            params = params.set('error', error);
        }
        params = params.set('state', state);

        return this.http.get<ResponseInterface<void>>(url, { params }).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    attachCloudApiToken(apiToken: string, connectionId: string): Observable<void> {
        const url = `${environment.apiBaseUrl}/jira/auth/auth/api-token`;
        const body = {
            apiToken,
            jiraConnectId: connectionId,
        };

        return this.http.post<ResponseInterface<void>>(url, body).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    getSelfHostedOauth1RedirectUrl(host: string, projectId: string): Observable<string> {
        const url = `${environment.apiBaseUrl}/jira/auth/server/auth`;
        const body = {
            type: 'oauth',
            host,
            redirectProjectId: projectId,
        };

        return this.http.post<ResponseInterface<{ authorizeUrl: string }>>(url, body).pipe(
            map((response) => {
                return response.body.authorizeUrl;
            }),
        );
    }

    verifySelfHostedOauth1(token: string, project: string, sessionState: string): Observable<void> {
        const url = `${environment.apiBaseUrl}/jira/auth/oauth1/verify`;
        let params = new HttpParams();
        params = params.set('jiraToken', token);
        params = params.set('projectId', project);
        params = params.set('sessionState', sessionState);

        return this.http.get<ResponseInterface<void>>(url, { params }).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    authSelfHostedBasic(host: string, login: string, password: string, projectId: string): Observable<void> {
        const url = `${environment.apiBaseUrl}/jira/auth/server/auth`;
        const body = {
            type: 'basic',
            host,
            jiraLogin: login,
            jiraPassword: password,
            redirectProjectId: projectId,
        };

        return this.http.post<ResponseInterface<void>>(url, body).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    logout(connectionId: string): Observable<void> {
        const url = `${environment.apiBaseUrl}/jira/auth/auth/logout`;
        const body = {
            jiraConnectId: connectionId,
        };

        return this.http.post<ResponseInterface<void>>(url, body).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }
}
