import { createReducer, on } from '@ngrx/store';

import {
    reportWorkDayClearDataAction,
    reportWorkDayExportGoogleSpreadsheetAction,
    reportWorkDayExportGoogleSpreadsheetFailureAction,
    reportWorkDayExportGoogleSpreadsheetSuccessAction,
    reportWorkDayGetReportAction,
    reportWorkDayGetReportFailureAction,
    reportWorkDayGetReportSuccessAction,
} from '@app-client/store/report/work-day/actions/report-work-day.actions';
import { ReportWorkDayStateInterface } from '@app-client/store/report/work-day/report-work-day-state.interface';


const initialState: ReportWorkDayStateInterface = {
    isExportGoogleSpreadsheetInProgress: false,
    isGetReportInProgress: false,
    report: null,
    savedFilters: null,
};

const reducer = createReducer(
    initialState,
    on(
        reportWorkDayGetReportAction,
        (state) => ({
            ...state,
            isGetReportInProgress: true,
        }),
    ),
    on(
        reportWorkDayGetReportSuccessAction,
        (state, action) => ({
            ...state,
            isGetReportInProgress: false,
            report: action.report,
            savedFilters: action.filters,
        }),
    ),
    on(
        reportWorkDayGetReportFailureAction,
        (state) => ({
            ...state,
            isGetReportInProgress: false,
        }),
    ),
    on(
        reportWorkDayExportGoogleSpreadsheetAction,
        (state) => ({
            ...state,
            isExportGoogleSpreadsheetInProgress: true,
        }),
    ),
    on(
        reportWorkDayExportGoogleSpreadsheetSuccessAction,
        reportWorkDayExportGoogleSpreadsheetFailureAction,
        (state) => ({
            ...state,
            isExportGoogleSpreadsheetInProgress: false,
        }),
    ),
    on(
        reportWorkDayClearDataAction,
        (state) => ({
            ...state,
            savedFilters: null,
            report: null,
        }),
    ),
);

export const reportWorkDayReducer = (state, action) => reducer(state, action);
