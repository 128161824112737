import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UIConfirmationDialogService } from '@umbrella-web/attrack-ui-module';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import {
    createProjectMemberAction,
    createProjectMemberFailureAction,
    createProjectMemberSuccessAction,
    getProjectMemberAction,
    getProjectMemberFailureAction,
    getProjectMemberListAction,
    getProjectMemberListFailureAction,
    getProjectMemberListSuccessAction,
    getProjectMemberSuccessAction,
    projectMembersBatchUpdatePermissionAction,
    projectMembersBatchUpdatePermissionsFailureAction,
    projectMembersBatchUpdatePermissionsSuccessAction,
    removeProjectMemberAction,
    removeProjectMemberFailureAction,
    removeProjectMemberSuccessAction,
    requiresDeleteProjectMemberConfirmationAction,
    setProjectMembersListQueryParamsAction,
    updateProjectMemberAction,
    updateProjectMemberFailureAction,
    updateProjectMemberSuccessAction,
} from '@app-client/store/project-members/actions/project-members.action';
import { projectMemberListQueryParamsSelector } from '@app-client/store/project-members/selectors/project-members.selector';
import { ProjectMembersService } from '@app-client/store/project-members/serivces/project-members.service';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class ProjectMembersEffect {

    constructor(
        private actions$: Actions,
        private projectMembersService: ProjectMembersService,
        private store$: Store,
        private toastr: ToastrService,
        private translate: TranslateService,
        private router: Router,
        private dialogService: UIConfirmationDialogService,
    ) {}

    getProjectMemberList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getProjectMemberListAction),
            withLatestFrom(this.store$.select(projectMemberListQueryParamsSelector)),
            switchMap(([, queryParams]) => {
                return this.projectMembersService.getProjectMembers(queryParams).pipe(
                    map(response => {
                        return getProjectMemberListSuccessAction({ response });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(getProjectMemberListFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getProjectMemberListFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getProjectMemberListFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('PROJECT_MEMBERS.NOTIFICATIONS.GET_PROJECT_MEMBERS_LIST.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    createProjectMember$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createProjectMemberAction),
            switchMap(({ createProjectMember }) => {
                return this.projectMembersService.createProjectMember(createProjectMember).pipe(
                    map(response => {
                        return createProjectMemberSuccessAction({ response });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(createProjectMemberFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    createProjectMemberSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createProjectMemberSuccessAction),
            tap(({ response }) => {
                this.toastr.success(
                    this.translate.instant('PROJECT_MEMBERS.NOTIFICATIONS.CREATE.SUCCESS.MESSAGE'),
                    this.translate.instant('PROJECT_MEMBERS.NOTIFICATIONS.CREATE.SUCCESS.TITLE'),
                );
                this.router.navigate(['projects', response.body.project.id, 'team', response.body.id, 'details']);
            }),
        );
    }, { dispatch: false });

    createProjectMemberFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createProjectMemberFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('PROJECT_MEMBERS.NOTIFICATIONS.CREATE.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    updateProjectMember$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateProjectMemberAction),
            switchMap(({ id, updateProjectMember }) => {
                return this.projectMembersService.updateProjectMember(id, updateProjectMember).pipe(
                    map(response => {
                        return updateProjectMemberSuccessAction({ response });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(updateProjectMemberFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    updateProjectMemberSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateProjectMemberSuccessAction),
            tap(({ response }) => {
                this.toastr.success(
                    this.translate.instant('PROJECT_MEMBERS.NOTIFICATIONS.UPDATE.SUCCESS.MESSAGE'),
                    this.translate.instant('PROJECT_MEMBERS.NOTIFICATIONS.UPDATE.SUCCESS.TITLE'),
                );
                this.router.navigate(['projects', response.body.project.id, 'team', response.body.id, 'details']);
            }),
        );
    }, { dispatch: false });

    updateProjectMemberFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateProjectMemberFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('PROJECT_MEMBERS.NOTIFICATIONS.UPDATE.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    removeProjectMember$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(removeProjectMemberAction),
            switchMap(({ projectId, memberId, force }) => {
                return this.projectMembersService.removeProjectMember(memberId, force).pipe(
                    map(() => {
                        return removeProjectMemberSuccessAction({ projectId });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        if (httpErrorResponse.status === 409 && !force){
                            return of(requiresDeleteProjectMemberConfirmationAction({projectId, memberId}));
                        }
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(removeProjectMemberFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    requiresDeleteUserConfirmation$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(requiresDeleteProjectMemberConfirmationAction),
            tap(({ projectId, memberId }) => {
                this.dialogService.showConfirmationDialog({
                    title: this.translate.stream('USERS.DELETE_FORCE_CONFIRMATION.TITLE'),
                    message: this.translate.stream('USERS.DELETE_FORCE_CONFIRMATION.TEXT'),
                    okLabel: this.translate.stream('USERS.DELETE_FORCE_CONFIRMATION.OK_LABEL'),
                    cancelLabel: this.translate.stream('USERS.DELETE_FORCE_CONFIRMATION.CANCEL_LABEL'),
                    okCallback: () => {
                        this.store$.dispatch(removeProjectMemberAction({ projectId, memberId, force: true }));
                    },
                });
            })
        );
    }, { dispatch: false });

    removeProjectMemberSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(removeProjectMemberSuccessAction),
            tap(({ projectId }) => {
                this.toastr.success(
                    this.translate.instant('PROJECT_MEMBERS.NOTIFICATIONS.DELETE.SUCCESS.MESSAGE'),
                    this.translate.instant('PROJECT_MEMBERS.NOTIFICATIONS.DELETE.SUCCESS.TITLE'),
                );
                this.store$.select(projectMemberListQueryParamsSelector).pipe(
                    take(1),
                ).subscribe(params => {
                    this.store$.dispatch(setProjectMembersListQueryParamsAction({
                        queryParams: {
                            ...params,
                            page: 1,
                        }
                    }));
                    this.store$.dispatch(getProjectMemberListAction());
                });
                this.router.navigate(['projects', projectId, 'team']);
            }),
        );
    }, { dispatch: false });

    removeProjectMemberFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(removeProjectMemberFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('PROJECT_MEMBERS.NOTIFICATIONS.DELETE.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    getProjectMember$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getProjectMemberAction),
            switchMap(({ id }) => {
                return this.projectMembersService.getProjectMember(id).pipe(
                    map(response => {
                        return getProjectMemberSuccessAction({ response });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse.error.message,
                            errors: httpErrorResponse.error.errors,
                        };

                        return of(getProjectMemberFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getProjectMemberFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getProjectMemberFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('PROJECT_MEMBERS.NOTIFICATIONS.GET_ONE.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    batchUpdateProjectMemberPermission$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(projectMembersBatchUpdatePermissionAction),
            mergeMap(({ ids, permission, action }) => {
                return this.projectMembersService.batchUpdatePermission(ids, permission, action).pipe(
                    map(() => {
                        return projectMembersBatchUpdatePermissionsSuccessAction({ ids, permission, action });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse.error.message,
                            errors: httpErrorResponse.error.errors,
                        };

                        return of(projectMembersBatchUpdatePermissionsFailureAction({ ids, backendErrors }));
                    }),
                );
            }),
        );
    });
}
