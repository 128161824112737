<ng-container *ngIf="false === (isForgotPasswordTokenValidateInProgress$ | async)">
    <p class="subtitle mb-8 center">
        {{ 'LOGIN.FORGOT_PASSWORD_FORM.TITLE' | translate }}
        <br>
        <span *ngIf="forgotPasswordRequestedForEmail$ | async">
            <strong>{{ forgotPasswordRequestedForEmail$ | async }}</strong>
        </span>
    </p>
    <form
        [formGroup]="form"
        (ngSubmit)="submit()"
        novalidate
    >
        <div
            *ngIf="false === (isForgotPasswordRequestSuccess$ | async)"
            class="row mb-40"
        >
            <ui-form-group [has-errors]="password.invalid && (password.dirty || password.touched)">
                <label ui-label for="password">
                    {{ 'LOGIN.FORGOT_PASSWORD_FORM.PASSWORD.LABEL' | translate }}
                </label>
                <div class="input-wrapper">
                    <input
                        #passwordInput
                        ui-input
                        formControlName="password"
                        id="password"
                        type="password"
                        placeholder="{{ 'LOGIN.FORGOT_PASSWORD_FORM.PASSWORD.PLACEHOLDER' | translate }}"
                        autocomplete="new-password"
                    />
                    <mat-icon
                        [svgIcon]="passwordInput.type === 'password' ? 'attrack-eye-hide' : 'attrack-eye-open'"
                        (click)="passwordInput.type === 'password' ? passwordInput.type = 'text' : passwordInput.type = 'password'"
                    ></mat-icon>
                </div>
                <div
                    *ngIf="password.invalid && (password.dirty || password.touched)"
                    ui-hint
                    app-form-errors
                    [formControlRef]="password"
                    controlName="{{ 'LOGIN.FORGOT_PASSWORD_FORM.PASSWORD.LABEL' | translate }}"
                    controlNameGender="male"
                ></div>
            </ui-form-group>
            <div class="field-margin"></div>
            <ui-form-group [has-errors]="passwordConfirm.invalid && (passwordConfirm.dirty || passwordConfirm.touched)">
                <label ui-label for="passwordConfirm">
                    {{ 'LOGIN.FORGOT_PASSWORD_FORM.CONFIRM_PASSWORD.LABEL' | translate }}
                </label>
                <div class="input-wrapper">
                    <input
                        #passwordConfirmInput
                        ui-input
                        formControlName="passwordConfirm"
                        id="passwordConfirm"
                        type="password"
                        placeholder="{{ 'LOGIN.FORGOT_PASSWORD_FORM.CONFIRM_PASSWORD.PLACEHOLDER' | translate }}"
                        autocomplete="new-password"
                    />
                    <mat-icon
                        [svgIcon]="passwordConfirmInput.type === 'password' ? 'attrack-eye-hide' : 'attrack-eye-open'"
                        (click)="passwordConfirmInput.type === 'password' ? passwordConfirmInput.type = 'text' : passwordConfirmInput.type = 'password'"
                    ></mat-icon>
                </div>
                <div
                    *ngIf="passwordConfirm.invalid && (passwordConfirm.dirty || passwordConfirm.touched)"
                    ui-hint
                    app-form-errors
                    [formControlRef]="passwordConfirm"
                    controlName="{{ 'LOGIN.FORGOT_PASSWORD_FORM.CONFIRM_PASSWORD.LABEL' | translate }}"
                    controlNameGender="other"
                ></div>
            </ui-form-group>
            <div class="field-margin"></div>
            <ui-form-group>
                <button
                    ui-button
                    class="full-width"
                    size="large"
                    type="submit"
                    color="yellow"
                    [disabled]="(isForgotPasswordSaveNewPasswordInProgress$ | async) || form.invalid"
                >
                    {{ 'LOGIN.FORGOT_PASSWORD_FORM.SAVE_NEW_PASSWORD_BUTTON' | translate }}
                </button>
            </ui-form-group>
        </div>
    </form>

    <div class="row mb-0" *ngIf="isForgotPasswordRequestSuccess$ | async">
        <div class="input-field col s12">
            <p class="reduced-text mb-8 center">
                {{ 'LOGIN.FORGOT_PASSWORD_FORM.PASSWORD_CHANGED_SUCCESS.TITLE' | translate }}
            </p>
            <p class="reduced-text mb-40 center">
                {{ 'LOGIN.FORGOT_PASSWORD_FORM.PASSWORD_CHANGED_SUCCESS.YOU_CAN_SIGN_IN' | translate }}
            </p>
            <button
                ui-button
                size="large"
                color="yellow"
                routerLink="/login"
                class="full-width"
            >
                {{ 'LOGIN.FORGOT_PASSWORD_FORM.PASSWORD_CHANGED_SUCCESS.SIGN_IN' | translate }}
            </button>
        </div>
    </div>
</ng-container>
<div
    *ngIf="true === (isForgotPasswordTokenValidateInProgress$ | async)"
    class="spinner-wrapper"
>
    <ui-spinner></ui-spinner>
</div>
