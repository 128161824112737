import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import * as amplitude from 'amplitude-js';
import { combineLatest, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { AmplitudeEventTypesEnum } from '@app-client/shared/types/amplitude-event-types.enum';
import { AddTimeRequestInterface } from '@app-client/store/activity/types/time-modify/add-time-request.interface';
import { currentCompanySelector, currentUserSelector } from '@app-client/store/auth/selectors/auth.selectors';
import { CurrentCompanyInterface } from '@app-client/store/auth/types/current-company.interface';
import { CurrentUserInterface } from '@app-client/store/auth/types/current-user.interface';
import { UserInterface } from '@app-client/user/types/user.interface';

import { environment } from '@env-client/environment';


@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class AmplitudeService {
    private amplitude;
    private currentUser$: Observable<CurrentUserInterface>;
    private currentCompany$: Observable<CurrentCompanyInterface>;

    constructor(private store$: Store) {
        this.amplitude = amplitude;
        this.amplitude.getInstance().init(environment.amplitudeApiKey);
        this.currentUser$ = this.store$.select(currentUserSelector);
        this.currentCompany$ = this.store$.select(currentCompanySelector);

        this.currentUser$.pipe(
            filter(user => !!user),
            untilDestroyed(this)
        ).subscribe((user) => {
            this.amplitude.getInstance().setUserId(user.id);
        });
    }

    addedOfflineTime(payload: AddTimeRequestInterface): void {
        combineLatest([
            this.currentUser$,
            this.currentCompany$,
        ]).pipe(
            untilDestroyed(this),
            take(1)
        ).subscribe(([currentUser, currentCompany]) => {
            const eventProperties = {
                'Tenant Name': currentCompany.subDomain,
                'Tenant ID': currentCompany.id,
                Role: currentUser.role,
                Email: currentUser.email,
                'User ID': payload.userId,
                'Project Space Name': payload.task.project.projectSpace.name,
                'Project Space ID': payload.task.project.projectSpace.id,
                'Project Name': payload.task.project.name,
                'Project ID': payload.task.project.id,
                'Task Name': payload.task.name,
                'Task ID': payload.taskId,
                Memo: payload.memo,
                Ranges: payload.ranges,
            };

            this.amplitude.getInstance().logEvent(AmplitudeEventTypesEnum.ADDED_OFFLINE_TIME, eventProperties);
        });
    }

    addedCurrentCompanyUser(user: UserInterface): void {
        this.currentCompany$.pipe(
            untilDestroyed(this),
            take(1),
        ).subscribe((currentCompany) => {
            const eventProperties = {
                'Tenant Name': currentCompany.subDomain,
                'Tenant ID': currentCompany.id,
                Role: user.role,
                Email: user.email,
                'User ID': user.id,
                'Create Way': 'Manual',
            };

            this.amplitude.getInstance().logEvent(AmplitudeEventTypesEnum.ADDED_COMPANY_USER, eventProperties);
        });
    }
}
