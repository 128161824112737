import { createAction, props } from '@ngrx/store';

import { PaymentInvoiceDetailsResponseInterface } from '@app-client/payment/invoices/details/types/payment-invoice-details-response.interface';
import { PaymentInvoiceResponseInterface } from '@app-client/payment/invoices/list/types/payment-invoice-response.interface';
import { PaymentInvoicePdfResponseInterface } from '@app-client/payment/invoices/types/payment-invoice-pdf-response.interface';
import { MarketplaceInvoicesActionsEnum } from '@app-client/store/marketplace/invoices/marketplace-invoices-actions.enum';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


export const marketplaceInvoicesGetListAction = createAction(
    MarketplaceInvoicesActionsEnum.GET_LIST,
);

export const marketplaceInvoicesGetListSuccessAction = createAction(
    MarketplaceInvoicesActionsEnum.GET_LIST_SUCCESS,
    props<{ list: Array<PaymentInvoiceResponseInterface> }>(),
);

export const marketplaceInvoicesGetListFailureAction = createAction(
    MarketplaceInvoicesActionsEnum.GET_LIST_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const marketplaceInvoicesGetDetailsAction = createAction(
    MarketplaceInvoicesActionsEnum.GET_DETAILS,
    props<{ id: string }>(),
);

export const marketplaceInvoicesGetDetailsSuccessAction = createAction(
    MarketplaceInvoicesActionsEnum.GET_DETAILS_SUCCESS,
    props<{ details: PaymentInvoiceDetailsResponseInterface }>(),
);

export const marketplaceInvoicesGetDetailsFailureAction = createAction(
    MarketplaceInvoicesActionsEnum.GET_DETAILS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const marketplaceInvoicesPayAction = createAction(
    MarketplaceInvoicesActionsEnum.PAY,
    props<{ id: string }>(),
);

export const marketplaceInvoicesPaySuccessAction = createAction(
    MarketplaceInvoicesActionsEnum.PAY_SUCCESS,
    props<{ id: string }>(),
);

export const marketplaceInvoicesPayFailureAction = createAction(
    MarketplaceInvoicesActionsEnum.PAY_FAILURE,
    props<{ backendErrors: BackendErrorsInterface, id: string }>(),
);

export const marketplaceInvoicesGetPdfAction = createAction(
    MarketplaceInvoicesActionsEnum.PDF,
    props<{ id: string }>(),
);

export const marketplaceInvoicesGetPdfSuccessAction = createAction(
    MarketplaceInvoicesActionsEnum.PDF_SUCCESS,
    props<{ response: PaymentInvoicePdfResponseInterface }>(),
);

export const marketplaceInvoicesGetPdfFailureAction = createAction(
    MarketplaceInvoicesActionsEnum.PDF_FAILURE,
    props<{ backendErrors: BackendErrorsInterface, id: string }>(),
);
