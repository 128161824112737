export interface ReportFiltersInterface {
    from: number;
    to: number;
    projectSpaces: Array<string> | null;
    users: Array<string> | null;
    projects: Array<string> | null;
    tasks: Array<string> | null;
    timeType: TimeTypeEnum;
    showDate: boolean;
    showRate: boolean;
    showTask: boolean;
    showUserMemos: boolean;
    splitTimeByType: boolean;
    groupByFirst: GroupByTypeEnum;
    groupBySecond: GroupByTypeEnum;
    timezone: string;
    timeFormat: number;
    dateFormat: number;
    showUrlToProvider: boolean;
    myEmployees: boolean;
    showUserActivity: boolean;
}

export enum TimeTypeEnum {
    ONLINE = 0,
    OFFLINE = 1,
    ALL = 2
}

export enum GroupByTypeEnum {
    PROJECT = 0,
    USER = 1,
}

export enum TimeFormatEnum {
    COLON = 'COLON',
    HUMAN = 'HUMAN',
    HUMAN_SHORT = 'HUMAN_SHORT',
    MINUTES = 'MINUTES',
    HOURS = 'HOURS',
    HOURS_WITHOUT_LABELS = 'HOURS_WITHOUT_LABELS',
}

export enum DateFormatEnum {
    ISO = 'ISO',
    REVERSE_ISO = 'REVERSE_ISO',
    HUMAN = 'HUMAN',
}
