import { Action, createAction, props } from '@ngrx/store';

import { ActionTypes } from '@app-client/store/auth/action-types';


export const currentLanguageChangedAction = createAction(
    ActionTypes.CURRENT_LANGUAGE_CHANGED,
    props<{
        language: string | null,
        dispatchAfter?: Action,
    }>(),
);

export const currentLanguageChangedSuccessAction = createAction(
    ActionTypes.CURRENT_LANGUAGE_CHANGED_SUCCESS,
    props<{ language: string | null }>(),
);
