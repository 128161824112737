import { createReducer, on } from '@ngrx/store';

import {
    invoicingInvoicesClearCalculateAction,
    invoicingInvoicesDeleteAction,
    invoicingInvoicesDeleteFailureAction,
    invoicingInvoicesDeleteSuccessAction,
    invoicingInvoicesExportToGoogleSheetAction,
    invoicingInvoicesExportToGoogleSheetFailureAction,
    invoicingInvoicesExportToGoogleSheetSuccessAction,
    invoicingInvoicesGenerateAction,
    invoicingInvoicesGenerateFailureAction,
    invoicingInvoicesGenerateSuccessAction,
    invoicingInvoicesGetDetailsAction,
    invoicingInvoicesGetDetailsFailureAction,
    invoicingInvoicesGetDetailsSuccessAction,
    invoicingInvoicesGetListAction,
    invoicingInvoicesGetListFailureAction,
    invoicingInvoicesGetListSuccessAction,
    invoicingInvoicesRecalculateAction,
    invoicingInvoicesRecalculateFailureAction,
    invoicingInvoicesRecalculateSuccessAction,
    invoicingInvoicesSaveAction,
    invoicingInvoicesSaveFailureAction,
    invoicingInvoicesSaveSuccessAction,
    invoicingInvoicesUpdateAction,
    invoicingInvoicesUpdateFailureAction,
    invoicingInvoicesUpdateSuccessAction,
} from '@app-client/store/invoicing/invoices/actions/invoicing-invoices.actions';
import { InvoicingInvoicesStateInterface } from '@app-client/store/invoicing/invoices/invoicing-invoices-state.interface';


const initialState: InvoicingInvoicesStateInterface = {
    list: [],
    isListLoading: false,
    hasMore: false,

    isCalculateInProgress: false,
    calculateResult: null,
    calculateSettings: null,

    isSaveInProgress: false,
    isUpdateInProgress: false,
    isDeleteInProgress: false,

    isDetailsLoading: false,
    details: null,

    isExportToGoogleSheetInProgress: false,
};

const reducer = createReducer(
    initialState,
    on(
        invoicingInvoicesGetListAction,
        (state, action) => ({
            ...state,
            list: action.requestParams.page === 1 ? [] : state.list,
            isListLoading: true,
            hasMore: false,
        }),
    ),
    on(
        invoicingInvoicesGetListSuccessAction,
        (state, action) => ({
            ...state,
            list: [...state.list, ...action.response.items],
            isListLoading: false,
            hasMore: action.response.page < action.response.totalPages,
        }),
    ),
    on(
        invoicingInvoicesGetListFailureAction,
        (state) => ({
            ...state,
            isListLoading: false,
            hasMore: false,
        }),
    ),

    on(
        invoicingInvoicesGenerateAction,
        (state) => ({
            ...state,
            isCalculateInProgress: true,
            calculateResult: null,
            calculateSettings: null,
        }),
    ),
    on(
        invoicingInvoicesGenerateSuccessAction,
        (state, action) => ({
            ...state,
            isCalculateInProgress: false,
            calculateResult: action.response,
            calculateSettings: action.settings,
        }),
    ),
    on(
        invoicingInvoicesGenerateFailureAction,
        (state) => ({
            ...state,
            isCalculateInProgress: false,
            calculateResult: null,
            calculateSettings: null,
        }),
    ),

    on(
        invoicingInvoicesRecalculateAction,
        (state) => ({
            ...state,
            isCalculateInProgress: true,
        }),
    ),
    on(
        invoicingInvoicesRecalculateSuccessAction,
        (state, action) => ({
            ...state,
            isCalculateInProgress: false,
            calculateResult: action.response,
        }),
    ),
    on(
        invoicingInvoicesRecalculateFailureAction,
        (state) => ({
            ...state,
            isCalculateInProgress: false,
        }),
    ),

    on(
        invoicingInvoicesClearCalculateAction,
        (state) => ({
            ...state,
            calculateResult: null,
            calculateSettings: null,
        }),
    ),

    on(
        invoicingInvoicesUpdateAction,
        (state) => ({
            ...state,
            isUpdateInProgress: true,
        }),
    ),
    on(
        invoicingInvoicesUpdateSuccessAction,
        invoicingInvoicesUpdateFailureAction,
        (state) => ({
            ...state,
            isUpdateInProgress: false,
        }),
    ),

    on(
        invoicingInvoicesSaveAction,
        (state) => ({
            ...state,
            isSaveInProgress: true,
        }),
    ),
    on(
        invoicingInvoicesSaveSuccessAction,
        invoicingInvoicesSaveFailureAction,
        (state) => ({
            ...state,
            isSaveInProgress: false,
        }),
    ),

    on(
        invoicingInvoicesDeleteAction,
        (state) => ({
            ...state,
            isDeleteInProgress: true,
        }),
    ),
    on(
        invoicingInvoicesDeleteSuccessAction,
        invoicingInvoicesDeleteFailureAction,
        (state) => ({
            ...state,
            isDeleteInProgress: false,
        }),
    ),

    on(
        invoicingInvoicesGetDetailsAction,
        (state) => ({
            ...state,
            isDetailsLoading: true,
            details: null,
        }),
    ),
    on(
        invoicingInvoicesGetDetailsSuccessAction,
        (state, action) => ({
            ...state,
            isDetailsLoading: false,
            details: action.response,
        }),
    ),
    on(
        invoicingInvoicesGetDetailsFailureAction,
        (state) => ({
            ...state,
            isDetailsLoading: false,
        }),
    ),

    on(
        invoicingInvoicesExportToGoogleSheetAction,
        (state) => ({
            ...state,
            isExportToGoogleSheetInProgress: true,
        }),
    ),
    on(
        invoicingInvoicesExportToGoogleSheetSuccessAction,
        invoicingInvoicesExportToGoogleSheetFailureAction,
        (state) => ({
            ...state,
            isExportToGoogleSheetInProgress: false,
        }),
    ),
);

export const invoicingInvoicesReducer = (state, action) => reducer(state, action);
