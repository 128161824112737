<p class="subtitle mb-16 center">
    {{ 'LOGIN.FORGOT_PASSWORD_FORM.TITLE' | translate }}
</p>
<form
    [formGroup]="form"
    (ngSubmit)="submit()"
    novalidate
>
    <div *ngIf="false === (isForgotPasswordRequestSuccess$ | async)" class="mb-40">
        <ui-form-group [has-errors]="(email.invalid && (email.dirty || email.touched)) || (backendErrors$ | async) !== null" >
            <label
                ui-label
                for="email"
                [class.error-label]="(email.invalid && (email.dirty || email.touched)) || (backendErrors$ | async) !== null">
                {{ 'LOGIN.FORGOT_PASSWORD_FORM.EMAIL.LABEL' | translate }}
            </label>
            <input
                ui-input
                formControlName="email"
                id="email"
                type="email"
                placeholder="{{ 'LOGIN.FORGOT_PASSWORD_FORM.EMAIL.PLACEHOLDER' | translate }}"
                autocomplete="off"
                trim
            />
            <div
                *ngIf="email.invalid && (email.dirty || email.touched)"
                ui-hint
                app-form-errors
                [formControlRef]="email"
                controlName="{{ 'LOGIN.FORGOT_PASSWORD_FORM.EMAIL.LABEL' | translate }}"
                controlNameGender="male"
            ></div>
        </ui-form-group>
        <ui-form-group class="button-margin">
            <button
                ui-button
                class="full-width"
                size="large"
                type="submit"
                color="yellow"
                [disabled]="(changePasswordRequestInProgress$ | async) || form.invalid"
            >
                {{ 'LOGIN.FORGOT_PASSWORD_FORM.SUBMIT_REQUEST_BUTTON' | translate }}
            </button>
        </ui-form-group>
    </div>
    <div *ngIf="isForgotPasswordRequestSuccess$ | async" class="mb-30">
        <p class="reduced-text center">
            {{ 'LOGIN.FORGOT_PASSWORD_FORM.REQUEST_SUCCESS_MESSAGE' | translate }}
        </p>
    </div>
    <div class="reduced-text center mb-30">
        {{ 'LOGIN.FORGOT_PASSWORD_FORM.REMEMBERED_PASSWORD' | translate }}&nbsp;
        <a routerLink="/login" class="link">
            {{ 'LOGIN.FORGOT_PASSWORD_FORM.SIGN_IN_LINK' | translate }}
        </a>
    </div>
</form>


