import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TimeFormatEnum } from '@app-client/report/by-projects/types/report-filters.interface';
import { ReportUserActivityFiltersInterface } from '@app-client/report/user-activity/types/report-user-activity-filters.interface';
import { ReportUserActivityReportInterface } from '@app-client/report/user-activity/types/report-user-activity-report.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';

import { environment } from '@env-client/environment';

@Injectable()
export class ReportUserActivityService {
    constructor(
        private http: HttpClient,
    ) {}

    getReport(filters: ReportUserActivityFiltersInterface): Observable<ReportUserActivityReportInterface> {
        const url = `${environment.apiBaseUrl}/reports/users-activity`;
        const body = {
            from: filters.interval.from.unix(),
            to: filters.interval.to.unix(),
            users: filters.userIds,
            projects: filters.projectIds,
            projectSpaces: filters.projectSpaceIds,
            timeFormat: filters.viewTimeType,
            showUnaccountedTime: filters.unaccountedTime,
            showIdleTime: filters.idleTime,
            showDeleteTime: filters.deletedTime,
            showTotalAccountedTime: filters.accountedTime,
            showActiveTime: filters.trackedTime,
            showRequestedTime: filters.addedTime,
            myEmployees: filters.myEmployees,
        };

        return this.http.post<ResponseInterface<ReportUserActivityReportInterface>>(url, body).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    exportGoogleSpreadsheet(filters: ReportUserActivityFiltersInterface): Observable<void> {
        const url = `${environment.apiBaseUrl}/reports/users-activity/google`;
        const body = {
            from: filters.interval.from.unix(),
            to: filters.interval.to.unix(),
            users: filters.userIds,
            projects: filters.projectIds,
            projectSpaces: filters.projectSpaceIds,
            timeFormat: filters.viewTimeType,
            showUnaccountedTime: filters.unaccountedTime,
            showIdleTime: filters.idleTime,
            showDeleteTime: filters.deletedTime,
            showTotalAccountedTime: filters.accountedTime,
            showActiveTime: filters.trackedTime,
            showRequestedTime: filters.addedTime,
            viewTimeFormat: ((format: TimeFormatEnum) => {
                switch (format) {
                    case TimeFormatEnum.HUMAN:
                        return 0;
                    case TimeFormatEnum.HOURS:
                        return 1;
                    case TimeFormatEnum.MINUTES:
                        return 2;
                    case TimeFormatEnum.HOURS_WITHOUT_LABELS:
                        return 3;
                }
            })(filters.timeFormat),
            myEmployees: filters.myEmployees,
        };

        return this.http.post<ResponseInterface<void>>(url, body).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }
}
