import { createReducer, on } from '@ngrx/store';

import {
    reportUserActivityClearData,
    reportUserActivityExportGoogleSpreadsheetAction, reportUserActivityExportGoogleSpreadsheetFailureAction,
    reportUserActivityExportGoogleSpreadsheetSuccessAction,
    reportUserActivityGetReportAction, reportUserActivityGetReportFailureAction,
    reportUserActivityGetReportSuccessAction,
} from '@app-client/store/report/user-activity/actions/report-user-activity.actions';
import { ReportUserActivityStateInterface } from '@app-client/store/report/user-activity/report-user-activity-state.interface';


const initialState: ReportUserActivityStateInterface = {
    isGetReportInProgress: false,
    report: null,
    savedFilters: null,
    isExportGoogleSpreadsheetsInProgress: false,
};

const reducer = createReducer(
    initialState,
    on(
        reportUserActivityGetReportAction,
        (state) => ({
            ...state,
            isGetReportInProgress: true,
        }),
    ),
    on(
        reportUserActivityGetReportSuccessAction,
        (state, action) => ({
            ...state,
            isGetReportInProgress: false,
            report: action.report,
            savedFilters: action.filters,
        }),
    ),
    on(
        reportUserActivityGetReportFailureAction,
        (state) => ({
            ...state,
            isGetReportInProgress: false,
        }),
    ),
    on(
        reportUserActivityClearData,
        (state) => ({
            ...state,
            report: null,
            savedFilters: null,
        }),
    ),
    on(
        reportUserActivityExportGoogleSpreadsheetAction,
        (state) => ({
            ...state,
            isExportGoogleSpreadsheetsInProgress: true,
        }),
    ),
    on(
        reportUserActivityExportGoogleSpreadsheetSuccessAction,
        reportUserActivityExportGoogleSpreadsheetFailureAction,
        (state) => ({
            ...state,
            isExportGoogleSpreadsheetsInProgress: false,
        })
    )
);

export const reportUserActivityReducer = (state, action) => reducer(state, action);
