export enum ReportUsingApplicationActionTypes {
    GET_REPORT = '[ReportUsingApplication] Get report',
    GET_REPORT_SUCCESS = '[ReportUsingApplication] Get report success',
    GET_REPORT_FAILURE = '[ReportUsingApplication] Get report failure',

    CLEAR_DATA = '[ReportUsingAPPLICATIONS] Clear data',

    EXPORT_GOOGLE_SPREADSHEET = '[ReportUsingApplication] Export Google spreadsheet',
    EXPORT_GOOGLE_SPREADSHEET_SUCCESS = '[ReportUsingApplication] Export Google spreadsheet success',
    EXPORT_GOOGLE_SPREADSHEET_FAILURE = '[ReportUsingApplication] Export Google spreadsheet failure',
}
