
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { InvoicingCustomersCreateInterface } from '@app-client/store/invoicing/customers/types/invoicing-customers-create.interface';
import { InvoicingCustomersDetailsResponseInterface } from '@app-client/store/invoicing/customers/types/invoicing-customers-details-response.interface';
import { InvoicingCustomersListItemResponseInterface } from '@app-client/store/invoicing/customers/types/invoicing-customers-list-item-response.interface';
import { InvoicingCustomersListRequestParamsInterface } from '@app-client/store/invoicing/customers/types/invoicing-customers-list-request-params.interface';
import { PageResponseInterface } from '@app-shared/types/page-response.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';

import { environment } from '@env-client/environment';


@Injectable()
export class InvoicingCustomersService {
    constructor(
        private http: HttpClient,
    ) {}

    getList(requestParams: InvoicingCustomersListRequestParamsInterface): Observable<any> {
        const url = `${environment.apiBaseUrl}/module/invoicing/customers`;

        let params = new HttpParams();
        params = params.set('page', requestParams.page.toString());
        params = params.set('limit', requestParams.limit.toString());
        if (undefined !== requestParams.archived) {
            params = params.set('archived', requestParams.archived.toString());
        }

        if (true === !!requestParams.searchText) {
            params = params.set('search', requestParams.searchText);
        }

        return this.http.get<ResponseInterface<PageResponseInterface<InvoicingCustomersListItemResponseInterface>>>(url, { params }).pipe(
            map((response) => {
                return response.body;
            })
        );
    }

    getDetails(id: string): Observable<InvoicingCustomersDetailsResponseInterface> {
        const url = `${environment.apiBaseUrl}/module/invoicing/customers/${id}`;

        return this.http.get<ResponseInterface<InvoicingCustomersDetailsResponseInterface>>(url).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    create(data: InvoicingCustomersCreateInterface): Observable<string> {
        const url = `${environment.apiBaseUrl}/module/invoicing/customers`;
        const customerData = {};

        for (const [key, value] of Object.entries(data)) {
            customerData[key] = (value === '') ? null : value;
        }

        return this.http.post<ResponseInterface<{ id: string }>>(url, customerData).pipe(
            map((response) => {
                return response.body.id;
            }),
        );
    }

    update(id: string, data: InvoicingCustomersCreateInterface): Observable<string> {
        const url = `${environment.apiBaseUrl}/module/invoicing/customers/${id}`;
        const customerData = {};

        for (const [key, value] of Object.entries(data)) {
            customerData[key] = (value === '') ? null : value;
        }

        return this.http.patch<ResponseInterface<{ id: string }>>(url, customerData).pipe(
            map((response) => {
                return response.body.id;
            }),
        );
    }

    delete(id: string): Observable<void> {
        const url = `${environment.apiBaseUrl}/module/invoicing/customers/${id}`;

        return this.http.delete<ResponseInterface<void>>(url).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }
 }
