export enum UsersActionType {
    SET_USERS_QUERY_PARAMS = '[Users] Set users query params',

    GET_USERS = '[Users] Get users',
    GET_USERS_SUCCESS = '[Users] Get users success',
    GET_USERS_FAILURE = '[Users] Get users failure',

    GET_USER_DETAILS = '[Users] Get user details',
    GET_USER_DETAILS_SUCCESS = '[Users] Get user details success',
    GET_USER_DETAILS_FAILURE = '[Users] Get user details failure',

    CREATE_USER = '[Users] Create user',
    CREATE_USER_SUCCESS = '[Users] Create user success',
    CREATE_USER_FAILURE = '[Users] Create user failure',

    UPDATE_USER = '[Users] Update user',
    UPDATE_USER_SUCCESS = '[Users] Update user success',
    UPDATE_USER_FAILURE = '[Users] Update user failure',

    DELETE_USER = '[Users] Delete user',
    DELETE_USER_SUCCESS = '[Users] Delete user success',
    DELETE_USER_FAILURE = '[Users] Delete user failure',

    GET_TOTAL_COUNT = '[Users] Get total count',
    GET_TOTAL_COUNT_SUCCESS = '[Users] Get total count success',
    GET_TOTAL_COUNT_FAILURE = '[Users] Get total count failure',

    GET_REQUIRES_DELETE_USER_CONFIRMATION = '[Users] Get requires delete user confirmation',

    DEACTIVATE = '[Users] Deactivate',
    DEACTIVATE_SUCCESS = '[Users] Deactivate success',
    DEACTIVATE_FAILURE = '[Users] Deactivate failure',

    ACTIVATE = '[Users] Activate',
    ACTIVATE_SUCCESS = '[Users] Activate success',
    ACTIVATE_FAILURE = '[Users] Activate failure',

    GET_EMPLOYEES = '[Users] Get employees',
    GET_EMPLOYEES_SUCCESS = '[Users] Get employees success',
    GET_EMPLOYEES_FAILURE = '[Users] Get employees failure',

    CLEAR_IS_NEED_FORCE_DEACTIVATE = '[Users] Clear isNeedForceDeactivate',
}
