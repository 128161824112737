import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { PersistenceService } from '@app-client/shared/services/persistence.service';
import { getCompanyTrackingSettingsAction } from '@app-client/store/auth/actions/get-company-tracking-settings.action';
import {
    getCurrentCompanyAction,
    getCurrentCompanyFailureAction,
    getCurrentCompanySuccessAction,
} from '@app-client/store/auth/actions/get-current-company.action';
import { getCurrentUserAction } from '@app-client/store/auth/actions/get-current-user.action';
import { AuthService } from '@app-client/store/auth/services/auth.service';
import { CurrentCompanyInterface } from '@app-client/store/auth/types/current-company.interface';
import { marketplaceModulesGetEnabledAction } from '@app-client/store/marketplace/modules/actions/marketplace-modules.actions';


@Injectable()
export class GetCurrentCompanyEffect {
    getCurrentCompany$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getCurrentCompanyAction),
            switchMap(({ subDomain }) => {
                return this.authService.getCurrentCompany(subDomain).pipe(
                    map((currentCompany: CurrentCompanyInterface) => {
                        if (this.persistenceService.get('credentials')) {
                            this.store.dispatch(getCurrentUserAction());
                            this.store.dispatch(getCompanyTrackingSettingsAction());
                            this.store.dispatch(marketplaceModulesGetEnabledAction());
                        }

                        return getCurrentCompanySuccessAction({ currentCompany });
                    }),
                    catchError(() => {
                        return of(getCurrentCompanyFailureAction());
                    }),
                );
            }),
        );
    });

    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private store: Store,
        private persistenceService: PersistenceService,
    ) {}
}
