import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NewYearService } from './new-year.service';


@UntilDestroy()
@Component({
    selector: 'new-year-settings',
    template: `
        <div class="row mb-48">
            <div class="col s12">
                <div class="notifications">
                    <mat-checkbox
                        class="subscription"
                        [checked]="newYearService.isSnowEnabled$ | async"
                        (click)="$event.preventDefault();newYearService.isSnowEnabled$.next(!newYearService.isSnowEnabled$.value)"
                    >
                        {{ 'NEW_YEAR.SNOW' | translate }}
                    </mat-checkbox>
                    <mat-checkbox
                        class="subscription"
                        [checked]="newYearService.isWreathEnabled$ | async"
                        (click)="$event.preventDefault();newYearService.isWreathEnabled$.next(!newYearService.isWreathEnabled$.value)"
                    >
                        {{ 'NEW_YEAR.WREATH' | translate }}
                    </mat-checkbox>
                </div>
            </div>
        </div>
    `,
    styles: [`
        .notifications {
            display: flex;
            flex-direction: column;
        }

        .subscription {
            margin-bottom: 20px;
        }
    `],
})
export class NewYearSettingsComponent {

    constructor(
        public newYearService: NewYearService,
    ) {}
}
