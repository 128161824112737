import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { AmplitudeService } from '@app-client/shared/services/amplitude.service';
import {
    addTimeAction,
    addTimeFailureAction,
    addTimeSuccessAction,
    deleteTimeAction,
    deleteTimeFailureAction,
    deleteTimeSuccessAction,
    editTimeAction,
    editTimeFailureAction,
    editTimeSuccessAction,
} from '@app-client/store/activity/actions/time.action';
import { TimeService } from '@app-client/store/activity/services/time.service';
import { currentUserIsAdminSelector } from '@app-client/store/auth/selectors/auth.selectors';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class TimeEffect {
    addTime$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(addTimeAction),
            switchMap(({ requestData, successCallback }) => {
                return this.timeService.addTime(requestData).pipe(
                    map(() => {
                        this.amplitudeService.addedOfflineTime(requestData);
                        return addTimeSuccessAction({
                            successCallback,
                            userId: requestData.userId,
                        });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(addTimeFailureAction({
                            userId: requestData.userId,
                            backendErrors,
                        }));
                    }),
                );
            })
        );
    });

    addTimeSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(addTimeSuccessAction),
            withLatestFrom(this.store$.select(currentUserIsAdminSelector)),
            tap(([{ successCallback, userId }, isAdmin]) => {
                const key = isAdmin ? 'ADD' : 'REQUEST';
                this.toastr.success(
                    this.translate.instant(`ACTIVITY.TIMELINE.ADD_TIME_MODAL.NOTIFICATIONS.${key}.SUCCESS.MESSAGE`),
                    this.translate.instant(`ACTIVITY.TIMELINE.ADD_TIME_MODAL.NOTIFICATIONS.${key}.SUCCESS.TITLE`),
                );
                if (successCallback && typeof successCallback === 'function') {
                    successCallback();
                }
            }),
        );
    }, { dispatch: false });

    addTimeFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(addTimeFailureAction),
            withLatestFrom(this.store$.select(currentUserIsAdminSelector)),
            tap(([{ userId, backendErrors }, isAdmin]) => {
                const key = isAdmin ? 'ADD' : 'REQUEST';

                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant(`ACTIVITY.TIMELINE.ADD_TIME_MODAL.NOTIFICATIONS.${key}.ERROR.TITLE`),
                );
            }),
        );
    }, { dispatch: false });

    editTime$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(editTimeAction),
            switchMap(({ requestData, successCallback }) => {
                return this.timeService.editTime(requestData).pipe(
                    map(() => {
                        return editTimeSuccessAction({ successCallback });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(editTimeFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    editTimeSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(editTimeSuccessAction),
            tap(({ successCallback }) => {
                this.toastr.success(
                    this.translate.instant('ACTIVITY.TIMELINE.EDIT_TIME_MODAL.NOTIFICATIONS.SUCCESS.MESSAGE'),
                    this.translate.instant('ACTIVITY.TIMELINE.EDIT_TIME_MODAL.NOTIFICATIONS.SUCCESS.TITLE'),
                );

                if (successCallback && typeof successCallback === 'function') {
                    successCallback();
                }
            }),
        );
    }, { dispatch: false });

    editTimeFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(editTimeFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('ACTIVITY.TIMELINE.EDIT_TIME_MODAL.NOTIFICATIONS.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    deleteTime$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteTimeAction),
            switchMap(({ requestData, successCallback }) => {
                return this.timeService.deleteTime(requestData).pipe(
                    map(() => {
                        return deleteTimeSuccessAction({ successCallback });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(deleteTimeFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    deleteTimeSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteTimeSuccessAction),
            tap(({ successCallback }) => {
                this.toastr.success(
                    this.translate.instant('ACTIVITY.TIMELINE.DELETE_TIME_DIALOG.NOTIFICATIONS.SUCCESS.MESSAGE'),
                    this.translate.instant('ACTIVITY.TIMELINE.DELETE_TIME_DIALOG.NOTIFICATIONS.SUCCESS.TITLE'),
                );

                if (successCallback && typeof successCallback === 'function') {
                    successCallback();
                }
            }),
        );
    }, { dispatch: false });

    deleteTimeFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteTimeFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('ACTIVITY.TIMELINE.DELETE_TIME_DIALOG.NOTIFICATIONS.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    constructor(
        private store$: Store,
        private timeService: TimeService,
        private actions$: Actions,
        private toastr: ToastrService,
        private translate: TranslateService,
        private amplitudeService: AmplitudeService,
    ) {}
}
