import { createAction, props } from '@ngrx/store';

import { KaitenBoardColumnSettingsInterface } from '@app-client/kaiten/types/kaiten-board-column-settings.interface';
import { KaitenBoardColumnInterface } from '@app-client/kaiten/types/kaiten-board-column.interface';
import { KaitenBoardInterface } from '@app-client/kaiten/types/kaiten-board.interface';
import { KaitenProjectStatusInterface } from '@app-client/kaiten/types/kaiten-project-status.interface';
import { KaitenSpaceInterface } from '@app-client/kaiten/types/kaiten-space.interface';
import { KaitenUserDataInterface } from '@app-client/kaiten/types/kaiten-user-data.interface';
import { KaitenActionTypes } from '@app-client/store/kaiten/kaiten-action-types';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


export const kaitenModuleDisabledAction = createAction(
    KaitenActionTypes.MODULE_DISABLED,
);

export const kaitenGetProjectStatusAction = createAction(
    KaitenActionTypes.GET_PROJECT_STATUS,
    props<{ projectId: string }>(),
);
export const kaitenGetProjectStatusSuccessAction = createAction(
    KaitenActionTypes.GET_PROJECT_STATUS_SUCCESS,
    props<{ projectId: string, projectStatus: KaitenProjectStatusInterface }>(),
);
export const kaitenGetProjectStatusFailureAction = createAction(
    KaitenActionTypes.GET_PROJECT_STATUS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const kaitenGetUserSpacesAction = createAction(
    KaitenActionTypes.GET_USER_SPACES,
);
export const kaitenGetUserSpacesSuccessAction = createAction(
    KaitenActionTypes.GET_USER_SPACES_SUCCESS,
    props<{ spaces: KaitenSpaceInterface[] }>(),
);
export const kaitenGetUserSpacesFailureAction = createAction(
    KaitenActionTypes.GET_USER_SPACES_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const kaitenGetUserBoardsAction = createAction(
    KaitenActionTypes.GET_USER_BOARDS,
    props<{ spaceId: string }>()
);
export const kaitenGetUserBoardsSuccessAction = createAction(
    KaitenActionTypes.GET_USER_BOARDS_SUCCESS,
    props<{ boards: KaitenBoardInterface[] }>(),
);
export const kaitenGetUserBoardsFailureAction = createAction(
    KaitenActionTypes.GET_USER_BOARDS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const kaitenUpdateKeyAction = createAction(
    KaitenActionTypes.UPDATE_KEY,
    props<{ kaitenApiKey: string }>(),
);
export const kaitenUpdateKeySuccessAction = createAction(
    KaitenActionTypes.UPDATE_KEY_SUCCESS
);
export const kaitenUpdateKeyFailureAction = createAction(
    KaitenActionTypes.UPDATE_KEY_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const kaitenGetUserDataAction = createAction(
    KaitenActionTypes.GET_USER_DATA
);

export const kaitenGetUserDataSuccessAction = createAction(
    KaitenActionTypes.GET_USER_DATA_SUCCESS,
    props<{ kaitenUserData: KaitenUserDataInterface }>(),
);

export const kaitenGetUserDataFailureAction = createAction(
    KaitenActionTypes.GET_USER_DATA_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const kaitenDeleteKeyAction = createAction(
    KaitenActionTypes.DELETE_KEY
);

export const kaitenDeleteKeySuccessAction = createAction(
    KaitenActionTypes.DELETE_KEY_SUCCESS
);

export const kaitenDeleteKeyFailureAction = createAction(
    KaitenActionTypes.DELETE_KEY_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const kaitenGetProjectColumnsAction = createAction(
    KaitenActionTypes.GET_PROJECT_COLUMNS,
    props<{ projectId: string }>(),
);
export const kaitenGetProjectColumnsSuccessAction = createAction(
    KaitenActionTypes.GET_PROJECT_COLUMNS_SUCCESS,
    props<{ columns: KaitenBoardColumnInterface[] }>(),
);
export const kaitenGetProjectColumnsFailureAction = createAction(
    KaitenActionTypes.GET_PROJECT_COLUMNS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface, projectId: string }>(),
);

export const kaitenAttachSpaceToProjectAction = createAction(
    KaitenActionTypes.ATTACH_SPACE_TO_PROJECT,
    props<{
        projectId: string,
        spaceId: string,
        boardId: string,
        columnSettings: KaitenBoardColumnSettingsInterface
    }>(),
);

export const kaitenAttachSpaceToProjectSuccessAction = createAction(
    KaitenActionTypes.ATTACH_SPACE_TO_PROJECT_SUCCESS,
    props<{ projectId: string }>(),
);
export const kaitenAttachSpaceToProjectFailureAction = createAction(
    KaitenActionTypes.ATTACH_SPACE_TO_PROJECT_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const kaitenUpdateProjectColumnSettingsAction = createAction(
    KaitenActionTypes.UPDATE_PROJECT_COLUMN_SETTINGS,
    props<{ projectId: string, spaceId: string, boardId: string, columnSettings: KaitenBoardColumnSettingsInterface }>(),
);

export const kaitenUpdateProjectColumnSettingsSuccessAction = createAction(
    KaitenActionTypes.UPDATE_PROJECT_COLUMN_SETTINGS_SUCCESS,
    props<{ projectId: string }>(),
);
export const kaitenUpdateProjectColumnSettingsFailureAction = createAction(
    KaitenActionTypes.UPDATE_PROJECT_COLUMN_SETTINGS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface, projectId: string }>(),
);

export const kaitenDetachSpaceFromProjectAction = createAction(
    KaitenActionTypes.DETACH_SPACE_FROM_PROJECT,
    props<{ projectId: string }>(),
);
export const kaitenDetachSpaceFromProjectSuccessAction = createAction(
    KaitenActionTypes.DETACH_SPACE_FROM_PROJECT_SUCCESS,
    props<{ projectId: string }>(),
);
export const kaitenDetachSpaceFromProjectFailureAction = createAction(
    KaitenActionTypes.DETACH_SPACE_FROM_PROJECT_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const kaitenStartSyncAction = createAction(
    KaitenActionTypes.START_SYNC,
    props<{ projectId: string }>(),
);
export const kaitenStartSyncSuccessAction = createAction(
    KaitenActionTypes.START_SYNC_SUCCESS,
    props<{ projectId: string }>(),
);
export const kaitenStartSyncFailureAction = createAction(
    KaitenActionTypes.START_SYNC_FAILURE,
    props<{ backendErrors: BackendErrorsInterface, projectId: string }>(),
);

export const kaitenStopSyncAction = createAction(
    KaitenActionTypes.STOP_SYNC,
    props<{ projectId: string }>(),
);
export const kaitenStopSyncSuccessAction = createAction(
    KaitenActionTypes.STOP_SYNC_SUCCESS,
    props<{ projectId: string }>(),
);
export const kaitenStopSyncFailureAction = createAction(
    KaitenActionTypes.STOP_SYNC_FAILURE,
    props<{ backendErrors: BackendErrorsInterface, projectId: string }>(),
);

export const kaitenStartSpacesListSyncAction = createAction(
    KaitenActionTypes.START_SPACE_SYNC
);
export const kaitenStartSpacesListSyncSuccessAction = createAction(
    KaitenActionTypes.START_SPACE_SYNC_SUCCESS
);
export const kaitenStartSpacesListSyncFailureAction = createAction(
    KaitenActionTypes.START_SPACE_SYNC_FAILURE,
    props<{ backendErrors: BackendErrorsInterface}>(),
);
export const kaitenStartSpaceBoardsListSyncAction = createAction(
    KaitenActionTypes.START_SPACE_BOARDS_SYNC,
    props<{ spaceId: string }>()
);
export const kaitenStartSpaceBoardsListSyncSuccessAction = createAction(
    KaitenActionTypes.START_SPACE_BOARDS_SYNC_SUCCESS
);
export const kaitenStartSpaceBoardsListSyncFailureAction = createAction(
    KaitenActionTypes.START_SPACE_BOARDS_SYNC_FAILURE,
    props<{ backendErrors: BackendErrorsInterface}>(),
);
