import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CreateUserInterface } from '@app-client/user/types/create-user.interface';
import { UpdateUserInterface } from '@app-client/user/types/update-user.interface';
import { UserListQueryInterface } from '@app-client/user/types/user-list-query.interface';
import { UserResponseInterface } from '@app-client/user/types/user-response.interface';
import { UserInterface } from '@app-client/user/types/user.interface';
import { PageResponseInterface } from '@app-shared/types/page-response.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';
import { UserDeleteInterface } from '@app-shared/types/user-delete.interface';

import { environment } from '@env-client/environment';
import { UserEmployeesQueryParamsInterface } from '@app-client/user-profile/types/user-employees-list-query.interface';


@Injectable()
export class UsersService {
    constructor(private httpClient: HttpClient) {}

    getUsers(
        params: UserListQueryInterface,
    ): Observable<PageResponseInterface<UserInterface>> {
        let httpParams = new HttpParams()
            .set('limit', (params.limit || 10).toString())
            .set('orderBy', params.orderBy)
            .set('orderDirection', params.orderDirection)
            .set('page', params.page.toString())
        ;
        if (params.search) {
            httpParams = httpParams.set('search', params.search);
        }
        if (params.project !== null && params.project !== undefined) {
            const projects = Array.isArray(params.project) ? params.project : [params.project];
            projects.forEach((project) => {
                httpParams = httpParams.append('project[]', project);
            });
        }
        if (params.whereImManagerOrObserver !== null && params.whereImManagerOrObserver !== undefined) {
            httpParams = httpParams.set('whereImManagerOrObserver', params.whereImManagerOrObserver.toString());
        }
        if (params.whereImManager !== null && params.whereImManager !== undefined) {
            httpParams = httpParams.set('whereImManager', params.whereImManager.toString());
        }
        if (params.whereImHead !== null && params.whereImHead !== undefined) {
            httpParams = httpParams.set('whereImHead', params.whereImHead.toString());
        }
        if (params.role !== null && params.role !== undefined) {
            const roles = Array.isArray(params.role) ? params.role : [params.role];
            roles.forEach(role => {
                httpParams = httpParams.append('role[]', role);
            });
        }
        if (params.excludeIds !== null && params.excludeIds !== undefined) {
            const excludeIds = Array.isArray(params.excludeIds) ? params.excludeIds : [params.excludeIds];
            excludeIds.forEach(excludeId => {
                httpParams = httpParams.append('excludeIds[]', excludeId);
            });
        }
        if (params.projectSpace !== null && params.projectSpace !== undefined) {
            const projectSpaces = Array.isArray(params.projectSpace) ? params.projectSpace : [params.projectSpace];
            projectSpaces.forEach((projectSpace) => {
                httpParams = httpParams.append('projectSpace[]', projectSpace);
            });
        }
        if (params.archived) {
            httpParams = httpParams.set('archived', params.archived.toString());
        }

        const url = environment.apiBaseUrl + '/users';

        return this.httpClient.get<ResponseInterface<PageResponseInterface<UserInterface>>>(url, { params: httpParams }).pipe(
            map((response: ResponseInterface<PageResponseInterface<UserInterface>>) => {
                return response.body;
            })
        );
    }

    getEmployees(params: UserEmployeesQueryParamsInterface): Observable<Array<UserInterface>> {
        const url = environment.apiBaseUrl + '/users/employees';
        let httpParams = new HttpParams();
        
        if (params) {
            if (params.search !== null && params.search !== undefined) {
                httpParams = httpParams.set('search', (params.search));
            }
    
            if (params.orderDirection !== null && params.orderDirection !== undefined) {
                httpParams = httpParams.set('orderDirection', (params.orderDirection));
            }
    
            if (params.orderBy !== null && params.orderBy !== undefined) {
                httpParams = httpParams.set('orderBy', params.orderBy)
            }
    
            if (params.role !== null && params.role !== undefined) {
                httpParams = httpParams.set('role', params.role)
            }
        }

        return this.httpClient.get<ResponseInterface<Array<UserInterface>>>(url, { params: httpParams }).pipe(
            map((response: ResponseInterface<Array<UserInterface>>) => {
                return response.body;
            })
        );
    }

    getUserById(id: string): Observable<UserInterface> {
        const url = environment.apiBaseUrl + '/users/' + id;

        return this.httpClient.get(url).pipe(
            map((response: ResponseInterface<{
                user: UserInterface,
                employees?: Array<UserInterface>
            }>) => {
                const user = {
                    ...response.body.user,
                    employees: response.body?.employees ?? [],
                } as UserResponseInterface;
                return this.transformResponse(user);
            })
        );
    }

    createUser(userData: CreateUserInterface): Observable<UserInterface> {
        const url = environment.apiBaseUrl + '/users';

        return this.httpClient.post(url, { ...userData })
            .pipe(map((response: ResponseInterface<UserResponseInterface>) => {
                return this.transformResponse(response.body);
            }));
    }

    updateUser(id: string, userData: UpdateUserInterface): Observable<UserInterface> {
        const url = environment.apiBaseUrl + '/users/' + id;

        return this.httpClient.patch(url, { ...userData })
            .pipe(map((response: ResponseInterface<UserResponseInterface>) => {
                return this.transformResponse(response.body);
            }));
    }

    deleteUser(id: string) {
        const url = environment.apiBaseUrl + '/users/' + id;

        return this.httpClient.delete<ResponseInterface<UserDeleteInterface<void>>>(url).pipe(
            map((response) => {
                return response.body.force;
            }),
        );
    }

    getTotalCount(): Observable<number> {
        const url = `${environment.apiBaseUrl}/users`;
        const params = new HttpParams()
            .set('limit', '10')
            .set('orderBy', 'email')
            .set('orderDirection', 'ASC')
            .set('page', '1')
            .set('archived', '0');

        return this.httpClient.get<ResponseInterface<PageResponseInterface<void>>>(url, { params }).pipe(
            map((response) => {
                return response.body.totalRecords;
            }),
        );
    }

    deactivate(id: string, force: boolean): Observable<void> {
        const url = `${environment.apiBaseUrl}/users/${id}/deactivate`;
        let params = new HttpParams();

        if (true === force) {
            params = params.set('force', '1');
        }

        return this.httpClient.patch<ResponseInterface<void>>(url, {}, { params }).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    activate(id: string): Observable<void> {
        const url = `${environment.apiBaseUrl}/users/${id}/activate`;

        return this.httpClient.patch<ResponseInterface<void>>(url, {}).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    private transformResponse(response: UserResponseInterface): UserInterface {
        const permissions = {};
        if (response.hasOwnProperty('permissions')) {
            response.permissions.map(v => {
                permissions[v] = true;
            });
        }
        return {
            ...response,
            permissions
        } as UserInterface;
    }
}
