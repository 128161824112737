import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { OnboardingDataStatusEnum } from '@app-client/shared/types/onboarding-data-status.enum';
import {
    currentCompanySelector,
    currentUserIsAdminSelector,
    currentUserIsManagerSelector,
    currentUserIsObserverSelector,
    currentUserSelector,
} from '@app-client/store/auth/selectors/auth.selectors';
import { marketplaceIsEnabledEffectivityFeatureSelector, marketplaceModulesIsModuleEnabledSelector } from '@app-client/store/marketplace/modules/selectors/marketplace-modules.selectors';
import { setSharedDataFiltersDayAction } from '@app-client/store/shared-data-filters/actions/shared-data-filters.action';
import { sharedDataFiltersDaySelector } from '@app-client/store/shared-data-filters/selectors/shared-data-filters.selector';
import {
    enableDailyResultSandbox,
    openWelcomeDialogAction,
    sendOnboardingStatusStartedAction,
    startTourSectionAction,
} from '@app-client/store/tour/actions/tour.action';
import { onboardingStatusSelector } from '@app-client/store/tour/selectors/tour.selector';
import { TourService } from '@app-client/tour/services/tour.service';
import { TourSectionsEnum } from '@app-client/tour/types/tour-sections.enum';
import { collapseAnimation } from '@app-shared/animations/collapse.animation';
import { AppModulesEnum } from '@app-shared/types/app-modules.enum';
import { ProjectRoleEnum } from '@app-client/project/types/project-role.enum';


@UntilDestroy()
@Component({
    selector: 'app-restart-demo-dialog',
    templateUrl: './restart-demo-dialog.component.html',
    styleUrls: ['./restart-demo-dialog.component.scss'],
    animations: [
        collapseAnimation,
    ],
})
export class RestartDemoDialogComponent implements OnInit {
    readonly OnboardingDataStatusEnum = OnboardingDataStatusEnum;

    availableSections$: Observable<{ link: string; section: TourSectionsEnum; name$: Observable<any>; needOnboardingData: boolean; }[]>;
    onboardingDataStatus: OnboardingDataStatusEnum;

    constructor(
        public dialogRef: MatDialogRef<RestartDemoDialogComponent>,
        private store: Store,
        private translate: TranslateService,
        private tourService: TourService,
    ) {}

    ngOnInit() {
        this.initializeVariables();
    }

    private initializeVariables() {
        this.store.select(onboardingStatusSelector)
            .pipe(untilDestroyed(this))
            .subscribe((onboardingDataStatus) => this.onboardingDataStatus = onboardingDataStatus);
        this.availableSections$ = combineLatest([
            this.store.select(currentUserIsAdminSelector),
            this.store.select(currentUserIsObserverSelector),
            this.store.select(currentUserIsManagerSelector),
            this.store.select(marketplaceIsEnabledEffectivityFeatureSelector),
            this.store.select(marketplaceModulesIsModuleEnabledSelector(AppModulesEnum.DAILY_RESULT)),
            this.store.select(currentUserSelector),
        ]).pipe(
            map(([isAdmin, isObserver, isManager, isEffectivityEnabled, isDailyResultEnabled, currentUser]) => {
                const isObserverOnly = currentUser.projectRoles.every(role => role === ProjectRoleEnum.ROLE_OBSERVER);
                const adminEffectivitySections = [
                    {
                        link: '/effectivity',
                        section: TourSectionsEnum.EFFECTIVITY,
                        name$: this.translate.stream('ACTIVITY.DEMO.RESTART_DEMO_DIALOG.EFFECTIVITY'),
                        needOnboardingData: true,
                    },
                ];
                const managerEffectivitySections = [
                    {
                        link: '/company/effectivity-templates',
                        section: TourSectionsEnum.COMPANY_SETTINGS,
                        name$: this.translate.stream('ACTIVITY.DEMO.RESTART_DEMO_DIALOG.EFFECTIVITY_TEMPLATES'),
                        needOnboardingData: false,
                    },
                ];
                const dailyResultSections = [
                    {
                        link: '/daily-result',
                        section: TourSectionsEnum.DAILY_RESULT,
                        name$: this.translate.stream('ACTIVITY.DEMO.RESTART_DEMO_DIALOG.DAILY_RESULT'),
                        needOnboardingData: false,
                    },
                ];

                if (isAdmin) {
                    return [
                        {
                            link: null,
                            section: null,
                            name$: this.translate.stream('ACTIVITY.DEMO.RESTART_DEMO_DIALOG.RESTART_ALL'),
                            needOnboardingData: false,
                        },
                        {
                            link: null,
                            section: TourSectionsEnum.OVERVIEW,
                            name$: this.translate.stream('ACTIVITY.DEMO.RESTART_DEMO_DIALOG.OVERVIEW'),
                            needOnboardingData: false,
                        },
                        {
                            link: '/activity',
                            section: TourSectionsEnum.ACTIVITY,
                            name$: this.translate.stream('ACTIVITY.DEMO.RESTART_DEMO_DIALOG.ACTIVITY'),
                            needOnboardingData: true,
                        },
                        ...(isEffectivityEnabled ? adminEffectivitySections : []),
                        {
                            link: '/projects',
                            section: TourSectionsEnum.PROJECTS,
                            name$: this.translate.stream('ACTIVITY.DEMO.RESTART_DEMO_DIALOG.PROJECTS'),
                            needOnboardingData: false,
                        },
                        {
                            link: '/project-spaces',
                            section: TourSectionsEnum.PROJECT_SPACES,
                            name$: this.translate.stream('ACTIVITY.DEMO.RESTART_DEMO_DIALOG.PROJECT_SPACES'),
                            needOnboardingData: false,
                        },
                        {
                            link: '/report',
                            section: TourSectionsEnum.REPORTS,
                            name$: this.translate.stream('ACTIVITY.DEMO.RESTART_DEMO_DIALOG.REPORT'),
                            needOnboardingData: false,
                        },
                        {
                            link: '/time-requests',
                            section: TourSectionsEnum.TIME_REQUESTS,
                            name$: this.translate.stream('ACTIVITY.DEMO.RESTART_DEMO_DIALOG.TIME_REQUESTS'),
                            needOnboardingData: false,
                        },
                        {
                            link: '/users',
                            section: TourSectionsEnum.USERS,
                            name$: this.translate.stream('ACTIVITY.DEMO.RESTART_DEMO_DIALOG.USERS'),
                            needOnboardingData: false,
                        },
                        {
                            link: '/modules',
                            section: TourSectionsEnum.MARKETPLACE,
                            name$: this.translate.stream('ACTIVITY.DEMO.RESTART_DEMO_DIALOG.MODULES'),
                            needOnboardingData: false,
                        },
                        {
                            link: '/company',
                            section: TourSectionsEnum.COMPANY_SETTINGS,
                            name$: this.translate.stream('ACTIVITY.DEMO.RESTART_DEMO_DIALOG.COMPANY_SETTINGS'),
                            needOnboardingData: false,
                        },
                        ...(isDailyResultEnabled ? dailyResultSections : []),
                    ];
                }

                if (isManager) {
                    return [
                        {
                            link: null,
                            section: null,
                            name$: this.translate.stream('ACTIVITY.DEMO.RESTART_DEMO_DIALOG.RESTART_ALL'),
                            needOnboardingData: false,
                        },
                        {
                            link: null,
                            section: TourSectionsEnum.OVERVIEW,
                            name$: this.translate.stream('ACTIVITY.DEMO.RESTART_DEMO_DIALOG.OVERVIEW'),
                            needOnboardingData: false,
                        },
                        {
                            link: '/activity',
                            section: TourSectionsEnum.ACTIVITY,
                            name$: this.translate.stream('ACTIVITY.DEMO.RESTART_DEMO_DIALOG.ACTIVITY'),
                            needOnboardingData: false,
                        },
                        {
                            link: '/projects',
                            section: TourSectionsEnum.PROJECTS,
                            name$: this.translate.stream('ACTIVITY.DEMO.RESTART_DEMO_DIALOG.PROJECTS'),
                            needOnboardingData: false,
                        },
                        {
                            link: '/report',
                            section: TourSectionsEnum.REPORTS,
                            name$: this.translate.stream('ACTIVITY.DEMO.RESTART_DEMO_DIALOG.REPORT'),
                            needOnboardingData: false,
                        },
                        {
                            link: '/time-requests',
                            section: TourSectionsEnum.TIME_REQUESTS,
                            name$: this.translate.stream('ACTIVITY.DEMO.RESTART_DEMO_DIALOG.TIME_REQUESTS'),
                            needOnboardingData: false,
                        },
                        ...(isEffectivityEnabled ? managerEffectivitySections : []),
                        ...(isDailyResultEnabled ? dailyResultSections : []),
                    ];
                }

                if (isObserverOnly) {
                    return [
                        {
                            link: null,
                            section: null,
                            name$: this.translate.stream('ACTIVITY.DEMO.RESTART_DEMO_DIALOG.RESTART_ALL'),
                            needOnboardingData: false,
                        },
                        {
                            link: null,
                            section: TourSectionsEnum.OVERVIEW,
                            name$: this.translate.stream('ACTIVITY.DEMO.RESTART_DEMO_DIALOG.OVERVIEW'),
                            needOnboardingData: false,
                        },
                        {
                            link: '/activity',
                            section: TourSectionsEnum.ACTIVITY,
                            name$: this.translate.stream('ACTIVITY.DEMO.RESTART_DEMO_DIALOG.ACTIVITY'),
                            needOnboardingData: false,
                        },
                        {
                            link: '/projects',
                            section: TourSectionsEnum.PROJECTS,
                            name$: this.translate.stream('ACTIVITY.DEMO.RESTART_DEMO_DIALOG.PROJECTS'),
                            needOnboardingData: false,
                        },
                        {
                            link: '/report',
                            section: TourSectionsEnum.REPORTS,
                            name$: this.translate.stream('ACTIVITY.DEMO.RESTART_DEMO_DIALOG.REPORT'),
                            needOnboardingData: false,
                        },
                    ];
                } else {
                    return [
                        {
                            link: null,
                            section: null,
                            name$: this.translate.stream('ACTIVITY.DEMO.RESTART_DEMO_DIALOG.RESTART_ALL'),
                            needOnboardingData: false,
                        },
                        {
                            link: null,
                            section: TourSectionsEnum.OVERVIEW,
                            name$: this.translate.stream('ACTIVITY.DEMO.RESTART_DEMO_DIALOG.OVERVIEW'),
                            needOnboardingData: false,
                        },
                        {
                            link: '/activity',
                            section: TourSectionsEnum.ACTIVITY,
                            name$: this.translate.stream('ACTIVITY.DEMO.RESTART_DEMO_DIALOG.ACTIVITY'),
                            needOnboardingData: false,
                        },
                        {
                            link: '/report',
                            section: TourSectionsEnum.REPORTS,
                            name$: this.translate.stream('ACTIVITY.DEMO.RESTART_DEMO_DIALOG.REPORT'),
                            needOnboardingData: false,
                        },
                        ...(isDailyResultEnabled ? dailyResultSections : []),
                    ];
                }
            }),
        );
    }

    openSection(item: { link: string; section: TourSectionsEnum; name$: Observable<any>; needOnboardingData: boolean; }) {
        const allSections = [
            TourSectionsEnum.OVERVIEW,
            TourSectionsEnum.USERS,
            TourSectionsEnum.CREATE_USER,
            TourSectionsEnum.PROJECT_SPACES,
            TourSectionsEnum.PROJECTS,
            TourSectionsEnum.NEW_PROJECT,
            TourSectionsEnum.NEW_PROJECT_TEAM,
            TourSectionsEnum.NEW_PROJECT_TEAM_ADD_MEMBER,
            TourSectionsEnum.NEW_PROJECT_TEAM_ADD_MEMBER_FORM,
            TourSectionsEnum.REPORTS,
            TourSectionsEnum.TIME_REQUESTS,
            TourSectionsEnum.MARKETPLACE,
            TourSectionsEnum.COMPANY_SETTINGS,
            TourSectionsEnum.ACTIVITY,
            TourSectionsEnum.EFFECTIVITY,
            TourSectionsEnum.DAILY_RESULT,
        ];

        this.store.dispatch(sendOnboardingStatusStartedAction());

        this.tourService.clearCompletedSections(item.section === TourSectionsEnum.OVERVIEW ? [] : [ TourSectionsEnum.OVERVIEW ]);

        if (item.link) {
            combineLatest([
                this.store.select(currentCompanySelector),
                this.store.select(sharedDataFiltersDaySelector),
            ])
                .pipe(
                    untilDestroyed(this),
                    take(1),
                )
                .subscribe(async ([currentCompany, currentDay]) => {
                    const demoDate = moment(currentCompany.createdAt).startOf('day').subtract(3, 'days');
                    const currentDate = (currentDay ? moment(currentDay) : moment()).startOf('day');

                    if (!currentDate.isSame(demoDate)) {
                        this.store.dispatch(setSharedDataFiltersDayAction({ day: demoDate.toISOString() }));
                    }

                    if (item.section === TourSectionsEnum.DAILY_RESULT) {
                        this.store.dispatch(enableDailyResultSandbox());
                    }

                    window.location.assign(item.link);
                });
        } else {
            if (item.section === null) {
                this.store.dispatch(openWelcomeDialogAction({ data: { returnToRestartDemoDialog: true } }));
                this.store.dispatch(enableDailyResultSandbox());
            } else {
                this.store.dispatch(startTourSectionAction({ section: TourSectionsEnum.OVERVIEW, showCompleteDialog: false }));

                for (const section of allSections) {
                    this.tourService.addSectionToCompleted(section);
                }
            }
        }

        this.cancel();
    }

    cancel(): void {
        this.dialogRef.close(false);
    }

    isSectionDisabled(
        item: { link: string; section: TourSectionsEnum; name$: Observable<any>; needOnboardingData: boolean; },
        onboardingDataStatus: OnboardingDataStatusEnum,
    ) {
        return item.needOnboardingData && onboardingDataStatus === OnboardingDataStatusEnum.NOT_LOADED;
    }

}
