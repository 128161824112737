import { createAction, props } from '@ngrx/store';

import { CompanyConfigActionEnum } from '@app-client/store/company/company-config-action.enum';
import { CompanyConfigKaitenInterface } from '@app-client/store/company/company-config.interface';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';

export const getCompanyConfigKaitenAction = createAction(
    CompanyConfigActionEnum.GET_COMPANY_CONFIG_KAITEN,
);

export const getCompanyConfigKaitenSuccessAction = createAction(
    CompanyConfigActionEnum.GET_COMPANY_CONFIG_KAITEN_SUCCESS,
    props<{ companyConfigKaiten: CompanyConfigKaitenInterface }>(),
);

export const getCompanyConfigKaitenFailureAction = createAction(
    CompanyConfigActionEnum.GET_COMPANY_CONFIG_KAITEN_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const updateCompanyConfigKaitenAction = createAction(
    CompanyConfigActionEnum.UPDATE_COMPANY_CONFIG_KAITEN,
    props<{ companyConfigKaiten: CompanyConfigKaitenInterface }>(),
);

export const updateCompanyConfigKaitenSuccessAction = createAction(
    CompanyConfigActionEnum.UPDATE_COMPANY_CONFIG_KAITEN_SUCCESS
);

export const updateCompanyConfigKaitenFailureAction = createAction(
    CompanyConfigActionEnum.UPDATE_COMPANY_CONFIG_KAITEN_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
