import { createFeatureSelector, createSelector } from '@ngrx/store';

import { EffectivityTemplateStateInterface } from '@app-client/store/effectivity-template/effectivity-template-state.interface';


export const effectivityTemplateFeatureSelector = createFeatureSelector<EffectivityTemplateStateInterface>('effectivityTemplate');

export const effectivityTemplateListQueryParamsSelector = createSelector(
    effectivityTemplateFeatureSelector,
    state => state.effectivityTemplateListQueryParams,
);

export const effectivityTemplateListSelector = createSelector(
    effectivityTemplateFeatureSelector,
    state => state.effectivityTemplateList,
);

export const isEffectivityTemplateListLoadingSelector = createSelector(
    effectivityTemplateFeatureSelector,
    state => state.isEffectivityTemplateListLoading,
);

export const hasMoreEffectivityTemplateSelector = createSelector(
    effectivityTemplateFeatureSelector,
    state => state.hasMoreEffectivityTemplate,
);

export const effectivityTemplateDetailsSelector = createSelector(
    effectivityTemplateFeatureSelector,
    state => state.effectivityTemplateDetails,
);

export const isEffectivityTemplateDetailsLoadingSelector = createSelector(
    effectivityTemplateFeatureSelector,
    state => state.isEffectivityTemplateDetailsLoading,
);
