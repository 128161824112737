import { createAction, props } from '@ngrx/store';

import { CredentialsInterface } from '@app-client/store/auth/types/credentials.interface';
import { CurrentUserInterface } from '@app-client/store/auth/types/current-user.interface';
import { ActionTypes } from '@app-client/store/user-profile/action-types';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


export const updateEmailRequestAction = createAction(
    ActionTypes.UPDATE_EMAIL_REQUEST,
    props<{ newEmail: string }>(),
);

export const updateEmailRequestSuccessAction = createAction(
    ActionTypes.UPDATE_EMAIL_REQUEST_SUCCESS,
    props<{ user: CurrentUserInterface }>(),
);

export const updateEmailRequestFailureAction = createAction(
    ActionTypes.UPDATE_EMAIL_REQUEST_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const updateEmailConfirmAction = createAction(
    ActionTypes.UPDATE_EMAIL_CONFIRM,
    props<{ token: string }>(),
);

export const updateEmailConfirmSuccessAction = createAction(
    ActionTypes.UPDATE_EMAIL_CONFIRM_SUCCESS,
    props<{ credentials: CredentialsInterface }>(),
);

export const updateEmailConfirmFailureAction = createAction(
    ActionTypes.UPDATE_EMAIL_CONFIRM_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const updateEmailCancelAction = createAction(
    ActionTypes.UPDATE_EMAIL_CANCEL,
);

export const updateEmailCancelSuccessAction = createAction(
    ActionTypes.UPDATE_EMAIL_CANCEL_SUCCESS,
    props<{ user: CurrentUserInterface }>(),
);

export const updateEmailCancelFailureAction = createAction(
    ActionTypes.UPDATE_EMAIL_CANCEL_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
