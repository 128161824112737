import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ActivityEffect } from '@app-client/store/activity/effects/activity.effect';
import { TimeEffect } from '@app-client/store/activity/effects/time.effect';
import { activityLocalStorageSyncReducer, activityReducer } from '@app-client/store/activity/reducers/activity.reducer';
import { timeReducer } from '@app-client/store/activity/reducers/time.reducer';
import { ActivityService } from '@app-client/store/activity/services/activity.service';
import { TimeService } from '@app-client/store/activity/services/time.service';
import { AccessRequestEffect } from '@app-client/store/auth/effects/access-request.effect';
import { ForgotPasswordEffect } from '@app-client/store/auth/effects/forgot-password.effect';
import { GetCompanyTrackingSettingsEffect } from '@app-client/store/auth/effects/get-company-tracking-settings.effect';
import { GetCurrentCompanyEffect } from '@app-client/store/auth/effects/get-current-company.effect';
import { GetCurrentUserEffect } from '@app-client/store/auth/effects/get-current-user.effect';
import { LanguageChangeEffect } from '@app-client/store/auth/effects/language-change.effect';
import { LoginUnderUserEffect } from '@app-client/store/auth/effects/login-under-user.effect';
import { LoginEffect } from '@app-client/store/auth/effects/login.effect';
import { TimezoneChangeEffect } from '@app-client/store/auth/effects/timezone-change.effect';
import { TitleEffect } from '@app-client/store/auth/effects/title.effect';
import { authReducer } from '@app-client/store/auth/reducers/auth.reducer';
import { AuthService } from '@app-client/store/auth/services/auth.service';
import { CompanyConfigScreenshotsEffect } from '@app-client/store/company/effects/company-config-screenshots.effect';
import { CompanyConfigSignatureEffect } from '@app-client/store/company/effects/company-config-signature.effect';
import { CompanyPositionsEffect } from '@app-client/store/company/effects/company-positions.effect';
import { CompanySettingsEffect } from '@app-client/store/company/effects/company-settings.effect';
import { companyConfigScreenshotsReducer } from '@app-client/store/company/reducers/company-config-screenshots.reducer';
import { companyConfigSignatureReducer } from '@app-client/store/company/reducers/company-config-signature.reducer';
import { companyPositionsReducer } from '@app-client/store/company/reducers/company-positions.reducer';
import { companySettingsReducer } from '@app-client/store/company/reducers/company-settings.reducer';
import { CompanyConfigService } from '@app-client/store/company/services/company-config.service';
import { CompanyPositionsService } from '@app-client/store/company/services/company-positions.service';
import { CompanySettingsService } from '@app-client/store/company/services/company-settings.service';
import { EffectivityCategoryEffects } from '@app-client/store/effectivity-categories/effects/effectivity-category.effects';
import { effectivityCategoryReducer } from '@app-client/store/effectivity-categories/reducers/effectivity-category.reducer';
import { EffectivityCategoryService } from '@app-client/store/effectivity-categories/services/effectivity-category.service';
import { EffectivityConfigEffects } from '@app-client/store/effectivity-config/effects/effectivity-config.effects';
import { effectivityConfigReducer } from '@app-client/store/effectivity-config/reducers/effectivity-config.reducer';
import { EffectivityConfigService } from '@app-client/store/effectivity-config/services/effectivity-config.service';
import { EffectivityTemplateEffects } from '@app-client/store/effectivity-template/effects/effectivity-template.effects';
import { effectivityTemplateReducer } from '@app-client/store/effectivity-template/reducers/effectivity-template.reducer';
import { EffectivityTemplateService } from '@app-client/store/effectivity-template/services/effectivity-template.service';
import { InvoicingContractorsEffects } from '@app-client/store/invoicing/contractors/effects/invoicing-contractors.effects';
import { invoicingContractorsReducer } from '@app-client/store/invoicing/contractors/reducers/invoicing-contractors.reducer';
import { InvoicingContractorsService } from '@app-client/store/invoicing/contractors/services/invoicing-contractors.service';
import { InvoicingCustomersEffects } from '@app-client/store/invoicing/customers/effects/invoicing-customers.effects';
import { invoicingCustomersReducer } from '@app-client/store/invoicing/customers/reducers/invoicing-customers.reducer';
import { InvoicingCustomersService } from '@app-client/store/invoicing/customers/services/invoicing-customers.service';
import { InvoicingInvoicesEffects } from '@app-client/store/invoicing/invoices/effects/invoicing-invoices.effects';
import { invoicingInvoicesReducer } from '@app-client/store/invoicing/invoices/reducers/invoicing-invoices.reducer';
import { InvoicingInvoicesService } from '@app-client/store/invoicing/invoices/services/invoicing-invoices.service';
import { JiraAuthEffect } from '@app-client/store/jira/effects/jira-auth.effect';
import { JiraConnectionEffect } from '@app-client/store/jira/effects/jira-connection.effect';
import { jiraAuthReducer } from '@app-client/store/jira/reducers/jira-auth.reducer';
import { jiraConnectionReducer } from '@app-client/store/jira/reducers/jira-connection.reducer';
import { JiraAuthService } from '@app-client/store/jira/services/jira-auth.service';
import { JiraConnectionService } from '@app-client/store/jira/services/jira-connection.service';
import { LoggedAsEffect } from '@app-client/store/logged-as/effects/logged-as.effect';
import { loggedAsReducer } from '@app-client/store/logged-as/reducers/logged-as.reducer';
import { MarketplaceAccountEffects } from '@app-client/store/marketplace/account/effects/marketplace-account.effects';
import { marketplaceAccountReducer } from '@app-client/store/marketplace/account/reducer/marketplace-account.reducer';
import { MarketplaceAccountService } from '@app-client/store/marketplace/account/services/marketplace-account.service';
import { MarketplaceInvoicesEffect } from '@app-client/store/marketplace/invoices/effects/marketplace-invoices.effect';
import { marketplaceInvoicesReducer } from '@app-client/store/marketplace/invoices/reducers/marketplace-invoices.reducer';
import { MarketplaceInvoicesService } from '@app-client/store/marketplace/invoices/services/marketplace-invoices.service';
import { MarketplaceModulesEffect } from '@app-client/store/marketplace/modules/effects/marketplace-modules.effect';
import { YearlyReportEffect } from '@app-client/store/marketplace/modules/effects/yearly-report.effect';
import { marketplaceModulesReducer } from '@app-client/store/marketplace/modules/reducers/marketplace-modules.reducer';
import { yearlyReportReducer } from '@app-client/store/marketplace/modules/reducers/yearly-report.reducer';
import { MarketplaceModulesService } from '@app-client/store/marketplace/modules/services/marketplace-modules.service';
import { YearlyReportsService } from '@app-client/store/marketplace/modules/services/yearly-reports.service';
import { ProjectMembersEffect } from '@app-client/store/project-members/effects/project-members-effect.service';
import { projectMembersReducer } from '@app-client/store/project-members/reducers/project-members.reducer';
import { ProjectMembersService } from '@app-client/store/project-members/serivces/project-members.service';
import { ProjectPositionsEffects } from '@app-client/store/project-posiitons/effects/project-positions.effect';
import { projectPositionsReducer } from '@app-client/store/project-posiitons/reducers/project-positions.reducer';
import { ProjectPositionsService } from '@app-client/store/project-posiitons/serivces/project-positions.service';
import { ProjectSpacesEffect } from '@app-client/store/project-spaces/effects/project-spaces.effect';
import { projectSpacesReducer } from '@app-client/store/project-spaces/reducers/project-spaces.reducer';
import { ProjectSpacesService } from '@app-client/store/project-spaces/services/project-spaces.service';
import { ProjectTasksEffects } from '@app-client/store/project-tasks/effects/project-tasks.effect';
import { projectTasksReducer } from '@app-client/store/project-tasks/reducers/project-tasks.reducer';
import { ProjectTasksService } from '@app-client/store/project-tasks/serivces/project-tasks.service';
import { ProjectsEffects } from '@app-client/store/projects/effects/projects.effects';
import { projectsReducer } from '@app-client/store/projects/reducers/projects.reducer';
import { ProjectsService } from '@app-client/store/projects/services/projects.service';
import { ReportEffect } from '@app-client/store/report/by-project/effects/report.effect';
import { reportLocalStorageSyncReducer, reportReducer } from '@app-client/store/report/by-project/reducers/report.reducer';
import { ReportService } from '@app-client/store/report/by-project/services/report.service';
import { sharedReportFiltersLocalStorageSyncReducer, sharedReportFiltersReducer } from '@app-client/store/report/shared-filters/reducers/shared-report-filters.reducer';
import { ReportUserActivityEffect } from '@app-client/store/report/user-activity/effects/report-user-activity.effect';
import { reportUserActivityReducer } from '@app-client/store/report/user-activity/reducers/report-user-activity.reducer';
import { ReportUserActivityService } from '@app-client/store/report/user-activity/services/report-user-activity.service';
import { ReportUsingApplicationsEffect } from '@app-client/store/report/using-applications/effects/report-using-applications.effect';
import { reportUsingApplicationsReducer } from '@app-client/store/report/using-applications/reducers/report-using-applications.reducer';
import { ReportUsingApplicationsService } from '@app-client/store/report/using-applications/services/report-using-applications.service';
import { ReportWorkDayEffect } from '@app-client/store/report/work-day/effects/report-wirk-day.effect';
import { reportWorkDayReducer } from '@app-client/store/report/work-day/reducers/report-work-day.reducer';
import { ReportWorkDayService } from '@app-client/store/report/work-day/services/report-work-day.service';
import { sharedDataFiltersReducer } from '@app-client/store/shared-data-filters/reducers/shared-data-filters.reducer';
import { signatureBioLocalStorageSyncReducer, signatureBioReducer } from '@app-client/store/signature/bio/signature-bio.reducer';
import { SignatureDocumentEffects } from '@app-client/store/signature/document/signature-document.effects';
import { signatureDocumentReducer } from '@app-client/store/signature/document/signature-document.reducer';
import { SignatureDocumentService } from '@app-client/store/signature/document/signature-document.service';
import { SignatureUnsignedIntervalsEffects } from '@app-client/store/signature/unsiged-intervals/signature-unsigned-intervals.effects';
import { signatureUnsignedIntervalsReducer } from '@app-client/store/signature/unsiged-intervals/signature-unsigned-intervals.reducer';
import { SignatureUnsignedIntervalsService } from '@app-client/store/signature/unsiged-intervals/signature-unsigned-intervals.service';
import { TimeRequestEffect } from '@app-client/store/time-request/effects/time-request.effect';
import { timeRequestsReducer } from '@app-client/store/time-request/reducers/time-request.reducer';
import { TimeRequestService } from '@app-client/store/time-request/services/time-request.service';
import { TourEffect } from '@app-client/store/tour/effects/tour.effect';
import { tourLocalStorageSyncReducer, tourReducer } from '@app-client/store/tour/reducers/tour.reducer';
import { TrelloEffect } from '@app-client/store/trello/effects/trello.effect';
import { trelloReducer } from '@app-client/store/trello/reducers/trello.reducer';
import { TrelloService } from '@app-client/store/trello/services/trello.service';
import { UserProfileEffect } from '@app-client/store/user-profile/effects/user-profile.effect';
import { userProfileReducer } from '@app-client/store/user-profile/reducers/user-profile.reducer';
import { UserProfileService } from '@app-client/store/user-profile/services/user-profile.service';
import { UserRequestEffect } from '@app-client/store/users/effects/user-request.effect';
import { UsersEffect } from '@app-client/store/users/effects/users.effect';
import { userRequestReducer } from '@app-client/store/users/reducers/user-request.reducer';
import { usersReducer } from '@app-client/store/users/reducers/users.reducer';
import { UserRequestService } from '@app-client/store/users/services/user-request.service';
import { UsersService } from '@app-client/store/users/services/users.service';

import { CompanyConfigKaitenEffect } from './company/effects/company-config-kaiten.effect';
import { companyConfigKaitenReducer } from './company/reducers/company-config-kaiten.reducer';
import { KaitenEffect } from './kaiten/effects/kaiten.effect';
import { kaitenReducer } from './kaiten/reducers/kaiten.reducer';
import { KaitenService } from './kaiten/services/kaiten.service';


@NgModule({
    imports: [
        StoreModule.forRoot({
            auth: authReducer,
            projectSpaces: projectSpacesReducer,
            userProfile: userProfileReducer,
            companySettings: companySettingsReducer,
            companyPositions: companyPositionsReducer,
            users: usersReducer,
            loggedAs: loggedAsReducer,
            projectMembers: projectMembersReducer,
            userRequest: userRequestReducer,
            projects: projectsReducer,
            projectPositions: projectPositionsReducer,
            projectTasks: projectTasksReducer,
            effectivityTemplate: effectivityTemplateReducer,
            effectivityCategory: effectivityCategoryReducer,
            activity: activityReducer,
            time: timeReducer,
            timeRequests: timeRequestsReducer,
            effectivityConfig: effectivityConfigReducer,
            report: reportReducer,
            trello: trelloReducer,
            kaiten: kaitenReducer,
            sharedDataFilters: sharedDataFiltersReducer,
            sharedReportFilters: sharedReportFiltersReducer,
            jiraAuth: jiraAuthReducer,
            jiraConnection: jiraConnectionReducer,
            tour: tourReducer,
            marketplaceAccount: marketplaceAccountReducer,
            marketplaceModules: marketplaceModulesReducer,
            marketplaceInvoices: marketplaceInvoicesReducer,
            reportUserActivity: reportUserActivityReducer,
            reportWorkDay: reportWorkDayReducer,
            reportUsingApplications: reportUsingApplicationsReducer,
            invoicingContractors: invoicingContractorsReducer,
            invoicingCustomers: invoicingCustomersReducer,
            invoicingInvoices: invoicingInvoicesReducer,
            yearlyReport: yearlyReportReducer,
            companyConfigScreenshots: companyConfigScreenshotsReducer,
            companyConfigSignature: companyConfigSignatureReducer,
            companyConfigKaiten: companyConfigKaitenReducer,
            signatureUnsignedIntervals: signatureUnsignedIntervalsReducer,
            signatureBio: signatureBioReducer,
            signatureDocument: signatureDocumentReducer,
        }, {
            metaReducers: [
                activityLocalStorageSyncReducer,
                sharedReportFiltersLocalStorageSyncReducer,
                reportLocalStorageSyncReducer,
                signatureBioLocalStorageSyncReducer,
                tourLocalStorageSyncReducer,
            ],
        }),
        EffectsModule.forRoot([
            GetCurrentUserEffect,
            GetCurrentCompanyEffect,
            GetCompanyTrackingSettingsEffect,
            TitleEffect,
            LoginEffect,
            LanguageChangeEffect,
            ForgotPasswordEffect,
            AccessRequestEffect,
            ProjectSpacesEffect,
            ProjectSpacesEffect,
            UserProfileEffect,
            CompanySettingsEffect,
            CompanyPositionsEffect,
            UsersEffect,
            LoginUnderUserEffect,
            LoggedAsEffect,
            ProjectMembersEffect,
            UserRequestEffect,
            ProjectsEffects,
            ProjectPositionsEffects,
            ProjectTasksEffects,
            EffectivityTemplateEffects,
            EffectivityCategoryEffects,
            ActivityEffect,
            TimeEffect,
            TimeRequestEffect,
            EffectivityConfigEffects,
            ReportEffect,
            TrelloEffect,
            KaitenEffect,
            TimezoneChangeEffect,
            JiraConnectionEffect,
            JiraAuthEffect,
            TourEffect,
            MarketplaceAccountEffects,
            MarketplaceModulesEffect,
            MarketplaceInvoicesEffect,
            ReportUserActivityEffect,
            ReportWorkDayEffect,
            ReportUsingApplicationsEffect,
            InvoicingContractorsEffects,
            InvoicingCustomersEffects,
            InvoicingInvoicesEffects,
            YearlyReportEffect,
            CompanyConfigScreenshotsEffect,
            CompanyConfigSignatureEffect,
            CompanyConfigKaitenEffect,
            SignatureUnsignedIntervalsEffects,
            SignatureDocumentEffects,
        ]),
    ],
    providers: [
        AuthService,
        ProjectSpacesService,
        UserProfileService,
        CompanySettingsService,
        CompanyPositionsService,
        UsersService,
        ProjectMembersService,
        UserRequestService,
        ProjectsService,
        ProjectPositionsService,
        ProjectTasksService,
        EffectivityTemplateService,
        EffectivityCategoryService,
        ActivityService,
        TimeService,
        TimeRequestService,
        EffectivityConfigService,
        ReportService,
        TrelloService,
        KaitenService,
        JiraConnectionService,
        JiraAuthService,
        MarketplaceAccountService,
        MarketplaceModulesService,
        MarketplaceInvoicesService,
        ReportUserActivityService,
        ReportWorkDayService,
        ReportUsingApplicationsService,
        InvoicingContractorsService,
        InvoicingCustomersService,
        InvoicingInvoicesService,
        YearlyReportsService,
        CompanyConfigService,
        SignatureUnsignedIntervalsService,
        SignatureDocumentService,
    ],
})
export class AppStoreModule {}
