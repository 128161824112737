import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ProjectMembersStateInterface } from '@app-client/store/project-members/project-members-state.interface';


export const projectMembersFeatureSelector = createFeatureSelector<ProjectMembersStateInterface>('projectMembers');

export const projectMembersSelector = createSelector(
    projectMembersFeatureSelector,
    state => state.projectMemberList,
);

export const isProjectMemberListLoadingSelector = createSelector(
    projectMembersFeatureSelector,
    state => state.isProjectMemberListLoading,
);

export const projectMemberListQueryParamsSelector = createSelector(
    projectMembersFeatureSelector,
    state => state.listQueryParams,
);

export const hasMoreProjectMemberSelector = createSelector(
    projectMembersFeatureSelector,
    state => state.hasMoreProjectMember,
);

export const isCreateProjectMemberInProgressSelector = createSelector(
    projectMembersFeatureSelector,
    state => state.isCreateProjectMemberInProgress,
);

export const createProjectMemberBackendErrorsSelector = createSelector(
    projectMembersFeatureSelector,
    state => state.createProjectMemberBackendErrors,
);

export const isUpdateProjectMemberInProgressSelector = createSelector(
    projectMembersFeatureSelector,
    state => state.isUpdateProjectMemberInProgress,
);

export const updateProjectMemberBackendErrorsSelector = createSelector(
    projectMembersFeatureSelector,
    state => state.updateProjectMemberBackendErrors,
);

export const isRemoveProjectMemberInProgress = createSelector(
    projectMembersFeatureSelector,
    state => state.isRemoveProjectMemberInProgress,
);

export const projectMemberDetailsSelector = createSelector(
    projectMembersFeatureSelector,
    state => state.projectMemberDetails,
);

export const isProjectMemberDetailsLoadingSelector = createSelector(
    projectMembersFeatureSelector,
    state => state.isProjectMemberDetailsLoading,
);

export const projectMembersInUpdatePermissionIdsSelector = createSelector(
    projectMembersFeatureSelector,
    (state) => state.projectMembersInUpdatePermissionIds,
);
