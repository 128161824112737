import { createAction, props } from '@ngrx/store';

import { CurrentUserInterface } from '@app-client/store/auth/types/current-user.interface';
import { ActionTypes } from '@app-client/store/user-profile/action-types';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


export const updateAvatarAction = createAction(
    ActionTypes.UPDATE_AVATAR,
    props<{ avatar: string }>(),
);

export const updateAvatarSuccessAction = createAction(
    ActionTypes.UPDATE_AVATAR_SUCCESS,
    props<{ user: CurrentUserInterface }>(),
);

export const updateAvatarFailureAction = createAction(
    ActionTypes.UPDATE_AVATAR_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
