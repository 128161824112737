import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import { endTourAction, nextTourStepAction, sendOnboardingStatusFinishedAction } from '@app-client/store/tour/actions/tour.action';
import { CompleteDemoDialogComponent } from '@app-client/tour/components/complete-demo-dialog/complete-demo-dialog.component';
import { SkippedDemoDialogComponent } from '@app-client/tour/components/skipped-demo-dialog/skipped-demo-dialog.component';
import { TourService } from '@app-client/tour/services/tour.service';


@UntilDestroy()
@Component({
    selector: 'app-tour-tooltip',
    templateUrl: './tour-tooltip.component.html',
    styleUrls: ['./tour-tooltip.component.scss'],
})
export class TourTooltipComponent {
    readonly currentStepTranslations$ = this.tour.currentStepTranslations$;

    constructor(
        private matDialog: MatDialog,
        private tour: TourService,
        private store: Store,
    ) {}

    nextStep() {
        if (this.tour?.isLastStep && this.tour?.isShouldFinishTutorial) {
            this.store.dispatch(sendOnboardingStatusFinishedAction());
        }

        if (this.tour?.isLastStep && this.tour?.showCompleteDialog) {
            this.matDialog.open(CompleteDemoDialogComponent, {
                panelClass: 'no-padding-dialog-container'
            });

            this.store.dispatch(endTourAction());
        } else if (this.tour?.isLastStep) {
            this.store.dispatch(endTourAction());
        } else {
            this.store.dispatch(nextTourStepAction());
        }
    }

    skipTour() {
        this.matDialog.open(SkippedDemoDialogComponent, {
            panelClass: 'no-padding-dialog-container'
        });

        this.store.dispatch(sendOnboardingStatusFinishedAction());
        this.store.dispatch(endTourAction());
    }
}
