import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { UrlService } from '@app-client/shared/services/url.service';
import {
    clearForgotPasswordSaveNewPasswordAction,
    forgotPasswordSaveNewPasswordAction,
    forgotPasswordValidateTokenAction,
} from '@app-client/store/auth/actions/forgot-password.action';
import {
    forgotPasswordBackendErrorsSelector,
    forgotPasswordRequestedForEmailSelector,
    forgotPasswordSaveNewPasswordSuccessSelector,
    isForgotPasswordSaveNewPasswordInProgressSelector,
    isForgotPasswordTokenValidateInProgressSelector,
} from '@app-client/store/auth/selectors/auth.selectors';
import { passwordMatchesValidator } from '@app-shared/validators/passwords-matches.validatior';
import { strongPasswordValidator } from '@app-shared/validators/strong-password.validator';


@UntilDestroy()
@Component({
    selector: 'app-new-password',
    templateUrl: './new-password.component.html',
    styleUrls: ['./new-password.component.scss'],
})
export class NewPasswordComponent implements OnInit, OnDestroy {
    forgotPasswordRequestedForEmail$: Observable<string | null>;
    isForgotPasswordTokenValidateInProgress$: Observable<boolean>;
    isForgotPasswordSaveNewPasswordInProgress$: Observable<boolean>;

    isForgotPasswordRequestSuccess$: Observable<boolean>;

    token: string;

    form: FormGroup;

    constructor(
        private store: Store,
        private fb: FormBuilder,
        private urlService: UrlService,
        private route: ActivatedRoute,
        private router: Router,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
    ) {
        this.iconRegistry.addSvgIcon(
            'attrack-eye-hide',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/eye_hide_icon.svg'));
        this.iconRegistry.addSvgIcon(
            'attrack-eye-open',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/eye_open_icon.svg'));
    }

    get password() { return this.form.get('password'); }

    get passwordConfirm() { return this.form.get('passwordConfirm'); }

    ngOnInit(): void {
        this.tryToFetchChangePasswordToken();
        this.initializeForm();
        this.initializeValues();
        this.initializeBackendErrorsWatcher();
    }

    tryToFetchChangePasswordToken() {
        const changePasswordToken = this.route.snapshot.queryParams['change-password-token'];

        const params = {};
        params['change-password-token'] = null;
        this.urlService.updateCurrentUrlQueryParams(params);

        if (changePasswordToken) {
            this.token = changePasswordToken;
            this.store.dispatch(forgotPasswordValidateTokenAction({
                token: changePasswordToken,
            }));
        } else {
            this.router.navigate(['/login']);
        }
    }

    initializeForm(): void {
        this.form = this.fb.group({
            password: ['', [Validators.required, strongPasswordValidator]],
            passwordConfirm: [''],
        }, {
            validators: [
                passwordMatchesValidator('password', 'passwordConfirm'),
            ],
        });
    }

    initializeValues(): void {
        this.forgotPasswordRequestedForEmail$ = this.store.select(forgotPasswordRequestedForEmailSelector);
        this.isForgotPasswordTokenValidateInProgress$ = this.store.select(isForgotPasswordTokenValidateInProgressSelector).pipe(
            untilDestroyed(this),
            tap((inProgress) => {
                if (inProgress) {
                    this.form.disable();
                } else {
                    this.form.enable();
                }
            }),
        );
        this.isForgotPasswordSaveNewPasswordInProgress$ = this.store.select(isForgotPasswordSaveNewPasswordInProgressSelector).pipe(
            untilDestroyed(this),
            tap((inProgress) => {
                if (inProgress) {
                    this.form.disable();
                } else {
                    this.form.enable();
                }
            }),
        );

        this.isForgotPasswordRequestSuccess$ = this.store.select(forgotPasswordSaveNewPasswordSuccessSelector);
    }

    submit(): void {
        if (this.form.invalid) {
            return;
        }

        if (this.password.value !== this.passwordConfirm.value) {
            return;
        }

        this.store.dispatch(forgotPasswordSaveNewPasswordAction({
            token: this.token,
            plainPassword: {
                first: this.password.value,
                second: this.passwordConfirm.value,
            },
        }));
    }

    ngOnDestroy(): void {
        this.store.dispatch(clearForgotPasswordSaveNewPasswordAction());
    }

    private initializeBackendErrorsWatcher() {
        this.store.select(forgotPasswordBackendErrorsSelector).pipe(
            untilDestroyed(this),
        ).subscribe(backendErrors => {
            if (!backendErrors || !Object.keys(backendErrors).length) {
                return;
            }

            for (const key of Object.keys(backendErrors)) {
                const control = this.form.get(key);

                if (!control) {
                    continue;
                }

                if (backendErrors[key]) {
                    control.setErrors({
                        ...control.errors,
                        backendErrors: backendErrors[key],
                    });
                }

                if (control.errors && !Object.keys(control.errors)) {
                    control.setErrors(null);
                }
            }
        });
    }
}
