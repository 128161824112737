import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AccountResponseInterface } from '@app-client/payment/overview/types/account-response.interface';
import { CardResponseInterface } from '@app-client/payment/overview/types/card-response.interface';
import { PayResponseInterface } from '@app-client/payment/overview/types/pay-response.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';

import { environment } from '@env-client/environment';


@Injectable()
export class MarketplaceAccountService {
    constructor(
        private http: HttpClient,
    ) {}

    getMarketplaceAccountData(): Observable<AccountResponseInterface> {
        const url = `${environment.apiBaseUrl}/market/account`;

        return this.http.get<ResponseInterface<AccountResponseInterface>>(url).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    getCards(): Observable<Array<CardResponseInterface>> {
        const url = `${environment.apiBaseUrl}/market/payments/cards`;

        return this.http.get<ResponseInterface<{ items: Array<CardResponseInterface> }>>(url).pipe(
            map((response) => {
                return response.body.items;
            }),
        );
    }

    removeCard(id: string): Observable<void> {
        const url = `${environment.apiBaseUrl}/market/payments/cards/${id}`;

        return this.http.delete<void>(url);
    }

    getInitKey(): Observable<string> {
        const url = `${environment.apiBaseUrl}/market/payments/cards`;

        return this.http.post<ResponseInterface<{ clientSecret: string }>>(url, {}).pipe(
            map((response) => {
                return response.body.clientSecret;
            }),
        );
    }

    pay(cardId: string, amount: number): Observable<PayResponseInterface> {
        const url = `${environment.apiBaseUrl}/market/payments/pay`;
        const body = {
            cardId,
            amount,
        };

        return this.http.post<ResponseInterface<PayResponseInterface>>(url, body).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    charge(amount: number): Observable<string> {
        const url = `${environment.apiBaseUrl}/market/payments/charge`;
        const body = {
            amount,
        };

        return this.http.post<ResponseInterface<{ clientSecret: string }>>(url, body).pipe(
            map((response) => {
                return response.body.clientSecret;
            }),
        );
    }
}
