import { ActionReducer, createReducer, on } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

import { SignatureBioStateInterface } from '@app-client/store/signature/bio/signature-bio-state.interface';
import {
    signatureBioSetBioAction,
    signatureBioSetFirstNameAction,
    signatureBioSetLastNameAction, signatureBioSetMiddleNameAction,
} from '@app-client/store/signature/bio/signature-bio.actions';


const initialState: SignatureBioStateInterface = {
    firstName: null,
    lastName: null,
    middleName: null,
};

const reducer = createReducer(
    initialState,
    on(
        signatureBioSetBioAction,
        (state, { firstName, lastName, middleName }) => ({
            ...state,
            firstName,
            lastName,
            middleName,
        }),
    ),
    on(
        signatureBioSetFirstNameAction,
        (state, { value }) => ({
            ...state,
            firstName: value,
        }),
    ),
    on(
        signatureBioSetLastNameAction,
        (state, { value }) => ({
            ...state,
            lastName: value,
        }),
    ),
    on(
        signatureBioSetMiddleNameAction,
        (state, { value }) => ({
            ...state,
            middleName: value,
        }),
    ),
);

export const signatureBioReducer = (state, action) => reducer(state, action);
export const signatureBioLocalStorageSyncReducer = (r: ActionReducer<any>): ActionReducer<any> => {
    return localStorageSync({
        keys: [
            {
                signatureBio: [
                    'firstName',
                    'lastName',
                    'middleName',
                ],
            },
        ],
        rehydrate: true,
    })(r);
};
