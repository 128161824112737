import { FormGroup } from '@angular/forms';


export class Utility {
    public static markAllFormFieldsAsDirty(form: FormGroup): void {
        for (const controlsKey in form.controls) {
            if (form.controls.hasOwnProperty(controlsKey)) {
                form.controls[controlsKey].markAsDirty();
            }
        }
    }
}
