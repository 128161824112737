import { createAction, props } from '@ngrx/store';

import { CompanyPositionListQueryInterface } from '@app-client/company/types/position/company-position-list-query.interface';
import { CompanyPositionInterface } from '@app-client/company/types/position/company-position.interface';
import { PositionsActionTypes } from '@app-client/store/company/positions-action-types';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';
import { PageResponseInterface } from '@app-shared/types/page-response.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';


export const setCompanyPositionsQueryParamsAction = createAction(
    PositionsActionTypes.SET_COMPANY_POSITIONS_QUERY_PARAMS,
    props<{ queryParams: CompanyPositionListQueryInterface }>(),
);

export const getCompanyPositionsAction = createAction(
    PositionsActionTypes.GET_COMPANY_POSITIONS,
);
export const getCompanyPositionsSuccessAction = createAction(
    PositionsActionTypes.GET_COMPANY_POSITIONS_SUCCESS,
    props<{ response: ResponseInterface<PageResponseInterface<CompanyPositionInterface>> }>(),
);
export const getCompanyPositionsFailureAction = createAction(
    PositionsActionTypes.GET_COMPANY_POSITIONS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const createCompanyPositionAction = createAction(
    PositionsActionTypes.CREATE_COMPANY_POSITION,
    props<{ name: string }>(),
);
export const createCompanyPositionSuccessAction = createAction(
    PositionsActionTypes.CREATE_COMPANY_POSITION_SUCCESS,
    props<{ newPosition: CompanyPositionInterface }>(),
);
export const createCompanyPositionFailureAction = createAction(
    PositionsActionTypes.CREATE_COMPANY_POSITION_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const updateCompanyPositionAction = createAction(
    PositionsActionTypes.UPDATE_COMPANY_POSITION,
    props<{ id: string, name: string }>(),
);
export const updateCompanyPositionSuccessAction = createAction(
    PositionsActionTypes.UPDATE_COMPANY_POSITION_SUCCESS,
    props<{ updatedPosition: CompanyPositionInterface }>(),
);
export const updateCompanyPositionFailureAction = createAction(
    PositionsActionTypes.UPDATE_COMPANY_POSITION_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const deleteCompanyPositionAction = createAction(
    PositionsActionTypes.DELETE_COMPANY_POSITION,
    props<{ id: string }>(),
);
export const deleteCompanyPositionSuccessAction = createAction(
    PositionsActionTypes.DELETE_COMPANY_POSITION_SUCCESS,
);
export const deleteCompanyPositionFailureAction = createAction(
    PositionsActionTypes.DELETE_COMPANY_POSITION_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
