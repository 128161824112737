import { createAction, props } from '@ngrx/store';

import { ActionTypes } from '@app-client/store/auth/action-types';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


export const forgotPasswordRequestAction = createAction(
    ActionTypes.FORGOT_PASSWORD_REQUEST,
    props<{ email: string }>(),
);
export const forgotPasswordRequestSuccessAction = createAction(
    ActionTypes.FORGOT_PASSWORD_REQUEST_SUCCESS,
);
export const forgotPasswordRequestFailureAction = createAction(
    ActionTypes.FORGOT_PASSWORD_REQUEST_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
export const clearForgotPasswordRequestAction = createAction(
    ActionTypes.CLEAR_FORGOT_PASSWORD_REQUEST,
);

export const forgotPasswordValidateTokenAction = createAction(
    ActionTypes.FORGOT_PASSWORD_VALIDATE_TOKEN,
    props<{ token: string }>(),
);
export const forgotPasswordValidateTokenSuccessAction = createAction(
    ActionTypes.FORGOT_PASSWORD_VALIDATE_TOKEN_SUCCESS,
    props<{ email: string }>(),
);
export const forgotPasswordValidateTokenFailureAction = createAction(
    ActionTypes.FORGOT_PASSWORD_VALIDATE_TOKEN_FAILURE,
);

export const forgotPasswordSaveNewPasswordAction = createAction(
    ActionTypes.FORGOT_PASSWORD_SAVE_NEW_PASSWORD,
    props<{ token: string, plainPassword: { first: string, second: string } }>(),
);
export const forgotPasswordSaveNewPasswordSuccessAction = createAction(
    ActionTypes.FORGOT_PASSWORD_SAVE_NEW_PASSWORD_SUCCESS,
);
export const forgotPasswordSaveNewPasswordFailureAction = createAction(
    ActionTypes.FORGOT_PASSWORD_SAVE_NEW_PASSWORD_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
export const clearForgotPasswordSaveNewPasswordAction = createAction(
    ActionTypes.CLEAR_FORGOT_PASSWORD_SAVE_NEW_PASSWORD,
);
