export enum KaitenErrorsEnum {
    SPACE_NOT_FOUND = '[kaiten_space_not_found]',
    SPACE_ALREADY_ATTACHED = '[kaiten_space_already_attached]',
    SPACE_NOT_ATTACHED = '[kaiten_space_not_attached]',
    SPACE_IN_SYNC = '[kaiten_space_in_sync]',
    BOARD_NOT_FOUND = '[kaiten_board_not_found]',
    BOARD_ALREADY_ATTACHED = '[kaiten_board_already_attached]',
    BOARD_NOT_ATTACHED = '[kaiten_board_not_attached]',
    BOARD_IN_SYNC = '[kaiten_board_in_sync]',
    CLIENT_ACCESS_DENIED = '[kaiten_client_access_denied]',
    CLIENT_ENTITY_NOT_FOUND = '[kaiten_client_entity_not_found]',
    CLIENT_SOMETHING_WRONG = '[kaiten_client_something_wrong]',
    INVALID_KEY = '[kaiten_key_is_invalid]'
}
