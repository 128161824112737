import { createAction, props } from '@ngrx/store';

import { SignatureUnsignedIntervalsActionsEnum } from '@app-client/store/signature/unsiged-intervals/signature-unsigned-intervals-actions.enum';
import { SignRequestType } from '@app-client/store/signature/unsiged-intervals/types/sign-request.type';
import { UnsignedIntervalType } from '@app-client/store/signature/unsiged-intervals/types/unsigned-interval.type';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


export const signatureUnsignedIntervalsGetListAction = createAction(
    SignatureUnsignedIntervalsActionsEnum.GET_LIST,
);

export const signatureUnsignedIntervalsGetListSuccessAction = createAction(
    SignatureUnsignedIntervalsActionsEnum.GET_LIST_SUCCESS,
    props<{ list: Array<UnsignedIntervalType> }>(),
);

export const signatureUnsignedIntervalsGetListFailureAction = createAction(
    SignatureUnsignedIntervalsActionsEnum.GET_LIST_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const signatureUnsignedIntervalsSignAction = createAction(
    SignatureUnsignedIntervalsActionsEnum.SIGN,
    props<{ signRequest: SignRequestType }>(),
);

export const signatureUnsignedIntervalsSignSuccessAction = createAction(
    SignatureUnsignedIntervalsActionsEnum.SIGN_SUCCESS,
);

export const signatureUnsignedIntervalsSignFailureAction = createAction(
    SignatureUnsignedIntervalsActionsEnum.SIGN_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
