import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { OnboardingDataStatusEnum } from '@app-client/shared/types/onboarding-data-status.enum';
import { OnboardingStatusEnum } from '@app-client/shared/types/onboarding-status.enum';
import { getMonthlyStatisticsAction, getTimelineDataAction } from '@app-client/store/activity/actions/activity-data.action';
import { getCurrentUserAction } from '@app-client/store/auth/actions/get-current-user.action';
import { logoutAction } from '@app-client/store/auth/actions/login.action';
import {
    currentCompanySelector,
    currentUserIsAdminSelector,
    currentUserIsManagerSelector,
    currentUserIsObserverSelector,
    currentUserIsSysadminSelector,
    currentUserSelector,
} from '@app-client/store/auth/selectors/auth.selectors';
import { marketplaceEnabledCoreModuleTariffSelector } from '@app-client/store/marketplace/modules/selectors/marketplace-modules.selectors';
import { setSharedDataFiltersDayAction } from '@app-client/store/shared-data-filters/actions/shared-data-filters.action';
import { sharedDataFiltersDaySelector } from '@app-client/store/shared-data-filters/selectors/shared-data-filters.selector';
import {
    checkCompanyOnboardingDataAction,
    checkCompanyOnboardingDataFailedAction,
    checkCompanyOnboardingDataSuccessAction,
    deleteCompanyOnboardingDataAction,
    deleteCompanyOnboardingDataFailedAction,
    deleteCompanyOnboardingDataSuccessAction,
    endTourAction,
    loadCompanyOnboardingDataAction,
    loadCompanyOnboardingDataFailedAction,
    loadCompanyOnboardingDataSuccessAction,
    nextTourStepAction,
    onboardingStatusRestartedAction,
    openRestartDialogAction,
    openWelcomeDialogAction,
    sendOnboardingStatusFinishedAction,
    sendOnboardingStatusFinishedFailedAction,
    sendOnboardingStatusFinishedSuccessAction,
    sendOnboardingStatusStartedAction,
    sendOnboardingStatusStartedFailedAction,
    sendOnboardingStatusStartedSuccessAction,
    startTourSectionAction,
} from '@app-client/store/tour/actions/tour.action';
import { currentSectionSelector, isTourCompletedSelector, onboardingStatusSelector } from '@app-client/store/tour/selectors/tour.selector';
import { RestartDemoDialogComponent } from '@app-client/tour/components/restart-demo-dialog/restart-demo-dialog.component';
import {
    WelcomeDemoDialogComponent,
    WelcomeDemoDialogData,
} from '@app-client/tour/components/welcome-demo-dialog/welcome-demo-dialog.component';
import { TourService } from '@app-client/tour/services/tour.service';
import { TourUsersRoleEnum } from '@app-client/tour/tour-users-role.enum';
import { TourSectionsEnum } from '@app-client/tour/types/tour-sections.enum';
import { CoreModuleTariffsEnum } from '@app-shared/types/app-modules.enum';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class TourEffect {
    constructor(
        private actions$: Actions,
        private store$: Store,
        private tourService: TourService,
        private toastr: ToastrService,
        private translate: TranslateService,
        private matDialog: MatDialog,
    ) {}

    startTourSection$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(startTourSectionAction),
            withLatestFrom(
                this.store$.select(isTourCompletedSelector),
                this.store$.select(currentUserIsAdminSelector),
                this.store$.select(currentUserIsSysadminSelector),
                this.store$.select(currentUserIsManagerSelector),
                this.store$.select(currentUserIsObserverSelector),
                this.store$.select(marketplaceEnabledCoreModuleTariffSelector),
            ),
            tap(([action, isCompleted, isAdmin, isSysadmin, isManager, isObserver, activatedTariff]) => {
                const completedSections = this.tourService.completedSections;
                const section = action.section;
                const isSectionCompleted = completedSections.includes(section);
                const showLoadDataDialog = section !== TourSectionsEnum.OVERVIEW && !completedSections.includes(TourSectionsEnum.OVERVIEW);

                if (!isCompleted) {
                    let role = TourUsersRoleEnum.USER;

                    if (isObserver) {
                        role = TourUsersRoleEnum.OBSERVER;
                    }

                    if (isManager) {
                        role = TourUsersRoleEnum.MANAGER;
                    }

                    if (isSysadmin) {
                        role = TourUsersRoleEnum.SYSADMIN;
                    }

                    if (isAdmin) {
                        role = TourUsersRoleEnum.ADMIN;
                    }

                    if (showLoadDataDialog) {
                        this.matDialog.open(WelcomeDemoDialogComponent, {
                            panelClass: 'no-padding-dialog-container'
                        });
                    } else if (!isSectionCompleted) {
                        this.tourService.start(
                            section,
                            role,
                            activatedTariff || CoreModuleTariffsEnum.BASIC,
                            action.showCompleteDialog || false,
                            action.finishTutorialAfterThisSection || false
                        );
                    }
                }
            })
        );
    }, { dispatch: false });

    nextTourStep$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nextTourStepAction),
            tap(() => {
                this.tourService.nextStep();
            }),
        );
    }, { dispatch: false });

    endTour$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(endTourAction),
            withLatestFrom(this.store$.select(currentSectionSelector)),
            tap(([_, section]) => {
                this.tourService.addSectionToCompleted(section);
                this.tourService.end();
            }),
        );
    }, { dispatch: false });

    sendStatusStarted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(sendOnboardingStatusStartedAction),
            withLatestFrom(
                this.store$.select(currentUserSelector),
                this.store$.select(onboardingStatusSelector),
            ),
            switchMap(([, currentUser, onboardingDataStatus]) => {
                const isOnboarding = onboardingDataStatus !== OnboardingDataStatusEnum.NOT_LOADED
                                    && currentUser.onboardingStatus === OnboardingStatusEnum.IN_PROGRESS;

                if (!isOnboarding) {
                    return this.tourService.sendStatusStarted()
                        .pipe(
                            map(() => sendOnboardingStatusStartedSuccessAction()),
                            catchError((httpErrorResponse: HttpErrorResponse) => {
                                const backendErrors: BackendErrorsInterface = {
                                    message: httpErrorResponse.error.message,
                                    errors: httpErrorResponse.error.errors,
                                };

                                return of(sendOnboardingStatusStartedFailedAction({ backendErrors }));
                            })
                        );
                } else {
                    return of(onboardingStatusRestartedAction());
                }
            })
        );
    });

    sendStatusStartedSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(sendOnboardingStatusStartedSuccessAction),
            map(() => getCurrentUserAction())
        );
    });

    sendStatusStartedFailed$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(sendOnboardingStatusStartedFailedAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('ACTIVITY.DEMO.CHANGE_STATUS.START.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    sendStatusFinished$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(sendOnboardingStatusFinishedAction),
            withLatestFrom(
                this.store$.select(currentUserSelector),
                this.store$.select(onboardingStatusSelector),
            ),
            switchMap(([, currentUser, onboardingDataStatus]) => {
                const isOnboarding = onboardingDataStatus !== OnboardingDataStatusEnum.NOT_LOADED
                    || currentUser.onboardingStatus === OnboardingStatusEnum.IN_PROGRESS;

                if (isOnboarding) {
                    return this.tourService.sendStatusFinished()
                        .pipe(
                            map(() => sendOnboardingStatusFinishedSuccessAction()),
                            catchError((httpErrorResponse: HttpErrorResponse) => {
                                const backendErrors: BackendErrorsInterface = {
                                    message: httpErrorResponse.error.message,
                                    errors: httpErrorResponse.error.errors,
                                };

                                return of(sendOnboardingStatusFinishedFailedAction({ backendErrors }));
                            })
                        );
                } else {
                    return EMPTY;
                }
            })
        );
    });

    sendStatusFinishedSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(sendOnboardingStatusFinishedSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('ACTIVITY.DEMO.CHANGE_STATUS.FINISH.SUCCESS.TITLE'),
                );
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            }),
            map(() => getCurrentUserAction())
        );
    });

    sendStatusFinishedFailed$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(sendOnboardingStatusFinishedFailedAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('ACTIVITY.DEMO.CHANGE_STATUS.FINISH.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    loadCompanyOnboardingData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadCompanyOnboardingDataAction),
            switchMap(() => {
                return this.tourService.loadCompanyOnboardingData()
                    .pipe(
                        map(() => loadCompanyOnboardingDataSuccessAction()),
                        catchError((httpErrorResponse: HttpErrorResponse) => {
                            const backendErrors: BackendErrorsInterface = {
                                message: httpErrorResponse.error.message,
                                errors: httpErrorResponse.error.errors,
                            };

                            return of(loadCompanyOnboardingDataFailedAction({ backendErrors }));
                        })
                    );
            })
        );
    });

    loadCompanyOnboardingDataFailed$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadCompanyOnboardingDataFailedAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('ACTIVITY.DEMO.ONBOARDING_DATA.LOAD.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    deleteCompanyOnboardingData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteCompanyOnboardingDataAction),
            tap(() => this.toastr.success(
                this.translate.instant('ACTIVITY.DEMO.ONBOARDING_DATA.DELETE.START.TITLE')),
            ),
            switchMap(() => {
                return this.tourService.deleteCompanyOnboardingData()
                    .pipe(
                        map(() => deleteCompanyOnboardingDataSuccessAction()),
                        catchError((httpErrorResponse: HttpErrorResponse) => {
                            const backendErrors: BackendErrorsInterface = {
                                message: httpErrorResponse.error.message,
                                errors: httpErrorResponse.error.errors,
                            };

                            return of(deleteCompanyOnboardingDataFailedAction({ backendErrors }));
                        })
                    );
            })
        );
    });

    deleteCompanyOnboardingDataSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteCompanyOnboardingDataSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('ACTIVITY.DEMO.ONBOARDING_DATA.DELETE.SUCCESS.TITLE'),
                );
            }),
            switchMap(() => [
                checkCompanyOnboardingDataAction(),
                getTimelineDataAction(),
                getMonthlyStatisticsAction(),
            ])
        );
    });

    deleteCompanyOnboardingDataFailed$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteCompanyOnboardingDataFailedAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('ACTIVITY.DEMO.ONBOARDING_DATA.DELETE.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    checkCompanyOnboardingData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(checkCompanyOnboardingDataAction),
            withLatestFrom(this.store$.select(currentUserIsAdminSelector)),
            switchMap(([_, isAdmin]) => {
                if (isAdmin) {
                    return this.tourService.checkCompanyOnboardingData()
                        .pipe(
                            switchMap((res) => {
                                switch (res.body.status) {
                                    case 'pending':
                                        return of(OnboardingDataStatusEnum.LOADING);
                                    case 'loaded':
                                        return of(OnboardingDataStatusEnum.LOADED);
                                    case 'error':
                                        return of(OnboardingDataStatusEnum.ERROR);
                                    default:
                                        return of(OnboardingDataStatusEnum.NOT_LOADED);
                                }
                            }),
                            switchMap((status) => {
                                if (status !== OnboardingDataStatusEnum.ERROR) {
                                    return of(checkCompanyOnboardingDataSuccessAction({ status }));
                                } else {
                                    const backendErrors: BackendErrorsInterface = {
                                        message: this.translate.instant('ACTIVITY.DEMO.ONBOARDING_DATA.CHECK.ERROR.SOMETHING_WENT_WRONG'),
                                        errors: null,
                                    };

                                    return of(checkCompanyOnboardingDataFailedAction({ backendErrors }));
                                }
                            }),
                            catchError((httpErrorResponse: HttpErrorResponse) => {
                                const backendErrors: BackendErrorsInterface = {
                                    message: httpErrorResponse.error.message,
                                    errors: httpErrorResponse.error.errors,
                                };

                                return of(checkCompanyOnboardingDataFailedAction({ backendErrors }));
                            })
                        );
                } else {
                    return of(checkCompanyOnboardingDataSuccessAction({ status: OnboardingDataStatusEnum.NOT_LOADED }));
                }
            })
        );
    });

    checkCompanyOnboardingDataSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(checkCompanyOnboardingDataSuccessAction),
            withLatestFrom(
                this.store$.select(currentCompanySelector),
                this.store$.select(sharedDataFiltersDaySelector),
                this.store$.select(currentUserSelector),
            ),
            tap(([action, currentCompany, currentDay, currentUser]) => {
                const isDataLoaded = action.status === OnboardingDataStatusEnum.LOADED;
                const isUserOnboarding = currentUser?.onboardingStatus === OnboardingStatusEnum.IN_PROGRESS;

                if (isDataLoaded && isUserOnboarding) {
                    const currentDate = (currentDay ? moment(currentDay) : moment()).startOf('day');
                    const demoDate = moment(currentCompany.createdAt).startOf('day').subtract(3, 'days');

                    this.tourService.unsubscribeOnboardingDataCheckTimer();

                    if (!currentDate.isSame(demoDate)) {
                        this.store$.dispatch(setSharedDataFiltersDayAction({ day: demoDate.toISOString() }));
                    }
                }
            }),
            switchMap(([action]) => action.status === OnboardingDataStatusEnum.LOADED
                ? [ getTimelineDataAction(), getMonthlyStatisticsAction() ]
                : EMPTY
            ),
        );
    });

    checkCompanyOnboardingDataFailed$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(checkCompanyOnboardingDataFailedAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('ACTIVITY.DEMO.ONBOARDING_DATA.CHECK.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    logout$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(logoutAction),
            tap(() => setTimeout(() => this.tourService.end())),
        );
    }, { dispatch: false });

    openWelcomeDialog$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(openWelcomeDialogAction),
            tap((action) => this.matDialog.open(WelcomeDemoDialogComponent, {
                panelClass: 'no-padding-dialog-container',
                data: action.data || {} as WelcomeDemoDialogData,
            })),
        );
    }, { dispatch: false });

    openRestartDialog$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(openRestartDialogAction),
            tap(() => this.matDialog.open(RestartDemoDialogComponent, {
                panelClass: 'no-padding-dialog-container',
            })),
        );
    }, { dispatch: false });

}
