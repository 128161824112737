import { createReducer, on } from '@ngrx/store';

import {
    createUserAction,
    createUserFailureAction,
    createUserSuccessAction,
    deleteUserAction,
    deleteUserFailureAction,
    deleteUserSuccessAction,
    getEmployeesAction,
    getEmployeesFailureAction,
    getEmployeesSuccessAction,
    getUserDetailsAction,
    getUserDetailsFailureAction,
    getUserDetailsSuccessAction,
    getUsersAction,
    getUsersFailureAction,
    getUsersSuccessAction,
    setUsersQueryParamsAction,
    updateUserAction, updateUserFailureAction,
    updateUserSuccessAction,
    usersActivateAction,
    usersActivateFailureAction,
    usersActivateSuccessAction,
    usersDeactivateAction,
    usersDeactivateFailureAction,
    usersDeactivateSuccessAction,
    usersGetTotalCountAction,
    usersGetTotalCountFailureAction,
    usersGetTotalCountSuccessAction,
} from '@app-client/store/users/actions/users.action';
import { UsersStateInterface } from '@app-client/store/users/users-state.interface';


const initialState: UsersStateInterface = {
    users: [],
    isListLoading: false,
    hasLoadingMore: true,
    queryConfig: null,
    isCreateInProgress: false,
    isUpdateInProgress: false,
    isDeleteInProgress: false,
    backendErrors: null,
    isUserDetailsLoading: false,
    userDetails: null,
    isTotalCountLoading: false,
    totalCount: 0,

    isDeactivateInProgress: false,
    isNeedForceDeactivate: false,

    isActivateInProgress: false,

    employees: [],
    isEmployeesLoading: false,
};

const reducer = createReducer(
    initialState,
    on(
        getUsersAction,
        state => ({
            ...state,
            isListLoading: true,
            users: state.queryConfig.page === 1 ? [] : state.users,
            hasLoadingMore: true,
        }),
    ),
    on(
        getUsersSuccessAction,
        (state, action) => ({
            ...state,
            users: [...state.users, ...action.pageResponse.items],
            isListLoading: false,
            hasLoadingMore: action.pageResponse.page < action.pageResponse.totalPages,
        }),
    ),
    on(
        getUsersFailureAction,
        (state, action) => ({
            ...state,
            isListLoading: false,
            hasLoadingMore: false,
        }),
    ),

    on(
        setUsersQueryParamsAction,
        (state, action) => ({
            ...state,
            queryConfig: action.queryParams,
        }),
    ),

    on(
        createUserAction,
        state => ({
            ...state,
            isCreateInProgress: true,
            backendErrors: null,
        }),
    ),
    on(
        createUserSuccessAction,
        state => ({
            ...state,
            isCreateInProgress: false,
        }),
    ),
    on(
        createUserFailureAction,
        (state, action) => ({
            ...state,
            isCreateInProgress: false,
            backendErrors: action.backendErrors,
        }),
    ),

    on(
        updateUserAction,
        state => ({
            ...state,
            isUpdateInProgress: true,
            backendErrors: null,
        }),
    ),
    on(
        updateUserSuccessAction,
        state => ({
            ...state,
            isUpdateInProgress: false,
        }),
    ),
    on(
        updateUserFailureAction,
        (state, action) => ({
            ...state,
            isUpdateInProgress: false,
            backendErrors: action.backendErrors,
        }),
    ),

    on(
        deleteUserAction,
        state => ({
            ...state,
            isDeleteInProgress: true,
        }),
    ),
    on(
        deleteUserSuccessAction,
        state => ({
            ...state,
            isDeleteInProgress: false,
        }),
    ),
    on(
        deleteUserFailureAction,
        state => ({
            ...state,
            isDeleteInProgress: false,
        }),
    ),

    on(
        getUserDetailsAction,
        (state, action) => ({
            ...state,
            userDetails: null,
            isUserDetailsLoading: true,
        }),
    ),
    on(
        getUserDetailsSuccessAction,
        (state, action) => ({
            ...state,
            isUserDetailsLoading: false,
            userDetails: action.userData,
        }),
    ),
    on(
        getUserDetailsFailureAction,
        state => ({
            ...state,
            isUserDetailsLoading: false,
        })
    ),
    on(
        usersGetTotalCountAction,
        (state) => ({
            ...state,
            isTotalCountLoading: true,
        }),
    ),
    on(
        usersGetTotalCountSuccessAction,
        (state, action) => ({
            ...state,
            isTotalCountLoading: false,
            totalCount: action.count,
        }),
    ),
    on(
        usersGetTotalCountFailureAction,
        (state) => ({
            ...state,
            isTotalCountLoading: false,
        }),
    ),

    on(
        usersDeactivateAction,
        (state) => ({
            ...state,
            isDeactivateInProgress: true,
        }),
    ),
    on(
        usersDeactivateSuccessAction,
        (state, action) => {
            const users = state.users.filter((user) => {
                return action.id !== user.id;
            });

            return {
                ...state,
                users,
                isDeactivateInProgress: false,
                isNeedForceDeactivate: false,
            };
        },
    ),
    on (
        usersDeactivateFailureAction,
        (state, action) => ({
            ...state,
            isDeactivateInProgress: false,
            isNeedForceDeactivate: action.isNeedForce,
        })
    ),


    on(
        usersActivateAction,
        (state) => ({
            ...state,
            isActivateInProgress: true,
            isNeedForceDeactivate: false,
        }),
    ),
    on(
        usersActivateSuccessAction,
        (state, action) => {
            const users = state.users.filter((user) => {
                return action.id !== user.id;
            });

            return {
                ...state,
                users,
                isActivateInProgress: false,
            };
        },
    ),
    on (
        usersActivateFailureAction,
        (state) => ({
            ...state,
            isActivateInProgress: false,
        })
    ),

    on(
        getEmployeesAction,
        state => ({
            ...state,
            isEmployeesLoading: true,
        }),
    ),
    on(
        getEmployeesSuccessAction,
        (state, action) => ({
            ...state,
            employees: action.employees,
            isEmployeesLoading: false,
        }),
    ),
    on(
        getEmployeesFailureAction,
        (state, action) => ({
            ...state,
            isEmployeesLoading: false,
        }),
    ),
);

export const usersReducer = (state, action) => reducer(state, action);
