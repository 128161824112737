import { createReducer, on } from '@ngrx/store';

import {
    invoicingContractorsCreateAction,
    invoicingContractorsCreateFailureAction,
    invoicingContractorsCreateSuccessAction,
    invoicingContractorsDeleteAction,
    invoicingContractorsDeleteFailureAction, invoicingContractorsDeleteHeaderFileAction,
    invoicingContractorsDeleteHeaderFileFailureAction,
    invoicingContractorsDeleteHeaderFileSuccessAction,
    invoicingContractorsDeleteSuccessAction,
    invoicingContractorsGetDetailsAction,
    invoicingContractorsGetDetailsFailureAction,
    invoicingContractorsGetDetailsSuccessAction,
    invoicingContractorsGetListAction,
    invoicingContractorsGetListFailureAction,
    invoicingContractorsGetListSuccessAction,
    invoicingContractorsUpdateAction,
    invoicingContractorsUpdateFailureAction,
    invoicingContractorsUpdateSuccessAction,
    invoicingContractorsUploadHeaderFileAction,
    invoicingContractorsUploadHeaderFileFailureAction,
    invoicingContractorsUploadHeaderFileSuccessAction,
} from '@app-client/store/invoicing/contractors/actions/invoicing-contractors.actions';
import { InvoicingContractorsStateInterface } from '@app-client/store/invoicing/contractors/invoicing-contractors-state.interface';


const initialState: InvoicingContractorsStateInterface = {
    list: [],
    isListLoading: false,
    hasMore: false,

    details: null,
    isDetailsLoading: false,

    isCreateInProgress: false,

    isUpdateInProgress: false,

    isDeleteInProgress: false,

    isUploadHeaderFileInProgress: false,
    isDeleteHeaderFileInProgress: false,
};

const reducer = createReducer(
    initialState,
    on(
        invoicingContractorsGetListAction,
        (state, action) => ({
            ...state,
            isListLoading: true,
            list: 1 === action.requestParams.page ? [] : state.list,
        }),
    ),
    on(
        invoicingContractorsGetListSuccessAction,
        (state, action) => ({
            ...state,
            isListLoading: false,
            list: [...state.list, ...action.response.items],
            hasMore: action.response.page < action.response.totalPages,
        }),
    ),
    on(
        invoicingContractorsGetListFailureAction,
        (state) => ({
            ...state,
            isListLoading: false,
            hasMore: false,
        }),
    ),

    on(
        invoicingContractorsGetDetailsAction,
        (state) => ({
            ...state,
            details: null,
            isDetailsLoading: true,
        }),
    ),
    on(
        invoicingContractorsGetDetailsSuccessAction,
        (state, action) => ({
            ...state,
            details: action.response,
            isDetailsLoading: false,
        }),
    ),
    on(
        invoicingContractorsGetDetailsFailureAction,
        (state) => ({
            ...state,
            isDetailsLoading: false,
        }),
    ),

    on(
        invoicingContractorsCreateAction,
        (state) => ({
            ...state,
            isCreateInProgress: true,
        }),
    ),
    on(
        invoicingContractorsCreateSuccessAction,
        invoicingContractorsCreateFailureAction,
        (state) => ({
            ...state,
            isCreateInProgress: false,
        }),
    ),

    on(
        invoicingContractorsUpdateAction,
        (state) => ({
            ...state,
            isUpdateInProgress: true,
        }),
    ),
    on(
        invoicingContractorsUpdateSuccessAction,
        invoicingContractorsUpdateFailureAction,
        (state) => ({
            ...state,
            isUpdateInProgress: false,
        }),
    ),

    on(
        invoicingContractorsDeleteAction,
        (state) => ({
            ...state,
            isDeleteInProgress: true,
        }),
    ),
    on(
        invoicingContractorsDeleteSuccessAction,
        invoicingContractorsDeleteFailureAction,
        (state) => ({
            ...state,
            isDeleteInProgress: false,
        }),
    ),

    on(
        invoicingContractorsUploadHeaderFileAction,
        (state) => ({
            ...state,
            isUploadHeaderFileInProgress: true,
        }),
    ),
    on(
        invoicingContractorsUploadHeaderFileSuccessAction,
        invoicingContractorsUploadHeaderFileFailureAction,
        (state) => ({
            ...state,
            isUploadHeaderFileInProgress: false,
        }),
    ),

    on(
        invoicingContractorsDeleteHeaderFileAction,
        (state) => ({
            ...state,
            isUploadHeaderFileInProgress: true,
        }),
    ),
    on(
        invoicingContractorsDeleteHeaderFileSuccessAction,
        invoicingContractorsDeleteHeaderFileFailureAction,
        (state) => ({
            ...state,
            isUploadHeaderFileInProgress: false,
        }),
    ),
);

export const invoicingContractorsReducer = (state, action) => reducer(state, action);
