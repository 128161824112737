import { createAction, props } from '@ngrx/store';

import { YearlyReportInterface } from '@app-client/store/marketplace/modules/types/yearly-report.interface';
import { YearlyReportActionsEnum } from '@app-client/store/marketplace/modules/yearly-report-actions.enum';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';
import { PageResponseInterface } from '@app-shared/types/page-response.interface';

export const yearlyReportGetListAction = createAction(
    YearlyReportActionsEnum.GET_LIST,
);

export const yearlyReportGetListSuccessAction = createAction(
    YearlyReportActionsEnum.GET_LIST_SUCCESS,
    props<{ pageResponse: PageResponseInterface<YearlyReportInterface> }>(),
);

export const yearlyReportGetListFailureAction = createAction(
    YearlyReportActionsEnum.GET_LIST_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
