import { createAction, props } from '@ngrx/store';

import { ReportAdditionalFiltersInterface } from '@app-client/report/by-projects/types/report-additional-filters.interface';
import { ReportFiltersFormInterface } from '@app-client/report/by-projects/types/report-filters-form.interface';
import { ReportFiltersInterface } from '@app-client/report/by-projects/types/report-filters.interface';
import { ReportInterface } from '@app-client/report/by-projects/types/report.interface';
import { ReportActionTypes } from '@app-client/store/report/by-project/action-types';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';
import { ProcessCommand } from '@app-client/report/by-projects/components/report-filters/commands';


export const getReportAction = createAction(
    ReportActionTypes.GET_REPORT,
    props<{ filters: ReportFiltersInterface }>(),
);
export const getReportSuccessAction = createAction(
    ReportActionTypes.GET_REPORT_SUCCESS,
    props<{ report: ReportInterface }>(),
);
export const getReportFailureAction = createAction(
    ReportActionTypes.GET_REPORT_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const requestGoogleSpreadsheetReportAction = createAction(
    ReportActionTypes.REQUEST_GOOGLE_SPREADSHEET_REPORT,
    props<{ filters: ReportFiltersInterface, isLightStyles: boolean, processPipe: Array<ProcessCommand> }>(),
);
export const requestGoogleSpreadsheetReportSuccessAction = createAction(
    ReportActionTypes.REQUEST_GOOGLE_SPREADSHEET_REPORT_SUCCESS,
);
export const requestGoogleSpreadsheetReportFailureAction = createAction(
    ReportActionTypes.REQUEST_GOOGLE_SPREADSHEET_REPORT_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const saveReportFiltersAction = createAction(
    ReportActionTypes.SAVE_REPORT_FILTERS,
    props<{ filters: ReportFiltersFormInterface }>(),
);

export const clearReportDataAction = createAction(
    ReportActionTypes.CLEAR_REPORT_DATA,
);

export const setReportAdditionalFiltersAction = createAction(
    ReportActionTypes.SET_ADDITIONAL_FILTERS,
    props<{ additionalFilters: ReportAdditionalFiltersInterface | null }>(),
);
