import { createReducer, on } from '@ngrx/store';

import {
    archiveProjectAction,
    archiveProjectFailureAction,
    archiveProjectSuccessAction,
    createProjectAction,
    createProjectFailureAction,
    createProjectSuccessAction,
    getProjectAction,
    getProjectFailureAction,
    getProjectListAction,
    getProjectListFailureAction,
    getProjectListForEditTimeAction,
    getProjectListForEditTimeFailureAction,
    getProjectListForEditTimeSuccessAction,
    getProjectListSuccessAction,
    getProjectSuccessAction,
    restoreProjectAction,
    restoreProjectFailureAction,
    restoreProjectSuccessAction,
    setProjectListForEditTimeQueryParamsAction,
    setProjectListQueryParamsAction,
    updateProjectAction,
    updateProjectFailureAction,
    updateProjectSuccessAction,
} from '@app-client/store/projects/actions/projects.actions';
import { ProjectsStateInterface } from '@app-client/store/projects/projects-state.interface';


const initialState: ProjectsStateInterface = {
    listQueryParams: null,
    projectList: [],
    isProjectListLoading: false,
    hasMoreProjects: false,
    listQueryParamsForEditTime: null,
    projectListForEditTime: [],
    isProjectListForEditTimeLoading: false,
    hasMoreProjectsForEditTime: false,
    isProjectDetailsLoading: false,
    isUpdateProjectInProgress: false,
    updateProjectBackendErrors: null,
    isCreateProjectInProgress: false,
    createProjectBackendErrors: null,
    isArchiveProjectInProgress: false,
    isRestoreProjectInProgress: false,
    projectDetails: null,
};

const reducer = createReducer(
    initialState,
    on(
        setProjectListQueryParamsAction,
        (state, action) => ({
            ...state,
            listQueryParams: action.params,
        }),
    ),

    on(
        getProjectListAction,
        (state) => ({
            ...state,
            isProjectListLoading: true,
            projectList: state.listQueryParams.page === 1 ? [] : state.projectList,
        }),
    ),

    on(
        getProjectListSuccessAction,
        (state, action) => ({
            ...state,
            isProjectListLoading: false,
            projectList: [...state.projectList, ...action.response.items],
            hasMoreProjects: action.response.page < action.response.totalPages,
        }),
    ),

    on(
        getProjectListFailureAction,
        (state) => ({
            ...state,
            isProjectListLoading: false,
            hasMoreProjects: false,
        }),
    ),

    on(
        setProjectListForEditTimeQueryParamsAction,
        (state, action) => ({
            ...state,
            listQueryParamsForEditTime: action.params,
        }),
    ),

    on(
        getProjectListForEditTimeAction,
        (state) => ({
            ...state,
            isProjectListForEditTimeLoading: true,
            projectListForEditTime: state.listQueryParamsForEditTime.page === 1 ? [] : state.projectListForEditTime,
        }),
    ),

    on(
        getProjectListForEditTimeSuccessAction,
        (state, action) => ({
            ...state,
            isProjectListForEditTimeLoading: false,
            projectListForEditTime: action.response.page === 1
                ? action.response.items
                : [...state.projectListForEditTime, ...action.response.items],
            hasMoreProjectsForEditTime: action.response.page < action.response.totalPages,
        }),
    ),

    on(
        getProjectListForEditTimeFailureAction,
        (state) => ({
            ...state,
            isProjectListForEditTimeLoading: false,
            hasMoreProjectsForEditTime: false,
        }),
    ),

    on(
        getProjectAction,
        (state) => ({
            ...state,
            isProjectDetailsLoading: true,
            projectDetails: null,
        }),
    ),

    on(
        getProjectSuccessAction,
        (state, action) => ({
            ...state,
            isProjectDetailsLoading: false,
            projectDetails: action.response.body,
        }),
    ),

    on(
        getProjectFailureAction,
        (state) => ({
            ...state,
            isProjectDetailsLoading: false,
        }),
    ),

    on(
        updateProjectAction,
        (state) => ({
            ...state,
            isUpdateProjectInProgress: true,
        }),
    ),

    on(
        updateProjectSuccessAction,
        (state) => ({
            ...state,
            isUpdateProjectInProgress: false,
        }),
    ),

    on(
        updateProjectFailureAction,
        (state, action) => ({
            ...state,
            isUpdateProjectInProgress: false,
            updateProjectBackendErrors: action.backendErrors,
        }),
    ),

    on(
        createProjectAction,
        (state) => ({
            ...state,
            isCreateProjectInProgress: true,
        }),
    ),

    on(
        createProjectSuccessAction,
        (state) => ({
            ...state,
            isCreateProjectInProgress: false,
        }),
    ),

    on(
        createProjectFailureAction,
        (state, action) => ({
            ...state,
            isCreateProjectInProgress: false,
            createProjectBackendErrors: action.backendErrors,
        }),
    ),

    on(
        archiveProjectAction,
        (state) => ({
            ...state,
            isArchiveProjectInProgress: true,
        }),
    ),

    on(
        archiveProjectSuccessAction,
        (state) => ({
            ...state,
            isArchiveProjectInProgress: false,
        }),
    ),

    on(
        archiveProjectFailureAction,
        (state) => ({
            ...state,
            isArchiveProjectInProgress: false,
        }),
    ),

    on(
        restoreProjectAction,
        (state) => ({
            ...state,
            isRestoreProjectInProgress: true,
        }),
    ),

    on(
        restoreProjectSuccessAction,
        (state) => ({
            ...state,
            isRestoreProjectInProgress: false,
        }),
    ),

    on(
        restoreProjectFailureAction,
        (state) => ({
            ...state,
            isRestoreProjectInProgress: false,
        }),
    ),
);

export const projectsReducer = (state, action) => reducer(state, action);
