import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { skipWhile, switchMap, take } from 'rxjs/operators';

import { ProjectRoleEnum } from '@app-client/project/types/project-role.enum';
import { UserRoleEnum } from '@app-client/shared/types/user-role.enum';
import { currentUserSelector, isCurrentUserLoadingSelector } from '@app-client/store/auth/selectors/auth.selectors';


@Injectable()
export class HappinessModuleGuardService implements CanActivate {
    constructor(
        private store: Store,
        private router: Router,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return combineLatest([
            this.store.select(currentUserSelector),
            this.store.select(isCurrentUserLoadingSelector),
        ]).pipe(
            skipWhile(([, isUserLoading]) => isUserLoading || isUserLoading === null),
            take(1),
            switchMap(([user]) => {
                const forbid = () => {
                    this.router.navigate(['/']);

                    return of(false);
                };

                const allow = () => {
                    return of(true);
                };

                if (!user) {
                    return forbid();
                }

                if (
                    user.role === UserRoleEnum.ROLE_ADMIN
                    || user.role === UserRoleEnum.ROLE_HEAD
                ) {
                    return allow();
                }

                if (user.projectRoles.some(projectRole => projectRole === ProjectRoleEnum.ROLE_MANAGER)) {
                    return allow();
                }

                return forbid();
            }),
        );
    }
}
