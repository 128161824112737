import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserTimeFormatEnum } from '@app-client/shared/types/user-time-format.enum';
import { currentUserSelector, isUseTimeslotsSelector } from '@app-client/store/auth/selectors/auth.selectors';


export interface SecondsToTimeStringPipeOptionsInterface {
    hideSeconds?: boolean;
}

const defaultOptions: SecondsToTimeStringPipeOptionsInterface = {
    hideSeconds: false,
};

@Pipe({
    name: 'secondsToTimeString',
})
export class SecondsToTimeStringPipe implements PipeTransform {
    constructor(
        private store: Store,
    ) {}

    transform(value: number, options?: SecondsToTimeStringPipeOptionsInterface): Observable<string> {
        return combineLatest([
            this.store.select(currentUserSelector),
            this.store.select(isUseTimeslotsSelector),
        ]).pipe(
            map(([currentUser, isUseTimeslots]) => {
                options = {
                    ...defaultOptions,
                    hideSeconds: isUseTimeslots,
                    ...options,
                };

                value = value % 86400;

                let hours = Math.trunc(value / 3600);
                const minutes = Math.trunc(value / 60) % 60;
                let seconds: number;
                let meridianPostfix: 'am' | 'pm';

                if (currentUser.timeFormat === UserTimeFormatEnum.TIME_FORMAT_12) {
                    meridianPostfix = (hours >= 12 && hours < 24) ? 'pm' : 'am';
                    hours = hours % 12;

                    if (hours === 0) {
                        hours = 12;
                    }
                }

                if (options.hideSeconds === false) {
                    seconds = value % 60;
                }

                let result = this.normalizeNumber(hours) + ':' + this.normalizeNumber(minutes);
                if (seconds !== undefined) {
                    result += ':' + this.normalizeNumber(seconds);
                }

                if (meridianPostfix !== undefined) {
                    result += ' ' + meridianPostfix;
                }

                return result;
            })
        );
    }

    normalizeNumber(value: number): string {
        let result = value.toString();
        if (value < 10) {
            result = '0' + result;
        }

        return result;
    }
}
