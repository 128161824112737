export enum ActivityDataActionTypesEnum {
    GET_DAILY_STATISTICS = '[Activity/Data] Get daily statistics',
    GET_DAILY_STATISTICS_SUCCESS = '[Activity/Data] Get daily statistics success',
    GET_DAILY_STATISTICS_FAILURE = '[Activity/Data] Get daily statistics failure',

    GET_WEEKLY_STATISTICS = '[Activity/Data] Get weekly statistics',
    GET_WEEKLY_STATISTICS_SUCCESS = '[Activity/Data] Get weekly statistics success',
    GET_WEEKLY_STATISTICS_FAILURE = '[Activity/Data] Get weekly statistics failure',

    GET_MONTHLY_STATISTICS = '[Activity/Data] Get monthly statistics',
    GET_MONTHLY_STATISTICS_SUCCESS = '[Activity/Data] Get monthly statistics success',
    GET_MONTHLY_STATISTICS_FAILURE = '[Activity/Data] Get monthly statistics failure',

    GET_PROJECT_TASK_MEMO_DATA = '[Activity/Data] Get project/task/memo data',
    GET_PROJECT_TASK_MEMO_DATA_SUCCESS = '[Activity/Data] Get project/task/memo data success',
    GET_PROJECT_TASK_MEMO_DATA_FAILURE = '[Activity/Data] Get project/task/memo data failure',

    GET_WINDOW_DATA = '[Activity/Data] Get window data',
    GET_WINDOW_DATA_SUCCESS = '[Activity/Data] Get window data success',
    GET_WINDOW_DATA_FAILURE = '[Activity/Data] Get window data failure',

    GET_SCREENSHOT_DATA = '[Activity/Data] Get screenshot data',
    GET_SCREENSHOT_DATA_SUCCESS = '[Activity/Data] Get screenshot data success',
    GET_SCREENSHOT_DATA_FAILURE = '[Activity/Data] Get screenshot data failure',
    CLEAR_SCREENSHOT_DATA = '[Activity/Data] Clear screenshot data',

    GET_TIMELINE_DATA = '[Activity/Data] Get timeline data',
    GET_TIMELINE_DATA_SUCCESS = '[Activity/Data] Get timeline data success',
    GET_TIMELINE_DATA_FAILURE = '[Activity/Data] Get timeline data failure',

    DROP_DAILY_STATISTICS = '[Activity/Data] Drop daily statistics',
}

