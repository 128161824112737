import { createFeatureSelector, createSelector } from '@ngrx/store';

import { TourStateInterface } from '@app-client/store/tour/tour.state.interface';


export const tourFeatureSelector = createFeatureSelector<TourStateInterface>('tour');

export const currentSectionSelector = createSelector(
    tourFeatureSelector,
    (state) => state.currentSection,
);

export const currentStepSelector = createSelector(
    tourFeatureSelector,
    (state) => state.currentStep,
);

export const isTourInProgressSelector = createSelector(
    tourFeatureSelector,
    (state) => state.isInProgress,
);

export const isTourCompletedSelector = createSelector(
    tourFeatureSelector,
    (state) => state.isTutorialCompleted,
);

export const isOnboardingStatusLoadingSelector = createSelector(
    tourFeatureSelector,
    (state) => state.isOnboardingStatusLoading,
);

export const onboardingStatusSelector = createSelector(
    tourFeatureSelector,
    (state) => state.onboardingDataStatus,
);

export const isOnboardingDataDeletingSelector = createSelector(
    tourFeatureSelector,
    (state) => state.isOnboardingDataDeleting,
);

export const isDailySandboxDisabledSelector = createSelector(
    tourFeatureSelector,
    (state) => state.isDailyResultSandboxDisabled,
);