import { Action, createAction, props } from '@ngrx/store';

import { InvoicingContractorsActionTypesEnum } from '@app-client/store/invoicing/contractors/invoicing-contractors-action-types.enum';
import { InvoicingContractorsCreateInterface } from '@app-client/store/invoicing/contractors/types/invoicing-contractors-create.interface';
import { InvoicingContractorsDetailsResponseInterface } from '@app-client/store/invoicing/contractors/types/invoicing-contractors-details-response.interface';
import { InvoicingContractorsListItemResponseInterface } from '@app-client/store/invoicing/contractors/types/invoicing-contractors-list-item-response.interface';
import { InvoicingContractorsListRequestParamsInterface } from '@app-client/store/invoicing/contractors/types/invoicing-contractors-list-request-params.interface';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';
import { PageResponseInterface } from '@app-shared/types/page-response.interface';


export const invoicingContractorsGetListAction = createAction(
    InvoicingContractorsActionTypesEnum.GET_LIST,
    props<{ requestParams: InvoicingContractorsListRequestParamsInterface }>(),
);
export const invoicingContractorsGetListSuccessAction = createAction(
    InvoicingContractorsActionTypesEnum.GET_LIST_SUCCESS,
    props<{ response: PageResponseInterface<InvoicingContractorsListItemResponseInterface> }>(),
);
export const invoicingContractorsGetListFailureAction = createAction(
    InvoicingContractorsActionTypesEnum.GET_LIST_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const invoicingContractorsGetDetailsAction = createAction(
    InvoicingContractorsActionTypesEnum.GET_DETAILS,
    props<{ id: string }>(),
);
export const invoicingContractorsGetDetailsSuccessAction = createAction(
    InvoicingContractorsActionTypesEnum.GET_DETAILS_SUCCESS,
    props<{ response: InvoicingContractorsDetailsResponseInterface }>(),
);
export const invoicingContractorsGetDetailsFailureAction = createAction(
    InvoicingContractorsActionTypesEnum.GET_DETAILS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const invoicingContractorsCreateAction = createAction(
    InvoicingContractorsActionTypesEnum.CREATE,
    props<{ data: InvoicingContractorsCreateInterface, base64?: string }>(),
);
export const invoicingContractorsCreateSuccessAction = createAction(
    InvoicingContractorsActionTypesEnum.CREATE_SUCCESS,
    props<{ id: string }>(),
);
export const invoicingContractorsCreateFailureAction = createAction(
    InvoicingContractorsActionTypesEnum.CREATE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const invoicingContractorsUpdateAction = createAction(
    InvoicingContractorsActionTypesEnum.UPDATE,
    props<{ id: string, data: InvoicingContractorsCreateInterface, base64?: string | null }>(),
);
export const invoicingContractorsUpdateSuccessAction = createAction(
    InvoicingContractorsActionTypesEnum.UPDATE_SUCCESS,
    props<{ id: string }>(),
);
export const invoicingContractorsUpdateFailureAction = createAction(
    InvoicingContractorsActionTypesEnum.UPDATE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const invoicingContractorsDeleteAction = createAction(
    InvoicingContractorsActionTypesEnum.DELETE,
    props<{ id: string }>(),
);
export const invoicingContractorsDeleteSuccessAction = createAction(
    InvoicingContractorsActionTypesEnum.DELETE_SUCCESS,
);
export const invoicingContractorsDeleteFailureAction = createAction(
    InvoicingContractorsActionTypesEnum.DELETE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const invoicingContractorsUploadHeaderFileAction = createAction(
    InvoicingContractorsActionTypesEnum.UPLOAD_HEADER_FILE,
    props<{ id: string, base64: string, dispatchAfter: Action }>(),
);
export const invoicingContractorsUploadHeaderFileSuccessAction = createAction(
    InvoicingContractorsActionTypesEnum.UPLOAD_HEADER_FILE_SUCCESS,
);
export const invoicingContractorsUploadHeaderFileFailureAction = createAction(
    InvoicingContractorsActionTypesEnum.UPLOAD_HEADER_FILE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const invoicingContractorsDeleteHeaderFileAction = createAction(
    InvoicingContractorsActionTypesEnum.DELETE_HEADER_FILE,
    props<{ id: string, dispatchAfter: Action }>(),
);
export const invoicingContractorsDeleteHeaderFileSuccessAction = createAction(
    InvoicingContractorsActionTypesEnum.DELETE_HEADER_FILE_SUCCESS,
);
export const invoicingContractorsDeleteHeaderFileFailureAction = createAction(
    InvoicingContractorsActionTypesEnum.DELETE_HEADER_FILE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
