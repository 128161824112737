export enum InvoicingContractorsActionTypesEnum {
    GET_LIST = '[Invoicing/Contractors] Get list',
    GET_LIST_SUCCESS = '[Invoicing/Contractors] Get list success',
    GET_LIST_FAILURE = '[Invoicing/Contractors] Get list failure',

    GET_DETAILS = '[Invoicing/Contractors] Get details',
    GET_DETAILS_SUCCESS = '[Invoicing/Contractors] Get details success',
    GET_DETAILS_FAILURE = '[Invoicing/Contractors] Get details failure',

    CREATE = '[Invoicing/Contractors] Create',
    CREATE_SUCCESS = '[Invoicing/Contractors] Create success',
    CREATE_FAILURE = '[Invoicing/Contractors] Create failure',

    UPDATE = '[Invoicing/Contractors] Update',
    UPDATE_SUCCESS = '[Invoicing/Contractors] Update success',
    UPDATE_FAILURE = '[Invoicing/Contractors] Update failure',

    DELETE = '[Invoicing/Contractors] Delete',
    DELETE_SUCCESS = '[Invoicing/Contractors] Delete success',
    DELETE_FAILURE = '[Invoicing/Contractors] Delete failure',

    UPLOAD_HEADER_FILE = '[Invoicing/Contractors] Upload header file',
    UPLOAD_HEADER_FILE_SUCCESS = '[Invoicing/Contractors] Upload header file success',
    UPLOAD_HEADER_FILE_FAILURE = '[Invoicing/Contractors] Upload header file failure',

    DELETE_HEADER_FILE = '[Invoicing/Contractors] Delete header file',
    DELETE_HEADER_FILE_SUCCESS = '[Invoicing/Contractors] Delete header file success',
    DELETE_HEADER_FILE_FAILURE = '[Invoicing/Contractors] Delete header file failure',
}
