import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import {
    batchUpdateAppSubcategoryAction,
    batchUpdateAppSubcategoryFailureAction,
    batchUpdateAppSubcategorySuccessAction,
    createEffectivityCategoryAction,
    createEffectivityCategoryFailureAction,
    createEffectivityCategorySuccessAction,
    createEffectivitySubcategoryAction,
    createEffectivitySubcategoryFailureAction,
    createEffectivitySubcategorySuccessAction,
    getEffectivityAppListAction,
    getEffectivityAppListFailureAction,
    getEffectivityAppListSuccessAction,
    getEffectivityCategoryListAction,
    getEffectivityCategoryListFailureAction,
    getEffectivityCategoryListSuccessAction,
    removeEffectivityCategoryAction,
    removeEffectivityCategoryFailureAction,
    removeEffectivityCategorySuccessAction,
    removeEffectivitySubcategoryAction,
    removeEffectivitySubcategoryFailureAction,
    removeEffectivitySubcategorySuccessAction,
    updateEffectivityCategoryAction,
    updateEffectivityCategoryFailureAction,
    updateEffectivityCategorySuccessAction,
    updateEffectivitySubcategoryAction,
    updateEffectivitySubcategoryFailureAction,
    updateEffectivitySubcategorySuccessAction,
} from '@app-client/store/effectivity-categories/actions/effectivity-category.actions';
import {
    effectivityAppListQueryParamsSelector,
} from '@app-client/store/effectivity-categories/selectors/effectivity-category.selectors';
import { EffectivityCategoryService } from '@app-client/store/effectivity-categories/services/effectivity-category.service';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class EffectivityCategoryEffects {
    constructor(
        private actions$: Actions,
        private effectivityService: EffectivityCategoryService,
        private toastr: ToastrService,
        private translate: TranslateService,
        private store$: Store,
        private dialogRef: MatDialog,
    ) {}

    getEffectivityCategoryList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getEffectivityCategoryListAction),
            switchMap(() => {
                return this.effectivityService.getEffectivityCategoryList().pipe(
                    map(response => {
                        return getEffectivityCategoryListSuccessAction({ response });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse.error.message,
                            errors: httpErrorResponse.error.errors,
                        };

                        return of(getEffectivityCategoryListFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getEffectivityCategoryFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getEffectivityCategoryListFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('EFFECTIVITY_CATEGORY.NOTIFICATIONS.GET_LIST.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    createEffectivityCategory$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createEffectivityCategoryAction),
            switchMap(({ newEffectivityCategory }) => {
                return this.effectivityService.createEffectivityCategory(newEffectivityCategory).pipe(
                    map(response => {
                        return createEffectivityCategorySuccessAction({
                            newEffectivityCategory: response.body,
                        });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse.error.message,
                            errors: httpErrorResponse.error.errors,
                        };

                        return of(createEffectivityCategoryFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    createEffectivityCategorySuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createEffectivityCategorySuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('EFFECTIVITY_CATEGORY.NOTIFICATIONS.CREATE.SUCCESS.MESSAGE'),
                    this.translate.instant('EFFECTIVITY_CATEGORY.NOTIFICATIONS.CREATE.SUCCESS.TITLE'),
                );
                this.dialogRef.closeAll();
            }),
        );
    }, { dispatch: false });

    createEffectivityCategoryFailure$ = createEffect(() => {
        return this.actions$.pipe(
           ofType(createEffectivityCategoryFailureAction),
           tap(({ backendErrors }) => {
               this.toastr.error(
                   backendErrors.message,
                   this.translate.instant('EFFECTIVITY_CATEGORY.NOTIFICATIONS.CREATE.ERROR.TITLE'),
               );
           }),
        );
    }, { dispatch: false });

    updateEffectivityCategory$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateEffectivityCategoryAction),
            switchMap(({ id, updatedEffectivityCategory }) => {
                return this.effectivityService.updateEffectivityCategory(id, updatedEffectivityCategory).pipe(
                    map(response => {
                        return updateEffectivityCategorySuccessAction({
                            updatedEffectivityCategory: response.body,
                        });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse.error.message,
                            errors: httpErrorResponse.error.errors,
                        };

                        return of(updateEffectivityCategoryFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    updateEffectivityCategorySuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateEffectivityCategorySuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('EFFECTIVITY_CATEGORY.NOTIFICATIONS.UPDATE.SUCCESS.MESSAGE'),
                    this.translate.instant('EFFECTIVITY_CATEGORY.NOTIFICATIONS.UPDATE.SUCCESS.TITLE'),
                );
                this.dialogRef.closeAll();
            }),
        );
    }, { dispatch: false });

    updateEffectivityCategoryFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateEffectivityCategoryFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('EFFECTIVITY_CATEGORY.NOTIFICATIONS.UPDATE.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    removeEffectivityCategory$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(removeEffectivityCategoryAction),
            switchMap(({ id }) => {
                return this.effectivityService.removeEffectivityCategory(id).pipe(
                    map(response => {
                        return removeEffectivityCategorySuccessAction({
                            removedEffectivityCategoryId: id,
                        });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse.error.message,
                            errors: httpErrorResponse.error.errors,
                        };

                        return of(removeEffectivityCategoryFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    removeEffectivityCategorySuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(removeEffectivityCategorySuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('EFFECTIVITY_CATEGORY.NOTIFICATIONS.REMOVE.SUCCESS.MESSAGE'),
                    this.translate.instant('EFFECTIVITY_CATEGORY.NOTIFICATIONS.REMOVE.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    removeEffectivityCategoryFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(removeEffectivityCategoryFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('EFFECTIVITY_CATEGORY.NOTIFICATIONS.REMOVE.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    createEffectivitySubcategory$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createEffectivitySubcategoryAction),
            switchMap(({ createSubcategory }) => {
                return this.effectivityService.createEffectivitySubcategory(createSubcategory).pipe(
                    map(response => {
                        return createEffectivitySubcategorySuccessAction({
                            categoryId: createSubcategory.category,
                            createdSubcategory: response.body,
                        });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse.error.message,
                            errors: httpErrorResponse.error.errors,
                        };

                        return of(createEffectivitySubcategoryFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    createEffectivitySubcategorySuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createEffectivitySubcategorySuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('EFFECTIVITY_SUBCATEGORY.NOTIFICATIONS.CREATE.SUCCESS.MESSAGE'),
                    this.translate.instant('EFFECTIVITY_SUBCATEGORY.NOTIFICATIONS.CREATE.SUCCESS.TITLE'),
                );
                this.dialogRef.closeAll();
            }),
        );
    }, { dispatch: false });

    createEffectivitySubcategoryFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createEffectivitySubcategoryFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('EFFECTIVITY_SUBCATEGORY.NOTIFICATIONS.CREATE.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    updateEffectivitySubcategory$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateEffectivitySubcategoryAction),
            switchMap(({ updateSubcategoryId, updateSubcategory }) => {
                return this.effectivityService.updateEffectivitySubcategory(updateSubcategoryId, updateSubcategory).pipe(
                    map(response => {
                        return updateEffectivitySubcategorySuccessAction({
                            newCategoryId: updateSubcategory.category,
                            updatedSubcategory: response.body,
                        });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse.error.message,
                            errors: httpErrorResponse.error.errors,
                        };

                        return of(updateEffectivitySubcategoryFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    updateEffectivitySubcategorySuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateEffectivitySubcategorySuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('EFFECTIVITY_SUBCATEGORY.NOTIFICATIONS.UPDATE.SUCCESS.MESSAGE'),
                    this.translate.instant('EFFECTIVITY_SUBCATEGORY.NOTIFICATIONS.UPDATE.SUCCESS.TITLE'),
                );
                this.dialogRef.closeAll();
            }),
        );
    }, { dispatch: false });

    updateEffectivitySubcategoryFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateEffectivitySubcategoryFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('EFFECTIVITY_SUBCATEGORY.NOTIFICATIONS.UPDATE.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    removeEffectivitySubcategory$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(removeEffectivitySubcategoryAction),
            switchMap(({ removeSubcategoryId }) => {
                return this.effectivityService.removeEffectivitySubcategory(removeSubcategoryId).pipe(
                    map(response => {
                        return removeEffectivitySubcategorySuccessAction({
                            removedSubcategoryId: removeSubcategoryId,
                        });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse.error.message,
                            errors: httpErrorResponse.error.errors,
                        };

                        return of(removeEffectivitySubcategoryFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    removeEffectivitySubcategorySuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(removeEffectivitySubcategorySuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('EFFECTIVITY_SUBCATEGORY.NOTIFICATIONS.REMOVE.SUCCESS.MESSAGE'),
                    this.translate.instant('EFFECTIVITY_SUBCATEGORY.NOTIFICATIONS.REMOVE.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    removeEffectivitySubcategoryFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(removeEffectivitySubcategoryFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('EFFECTIVITY_SUBCATEGORY.NOTIFICATIONS.REMOVE.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    getEffectivityAppList = createEffect(() => {
        return this.actions$.pipe(
            ofType(getEffectivityAppListAction),
            withLatestFrom(this.store$.select(effectivityAppListQueryParamsSelector)),
            switchMap(([, queryParams]) => {
                return this.effectivityService.getAppList(queryParams).pipe(
                    map(response => {
                        return getEffectivityAppListSuccessAction({ response });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse.error.message,
                            errors: httpErrorResponse.error.errors,
                        };

                        return of(getEffectivityAppListFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getEffectivityAppListFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getEffectivityAppListFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('EFFECTIVITY_APP.NOTIFICATIONS.GET_LIST.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    batchUpdateAppSubcategory$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(batchUpdateAppSubcategoryAction),
            switchMap(({ apps, subcategoryId }) => {
                return this.effectivityService.batchUpdateSubcategory(subcategoryId, apps).pipe(
                    map(response => {
                        return batchUpdateAppSubcategorySuccessAction({
                            apps,
                            subcategoryId,
                        });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse.error.message,
                            errors: httpErrorResponse.error.errors,
                        };

                        return of(batchUpdateAppSubcategoryFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    batchUpdateAppSubcategorySuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(batchUpdateAppSubcategorySuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('EFFECTIVITY_SUBCATEGORY.NOTIFICATIONS.ADD_APPLICATIONS.SUCCESS.MESSAGE'),
                    this.translate.instant('EFFECTIVITY_SUBCATEGORY.NOTIFICATIONS.ADD_APPLICATIONS.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    batchUpdateAppSubcategoryFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(batchUpdateAppSubcategoryFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('EFFECTIVITY_SUBCATEGORY.NOTIFICATIONS.ADD_APPLICATIONS.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });
}
