import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ResponseInterface } from '@app-shared/types/response.interface';

import { SignatureDocumentType } from './types/signature-document.type';

import { environment } from '@env-client/environment';


@Injectable()
export class SignatureDocumentService {

    constructor(
        private http: HttpClient,
    ) {}

    getDocument(): Observable<SignatureDocumentType> {
        const url = `${environment.apiBaseUrl}/signature/template`;

        return this.http.get<ResponseInterface<SignatureDocumentType>>(url).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }
}
