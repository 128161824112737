import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NewYearService } from './new-year.service';


@UntilDestroy()
@Component({
    selector: 'new-year',
    template: `
        <div class="new-year">
            <ng-container *ngIf="newYearService.isWreathVisible$ | async">
                <div class="wreath"></div>
            </ng-container>
            <ng-container *ngIf="newYearService.isSnowVisible$ | async">
                <div class="snow"></div>
            </ng-container>
        </div>
    `,
    styles: [`
        .new-year {
            pointer-events: none;
            z-index: 10001;
            position: fixed;
        }

        .snow {
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: url("/assets/images/d.gif");
        }

        .wreath {
            position: fixed;
            left: 0;
            right: 0;
            top: calc(72px - 50px);
            height: 100px;
            background-image: url("/assets/images/g.png");
            background-repeat: repeat;
            background-size: contain;
        }

    `],
})
export class NewYearComponent {
    constructor(
        public newYearService: NewYearService,
    ) {}
}
