<div class="sidebar-container">
    <ng-scrollbar class="sidebar-scrollbar">
        <ul class="top">
            <li routerLinkActive="active"
                joyrideStep="view_activity_data"
                stepPosition="right"
                [stepContent]="tourTooltip">
                <a routerLink="/activity">
                    <mat-icon svgIcon="attrack-activity" aria-hidden="false"></mat-icon>
                    {{ 'SIDEBAR.ACTIVITY' | translate }}
                </a>
            </li>
            <li 
                *ngIf="
                    (isEnabledCoreBusinessTariff$ | async) === true
                    && ((isUserAdmin$ | async) === true || (isUserHead$ | async) === true || (isUserObserverOnly$ | async) === false)
                "
                routerLinkActive="active"
                joyrideStep="view_effectivity_data"
                stepPosition="right"
                [stepContent]="tourTooltip">
                <a routerLink="/effectivity">
                    <mat-icon svgIcon="attrack-effectivity" aria-hidden="false"></mat-icon>
                    {{ 'SIDEBAR.EFFECTIVITY' | translate }}
                </a>
            </li>

            <li routerLinkActive="active"
                joyrideStep="create_projects"
                stepPosition="right"
                [stepContent]="tourTooltip">
                <a routerLink="/projects">
                    <mat-icon svgIcon="attrack-projects" aria-hidden="false"></mat-icon>
                    {{ 'SIDEBAR.PROJECTS' | translate }}
                </a>
            </li>

            <li routerLinkActive="active" *ngIf="(isUserAdmin$ | async)"
                joyrideStep="create_project_spaces"
                stepPosition="right"
                [stepContent]="tourTooltip">
                <a routerLink="/project-spaces">
                    <mat-icon svgIcon="attrack-project-spaces" aria-hidden="false"></mat-icon>
                    {{ 'SIDEBAR.PROJECT_SPACES' | translate }}
                </a>
            </li>

            <li routerLinkActive="active"
                joyrideStep="create_reports"
                stepPosition="right"
                [stepContent]="tourTooltip">
                <a routerLink="/report">
                    <mat-icon svgIcon="attrack-reports" aria-hidden="false"></mat-icon>
                    {{ 'SIDEBAR.REPORTS' | translate }}
                </a>
            </li>

            <li
                routerLinkActive="active"
                *ngIf="true === (isEnabledDailyResultFeature$ | async)"
            >
                <a routerLink="/daily-result">
                    <mat-icon 
                        svgIcon="attrack-daily-result"
                        aria-hidden="false"
                    ></mat-icon>
                    {{ 'SIDEBAR.DAILY_RESULT' | translate }}
                </a>
            </li>

            <li routerLinkActive="active" *ngIf="true === (isEnabledInvoicingFeature$ | async) && (true === (isUserAdmin$ | async) || true === (isUserManager$ | async))">
                <a routerLink="/invoicing">
                    <mat-icon svgIcon="attrack-invoicing" aria-hidden="false"></mat-icon>
                    {{ 'SIDEBAR.INVOICING' | translate }}
                </a>
            </li>

            <li routerLinkActive="active" *ngIf="(isUserManager$ | async) || (isUserAdmin$ | async)"
                joyrideStep="process_time_requests"
                stepPosition="right"
                [stepContent]="tourTooltip">
                <a routerLink="/time-requests">
                    <mat-icon svgIcon="attrack-time-requests" aria-hidden="false"></mat-icon>
                    {{ 'SIDEBAR.TIME_REQUESTS' | translate }}
                </a>
            </li>

            <li routerLinkActive="active"
                *ngIf="true === (isEnabledHappinessIndexFeature$ | async)
                        && (true === (isUserAdmin$ | async)
                            || true === (isUserManager$ | async)
                            || true === (isUserHead$ | async)
                        )"
            >
                <a routerLink="/happiness-index">
                    <mat-icon svgIcon="attrack-happiness-index" aria-hidden="false"></mat-icon>
                    {{ 'SIDEBAR.HAPPINESS_INDEX' | translate }}
                </a>
            </li>

            <li routerLinkActive="active" *ngIf="(isUserAdmin$ | async) || (isUserSysadmin$ | async)"
                joyrideStep="create_users"
                stepPosition="right"
                [stepContent]="tourTooltip">
                <a routerLink="/users">
                    <mat-icon svgIcon="attrack-users" aria-hidden="false"></mat-icon>
                    {{ 'SIDEBAR.USERS' | translate }}
                </a>
            </li>

            <li routerLinkActive="active" *ngIf="isUserAdmin$ | async"
                joyrideStep="connect_new_modules"
                [stepPosition]="(isSmallHeightDevice$ | async) === true ? 'top' : 'right'"
                [stepContent]="tourTooltip">
                <a routerLink="/modules">
                    <mat-icon svgIcon="attrack-marketplace" aria-hidden="false"></mat-icon>
                    {{ 'SIDEBAR.MODULES' | translate }}
                </a>
            </li>

            <ng-container *ngIf="(isUserAdmin$ | async)">
                <li
                    routerLinkActive="active"
                    joyrideStep="add_company_info"
                    [stepPosition]="(isSmallHeightDevice$ | async) === true ? 'top' : 'right'"
                    [stepContent]="tourTooltip"
                >
                    <a routerLink="/company">
                        <mat-icon svgIcon="attrack-settings" aria-hidden="false"></mat-icon>
                        {{ 'SIDEBAR.SETTINGS' | translate }}
                    </a>
                </li>
            </ng-container>

            <ng-container *ngIf="(isUserAdmin$ | async)">
                <li routerLinkActive="active">
                    <a routerLink="/payment">
                        <mat-icon svgIcon="attrack-payment" aria-hidden="false"></mat-icon>
                        {{ 'SIDEBAR.PAYMENT' | translate }}
                    </a>
                </li>
            </ng-container>

            <li>
                <a [href]="feedbackUrl$ | async" target="blank">
                    {{ 'SIDEBAR.NOLT_LINK' | translate }}
                </a>
            </li>

            <!-- nolt section -->
            <!-- <li>
                <a href="#" (click)="$event.preventDefault(); nolt.click()">
                    {{ 'SIDEBAR.NOLT_LINK' | translate }}
                </a>
            </li>

            <a
                style="visibility: hidden; height: 0; position: absolute;"
                data-nolt="button" target="_blank"
                href="{{ noltLink }}"
                #nolt
            ></a> -->
        </ul>
    </ng-scrollbar>
</div>

<ng-template #tourTooltip>
    <app-tour-tooltip></app-tour-tooltip>
</ng-template>

