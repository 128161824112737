export enum InvoicingCustomersActionTypesEnum {
    GET_LIST = '[Invoicing/Customers] Get list',
    GET_LIST_SUCCESS = '[Invoicing/Customers] Get list success',
    GET_LIST_FAILURE = '[Invoicing/Customers] Get list failure',

    GET_DETAILS = '[Invoicing/Customers] Get details',
    GET_DETAILS_SUCCESS = '[Invoicing/Customers] Get details success',
    GET_DETAILS_FAILURE = '[Invoicing/Customers] Get details failure',

    CREATE = '[Invoicing/Customers] Create',
    CREATE_SUCCESS = '[Invoicing/Customers] Create success',
    CREATE_FAILURE = '[Invoicing/Customers] Create failure',

    UPDATE = '[Invoicing/Customers] Update',
    UPDATE_SUCCESS = '[Invoicing/Customers] Update success',
    UPDATE_FAILURE = '[Invoicing/Customers] Update failure',

    DELETE = '[Invoicing/Customers] Delete',
    DELETE_SUCCESS = '[Invoicing/Customers] Delete success',
    DELETE_FAILURE = '[Invoicing/Customers] Delete failure',
}
