import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Moment } from 'moment-timezone';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import {
    marketplaceAccountSelector,
} from '@app-client/store/marketplace/account/selectors/marketplace-account.selectors';
import {
    marketplaceModulesGetFreeTariffsAction,
    marketplaceModulesGetListAction,
    marketplaceModulesInstallAction,
    marketplaceModulesUpdatePlanAction,
} from '@app-client/store/marketplace/modules/actions/marketplace-modules.actions';
import {
    marketplaceModulesFreeTariffsSelector,
    marketplaceModulesIsListLoadingSelector,
    marketplaceModulesListSelector,
} from '@app-client/store/marketplace/modules/selectors/marketplace-modules.selectors';


@UntilDestroy()
@Component({
    selector: 'app-plan-selector-modules',
    templateUrl: './plan-selector-modules.component.html',
    styleUrls: ['./plan-selector-modules.component.scss'],
})
export class PlanSelectorModulesComponent implements OnInit {
    isLoading$: Observable<boolean>;
    modules$: Observable<Array<{
        id: string,
        icon: string,
        name: string,
        description: string,
        isSubscription: boolean,
        isPersonal: boolean,
        tariffs: Array<{
            id: string,
            name: string,
            description: string,
            cost: number,
            actualCost: number,
            isInstalled: boolean,
            isFreeOnPlan: boolean,
        }>,
    }>>;
    trialEnd$: Observable<Moment | null>;

    constructor(
        private store: Store,
        private dialogRef: MatDialogRef<any>,
    ) {}

    ngOnInit(): void {
        this.fetchData();
        this.initializeVariables();
    }

    private initializeVariables(): void {
        this.isLoading$ = combineLatest([
            this.store.select(marketplaceModulesIsListLoadingSelector),
            this.store.select(marketplaceModulesFreeTariffsSelector),
        ]).pipe(
            map(([isListLoading, isFreeLoading]) => {
                return isListLoading || isFreeLoading === null;
            }),
        );
        this.trialEnd$ = this.store.select(marketplaceAccountSelector).pipe(
            map((account) => {
                return account.trialEnd === null ? null : moment(account.trialEnd);
            }),
            map((trialEnd) => {
                if (trialEnd === null || trialEnd.isBefore(moment())) {
                    return null;
                }

                return trialEnd;
            }),
        );
        this.modules$ = combineLatest([
            this.store.select(marketplaceModulesListSelector),
            this.store.select(marketplaceModulesFreeTariffsSelector),
        ]).pipe(
            map(([modules, freeTariffs]) => {
                return modules.map((module) => {
                    return {
                        id: module.id,
                        icon: module.icon,
                        name: module.name,
                        description: module.shortDescription,
                        isSubscription: module.isSubscription,
                        isPersonal: module.isMultiple,
                        tariffs: module.tariffs.map((tariff) => {
                            return {
                                id: tariff.id,
                                name: tariff.name,
                                description: tariff.description,
                                cost: +tariff.cost,
                                actualCost: +tariff.actualCost,
                                isInstalled: module.companyModule?.tariff?.id === tariff.id,
                                isFreeOnPlan: !!freeTariffs?.find((freeTariff) => {
                                    return freeTariff === tariff.id;
                                }),
                            };
                        }),
                    };
                });
            }),
        );
    }

    private fetchData(): void {
        this.store.dispatch(marketplaceModulesGetListAction());
        this.store.dispatch(marketplaceModulesGetFreeTariffsAction());
    }

    select(moduleId: string, tariffId: string): void {
        this.modules$.pipe(
            untilDestroyed(this),
            take(1),
        ).subscribe((modules) => {
            const module = modules.find((findModule) => {
                return findModule.id === moduleId;
            });

            const isModuleInstall = module.tariffs.some((someTariff) => {
                return someTariff.isInstalled === true;
            });

            if (isModuleInstall === true) {
                this.store.dispatch(marketplaceModulesUpdatePlanAction({
                    moduleId,
                    tariffId,
                }));
            }

            if (isModuleInstall === false) {
                this.store.dispatch(marketplaceModulesInstallAction({
                    moduleId,
                    tariffId,
                    forAll: (module.tariffs.length > 1) ? true : null,
                }));
            }
        });
    }

    close(): void {
        this.dialogRef.close();
    }
}
