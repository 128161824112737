import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';

import { PersistenceService } from '@app-client/shared/services/persistence.service';
import { AppStateInterface } from '@app-client/shared/types/app-state.interface';
import {
    currentCompanySelector,
    isCompanyLoadingSelector,
    isCurrentUserLoadingSelector,
} from '@app-client/store/auth/selectors/auth.selectors';


@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(
        private router: Router,
        private store: Store<AppStateInterface>,
        private persistenceService: PersistenceService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return combineLatest([
            this.store.select(isCompanyLoadingSelector),
            this.store.select(isCurrentUserLoadingSelector),
        ]).pipe(
            skipWhile(([isCompanyLoading, isUserLoading]) => isCompanyLoading || isCompanyLoading === null || isUserLoading),
            take(1),
            switchMap(() => {
                return this.store.select(currentCompanySelector).pipe(
                    take(1),
                    map(currentCompany => {
                        const isLoggedIn = currentCompany && !!this.persistenceService.get('credentials');

                        if (!isLoggedIn) {
                            this.router.navigate(['/login']);

                            return false;
                        }

                        return true;
                    }),
                );
            }),
        );
    }
}
