import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { UrlService } from '@app-client/shared/services/url.service';
import { AccessRequestOAuthInterface } from '@app-client/store/auth/access-request-oauth.interface';
import { accessRequestSend, clearAccessRequestSend } from '@app-client/store/auth/actions/access-request.action';
import {
    accessRequestBackendErrorsSelector,
    accessRequestSendingSuccessSelector,
    isAccessRequestSendingInProgressSelector,
} from '@app-client/store/auth/selectors/auth.selectors';
import { emailValidator } from '@app-shared/validators/email.validator';


@UntilDestroy()
@Component({
    selector: 'app-access-request',
    templateUrl: './access-request.component.html',
    styleUrls: ['./access-request.component.scss'],
})
export class AccessRequestComponent implements OnInit, OnDestroy {
    isAccessRequestSendInProgress$: Observable<boolean>;
    accessRequestSendSuccess$: Observable<boolean>;

    form: FormGroup;

    queryData: AccessRequestOAuthInterface | null;

    constructor(
        private store: Store,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private urlService: UrlService,
    ) { }

    get firstName() { return this.form.get('firstName'); }

    get lastName() { return this.form.get('lastName'); }

    get email() { return this.form.get('email'); }

    ngOnInit(): void {
        this.initializeValues();
        this.initializeForm();
        this.initializeBackendErrorsWatcher();

        this.tryToFetchQueryParams();
    }

    ngOnDestroy(): void {
        this.store.dispatch(clearAccessRequestSend());
    }

    initializeValues(): void {
        this.isAccessRequestSendInProgress$ = this.store.select(isAccessRequestSendingInProgressSelector);
        this.accessRequestSendSuccess$ = this.store.select(accessRequestSendingSuccessSelector);
    }

    initializeForm(): void {
        this.form = this.fb.group({
            firstName: ['', [
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(255),
            ]],
            lastName: ['', [
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(255),
            ]],
            email: ['', [
                Validators.required,
                Validators.maxLength(255),
                emailValidator,
            ]],
        });

        this.isAccessRequestSendInProgress$.pipe(
            untilDestroyed(this),
        ).subscribe((inProgress) => {
            if (inProgress) {
                this.form.disable({ emitEvent: false });
            } else {
                this.form.enable({ emitEvent: false });
            }
        });
    }

    tryToFetchQueryParams(): void {
        const params = this.route.snapshot.queryParams;

        if (['id', 'email', 'isActive', 'firstName', 'lastName'].every(param => !!params[param]) && params.isActive === '0') {
            this.queryData = {
                id: params.id,
                firstName: params.firstName,
                lastName: params.lastName,
                email: params.email,
            };

            this.form.setValue({
                firstName: params.firstName || '',
                lastName: params.lastName || '',
                email: params.email || '',
            });

            this.email.disable();
        }

        this.urlService.updateCurrentUrlQueryParams({
            id: null,
            firstName: null,
            lastName: null,
            email: null,
            isActive: null,
        });
    }

    submit(): void {
        if (this.form.invalid) {
            return;
        }

        this.store.dispatch(accessRequestSend({
            accessRequestData: {
                firstName: this.firstName.value,
                lastName: this.lastName.value,
                email: this.email.value,
                id: this.queryData?.id,
            },
        }));
    }

    private initializeBackendErrorsWatcher(): void {
        this.store.select(accessRequestBackendErrorsSelector).pipe(
            untilDestroyed(this),
        ).subscribe(backendErrors => {
            if (!backendErrors || !Object.keys(backendErrors).length) {
                return;
            }

            for (const key of Object.keys(backendErrors)) {
                const control = this.form.get(key);

                if (!control) {
                    continue;
                }

                if (backendErrors[key]) {
                    control.setErrors({
                        ...control.errors,
                        backendErrors: backendErrors[key],
                    });
                }

                if (control.errors && !Object.keys(control.errors)) {
                    control.setErrors(null);
                }
            }
        });
    }
}
