import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { CreateProjectTaskInterface } from '@app-client/project/types/create-project-task.interface';
import { ProjectTaskListQueryParamsInterface } from '@app-client/project/types/project-task-list-query-params.interface';
import { ProjectTaskInterface } from '@app-client/project/types/project-task.interface';
import { UpdateProjectTaskInterface } from '@app-client/project/types/update-project-task.interface';
import { PageResponseInterface } from '@app-shared/types/page-response.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';

import { environment } from '@env-client/environment';


@Injectable()
export class ProjectTasksService {
    constructor(private http: HttpClient) {}

    public getProjectTasksList(queryParams: ProjectTaskListQueryParamsInterface)
        : Observable<PageResponseInterface<ProjectTaskInterface>> {
        let params = new HttpParams();
        if ((queryParams.search || '').trim()) {
            params = params.set('search', (queryParams.search || '').trim());
        }
        if (!!queryParams.searchByUserHasTime) {
            params = params.set('searchByUserHasTime', queryParams.searchByUserHasTime);
        }
        params = params.set('page', queryParams.page.toString());
        params = params.set('limit', (queryParams.limit || 10).toString());
        params = params.set('orderBy', queryParams.orderBy);
        params = params.set('orderDirection', queryParams.orderDirection);
        const projects = Array.isArray(queryParams.project) ? queryParams.project : [queryParams.project];
        projects.forEach((project) => {
            params = params.append('project[]', project);
        });
        if (queryParams.searchByUser !== null && queryParams.searchByUser !== undefined) {
            const users = Array.isArray(queryParams.searchByUser) ? queryParams.searchByUser : [queryParams.searchByUser];
            users.forEach((user) => {
                params = params.append('searchByUser[]', user);
            });
        }
        params = params.set('archived', queryParams.archived.toString());

        if (projects.some(project => project === '-1')) {
            return of({ items: [], page: 1, totalRecords: 0, limit: queryParams.limit, totalPages: 0 });
        }

        return this.http.get<ResponseInterface<PageResponseInterface<ProjectTaskInterface>>>(
            environment.apiBaseUrl + '/tasks', { params },
        ).pipe(
            map((x) => {
                return x.body;
            }),
        );
    }

    public createProjectTask(createProjectTask: CreateProjectTaskInterface)
        : Observable<ResponseInterface<ProjectTaskInterface>> {
        return this.http.post<ResponseInterface<ProjectTaskInterface>>(
            environment.apiBaseUrl + '/tasks', { ...createProjectTask, description: null, grade: null },
        );
    }

    public updateProjectTask(id: string, updateProjectTask: UpdateProjectTaskInterface)
        : Observable<ResponseInterface<ProjectTaskInterface>> {
        return this.http.patch<ResponseInterface<ProjectTaskInterface>>(
            environment.apiBaseUrl + '/tasks/' + id, updateProjectTask,
        );
    }

    public removeProjectTask(id: string)
        : Observable<ResponseInterface<null>> {
        return this.http.delete<ResponseInterface<null>>(
            environment.apiBaseUrl + '/tasks/' + id,
        );
    }

    public restoreProjectTask(id: string)
        : Observable<ResponseInterface<ProjectTaskInterface>> {
        return this.http.patch<ResponseInterface<ProjectTaskInterface>>(
            environment.apiBaseUrl + '/tasks/' + id + '/restore', {},
        );
    }
}
