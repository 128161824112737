import { createReducer, on } from '@ngrx/store';
import * as moment from 'moment';

import { PaymentInvoiceStatusEnum } from '@app-client/payment/invoices/types/payment-invoice-status.enum';
import {
    marketplaceInvoicesGetDetailsAction,
    marketplaceInvoicesGetDetailsSuccessAction,
    marketplaceInvoicesGetListAction,
    marketplaceInvoicesGetListFailureAction,
    marketplaceInvoicesGetListSuccessAction,
    marketplaceInvoicesGetPdfAction, marketplaceInvoicesGetPdfFailureAction,
    marketplaceInvoicesGetPdfSuccessAction,
    marketplaceInvoicesPayAction,
    marketplaceInvoicesPayFailureAction,
    marketplaceInvoicesPaySuccessAction,
} from '@app-client/store/marketplace/invoices/actions/marketplace-invoices.actions';
import { MarketplaceInvoicesStateInterface } from '@app-client/store/marketplace/invoices/marketplace-invoices-state.interface';
import { marketplaceModulesGetDetailsFailureAction } from '@app-client/store/marketplace/modules/actions/marketplace-modules.actions';


const initialState: MarketplaceInvoicesStateInterface = {
    isListLoading: false,
    list: [],
    isDetailsLoading: false,
    details: null,
    isPayInProgress: false,
    isGetPdfInProgress: false,
};

const reducer = createReducer(
    initialState,
    on(
        marketplaceInvoicesGetListAction,
        (state) => ({
            ...state,
            isListLoading: true,
            list: [],
        }),
    ),
    on(
        marketplaceInvoicesGetListSuccessAction,
        (state, action) => ({
            ...state,
            isListLoading: false,
            list: action.list,
        }),
    ),
    on(
        marketplaceInvoicesGetListFailureAction,
        (state) => ({
            ...state,
            isListLoading: false,
        }),
    ),
    on(
        marketplaceInvoicesGetDetailsAction,
        (state) => ({
            ...state,
            isDetailsLoading: true,
            details: null,
        }),
    ),
    on(
        marketplaceInvoicesGetDetailsSuccessAction,
        (state, action) => ({
            ...state,
            isDetailsLoading: false,
            details: action.details,
        }),
    ),
    on(
        marketplaceModulesGetDetailsFailureAction,
        (state) => ({
            ...state,
            isDetailsLoading: false,
        }),
    ),
    on(
        marketplaceInvoicesPayAction,
        (state) => ({
            ...state,
            isPayInProgress: true,
        }),
    ),
    on(
        marketplaceInvoicesPaySuccessAction,
        (state, action) => {
            const newState = {
                ...state,
                isPayInProgress: false,
            };
            if (state.details?.invoice.id === action.id) {
                newState.details = {
                    ...newState.details,
                    invoice: {
                        ...newState.details.invoice,
                        status: PaymentInvoiceStatusEnum.PAID,
                        paymentDate: moment().toISOString(),
                    }
                };
            }
            newState.list = newState.list.map((invoice) => {
                if (invoice.id !== action.id) {
                    return invoice;
                }
                return {
                    ...invoice,
                    status: PaymentInvoiceStatusEnum.PAID,
                };
            });

            return newState;
        },
    ),
    on(
        marketplaceInvoicesPayFailureAction,
        (state) => ({
            ...state,
            isPayInProgress: false,
        }),
    ),

    on(
        marketplaceInvoicesGetPdfAction,
        (state) => ({
            ...state,
            isGetPdfInProgress: true,
        }),
    ),
    on(
        marketplaceInvoicesGetPdfSuccessAction,
        (state) => ({
            ...state,
            isGetPdfInProgress: false,
        }),
    ),
    on(
        marketplaceInvoicesGetPdfFailureAction,
        (state) => ({
            ...state,
            isGetPdfInProgress: false,
        }),
    ),
);

export const marketplaceInvoicesReducer = (state, action) => reducer(state, action);
