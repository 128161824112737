<ui-select
    [items]="list"
    [(ngModel)]="selected"
    [placeholder]="('PROJECTS_SELECTOR.PLACEHOLDER.' + (true === isMultiple ? 'MULTIPLE' : 'SINGLE')) | translate"
    [multiple]="true === isMultiple"
    [clearable]="true === isMultiple"
    [closeOnSelect]="false === isMultiple"
    (scrollToEnd)="loadMore()"
    [loading]="true === isLoading"
    [loadingText]="'PROJECTS_SELECTOR.LOADING' | translate"
    [searchFn]="searchFn"
    (search)="search($event.term)"
    [notFoundText]="'PROJECTS_SELECTOR.NOT_FOUND' | translate"
    [compareWith]="compareWith"
    (blur)="search('')"
    (clear)="search('')"
>
    <ng-container *ngIf="true === isAllowArchived">
        <ng-template ui-header-tmp>
            <mat-checkbox
                [checked]="listQueryParams$.value.archived === QueryArchivedEnum.INCLUDE"
                (click)="$event.preventDefault();toggleArchived()"
            >
                {{ 'PROJECTS_SELECTOR.SHOW_ARCHIVED' | translate }}
            </mat-checkbox>
        </ng-template>
    </ng-container>
    <ng-container *ngIf="true === isMultiple">
        <ng-template
            ui-option-tmp
            let-project="item"
            let-project$="item$"
        >
                <mat-checkbox
                    [checked]="project$.selected"
                    (click)="$event.preventDefault()"
                >
                    {{ true === !!project.archivedAt ? ('GLOBAL.ENTITY.ARCHIVED_PREFIX' | translate) : '' }}
                    {{ project.isDefault? ('GLOBAL.ENTITY.DEFAULT.PROJECT.NAME' | translate) : project.name }}
                </mat-checkbox>
        </ng-template>
        <ng-template
            ui-label-tmp
            let-project="item"
            let-clear="clear"
        >
            <span class="ui-value-label">
                {{ true === !!project.archivedAt ? ('GLOBAL.ENTITY.ARCHIVED_PREFIX' | translate) : '' }}
                {{ project.isDefault? ('GLOBAL.ENTITY.DEFAULT.PROJECT.NAME' | translate) : project.name }}
            </span>
            <span
                class="ui-value-icon right"
                (click)="clear(project)"
            >
                ×
            </span>
        </ng-template>
    </ng-container>

    <ng-container *ngIf="false === isMultiple">
        <ng-template
            ui-option-tmp
            ui-label-tmp
            let-project="item"
        >
            <ng-container *ngIf="PROJECTS_SELECTOR_ALL_ELEMENT === project">
                {{ 'GLOBAL.INPUT.ALL' | translate }}
            </ng-container>
            <ng-container *ngIf="PROJECTS_SELECTOR_ALL_ELEMENT !== project">
                {{ true === !!project.archivedAt ? ('GLOBAL.ENTITY.ARCHIVED_PREFIX' | translate) : '' }}
                {{ project.isDefault? ('GLOBAL.ENTITY.DEFAULT.PROJECT.NAME' | translate) : project.name }}
            </ng-container>
        </ng-template>
    </ng-container>
</ui-select>
