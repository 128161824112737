import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { JiraBoardInterface } from '@app-client/jira/types/filter-types/jira-board.interface';
import { JiraEpicInterface } from '@app-client/jira/types/filter-types/jira-epic.interface';
import { JiraIssueTypeInterface } from '@app-client/jira/types/filter-types/jira-issue-type.interface';
import { JiraProjectInterface } from '@app-client/jira/types/filter-types/jira-project.interface';
import { JiraSprintInterface } from '@app-client/jira/types/filter-types/jira-sprint.interface';
import { JiraStatusInterface } from '@app-client/jira/types/filter-types/jira-status.interface';
import { JiraAttachProjectInterface } from '@app-client/jira/types/jira-attach-project.interface';
import { JiraSyncStatusInterface } from '@app-client/jira/types/jira-sync-status.interface';
import { JiraSyncTypeEnum } from '@app-client/jira/types/jira-sync-type.enum';
import { JiraUpdateProjectInterface } from '@app-client/jira/types/jira-update-project.interface';
import { JiraBoardResponseInterface } from '@app-client/jira/types/response-types/jira-board-response.interface';
import { JiraEpicResponseInterface } from '@app-client/jira/types/response-types/jira-epic-response.interface';
import { JiraProjectStatusInterface } from '@app-client/jira/types/response-types/jira-project-status.interface';
import { JiraSprintResponseInterface } from '@app-client/jira/types/response-types/jira-sprint-response.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';

import { environment } from '@env-client/environment';


@Injectable()
export class JiraConnectionService {
    constructor(
        private http: HttpClient,
    ) {}

    getProjectStatus(projectId: string): Observable<JiraProjectStatusInterface> {
        const url = `${environment.apiBaseUrl}/jira/project-status/${projectId}`;

        return this.http.get<ResponseInterface<JiraProjectStatusInterface>>(url).pipe(
            map((response) => {
                return response.body.connectId ? response.body : null;
            }),
        );
    }

    getProjects(connectionId: string): Observable<Array<JiraProjectInterface>> {
        const url = `${environment.apiBaseUrl}/jira/${connectionId}/projects`;

        return this.http.get<ResponseInterface<Array<JiraProjectInterface>>>(url).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    getStatuses(connectionId: string, projectKey: string): Observable<Array<JiraStatusInterface>> {
        const url = `${environment.apiBaseUrl}/jira/${connectionId}/projects/${projectKey}/statuses`;

        return this.http.get<ResponseInterface<Array<JiraStatusInterface>>>(url).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    getIssueTypes(connectionId: string, projectKey: string): Observable<Array<JiraIssueTypeInterface>> {
        const url = `${environment.apiBaseUrl}/jira/${connectionId}/projects/${projectKey}/issue-types`;

        return this.http.get<ResponseInterface<Array<JiraIssueTypeInterface>>>(url).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    getBoards(connectionId: string, projectKey: string): Observable<Array<JiraBoardInterface>> {
        const url = `${environment.apiBaseUrl}/jira/${connectionId}/projects/${projectKey}/boards`;

        return this.http.get<ResponseInterface<Array<JiraBoardResponseInterface>>>(url).pipe(
            map((response) => {
                return response.body.map((board) => {
                    return {
                        ...board,
                        id: board.id.toString(),
                    };
                });
            }),
        );
    }

    getEpics(connectionId: string, boardIds: Array<string>): Observable<Array<JiraEpicInterface>> {
        const url = `${environment.apiBaseUrl}/jira/${connectionId}/boards/epics`;
        const body = {
            boardsId: boardIds,
        };

        return this.http.post<ResponseInterface<{ [key: string]: Array<JiraEpicResponseInterface> }>>(url, body).pipe(
            map((response) => {
                return Object.values(response.body).reduce((acc, epics) => {
                    const mappedEpics = epics.map((epic) => {
                        return {
                            id: epic.id.toString(),
                            name: epic.name,
                        };
                    }).filter((epic) => {
                        return acc.every((accEpic) => {
                            return accEpic.id !== epic.id;
                        });
                    });
                    return [...acc, ...mappedEpics];
                }, new Array<JiraEpicInterface>());
            }),
        );
    }

    getSprints(connectionId: string, boardIds: Array<string>): Observable<Array<JiraSprintInterface>> {
        const url = `${environment.apiBaseUrl}/jira/${connectionId}/boards/sprints`;
        const body = {
            boardsId: boardIds,
        };

        return this.http.post<ResponseInterface<{ [key: string]: Array<JiraSprintResponseInterface> }>>(url, body).pipe(
            map((response) => {
                return Object.values(response.body).reduce((acc, sprints) => {
                    const mappedSprints = sprints.map((sprint) => {
                        return {
                            id: sprint.id.toString(),
                            name: sprint.name,
                        };
                    }).filter((sprint) => {
                        return acc.every((accSprint) => {
                            return accSprint.id !== sprint.id;
                        });
                    });

                    return [...acc, ...mappedSprints];
                }, new Array<JiraSprintInterface>());
            }),
        );
    }

    attachProject(projectId: string, filters: JiraAttachProjectInterface): Observable<void> {
        const url = `${environment.apiBaseUrl}/jira/attach-project/${projectId}`;
        const body = {
            ...filters,
        };

        return this.http.post<ResponseInterface<void>>(url, body).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    detachProject(projectId: string): Observable<void> {
        const url = `${environment.apiBaseUrl}/jira/detach-project/${projectId}`;
        const body = {
            projectId,
        };

        return this.http.post<ResponseInterface<void>>(url, body).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    updateProject(projectId: string, filters: JiraUpdateProjectInterface): Observable<void> {
        const url = `${environment.apiBaseUrl}/jira/update-filter-settings/${projectId}`;
        const body = {
            ...filters,
        };

        return this.http.patch<ResponseInterface<void>>(url, body).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

     checkPermissions(connectionId: string): Observable<{ getProject: boolean }> {
        const url = `${environment.apiBaseUrl}/jira/auth/check-permissions`;
        let params = new HttpParams();
        params = params.set('jiraConnectId', connectionId);

        return this.http.get<ResponseInterface<Array<{ getProject: boolean }>>>(url, { params }).pipe(
            map((response) => {
                return response.body[0];
            }),
        );
     }

     startSync(projectId: string): Observable<void> {
        const url = `${environment.apiBaseUrl}/jira/sync/run/${projectId}`;

        return this.http.post<ResponseInterface<void>>(url, {}).pipe(
            map((response) => {
                return response.body;
            }),
        );
     }

    updateSyncTimeSettings(projectId: string, syncMemo: number, sumTimeByTask: number): Observable<void> {
        const url = `${environment.apiBaseUrl}/jira/sync-time/settings/${projectId}`;
        const body = {
            syncMemo,
            sumTimeByTask
        };

        return this.http.post<ResponseInterface<void>>(url, body).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    startSyncTime(projectId: string, from: number, to: number): Observable<void> {
        const url = `${environment.apiBaseUrl}/jira/sync-time/run/${projectId}`;

        let params = new HttpParams();
        params = params.set('fromDate', String(from));
        params = params.set('toDate', String(to));

        return this.http.post<ResponseInterface<void>>(url, {}, { params }).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    getSyncStatus(projectId: string): Observable<JiraSyncStatusInterface> {
        const url = `${environment.apiBaseUrl}/jira/project-status/${projectId}`;

        return this.http.get<ResponseInterface<JiraProjectStatusInterface>>(url).pipe(
            map((response) => {
                return {
                    syncStatus: response.body?.connectId ? response.body.syncStatus !== 0 : false,
                    syncType: response.body?.connectId ? response.body.syncType : JiraSyncTypeEnum.NONE
                };
            }),
        );
    }
}
