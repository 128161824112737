<div class="row">
    <div class="col s12 center">
        <h1>{{ 'SELECTOR.MODULES.TITLE' | translate }}</h1>
    </div>
</div>
<div class="row">
    <div class="col s12">
        <ng-container *ngIf="(isLoading$ | async) === true">
            <div class="row">
                <div class="col s12 center">
                    <ui-spinner></ui-spinner>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="(isLoading$ | async) === false">
            <div class="module__list">
                <ng-container *ngFor="let module of (modules$ | async)">
                    <div class="module">
                        <div class="row">
                            <div class="col s12">
                                <div class="module__title">
                                    <img class="title__icon" [src]="module.icon" />
                                    <div class="title__name">
                                        {{ module.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-30">
                            <div class="col s12">
                                <div class="module__description">
                                    <div class="description__title">
                                        {{ 'MARKETPLACE.MODULES.DETAILS.DESCRIPTION' | translate }}
                                    </div>
                                    <div class="description__text">
                                        {{ module.description }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="module.tariffs.length === 1">
                            <div class="row">
                                <div class="col">
                                    <div class="module__information">
                                        <ng-container *ngIf="module.tariffs[0].isFreeOnPlan === false && (trialEnd$ | async) === null">
                                            <div class="information__title">
                                                {{ module.isPersonal === true ? 'MARKETPLACE.MODULES.DETAILS.INFORMATION.PRICE.LICENSE' : 'MARKETPLACE.MODULES.DETAILS.INFORMATION.PRICE.MODULE' | translate }}
                                            </div>
                                        </ng-container>
                                        <div class="information__content">
                                            <ng-container *ngIf="module.tariffs[0].isFreeOnPlan === true">
                                                {{ 'SELECTOR.MODULES.FREE' | translate }}
                                            </ng-container>
                                            <ng-container *ngIf="module.tariffs[0].isFreeOnPlan === false">
                                                <ng-container *ngIf="(trialEnd$ | async) !== null">
                                                    {{ 'SELECTOR.TRIAL.INLINE' | translate:{ date: (trialEnd$ | async).format('DD MMMM YYYY HH:mm') } }}
                                                </ng-container>
                                                <ng-container *ngIf="(trialEnd$ | async) === null">
                                                    {{ module.tariffs[0].actualCost / 100 }}$
                                                    <ng-container *ngIf="module.isSubscription === true">
                                                        {{ 'MARKETPLACE.MODULES.DETAILS.INFORMATION.PRICE.PER_MONTH' | translate }}
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                                <div class="col right">
                                    <button
                                        ui-button
                                        size="large"
                                        color="yellow"
                                        (click)="select(module.id, module.tariffs[0].id)"
                                        [disabled]="module.tariffs[0].isInstalled === true"
                                    >
                                        {{ (module.tariffs[0].isInstalled ? "MARKETPLACE.MODULES.DETAILS.INSTALLED.MODULE" : "MARKETPLACE.MODULES.DETAILS.INSTALL.MODULE") | translate }}
                                    </button>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="module.tariffs.length > 1">
                            <div class="row">
                                <div class="col s12">
                                    <div class="module__plans-description">
                                        <div class="plans-description__title">
                                            {{ 'MARKETPLACE.MODULES.DETAILS.CONFIGURATIONS.TITLE' | translate }}
                                        </div>
                                        <div class="plans-description__text">
                                            {{ 'MARKETPLACE.MODULES.DETAILS.CONFIGURATIONS.DESCRIPTION' | translate }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col s12">
                                    <div class="module__plans-list">
                                        <ng-container *ngFor="let tariff of module.tariffs">
                                            <div class="plans-list__plan">
                                                <div class="plan__title">
                                                    {{ tariff.name }}
                                                </div>
                                                <div class="plan__description">
                                                    {{ tariff.description }}
                                                </div>
                                                <div class="plan__cost">
                                                    <ng-container *ngIf="tariff.isFreeOnPlan === false && (trialEnd$ | async) === null">
                                                        <div class="cost__title">
                                                            {{ 'MARKETPLACE.MODULES.DETAILS.CONFIGURATIONS.PRICE' | translate }}
                                                            <ng-container *ngIf="module.isPersonal === true">
                                                                {{ 'MARKETPLACE.MODULES.DETAILS.CONFIGURATIONS.PER_USER' | translate }}
                                                            </ng-container>
                                                            <ng-container *ngIf="module.isSubscription === true">
                                                                {{ 'MARKETPLACE.MODULES.DETAILS.CONFIGURATIONS.PER_MONTH' | translate }}
                                                            </ng-container>
                                                        </div>
                                                    </ng-container>
                                                    <div class="cost__value">
                                                        <ng-container *ngIf="tariff.isFreeOnPlan === true">
                                                            {{ 'SELECTOR.MODULES.FREE' | translate }}
                                                        </ng-container>
                                                        <ng-container *ngIf="tariff.isFreeOnPlan === false">
                                                            <ng-container *ngIf="(trialEnd$ | async) !== null">
                                                                <div
                                                                    innerHTML="{{ 'SELECTOR.TRIAL.MULTILINE' | translate:{ date: (trialEnd$ | async).format('DD MMMM YYYY HH:mm') } }}"
                                                                ></div>
                                                            </ng-container>
                                                            <ng-container *ngIf="(trialEnd$ | async) === null">
                                                                {{ tariff.actualCost / 100 }}$
                                                            </ng-container>
                                                        </ng-container>

                                                    </div>
                                                </div>
                                                <button
                                                    ui-button
                                                    color="yellow"
                                                    size="large"
                                                    class="plan__install"
                                                    (click)="select(module.id, tariff.id)"
                                                    [disabled]="tariff.isInstalled === true"
                                                >
                                                    {{ (tariff.isInstalled ? 'MARKETPLACE.MODULES.DETAILS.INSTALLED.CONFIGURATION' : 'MARKETPLACE.MODULES.DETAILS.INSTALL.CONFIGURATION') | translate }}
                                                </button>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>
<div class="row">
    <div class="col s12 center">
        <button
            ui-button
            color="yellow"
            size="large"
            (click)="close()"
            class="start-using"
        >
            {{ 'SELECTOR.MODULES.START_USING' | translate }}
        </button>
    </div>
</div>
