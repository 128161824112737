import { createAction, props } from '@ngrx/store';

import { EffectivityConfigBulkUpdateInterface } from '@app-client/project/types/effectivity-config-bulk-update.interface';
import { EffectivityConfigDetailsInterface } from '@app-client/project/types/effectivity-config-details.interface';
import { EffectivityConfigSetInterface } from '@app-client/project/types/effectivity-config-set.interface';
import { ActionTypes } from '@app-client/store/effectivity-config/action-types';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';


export const getEffectivityConfigDetailsByProjectMemberAction = createAction(
    ActionTypes.GET_ONE_BY_PROJECT_MEMBER,
    props<{ projectMemberId: string }>(),
);

export const getEffectivityConfigDetailsByProjectAndUserAction = createAction(
    ActionTypes.GET_ONE_BY_PROJECT_AND_USER,
    props<{ projectId: string, userId: string }>(),
);

export const getEffectivityConfigDetailsSuccessAction = createAction(
    ActionTypes.GET_ONE_SUCCESS,
    props<{ response: ResponseInterface<EffectivityConfigDetailsInterface> }>(),
);

export const getEffectivityConfigDetailsFailureAction = createAction(
    ActionTypes.GET_ONE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const setEffectivityConfigByProjectMemberAction = createAction(
    ActionTypes.SET_BY_PROJECT_MEMBER,
    props<{ projectMemberId: string, config: EffectivityConfigSetInterface, successCallback?: () => void }>(),
);

export const setEffectivityConfigSuccessAction = createAction(
    ActionTypes.SET_SUCCESS,
    props<{ successCallback: () => void }>(),
);

export const setEffectivityConfigFailureAction = createAction(
    ActionTypes.SET_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const effectivityConfigBulkUpdateAction = createAction(
    ActionTypes.BULK_UPDATE,
    props<{ bulkUpdate: EffectivityConfigBulkUpdateInterface }>(),
);

export const effectivityConfigBulkUpdateSuccessAction = createAction(
    ActionTypes.BULK_UPDATE_SUCCESS,
);

export const effectivityConfigBulkUpdateFailureAction = createAction(
    ActionTypes.BULK_UPDATE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
