import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import {
    createProjectTaskAction,
    createProjectTaskFailureAction,
    createProjectTaskSuccessAction,
    getProjectTaskListAction,
    getProjectTaskListFailureAction,
    getProjectTaskListForEditTimeAction,
    getProjectTaskListForEditTimeFailureAction,
    getProjectTaskListForEditTimeSuccessAction,
    getProjectTaskListSuccessAction,
    removeProjectTaskAction,
    removeProjectTaskFailureAction,
    removeProjectTaskSuccessAction,
    restoreProjectTaskAction,
    restoreProjectTaskFailureAction,
    restoreProjectTaskSuccessAction,
    setProjectTaskListQueryParamsAction,
    updateProjectTaskAction,
    updateProjectTaskFailureAction,
    updateProjectTaskSuccessAction,
} from '@app-client/store/project-tasks/actions/project-tasks.action';
import {
    projectTaskForEditTimeQueryParamsSelector,
    projectTaskQueryParamsSelector,
} from '@app-client/store/project-tasks/selectors/project-tasks.selector';
import { ProjectTasksService } from '@app-client/store/project-tasks/serivces/project-tasks.service';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';



@Injectable()
export class ProjectTasksEffects {

    constructor(
        private actions$: Actions,
        private store$: Store,
        private toastr: ToastrService,
        private translate: TranslateService,
        private projectTasksService: ProjectTasksService,
    ) {}

    getProjectTaskList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getProjectTaskListAction),
            withLatestFrom(this.store$.select(projectTaskQueryParamsSelector)),
            switchMap(([, queryParams]) => {
                return this.projectTasksService.getProjectTasksList(queryParams).pipe(
                    map((response) => {
                        return getProjectTaskListSuccessAction({ response });
                    }),
                    catchError( (httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(getProjectTaskListFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getProjectTaskListFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getProjectTaskListFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('PROJECT_TASKS.NOTIFICATIONS.GET_PROJECT_TASKS_LIST.ERROR.TITLE')
                );
            }),
        );
    }, { dispatch: false });

    getProjectTaskListForEditTime$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getProjectTaskListForEditTimeAction),
            withLatestFrom(this.store$.select(projectTaskForEditTimeQueryParamsSelector)),
            switchMap(([, queryParams]) => {
                return this.projectTasksService.getProjectTasksList(queryParams).pipe(
                    map((response) => {
                        return getProjectTaskListForEditTimeSuccessAction({ response });
                    }),
                    catchError( (httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(getProjectTaskListForEditTimeFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getProjectTaskListForEditTimeFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getProjectTaskListForEditTimeFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('PROJECT_TASKS.NOTIFICATIONS.GET_PROJECT_TASKS_LIST.ERROR.TITLE')
                );
            }),
        );
    }, { dispatch: false });

    createProjectTask$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createProjectTaskAction),
            switchMap(({ createProjectTask, successCallback }) => {
                return this.projectTasksService.createProjectTask(createProjectTask).pipe(
                    map(() => {
                        return createProjectTaskSuccessAction({ successCallback });
                    }),
                    catchError( (httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(createProjectTaskFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    createProjectTaskSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createProjectTaskSuccessAction),
            tap(({ successCallback }) => {
                this.toastr.success(
                    this.translate.instant('PROJECT_TASKS.NOTIFICATIONS.CREATE.SUCCESS.MESSAGE'),
                    this.translate.instant('PROJECT_TASKS.NOTIFICATIONS.CREATE.SUCCESS.TITLE')
                );
                successCallback();
            }),
        );
    }, { dispatch: false });


    createProjectTaskFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createProjectTaskFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('PROJECT_TASKS.NOTIFICATIONS.CREATE.ERROR.TITLE')
                );
            }),
        );
    }, { dispatch: false });

    updateProjectTask$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateProjectTaskAction),
            switchMap(({ id, updateProjectTask, successCallback }) => {
                return this.projectTasksService.updateProjectTask(id, updateProjectTask).pipe(
                    map( () => {
                        return updateProjectTaskSuccessAction({ successCallback });
                    }),
                    catchError( (httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(updateProjectTaskFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    updateProjectTaskSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateProjectTaskSuccessAction),
            tap(({ successCallback }) => {
                this.toastr.success(
                    this.translate.instant('PROJECT_TASKS.NOTIFICATIONS.UPDATE.SUCCESS.MESSAGE'),
                    this.translate.instant('PROJECT_TASKS.NOTIFICATIONS.UPDATE.SUCCESS.TITLE')
                );
                successCallback();
            }),
        );
    }, { dispatch: false });


    updateProjectTaskFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateProjectTaskFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('PROJECT_TASKS.NOTIFICATIONS.UPDATE.ERROR.TITLE')
                );
            }),
        );
    }, { dispatch: false });

   removeProjectTask$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(removeProjectTaskAction),
            switchMap(({ id }) => {
                return this.projectTasksService.removeProjectTask(id).pipe(
                    map( () => {
                        return removeProjectTaskSuccessAction();
                    }),
                    catchError( (httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(removeProjectTaskFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
   });

    removeProjectTaskSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(removeProjectTaskSuccessAction),
            withLatestFrom(this.store$.select(projectTaskQueryParamsSelector)),
            switchMap(([, queryParams]) => {
                this.toastr.success(
                    this.translate.instant('PROJECT_TASKS.NOTIFICATIONS.ARCHIVE.SUCCESS.MESSAGE'),
                    this.translate.instant('PROJECT_TASKS.NOTIFICATIONS.ARCHIVE.SUCCESS.TITLE')
                );
                return of(
                    setProjectTaskListQueryParamsAction({
                        queryParams: {
                            ...queryParams,
                            page: 1,
                        }
                    }),
                    getProjectTaskListAction(),
                );
            }),
        );
    });


    removeProjectTaskFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(removeProjectTaskFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('PROJECT_TASKS.NOTIFICATIONS.ARCHIVE.ERROR.TITLE')
                );
            }),
        );
    }, { dispatch: false });

    restoreProjectTask$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(restoreProjectTaskAction),
            switchMap(({ id }) => {
                return this.projectTasksService.restoreProjectTask(id).pipe(
                    map( () => {
                        return restoreProjectTaskSuccessAction();
                    }),
                    catchError( (httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(restoreProjectTaskFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    restoreProjectTaskSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(restoreProjectTaskSuccessAction),
            withLatestFrom(this.store$.select(projectTaskQueryParamsSelector)),
            switchMap(([, queryParams]) => {
                this.toastr.success(
                    this.translate.instant('PROJECT_TASKS.NOTIFICATIONS.RESTORE.SUCCESS.MESSAGE'),
                    this.translate.instant('PROJECT_TASKS.NOTIFICATIONS.RESTORE.SUCCESS.TITLE')
                );
                return of(
                    setProjectTaskListQueryParamsAction({
                        queryParams: {
                            ...queryParams,
                            page: 1,
                        }
                    }),
                    getProjectTaskListAction(),
                );
            }),
        );
    });


    restoreProjectTaskFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(restoreProjectTaskFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('PROJECT_TASKS.NOTIFICATIONS.RESTORE.ERROR.TITLE')
                );
            }),
        );
    }, { dispatch: false });
}
