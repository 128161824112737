import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'filter',
    pure: false,
})
export class FilterPipe implements PipeTransform {
    transform<T>(value: Array<T>, callback: (item: T) => boolean): Array<T> {
        return value.filter(callback);
    }
}
