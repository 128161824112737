import { Injectable } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';


@Injectable({
    providedIn: 'root',
})
export class MissingTranslationService implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams) {
        const message = `WARN: '${params.key}' is missing in '${params.translateService.currentLang}' locale`;

        console.warn(message);

        return params.key;
    }
}
