import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';

export function strongPasswordValidator(control: AbstractControl): ValidationErrors {
  const value = control.value;

  if (!value) {
    return null;
  }

  const lengthErrors = Validators.minLength(8)(control);

  if (lengthErrors) {
      return lengthErrors;
  }

  const uppercase = new RegExp('[A-Z]').test(value);
  const lowercase = new RegExp('[a-z]').test(value);
  const digits = new RegExp('\\d').test(value);
  const specialChars = new RegExp('[!@#$%^&*()_=+\[\\]{};:"|,.]').test(value);

  const anythingElse = new RegExp('[^!@#$%^&*()_=+[\\]{};:"|,.A-Za-z\\d]').test(value);

  if (anythingElse) {
    return { strongPasswordForbiddenCharacters: true };
  }

  if (!uppercase || !lowercase || !digits || !specialChars) {
    return { strongPassword: true };
  }

  return null;
}
