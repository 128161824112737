import { createReducer, on } from '@ngrx/store';

import { KaitenProjectSyncStatusEnum } from '@app-client/kaiten/types/kaiten-project-status.interface';
import {
    kaitenAttachSpaceToProjectAction,
    kaitenAttachSpaceToProjectFailureAction,
    kaitenAttachSpaceToProjectSuccessAction,
    kaitenDetachSpaceFromProjectAction,
    kaitenDetachSpaceFromProjectFailureAction,
    kaitenDetachSpaceFromProjectSuccessAction,
    kaitenGetProjectColumnsAction,
    kaitenGetProjectColumnsFailureAction,
    kaitenGetProjectColumnsSuccessAction,
    kaitenGetProjectStatusAction,
    kaitenGetProjectStatusFailureAction,
    kaitenGetProjectStatusSuccessAction,
    kaitenGetUserBoardsAction,
    kaitenGetUserBoardsFailureAction,
    kaitenGetUserBoardsSuccessAction,
    kaitenStartSpacesListSyncAction,
    kaitenStartSpacesListSyncSuccessAction,
    kaitenStartSpacesListSyncFailureAction,
    kaitenStartSyncAction,
    kaitenStartSyncFailureAction,
    kaitenStartSyncSuccessAction,
    kaitenStopSyncAction,
    kaitenStopSyncFailureAction,
    kaitenStopSyncSuccessAction,
    kaitenUpdateProjectColumnSettingsAction,
    kaitenUpdateProjectColumnSettingsFailureAction,
    kaitenUpdateProjectColumnSettingsSuccessAction,
    kaitenUpdateKeyAction,
    kaitenUpdateKeySuccessAction,
    kaitenUpdateKeyFailureAction,
    kaitenGetUserSpacesAction,
    kaitenGetUserSpacesSuccessAction,
    kaitenGetUserSpacesFailureAction,
    kaitenGetUserDataAction,
    kaitenGetUserDataSuccessAction,
    kaitenGetUserDataFailureAction,
    kaitenDeleteKeyAction,
    kaitenDeleteKeySuccessAction,
    kaitenDeleteKeyFailureAction,
    kaitenStartSpaceBoardsListSyncAction,
    kaitenStartSpaceBoardsListSyncSuccessAction,
    kaitenStartSpaceBoardsListSyncFailureAction
} from '@app-client/store/kaiten/actions/kaiten.action';
import { KaitenStateInterface } from '@app-client/store/kaiten/kaiten.state.interface';


const initialState: KaitenStateInterface = {
    isGetUserSpacesInProgess: false,
    isGetProjectStatusInProgress: false,
    kaitenUserData: {},
    isKaitenUserDataInProgress: false,
    isKaitenUpdateKeyInProgress: false,
    isKaitenDeleteKeyInProgress: false,
    userSpaces: [],
    projectStatuses: {},
    isGetUserBoardsInProgress: false,
    isGetProjectColumnsInProgress: false,
    userBoards: [],
    columns: [],
    isAttachSpaceToProjectInProgress: false,
    isUpdateColumnSettingsInProgress: false,
    isDetachSpaceFromProjectInProgress: false,
    isStartSyncInProgress: false,
    isStopSyncInProgress: false,
    isStartSpacesListSyncInProgress: false,
    isStartSpaceBoardsListSyncInProgress: false
};

const reducer = createReducer(
    initialState,
    on(
        kaitenGetProjectStatusAction,
        (state) => ({
            ...state,
            isGetProjectStatusInProgress: true,
        }),
    ),
    on(
        kaitenGetProjectStatusSuccessAction,
        (state, { projectId, projectStatus }) => ({
            ...state,
            isGetProjectStatusInProgress: false,
            projectStatuses: {
                ...state.projectStatuses,
                [projectId]: projectStatus,
            },
        }),
    ),
    on(
        kaitenGetProjectStatusFailureAction,
        (state) => ({
            ...state,
            isGetProjectStatusInProgress: false,
        }),
    ),
    on(
        kaitenGetUserBoardsAction,
        (state) => ({
            ...state,
            isGetUserBoardsInProgress: true,
            userBoards: [],
            columns: [],
        }),
    ),
    on(
        kaitenGetUserBoardsSuccessAction,
        (state, { boards }) => ({
            ...state,
            isGetUserBoardsInProgress: false,
            userBoards: [
                ...boards,
            ],
        }),
    ),
    on(
        kaitenGetUserBoardsFailureAction,
        (state) => ({
            ...state,
            isGetUserBoardsInProgress: false,
        }),
    ),
    on(
        kaitenGetUserSpacesAction,
        (state) => ({
            ...state,
            isGetUserSpacesInProgess: true,
            userSpaces: [
            ]
        })
    ),
    on(
        kaitenGetUserSpacesSuccessAction,
        (state, { spaces }) => ({
            ...state,
            isGetUserSpacesInProgess: false,
            userSpaces: [
                ...spaces,
            ],
        }),
    ),
    on(
        kaitenGetUserSpacesFailureAction,
        (state) => ({
            ...state,
            isGetUserSpacesInProgess: false,
        }),
    ),
    on(
        kaitenGetUserDataAction,
        (state) => ({
            ...state,
            isKaitenUserDataInProgress: true,
        }),
    ),
    on(
        kaitenGetUserDataSuccessAction,
        (state, { kaitenUserData }) => ({
            ...state,
            kaitenUserData,
            isKaitenUserDataInProgress: false,
        }),
    ),
    on(
        kaitenGetUserDataFailureAction,
        (state) => ({
            ...state,
            isKaitenUserDataInProgress: false,
        }),
    ),
    on(
        kaitenUpdateKeyAction,
        (state) => ({
            ...state,
            isKaitenUpdateKeyInProgress: true,
        })
    ),
    on(
        kaitenUpdateKeySuccessAction,
        (state) => ({
            ...state,
            isKaitenUpdateKeyInProgress: false,
        }),
    ),
    on(
        kaitenUpdateKeyFailureAction,
        (state) => ({
            ...state,
            isKaitenUpdateKeyInProgress: false,
        }),
    ),
    on(
        kaitenDeleteKeyAction,
        (state) => ({
            ...state,
            isKaitenDeleteKeyInProgress: true,
        })
    ),
    on(
        kaitenDeleteKeySuccessAction,
        (state) => ({
            ...state,
            isKaitenDeleteKeyInProgress: false,
        }),
    ),
    on(
        kaitenDeleteKeyFailureAction,
        (state) => ({
            ...state,
            isKaitenDeleteKeyInProgress: false,
        }),
    ),
    on(
        kaitenGetProjectColumnsAction,
        (state) => ({
            ...state,
            isGetProjectColumnsInProgress: true,
        }),
    ),
    on(
        kaitenGetProjectColumnsSuccessAction,
        (state, { columns }) => ({
            ...state,
            isGetProjectColumnsInProgress: false,
            columns,
        }),
    ),
    on(
        kaitenGetProjectColumnsFailureAction,
        (state) => ({
            ...state,
            isGetProjectColumnsInProgress: false,
        }),
    ),
    on(
        kaitenAttachSpaceToProjectAction,
        (state) => ({
            ...state,
            isAttachSpaceToProjectInProgress: true,
        }),
    ),
    on(
        kaitenAttachSpaceToProjectSuccessAction,
        (state) => ({
            ...state,
            isAttachSpaceToProjectInProgress: false,
        }),
    ),
    on(
        kaitenAttachSpaceToProjectFailureAction,
        (state) => ({
            ...state,
            isAttachSpaceToProjectInProgress: false,
        }),
    ),
    on(
        kaitenUpdateProjectColumnSettingsAction,
        (state) => ({
            ...state,
            isUpdateColumnSettingsInProgress: true,
        }),
    ),
    on(
        kaitenUpdateProjectColumnSettingsSuccessAction,
        (state) => ({
            ...state,
            isUpdateColumnSettingsInProgress: false,
        }),
    ),
    on(
        kaitenUpdateProjectColumnSettingsFailureAction,
        (state) => ({
            ...state,
            isUpdateColumnSettingsInProgress: false,
        }),
    ),
    on(
        kaitenDetachSpaceFromProjectAction,
        (state) => ({
            ...state,
            isDetachSpaceFromProjectInProgress: true,
        }),
    ),
    on(
        kaitenDetachSpaceFromProjectSuccessAction,
        (state) => ({
            ...state,
            isDetachSpaceFromProjectInProgress: false,
        }),
    ),
    on(
        kaitenDetachSpaceFromProjectFailureAction,
        (state) => ({
            ...state,
            isDetachSpaceFromProjectInProgress: false,
        }),
    ),
    on(
        kaitenStartSyncAction,
        (state) => ({
            ...state,
            isStartSyncInProgress: true,
        }),
    ),
    on(
        kaitenStartSyncSuccessAction,
        (state, { projectId }) => ({
            ...state,
            isStartSyncInProgress: false,
            projectStatuses: {
                ...state.projectStatuses,
                [projectId]: {
                    ...state.projectStatuses[projectId],
                    syncStatus: KaitenProjectSyncStatusEnum.IN_PROGRESS,
                },
            },
        }),
    ),
    on(
        kaitenStartSyncFailureAction,
        (state) => ({
            ...state,
            isStartSyncInProgress: false,
        }),
    ),
    on(
        kaitenStopSyncAction,
        (state) => ({
            ...state,
            isStopSyncInProgress: true,
        }),
    ),
    on(
        kaitenStopSyncSuccessAction,
        (state, { projectId }) => ({
            ...state,
            isStopSyncInProgress: false,
            projectStatuses: {
                ...state.projectStatuses,
                [projectId]: {
                    ...state.projectStatuses[projectId],
                    syncStatus: KaitenProjectSyncStatusEnum.IDLE,
                },
            },
        }),
    ),
    on(
        kaitenStopSyncFailureAction,
        (state, { projectId }) => ({
            ...state,
            isStopSyncInProgress: false,
            projectStatuses: {
                ...state.projectStatuses,
                [projectId]: {
                    ...state.projectStatuses[projectId],
                    syncStatus: KaitenProjectSyncStatusEnum.IDLE,
                },
            },
        }),
    ),
    on(
        kaitenStartSpacesListSyncAction,
        state => ({
            ...state,
            isStartSpacesListSyncInProgress: true
        })
    ),
    on(
        kaitenStartSpacesListSyncSuccessAction,
        state => ({
            ...state,
            isStartSpacesListSyncInProgress: false
        }),
    ),
    on(
        kaitenStartSpacesListSyncFailureAction,
        (state) => ({
            ...state,
            isStartSpacesListSyncInProgress: false,
        }),
    ),
    on(
        kaitenStartSpaceBoardsListSyncAction,
        state => ({
            ...state,
            isStartSpaceBoardsListSyncInProgress: true
        })
    ),
    on(
        kaitenStartSpaceBoardsListSyncSuccessAction,
        state => ({
            ...state,
            isStartSpaceBoardsListSyncInProgress: false
        }),
    ),
    on(
        kaitenStartSpaceBoardsListSyncFailureAction,
        (state) => ({
            ...state,
            isStartSpaceBoardsListSyncInProgress: false,
        }),
    ),
);

export const kaitenReducer = (state, action) => reducer(state, action);
