import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { OnboardingDataStatusEnum } from '@app-client/shared/types/onboarding-data-status.enum';
import { currentUserIsAdminSelector, currentUserSelector } from '@app-client/store/auth/selectors/auth.selectors';
import { CurrentUserInterface } from '@app-client/store/auth/types/current-user.interface';
import { onboardingStatusSelector } from '@app-client/store/tour/selectors/tour.selector';
import { TourSectionsEnum } from '@app-client/tour/types/tour-sections.enum';
import { collapseAnimation } from '@app-shared/animations/collapse.animation';


@UntilDestroy()
@Component({
    selector: 'app-complete-demo-dialog',
    templateUrl: './complete-demo-dialog.component.html',
    styleUrls: ['./complete-demo-dialog.component.scss'],
    animations: [
        collapseAnimation,
    ],
})
export class CompleteDemoDialogComponent implements OnInit {
    readonly OnboardingDataStatus = OnboardingDataStatusEnum;
    currentUser$: Observable<CurrentUserInterface>;
    isAdmin$: Observable<boolean>;
    onboardingDataStatus$: Observable<OnboardingDataStatusEnum>;

    constructor(
        public dialogRef: MatDialogRef<CompleteDemoDialogComponent>,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private store: Store,
    ) {
        this.iconRegistry.addSvgIcon(
            'attrack-complete-demo-dialog-picture',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/exit-demo-dialog-picture.svg'));
    }

    ngOnInit() {
        this.initializeVariables();
    }

    private initializeVariables() {
        this.currentUser$ = this.store.select(currentUserSelector);
        this.isAdmin$ = this.store.select(currentUserIsAdminSelector);
        this.onboardingDataStatus$ = this.store.select(onboardingStatusSelector);
    }

    close(): void {
        const route = window.location.pathname;
        const sectionRouteMap = [
            {
                link: '/activity',
                section: TourSectionsEnum.ACTIVITY,
            },
            {
                link: '/effectivity',
                section: TourSectionsEnum.EFFECTIVITY,
            },
            {
                link: '/projects',
                section: TourSectionsEnum.PROJECTS,
            },
            {
                link: '/project-spaces',
                section: TourSectionsEnum.PROJECT_SPACES,
            },
            {
                link: '/report',
                section: TourSectionsEnum.REPORTS,
            },
            {
                link: '/time-requests',
                section: TourSectionsEnum.TIME_REQUESTS,
            },
            {
                link: '/users',
                section: TourSectionsEnum.USERS,
            },
            {
                link: '/modules',
                section: TourSectionsEnum.MARKETPLACE,
            },
            {
                link: '/company',
                section: TourSectionsEnum.COMPANY_SETTINGS,
            },
            {
                link: '/payment/overview',
                section: null,
            },
            {
                link: '/payment/plans',
                section: null,
            }
        ];
        const item = sectionRouteMap.find((i) => i.link === route);

        this.dialogRef.afterClosed()
            .subscribe(() => window.location.assign(item?.link ?? window.location.href));

        this.dialogRef.close(false);
    }

}
