import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CreateProjectMemberInterface } from '@app-client/project/types/create-project-member.interface';
import { ProjectMemberDetailsInterface } from '@app-client/project/types/project-member-details.interface';
import { ProjectMemberListQueryInterface } from '@app-client/project/types/project-member-list-query.interface';
import { ProjectMemberInterface, ProjectMemberPermissionsEnum } from '@app-client/project/types/project-member.interface';
import { UpdateProjectMemberInterface } from '@app-client/project/types/update-project-member.interface';
import { PageResponseInterface } from '@app-shared/types/page-response.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';

import { environment } from '@env-client/environment';


@Injectable()
export class ProjectMembersService {
    constructor(private http: HttpClient) {}

    getProjectMembers(
        params: ProjectMemberListQueryInterface,
    ): Observable<PageResponseInterface<ProjectMemberInterface>> {
        let httpParams = new HttpParams()
            .set('limit', (params.limit || 10).toString())
            .set('page', params.page.toString());

        if ((params.search || '').trim()) {
            httpParams = httpParams.set('search', (params.search || '').trim());
        }
        if (params.project !== null && params.project !== undefined) {
            httpParams = httpParams.set('project', params.project);
        }
        if (params.user !== null && params.user !== undefined) {
            httpParams = httpParams.set('user', params.user);
        }
        if (params.projectPosition !== null && params.projectPosition !== undefined) {
            httpParams = httpParams.set('projectPosition', params.projectPosition);
        }
        if (params.projectRole !== null && params.projectRole !== undefined) {
            httpParams = httpParams.set('projectRole', params.projectRole.toString());
        }
        if (params.archived !== null && params.archived !== undefined) {
            httpParams = httpParams.set('archived', params.archived.toString());
        }
        if (params.orderBy !== null) {
            httpParams = httpParams.set('orderBy', params.orderBy);
        }
        if (params.orderDirection !== null) {
            httpParams = httpParams.set('orderDirection', params.orderDirection);
        }

        const url = environment.apiBaseUrl + '/project-members';

        return this.http.get<ResponseInterface<PageResponseInterface<ProjectMemberInterface>>>(
            url, { params: httpParams }
        ).pipe(
            map(x => {
               return x.body;
            }),
        );
    }

    public createProjectMember(createProjectMember: CreateProjectMemberInterface)
        : Observable<ResponseInterface<ProjectMemberInterface>> {
        const body = {
            user: createProjectMember.user,
            project: createProjectMember.project,
            projectRole: createProjectMember.projectRole,
            projectPosition: createProjectMember.projectPosition,
            rate: createProjectMember.rate,
        } as { [key: string]: any };
        if (createProjectMember.effectivityTemplate !== null) {
            body.effectivityTemplate = createProjectMember.effectivityTemplate;
            body.subscribed = createProjectMember.subscribed;
        }
        return this.http.post<ResponseInterface<ProjectMemberInterface>>(
            environment.apiBaseUrl + '/project-members', body
        );
    }

    public updateProjectMember(id: string, updateProjectMember: UpdateProjectMemberInterface)
        : Observable<ResponseInterface<ProjectMemberDetailsInterface>> {
        return this.http.patch<ResponseInterface<ProjectMemberDetailsInterface>>(
            environment.apiBaseUrl + '/project-members/' + id, updateProjectMember,
        );
    }

    public removeProjectMember(id: string, force?: boolean): Observable<void> {
        const url = environment.apiBaseUrl + '/project-members/' + id;
        let params = new HttpParams();

        if (force) {
            params = params.set('force', '1');
        }

        return this.http.delete<void>(url, { params });
    }

    public getProjectMember(id: string)
        : Observable<ResponseInterface<ProjectMemberDetailsInterface>> {
        return this.http.get<ResponseInterface<ProjectMemberDetailsInterface>>(
            environment.apiBaseUrl + '/project-members/' + id
        );
    }

    batchUpdatePermission(ids: Array<string>, permission: ProjectMemberPermissionsEnum, permissionAction: string): Observable<void> {
        const url = `${environment.apiBaseUrl}/project-members/update-project-members-permission`;
        const body = {
            projectMembers: ids,
            permission,
            action: permissionAction,
        };

        return this.http.patch<ResponseInterface<void>>(url, body).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }
}
