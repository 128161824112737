import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { InvoicingInvoicesCalculateRequestInterface } from '@app-client/store/invoicing/invoices/types/invoicing-invoices-calculate-request.interface';
import { InvoicingInvoicesDetailsResponseInterface } from '@app-client/store/invoicing/invoices/types/invoicing-invoices-details-response.interface';
import { InvoicingInvoicesListItemResponseInterface } from '@app-client/store/invoicing/invoices/types/invoicing-invoices-list-item-response.interface';
import { InvoicingInvoicesListRequestParamsInterface } from '@app-client/store/invoicing/invoices/types/invoicing-invoices-list-request-params.interface';
import { InvoicingInvoicesRecalculateInterface } from '@app-client/store/invoicing/invoices/types/invoicing-invoices-recalculate.interface';
import { InvoicingInvoicesSaveRequestInterface } from '@app-client/store/invoicing/invoices/types/invoicing-invoices-save-request.interface';
import { PageResponseInterface } from '@app-shared/types/page-response.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';

import { environment } from '@env-client/environment';


@Injectable()
export class InvoicingInvoicesService {
    constructor(
        private http: HttpClient,
    ) {}

    getList(
        requestParams: InvoicingInvoicesListRequestParamsInterface
    ): Observable<PageResponseInterface<InvoicingInvoicesListItemResponseInterface>> {
        const url = `${environment.apiBaseUrl}/module/invoicing/invoices`;

        let queryParams = new HttpParams();
        queryParams = queryParams.set('page', requestParams.page.toString());
        queryParams = queryParams.set('limit', requestParams.limit.toString());

        if (undefined !== requestParams.search) {
            queryParams = queryParams.set('search', requestParams.search);
        }
        if (undefined !== requestParams.statuses) {
            requestParams.statuses.forEach((status) => {
                queryParams = queryParams.append('status[]', status.toString());
            });
        }
        if (undefined !== requestParams.projects) {
            requestParams.projects.forEach((project) => {
                queryParams = queryParams.append('project[]', project);
            });
        }
        if (undefined !== requestParams.projectSpace) {
            requestParams.projectSpace.forEach((project) => {
                queryParams = queryParams.append('projectSpace[]', project);
            });
        }
        if (requestParams.from && requestParams.to) {
            queryParams = queryParams.set('from', requestParams.from.toString());
            queryParams = queryParams.set('to', requestParams.to.toString());
        }

        return this.http.get<ResponseInterface<PageResponseInterface<InvoicingInvoicesListItemResponseInterface>>>(
            url,
            { params: queryParams }
        ).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    generate(settings: InvoicingInvoicesCalculateRequestInterface): Observable<InvoicingInvoicesDetailsResponseInterface> {
        const url = `${environment.apiBaseUrl}/module/invoicing/invoices/generate`;
        const body = {
            ...settings,
        };

        return this.http.post<ResponseInterface<InvoicingInvoicesDetailsResponseInterface>>(url, body).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    recalculate(settings: InvoicingInvoicesRecalculateInterface): Observable<InvoicingInvoicesDetailsResponseInterface> {
        const url = `${environment.apiBaseUrl}/module/invoicing/invoices/recalculate`;
        const body = {
            ...settings,
        };

        return this.http.post<ResponseInterface<InvoicingInvoicesDetailsResponseInterface>>(url, body).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    save(body: InvoicingInvoicesSaveRequestInterface): Observable<InvoicingInvoicesDetailsResponseInterface> {
        const url = `${environment.apiBaseUrl}/module/invoicing/invoices`;

        return this.http.post<ResponseInterface<InvoicingInvoicesDetailsResponseInterface>>(url, body).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    delete(id: string): Observable<void> {
        const url = `${environment.apiBaseUrl}/module/invoicing/invoices/${id}`;

        return this.http.delete<void>(url);
    }

    getDetails(id: string): Observable<InvoicingInvoicesDetailsResponseInterface> {
        const url = `${environment.apiBaseUrl}/module/invoicing/invoices/${id}`;

        return this.http.get<ResponseInterface<InvoicingInvoicesDetailsResponseInterface>>(url).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    exportToGoogleSpreadsheet(id: string): Observable<void> {
        const url = `${environment.apiBaseUrl}/module/invoicing/invoices/${id}/google`;

        return this.http.post<ResponseInterface<void>>(url, {}).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    update(id: string, body: InvoicingInvoicesSaveRequestInterface): Observable<InvoicingInvoicesDetailsResponseInterface> {
        const url = `${environment.apiBaseUrl}/module/invoicing/invoices/${id}`;

        return this.http.patch<ResponseInterface<InvoicingInvoicesDetailsResponseInterface>>(url, body).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }
}
