import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TrelloBoardColumnSettingsInterface } from '@app-client/trello/types/trello-board-column-settings.interface';
import { TrelloBoardColumnInterface } from '@app-client/trello/types/trello-board-column.interface';
import { TrelloBoardInterface } from '@app-client/trello/types/trello-board.interface';
import { TrelloColumnFilteringTypeEnum, TrelloProjectStatusInterface } from '@app-client/trello/types/trello-project-status.interface';
import { TrelloUserTokenDataInterface } from '@app-client/trello/types/trello-user-token-data.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';
import { mapToVoid } from '@app-shared/utils/rxjs/map-to-void';

import { environment } from '@env-client/environment';


@Injectable()
export class TrelloService {
    constructor(private httpClient: HttpClient) {}

    authRequest(redirectUrl: string): Observable<{ url: string }> {
        const url = `${environment.apiBaseUrl}/module/trello-sync/auth`;

        return this.httpClient.get(url, {
            params: {
                redirect_url: redirectUrl,
            },
        }).pipe(
            map((response: ResponseInterface<{ url: string }>) => {
                return response.body;
            }),
        );
    }

    authVerify(token: string): Observable<void> {
        const url = `${environment.apiBaseUrl}/module/trello-sync/auth/verify`;

        return this.httpClient.post(url, { token }).pipe(mapToVoid);
    }

    getUserTokenData(): Observable<TrelloUserTokenDataInterface> {
        const url = `${environment.apiBaseUrl}/module/trello-sync/auth/me`;

        return this.httpClient.get(url).pipe(
            map((response: ResponseInterface<TrelloUserTokenDataInterface>) => {
                return response.body;
            }),
        );
    }

    logout(): Observable<void> {
        const url = `${environment.apiBaseUrl}/module/trello-sync/auth/logout`;

        return this.httpClient.post(url, {}).pipe(mapToVoid);
    }

    getProjectStatus(projectId: string): Observable<TrelloProjectStatusInterface> {
        const url = `${environment.apiBaseUrl}/module/trello-sync/project-status/${projectId}`;

        return this.httpClient.get(url).pipe(
            map((response: ResponseInterface<TrelloProjectStatusInterface>) => {
                return response.body;
            }),
        );
    }

    getUserTrelloBoards(): Observable<TrelloBoardInterface[]> {
        const url = `${environment.apiBaseUrl}/module/trello-sync/boards`;

        return this.httpClient.get(url).pipe(
            map((response: ResponseInterface<TrelloBoardInterface[]>) => {
                return response.body;
            }),
        );
    }

    getBoardColumnsByUserBoard(boardId: string): Observable<TrelloBoardColumnInterface[]> {
        const url = `${environment.apiBaseUrl}/module/trello-sync/boards/${boardId}/columns`;

        return this.httpClient.get(url).pipe(
            map((response: ResponseInterface<TrelloBoardColumnInterface[]>) => {
                return response.body;
            }),
        );
    }

    getBoardColumnsByProject(projectId: string): Observable<TrelloBoardColumnInterface[]> {
        const url = `${environment.apiBaseUrl}/module/trello-sync/projects/${projectId}/columns`;

        return this.httpClient.get(url).pipe(
            map((response: ResponseInterface<TrelloBoardColumnInterface[]>) => {
                return response.body;
            }),
        );
    }

    attachTrelloBoardToProject(
        projectId: string,
        boardId: string,
        columnFilteringType: TrelloColumnFilteringTypeEnum,
        columns: Array<{ columnName: string, columnId: string }>,
    ): Observable<void> {
        const url = `${environment.apiBaseUrl}/module/trello-sync/attach-board-to-project/${projectId}`;

        return this.httpClient.post(url, {
            boardId,
            columnFilteringType,
            columns,
        }).pipe(mapToVoid);
    }

    updateColumnSettings(projectId: string, { columnFilteringType, columns }: TrelloBoardColumnSettingsInterface): Observable<void> {
        const url = `${environment.apiBaseUrl}/module/trello-sync/update-column-settings/${projectId}`;

        return this.httpClient.put(url, {
            columnFilteringType,
            columns,
        }).pipe(mapToVoid);
    }

    detachBoardFromProject(projectId: string): Observable<void> {
        const url = `${environment.apiBaseUrl}/module/trello-sync/detach-board-from-project/${projectId}`;

        return this.httpClient.post(url, {}).pipe(mapToVoid);
    }

    startSync(projectId: string): Observable<void> {
        const url = `${environment.apiBaseUrl}/module/trello-sync/start-sync/${projectId}`;

        return this.httpClient.post(url, {}).pipe(mapToVoid);
    }

    stopSync(projectId: string): Observable<void> {
        const url = `${environment.apiBaseUrl}/module/trello-sync/stop-sync/${projectId}`;

        return this.httpClient.post(url, {}).pipe(mapToVoid);
    }
}
