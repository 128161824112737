import { createAction, props } from '@ngrx/store';

import { ShowLoggedAsInterface } from '@app-client/logged-as/types/show-logged-as.interface';
import { ActionTypes } from '@app-client/store/logged-as/action-types';


export const initLoggedAsAction = createAction(
    ActionTypes.INIT_LOGGED_AS,
);

export const showLoggedAsAction = createAction(
    ActionTypes.SHOW_LOGGED_AS,
    props<{ showLoggedAsParams: ShowLoggedAsInterface }>(),
);

export const toggleLoggedAsAction = createAction(
    ActionTypes.TOGGLE_LOGGED_AS,
    props<{ show: boolean }>(),
);
