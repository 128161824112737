import { createAction, props } from '@ngrx/store';

import { ReportWorkDayFiltersInterface } from '@app-client/report/work-day/types/report-work-day-filters.interface';
import { ReportWorkDayReportInterface } from '@app-client/report/work-day/types/report-work-day-report.interface';
import { ReportWorkDayActionTypes } from '@app-client/store/report/work-day/report-work-day-action-types';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


export const reportWorkDayGetReportAction = createAction(
    ReportWorkDayActionTypes.GET_REPORT,
    props<{ filters: ReportWorkDayFiltersInterface }>(),
);

export const reportWorkDayGetReportSuccessAction = createAction(
    ReportWorkDayActionTypes.GET_REPORT_SUCCESS,
    props<{ report: ReportWorkDayReportInterface, filters: ReportWorkDayFiltersInterface }>(),
);

export const reportWorkDayGetReportFailureAction = createAction(
    ReportWorkDayActionTypes.GET_REPORT_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const reportWorkDayExportGoogleSpreadsheetAction = createAction(
    ReportWorkDayActionTypes.EXPORT_GOOGLE_SPREADSHEET,
    props<{ filters: ReportWorkDayFiltersInterface }>(),
);

export const reportWorkDayExportGoogleSpreadsheetSuccessAction = createAction(
    ReportWorkDayActionTypes.EXPORT_GOOGLE_SPREADSHEET_SUCCESS,
);

export const reportWorkDayExportGoogleSpreadsheetFailureAction = createAction(
    ReportWorkDayActionTypes.EXPORT_GOOGLE_SPREADSHEET_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const reportWorkDayClearDataAction = createAction(
    ReportWorkDayActionTypes.CLEAR_DATA,
);
