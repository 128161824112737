import { ActionReducer, createReducer, on } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

import {
    dropDailyStatisticsAction,
    getDailyStatisticsAction,
    getDailyStatisticsFailureAction,
    getDailyStatisticsSuccessAction,
    getMonthlyStatisticsAction,
    getMonthlyStatisticsFailureAction,
    getMonthlyStatisticsSuccessAction,
    getProjectTaskMemoDataAction,
    getProjectTaskMemoDataFailureAction,
    getProjectTaskMemoDataSuccessAction,
    getScreenshotDataAction,
    getScreenshotDataFailureAction,
    getScreenshotDataSuccessAction,
    getTimelineDataAction,
    getTimelineDataFailureAction,
    getTimelineDataSuccessAction,
    getWeeklyStatisticsAction,
    getWeeklyStatisticsFailureAction,
    getWeeklyStatisticsSuccessAction,
    getWindowDataAction,
    getWindowDataFailureAction,
    getWindowDataSuccessAction,
} from '@app-client/store/activity/actions/activity-data.action';
import {
    updateDisplayFiltersAction,
} from '@app-client/store/activity/actions/activity-filters.action';
import { ActivityStateInterface } from '@app-client/store/activity/activity-state.interface';
import { DailyStatisticsInterface } from '@app-client/store/activity/types/statistics/daily-statistics.interface';
import { MonthlyStatisticsInterface } from '@app-client/store/activity/types/statistics/monthly-statistics.interface';
import { WeeklyStatisticsInterface } from '@app-client/store/activity/types/statistics/weekly-statistics.interface';
import { logoutAction } from '@app-client/store/auth/actions/login.action';
import {
    setSharedDataFiltersAction,
    setSharedDataFiltersDayAction,
    setSharedDataFiltersProjectAction, setSharedDataFiltersTaskAction,
    setSharedDataFiltersUserAction,
} from '@app-client/store/shared-data-filters/actions/shared-data-filters.action';


const initialDailyStatistics: DailyStatisticsInterface = {
    day: {
        total: 0,
        accounted: {
            active: 0,
            inactive: 0,
            requested: 0,
        },
        unaccounted: {
            idle: 0,
            deleted: 0,
        },
        byActivity: {
            low: 0,
            normal: 0,
        },
        activityIndex: 0,
    },
    perMinutes: [],
    projects: [],
};

const initialWeeklyStatistics: WeeklyStatisticsInterface = {
    week: {
        total: 0,
        accounted: {
            active: 0,
            inactive: 0,
            requested: 0,
        },
        unaccounted: {
            idle: 0,
            deleted: 0,
        },
    },
    projects: [],
};

const initialMonthlyStatistics: MonthlyStatisticsInterface = {
    month: {
        total: 0,
        accounted: {
            active: 0,
            inactive: 0,
            requested: 0,
        },
        unaccounted: {
            idle: 0,
            deleted: 0,
        },
    },
    perDays: [],
};

const initialState: ActivityStateInterface = {
    // utility
    isDailyStatisticsLoading: false,
    isWeeklyStatisticsLoading: false,
    isMonthlyStatisticsLoading: false,
    isProjectTaskMemoDataLoading: false,
    isWindowDataLoading: false,
    isScreenshotDataLoading: false,
    isTimelineDataPersist: false,

    // filters
    displayFilters: {
        screenshots: true,
        activity: true,
        apps: true,
        allTime: false,
    },

    // data
    dailyStatistics: {
        ...initialDailyStatistics,
    },
    weeklyStatistics: {
        ...initialWeeklyStatistics,
    },
    monthlyStatistics: {
        ...initialMonthlyStatistics,
    },
    projectTaskMemoData: [],
    windowData: [],
    screenshotData: [],
    statisticPerDay: {},
};

const reducer = createReducer<ActivityStateInterface>(
    initialState,
    on(
        getDailyStatisticsAction,
        state => ({
            ...state,
            isDailyStatisticsLoading: true,
            isTimelineDataPersist: false,
        }),
    ),
    on(
        getDailyStatisticsSuccessAction,
        (state, action) => {
            const newStatisticPerDay = { ...state.statisticPerDay };
            newStatisticPerDay[action.day.unix().toString()] = {
                accounted: {
                    active: action.statistics.day.accounted.active,
                    inactive: action.statistics.day.accounted.inactive,
                    requested: action.statistics.day.accounted.requested,
                },
                total: action.statistics.day.total,
                unaccounted: {
                    deleted: action.statistics.day.unaccounted.deleted,
                    idle: action.statistics.day.unaccounted.idle,
                },
                timeRate: 60 * 60 * 8,
            };

            return {
                ...state,
                isDailyStatisticsLoading: false,
                dailyStatistics: action.statistics,
                statisticPerDay: newStatisticPerDay,
            };
        },
    ),
    on(
        getDailyStatisticsFailureAction,
        state => ({
            ...state,
            isDailyStatisticsLoading: false,
            dailyStatistics: {
                ...initialDailyStatistics,
            }
        }),
    ),

    on(
        getWeeklyStatisticsAction,
        state => ({
            ...state,
            isWeeklyStatisticsLoading: true,
        }),
    ),
    on(
        getWeeklyStatisticsSuccessAction,
        (state, action) => ({
            ...state,
            isWeeklyStatisticsLoading: false,
            weeklyStatistics: action.statistics,
        }),
    ),
    on(
        getWeeklyStatisticsFailureAction,
        state => ({
            ...state,
            isWeeklyStatisticsLoading: false,
            weeklyStatistics: {
                ...initialWeeklyStatistics,
            },
        }),
    ),

    on(
        getMonthlyStatisticsAction,
        state => ({
            ...state,
            isMonthlyStatisticsLoading: true,
        }),
    ),
    on(
        getMonthlyStatisticsSuccessAction,
        (state, action) => {
            const newStatisticPerDay = { ...state.statisticPerDay };
            action.statistics.forEach(statistic => {
                statistic.perDays.map(day => {
                    newStatisticPerDay[day.begin.toString()] = {
                        accounted: {
                            active: day.accounted.active,
                            inactive: day.accounted.inactive,
                            requested: day.accounted.requested,
                        },
                        timeRate: day.timeRate,
                        total: day.total,
                        unaccounted: {
                            deleted: day.unaccounted.deleted,
                            idle: day.unaccounted.idle,
                        }
                    };
                });
            });

            return {
                ...state,
                isMonthlyStatisticsLoading: false,
                statisticPerDay: newStatisticPerDay,
            };
        },
    ),
    on(
        getMonthlyStatisticsFailureAction,
        state => ({
            ...state,
            isMonthlyStatisticsLoading: false,
            monthlyStatistics: {
                ...initialMonthlyStatistics,
            },
        }),
    ),

    on(
        getProjectTaskMemoDataAction,
        state => ({
            ...state,
            isProjectTaskMemoDataLoading: true,
            isTimelineDataPersist: false,
        }),
    ),
    on(
        getProjectTaskMemoDataSuccessAction,
        (state, action) => ({
            ...state,
            isProjectTaskMemoDataLoading: false,
            projectTaskMemoData: action.projectTaskMemoData,
        }),
    ),
    on(
        getProjectTaskMemoDataFailureAction,
        state => ({
            ...state,
            isProjectTaskMemoDataLoading: false,
        }),
    ),

    on(
        getWindowDataAction,
        state => ({
            ...state,
            isWindowDataLoading: true,
            isTimelineDataPersist: false,
        }),
    ),
    on(
        getWindowDataSuccessAction,
        (state, action) => ({
            ...state,
            isWindowDataLoading: false,
            windowData: action.windowData,
        }),
    ),
    on(
        getWindowDataFailureAction,
        state => ({
            ...state,
            isWindowDataLoading: false,
        }),
    ),

    on(
        getScreenshotDataAction,
        state => ({
            ...state,
            screenshotData: [],
            isScreenshotDataLoading: true,
            isTimelineDataPersist: false,
        }),
    ),
    on(
        getScreenshotDataSuccessAction,
        (state, action) => ({
            ...state,
            isScreenshotDataLoading: false,
            screenshotData: action.screenshotData,
        }),
    ),
    on(
        getScreenshotDataFailureAction,
        state => ({
            ...state,
            isScreenshotDataLoading: false,
        }),
    ),

    on(
        updateDisplayFiltersAction,
        (state, action) => ({
            ...state,
            displayFilters: {
                ...action.newFilters,
            },
        }),
    ),

    on(
        getTimelineDataAction,
        (state) => ({
            ...state,
            isDailyStatisticsLoading: true,
            isProjectTaskMemoDataLoading: true,
            isWindowDataLoading: true,
            isScreenshotDataLoading: true,
        })
    ),
    on(
        getTimelineDataSuccessAction,
        (state, action) => ({
            ...state,
            isDailyStatisticsLoading: false,
            dailyStatistics: action.dailyStatisticData,
            isProjectTaskMemoDataLoading: false,
            projectTaskMemoData: action.projectTaskMemoData,
            isWindowDataLoading: false,
            windowData: action.windowData,
            isScreenshotDataLoading: false,
            screenshotData: action.screenshotData,
            isTimelineDataPersist: true,
        }),
    ),
    on(
        getTimelineDataFailureAction,
        (state) => ({
            ...state,
            isDailyStatisticsLoading: false,
            dailyStatistics: {
                ...initialDailyStatistics
            },
            isProjectTaskMemoDataLoading: false,
            projectTaskMemoData: [],
            isWindowDataLoading: false,
            windowData: [],
            isScreenshotDataLoading: false,
            screenshotData: [],
            isTimelineDataPersist: true,
        }),
    ),

    on(
        setSharedDataFiltersDayAction,
        setSharedDataFiltersUserAction,
        setSharedDataFiltersProjectAction,
        setSharedDataFiltersTaskAction,
        setSharedDataFiltersAction,
        (state) => ({
            ...state,
            isTimelineDataPersist: false,
        }),
    ),

    on(
        logoutAction,
        dropDailyStatisticsAction,
        (state) => ({
                ...state,
                statisticPerDay: {},
        }),
    ),
);

export const activityReducer = (state, action) => reducer(state, action);
export const activityLocalStorageSyncReducer = (r: ActionReducer<any>): ActionReducer<any> => {
    return localStorageSync({
        keys: [
            {
                activity: ['displayFilters'],
            },
        ],
        rehydrate: true,
    })(r);
};

