import { createReducer, on } from '@ngrx/store';

import {
    createEffectivityTemplateAction,
    createEffectivityTemplateFailureAction,
    createEffectivityTemplateSuccessAction,
    deleteEffectivityTemplateAction, deleteEffectivityTemplateFailureAction,
    deleteEffectivityTemplateSuccessAction,
    getEffectivityTemplateDetailsAction,
    getEffectivityTemplateDetailsFailureAction,
    getEffectivityTemplateDetailsSuccessAction,
    getEffectivityTemplateListAction,
    getEffectivityTemplateListFailureAction,
    getEffectivityTemplateListSuccessAction,
    setEffectivityTemplateQueryParamsAction,
    updateEffectivityTemplateAction,
    updateEffectivityTemplateFailureAction,
    updateEffectivityTemplateSuccessAction,
} from '@app-client/store/effectivity-template/actions/effectivity-template.actions';
import { EffectivityTemplateStateInterface } from '@app-client/store/effectivity-template/effectivity-template-state.interface';


const initialState: EffectivityTemplateStateInterface = {
    createEffectivityTemplateBackendErrors: null,
    effectivityTemplateDetails: null,
    effectivityTemplateList: [],
    effectivityTemplateListQueryParams: null,
    isCreateEffectivityTemplateInProgress: false,
    isDeleteEffectivityTemplateInProgress: false,
    isEffectivityTemplateDetailsLoading: false,
    isEffectivityTemplateListLoading: false,
    hasMoreEffectivityTemplate: false,
    isUpdateEffectivityTemplateInProgress: false,
    updateEffectivityTemplateBackendErrors: null,
};

const reducer = createReducer(
    initialState,
    on(
        setEffectivityTemplateQueryParamsAction,
        (state, action) => ({
            ...state,
            effectivityTemplateListQueryParams: action.params,
        }),
    ),

    on(
        getEffectivityTemplateListAction,
        (state) => ({
            ...state,
            isEffectivityTemplateListLoading: true,
            effectivityTemplateList: state.effectivityTemplateListQueryParams.page === 1 ? [] : state.effectivityTemplateList,
        })
    ),

    on(
        getEffectivityTemplateListSuccessAction,
        (state, action) => ({
            ...state,
            isEffectivityTemplateListLoading: false,
            effectivityTemplateList: [...state.effectivityTemplateList, ...action.response.items],
            hasMoreEffectivityTemplate: action.response.page < action.response.totalPages,
        }),
    ),

    on(
        getEffectivityTemplateListFailureAction,
        (state) => ({
            ...state,
            isEffectivityTemplateListLoading: false,
            hasMoreEffectivityTemplate: false,
        }),
    ),

    on(
        getEffectivityTemplateDetailsAction,
        (state) => ({
            ...state,
            isEffectivityTemplateDetailsLoading: true,
            effectivityTemplateDetails: null,
        }),
    ),

    on(
        getEffectivityTemplateDetailsSuccessAction,
        (state, action) => ({
            ...state,
            isEffectivityTemplateDetailsLoading: false,
            effectivityTemplateDetails: action.response.body,
        }),
    ),

    on(
        getEffectivityTemplateDetailsFailureAction,
        (state) => ({
            ...state,
            isEffectivityTemplateDetailsLoading: false,
        }),
    ),

    on(
        createEffectivityTemplateAction,
        (state) => ({
            ...state,
            isCreateEffectivityTemplateInProgress: true,
        }),
    ),

    on(
        createEffectivityTemplateSuccessAction,
        (state) => ({
            ...state,
            isCreateEffectivityTemplateInProgress: false,
        }),
    ),

    on(
        createEffectivityTemplateFailureAction,
        (state, action) => ({
            ...state,
            isCreateEffectivityTemplateInProgress: false,
            createEffectivityTemplateBackendErrors: action.backendErrors,
        }),
    ),

    on(
        updateEffectivityTemplateAction,
        (state) => ({
            ...state,
            isUpdateEffectivityTemplateInProgress: true,
        }),
    ),

    on(
        updateEffectivityTemplateSuccessAction,
        (state) => ({
            ...state,
            isUpdateEffectivityTemplateInProgress: false,
        }),
    ),

    on(
        updateEffectivityTemplateFailureAction,
        (state, action) => ({
            ...state,
            isUpdateEffectivityTemplateInProgress: false,
            updateEffectivityTemplateBackendErrors: action.backendErrors,
        }),
    ),

    on(
        deleteEffectivityTemplateAction,
        (state) => ({
            ...state,
            isDeleteEffectivityTemplateInProgress: true,
        }),
    ),

    on(
        deleteEffectivityTemplateSuccessAction,
        (state) => ({
            ...state,
            isDeleteEffectivityTemplateInProgress: false,
        }),
    ),

    on(
        deleteEffectivityTemplateFailureAction,
        (state) => ({
            ...state,
            isDeleteEffectivityTemplateInProgress: false,
        }),
    ),
);

export const effectivityTemplateReducer = (state, action) => reducer(state, action);
