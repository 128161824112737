export enum PositionsActionTypes {
    SET_COMPANY_POSITIONS_QUERY_PARAMS = '[Company/Positions] Set company positions query params',

    GET_COMPANY_POSITIONS = '[Company/Positions] Get company positions',
    GET_COMPANY_POSITIONS_SUCCESS = '[Company/Positions] Get company positions success',
    GET_COMPANY_POSITIONS_FAILURE = '[Company/Positions] Get company positions failure',

    CREATE_COMPANY_POSITION = '[Company/Positions] Create company position',
    CREATE_COMPANY_POSITION_SUCCESS = '[Company/Positions] Create company position success',
    CREATE_COMPANY_POSITION_FAILURE = '[Company/Positions] Create company position failure',

    UPDATE_COMPANY_POSITION = '[Company/Positions] Update company position',
    UPDATE_COMPANY_POSITION_SUCCESS = '[Company/Positions] Update company position success',
    UPDATE_COMPANY_POSITION_FAILURE = '[Company/Positions] Update company position failure',

    DELETE_COMPANY_POSITION = '[Company/Positions] Delete company position',
    DELETE_COMPANY_POSITION_SUCCESS = '[Company/Positions] Delete company position success',
    DELETE_COMPANY_POSITION_FAILURE = '[Company/Positions] Delete company position failure',
}
