import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, switchMap, take, withLatestFrom } from 'rxjs/operators';

import { SelectorConstants } from '@app-client/shared/constants/selector.constants';
import { QueryArchivedEnum } from '@app-client/shared/types/query-archived.enum';
import { UserRoleEnum } from '@app-client/shared/types/user-role.enum';
import { currentUserSelector } from '@app-client/store/auth/selectors/auth.selectors';
import { UsersService } from '@app-client/store/users/services/users.service';
import { UserListQueryInterface } from '@app-client/user/types/user-list-query.interface';
import { UserInterface } from '@app-client/user/types/user.interface';


export type USERS_SELECTOR_ALL_ELEMENT_TYPE = 'ALL';
export const USERS_SELECTOR_ALL_ELEMENT: USERS_SELECTOR_ALL_ELEMENT_TYPE = 'ALL';
export type MY_DEPARTMENT_ELEMENT_TYPE = 'MY_DEPARTMENT';
export const MY_DEPARTMENT_ELEMENT: MY_DEPARTMENT_ELEMENT_TYPE = 'MY_DEPARTMENT';

@UntilDestroy()
@Component({
    selector: 'users-selector',
    templateUrl: './users-selector.component.html',
    styleUrls: ['./users-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: UsersSelectorComponent
        }
    ],
})
export class UsersSelectorComponent implements ControlValueAccessor, AfterViewInit{
    readonly QueryArchivedEnum = QueryArchivedEnum;
    readonly USERS_SELECTOR_ALL_ELEMENT = USERS_SELECTOR_ALL_ELEMENT;
    readonly MY_DEPARTMENT_ELEMENT = MY_DEPARTMENT_ELEMENT;

    @Input()
    isMultiple = true;
    @Input()
    currentUserIsFirst = false;
    @Input()
    emptyIsAll = false;
    @Input()
    isMyDepartmentSelectEnabled = false;
    @Input()
    set archived(archived: QueryArchivedEnum) {
        this.isAllowArchived = QueryArchivedEnum.INCLUDE === archived;
        this.listQueryParams$.next({
            ...this.listQueryParams$.value,
            archived,
            page: 1,
        });
    }
    @Input()
    set projectSpace(projectSpace: Array<string>) {
        this.listQueryParams$.next({
            ...this.listQueryParams$.value,
            projectSpace,
            page: 1,
        });
    }
    @Input()
    set project(project: Array<string>) {
        this.listQueryParams$.next({
            ...this.listQueryParams$.value,
            project,
            page: 1,
        });
    }
    @Input()
    set whereImManagerOrObserver(whereImManagerOrObserver: boolean) {
        this.listQueryParams$.next({
            ...this.listQueryParams$.value,
            whereImManagerOrObserver: whereImManagerOrObserver ? 1 : 0,
            page: 1,
        });
    }
    @Input()
    set whereImManager(whereImManager: boolean) {
        this.listQueryParams$.next({
            ...this.listQueryParams$.value,
            whereImManager: whereImManager ? 1 : 0,
            page: 1,
        });
    }
    @Input()
    set whereImHead(whereImHead: boolean) {
        this.listQueryParams$.next({
            ...this.listQueryParams$.value,
            whereImHead: whereImHead ? 1 : 0,
            page: 1,
        });
    }
    @Input()
    set excludeIds(excludeIds: Array<string>) {
        this.listQueryParams$.next({
            ...this.listQueryParams$.value,
            excludeIds,
            page: 1,
        });
    }
    @Input()
    set role(role: Array<UserRoleEnum>) {
        this.listQueryParams$.next({
            ...this.listQueryParams$.value,
            role,
            page: 1,
        });
    }

    private onChange: any;

    isDisabled: boolean;
    get selected(): Array<UserInterface | USERS_SELECTOR_ALL_ELEMENT_TYPE | MY_DEPARTMENT_ELEMENT_TYPE> {
        return this.selectedStore;
    }
    set selected(value: Array<UserInterface | USERS_SELECTOR_ALL_ELEMENT_TYPE | MY_DEPARTMENT_ELEMENT_TYPE>) {
        if (false === this.isMultiple) {
            this.search('');
        }
        this.selectedStore = value;
        this.onChange(value);
    }
    private selectedStore = [] as Array<UserInterface | USERS_SELECTOR_ALL_ELEMENT_TYPE | MY_DEPARTMENT_ELEMENT_TYPE>;
    list: Array<UserInterface | USERS_SELECTOR_ALL_ELEMENT_TYPE | MY_DEPARTMENT_ELEMENT_TYPE>;
    isHasMore = true;
    isLoading = false;
    listQueryParams$ = new BehaviorSubject<UserListQueryInterface>({
        search: '',
        orderBy: 'fullName',
        orderDirection: 'ASC',
        page: 1,
        limit: 20,
    });
    isAllowArchived = false;

    constructor(
        private usersService: UsersService,
        private changeDetectorRef: ChangeDetectorRef,
        private toastr: ToastrService,
        private translate: TranslateService,
        private store: Store,
    ) {}

    ngAfterViewInit(): void {
        this.listQueryParams$.pipe(
            untilDestroyed(this),
            debounceTime(SelectorConstants.DEBOUNCE_TIME),
            withLatestFrom(this.store.select(currentUserSelector)),
            switchMap(([queryParams, currentUser]) => {
                if (1 === queryParams.page) {
                    this.list = [];
                    if (true === this.emptyIsAll) {
                        this.list.push(USERS_SELECTOR_ALL_ELEMENT);
                    }
                    
                    if (this.isMyDepartmentSelectEnabled && currentUser.role === UserRoleEnum.ROLE_HEAD) {
                        this.list.push(MY_DEPARTMENT_ELEMENT);
                    }

                    if (true === this.currentUserIsFirst) {
                        this.store.select(currentUserSelector).pipe(
                            untilDestroyed(this),
                            take(1),
                        ).subscribe((currentUser) => {
                            this.list.push({
                                ...currentUser,
                                archivedAt: null,
                            });
                        });
                    }
                }
                this.isLoading = true;
                return this.usersService.getUsers(queryParams);
            }),
        ).subscribe((response) => {
            this.isHasMore = response.page < response.totalPages;

            let newPage = response.items;
            if (true === this.currentUserIsFirst) {
                this.store.select(currentUserSelector).pipe(
                    untilDestroyed(this),
                    take(1),
                ).subscribe((currentUser) => {
                    newPage = newPage.filter((user) => {
                        return user.id !== currentUser.id;
                    });
                });
            }

            this.list = [
                ...this.list,
                ...newPage,
            ];
            this.isLoading = false;
            this.changeDetectorRef.detectChanges();
        },
        (error) => {
            this.isHasMore = false;
            this.toastr.error(
                error,
                this.translate.instant('USERS.NOTIFICATIONS.GET_USERS_LIST.ERROR.TITLE'),
            );
            this.isLoading = false;
            this.changeDetectorRef.detectChanges();
        });
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {}

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    writeValue(obj: any): void {
        this.selectedStore = obj;
    }

    loadMore(): void {
        if (false === this.isHasMore) {
            return;
        }

        this.listQueryParams$.next({
            ...this.listQueryParams$.value,
            page: this.listQueryParams$.value.page + 1,
        });
    }

    toggleArchived(): void {
        this.listQueryParams$.next({
            ...this.listQueryParams$.value,
            archived: QueryArchivedEnum.INCLUDE === this.listQueryParams$.value.archived
                ? QueryArchivedEnum.EXCLUDE
                : QueryArchivedEnum.INCLUDE,
            page: 1,
        });
    }

    search(str: string): void {
        if (str !== this.listQueryParams$.value.search) {
            this.listQueryParams$.next({
                ...this.listQueryParams$.value,
                search: str,
                page: 1,
            });
        }
    }

    searchFn(): boolean {
        return true;
    }

    compareWith(a: any, b: any): boolean {
        return a?.id === b?.id;
    }
}

