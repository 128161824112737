import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProjectRoleEnum } from '@app-client/project/types/project-role.enum';
import { UserRoleEnum } from '@app-client/shared/types/user-role.enum';
import {
    currentUserIsAdminSelector,
    currentUserIsHeadSelector,
    currentUserIsManagerSelector,
    currentUserIsObserverSelector,
    currentUserIsSysadminSelector,
    currentUserSelector,
    userLanguageSelector,
} from '@app-client/store/auth/selectors/auth.selectors';
import {
    marketplaceIsEnabledHappinessIndexFeatureSelector,
    marketplaceIsEnabledInvoicingFeatureSelector,
    marketplaceModulesIsModuleEnabledSelector,
    marketplaceModulesIsTariffEnabledSelector,
} from '@app-client/store/marketplace/modules/selectors/marketplace-modules.selectors';
import { AppModulesEnum, CoreModuleTariffsEnum } from '@app-shared/types/app-modules.enum';

import { environment } from '@env-client/environment';


@UntilDestroy()
@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
    noltLink: string;

    isUserAdmin$: Observable<boolean>;
    isUserManager$: Observable<boolean>;
    isUserObserver$: Observable<boolean>;
    isUserObserverOnly$: Observable<boolean>;
    isUserSysadmin$: Observable<boolean>;
    isUserHead$: Observable<boolean>;
    isSmallHeightDevice$: Observable<boolean>;
    isEnabledCoreBusinessTariff$: Observable<boolean>;
    isEnabledInvoicingFeature$: Observable<boolean>;
    isEnabledHappinessIndexFeature$: Observable<boolean>;
    isEnabledDailyResultFeature$: Observable<boolean>;

    feedbackUrl$: Observable<string>;
    readonly feedbackUrlMap = {
        en: 'https://attrack.com/contacts',
        ru: 'https://attrack.ru/contacts',
    };

    constructor(
        private breakpointObserver: BreakpointObserver,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private store: Store,
    ) {
        this.iconRegistry.addSvgIcon(
            'attrack-activity',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/menu/activity.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'attrack-reports',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/menu/reports.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'attrack-daily-result',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/menu/daily-result.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'attrack-users',
            sanitizer.bypassSecurityTrustResourceUrl('assets/images/menu/users.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'attrack-settings',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/menu/settings.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'attrack-projects',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/menu/projects.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'attrack-project-spaces',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/menu/project-spaces.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'attrack-effectivity',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/menu/effectivity.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'attrack-time-requests',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/menu/time-requests.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'attrack-marketplace',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/menu/marketplace.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'attrack-payment',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/menu/payment.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'attrack-invoicing',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/menu/invoicing.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'attrack-happiness-index',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/happiness-index/icon.svg'),
        );
    }

    ngOnInit() {
        this.initializeVariables();
        // this.loadNolt();
    }

    private initializeVariables() {
        this.noltLink = environment.noltLink;

        this.isUserAdmin$ = this.store.select(currentUserIsAdminSelector);
        this.isUserManager$ = this.store.select(currentUserIsManagerSelector);
        this.isUserObserver$ = this.store.select(currentUserIsObserverSelector);
        this.isUserObserverOnly$ = this.store.select(currentUserSelector).pipe(
            map((currentUser) => currentUser.projectRoles.every(role => role === ProjectRoleEnum.ROLE_OBSERVER)),
        );
        this.isUserSysadmin$ = this.store.select(currentUserIsSysadminSelector);
        this.isUserHead$ = this.store.select(currentUserIsHeadSelector);
        this.isSmallHeightDevice$ = this.breakpointObserver
            .observe('(min-height: 950px)')
            .pipe(
                untilDestroyed(this),
                map((result) => !result.matches),
            );

        this.isEnabledCoreBusinessTariff$ = this.store.select(
            marketplaceModulesIsTariffEnabledSelector(AppModulesEnum.CORE, CoreModuleTariffsEnum.BUSINESS),
        );
        this.isEnabledInvoicingFeature$ = this.store.select(marketplaceIsEnabledInvoicingFeatureSelector);
        this.isEnabledHappinessIndexFeature$ = this.store.select(marketplaceIsEnabledHappinessIndexFeatureSelector);
        this.isEnabledDailyResultFeature$ = combineLatest([
            this.store.select(marketplaceModulesIsModuleEnabledSelector(AppModulesEnum.DAILY_RESULT)),
            this.store.select(currentUserSelector),
        ]).pipe(
            map(([isModule, user]) => {
                if (false === isModule) {
                    return false;
                }

                if (UserRoleEnum.ROLE_ADMIN === user.role) {
                    return true;
                }

                if (1 === user.projectRoles.length && ProjectRoleEnum.ROLE_OBSERVER === user.projectRoles[0]) {
                    return false;
                }

                return true;
            }),
        );

        this.feedbackUrl$ = this.store.select(userLanguageSelector).pipe(
            map((language) => {
                return this.feedbackUrlMap[language] || this.feedbackUrlMap.en;
            }),
        );
    }

    // private loadNolt(): void {
    //     const noltScriptNode = document.createElement('script');
    //     noltScriptNode.src = 'https://cdn.nolt.io/widgets.js';
    //     noltScriptNode.type = 'text/javascript';
    //     noltScriptNode.async = true;
    //     document.getElementsByTagName('head')[0].appendChild(noltScriptNode);
    // }
}
