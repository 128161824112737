import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


@Injectable()
export class UrlService {

    constructor(
        private router: Router,
    ) {}

    updateCurrentUrlQueryParams(queryParams) {
        this.router.navigate([], {
            queryParams,
            queryParamsHandling: 'merge',
        });
    }
}
