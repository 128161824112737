import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ProjectSpacesStateInterface } from '@app-client/store/project-spaces/project-spaces-state.interface';


export const projectSpacesFeatureSelector = createFeatureSelector<ProjectSpacesStateInterface>('projectSpaces');

export const projectSpacesSelector = createSelector(
    projectSpacesFeatureSelector,
    state => state.projectSpaces,
);

export const isProjectSpacesLoadingSelector = createSelector(
    projectSpacesFeatureSelector,
    state => state.isProjectSpacesLoading,
);

export const projectSpacesQueryConfigSelector = createSelector(
    projectSpacesFeatureSelector,
    state => state.projectSpacesQueryConfig,
);

export const isProjectSpacesLoadingMoreSelector = createSelector(
    projectSpacesFeatureSelector,
    state => state.isProjectSpacesLoadingMore,
);

export const isProjectSpaceLoadingSelector = createSelector(
    projectSpacesFeatureSelector,
    state => state.isProjectSpaceLoading,
);

export const isCreatingProjectSpaceInProgressSelector = createSelector(
    projectSpacesFeatureSelector,
    state => state.isCreatingProjectSpaceInProgress,
);

export const creatingProjectSpaceErrorsSelector = createSelector(
    projectSpacesFeatureSelector,
    state => state.creatingProjectSpaceErrors,
);

export const isUpdatingProjectSpaceInProgressSelector = createSelector(
    projectSpacesFeatureSelector,
    state => state.isUpdatingProjectSpaceInProgress,
);

export const updatingProjectSpaceErrorsSelector = createSelector(
    projectSpacesFeatureSelector,
    state => state.updatingProjectSpaceErrors,
);

export const isArchiveProjectSpaceInProgressSelector = createSelector(
    projectSpacesFeatureSelector,
    state => state.isArchiveProjectSpaceInProgress,
);

export const archivedProjectSpacesSelector = createSelector(
    projectSpacesFeatureSelector,
    state => state.archivedProjectSpaces,
);

export const isArchivedProjectSpacesLoadingSelector = createSelector(
    projectSpacesFeatureSelector,
    state => state.isArchivedProjectSpacesLoading,
);

export const archivedProjectSpacesQueryConfigSelector = createSelector(
    projectSpacesFeatureSelector,
    state => state.archivedProjectSpacesQueryConfig,
);

export const isArchivedProjectSpacesLoadingMoreSelector = createSelector(
    projectSpacesFeatureSelector,
    state => state.isArchivedProjectSpacesLoadingMore,
);

export const isArchivedProjectSpaceLoadingSelector = createSelector(
    projectSpacesFeatureSelector,
    state => state.isArchiveProjectSpaceLoading,
);

export const isRestoreProjectSpaceInProgressSelector = createSelector(
    projectSpacesFeatureSelector,
    state => state.isRestoreProjectSpaceInProgress,
);

export const projectSpaceDetailsSelector = createSelector(
    projectSpacesFeatureSelector,
    state => state.projectSpaceDetails,
);
