import { ActionReducer, createReducer, on } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

import { loginUnderUserSuccessAction } from '@app-client/store/auth/actions/login-under-user.action';
import { logoutAction } from '@app-client/store/auth/actions/login.action';
import { sharedReportFiltersSetFiltersAction } from '@app-client/store/report/shared-filters/actions/shared-report-filters.actions';
import { SharedReportFiltersStateInterface } from '@app-client/store/report/shared-filters/shared-report-filters-state.interface';


const initialState: SharedReportFiltersStateInterface = {
    projectSpaces: [],
    projects: [],
    tasks: [],
    users: [],
    interval: null,
};

const reducer = createReducer(
    initialState,
    on(
        sharedReportFiltersSetFiltersAction,
        (state, action) => ({
            ...state,
            projectSpaces: action.projectSpaces,
            projects: action.projects,
            users: action.users,
            tasks: action.tasks,
            interval: {
                from: action.from,
                to: action.to,
            },
        }),
    ),
    on(
        logoutAction,
        loginUnderUserSuccessAction,
        (state) => ({
            ...state,
            projectSpaces: [],
            projects: [],
            tasks: [],
            users: [],
            interval: null,
        }),
    ),
);

export const sharedReportFiltersReducer = (state, action) => reducer(state, action);
export const sharedReportFiltersLocalStorageSyncReducer = (r: ActionReducer<any>): ActionReducer<any> => {
    return localStorageSync({
        keys: [
            {
                sharedReportFilters: [
                    'projectSpaces',
                    'projects',
                    'tasks',
                    'users',
                    'interval',
                ],
            },
        ],
        rehydrate: true,
    })(r);
};
