import { createReducer, on } from '@ngrx/store';

import { JiraSyncTypeEnum } from '@app-client/jira/types/jira-sync-type.enum';
import { jiraLogoutSuccessAction } from '@app-client/store/jira/actions/jira-auth.actions';
import {
    jiraAttachAction,
    jiraAttachFailureAction,
    jiraAttachSuccessAction,
    jiraGetBoardsAction,
    jiraGetBoardsFailureAction,
    jiraGetBoardsSuccessAction,
    jiraGetEpicsAction,
    jiraGetEpicsFailureAction,
    jiraGetEpicsSuccessAction,
    jiraGetIssueTypesAction,
    jiraGetIssueTypesFailureAction,
    jiraGetIssueTypesSuccessAction,
    jiraGetProjectsAction,
    jiraGetProjectsFailureAction,
    jiraGetProjectsSuccessAction,
    jiraGetProjectStatusAction,
    jiraGetProjectStatusFailureAction,
    jiraGetProjectStatusSuccessAction,
    jiraGetSprintsAction,
    jiraGetSprintsFailureAction,
    jiraGetSprintsSuccessAction,
    jiraGetStatusesAction,
    jiraGetStatusesFailureAction,
    jiraGetStatusesSuccessAction,
    jiraGetSyncStatusSuccessAction, jiraSetSyncTypeAction,
    jiraStartSyncAction,
    jiraStartSyncFailureAction,
    jiraStartSyncSuccessAction,
    jiraStartSyncTimeAction,
    jiraStartSyncTimeFailureAction,
    jiraStartSyncTimeSuccessAction,
    jiraUpdateAction,
    jiraUpdateFailureAction,
    jiraUpdateSuccessAction,
    jiraUpdateSyncTimeSettingsAction,
    jiraUpdateSyncTimeSettingsFailureAction,
    jiraUpdateSyncTimeSettingsSuccessAction,
} from '@app-client/store/jira/actions/jira-connection.actions';
import { JiraConnectionStateInterface } from '@app-client/store/jira/jira-connection-state.interface';


const initialState: JiraConnectionStateInterface = {
    projectStatus: null,
    isProjectStatusLoading: false,
    projects: [],
    isProjectsLoading: false,
    statuses: [],
    isStatusesLoading: false,
    issueTypes: [],
    isIssueTypesLoading: false,
    boards: [],
    isBoardsLoading: false,
    epics: [],
    isEpicsLoading: false,
    sprints: [],
    isSprintsLoading: false,
    isAttachInProgress: false,
    isDetachInProgress: false,
    isUpdateInProgress: false,
    isSyncInProgress: false,
    isUpdateSyncInProgress: false,
    isSumTimeByTask: false,
    isSyncTaskMemo: false,
    syncType: JiraSyncTypeEnum.NONE
};

const reducer = createReducer(
    initialState,
    on(
        jiraGetProjectStatusAction,
        (state) => ({
            ...state,
            projectStatus: null,
            isProjectStatusLoading: true,
            isSyncInProgress: false,
            isUpdateSyncInProgress: true,
        }),
    ),

    on(
        jiraGetProjectStatusSuccessAction,
        (state, action) => ({
            ...state,
            projectStatus: action.projectStatus,
            isProjectStatusLoading: false,
            isSyncInProgress: action.projectStatus ? action.projectStatus.syncStatus !== 0 : false,
            isUpdateSyncInProgress: false,
        }),
    ),

    on(
        jiraGetProjectStatusFailureAction,
        (state, action) => ({
            ...state,
            isProjectStatusLoading: false,
            isUpdateSyncInProgress: false,
        }),
    ),

    on(
        jiraGetProjectsAction,
        (state) => ({
            ...state,
            projects: [],
            isProjectsLoading: true,
        }),
    ),

    on(
        jiraGetProjectsSuccessAction,
        (state, action) => ({
            ...state,
            projects: action.projects,
            isProjectsLoading: false,
        }),
    ),

    on(
        jiraGetProjectsFailureAction,
        (state) => ({
            ...state,
            projects: null,
            isProjectsLoading: false,
        })
    ),

    on(
        jiraGetStatusesAction,
        (state) => ({
            ...state,
            statuses: [],
            isStatusesLoading: true,
        }),
    ),

    on(
        jiraGetStatusesSuccessAction,
        (state, action) => ({
            ...state,
            statuses: action.statuses,
            isStatusesLoading: false,
        }),
    ),

    on(
        jiraGetStatusesFailureAction,
        (state) => ({
            ...state,
            isStatusesLoading: false,
        }),
    ),

    on(
        jiraGetIssueTypesAction,
        (state) => ({
            ...state,
            issueTypes: [],
            isIssueTypesLoading: true,
        }),
    ),

    on(
        jiraGetIssueTypesSuccessAction,
        (state, action) => ({
            ...state,
            issueTypes: action.issueTypes,
            isIssueTypesLoading: false,
        }),
    ),

    on(
        jiraGetIssueTypesFailureAction,
        (state) => ({
            ...state,
            isIssueTypesLoading: false,
        }),
    ),

    on(
        jiraGetBoardsAction,
        (state) => ({
            ...state,
            boards: [],
            isBoardsLoading: true,
        }),
    ),

    on(
        jiraGetBoardsSuccessAction,
        (state, action) => ({
            ...state,
            boards: action.boards,
            isBoardsLoading: false,
        }),
    ),

    on(
        jiraGetBoardsFailureAction,
        (state) => ({
            ...state,
            isBoardsLoading: false,
        }),
    ),

    on(
        jiraGetEpicsAction,
        (state) => ({
            ...state,
            epics: [],
            isEpicsLoading: true,
        }),
    ),

    on(
        jiraGetEpicsSuccessAction,
        (state, action) => ({
            ...state,
            epics: action.epics,
            isEpicsLoading: false,
        }),
    ),

    on(
        jiraGetEpicsFailureAction,
        (state) => ({
            ...state,
            isEpicsLoading: false,
        }),
    ),

    on(
        jiraGetSprintsAction,
        (state) => ({
            ...state,
            sprints: [],
            isSprintsLoading: true,
        }),
    ),

    on(
        jiraGetSprintsSuccessAction,
        (state, action) => ({
            ...state,
            sprints: action.sprints,
            isSprintsLoading: false,
        }),
    ),

    on(
        jiraGetSprintsFailureAction,
        (state) => ({
            ...state,
            isSprintsLoading: false,
        }),
    ),

    on(
        jiraAttachAction,
        (state ) => ({
            ...state,
            isAttachInProgress: true,
        }),
    ),

    on(
        jiraAttachSuccessAction,
        jiraAttachFailureAction,
        (state) => ({
            ...state,
            isAttachInProgress: false,
        }),
    ),

    on(
        jiraUpdateAction,
        (state) => ({
            ...state,
            isUpdateInProgress: true,
        }),
    ),

    on(
        jiraUpdateSuccessAction,
        jiraUpdateFailureAction,
        (state) => ({
            ...state,
            isUpdateInProgress: false,
        }),
    ),

    on(
        jiraStartSyncAction,
        (state) => ({
            ...state,
            isUpdateSyncInProgress: true,
        }),
    ),

    on(
        jiraStartSyncSuccessAction,
        (state) => ({
            ...state,
            isSyncInProgress: true,
            isUpdateSyncInProgress: false,
        }),
    ),

    on(
        jiraStartSyncFailureAction,
        (state) => ({
            ...state,
            isUpdateSyncInProgress: false,
        }),
    ),

    on(
        jiraGetSyncStatusSuccessAction,
        (state, action) => ({
            ...state,
            isSyncInProgress: action.syncStatus.syncStatus,
            syncType: action.syncStatus.syncType,
        }),
    ),

    on(
        jiraLogoutSuccessAction,
        (state, action) => ({
            ...state,
            projectStatus: action.connectionId === state.projectStatus?.connectId ? null : state.projectStatus,
        }),
    ),

    on (
        jiraUpdateSyncTimeSettingsAction,
        (state) => ({
            ...state,
            isSyncInProgress: true,
            isUpdateSyncInProgress: true,
        })
    ),

    on (
        jiraUpdateSyncTimeSettingsSuccessAction,
        (state) => ({
            ...state,
            isSyncInProgress: true,
            isUpdateSyncInProgress: true,
        })
    ),

    on (
        jiraUpdateSyncTimeSettingsFailureAction,
        (state) => ({
            ...state,
            isSyncInProgress: true,
            isUpdateSyncInProgress: true,
        })
    ),

    on (
        jiraStartSyncTimeAction,
        (state ) => ({
            ...state,
            isSyncInProgress: true,
            isUpdateSyncInProgress: true,
        })
    ),

    on(
        jiraStartSyncTimeSuccessAction,
        (state) => ({
            ...state,
            isUpdateSyncInProgress: false,
        }),
    ),

    on(
        jiraStartSyncTimeFailureAction,
        (state) => ({
            ...state,
            isUpdateSyncInProgress: false,
            syncType: JiraSyncTypeEnum.NONE,
        }),
    ),

    on(
        jiraSetSyncTypeAction,
        (state, action) => ({
            ...state,
            syncType: action.syncType,
        }),
    ),
);

export const jiraConnectionReducer = (state, action) => reducer(state, action);
