import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import {
    createCompanyPositionAction,
    createCompanyPositionFailureAction,
    createCompanyPositionSuccessAction,
    deleteCompanyPositionAction,
    deleteCompanyPositionFailureAction,
    deleteCompanyPositionSuccessAction,
    getCompanyPositionsAction,
    getCompanyPositionsFailureAction,
    getCompanyPositionsSuccessAction,
    setCompanyPositionsQueryParamsAction,
    updateCompanyPositionAction,
    updateCompanyPositionFailureAction,
    updateCompanyPositionSuccessAction,
} from '@app-client/store/company/actions/company-positions.action';
import { companyPositionsQueryConfigSelector } from '@app-client/store/company/selectors/company-positions.selector';
import { CompanyPositionsService } from '@app-client/store/company/services/company-positions.service';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class CompanyPositionsEffect {

    getCompanyPositions$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getCompanyPositionsAction),
            withLatestFrom(this.store$.select(companyPositionsQueryConfigSelector)),
            switchMap(([, queryParams]) => {
                return this.companyPositionsService.getCompanyPositions(queryParams).pipe(
                    map(response => {
                        return getCompanyPositionsSuccessAction({ response });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(getCompanyPositionsFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getCompanyPositionsFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getCompanyPositionsFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('COMPANY_SETTINGS.PROJECT_POSITIONS.NOTIFICATIONS.GET_POSITIONS.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    createCompanyPosition$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createCompanyPositionAction),
            switchMap(({ name }) => {
                return this.companyPositionsService.createCompanyPosition({ name }).pipe(
                    map((newPosition) => {
                        return createCompanyPositionSuccessAction({ newPosition });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(createCompanyPositionFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    createCompanyPositionSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createCompanyPositionSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('COMPANY_SETTINGS.PROJECT_POSITIONS.NOTIFICATIONS.CREATE_POSITION.SUCCESS.MESSAGE'),
                    this.translate.instant('COMPANY_SETTINGS.PROJECT_POSITIONS.NOTIFICATIONS.CREATE_POSITION.SUCCESS.TITLE'),
                );
            }),
            switchMap(() => {
                return this.store$.select(companyPositionsQueryConfigSelector).pipe(
                    take(1),
                    switchMap(config => {
                        this.dialogRef.closeAll();

                        return [
                            setCompanyPositionsQueryParamsAction({
                                queryParams: {
                                    ...config,
                                    page: 1,
                                },
                            }),
                            getCompanyPositionsAction(),
                        ];
                    }),
                );
            }),
        );
    });

    createCompanyPositionFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createCompanyPositionFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('COMPANY_SETTINGS.PROJECT_POSITIONS.NOTIFICATIONS.CREATE_POSITION.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    updateCompanyPosition$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateCompanyPositionAction),
            switchMap(({ id, name }) => {
                return this.companyPositionsService.updateCompanyPosition(id, { name }).pipe(
                    map((updatedPosition) => {
                        return updateCompanyPositionSuccessAction({ updatedPosition });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(updateCompanyPositionFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    updateCompanyPositionSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateCompanyPositionSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('COMPANY_SETTINGS.PROJECT_POSITIONS.NOTIFICATIONS.UPDATE_POSITION.SUCCESS.MESSAGE'),
                    this.translate.instant('COMPANY_SETTINGS.PROJECT_POSITIONS.NOTIFICATIONS.UPDATE_POSITION.SUCCESS.TITLE'),
                );
            }),
            switchMap(() => {
                return this.store$.select(companyPositionsQueryConfigSelector).pipe(
                    take(1),
                    switchMap(config => {
                        this.dialogRef.closeAll();

                        return [
                            setCompanyPositionsQueryParamsAction({
                                queryParams: {
                                    ...config,
                                    page: 1,
                                },
                            }),
                            getCompanyPositionsAction(),
                        ];
                    }),
                );
            }),
        );
    });

    updateCompanyPositionFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateCompanyPositionFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('COMPANY_SETTINGS.PROJECT_POSITIONS.NOTIFICATIONS.UPDATE_POSITION.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    deleteCompanyPosition$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteCompanyPositionAction),
            switchMap(({ id }) => {
                return this.companyPositionsService.deleteCompanyPosition(id).pipe(
                    map(() => {
                        return deleteCompanyPositionSuccessAction();
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(deleteCompanyPositionFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    deleteCompanyPositionSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteCompanyPositionSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('COMPANY_SETTINGS.PROJECT_POSITIONS.NOTIFICATIONS.DELETE_POSITION.SUCCESS.MESSAGE'),
                    this.translate.instant('COMPANY_SETTINGS.PROJECT_POSITIONS.NOTIFICATIONS.DELETE_POSITION.SUCCESS.TITLE'),
                );
            }),
            switchMap(() => {
                return this.store$.select(companyPositionsQueryConfigSelector).pipe(
                    take(1),
                    switchMap(config => {
                        return [
                            setCompanyPositionsQueryParamsAction({
                                queryParams: {
                                    ...config,
                                    page: 1,
                                },
                            }),
                            getCompanyPositionsAction(),
                        ];
                    }),
                );
            }),
        );
    });

    deleteCompanyPositionFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteCompanyPositionFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('COMPANY_SETTINGS.PROJECT_POSITIONS.NOTIFICATIONS.DELETE_POSITION.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    constructor(
        private actions$: Actions,
        private companyPositionsService: CompanyPositionsService,
        private store$: Store,
        private toastr: ToastrService,
        private translate: TranslateService,
        private dialogRef: MatDialog,
    ) {}
}
