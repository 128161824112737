import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    YearlyReportInterface
} from '@app-client/store/marketplace/modules/types/yearly-report.interface';
import { PageResponseInterface } from '@app-shared/types/page-response.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';

import { environment } from '@env-client/environment';

@Injectable()
export class YearlyReportsService {
    constructor(
        private http: HttpClient,
    ) {}

    public getList(): Observable<PageResponseInterface<YearlyReportInterface>> {
        const url = `${environment.apiBaseUrl}/yearly-report/`;

        return this.http.get<ResponseInterface<PageResponseInterface<YearlyReportInterface>>>(url).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }
}
