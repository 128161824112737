import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TimeFormatEnum } from '@app-client/report/by-projects/types/report-filters.interface';
import { ReportWorkDayFiltersInterface } from '@app-client/report/work-day/types/report-work-day-filters.interface';
import { ReportWorkDayReportInterface } from '@app-client/report/work-day/types/report-work-day-report.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';

import { environment } from '@env-client/environment';


@Injectable()
export class ReportWorkDayService {
    constructor(
        private http: HttpClient,
    ) {}

    getReport(filters: ReportWorkDayFiltersInterface): Observable<ReportWorkDayReportInterface> {
        const url = `${environment.apiBaseUrl}/reports/work-day`;
        const body = {
            from: filters.interval.from.unix(),
            to: filters.interval.to.unix(),
            projectSpaces: filters.projectSpaceIds,
            users: filters.userIds,
            projects: filters.projectIds,
            valuesType:  filters.interval.to.isSame(filters.interval.from.clone().add(1, 'day')) ? 0 : filters.variablesType,
            myEmployees: filters.myEmployees,
        };

        return this.http.post<ResponseInterface<ReportWorkDayReportInterface>>(url, body).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    exportGoogleSpreadsheet(filters: ReportWorkDayFiltersInterface): Observable<void> {
        const url = `${environment.apiBaseUrl}/reports/work-day/google`;
        const body = {
            from: filters.interval.from.unix(),
            to: filters.interval.to.unix(),
            projectSpaces: filters.projectSpaceIds,
            users: filters.userIds,
            projects: filters.projectIds,
            valuesType:  filters.interval.to.isSame(filters.interval.from.clone().add(1, 'day')) ? 0 : filters.variablesType,
            timeFormat: ((format: TimeFormatEnum) => {
                switch (format) {
                    case TimeFormatEnum.HUMAN:
                        return 0;
                    case TimeFormatEnum.HOURS:
                        return 1;
                    case TimeFormatEnum.MINUTES:
                        return 2;
                    case TimeFormatEnum.HOURS_WITHOUT_LABELS:
                        return 3;
                }
            })(filters.timeFormat),
            myEmployees: filters.myEmployees,
        };

        return this.http.post<ResponseInterface<void>>(url, body).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }
}
