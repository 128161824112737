import { createFeatureSelector, createSelector } from '@ngrx/store';

import { MarketplaceModulesStateInterface } from '@app-client/store/marketplace/modules/marketplace-modules-state.interface';
import { AppModulesEnum, CoreModuleTariffsEnum } from '@app-shared/types/app-modules.enum';


export const marketplaceModulesFeatureSelector = createFeatureSelector<MarketplaceModulesStateInterface>('marketplaceModules');

export const marketplaceModulesIsListLoadingSelector = createSelector(
    marketplaceModulesFeatureSelector,
    state => state.isModulesLoading,
);

export const marketplaceModulesListSelector = createSelector(
    marketplaceModulesFeatureSelector,
    state => state.modules,
);

export const marketplaceModulesIsDetailsLoadingSelector = createSelector(
    marketplaceModulesFeatureSelector,
    state => state.isDetailsLoading,
);

export const marketplaceModulesIsEnabledLoadingSelector = createSelector(
    marketplaceModulesFeatureSelector,
    state => state.isEnabledLoading,
);

export const marketplaceModulesDetailsSelector = createSelector(
    marketplaceModulesFeatureSelector,
    state => state.details,
);

export const marketplaceModulesIsInstallInProgressSelector = createSelector(
    marketplaceModulesFeatureSelector,
    state => state.isInstallInProgress,
);

export const marketplaceModulesIsUninstallInProgressSelector = createSelector(
    marketplaceModulesFeatureSelector,
    state => state.isUninstallInProgress,
);

export const marketplaceModulesIsUpdateInProgressSelector = createSelector(
    marketplaceModulesFeatureSelector,
    state => state.isUpdatePlanInProgress,
);

export const marketplaceModulesIsActivatedUsersLoadingSelector = createSelector(
    marketplaceModulesFeatureSelector,
    state => state.isActivatedUsersLoading,
);

export const marketplaceModulesActivatedUsersSelector = createSelector(
    marketplaceModulesFeatureSelector,
    state => state.activatedUsers,
);

export const marketplaceModulesIsModuleEnabledSelector = (moduleId: AppModulesEnum) => createSelector(
    marketplaceModulesFeatureSelector,
    state => state.enabled.some((module) => module.moduleId === moduleId),
);

export const marketplaceModulesIsTariffEnabledSelector = (moduleId: string, tariffId: string) => createSelector(
    marketplaceModulesFeatureSelector,
    state => !!state.enabled.find((module) => {
        return module.moduleId === moduleId && module.tariffId === tariffId;
    }),
);

export const marketplaceEnabledCoreModuleTariffSelector = createSelector(
    marketplaceModulesFeatureSelector,
    state => state.enabled.find((module) => module.moduleId === AppModulesEnum.CORE)?.tariffId ?? null,
);

export const marketplaceIsEnabledActivityFeatureSelector = createSelector(
    marketplaceModulesFeatureSelector,
    state => {
        const coreTariff = state.enabled.find(
            (module) => module.moduleId === AppModulesEnum.CORE
        )?.tariffId ?? null;

        switch (coreTariff) {
          case CoreModuleTariffsEnum.BUSINESS:
          case CoreModuleTariffsEnum.PRO:
              return true;
          case CoreModuleTariffsEnum.BASIC:
          default:
              return false;
        }
    }
);

export const marketplaceIsEnabledEffectivityFeatureSelector = createSelector(
    marketplaceModulesFeatureSelector,
    state => {
        const coreTariff = state.enabled.find(
            (module) => module.moduleId === AppModulesEnum.CORE
        )?.tariffId ?? null;

        switch (coreTariff) {
          case CoreModuleTariffsEnum.BUSINESS:
              return true;
          case CoreModuleTariffsEnum.PRO:
          case CoreModuleTariffsEnum.BASIC:
          default:
              return false;
        }
    }
);

export const marketplaceIsEnabledScreenshotsFeatureSelector = createSelector(
    marketplaceModulesFeatureSelector,
    state => state.enabled.find((enabledModule) => {
        return enabledModule.moduleId === AppModulesEnum.SCREENSHOTS;
    }) !== undefined,
);

export const marketplaceModulesFreeTariffsSelector = createSelector(
    marketplaceModulesFeatureSelector,
    state => state.freeTariffs,
);

export const marketplaceIsEnabledUsingApplicationReportFeatureSelector = createSelector(
    marketplaceModulesFeatureSelector,
    state => {
        const coreTariff = state.enabled.find(
            (module) => module.moduleId === AppModulesEnum.CORE
        )?.tariffId ?? null;

        switch (coreTariff) {
            case CoreModuleTariffsEnum.BUSINESS:
                return true;
            case CoreModuleTariffsEnum.PRO:
            case CoreModuleTariffsEnum.BASIC:
            default:
                return false;
        }
    }
);

export const marketplaceIsEnabledUserActivityReportFeatureSelector = createSelector(
    marketplaceModulesFeatureSelector,
    state => {
        const coreTariff = state.enabled.find(
            (module) => module.moduleId === AppModulesEnum.CORE
        )?.tariffId ?? null;

        switch (coreTariff) {
            case CoreModuleTariffsEnum.BUSINESS:
            case CoreModuleTariffsEnum.PRO:
                return true;
            case CoreModuleTariffsEnum.BASIC:
            default:
                return false;
        }
    }
);

export const marketplaceIsEnabledWorkDayReportFeatureSelector = createSelector(
    marketplaceModulesFeatureSelector,
    state => {
        const coreTariff = state.enabled.find(
            (module) => module.moduleId === AppModulesEnum.CORE
        )?.tariffId ?? null;

        switch (coreTariff) {
            case CoreModuleTariffsEnum.BUSINESS:
                return true;
            case CoreModuleTariffsEnum.BASIC:
            case CoreModuleTariffsEnum.PRO:
            default:
                return false;
        }
    }
);

export const marketplaceIsEnabledInvoicingFeatureSelector = createSelector(
    marketplaceModulesFeatureSelector,
    (state) => {
        return state.enabled.find((module) => {
            return module.moduleId === AppModulesEnum.INVOICING;
        }) !== undefined;
    },
);

export const marketplaceIsEnabledHappinessIndexFeatureSelector = createSelector(
    marketplaceModulesFeatureSelector,
    (state) => {
        return state.enabled.find((module) => {
            return module.moduleId === AppModulesEnum.HAPPINESS_INDEX;
        }) !== undefined;
    },
);
