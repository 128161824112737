import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { InvoicingContractorsCreateInterface } from '@app-client/store/invoicing/contractors/types/invoicing-contractors-create.interface';
import { InvoicingContractorsDetailsResponseInterface } from '@app-client/store/invoicing/contractors/types/invoicing-contractors-details-response.interface';
import { InvoicingContractorsListItemResponseInterface } from '@app-client/store/invoicing/contractors/types/invoicing-contractors-list-item-response.interface';
import { InvoicingContractorsListRequestParamsInterface } from '@app-client/store/invoicing/contractors/types/invoicing-contractors-list-request-params.interface';
import { PageResponseInterface } from '@app-shared/types/page-response.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';

import { environment } from '@env-client/environment';


@Injectable()
export class InvoicingContractorsService {
    constructor(
        private http: HttpClient,
    ) {}

    getList(requestParams: InvoicingContractorsListRequestParamsInterface): Observable<any> {
        const url = `${environment.apiBaseUrl}/module/invoicing/contractors`;

        let params = new HttpParams();
        params = params.set('page', requestParams.page.toString());
        params = params.set('limit', requestParams.limit.toString());

        if (true === !!requestParams.archived) {
            params = params.set('archived', requestParams.archived.toString());
        }

        if (true === !!requestParams.searchText) {
            params = params.set('search', requestParams.searchText);
        }

        return this.http.get<ResponseInterface<PageResponseInterface<InvoicingContractorsListItemResponseInterface>>>(url, { params }).pipe(
            map((response) => {
                return response.body;
            })
        );
    }

    getDetails(id: string): Observable<InvoicingContractorsDetailsResponseInterface> {
        const url = `${environment.apiBaseUrl}/module/invoicing/contractors/${id}`;

        return this.http.get<ResponseInterface<InvoicingContractorsDetailsResponseInterface>>(url).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    create(data: InvoicingContractorsCreateInterface): Observable<string> {
        const url = `${environment.apiBaseUrl}/module/invoicing/contractors`;
        const contractorData = {};

        for (const [key, value] of Object.entries(data)) {
            contractorData[key] = (value === '') ? null : value;
        }

        return this.http.post<ResponseInterface<{ id: string }>>(url, contractorData).pipe(
            map((response) => {
                return response.body.id;
            }),
        );
    }

    update(id: string, data: InvoicingContractorsCreateInterface): Observable<string> {
        const url = `${environment.apiBaseUrl}/module/invoicing/contractors/${id}`;
        const contractorData = {};

        for (const [key, value] of Object.entries(data)) {
            contractorData[key] = (value === '') ? null : value;
        }

        return this.http.patch<ResponseInterface<{ id: string }>>(url, contractorData).pipe(
            map((response) => {
                return response.body.id;
            }),
        );
    }

    delete(id: string): Observable<void> {
        const url = `${environment.apiBaseUrl}/module/invoicing/contractors/${id}`;

        return this.http.delete<ResponseInterface<void>>(url).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    uploadHeaderFile(base64: string, id: string): Observable<void> {
        const url = `${environment.apiBaseUrl}/module/invoicing/contractors/${id}/invoice-header`;
        const body = {
            invoiceHeaderFile: base64,
        };

        return this.http.post<ResponseInterface<void>>(url, body).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    deleteHeaderFile(id: string): Observable<void> {
        const url = `${environment.apiBaseUrl}/module/invoicing/contractors/${id}/invoice-header`;

        return this.http.delete<ResponseInterface<void>>(url).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }
 }
