import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import {
    yearlyReportGetListAction,
    yearlyReportGetListFailureAction,
    yearlyReportGetListSuccessAction,
} from '@app-client/store/marketplace/modules/actions/yearly-report.actions';
import { YearlyReportsService } from '@app-client/store/marketplace/modules/services/yearly-reports.service';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';

@Injectable()
export class YearlyReportEffect {
    constructor(
        private actions$: Actions,
        private yearlyReportService: YearlyReportsService,
        private toastr: ToastrService,
        private translate: TranslateService,
    ) {}

    getReports$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(yearlyReportGetListAction),
            switchMap(() => {
                return this.yearlyReportService.getList().pipe(
                    map((pageResponse) => {
                        return yearlyReportGetListSuccessAction({ pageResponse });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(yearlyReportGetListFailureAction({ backendErrors }));
                    }),
                );
            })
        );
    });

    getReportsFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(yearlyReportGetListFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('USERS.NOTIFICATIONS.GET_USERS_LIST.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });
}
