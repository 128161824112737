import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';


export function passwordMatchesValidator(field1Key: string, field2Key: string): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
        const field1 = group.controls[field1Key];
        const field2 = group.controls[field2Key];

        if (field1.value !== field2.value) {
            field2.setErrors({ ...field2.errors, passwordMatches: true });
        } else if (field2.errors) {
            // lodash.omit equivalent
            const { passwordMatches, ...errors } = field2.errors;

            if (Object.keys(errors).length === 0) {
                field2.setErrors(null);
            } else {
                field2.setErrors({ ...errors });
            }
        }

        return null;
    };
}
