export const environment = {
  production: true,
  env: 'dev',
  apiAuthUrl: 'auth-attrack-v2-dev.umbrellait.tech',
  apiHost: 'umbrellait.tech',
  apiBaseUrl: '',
  domain: 'admin-attrack-v2-dev',
  defaultLocale: 'en',
  locales: ['en', 'ru'],
  noltLink: 'https://attrack.nolt.io',
  appDownloadUrl: 'https://attrack.com/download-tracker/',
  sentryDsn: 'https://ce224cad1e9a4b00834e3713ae3ff8be@sentry.umbrellait.tech/60',
  stripeApiKey: 'pk_test_51Iia18KSkK7t2b7KEAw71EENtJzjto3PXNdveLJo7Yvd4ED3fMgxeBksKfycZ9MMCaCrgCSktNeG7DuANPCL9qeE00AT5pVmIC'
};
