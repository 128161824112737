import { createAction, props } from '@ngrx/store';
import { Moment } from 'moment-timezone';

import { ActivityDataActionTypesEnum } from '@app-client/store/activity/activity-data-action-types';
import { ProjectTaskMemoChunkInterface } from '@app-client/store/activity/types/data-chunks/project-task-memo-chunk.interface';
import { ScreenshotChunkInterface } from '@app-client/store/activity/types/data-chunks/screenshot-chunk.interface';
import { WindowChunkInterface } from '@app-client/store/activity/types/data-chunks/window-chunk.interface';
import { DailyStatisticsInterface } from '@app-client/store/activity/types/statistics/daily-statistics.interface';
import { MonthlyStatisticsInterface } from '@app-client/store/activity/types/statistics/monthly-statistics.interface';
import { WeeklyStatisticsInterface } from '@app-client/store/activity/types/statistics/weekly-statistics.interface';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


export const getDailyStatisticsAction = createAction(
    ActivityDataActionTypesEnum.GET_DAILY_STATISTICS,
);
export const getDailyStatisticsSuccessAction = createAction(
    ActivityDataActionTypesEnum.GET_DAILY_STATISTICS_SUCCESS,
    props<{ statistics: DailyStatisticsInterface, day: Moment }>(),
);
export const getDailyStatisticsFailureAction = createAction(
    ActivityDataActionTypesEnum.GET_DAILY_STATISTICS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const getWeeklyStatisticsAction = createAction(
    ActivityDataActionTypesEnum.GET_WEEKLY_STATISTICS,
);
export const getWeeklyStatisticsSuccessAction = createAction(
    ActivityDataActionTypesEnum.GET_WEEKLY_STATISTICS_SUCCESS,
    props<{ statistics: WeeklyStatisticsInterface }>(),
);
export const getWeeklyStatisticsFailureAction = createAction(
    ActivityDataActionTypesEnum.GET_WEEKLY_STATISTICS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const getMonthlyStatisticsAction = createAction(
    ActivityDataActionTypesEnum.GET_MONTHLY_STATISTICS,
);
export const getMonthlyStatisticsSuccessAction = createAction(
    ActivityDataActionTypesEnum.GET_MONTHLY_STATISTICS_SUCCESS,
    props<{ statistics: Array<MonthlyStatisticsInterface> }>(),
);
export const getMonthlyStatisticsFailureAction = createAction(
    ActivityDataActionTypesEnum.GET_MONTHLY_STATISTICS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const getProjectTaskMemoDataAction = createAction(
    ActivityDataActionTypesEnum.GET_PROJECT_TASK_MEMO_DATA,
);
export const getProjectTaskMemoDataSuccessAction = createAction(
    ActivityDataActionTypesEnum.GET_PROJECT_TASK_MEMO_DATA_SUCCESS,
    props<{ projectTaskMemoData: Array<ProjectTaskMemoChunkInterface> }>(),
);
export const getProjectTaskMemoDataFailureAction = createAction(
    ActivityDataActionTypesEnum.GET_PROJECT_TASK_MEMO_DATA_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const getWindowDataAction = createAction(
    ActivityDataActionTypesEnum.GET_WINDOW_DATA,
);
export const getWindowDataSuccessAction = createAction(
    ActivityDataActionTypesEnum.GET_WINDOW_DATA_SUCCESS,
    props<{ windowData: Array<WindowChunkInterface> }>(),
);
export const getWindowDataFailureAction = createAction(
    ActivityDataActionTypesEnum.GET_WINDOW_DATA_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const getScreenshotDataAction = createAction(
    ActivityDataActionTypesEnum.GET_SCREENSHOT_DATA,
);
export const getScreenshotDataSuccessAction = createAction(
    ActivityDataActionTypesEnum.GET_SCREENSHOT_DATA_SUCCESS,
    props<{ screenshotData: Array<ScreenshotChunkInterface> }>(),
);
export const getScreenshotDataFailureAction = createAction(
    ActivityDataActionTypesEnum.GET_SCREENSHOT_DATA_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
export const clearScreenshotDataAction = createAction(
    ActivityDataActionTypesEnum.CLEAR_SCREENSHOT_DATA,
);

export const getTimelineDataAction = createAction(
    ActivityDataActionTypesEnum.GET_TIMELINE_DATA,
);

export const getTimelineDataSuccessAction = createAction(
    ActivityDataActionTypesEnum.GET_TIMELINE_DATA_SUCCESS,
    props<{
        dailyStatisticData: DailyStatisticsInterface,
        projectTaskMemoData: Array<ProjectTaskMemoChunkInterface>,
        windowData: Array<WindowChunkInterface>,
        screenshotData: Array<ScreenshotChunkInterface>,
    }>(),
);

export const getTimelineDataFailureAction = createAction(
    ActivityDataActionTypesEnum.GET_TIMELINE_DATA_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const dropDailyStatisticsAction = createAction(
    ActivityDataActionTypesEnum.DROP_DAILY_STATISTICS,
);
