import { createReducer, on } from '@ngrx/store';

import {
    getCompanyConfigScreenshotsAction,
    getCompanyConfigScreenshotsFailureAction,
    getCompanyConfigScreenshotsSuccessAction,
    updateCompanyConfigScreenshotsAction,
    updateCompanyConfigScreenshotsFailureAction,
    updateCompanyConfigScreenshotsSuccessAction
} from '@app-client/store/company/actions/company-config-screenshots.action';
import { CompanyConfigStateInterface } from '@app-client/store/company/company-config.interface';


const initialState: CompanyConfigStateInterface = {
    companyConfigScreenshots:  {
        isBlurEnabledByDefault: false
    },

    isGetCompanyConfigInProgress: false,

    isUpdateCompanyConfigInProgress: false,
    updateCompanyConfigBackendErrors: null,
};

const reducer = createReducer(
    initialState,
    on(
        getCompanyConfigScreenshotsAction,
        state => ({
            ...state,
            isGetCompanyConfigInProgress: true,
        }),
    ),
    on(
        getCompanyConfigScreenshotsSuccessAction,
        (state, action) => ({
            ...state,
            isGetCompanyConfigInProgress: false,
            companyConfigScreenshots: action.companyConfigScreenshots,
        }),
    ),
    on(
        getCompanyConfigScreenshotsFailureAction,
        (state) => ({
            ...state,
            isGetCompanyConfigInProgress: false,
        }),
    ),
    on(
        updateCompanyConfigScreenshotsAction,
        (state) => ({
            ...state,
            isUpdateCompanyConfigInProgress: true,
        }),
    ),
    on(
        updateCompanyConfigScreenshotsSuccessAction,
        (state) => ({
            ...state,
            isUpdateCompanyConfigInProgress: false
        }),
    ),
    on(
        updateCompanyConfigScreenshotsFailureAction,
        (state) => ({
            ...state,
            isUpdateCompanyConfigInProgress: false,
        }),
    ),
);

export function companyConfigScreenshotsReducer(state, action) {
    return reducer(state, action);
}
