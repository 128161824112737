export enum ActionTypes {
    SET_BY_PROJECT_MEMBER = '[EffectivityConfig] Set by project member',
    SET_SUCCESS = '[EffectivityConfig] Set success',
    SET_FAILURE = '[EffectivityConfig] Set failure',
    GET_ONE_BY_PROJECT_MEMBER = '[EffectivityConfig] Get one by project member',
    GET_ONE_BY_PROJECT_AND_USER = '[EffectivityConfig] Get one by project and user',
    GET_ONE_SUCCESS = '[EffectivityConfig] Get one success',
    GET_ONE_FAILURE = '[EffectivityConfig] Get one failure',
    BULK_UPDATE = '[EffectivityConfig] Bulk update',
    BULK_UPDATE_SUCCESS = '[EffectivityConfig] Bulk update success',
    BULK_UPDATE_FAILURE = '[EffectivityConfig] Bulk update failure',
}
