import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { JoyrideService, JoyrideStepService } from 'ngx-joyride';
import { JoyrideStepInfo } from 'ngx-joyride/lib/models/joyride-step-info.class';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { map } from 'rxjs/operators';

import { PersistenceService } from '@app-client/shared/services/persistence.service';
import { checkCompanyOnboardingDataAction } from '@app-client/store/tour/actions/tour.action';
import { TourUsersRoleEnum } from '@app-client/tour/tour-users-role.enum';
import { TourSectionsEnum } from '@app-client/tour/types/tour-sections.enum';
import { TourStepInfoInterface } from '@app-client/tour/types/tour-step-info.interface';
import { CoreModuleTariffsEnum } from '@app-shared/types/app-modules.enum';

import { environment } from '@env-client/environment';


const ALL_ROLES = [
    TourUsersRoleEnum.USER,
    TourUsersRoleEnum.OBSERVER,
    TourUsersRoleEnum.MANAGER,
    TourUsersRoleEnum.SYSADMIN,
    TourUsersRoleEnum.ADMIN,
];

const EXCEPT_OBSERVER = [
    TourUsersRoleEnum.USER,
    TourUsersRoleEnum.MANAGER,
    TourUsersRoleEnum.SYSADMIN,
    TourUsersRoleEnum.ADMIN,
];

const ALL_TARIFFS = [
    CoreModuleTariffsEnum.BASIC,
    CoreModuleTariffsEnum.PRO,
    CoreModuleTariffsEnum.BUSINESS,
];

const DOWNLOAD_TRACKER_STEP = {
    step: 'download_desktop_client',
    roles: ALL_ROLES,
    tariffs: ALL_TARIFFS,
};

/**
 * @See https://github.com/tnicola/ngx-joyride
 * If you want to change location use '{section_step}@{location}' format
 *
 */
export const sectionStepsMap = new Map<TourSectionsEnum, { step: string; roles: TourUsersRoleEnum[]; tariffs: CoreModuleTariffsEnum[] }[]>([
    [
        TourSectionsEnum.OVERVIEW,
        [
            {
                step: 'create_users',
                roles: [ TourUsersRoleEnum.SYSADMIN, TourUsersRoleEnum.ADMIN ],
                tariffs: ALL_TARIFFS,
            },
            {
                step: 'create_project_spaces',
                roles: [ TourUsersRoleEnum.ADMIN ],
                tariffs: ALL_TARIFFS,
            },
            {
                step: 'create_projects',
                roles: [ TourUsersRoleEnum.MANAGER, TourUsersRoleEnum.ADMIN ],
                tariffs: ALL_TARIFFS,
            },
            {
                step: 'create_reports',
                roles: ALL_ROLES,
                tariffs: ALL_TARIFFS,
            },
            {
                step: 'process_time_requests',
                roles: [ TourUsersRoleEnum.MANAGER, TourUsersRoleEnum.ADMIN ],
                tariffs: ALL_TARIFFS,
            },
            {
                step: 'connect_new_modules',
                roles: [ TourUsersRoleEnum.ADMIN ],
                tariffs: ALL_TARIFFS,
            },
            {
                step: 'add_company_info',
                roles: [ TourUsersRoleEnum.MANAGER, TourUsersRoleEnum.ADMIN ],
                tariffs: ALL_TARIFFS,
            },
            {
                step: 'view_activity_data',
                roles: ALL_ROLES,
                tariffs: ALL_TARIFFS,
            },
            {
                step: 'view_effectivity_data',
                roles: EXCEPT_OBSERVER,
                tariffs: [ CoreModuleTariffsEnum.BUSINESS ],
            },
        ],
    ],
    [
        TourSectionsEnum.USERS,
        [
            {
                step: 'create_new_user_button',
                roles: [ TourUsersRoleEnum.MANAGER, TourUsersRoleEnum.SYSADMIN, TourUsersRoleEnum.ADMIN ],
                tariffs: ALL_TARIFFS,
            },
            {
                step: 'process_access_requests',
                roles: [ TourUsersRoleEnum.MANAGER, TourUsersRoleEnum.SYSADMIN, TourUsersRoleEnum.ADMIN ],
                tariffs: ALL_TARIFFS,
            },
        ],
    ],
    [
        TourSectionsEnum.CREATE_USER,
        [
            {
                step: 'create_user_with_role',
                roles: [ TourUsersRoleEnum.MANAGER, TourUsersRoleEnum.SYSADMIN, TourUsersRoleEnum.ADMIN ],
                tariffs: ALL_TARIFFS,
            },
        ],
    ],
    [
        TourSectionsEnum.PROJECT_SPACES,
        [
            {
                step: 'project_spaces_create_button',
                roles: [ TourUsersRoleEnum.ADMIN ],
                tariffs: ALL_TARIFFS,
            },
        ],
    ],
    [
        TourSectionsEnum.PROJECTS,
        [
            {
                step: 'project_list_create_new_project',
                roles: [ TourUsersRoleEnum.MANAGER, TourUsersRoleEnum.ADMIN ],
                tariffs: ALL_TARIFFS,
            },
        ],
    ],
    [
        TourSectionsEnum.NEW_PROJECT,
        [
            {
                step: 'project_list_create_new_project_status',
                roles: [ TourUsersRoleEnum.MANAGER, TourUsersRoleEnum.ADMIN ],
                tariffs: ALL_TARIFFS,
            },
            {
                step: 'project_list_create_new_project_and_save',
                roles: [ TourUsersRoleEnum.MANAGER, TourUsersRoleEnum.ADMIN ],
                tariffs: ALL_TARIFFS,
            },
        ],
    ],
    [
        TourSectionsEnum.NEW_PROJECT_TEAM,
        [
            {
                step: 'new_project_team',
                roles: [ TourUsersRoleEnum.MANAGER, TourUsersRoleEnum.ADMIN ],
                tariffs: ALL_TARIFFS,
            },
            {
                step: 'new_project_tasks',
                roles: [ TourUsersRoleEnum.MANAGER, TourUsersRoleEnum.ADMIN ],
                tariffs: ALL_TARIFFS,
            },
            {
                step: 'new_project_positions',
                roles: [ TourUsersRoleEnum.MANAGER, TourUsersRoleEnum.ADMIN ],
                tariffs: ALL_TARIFFS,
            },
        ],
    ],
    [
        TourSectionsEnum.NEW_PROJECT_TEAM_ADD_MEMBER,
        [
            {
                step: 'new_project_team_add_member',
                roles: [ TourUsersRoleEnum.MANAGER, TourUsersRoleEnum.ADMIN ],
                tariffs: ALL_TARIFFS,
            },
            {
                step: 'new_project_team_edit_member',
                roles: [ TourUsersRoleEnum.MANAGER, TourUsersRoleEnum.ADMIN ],
                tariffs: ALL_TARIFFS,
            },
            {
                step: 'new_project_team_template_bulk_update',
                roles: [ TourUsersRoleEnum.MANAGER, TourUsersRoleEnum.ADMIN ],
                tariffs: ALL_TARIFFS,
            },
        ],
    ],
    [
        TourSectionsEnum.NEW_PROJECT_TEAM_ADD_MEMBER_FORM,
        [
            {
                step: 'new_project_team_add_member_form_role_field',
                roles: [ TourUsersRoleEnum.MANAGER, TourUsersRoleEnum.ADMIN ],
                tariffs: ALL_TARIFFS,
            },
            {
                step: 'new_project_team_add_member_form_position_field',
                roles: [ TourUsersRoleEnum.MANAGER, TourUsersRoleEnum.ADMIN ],
                tariffs: ALL_TARIFFS,
            },
            {
                step: 'new_project_team_add_member_form_save',
                roles: [ TourUsersRoleEnum.MANAGER, TourUsersRoleEnum.ADMIN ],
                tariffs: ALL_TARIFFS,
            },
        ],
    ],
    [
        TourSectionsEnum.REPORTS,
        [
            {
                step: 'reports_show_additional_settings',
                roles: ALL_ROLES,
                tariffs: ALL_TARIFFS,
            },
            {
                step: 'reports_generate_button',
                roles: ALL_ROLES,
                tariffs: ALL_TARIFFS,
            },
            {
                step: 'reports_export_to_google_button',
                roles: ALL_ROLES,
                tariffs: ALL_TARIFFS,
            },
        ],
    ],
    [
        TourSectionsEnum.TIME_REQUESTS,
        [
            {
                step: 'time_requests_process_new_requests',
                roles: [ TourUsersRoleEnum.MANAGER, TourUsersRoleEnum.ADMIN ],
                tariffs: ALL_TARIFFS,
            },
        ],
    ],
    [
        TourSectionsEnum.MARKETPLACE,
        [
            {
                step: 'marketplace_connect_new_modules',
                roles: [ TourUsersRoleEnum.ADMIN ],
                tariffs: ALL_TARIFFS,
            },
        ],
    ],
    [
        TourSectionsEnum.COMPANY_SETTINGS,
        [
            {
                step: 'company_settings_positions',
                roles: [ TourUsersRoleEnum.ADMIN ],
                tariffs: ALL_TARIFFS,
            },
            {
                step: 'company_settings_effectivity_templates',
                roles: [ TourUsersRoleEnum.MANAGER, TourUsersRoleEnum.ADMIN ],
                tariffs: [ CoreModuleTariffsEnum.BUSINESS ],
            },
            {
                step: 'company_settings_effectivity_categories',
                roles: [ TourUsersRoleEnum.ADMIN ],
                tariffs: [ CoreModuleTariffsEnum.BUSINESS ],
            },
        ],
    ],
    [
        TourSectionsEnum.ACTIVITY,
        [
            {
                step: 'activity_detailed_statistics',
                roles: ALL_ROLES,
                tariffs: [ CoreModuleTariffsEnum.PRO, CoreModuleTariffsEnum.BUSINESS ],
            },
            {
                step: 'activity_add_time_button',
                roles: ALL_ROLES,
                tariffs: ALL_TARIFFS,
            },
        ],
    ],
    [
        TourSectionsEnum.EFFECTIVITY,
        [
            {
                step: 'effectivity_template_change',
                roles: [ TourUsersRoleEnum.MANAGER, TourUsersRoleEnum.ADMIN ],
                tariffs: [ CoreModuleTariffsEnum.BUSINESS ],
            },
        ],
    ],
    [
        TourSectionsEnum.DAILY_RESULT,
        [
            {
                step: 'daily-result__my-tab',
                roles: ALL_ROLES,
                tariffs: ALL_TARIFFS,
            },
            {
                step: 'daily-result__calendar',
                roles: ALL_ROLES,
                tariffs: ALL_TARIFFS,
            },
            {
                step: 'daily-result__task',
                roles: ALL_ROLES,
                tariffs: ALL_TARIFFS,
            },
            {
                step: 'daily-result__time',
                roles: ALL_ROLES,
                tariffs: ALL_TARIFFS,
            },
            {
                step: 'daily-result__comment',
                roles: ALL_ROLES,
                tariffs: ALL_TARIFFS,
            },
            {
                step: 'daily-result__important',
                roles: ALL_ROLES,
                tariffs: ALL_TARIFFS,
            },
            {
                step: 'daily-result__blokers-and-plans',
                roles: ALL_ROLES,
                tariffs: ALL_TARIFFS,
            },
            {
                step: 'daily-result__save',
                roles: ALL_ROLES,
                tariffs: ALL_TARIFFS,
            },
        ],
    ],
]);

@Injectable()
export class TourService {
    private currentSection: TourSectionsEnum;
    private currentStepTranslationsMap: Map<string, TourStepInfoInterface>;
    private currentStepIndex: number | null = null;
    private shouldShowCompleteDialog = false;
    private finishTutorialAfterThisSection = false;
    private currentTranslationsSubject = new BehaviorSubject<TourStepInfoInterface>(null);
    private checkOnboardingTimerSubscription: Subscription;
    private tourSubscription: Subscription;
    private userRole: TourUsersRoleEnum;
    private activatedTariff: CoreModuleTariffsEnum;

    constructor(
        private joyride: JoyrideService,
        private joyrideStep: JoyrideStepService,
        private persistenceService: PersistenceService,
        private http: HttpClient,
        private store: Store,
    ) {}

    public get steps() {
        const steps = sectionStepsMap.get(this.currentSection)
            .filter(({ roles }) => roles.includes(this.userRole))
            .filter(({ tariffs }) => tariffs.includes(this.activatedTariff));

        if (this.completedSections.length === 1) {
            return [
                ...steps,
                DOWNLOAD_TRACKER_STEP,
            ];
        }
        return steps;
    }

    public get isInProgress() {
        return this.joyride.isTourInProgress();
    }

    public get isLastStep() {
        return this.currentStepIndex === this.steps.length - 1;
    }

    public get showCompleteDialog() {
        return this.shouldShowCompleteDialog;
    }

    public get isShouldFinishTutorial() {
        return this.finishTutorialAfterThisSection;
    }

    public get isOnboardingCheckTimer() {
        return !!this.checkOnboardingTimerSubscription;
    }

    public get currentStep() {
        return this.currentStepIndex === null ? null : this.steps[this.currentStepIndex];
    }

    public get currentStepTranslations(): TourStepInfoInterface {
        return this.currentStepTranslationsMap?.get(this?.currentStep?.step);
    }

    public get currentStepTranslations$(): Observable<TourStepInfoInterface> {
        return this.currentTranslationsSubject.asObservable();
    }

    public get completedSections(): TourSectionsEnum[] {
        return this.persistenceService.get('onboarding_sections_complete') || [];
    }

    private static getStepInfo(step: string): TourStepInfoInterface {
        const translationToken = step.replace(/@(.*)/, '').toUpperCase();

        return {
            header: `TUTORIAL.${translationToken}.HEADER`,
            text: `TUTORIAL.${translationToken}.TEXT`,
            helpLink: `TUTORIAL.${translationToken}.HELP_LINK`,
            helpText: `TUTORIAL.${translationToken}.HELP_TEXT`,
        };
    }

    public start(
        section: TourSectionsEnum,
        userRole: TourUsersRoleEnum,
        activatedTariff: CoreModuleTariffsEnum,
        showDialogOnComplete = false,
        finishTutorialAfterSection = false
    ): Observable<JoyrideStepInfo> {
        this.currentSection = section;
        this.userRole = userRole;
        this.activatedTariff = activatedTariff;
        this.shouldShowCompleteDialog = showDialogOnComplete;
        this.finishTutorialAfterThisSection = finishTutorialAfterSection;
        const sectionSteps = this.steps;
        this.currentStepTranslationsMap = new Map(sectionSteps.map(({ step }) => [ step, TourService.getStepInfo(step) ]));
        this.currentStepIndex = 0;
        this.currentTranslationsSubject.next(this.currentStepTranslations);

        const tourObservable = this.joyride.startTour({
            steps: [
                ...sectionSteps
                    .map(({ step }) => step)
            ],
            showPrevButton: false,
            showCounter: false,
            themeColor: '#333',
        });

        this.tourSubscription = tourObservable.subscribe((step) => {
            const targetEl = document.querySelector(`[joyridestep=${step.name}]`);

            // this is a workaround for ngx-joyride issue #82 (https://github.com/tnicola/ngx-joyride/issues/82#issuecomment-571097242)
            // and should be removed
            document.scrollingElement.scrollTop = 0;

            targetEl?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        });

        return tourObservable;
    }

    public nextStep() {
        this.currentStepIndex++;
        this.currentTranslationsSubject.next(this.currentStepTranslations);
        this.joyrideStep.next();
    }

    public prevStep() {
        this.currentStepIndex--;
        this.currentTranslationsSubject.next(this.currentStepTranslations);
        this.joyrideStep.prev();
    }

    public end() {
        this.currentStepIndex = null;
        this.joyride.closeTour();
        this.tourSubscription?.unsubscribe();
    }

    public sendStatusStarted() {
        const url = environment.apiBaseUrl + '/onboarding/start';

        return this.http.patch<{ message: string; body: null }>(url, {});
    }

    public sendStatusFinished() {
        const url = environment.apiBaseUrl + '/onboarding/finish';

        return this.http.patch<{ message: string; body: null }>(url, {});
    }

    public loadCompanyOnboardingData() {
        const url = environment.apiBaseUrl + '/onboarding/load-data';

        return this.http.post<{ message: string; body: null }>(url, {});
    }

    public deleteCompanyOnboardingData() {
        const url = environment.apiBaseUrl + '/onboarding/delete-data';

        return this.http.delete<{ message: string; body: null }>(url, {});
    }

    public checkCompanyOnboardingData() {
        const url = environment.apiBaseUrl + '/onboarding/check-load-data';

        return this.http.get<{ message: string; body: { status: 'none' | 'pending' | 'loaded' | 'error'; begin: number } }>(url, {});
    }

    public setupOnboardingDataCheckTimer() {
        if (!this.isOnboardingCheckTimer) {
            this.checkOnboardingTimerSubscription = timer(0, 10000)
                .pipe(
                    map(() => this.store.dispatch(checkCompanyOnboardingDataAction())),
                )
                .subscribe();
        }
    }

    public unsubscribeOnboardingDataCheckTimer() {
        if (this.isOnboardingCheckTimer) {
            this.checkOnboardingTimerSubscription.unsubscribe();
            this.checkOnboardingTimerSubscription = null;
        }
    }

    public addSectionToCompleted(section: TourSectionsEnum) {
        const completedSectionsSet = new Set(this.completedSections);

        completedSectionsSet.add(section);

        this.persistenceService.set('onboarding_sections_complete', [ ...completedSectionsSet ].sort());
    }

    public clearCompletedSections(keepSections: TourSectionsEnum[] = []) {
        this.persistenceService.remove('onboarding_sections_complete');

        if (keepSections?.length > 0) {
            this.persistenceService.set('onboarding_sections_complete', [ ...keepSections ].sort());
        }
    }

    public markAllSectionsAsCompleted() {
        const sectionsLength = Object.keys(TourSectionsEnum).length / 2;
        const allSections = new Array(sectionsLength)
                                .fill(0)
                                .map((_, index) => index);
        this.persistenceService.set('onboarding_sections_complete', allSections);
    }
}
