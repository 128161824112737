import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { FormErrorsComponent } from '@app-shared/modules/form-errors/components/form-errors.component';


@NgModule({
    declarations: [FormErrorsComponent],
    imports: [
        CommonModule,
        TranslateModule,
    ],
    exports: [FormErrorsComponent],
})
export class FormErrorsModule {}
