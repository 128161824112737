import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { UIModule } from '@umbrella-web/attrack-ui-module';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

import { AccessRequestComponent } from '@app-client/auth/components/access-request/access-request.component';
import { ForgotPasswordComponent } from '@app-client/auth/components/forgot-password/forgot-password.component';
import { LoginLayoutComponent } from '@app-client/auth/components/login-layout/login-layout.component';
import { LoginComponent } from '@app-client/auth/components/login/login.component';
import { NewPasswordComponent } from '@app-client/auth/components/new-password/new-password.component';
import { AuthInterceptor } from '@app-client/auth/interceptors/auth.interceptor';
import { AccessRequestService } from '@app-client/auth/services/access-request.service';
import { UnauthorizedGuardService } from '@app-client/shared/services/unauthorized-guard.service';
import { AuthService } from '@app-client/store/auth/services/auth.service';
import { FormErrorsModule } from '@app-shared/modules/form-errors/form-errors.module';


const routes: Routes = [
    {
        path: '',
        component: LoginLayoutComponent,
        children: [
            { path: '', redirectTo: '/login', pathMatch: 'full', canActivate: [UnauthorizedGuardService] },
            { path: 'login', component: LoginComponent, canActivate: [UnauthorizedGuardService] },
            { path: 'restore-password', component: ForgotPasswordComponent, canActivate: [UnauthorizedGuardService] },
            { path: 'new-password', component: NewPasswordComponent, canActivate: [UnauthorizedGuardService] },
            { path: 'request-access', component: AccessRequestComponent, canActivate: [UnauthorizedGuardService] },
        ],
    },
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,
        UIModule,
        FormErrorsModule,
        MatIconModule,
        NgxTrimDirectiveModule,
    ],
    declarations: [
        LoginLayoutComponent,
        LoginComponent,
        ForgotPasswordComponent,
        NewPasswordComponent,
        AccessRequestComponent,
    ],
    providers: [
        AuthService,
        AccessRequestService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    ],
})
export class AuthModule {}
