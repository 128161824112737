import { AbstractControl, ValidationErrors } from '@angular/forms';
import validator from 'validator';


export function emailValidator(control: AbstractControl): ValidationErrors {
    if ((control.value || '').trim().length === 0) {
        return null;
    }
    if (!validator.isEmail(control.value || '', { allow_utf8_local_part: false }) ||
        !/^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/.test(control.value.split('@')[1])
    ) {
        return { email: true };
    }
}
