import { createAction, props } from '@ngrx/store';

import { CreateProjectMemberInterface } from '@app-client/project/types/create-project-member.interface';
import { ProjectMemberDetailsInterface } from '@app-client/project/types/project-member-details.interface';
import { ProjectMemberListQueryInterface } from '@app-client/project/types/project-member-list-query.interface';
import {
    ProjectMemberInterface,
    ProjectMemberPermissionActionEnum,
    ProjectMemberPermissionsEnum,
} from '@app-client/project/types/project-member.interface';
import { UpdateProjectMemberInterface } from '@app-client/project/types/update-project-member.interface';
import { ProjectMembersActionTypes } from '@app-client/store/project-members/project-members-action-types';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';
import { PageResponseInterface } from '@app-shared/types/page-response.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';


export const setProjectMembersListQueryParamsAction = createAction(
    ProjectMembersActionTypes.SET_LIST_QUERY_PARAMS,
    props<{ queryParams: ProjectMemberListQueryInterface }>(),
);

export const getProjectMemberListAction = createAction(
    ProjectMembersActionTypes.GET_LIST,
);

export const getProjectMemberListSuccessAction = createAction(
    ProjectMembersActionTypes.GET_LIST_SUCCESS,
    props<{ response: PageResponseInterface<ProjectMemberInterface> }>(),
);

export const getProjectMemberListFailureAction = createAction(
    ProjectMembersActionTypes.GET_LIST_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const createProjectMemberAction = createAction(
    ProjectMembersActionTypes.CREATE,
    props<{ createProjectMember: CreateProjectMemberInterface }>(),
);

export const createProjectMemberSuccessAction = createAction(
    ProjectMembersActionTypes.CREATE_SUCCESS,
    props<{ response: ResponseInterface<ProjectMemberInterface> }>(),
);

export const createProjectMemberFailureAction = createAction(
    ProjectMembersActionTypes.CREATE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const updateProjectMemberAction = createAction(
    ProjectMembersActionTypes.UPDATE,
    props<{ id: string, updateProjectMember: UpdateProjectMemberInterface }>(),
);

export const updateProjectMemberSuccessAction = createAction(
    ProjectMembersActionTypes.UPDATE_SUCCESS,
    props<{ response: ResponseInterface<ProjectMemberDetailsInterface> }>(),
);

export const updateProjectMemberFailureAction = createAction(
    ProjectMembersActionTypes.UPDATE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const requiresDeleteProjectMemberConfirmationAction = createAction(
    ProjectMembersActionTypes.GET_REQUIRES_DELETE_CONFIRMATION,
    props<{ projectId: string, memberId: string }>(),
);

export const removeProjectMemberAction = createAction(
    ProjectMembersActionTypes.REMOVE,
    props<{ projectId: string, memberId: string,  force?: boolean }>(),
);

export const removeProjectMemberSuccessAction = createAction(
    ProjectMembersActionTypes.REMOVE_SUCCESS,
    props<{ projectId: string }>(),
);

export const removeProjectMemberFailureAction = createAction(
    ProjectMembersActionTypes.REMOVE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const getProjectMemberAction = createAction(
    ProjectMembersActionTypes.GET_ONE,
    props<{ id: string }>(),
);

export const getProjectMemberSuccessAction = createAction(
    ProjectMembersActionTypes.GET_ONE_SUCCESS,
    props<{ response: ResponseInterface<ProjectMemberDetailsInterface> }>(),
);

export const getProjectMemberFailureAction = createAction(
    ProjectMembersActionTypes.GET_ONE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const projectMembersBatchUpdatePermissionAction = createAction(
    ProjectMembersActionTypes.BATCH_UPDATE_PERMISSION,
    props<{ ids: Array<string>, permission: ProjectMemberPermissionsEnum, action: ProjectMemberPermissionActionEnum }>(),
);

export const projectMembersBatchUpdatePermissionsSuccessAction = createAction(
    ProjectMembersActionTypes.BATCH_UPDATE_PERMISSION_SUCCESS,
    props<{ ids: Array<string>, permission: ProjectMemberPermissionsEnum, action: ProjectMemberPermissionActionEnum }>(),
);

export const projectMembersBatchUpdatePermissionsFailureAction = createAction(
    ProjectMembersActionTypes.BATCH_UPDATE_PERMISSION_FAILURE,
    props<{ ids: Array<string>, backendErrors: BackendErrorsInterface }>(),
);
