import { createAction, props } from '@ngrx/store';

import { ActionTypes } from '@app-client/store/auth/action-types';
import { CompanyTrackingConfigInterface } from '@app-client/store/auth/types/company-tracking-config.interface';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


export const getCompanyTrackingSettingsAction = createAction(
    ActionTypes.GET_COMPANY_TRACKING_SETTINGS,
);

export const getCompanyTrackingSettingsSuccessAction = createAction(
    ActionTypes.GET_COMPANY_TRACKING_SETTINGS_SUCCESS,
    props<{ config: CompanyTrackingConfigInterface }>(),
);

export const getCompanyTrackingSettingsFailureAction = createAction(
    ActionTypes.GET_COMPANY_TRACKING_SETTINGS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
