import { createReducer, on } from '@ngrx/store';

import {
    createProjectPositionAction,
    createProjectPositionFailureAction,
    createProjectPositionSuccessAction,
    getProjectPositionListAction,
    getProjectPositionListFailureAction,
    getProjectPositionListSuccessAction,
    removeProjectPositionAction,
    removeProjectPositionFailureAction,
    removeProjectPositionSuccessAction,
    setProjectPositionListQueryParamsAction,
    updateProjectPositionAction,
    updateProjectPositionFailureAction,
    updateProjectPositionSuccessAction,
} from '@app-client/store/project-posiitons/actions/project-positions.action';
import { ProjectPositionsStateInterface } from '@app-client/store/project-posiitons/project-positions-state.interface';


const initialState: ProjectPositionsStateInterface = {
    createProjectPositionBackendErrors: undefined,
    hasLoadingMore: false,
    isCreateProjectPositionInProgress: false,
    isProjectPositionListLoading: false,
    isRemoveProjectPositionInProgress: false,
    isUpdateProjectPositionInProgress: false,
    listQueryParams: undefined,
    updateProjectPositionBackendErrors: undefined,
    projectPositionList: [],
};

const reducer = createReducer(
    initialState,
    on(
        setProjectPositionListQueryParamsAction,
        (state, action) => ({
            ...state,
            listQueryParams: action.queryParams,
        }),
    ),
    on(
        getProjectPositionListAction,
        (state) => ({
            ...state,
            isProjectPositionListLoading: true,
            hasLoadingMore: false,
            projectPositionList: state.listQueryParams.page === 1 ? [] : [...state.projectPositionList],
        }),
    ),
    on(
        getProjectPositionListSuccessAction,
        (state, action) => ({
            ...state,
            isProjectPositionListLoading: false,
            hasLoadingMore: action.response.page < action.response.totalPages,
            projectPositionList: [...state.projectPositionList, ...action.response.items],
        }),
    ),
    on(
        getProjectPositionListFailureAction,
        (state) => ({
            ...state,
            isProjectPositionListLoading: false,
            hasLoadingMore: false,
            projectPositionList: [],
        }),
    ),
    on(
        createProjectPositionAction,
        (state) => ({
            ...state,
            isCreateProjectPositionInProgress: true,
        }),
    ),
    on(
        createProjectPositionSuccessAction,
        (state) => ({
            ...state,
            isCreateProjectPositionInProgress: false,
        }),
    ),
    on(
        createProjectPositionFailureAction,
        (state, action) => ({
            ...state,
            isCreateProjectPositionInProgress: false,
            createProjectPositionBackendErrors: action.backendErrors,
        })
    ),
    on(
        updateProjectPositionAction,
        (state) => ({
            ...state,
            isUpdateProjectPositionInProgress: true,
        }),
    ),
    on(
        updateProjectPositionSuccessAction,
        (state) => ({
            ...state,
            isUpdateProjectPositionInProgress: false,
        }),
    ),
    on(
        updateProjectPositionFailureAction,
        (state, action) => ({
            ...state,
            isUpdateProjectPositionInProgress: false,
            updateProjectPositionBackendErrors: action.backendErrors,
        }),
    ),
    on(
        removeProjectPositionAction,
        (state) => ({
            ...state,
            isRemoveProjectPositionInProgress: true,
        }),
    ),
    on(
        removeProjectPositionSuccessAction,
        (state) => ({
            ...state,
            isRemoveProjectPositionInProgress: false,
        }),
    ),
    on(
        removeProjectPositionFailureAction,
        (state) => ({
            ...state,
            isRemoveProjectPositionInProgress: false,
        }),
    ),
);

export const projectPositionsReducer = (state, action) => reducer(state, action);
