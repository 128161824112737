import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { currentCompanySelector } from '@app-client/store/auth/selectors/auth.selectors';
import { CurrentCompanyInterface } from '@app-client/store/auth/types/current-company.interface';

const ATTRACK_LOGO_URL = '/assets/images/AtTrackLogo.svg';
const ROMDO_LOGO_URL = '/assets/images/RomdoLogo.svg';

const TRACKER_SUB_DOMAIN = 'tracker';


@UntilDestroy()
@Component({
    selector: 'app-login-layout',
    templateUrl: './login-layout.component.html',
    styleUrls: ['./login-layout.component.scss'],
})
export class LoginLayoutComponent implements OnInit {
    company$: Observable<CurrentCompanyInterface>;
    logoUrl$: Observable<string>;
    isTrackerDomainDetected$: Observable<boolean>;

    constructor(
        private store: Store,
        private meta: Meta,
        @Inject(DOCUMENT) private document: Document,
    ) {}

    ngOnInit(): void {
        this.meta.updateTag({name: 'viewport', content: 'width=device-width, initial-scale=1'});
        this.document.body.style.background = '#282A32';
        this.initializeValues();
    }

    initializeValues(): void {
        this.company$ = this.store.select(currentCompanySelector);
        this.isTrackerDomainDetected$ = this.company$.pipe(
            map((company) => {
                return TRACKER_SUB_DOMAIN === company.subDomain;
            }),
        );
        this.logoUrl$ = this.isTrackerDomainDetected$.pipe(
            map((isDetected) => {
                if (true === isDetected) {
                    return ROMDO_LOGO_URL;
                }

                return ATTRACK_LOGO_URL;
            }),
        );
    }
}
