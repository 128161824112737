export enum TourSectionsEnum {
    OVERVIEW,
    USERS,
    CREATE_USER,
    PROJECT_SPACES,
    PROJECTS,
    NEW_PROJECT,
    NEW_PROJECT_TEAM,
    NEW_PROJECT_TEAM_ADD_MEMBER,
    NEW_PROJECT_TEAM_ADD_MEMBER_FORM,
    REPORTS,
    TIME_REQUESTS,
    MARKETPLACE,
    COMPANY_SETTINGS,
    ACTIVITY,
    EFFECTIVITY,
    DAILY_RESULT,
}
