import { Moment } from 'moment-timezone';

import { ProjectPositionInterface } from '@app-client/project/types/project-position.interface';
import { ProjectRoleEnum } from '@app-client/project/types/project-role.enum';
import { ProjectInterface } from '@app-client/project/types/project.interface';
import { UserInterface } from '@app-client/user/types/user.interface';


export interface ProjectMemberInterface {
    id: string;
    user?: UserInterface;
    project?: ProjectInterface;
    projectRole?: ProjectRoleEnum;
    projectPosition?: ProjectPositionInterface;
    archivedAt: Moment | null;
    effectivity?: {
        id: string;
        template?: {
            id: string;
            name: string;
        };
        subscribed: boolean;
    };
    permissions: Array<{
        id: ProjectMemberPermissionsEnum;
    }>;
}


export enum ProjectMemberPermissionsEnum {
    MANAGE_TASK = 'MANAGE_TASK',
    MANAGE_SCREENSHOT_BLUR = 'MANAGE_SCREENSHOT_BLUR',
    SHARED_DAILY_REPORT = 'SHARED_DAILY_REPORT',
    DAILY_RESULT_COMMENTS_NOT_REQUIRED = 'DAILY_RESULT_COMMENTS_NOT_REQUIRED',
    DAILY_RESULT_REPORT_NOT_REQUIRED = 'DAILY_RESULT_REPORT_NOT_REQUIRED',
}

export enum ProjectMemberPermissionActionEnum {
    ADD = 'add',
    REMOVE = 'remove',
}
