export enum ActionTypes {
    GET_CURRENT_USER = '[Auth] Get current user',
    GET_CURRENT_USER_SUCCESS = '[Auth] Get current user success',
    GET_CURRENT_USER_FAILURE = '[Auth] Get current user failure',

    GET_CURRENT_COMPANY = '[Auth] Get current company',
    GET_CURRENT_COMPANY_SUCCESS = '[Auth] Get current company success',
    GET_CURRENT_COMPANY_FAILURE = '[Auth] Get current company failure',

    GET_COMPANY_TRACKING_SETTINGS = '[Auth] Get company tracking settings',
    GET_COMPANY_TRACKING_SETTINGS_SUCCESS = '[Auth] Get company tracking settings success',
    GET_COMPANY_TRACKING_SETTINGS_FAILURE = '[Auth] Get company tracking settings failure',

    LOGIN = '[Auth] Login',
    LOGIN_SUCCESS = '[Auth] Login success',
    LOGIN_SUCCESS_FAILURE = '[Auth] Login failure',
    LOGOUT = '[Auth] Logout',

    CURRENT_LANGUAGE_CHANGED = '[Auth] Current language changed',
    CURRENT_LANGUAGE_CHANGED_SUCCESS = '[Auth] Current language changed success',

    FORGOT_PASSWORD_REQUEST = '[Auth/ForgotPassword] Forgot password request',
    FORGOT_PASSWORD_REQUEST_SUCCESS = '[Auth/ForgotPassword] Forgot password request success',
    FORGOT_PASSWORD_REQUEST_FAILURE = '[Auth/ForgotPassword] Forgot password request failure',
    CLEAR_FORGOT_PASSWORD_REQUEST = '[Auth/ForgotPassword] Clear forgot password request',

    FORGOT_PASSWORD_VALIDATE_TOKEN = '[Auth/ForgotPassword] Forgot password validate token',
    FORGOT_PASSWORD_VALIDATE_TOKEN_SUCCESS = '[Auth/ForgotPassword] Forgot password confirm validate token success',
    FORGOT_PASSWORD_VALIDATE_TOKEN_FAILURE = '[Auth/ForgotPassword] Forgot password confirm validate token failure',

    FORGOT_PASSWORD_SAVE_NEW_PASSWORD = '[Auth/ForgotPassword] Forgot password save new password',
    FORGOT_PASSWORD_SAVE_NEW_PASSWORD_SUCCESS = '[Auth/ForgotPassword] Forgot password save new password success',
    FORGOT_PASSWORD_SAVE_NEW_PASSWORD_FAILURE = '[Auth/ForgotPassword] Forgot password save new password failure',
    CLEAR_FORGOT_PASSWORD_SAVE_NEW_PASSWORD = '[Auth/ForgotPassword] Clear forgot password save new password',

    ACCESS_REQUEST_SEND = '[Auth/AccessRequest] Access request send',
    ACCESS_REQUEST_SEND_SUCCESS = '[Auth/AccessRequest] Access request send success',
    ACCESS_REQUEST_SEND_FAILURE = '[Auth/AccessRequest] Access request send failure',
    CLEAR_ACCESS_REQUEST_SEND = '[Auth/AccessRequest] Clear access request send',

    LOGIN_UNDER_USER = '[Auth/LoginUnderUser] Login under user',
    LOGIN_UNDER_USER_SUCCESS = '[Auth/LoginUnderUser] Login under user success',
    LOGIN_UNDER_USER_FAILURE = '[Auth/LoginUnderUser] Login under user failure',

    CURRENT_TIMEZONE_CHANGED = '[Auth] Current timezone changed',
}
