import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';


@Component({
    selector: '[app-form-errors]',
    templateUrl: './form-errors.component.html',
})
export class FormErrorsComponent {
    @Input() formControlRef: AbstractControl;
    @Input() controlName: string;
    @Input() controlNameIsMultiple = false;
    @Input() controlNameGender: 'male' | 'female' | 'other' = 'other';
}
