import { createAction, props } from '@ngrx/store';

import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';

import { SignatureDocumentEnum } from './signature-document.enum';
import { SignatureDocumentType } from './types/signature-document.type';


export const signatureGetDocumentAction = createAction(
    SignatureDocumentEnum.GET_DOCUMENT,
);

export const signatureGetDocumentSuccessAction = createAction(
    SignatureDocumentEnum.GET_DOCUMENT_SUCCESS,
    props<{ document: SignatureDocumentType }>(),
);

export const signatureGetDocumentFailureAction = createAction(
    SignatureDocumentEnum.GET_DOCUMENT_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
