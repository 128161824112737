import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as moment from 'moment-timezone';
import { map, tap } from 'rxjs/operators';

import { dropDailyStatisticsAction } from '@app-client/store/activity/actions/activity-data.action';
import { currentTimezoneChangedAction } from '@app-client/store/auth/actions/current-user-timezone.action';


@Injectable()
export class TimezoneChangeEffect {
    constructor(
        private actions$: Actions,
    ) {}

    currentTimezoneChanged$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(currentTimezoneChangedAction),
            tap(({ timezone }) => moment.tz.setDefault(timezone)),
            map(() => dropDailyStatisticsAction()),
        );
    });
}
