import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import {
    marketplaceModulesGetActivatedUsersAction,
    marketplaceModulesGetActivatedUsersFailureAction,
    marketplaceModulesGetActivatedUsersSuccessAction,
    marketplaceModulesGetDetailsAction,
    marketplaceModulesGetDetailsFailureAction,
    marketplaceModulesGetDetailsSuccessAction,
    marketplaceModulesGetEnabledAction,
    marketplaceModulesGetEnabledFailureAction,
    marketplaceModulesGetEnabledSuccessAction,
    marketplaceModulesGetFreeTariffsAction,
    marketplaceModulesGetFreeTariffsFailureAction,
    marketplaceModulesGetFreeTariffsSuccessAction,
    marketplaceModulesGetListAction,
    marketplaceModulesGetListFailureAction,
    marketplaceModulesGetListSuccessAction,
    marketplaceModulesInstallAction,
    marketplaceModulesInstallFailureAction,
    marketplaceModulesInstallSuccessAction,
    marketplaceModulesUninstallAction,
    marketplaceModulesUninstallFailureAction,
    marketplaceModulesUninstallSuccessAction,
    marketplaceModulesUpdatePlanAction,
    marketplaceModulesUpdatePlanFailureAction,
    marketplaceModulesUpdatePlanSuccessAction,
    marketplaceModulesUpdateSettingsAction,
    marketplaceModulesUpdateSettingsFailureAction,
    marketplaceModulesUpdateSettingsSuccessAction,
} from '@app-client/store/marketplace/modules/actions/marketplace-modules.actions';
import {
    marketplaceModulesActivatedUsersSelector, marketplaceModulesDetailsSelector,
} from '@app-client/store/marketplace/modules/selectors/marketplace-modules.selectors';
import { MarketplaceModulesService } from '@app-client/store/marketplace/modules/services/marketplace-modules.service';
import { signatureUnsignedIntervalsGetListAction } from '@app-client/store/signature/unsiged-intervals/signature-unsigned-intervals.actions';
import { AppModulesEnum } from '@app-shared/types/app-modules.enum';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class MarketplaceModulesEffect {
    constructor(
        private actions$: Actions,
        private marketplaceModulesService: MarketplaceModulesService,
        private store: Store,
        private toastr: ToastrService,
        private translate: TranslateService,
    ) {}

    getListEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(marketplaceModulesGetListAction),
            switchMap(() => {
                return this.marketplaceModulesService.getList().pipe(
                    map((modules) => {
                        return marketplaceModulesGetListSuccessAction({ modules });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(marketplaceModulesGetListFailureAction({ backendErrors }));
                    }),
                );
            })
        );
    });

    getDetailsEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(marketplaceModulesGetDetailsAction),
            switchMap(({ id }) => {
                return this.marketplaceModulesService.getDetails(id).pipe(
                    map((details) => {
                        return marketplaceModulesGetDetailsSuccessAction({ details });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(marketplaceModulesGetDetailsFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    installEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(marketplaceModulesInstallAction),
            switchMap(({ moduleId, tariffId, forAll }) => {
                return this.marketplaceModulesService.install(moduleId, tariffId, forAll).pipe(
                    map((companyModule) => {
                        return marketplaceModulesInstallSuccessAction({ moduleId, companyModule });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(marketplaceModulesInstallFailureAction({ backendErrors }));
                    }),
                );
            })
        );
    });

    installSuccessEffect$ = createEffect( () => {
        return this.actions$.pipe(
            ofType(marketplaceModulesInstallSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('MARKETPLACE.PAYMENT.PLANS.PLANS.NOTIFICATIONS.INSTALL.SUCCESS.MESSAGE'),
                    this.translate.instant('MARKETPLACE.PAYMENT.PLANS.PLANS.NOTIFICATIONS.INSTALL.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    installFailureEffect$ = createEffect( () => {
        return this.actions$.pipe(
            ofType(marketplaceModulesInstallFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('MARKETPLACE.PAYMENT.PLANS.PLANS.NOTIFICATIONS.INSTALL.FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    uninstallEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(marketplaceModulesUninstallAction),
            switchMap(({ moduleId }) => {
                return this.marketplaceModulesService.uninstall(moduleId).pipe(
                    map(() => {
                        return marketplaceModulesUninstallSuccessAction({ moduleId });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(marketplaceModulesUninstallFailureAction({ backendErrors }));
                    }),
                );
            })
        );
    });

    uninstallSuccessEffect$ = createEffect( () => {
        return this.actions$.pipe(
            ofType(marketplaceModulesUninstallSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('MARKETPLACE.PAYMENT.PLANS.PLANS.NOTIFICATIONS.UNINSTALL.SUCCESS.MESSAGE'),
                    this.translate.instant('MARKETPLACE.PAYMENT.PLANS.PLANS.NOTIFICATIONS.UNINSTALL.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    uninstallFailureEffect$ = createEffect( () => {
        return this.actions$.pipe(
            ofType(marketplaceModulesUninstallFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('MARKETPLACE.PAYMENT.PLANS.PLANS.NOTIFICATIONS.UNINSTALL.FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    updatePlanEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(marketplaceModulesUpdatePlanAction),
            switchMap(({ moduleId, tariffId }) => {
                return this.marketplaceModulesService.updateTariff(moduleId, tariffId).pipe(
                    map((companyModule) => {
                        return marketplaceModulesUpdatePlanSuccessAction({ moduleId, companyModule });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(marketplaceModulesUpdatePlanFailureAction({ backendErrors }));
                    }),
                );
            })
        );
    });

    updatePlaneSuccessEffect$ = createEffect( () => {
        return this.actions$.pipe(
            ofType(marketplaceModulesUpdatePlanSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('MARKETPLACE.PAYMENT.PLANS.PLANS.NOTIFICATIONS.SELECT_PLAN.SUCCESS.MESSAGE'),
                    this.translate.instant('MARKETPLACE.PAYMENT.PLANS.PLANS.NOTIFICATIONS.SELECT_PLAN.SUCCESS.TITLE'),
                );
            }),
            map( () => {
                return marketplaceModulesGetEnabledAction();
            })
        );
    });

    updatePlaneFailureEffect$ = createEffect( () => {
        return this.actions$.pipe(
            ofType(marketplaceModulesUpdatePlanFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('MARKETPLACE.PAYMENT.PLANS.PLANS.NOTIFICATIONS.SELECT_PLAN.FAILURE.TITLE'),
                );
            }),
            map( () => {
                return marketplaceModulesGetEnabledAction();
            })
        );
    });

    updateEnabledEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                marketplaceModulesInstallSuccessAction,
                marketplaceModulesUninstallSuccessAction,
                marketplaceModulesUpdatePlanSuccessAction
            ),
            map(() => {
                return marketplaceModulesGetEnabledAction();
            }),
        );
    });

    updateSettingsEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(marketplaceModulesUpdateSettingsAction),
            withLatestFrom(this.store.select(marketplaceModulesActivatedUsersSelector)),
            switchMap(([{ moduleId, forAll, users }, activatedUsers]) => {
                const usersToAdd = users.filter((user) => {
                    return activatedUsers.every((activatedUser) => {
                        return activatedUser.id !== user.id;
                    });
                });
                const usersToDelete = activatedUsers.filter((activatedUser) => {
                    return users.every((user) => {
                        return activatedUser.id !== user.id;
                    });
                });

                return this.marketplaceModulesService.updateSettings(moduleId, forAll, usersToAdd, usersToDelete).pipe(
                    map((companyModule) => {
                        return marketplaceModulesUpdateSettingsSuccessAction({ moduleId, companyModule, usersToAdd, usersToDelete });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(marketplaceModulesUpdateSettingsFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getActivatedUsersEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(marketplaceModulesGetActivatedUsersAction),
            withLatestFrom(this.store.select(marketplaceModulesDetailsSelector)),
            switchMap(([, details]) => {
                return this.marketplaceModulesService.getActivatedUsers(details.id).pipe(
                    map((activatedUsers) => {
                        return marketplaceModulesGetActivatedUsersSuccessAction({ activatedUsers });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(marketplaceModulesGetActivatedUsersFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getEnabledEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(marketplaceModulesGetEnabledAction),
            switchMap(() => {
                return this.marketplaceModulesService.getEnabled().pipe(
                    map((enabled) => {
                        return marketplaceModulesGetEnabledSuccessAction({ enabled });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(marketplaceModulesGetEnabledFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getEnabledSuccessEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(marketplaceModulesGetEnabledSuccessAction),
            tap(({ enabled }) => {
                if (!!enabled.find(module => module.moduleId === AppModulesEnum.SIGNATURE)) {
                    this.store.dispatch(signatureUnsignedIntervalsGetListAction());
                }
            }),
        );
    }, { dispatch: false });

    getFreeTariffsEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(marketplaceModulesGetFreeTariffsAction),
            switchMap(() => {
                return this.marketplaceModulesService.getDetails(AppModulesEnum.CORE).pipe(
                    map((details) => {
                        return marketplaceModulesGetFreeTariffsSuccessAction({
                            freeTariffs: details.companyModule.tariff.freeTariffs.map((freeTariff) => {
                                return freeTariff.id;
                            }),
                        });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(marketplaceModulesGetFreeTariffsFailureAction({ backendErrors }));
                    }),
                );
            })
        );
    });

    getFreeTariffsFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(marketplaceModulesGetFreeTariffsFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                );
            }),
        );
    }, { dispatch: false});
}
