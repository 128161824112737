import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EffectivityConfigBulkUpdateInterface } from '@app-client/project/types/effectivity-config-bulk-update.interface';
import { EffectivityConfigDetailsInterface } from '@app-client/project/types/effectivity-config-details.interface';
import { EffectivityConfigSetInterface } from '@app-client/project/types/effectivity-config-set.interface';
import { ProjectMemberDetailsInterface } from '@app-client/project/types/project-member-details.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';

import { environment } from '@env-client/environment';


@Injectable()
export class EffectivityConfigService {
    constructor(
        private http: HttpClient,
    ) {}

    public getEffectivityConfigDetailsByProjectAndUser(projectId: string, userId: string)
        : Observable<ResponseInterface<EffectivityConfigDetailsInterface>>
    {
        const httpParams = new HttpParams().set('project', projectId).set('user', userId);
        return this.http.get<ResponseInterface<EffectivityConfigDetailsInterface>>(
            environment.apiBaseUrl + '/effectivity/config',
            { params: httpParams }
        );
    }

    public getEffectivityConfigDetailsByProjectMember(projectMemberId: string)
        : Observable<ResponseInterface<EffectivityConfigDetailsInterface>>
    {
        return this.http.get<ResponseInterface<EffectivityConfigDetailsInterface>>(
            environment.apiBaseUrl + '/effectivity/config/project-member/' + projectMemberId
        );
    }

    public setEffectivityConfigByProjectMember(projectMemberId: string, config: EffectivityConfigSetInterface)
        : Observable<ResponseInterface<ProjectMemberDetailsInterface>>
    {
        return this.http.post<ResponseInterface<ProjectMemberDetailsInterface>>(
            environment.apiBaseUrl + '/effectivity/config/project-member/' + projectMemberId,
            config,
        );
    }

    public effectivityConfigBulkUpdate(bulkUpdate: EffectivityConfigBulkUpdateInterface)
        : Observable<ResponseInterface<null>>
    {
        return this.http.post<ResponseInterface<null>>(
            environment.apiBaseUrl + '/effectivity/config/bulk-setting',
            bulkUpdate
        );
    }
}
