import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import {
    createEffectivityTemplateAction,
    createEffectivityTemplateFailureAction,
    createEffectivityTemplateSuccessAction,
    deleteEffectivityTemplateAction,
    deleteEffectivityTemplateFailureAction,
    deleteEffectivityTemplateSuccessAction,
    getEffectivityTemplateDetailsAction,
    getEffectivityTemplateDetailsFailureAction,
    getEffectivityTemplateDetailsSuccessAction,
    getEffectivityTemplateListAction,
    getEffectivityTemplateListFailureAction,
    getEffectivityTemplateListSuccessAction, setEffectivityTemplateQueryParamsAction,
    updateEffectivityTemplateAction,
    updateEffectivityTemplateFailureAction,
    updateEffectivityTemplateSuccessAction,
} from '@app-client/store/effectivity-template/actions/effectivity-template.actions';
import { effectivityTemplateListQueryParamsSelector } from '@app-client/store/effectivity-template/selectors/effectivity-template.selectors';
import { EffectivityTemplateService } from '@app-client/store/effectivity-template/services/effectivity-template.service';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class EffectivityTemplateEffects {
    constructor(
        private actions$: Actions,
        private effectivityService: EffectivityTemplateService,
        private store$: Store,
        private toastr: ToastrService,
        private translate: TranslateService,
        private router: Router,
    ) {}

    getEffectivityTemplateList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getEffectivityTemplateListAction),
            withLatestFrom(this.store$.select(effectivityTemplateListQueryParamsSelector)),
            switchMap(([, queryParams]) => {
                return this.effectivityService.getEffectivityTemplateList(queryParams).pipe(
                    map(response => {
                        return getEffectivityTemplateListSuccessAction({ response });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse.error.message,
                            errors: httpErrorResponse.error.errors,
                        };

                        return of(getEffectivityTemplateListFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getEffectivityTemplateListFailure$ = createEffect(() => {
       return this.actions$.pipe(
           ofType(getEffectivityTemplateListFailureAction),
           tap(({ backendErrors }) => {
               this.toastr.error(
                   backendErrors.message,
                   this.translate.instant('EFFECTIVITY_TEMPLATE.NOTIFICATIONS.GET_LIST.ERROR.TITLE'),
               );
           }),
       );
    }, { dispatch: false });

    getEffectivityTemplateDetails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getEffectivityTemplateDetailsAction),
            switchMap(({ id }) => {
                return this.effectivityService.getEffectivityTemplate(id).pipe(
                    map(response => {
                        return getEffectivityTemplateDetailsSuccessAction({ response });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse.error.message,
                            errors: httpErrorResponse.error.errors,
                        };

                        return of(getEffectivityTemplateDetailsFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getEffectivityTemplateDetailsFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getEffectivityTemplateDetailsFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('EFFECTIVITY_TEMPLATE.NOTIFICATIONS.GET_DETAILS.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    createEffectivityTemplate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createEffectivityTemplateAction),
            switchMap(({ effectivityTemplate }) => {
                return this.effectivityService.createEffectivityTemplate(effectivityTemplate).pipe(
                    map(response => {
                        return createEffectivityTemplateSuccessAction({ response });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse.error.message,
                            errors: httpErrorResponse.error.errors,
                        };

                        return of(createEffectivityTemplateFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    createEffectivityTemplateSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createEffectivityTemplateSuccessAction),
            tap(({ response }) => {
                this.toastr.success(
                    this.translate.instant('EFFECTIVITY_TEMPLATE.NOTIFICATIONS.CREATE.SUCCESS.MESSAGE'),
                    this.translate.instant('EFFECTIVITY_TEMPLATE.NOTIFICATIONS.CREATE.SUCCESS.TITLE'),
                );
                this.router.navigate(['company', 'effectivity-templates', response.body.id]);
            }),
        );
    }, { dispatch: false });

    createEffectivityTemplateFailure$ = createEffect(() => {
        return this.actions$.pipe(
           ofType(createEffectivityTemplateFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('EFFECTIVITY_TEMPLATE.NOTIFICATIONS.CREATE.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    updateEffectivityTemplate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateEffectivityTemplateAction),
            switchMap(({ id, effectivityTemplate, successCallback }) => {
                return this.effectivityService.updateEffectivityTemplate(id, effectivityTemplate).pipe(
                    map(response => {
                        return updateEffectivityTemplateSuccessAction({ response, successCallback });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse.error.message,
                            errors: httpErrorResponse.error.errors,
                        };

                        return of(updateEffectivityTemplateFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    updateEffectivityTemplateSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateEffectivityTemplateSuccessAction),
            tap(({ response, successCallback }) => {
                this.toastr.success(
                    this.translate.instant('EFFECTIVITY_TEMPLATE.NOTIFICATIONS.UPDATE.SUCCESS.MESSAGE'),
                    this.translate.instant('EFFECTIVITY_TEMPLATE.NOTIFICATIONS.UPDATE.SUCCESS.TITLE'),
                );
                if (undefined === successCallback) {
                    this.router.navigate(['company', 'effectivity-templates', response.body.id]);
                }
                if (undefined !== successCallback) {
                    successCallback();
                }
            }),
        );
    }, { dispatch: false });

    updateEffectivityTemplateFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateEffectivityTemplateFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('EFFECTIVITY_TEMPLATE.NOTIFICATIONS.UPDATE.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    deleteEffectivityTemplate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteEffectivityTemplateAction),
            switchMap(({ id }) => {
                return this.effectivityService.deleteEffectivityTemplate(id).pipe(
                    map(response => {
                        return deleteEffectivityTemplateSuccessAction();
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse.error.message,
                            errors: httpErrorResponse.error.errors,
                        };

                        return of(deleteEffectivityTemplateFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    deleteEffectivityTemplateSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteEffectivityTemplateSuccessAction),
            withLatestFrom(this.store$.select(effectivityTemplateListQueryParamsSelector)),
            switchMap(([, params]) => {
                this.toastr.success(
                    this.translate.instant('EFFECTIVITY_TEMPLATE.NOTIFICATIONS.DELETE.SUCCESS.MESSAGE'),
                    this.translate.instant('EFFECTIVITY_TEMPLATE.NOTIFICATIONS.DELETE.SUCCESS.TITLE'),
                );
                return of(
                    setEffectivityTemplateQueryParamsAction({
                        params: {
                            ...params,
                            page: 1,
                        },
                    }),
                    getEffectivityTemplateListAction(),
                );
            }),
        );
    });

    deleteEffectivityTemplateFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteEffectivityTemplateFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('EFFECTIVITY_TEMPLATE.NOTIFICATIONS.DELETE.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });
}
