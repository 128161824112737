export enum ActionTypes {
    SET_LIST_QUERY_PARAMS = '[EffectivityTemplate] Set list query prams',
    GET_LIST = '[EffectivityTemplate] Get list',
    GET_LIST_SUCCESS = '[EffectivityTemplate] Get list success',
    GET_LIST_FAILURE = '[EffectivityTemplate] Get list failure',
    GET_DETAILS = '[EffectivityTemplate] Get details',
    GET_DETAILS_SUCCESS = '[EffectivityTemplate] Get details success',
    GET_DETAILS_FAILURE = '[EffectivityTemplate] Get details failure',
    CREATE = '[EffectivityTemplate] Create',
    CREATE_SUCCESS = '[EffectivityTemplate] Create success',
    CREATE_FAILURE = '[EffectivityTemplate] Create failure',
    UPDATE = '[EffectivityTemplate] Update',
    UPDATE_SUCCESS = '[EffectivityTemplate] Update success',
    UPDATE_FAILURE = '[EffectivityTemplate] Update failure',
    DELETE = '[EffectivityTemplate] Delete',
    DELETE_SUCCESS = '[EffectivityTemplate] Delete success',
    DELETE_FAILURE = '[EffectivityTemplate] Delete failure',
}
