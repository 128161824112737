import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import {
    archiveProjectAction,
    archiveProjectFailureAction,
    archiveProjectSuccessAction,
    createProjectAction,
    createProjectFailureAction,
    createProjectSuccessAction,
    getProjectAction,
    getProjectFailureAction,
    getProjectListAction,
    getProjectListFailureAction,
    getProjectListForEditTimeAction,
    getProjectListForEditTimeFailureAction,
    getProjectListForEditTimeSuccessAction,
    getProjectListSuccessAction,
    getProjectSuccessAction,
    restoreProjectAction,
    restoreProjectFailureAction,
    restoreProjectSuccessAction,
    updateProjectAction,
    updateProjectFailureAction,
    updateProjectSuccessAction,
} from '@app-client/store/projects/actions/projects.actions';
import {
    projectListForEditTimeQueryParamsSelector,
    projectListQueryParamsSelector,
} from '@app-client/store/projects/selectors/projects.selectors';
import { ProjectsService } from '@app-client/store/projects/services/projects.service';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class ProjectsEffects {

    constructor(
        private actions$: Actions,
        private projectsService: ProjectsService,
        private store$: Store,
        private toastr: ToastrService,
        private translate: TranslateService,
        private router: Router,
    ) {}

    getProjectList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getProjectListAction),
            withLatestFrom(this.store$.select(projectListQueryParamsSelector)),
            switchMap(([, queryParams]) => {
                return this.projectsService.getProjectsList(queryParams).pipe(
                    map(response => {
                        return getProjectListSuccessAction({ response });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(getProjectListFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getProjectListFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getProjectListFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('PROJECTS.NOTIFICATIONS.GET_LIST.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    getProjectListForEditTime$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getProjectListForEditTimeAction),
            withLatestFrom(this.store$.select(projectListForEditTimeQueryParamsSelector)),
            switchMap(([, queryParams]) => {
                return this.projectsService.getProjectsList(queryParams).pipe(
                    map(response => {
                        return getProjectListForEditTimeSuccessAction({ response });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(getProjectListForEditTimeFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getProjectListForEditTimeFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getProjectListForEditTimeFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('PROJECTS.NOTIFICATIONS.GET_LIST.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    getProject$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getProjectAction),
            switchMap(({ id, includePermissions }) => {
                return this.projectsService.getProject(id, includePermissions).pipe(
                    map(response => {
                        return getProjectSuccessAction({ response });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(getProjectFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getProjectFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getProjectFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('PROJECTS.NOTIFICATIONS.GET_ONE.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    createProject$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createProjectAction),
            switchMap(({ project }) => {
                return this.projectsService.createProject(project).pipe(
                    map(response => createProjectSuccessAction({ response })),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(createProjectFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    createProjectSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createProjectSuccessAction),
            tap(({ response }) => {
                this.toastr.success(
                    this.translate.instant('PROJECTS.NOTIFICATIONS.CREATE.SUCCESS.MESSAGE'),
                    this.translate.instant('PROJECTS.NOTIFICATIONS.CREATE.SUCCESS.TITLE'),
                );
                this.router.navigate(['projects', response.body.id]);
            }),
        );
    }, { dispatch: false });

    createProjectFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createProjectFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('PROJECTS.NOTIFICATIONS.CREATE.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    archiveProject$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(archiveProjectAction),
            switchMap(({ id, successCallback }) => {
                return this.projectsService.archiveProject(id).pipe(
                    map(response => {
                        return archiveProjectSuccessAction({ response, successCallback });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(archiveProjectFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    archiveProjectSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(archiveProjectSuccessAction),
            tap(({ successCallback }) => {
                this.toastr.success(
                    this.translate.instant('PROJECTS.NOTIFICATIONS.ARCHIVE.SUCCESS.MESSAGE'),
                    this.translate.instant('PROJECTS.NOTIFICATIONS.ARCHIVE.SUCCESS.TITLE'),
                );

                if (successCallback && typeof successCallback === 'function') {
                    successCallback();
                }
            }),
        );
    }, { dispatch: false });

    archiveProjectFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(archiveProjectFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('PROJECTS.NOTIFICATIONS.ARCHIVE.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    updateProject$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateProjectAction),
            switchMap(({ id, project }) => {
                return this.projectsService.updateProject(id, project).pipe(
                    map(response => updateProjectSuccessAction({ response })),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(updateProjectFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    updateProjectSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateProjectSuccessAction),
            map(({ response }) => {
                this.toastr.success(
                    this.translate.instant('PROJECTS.NOTIFICATIONS.UPDATE.SUCCESS.MESSAGE'),
                    this.translate.instant('PROJECTS.NOTIFICATIONS.UPDATE.SUCCESS.TITLE'),
                );
                this.router.navigate(['projects', response.body.id]);
                return getProjectAction({ id: response.body.id });
            }),
        );
    });

    updateProjectFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateProjectFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('PROJECTS.NOTIFICATIONS.UPDATE.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    restoreProject$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(restoreProjectAction),
            switchMap(({ id, successCallback }) => {
                return this.projectsService.restoreProject(id).pipe(
                    map(response => {
                        return restoreProjectSuccessAction({ response, successCallback });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(restoreProjectFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    restoreProjectSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(restoreProjectSuccessAction),
            tap(({ successCallback }) => {
                this.toastr.success(
                    this.translate.instant('PROJECTS.NOTIFICATIONS.RESTORE.SUCCESS.MESSAGE'),
                    this.translate.instant('PROJECTS.NOTIFICATIONS.RESTORE.SUCCESS.TITLE'),
                );

                this.router.navigate(['projects', 'archived']);

                if (successCallback && typeof successCallback === 'function') {
                    successCallback();
                }
            }),
        );
    }, { dispatch: false });

    restoreProjectFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(restoreProjectFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('PROJECTS.NOTIFICATIONS.RESTORE.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });
}
