import { Component, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';

import { PersistenceService } from '@app-client/shared/services/persistence.service';
import { getCurrentCompanyAction } from '@app-client/store/auth/actions/get-current-company.action';
import { isCompanyLoadingSelector } from '@app-client/store/auth/selectors/auth.selectors';
import { updateUserProfileDataAction } from '@app-client/store/user-profile/actions/user-profile-data.action';

import { environment } from '@env-client/environment';


@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    isCompanyLoading$: Observable<boolean>;

    constructor(
        private store: Store,
        private persistence: PersistenceService,
        private translate: TranslateService,
        private dateAdapter: DateAdapter<any>,
    ) {}

    ngOnInit(): void {
        this.initializeEnvironments();
        this.initializeLocale();
        this.fetchData();
        this.initializeValues();
        this.initializeTranslationObserver();
    }

    fetchData() {
        this.store.dispatch(getCurrentCompanyAction({ subDomain: environment.domain }));
    }

    initializeValues() {
        this.isCompanyLoading$ = this.store.select(isCompanyLoadingSelector);
    }

    initializeEnvironments() {
        const host = window.location.host.toLowerCase();
        const apiHost = environment.apiHost.toLowerCase();
        const subDomain = host.slice(0, host.indexOf(apiHost) - 1);

        environment.domain = subDomain;
        environment.apiBaseUrl = 'https://' + subDomain + '.' + environment.apiHost + '/api';
    }

    initializeLocale() {
        const supportedLangs = environment.locales;

        let storedLanguage = this.persistence.get('Accept-Language');
        if (!supportedLangs.includes(storedLanguage)) {
            storedLanguage = null;
        }

        const browserLang = window.navigator.languages.map(lang => lang.slice(0, 2)).find(lang => supportedLangs.includes(lang));

        const language = storedLanguage || browserLang || environment.defaultLocale || 'en';

        this.setLocale(language);
    }

    initializeTranslationObserver() {
        let lastLang = this.translate.currentLang;
        const supportedLangs = environment.locales;
        const config: MutationObserverInit = { attributes: true };
        const mutationsSubject = new Subject<MutationRecord[]>();

        const translationObserver = new MutationObserver((mutations) => {
            for (const mutation of mutations) {
                const target = mutation.target as HTMLElement;
                const classListChanged = mutation.attributeName === 'class';
                const isAutoTranslated = target.classList.contains('translated-ltr');
                const currentLang = this.translate.currentLang;
                const isAlreadyApplied = lastLang !== currentLang;
                const langAttr = target.getAttribute('lang');
                const autoLang = (!langAttr || langAttr === 'auto' || !supportedLangs.includes(langAttr)) ? 'en' : langAttr;

                if (classListChanged && isAutoTranslated && !isAlreadyApplied) {
                    this.store.dispatch(updateUserProfileDataAction({ profileData: { language: autoLang } }));
                }

                if (!isAutoTranslated && isAlreadyApplied) {
                    this.store.dispatch(updateUserProfileDataAction({ profileData: { language: lastLang } }));
                }
            }

            mutationsSubject.next(mutations);
        });

        this.translate.onLangChange.pipe(
            untilDestroyed(this),
            withLatestFrom(mutationsSubject.asObservable()),
        ).subscribe(([{ lang }, mutations]) => {
            // if only lang attribute was changed
            if (mutations.length === 1) {
                lastLang = lang;
            }
        });

        translationObserver.observe(document.documentElement, config);
    }

    setLocale(locale: string) {
        moment.locale(locale);
        document.documentElement.setAttribute('lang', locale);
        this.translate.use(locale);
        this.dateAdapter.setLocale(locale);
    }
}
