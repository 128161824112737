export enum AppModulesEnum {
    CORE = 'core',
    TRELLO_SYNC = 'trello-sync',
    SCREENSHOTS = 'screenshots',
    JIRA_SYNC = 'jira-sync',
    INVOICING = 'invoicing',
    YEARLY_REPORT = 'yearly-report',
    SIGNATURE = 'signature',
    HAPPINESS_INDEX = 'happiness-index',
    DAILY_RESULT = 'daily-result',
    KAITEN_SYNC = 'kaiten-sync'
}

export enum CoreModuleTariffsEnum {
    BASIC = 'core-basic',
    PRO = 'core-pro',
    BUSINESS = 'core-business',
}
