import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { UIModule } from '@umbrella-web/attrack-ui-module';

import { LoggedAsComponent } from '@app-client/logged-as/components/logged-as/logged-as.component';


@NgModule({
    declarations: [LoggedAsComponent],
    imports: [
        CommonModule,
        TranslateModule,
        UIModule,
    ],
    exports: [
        LoggedAsComponent,
    ],
})
export class LoggedAsModule {}
