import { createAction, props } from '@ngrx/store';

import { CreateProjectPositionInterface } from '@app-client/project/types/create-project-position.interface';
import { ProjectPositionListQueryParamsInterface } from '@app-client/project/types/project-position-list-query-params.interface';
import { ProjectPositionInterface } from '@app-client/project/types/project-position.interface';
import { UpdateProjectPositionInterface } from '@app-client/project/types/update-project-position.interface';
import { ProjectPositionsActionTypes } from '@app-client/store/project-posiitons/project-positions-action-types';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';
import { PageResponseInterface } from '@app-shared/types/page-response.interface';


export const setProjectPositionListQueryParamsAction = createAction(
    ProjectPositionsActionTypes.SET_LIST_QUERY_PARAMS,
    props<{ queryParams: ProjectPositionListQueryParamsInterface }>(),
);

export const getProjectPositionListAction = createAction(
    ProjectPositionsActionTypes.GET_LIST
);

export const getProjectPositionListSuccessAction = createAction(
    ProjectPositionsActionTypes.GET_LIST_SUCCESS,
    props<{ response: PageResponseInterface<ProjectPositionInterface> }>(),
);

export const getProjectPositionListFailureAction = createAction(
    ProjectPositionsActionTypes.GET_LIST_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const createProjectPositionAction = createAction(
    ProjectPositionsActionTypes.CREATE,
    props<{ createProjectPosition: CreateProjectPositionInterface, successCallback: () => void }>(),
);

export const createProjectPositionSuccessAction = createAction(
    ProjectPositionsActionTypes.CREATE_SUCCESS,
    props<{ successCallback: () => void }>(),
);

export const createProjectPositionFailureAction = createAction(
    ProjectPositionsActionTypes.CREATE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const updateProjectPositionAction = createAction(
    ProjectPositionsActionTypes.UPDATE,
    props<{ id: string, updateProjectPosition: UpdateProjectPositionInterface, successCallback: () => void }>(),
);

export const updateProjectPositionSuccessAction = createAction(
    ProjectPositionsActionTypes.UPDATE_SUCCESS,
    props<{ successCallback: () => void }>(),
);

export const updateProjectPositionFailureAction = createAction(
    ProjectPositionsActionTypes.UPDATE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const removeProjectPositionAction = createAction(
    ProjectPositionsActionTypes.REMOVE,
    props<{ id: string }>(),
);

export const removeProjectPositionSuccessAction = createAction(
    ProjectPositionsActionTypes.REMOVE_SUCCESS,
);

export const removeProjectPositionFailureAction = createAction(
    ProjectPositionsActionTypes.REMOVE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
