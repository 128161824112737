import { createFeatureSelector, createSelector } from '@ngrx/store';

import { EffectivityCategoryStateInterface } from '@app-client/store/effectivity-categories/effectivity-category-state.interface';


export const effectivityCategoryFeatureSelector = createFeatureSelector<EffectivityCategoryStateInterface>('effectivityCategory');

export const effectivityCategoryListSelector = createSelector(
    effectivityCategoryFeatureSelector,
    state => state.effectivityCategoryList,
);

export const isEffectivityCategoryListLoadingSelector = createSelector(
    effectivityCategoryFeatureSelector,
    state => state.isEffectivityCategoryListLoading,
);

export const isCreateEffectivityCategoryInProgressSelector = createSelector(
    effectivityCategoryFeatureSelector,
    state => state.isCreateEffectivityCategoryInProgress,
);

export const createEffectivityCategoryBackendErrorsSelector = createSelector(
    effectivityCategoryFeatureSelector,
    state => state.createEffectivityCategoryBackendErrors,
);

export const isUpdateEffectivityCategoryInProgressSelector = createSelector(
    effectivityCategoryFeatureSelector,
    state => state.isUpdateEffectivityCategoryInProgress,
);

export const updateEffectivityCategoryBackendErrorsSelector = createSelector(
    effectivityCategoryFeatureSelector,
    state => state.updateEffectivityCategoryBackendErrors,
);

export const isCreateEffectivitySubcategoryInProgressSelector = createSelector(
    effectivityCategoryFeatureSelector,
    state => state.isCreateEffectivitySubcategoryInProgress,
);

export const createEffectivitySubcategoryBackendErrorsSelector = createSelector(
    effectivityCategoryFeatureSelector,
    state => state.createEffectivitySubcategoryBackendErrors,
);

export const isUpdateEffectivitySubcategoryInProgressSelector = createSelector(
    effectivityCategoryFeatureSelector,
    state => state.isUpdateEffectivitySubcategoryInProgress,
);

export const updateEffectivitySubcategoryBackendErrorsSelector = createSelector(
    effectivityCategoryFeatureSelector,
    state => state.updateEffectivitySubcategoryBackendErrors,
);

export const effectivityAppListQueryParamsSelector = createSelector(
    effectivityCategoryFeatureSelector,
    state => state.effectivityAppListQueryParams,
);

export const effectivityAppListSelector = createSelector(
    effectivityCategoryFeatureSelector,
    state => state.effectivityAppList,
);

export const isEffectivityAppListLoadingSelector = createSelector(
    effectivityCategoryFeatureSelector,
    state => state.isEffectivityAppListLoading,
);
