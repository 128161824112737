export enum CompanyConfigActionEnum {
    GET_COMPANY_CONFIG_SCREENSHOTS = '[Company/Config] Get company screenshots config',
    GET_COMPANY_SCREENSHOTS_SUCCESS = '[Company/Config] Get company config screenshots success',
    GET_COMPANY_SCREENSHOTS_FAILURE = '[Company/Config] Get company config screenshots failure',

    UPDATE_COMPANY_CONFIG_SCREENSHOTS = '[Company/Config] Update company screenshots config',
    UPDATE_COMPANY_SCREENSHOTS_SUCCESS = '[Company/Config] Update company config screenshots success',
    UPDATE_COMPANY_SCREENSHOTS_FAILURE = '[Company/Config] Update company config screenshots failure',

    GET_COMPANY_CONFIG_SIGNATURE = '[Company/Config] Get company signature config',
    GET_COMPANY_SIGNATURE_SUCCESS = '[Company/Config] Get company config signature success',
    GET_COMPANY_SIGNATURE_FAILURE = '[Company/Config] Get company config signature failure',

    UPDATE_COMPANY_SIGNATURE_SCREENSHOTS = '[Company/Config] Update company signature config',
    UPDATE_COMPANY_SIGNATURE_SUCCESS = '[Company/Config] Update company config signature success',
    UPDATE_COMPANY_SIGNATURE_FAILURE = '[Company/Config] Update company config signature failure',

    GET_COMPANY_CONFIG_KAITEN = '[Company/Config] Get company kaiten config',
    GET_COMPANY_CONFIG_KAITEN_SUCCESS = '[Company/Config] Get company config kaiten success',
    GET_COMPANY_CONFIG_KAITEN_FAILURE = '[Company/Config] Get company config kaiten failure',

    UPDATE_COMPANY_CONFIG_KAITEN = '[Company/Config] Update company kaiten config',
    UPDATE_COMPANY_CONFIG_KAITEN_SUCCESS = '[Company/Config] Update company config kaiten success',
    UPDATE_COMPANY_CONFIG_KAITEN_FAILURE = '[Company/Config] Update company config kaiten failure',
}
