export enum JiraAuthActionEnum {
    GET_CONNECTIONS = '[Jira auth] Get connections',
    GET_CONNECTIONS_SUCCESS = '[Jira auth] Get connections success',
    GET_CONNECTIONS_FAILURE = '[Jira auth] Get connections failure',

    GET_CLOUD_OAUTH2_REDIRECT_URL = '[Jira auth] Get cloud oauth2 redirect url',
    GET_CLOUD_OAUTH2_REDIRECT_URL_SUCCESS = '[Jira auth] Get cloud oauth2 redirect url success',
    GET_CLOUD_OAUTH2_REDIRECT_URL_FAILURE = '[Jira auth] Get cloud oauth2 redirect url failure',

    VERIFY_CLOUD_OAUTH2 = '[Jira auth] Verify cloud oauth2',
    VERIFY_CLOUD_OAUTH2_SUCCESS = '[Jira auth] Verify cloud oauth2 success',
    VERIFY_CLOUD_OAUTH2_FAILURE = '[Jira auth] Verify cloud oauth2 failure',

    ATTACH_CLOUD_API_TOKEN = '[Jira auth] Attach cloud api token',
    ATTACH_CLOUD_API_TOKEN_SUCCESS = '[Jira auth] Attach cloud api token success',
    ATTACH_CLOUD_API_TOKEN_FAILURE = '[Jira auth] Attach cloud api token failure',

    GET_SELF_HOSTED_OAUTH1_REDIRECT_URL = '[Jira auth] Get self hosted oauth1 redirect url',
    GET_SELF_HOSTED_OAUTH1_REDIRECT_URL_SUCCESS = '[Jira auth] Get self hosted oauth1 redirect url success',
    GET_SELF_HOSTED_OAUTH1_REDIRECT_URL_FAILURE = '[Jira auth] Get self hosted oauth1 redirect url failure',

    VERIFY_SELF_HOSTED_OAUTH1 = '[Jira auth] Verify self hosted oauth1',
    VERIFY_SELF_HOSTED_OAUTH1_SUCCESS = '[Jira auth] Verify self hosted oauth1 success',
    VERIFY_SELF_HOSTED_OAUTH1_FAILURE = '[Jira auth] Verify self hosted oauth1 failure',

    AUTH_SELF_HOSTED_BASIC = '[Jira auth] Auth self hosted basic',
    AUTH_SELF_HOSTED_BASIC_SUCCESS = '[Jira auth] Auth self hosted basic success',
    AUTH_SELF_HOSTED_BASIC_FAILURE = '[Jira auth] Auth self hosted basic failure',

    LOGOUT = '[Jira auth] Logout',
    LOGOUT_SUCCESS = '[Jira auth] Logout success',
    LOGOUT_FAILURE = '[Jira auth] Logout failure',
}
