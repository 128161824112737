import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';

import { PersistenceService } from '@app-client/shared/services/persistence.service';
import { AppStateInterface } from '@app-client/shared/types/app-state.interface';
import { currentCompanySelector, isCompanyLoadingSelector } from '@app-client/store/auth/selectors/auth.selectors';


@Injectable()
export class UnauthorizedGuardService implements CanActivate {
    constructor(
        private router: Router,
        private store: Store<AppStateInterface>,
        private persistenceService: PersistenceService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // superadmin logged in
        if (
            (route.url[0] && route.url[0].path && route.url[0].path === 'login')
            && (route.queryParams && route.queryParams.is_show_logged_as_panel)
        ) {
            return true;
        }

        return this.store.select(isCompanyLoadingSelector).pipe(
            skipWhile(isCompanyLoading => isCompanyLoading || isCompanyLoading === null),
            take(1),
            switchMap(() => {
                return this.store.select(currentCompanySelector).pipe(
                    take(1),
                    map(currentCompany => {
                        const isLoggedIn = currentCompany && !!this.persistenceService.get('credentials');

                        if (isLoggedIn) {
                            this.router.navigate(['/activity']);

                            return false;
                        }

                        return true;
                    }),
                );
            }),
        );
    }
}
