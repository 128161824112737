import { TrelloBoardColumnSettingsInterface } from '@app-client/trello/types/trello-board-column-settings.interface';


export interface TrelloProjectStatusInterface {
    lastSyncTime?: Date;
    isWebhookRegistered: boolean;
    trelloBoardId?: string;
    trelloBoardName?: string;
    syncStatus?: number;
    columnSettings?: TrelloBoardColumnSettingsInterface;
    tokenStatus?: boolean;
}

export enum TrelloProjectSyncStatusEnum {
    IDLE = 0,
    IN_PROGRESS = 1,
}

export enum TrelloColumnFilteringTypeEnum {
    EXCLUDE = 1,
    INCLUDE = 2,
}
