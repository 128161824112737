import { createAction, props } from '@ngrx/store';

import { ActionTypes } from '@app-client/store/auth/action-types';
import { CredentialsInterface } from '@app-client/store/auth/types/credentials.interface';
import { UserInterface } from '@app-client/user/types/user.interface';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


export const loginUnderUserAction = createAction(
    ActionTypes.LOGIN_UNDER_USER,
    props<{ user: UserInterface }>(),
);

export const loginUnderUserSuccessAction = createAction(
    ActionTypes.LOGIN_UNDER_USER_SUCCESS,
    props<{ credentials: CredentialsInterface }>(),
);

export const loginUnderUserFailureAction = createAction(
    ActionTypes.LOGIN_UNDER_USER_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

