import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { UIModule } from '@umbrella-web/attrack-ui-module';

import { NotFoundComponent } from '@app-client/not-found/components/not-found/not-found.component';


const routes: Routes = [
    { path: '**', component: NotFoundComponent },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        UIModule,
        TranslateModule,
    ],
    declarations: [NotFoundComponent],
})
export class NotFoundModule {}
