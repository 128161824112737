export enum JiraConnectionActionEnum {
    GET_PROJECT_STATUS = '[Jira connection] Get project status',
    GET_PROJECT_STATUS_SUCCESS = '[Jira connection] Get project status success',
    GET_PROJECT_STATUS_FAILURE = '[Jira connection] Get project status failure',

    GET_PROJECTS = '[Jira connection] Get projects',
    GET_PROJECTS_SUCCESS = '[Jira connection] Get projects success',
    GET_PROJECTS_FAILURE = '[Jira connection] Get projects failure',

    GET_STATUSES = '[Jira connection] Get  statuses',
    GET_STATUSES_SUCCESS = '[Jira connection] Get statuses success',
    GET_STATUSES_FAILURE = '[Jira connection] Get statuses failure',

    GET_ISSUE_TYPES = '[Jira connection] Get issue types',
    GET_ISSUE_TYPES_SUCCESS = '[Jira connection] Get issue types success',
    GET_ISSUE_TYPES_FAILURE = '[Jira connection] Get issue types failure',

    GET_BOARDS = '[Jira connection] Get boards',
    GET_BOARDS_SUCCESS = '[Jira connection] Get boards success',
    GET_BOARDS_FAILURE = '[Jira connection] Get boards failure',

    GET_EPICS = '[Jira connection] Get epics',
    GET_EPICS_SUCCESS = '[Jira connection] Get epics success',
    GET_EPICS_FAILURE = '[Jira connection] Get epics failure',

    GET_SPRINTS = '[Jira connection] Get sprints',
    GET_SPRINTS_SUCCESS = '[Jira connection] Get sprints success',
    GET_SPRINTS_FAILURE = '[Jira connection] Get sprints failure',

    ATTACH = '[Jira connection] Attach',
    ATTACH_PROJECT_SUCCESS = '[Jira connection] Attach success',
    ATTACH_PROJECT_FAILURE = '[Jira connection] Attach failure',

    DETACH = '[Jira connection] Detach',
    DETACH_SUCCESS = '[Jira connection] Detach success',
    DETACH_FAILURE = '[Jira connection] Detach failure',

    UPDATE = '[Jira connection] Update',
    UPDATE_SUCCESS = '[Jira connection] Update success',
    UPDATE_FAILURE = '[Jira connection] Update failure',

    START_SYNC = '[Jira connection] Start sync',
    START_SYNC_SUCCESS = '[Jira connection] Start sync success',
    START_SYNC_FAILURE = '[Jira connection] Start sync failure',

    STOP_SYNC = '[Jira connection] Stop sync',
    STOP_SYNC_SUCCESS = '[Jira connection] Stop sync success',
    STOP_SYNC_FAILURE = '[Jira connection] Stop sync failure',

    GET_SYNC_STATUS = '[Jira connection] Get sync status',
    GET_SYNC_STATUS_SUCCESS = '[Jira connection] Get sync status success',
    GET_SYNC_STATUS_FAILURE = '[Jira connection] Get sync status failure',

    UPDATE_SYNC_TIME_SETTINGS = '[Jira connection] Update sync time settings',
    UPDATE_SYNC_TIME_SETTINGS_SUCCESS = '[Jira connection] Update sync time settings success',
    UPDATE_SYNC_TIME_SETTINGS_FAILURE = '[Jira connection] Update sync time settings failure',

    START_SYNC_TIME = '[Jira connection] Start sync time',
    START_SYNC_TIME_SUCCESS = '[Jira connection] Start sync time success',
    START_SYNC_TIME_FAILURE = '[Jira connection] Start sync time failure',

    SET_SYNC_TYPE = '[Jira connection] Set sync type',
}
