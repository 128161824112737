export enum TourActionTypes {
    SET_TOUR_SECTION = '[Tour] Set section',
    NEXT_TOUR_STEP = '[Tour] Next step',
    END_TOUR = '[Tour] End',
    TOUR_SEND_STATUS_STARTED = '[Tour] Send status started',
    TOUR_STATUS_RESTARTED = '[Tour] Restarted',
    TOUR_SEND_STATUS_STARTED_SUCCESS = '[Tour] Send status started success',
    TOUR_SEND_STATUS_STARTED_FAILED = '[Tour] Send status started failed',
    TOUR_SEND_STATUS_FINISHED = '[Tour] Send status finished',
    TOUR_SEND_STATUS_FINISHED_SUCCESS = '[Tour] Send status finished success',
    TOUR_SEND_STATUS_FINISHED_FAILED = '[Tour] Send status finished failed',
    LOAD_COMPANY_ONBOARDING_DATA = '[Tour] Load company onboarding data',
    LOAD_COMPANY_ONBOARDING_DATA_SUCCESS = '[Tour] Load company onboarding data success',
    LOAD_COMPANY_ONBOARDING_DATA_FAILED = '[Tour] Load company onboarding data failed',
    DELETE_COMPANY_ONBOARDING_DATA = '[Tour] Delete company onboarding data',
    DELETE_COMPANY_ONBOARDING_DATA_SUCCESS = '[Tour] Delete company onboarding data success',
    DELETE_COMPANY_ONBOARDING_DATA_FAILED = '[Tour] Delete company onboarding data failed',
    CHECK_COMPANY_ONBOARDING_DATA = '[Tour] Check company onboarding data',
    CHECK_COMPANY_ONBOARDING_DATA_SUCCESS = '[Tour] Check company onboarding data success',
    CHECK_COMPANY_ONBOARDING_DATA_FAILED = '[Tour] Check company onboarding data failed',

    TOUR_OPEN_WELCOME_DIALOG = '[Tour] Open welcome dialog',
    TOUR_OPEN_RESTART_DIALOG = '[Tour] Open restart dialog',

    DISABLE_DAILY_RESULT_SANDBOX = '[Tour] Disable daily result sandbox',
    ENABLE_DAILY_RESULT_SANDBOX = '[Tour] Enable daily result sandbox',
}
