import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { PersistenceService } from '@app-client/shared/services/persistence.service';
import { getCurrentUserAction } from '@app-client/store/auth/actions/get-current-user.action';
import {
    loginUnderUserAction,
    loginUnderUserFailureAction,
    loginUnderUserSuccessAction,
} from '@app-client/store/auth/actions/login-under-user.action';
import { AuthService } from '@app-client/store/auth/services/auth.service';
import { CredentialsInterface } from '@app-client/store/auth/types/credentials.interface';
import { showLoggedAsAction } from '@app-client/store/logged-as/actions/logged-as.action';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';
import { jwtDecode } from '@app-shared/utils/jwtDecode';


@Injectable()
export class LoginUnderUserEffect {
    loginUnderUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loginUnderUserAction),
            switchMap(({ user }) => {
                return this.authService.loginUnderUser(user.id).pipe(
                    map((credentials) => {
                        return loginUnderUserSuccessAction({ credentials });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(loginUnderUserFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    loginUnderUserSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loginUnderUserSuccessAction),
            switchMap(({ credentials }) => {
                const dispatchedActions = [];

                const currentCredentials: CredentialsInterface = this.persistenceService.get('credentials');

                this.persistenceService.set('credentials', credentials);

                dispatchedActions.push(getCurrentUserAction());

                const { email } = jwtDecode(credentials.token);

                const unknownUsername = this.translate.instant('LOGGED_AS.UNKNOWN_USERNAME');
                const displayedUsername = email || unknownUsername;

                if (!this.persistenceService.get('loggedAsName')) {
                    dispatchedActions.push(showLoggedAsAction({
                        showLoggedAsParams: {
                            loggedAsName: displayedUsername,
                            previousAdminCredentials: currentCredentials,
                        },
                    }));
                } else {
                    dispatchedActions.push(showLoggedAsAction({
                        showLoggedAsParams: {
                            loggedAsName: displayedUsername,
                        },
                    }));
                }
                this.router.navigate(['/']);

                return dispatchedActions;
            }),
        );
    });

    loginUnderUserFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loginUnderUserFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('LOGGED_AS.NOTIFICATIONS.LOGIN_UNDER_USER.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private toastr: ToastrService,
        private translate: TranslateService,
        private persistenceService: PersistenceService,
        private router: Router,
    ) {}
}
