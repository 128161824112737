import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { currentLanguageChangedAction } from '@app-client/store/auth/actions/current-user-language.action';
import { currentTimezoneChangedAction } from '@app-client/store/auth/actions/current-user-timezone.action';
import {
    getCurrentUserAction,
    getCurrentUserFailureAction,
    getCurrentUserSuccessAction,
} from '@app-client/store/auth/actions/get-current-user.action';
import { AuthService } from '@app-client/store/auth/services/auth.service';
import { CurrentUserInterface } from '@app-client/store/auth/types/current-user.interface';
import { checkCompanyOnboardingDataAction } from '@app-client/store/tour/actions/tour.action';


@Injectable()
export class GetCurrentUserEffect {
    getCurrentUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getCurrentUserAction),
            switchMap(() => {
                return this.authService.getCurrentUser().pipe(
                    switchMap((currentUser: CurrentUserInterface) => {
                        return [
                            getCurrentUserSuccessAction({ currentUser }),
                            currentLanguageChangedAction({ language: currentUser.language }),
                            currentTimezoneChangedAction({ timezone: currentUser.timezone }),
                            checkCompanyOnboardingDataAction(),
                        ];
                    }),
                    catchError(() => {
                        return of(getCurrentUserFailureAction());
                    }),
                );
            }),
        );
    });

    constructor(private actions$: Actions, private authService: AuthService) {}
}
