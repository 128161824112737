import { createAction, props } from '@ngrx/store';

import { ActionTypes } from '@app-client/store/auth/action-types';
import { CurrentCompanyInterface } from '@app-client/store/auth/types/current-company.interface';


export const getCurrentCompanyAction = createAction(
    ActionTypes.GET_CURRENT_COMPANY,
    props<{ subDomain: string }>(),
);

export const getCurrentCompanySuccessAction = createAction(
    ActionTypes.GET_CURRENT_COMPANY_SUCCESS,
    props<{ currentCompany: CurrentCompanyInterface }>(),
);

export const getCurrentCompanyFailureAction = createAction(ActionTypes.GET_CURRENT_COMPANY_FAILURE);
