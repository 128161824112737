import { createAction, props } from '@ngrx/store';

import { CreateProjectTaskInterface } from '@app-client/project/types/create-project-task.interface';
import { ProjectTaskListQueryParamsInterface } from '@app-client/project/types/project-task-list-query-params.interface';
import { ProjectTaskInterface } from '@app-client/project/types/project-task.interface';
import { UpdateProjectTaskInterface } from '@app-client/project/types/update-project-task.interface';
import { ProjectTasksActionTypes } from '@app-client/store/project-tasks/project-tasks-action-types';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';
import { PageResponseInterface } from '@app-shared/types/page-response.interface';


export const setProjectTaskListQueryParamsAction = createAction(
    ProjectTasksActionTypes.SET_LIST_QUERY_PARAMS,
    props<{ queryParams: ProjectTaskListQueryParamsInterface }>(),
);

export const getProjectTaskListAction = createAction(
    ProjectTasksActionTypes.GET_LIST
);

export const getProjectTaskListSuccessAction = createAction(
    ProjectTasksActionTypes.GET_LIST_SUCCESS,
    props<{ response: PageResponseInterface<ProjectTaskInterface> }>(),
);

export const getProjectTaskListFailureAction = createAction(
    ProjectTasksActionTypes.GET_LIST_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const setProjectTaskListForEditTimeQueryParamsAction = createAction(
    ProjectTasksActionTypes.SET_LIST_QUERY_PARAMS_FOR_EDIT_TIME,
    props<{ queryParams: ProjectTaskListQueryParamsInterface }>(),
);

export const getProjectTaskListForEditTimeAction = createAction(
    ProjectTasksActionTypes.GET_LIST_FOR_EDIT_TIME
);

export const getProjectTaskListForEditTimeSuccessAction = createAction(
    ProjectTasksActionTypes.GET_LIST_FOR_EDIT_TIME_SUCCESS,
    props<{ response: PageResponseInterface<ProjectTaskInterface> }>(),
);

export const getProjectTaskListForEditTimeFailureAction = createAction(
    ProjectTasksActionTypes.GET_LIST_FOR_EDIT_TIME_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const createProjectTaskAction = createAction(
    ProjectTasksActionTypes.CREATE,
    props<{ createProjectTask: CreateProjectTaskInterface, successCallback: () => void }>(),
);

export const createProjectTaskSuccessAction = createAction(
    ProjectTasksActionTypes.CREATE_SUCCESS,
    props<{ successCallback: () => void }>(),
);

export const createProjectTaskFailureAction = createAction(
    ProjectTasksActionTypes.CREATE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const updateProjectTaskAction = createAction(
    ProjectTasksActionTypes.UPDATE,
    props<{ id: string, updateProjectTask: UpdateProjectTaskInterface, successCallback: () => void }>(),
);

export const updateProjectTaskSuccessAction = createAction(
    ProjectTasksActionTypes.UPDATE_SUCCESS,
    props<{ successCallback: () => void }>(),
);

export const updateProjectTaskFailureAction = createAction(
    ProjectTasksActionTypes.UPDATE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const removeProjectTaskAction = createAction(
    ProjectTasksActionTypes.REMOVE,
    props<{ id: string }>(),
);

export const removeProjectTaskSuccessAction = createAction(
    ProjectTasksActionTypes.REMOVE_SUCCESS,
);

export const removeProjectTaskFailureAction = createAction(
    ProjectTasksActionTypes.REMOVE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const restoreProjectTaskAction = createAction(
    ProjectTasksActionTypes.RESTORE,
    props<{ id: string }>(),
);

export const restoreProjectTaskSuccessAction = createAction(
    ProjectTasksActionTypes.RESTORE_SUCCESS,
);

export const restoreProjectTaskFailureAction = createAction(
    ProjectTasksActionTypes.RESTORE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
