import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { KaitenBoardColumnSettingsInterface } from '@app-client/kaiten/types/kaiten-board-column-settings.interface';
import { KaitenBoardColumnInterface } from '@app-client/kaiten/types/kaiten-board-column.interface';
import { KaitenBoardInterface } from '@app-client/kaiten/types/kaiten-board.interface';
import { KaitenColumnFilteringTypeEnum, KaitenProjectStatusInterface } from '@app-client/kaiten/types/kaiten-project-status.interface';
import { KaitenSpaceInterface } from '@app-client/kaiten/types/kaiten-space.interface';
import { KaitenUserDataInterface } from '@app-client/kaiten/types/kaiten-user-data.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';
import { mapToVoid } from '@app-shared/utils/rxjs/map-to-void';

import { environment } from '@env-client/environment';

@Injectable()
export class KaitenService {
    constructor(private httpClient: HttpClient) {}

    getUserKaitenSpaces(): Observable<KaitenSpaceInterface[]> {
        const url = `${environment.apiBaseUrl}/module/kaiten-sync/spaces`;

        return this.httpClient.get(url).pipe(map((response: ResponseInterface<KaitenSpaceInterface[]>) => {
            return response.body;
            })
        );
    }

    getProjectStatus(projectId: string): Observable<KaitenProjectStatusInterface> {
        const url = `${environment.apiBaseUrl}/module/kaiten-sync/project-attachment-data/${projectId}`;

        return this.httpClient.get(url).pipe(
            map((response: ResponseInterface<KaitenProjectStatusInterface>) => {
                return response.body;
            }),
        );
    }

    getUserKaitenBoards(spaceId: string): Observable<KaitenBoardInterface[]> {
        const url = `${environment.apiBaseUrl}/module/kaiten-sync/spaces/${spaceId}/boards`;

        return this.httpClient.get(url).pipe(
            map((response: ResponseInterface<KaitenBoardInterface[]>) => {
                return response.body;
            }),
        );
    }

    getKaitenUserData(): Observable<KaitenUserDataInterface> {
        const url = `${environment.apiBaseUrl}/module/kaiten-sync/user`;

        return this.httpClient.get(url).pipe(
            map((response: ResponseInterface<KaitenUserDataInterface>) => {
                return response.body;
            }),
        );
    }

    updateKaitenKeyId(kaitenKeyId: string) {
        const url = `${environment.apiBaseUrl}/module/kaiten-sync/user/api-key`;

        return this.httpClient.post(url, {kaitenUserApiKey: kaitenKeyId}).pipe(mapToVoid);
    }

    deleteKaitenKey() {
        const url = `${environment.apiBaseUrl}/module/kaiten-sync/user/api-key`;

        return this.httpClient.delete(url, {}).pipe(mapToVoid);
    }

    getBoardColumnsByProject(projectId: string): Observable<KaitenBoardColumnInterface[]> {
        const url = `${environment.apiBaseUrl}/module/kaiten-sync/projects/${projectId}/columns`;

        return this.httpClient.get(url).pipe(
            map((response: ResponseInterface<KaitenBoardColumnInterface[]>) => {
                return response.body;
            }),
        );
    }

    attachKaitenSpaceToProject(
        projectId: string,
        spaceId: string,
        boardId: string,
        columnFilteringType: KaitenColumnFilteringTypeEnum,
        columns: Array<{ columnName: string, columnId: string }>,
    ): Observable<void> {
        const url = `${environment.apiBaseUrl}/module/kaiten-sync/attach-space-to-project/${projectId}`;

        return this.httpClient.post(url, {
            kaitenSpaceId: spaceId,
            kaitenBoardId: boardId,
            columnFilteringType,
            kaitenColumnIds: columns.map(column => column.columnId),
        }).pipe(mapToVoid);
    }

    updateColumnSettings(
        projectId: string,
        spaceId: string,
        boardId: string,
        { columnFilteringType, columns }: KaitenBoardColumnSettingsInterface
    ): Observable<void> {
        const url = `${environment.apiBaseUrl}/module/kaiten-sync/update-project-attachment-data/${projectId}`;

        return this.httpClient.post(url, {
            kaitenSpaceId: spaceId,
            kaitenBoardId: boardId,
            columnFilteringType,
            kaitenColumnIds: columns.map(column => column.columnId),
        }).pipe(mapToVoid);
    }

    detachKaitenSpaceFromProject(projectId: string): Observable<void> {
        const url = `${environment.apiBaseUrl}/module/kaiten-sync/detach-space-from-project/${projectId}`;

        return this.httpClient.post(url, {}).pipe(mapToVoid);
    }

    startSync(projectId: string): Observable<void> {
        const url = `${environment.apiBaseUrl}/module/kaiten-sync/start-sync/${projectId}`;

        return this.httpClient.post(url, {}).pipe(mapToVoid);
    }

    stopSync(projectId: string): Observable<void> {
        const url = `${environment.apiBaseUrl}/module/kaiten-sync/stop-sync/${projectId}`;

        return this.httpClient.post(url, {}).pipe(mapToVoid);
    }

    startSyncSpacesList(): Observable<void>  {
        const url = `${environment.apiBaseUrl}/module/kaiten-sync/sync-spaces-list`;

        return this.httpClient.post(url, {}).pipe(mapToVoid);
    }

    startSpaceBoardsListSync(spaceId: string) {
        const url = `${environment.apiBaseUrl}/module/kaiten-sync/sync-space-boards-list/${spaceId}`;

        return this.httpClient.post(url, {spaceId}).pipe(mapToVoid);
    }
}
