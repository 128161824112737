import { createAction, props } from '@ngrx/store';

import { UserTimeFormatEnum } from '@app-client/shared/types/user-time-format.enum';
import { CurrentUserInterface } from '@app-client/store/auth/types/current-user.interface';
import { ActionTypes } from '@app-client/store/user-profile/action-types';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


export const updateUserProfileDataAction = createAction(
    ActionTypes.UPDATE_PROFILE_DATA,
    props<{ profileData: {
        firstName?: string,
        lastName?: string,
        language?: string,
        timezone?: string,
        timeFormat?: UserTimeFormatEnum
    } }>(),
);

export const updateUserProfileDataSuccessAction = createAction(
    ActionTypes.UPDATE_PROFILE_DATA_SUCCESS,
    props<{ user: CurrentUserInterface }>(),
);

export const updateUserProfileDataFailureAction = createAction(
    ActionTypes.UPDATE_PROFILE_DATA_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
