import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ActivatedUserResponseInterface } from '@app-client/marketplace/modules/details/types/activated-user-response.interface';
import { CompanyModuleResponseInterface } from '@app-client/marketplace/modules/details/types/company-module-response.interface';
import { ModuleDetailsResponseInterface } from '@app-client/marketplace/modules/details/types/module-details-response.interface';
import { EnabledModuleResponseInterface } from '@app-client/marketplace/modules/list/types/enabled-module-response.interface';
import { ModuleResponseInterface } from '@app-client/marketplace/modules/list/types/module-response.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';

import { environment } from '@env-client/environment';


@Injectable()
export class MarketplaceModulesService {
    constructor(
        private http: HttpClient,
    ) {}

    getList(): Observable<Array<ModuleResponseInterface>> {
        const url = `${environment.apiBaseUrl}/market/modules`;

        return this.http.get<ResponseInterface<Array<ModuleResponseInterface>>>(url).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    getDetails(id: string): Observable<ModuleDetailsResponseInterface> {
        const url = `${environment.apiBaseUrl}/market/modules/${id}`;

        return this.http.get<ResponseInterface<ModuleDetailsResponseInterface>>(url).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    install(moduleId: string, tariffId: string, forAll: boolean | null): Observable<CompanyModuleResponseInterface> {
        const url = `${environment.apiBaseUrl}/market/modules/${moduleId}/tariff/${tariffId}`;
        const body = forAll === null ? {} : { forAll };

        return this.http.post<ResponseInterface<CompanyModuleResponseInterface>>(url, body).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    uninstall(moduleId: string): Observable<void> {
        const url = `${environment.apiBaseUrl}/market/modules/${moduleId}`;

        return this.http.delete<ResponseInterface<void>>(url).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    updateTariff(moduleId: string, tariffId: string): Observable<CompanyModuleResponseInterface> {
        const url = `${environment.apiBaseUrl}/market/modules/${moduleId}/tariff/${tariffId}`;

        return this.http.patch<ResponseInterface<CompanyModuleResponseInterface>>(url, {}).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    updateSettings(
        moduleId: string,
        forAll: boolean,
        usersToAdd: Array<ActivatedUserResponseInterface>,
        usersToDelete: Array<ActivatedUserResponseInterface>
    ): Observable<CompanyModuleResponseInterface> {
        const url = `${environment.apiBaseUrl}/market/modules/${moduleId}`;
        const body: any = {};
        if (forAll === true) {
            body.action = 'enable-for-all-mode';
        }
        if (forAll === false) {
            if (usersToAdd.length + usersToDelete.length === 0) {
                body.action = 'disable-for-all-mode';
            }
            if (usersToAdd.length + usersToDelete.length > 0) {
                body.action = 'manage-licenses';
                if (usersToAdd.length > 0) {
                    body.usersToAdd = usersToAdd.map((user) => {
                        return user.id;
                    });
                }
                if (usersToDelete.length > 0) {
                    body.usersToDelete = usersToDelete.map((user) => {
                        return user.id;
                    });
                }
            }
        }

        return this.http.patch<ResponseInterface<CompanyModuleResponseInterface>>(url, body).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    getActivatedUsers(moduleId: string): Observable<Array<ActivatedUserResponseInterface>> {
        const url = `${environment.apiBaseUrl}/market/modules/${moduleId}/active-user-licenses`;

        return this.http.get<ResponseInterface<Array<ActivatedUserResponseInterface>>>(url).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    getEnabled(): Observable<Array<EnabledModuleResponseInterface>> {
        const url = `${environment.apiBaseUrl}/market/modules/enabled`;

        return this.http.get<ResponseInterface<Array<EnabledModuleResponseInterface>>>(url).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

}
