import { createReducer, on } from '@ngrx/store';

import { initLoggedAsAction, showLoggedAsAction, toggleLoggedAsAction } from '@app-client/store/logged-as/actions/logged-as.action';
import { LoggedAsStateInterface } from '@app-client/store/logged-as/logged-as-state.interface';


const initialState: LoggedAsStateInterface = {
    isVisible: false,
    isExpanded: true,
    loggedAsName: null,
    previousAdminCredentials: null,
};

const reducer = createReducer(
    initialState,
    on(
        initLoggedAsAction,
        state => ({
            ...state,
            isVisible: false,
            isExpanded: true,
            loggedAsName: null,
            previousAdminCredentials: null,
        }),
    ),
    on(
        showLoggedAsAction,
        (state, action) => ({
            ...state,
            isVisible: true,
            isExpanded: true,
            loggedAsName: action.showLoggedAsParams.loggedAsName,
            previousAdminCredentials: action.showLoggedAsParams.previousAdminCredentials,
        }),
    ),
    on(
        toggleLoggedAsAction,
        (state, action) => ({
            ...state,
            isExpanded: action.show,
        }),
    ),
);

export function loggedAsReducer(state, action) {
    return reducer(state, action);
}
