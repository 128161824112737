import { createAction, props } from '@ngrx/store';

import { JiraBoardInterface } from '@app-client/jira/types/filter-types/jira-board.interface';
import { JiraEpicInterface } from '@app-client/jira/types/filter-types/jira-epic.interface';
import { JiraIssueTypeInterface } from '@app-client/jira/types/filter-types/jira-issue-type.interface';
import { JiraProjectInterface } from '@app-client/jira/types/filter-types/jira-project.interface';
import { JiraSprintInterface } from '@app-client/jira/types/filter-types/jira-sprint.interface';
import { JiraStatusInterface } from '@app-client/jira/types/filter-types/jira-status.interface';
import { JiraAttachProjectInterface } from '@app-client/jira/types/jira-attach-project.interface';
import { JiraSyncStatusInterface } from '@app-client/jira/types/jira-sync-status.interface';
import { JiraSyncTypeEnum } from '@app-client/jira/types/jira-sync-type.enum';
import { JiraUpdateProjectInterface } from '@app-client/jira/types/jira-update-project.interface';
import { JiraProjectStatusInterface } from '@app-client/jira/types/response-types/jira-project-status.interface';
import { JiraConnectionActionEnum } from '@app-client/store/jira/jira-connection-action.enum';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


export const jiraGetProjectStatusAction = createAction(
    JiraConnectionActionEnum.GET_PROJECT_STATUS,
    props<{ projectId: string }>(),
);

export const jiraGetProjectStatusSuccessAction = createAction(
    JiraConnectionActionEnum.GET_PROJECT_STATUS_SUCCESS,
    props<{ projectStatus: JiraProjectStatusInterface }>(),
);

export const jiraGetProjectStatusFailureAction = createAction(
    JiraConnectionActionEnum.GET_PROJECT_STATUS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const jiraGetProjectsAction = createAction(
    JiraConnectionActionEnum.GET_PROJECTS,
    props<{ connectionId: string }>(),
);

export const jiraGetProjectsSuccessAction = createAction(
    JiraConnectionActionEnum.GET_PROJECTS_SUCCESS,
    props<{ projects: Array<JiraProjectInterface> }>(),
);

export const jiraGetProjectsFailureAction = createAction(
    JiraConnectionActionEnum.GET_PROJECTS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const jiraGetStatusesAction = createAction(
    JiraConnectionActionEnum.GET_STATUSES,
    props<{ connectionId: string, projectKey: string }>(),
);

export const jiraGetStatusesSuccessAction = createAction(
    JiraConnectionActionEnum.GET_STATUSES_SUCCESS,
    props<{ statuses: Array<JiraStatusInterface> }>(),
);

export const jiraGetStatusesFailureAction = createAction(
    JiraConnectionActionEnum.GET_STATUSES_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const jiraGetIssueTypesAction = createAction(
    JiraConnectionActionEnum.GET_ISSUE_TYPES,
    props<{ connectionId: string, projectKey: string }>(),
);

export const jiraGetIssueTypesSuccessAction = createAction(
    JiraConnectionActionEnum.GET_ISSUE_TYPES_SUCCESS,
    props<{ issueTypes: Array<JiraIssueTypeInterface> }>(),
);

export const jiraGetIssueTypesFailureAction = createAction(
    JiraConnectionActionEnum.GET_ISSUE_TYPES_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const jiraGetBoardsAction = createAction(
    JiraConnectionActionEnum.GET_BOARDS,
    props<{ connectionId: string, projectKey: string }>(),
);

export const jiraGetBoardsSuccessAction = createAction(
    JiraConnectionActionEnum.GET_BOARDS_SUCCESS,
    props<{ boards: Array<JiraBoardInterface> }>(),
);

export const jiraGetBoardsFailureAction = createAction(
    JiraConnectionActionEnum.GET_BOARDS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const jiraGetEpicsAction = createAction(
    JiraConnectionActionEnum.GET_EPICS,
    props<{ connectionId: string, boardIds: Array<string> }>()
);

export const jiraGetEpicsSuccessAction = createAction(
    JiraConnectionActionEnum.GET_EPICS_SUCCESS,
    props<{ epics: Array<JiraEpicInterface> }>(),
);

export const jiraGetEpicsFailureAction = createAction(
    JiraConnectionActionEnum.GET_EPICS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const jiraGetSprintsAction = createAction(
    JiraConnectionActionEnum.GET_SPRINTS,
    props<{ connectionId: string, boardIds: Array<string> }>(),
);

export const jiraGetSprintsSuccessAction = createAction(
    JiraConnectionActionEnum.GET_SPRINTS_SUCCESS,
    props<{ sprints: Array<JiraSprintInterface> }>(),
);

export const jiraGetSprintsFailureAction = createAction(
    JiraConnectionActionEnum.GET_SPRINTS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const jiraAttachAction = createAction(
    JiraConnectionActionEnum.ATTACH,
    props<{projectId: string, filters: JiraAttachProjectInterface }>(),
);

export const jiraAttachSuccessAction = createAction(
    JiraConnectionActionEnum.ATTACH_PROJECT_SUCCESS,
    props<{ projectId: string }>(),
);

export const jiraAttachFailureAction = createAction(
    JiraConnectionActionEnum.ATTACH_PROJECT_FAILURE,
    props<{ projectId: string, backendErrors: BackendErrorsInterface }>(),
);

export const jiraDetachAction = createAction(
    JiraConnectionActionEnum.DETACH,
    props<{ projectId: string }>(),
);

export const jiraDetachSuccessAction = createAction(
    JiraConnectionActionEnum.DETACH_SUCCESS,
    props<{ projectId: string }>(),
);

export const jiraDetachFailureAction = createAction(
    JiraConnectionActionEnum.DETACH_FAILURE,
    props<{ projectId: string, backendErrors: BackendErrorsInterface }>(),
);

export const jiraUpdateAction = createAction(
    JiraConnectionActionEnum.UPDATE,
    props<{ projectId: string, filters: JiraUpdateProjectInterface }>(),
);

export const jiraUpdateSuccessAction = createAction(
    JiraConnectionActionEnum.UPDATE_SUCCESS,
    props<{ projectId: string }>(),
);

export const jiraUpdateFailureAction = createAction(
    JiraConnectionActionEnum.UPDATE_FAILURE,
    props<{ projectId: string, backendErrors: BackendErrorsInterface }>(),
);

export const jiraStartSyncAction = createAction(
    JiraConnectionActionEnum.START_SYNC,
    props<{ projectId: string }>(),
);

export const jiraStartSyncSuccessAction = createAction(
    JiraConnectionActionEnum.START_SYNC_SUCCESS,
);

export const jiraStartSyncFailureAction = createAction(
    JiraConnectionActionEnum.START_SYNC_FAILURE,
    props<{ projectId: string, backendErrors: BackendErrorsInterface }>(),
);

export const jiraGetSyncStatusAction = createAction(
    JiraConnectionActionEnum.GET_SYNC_STATUS,
    props<{ projectId: string }>(),
);

export const jiraGetSyncStatusSuccessAction = createAction(
    JiraConnectionActionEnum.GET_SYNC_STATUS_SUCCESS,
    props<{ syncStatus: JiraSyncStatusInterface }>(),
);

export const jiraGetSyncStatusFailureAction = createAction(
    JiraConnectionActionEnum.STOP_SYNC_FAILURE,
    props<{ projectId: string, backendErrors: BackendErrorsInterface }>(),
);

export const jiraUpdateSyncTimeSettingsAction = createAction(
    JiraConnectionActionEnum.UPDATE_SYNC_TIME_SETTINGS,
    props<{ projectId: string, syncMemo: number, sumTimeByTask: number, from: number, to: number }>(),
);

export const jiraUpdateSyncTimeSettingsSuccessAction = createAction(
    JiraConnectionActionEnum.UPDATE_SYNC_TIME_SETTINGS_SUCCESS,
    props<{ projectId: string, from: number, to: number}>(),
);

export const jiraUpdateSyncTimeSettingsFailureAction = createAction(
    JiraConnectionActionEnum.UPDATE_SYNC_TIME_SETTINGS_FAILURE,
    props<{ projectId: string, backendErrors: BackendErrorsInterface }>(),
);

export const jiraStartSyncTimeAction = createAction(
    JiraConnectionActionEnum.START_SYNC_TIME,
    props<{ projectId: string }>(),
);

export const jiraStartSyncTimeSuccessAction = createAction(
    JiraConnectionActionEnum.START_SYNC_TIME_SUCCESS,
    props<{ projectId: string }>(),
);

export const jiraStartSyncTimeFailureAction = createAction(
    JiraConnectionActionEnum.START_SYNC_TIME_FAILURE,
    props<{ projectId: string, backendErrors: BackendErrorsInterface }>(),
);

export const jiraSetSyncTypeAction = createAction(
    JiraConnectionActionEnum.SET_SYNC_TYPE,
    props<{ syncType: JiraSyncTypeEnum }>(),
);
