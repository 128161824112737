import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import Prando from 'prando';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProjectTaskMemoChunkStatusEnum } from '@app-client/store/activity/types/data-chunks/project-task-memo-chunk-status.enum';
import { ProjectTaskMemoChunkInterface } from '@app-client/store/activity/types/data-chunks/project-task-memo-chunk.interface';
import { ScreenshotChunkInterface } from '@app-client/store/activity/types/data-chunks/screenshot-chunk.interface';
import { WindowChunkInterface } from '@app-client/store/activity/types/data-chunks/window-chunk.interface';
import { WindowEffectivityEnum } from '@app-client/store/activity/types/data-chunks/window-effectivity.enum';
import { DataRequestParamsInterface } from '@app-client/store/activity/types/data-request-params.interface';
import { ProjectTaskMemoResponseInterface } from '@app-client/store/activity/types/project-task-memo-response.interface';
import { DailyStatisticsInterface } from '@app-client/store/activity/types/statistics/daily-statistics.interface';
import { MonthlyStatisticsInterface } from '@app-client/store/activity/types/statistics/monthly-statistics.interface';
import { WeeklyStatisticsInterface } from '@app-client/store/activity/types/statistics/weekly-statistics.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';
import { ALPHANUMERIC } from '@app-shared/utils/alphanumeric.const';

import { environment } from '@env-client/environment';


@Injectable()
export class ActivityService {
    private readonly requestedColor = '#D3D3D3';
    private readonly idleColor = '#EFEEF1';
    private readonly deletedColor = '#282A32';
    private readonly colorMap = [
        '#FFCE85',
        '#FFE588',
        '#C2E380',
        '#85DAE5',
        '#A3D3FF',
        '#91A1FA',
        '#D0C4EB',
        '#E8B1B7',
        '#FFBC8E',
        '#FCE9A7',
        '#ECF6AA',
        '#D0F3EB',
        '#99C0E4',
        '#99A5D2',
        '#D0A3E4',
        '#DBBECF',
        '#FA9079',
        '#FDFFA9',
        '#C5E3BA',
        '#C6FFFC',
        '#81B3FF',
        '#A7B5E8',
        '#FFD9FB',
        '#FFD8D8',
        '#E39F89',
        '#EED67F',
        '#A5E898',
        '#9FDDCC',
        '#D0EBFE',
        '#7E82E7',
        '#C4A7FF',
        '#FFADAD',
    ];

    constructor(
        private httpClient: HttpClient,
    ) {}

    getStatisticsByDay(params: DataRequestParamsInterface): Observable<DailyStatisticsInterface> {
        const url = environment.apiBaseUrl + '/trk/statistics/daily';

        let httpParams = new HttpParams()
            .set('startOfPeriod', `${Math.floor(params.from.toDate().getTime() / 1000)}`);

        if (params.userId) {
            httpParams = httpParams.set('userId', params.userId);
        }
        if (params.projectId) {
            httpParams = httpParams.set('projectId', params.projectId);
        }
        if (params.taskId) {
            httpParams = httpParams.set('taskId', params.taskId);
        }

        return this.httpClient.get(url, { params: httpParams }).pipe(
            map((response: ResponseInterface<DailyStatisticsInterface>) => {
                return response.body;
            }),
        );
    }

    getStatisticsByWeek(params: DataRequestParamsInterface): Observable<WeeklyStatisticsInterface> {
        const url = environment.apiBaseUrl + '/trk/statistics/weekly';

        let httpParams = new HttpParams()
            .set('startOfPeriod', `${Math.floor(params.from.clone().startOf('week').toDate().getTime() / 1000)}`);

        if (params.userId) {
            httpParams = httpParams.set('userId', params.userId);
        }
        if (params.projectId) {
            httpParams = httpParams.set('projectId', params.projectId);
        }
        if (params.taskId) {
            httpParams = httpParams.set('taskId', params.taskId);
        }

        return this.httpClient.get(url, { params: httpParams }).pipe(
            map((response: ResponseInterface<WeeklyStatisticsInterface>) => {
                return response.body;
            }),
        );
    }

    getStatisticsByMonth(params: DataRequestParamsInterface): Observable<MonthlyStatisticsInterface> {
        const url = environment.apiBaseUrl + '/trk/statistics/monthly';

        let httpParams = new HttpParams()
            .set('startOfPeriod', `${Math.floor(params.from.clone().startOf('month').toDate().getTime() / 1000)}`);

        if (params.userId) {
            httpParams = httpParams.set('userId', params.userId);
        }
        if (params.projectId) {
            httpParams = httpParams.set('projectId', params.projectId);
        }
        if (params.taskId) {
            httpParams = httpParams.set('taskId', params.taskId);
        }

        return this.httpClient.get(url, { params: httpParams }).pipe(
            map((response: ResponseInterface<MonthlyStatisticsInterface>) => {
                return response.body;
            }),
        );
    }

    getProjectTaskMemoData(params: DataRequestParamsInterface): Observable<Array<ProjectTaskMemoChunkInterface>> {
        const url = environment.apiBaseUrl + '/trk/time';

        let httpParams = new HttpParams()
            .set('from', `${Math.floor(params.from.toDate().getTime() / 1000)}`)
            .set('to', `${Math.floor(params.to.toDate().getTime() / 1000)}`);

        if (params.userId) {
            httpParams = httpParams.set('userId', params.userId);
        }
        if (params.projectId) {
            httpParams = httpParams.set('projectId', params.projectId);
        }
        if (params.taskId) {
            httpParams = httpParams.set('taskId', params.taskId);
        }

        return this.httpClient.get(url, { params: httpParams }).pipe(
            map((response: ResponseInterface<ProjectTaskMemoResponseInterface>) => {
                const rng = new Prando();
                // make ids for all
                const items = [
                    ...response.body.items,
                    ...response.body.requestedTime.map(i => ({ ...i, status: undefined })),
                ].map(item => {
                    return {
                        ...item,
                        begin: moment(item.begin * 1000),
                        end: moment(item.end * 1000),
                        id: rng.nextString(16, ALPHANUMERIC),
                    };
                });

                // colorize and return
                const colored = {};
                let colorIndex = 0;
                const getPTMUnique = (ptm) => {
                    return ptm.status + ptm.projectId + ptm.taskId + ptm.memo;
                };

                return items.map((ptm) => {
                    const ptmUnique = getPTMUnique(ptm);
                    let color = colored[ptmUnique];
                    if (!color) {
                        color = this.colorMap[colorIndex % this.colorMap.length];
                        colored[ptmUnique] = color;
                        colorIndex++;
                    }

                    switch (ptm.status) {
                        case ProjectTaskMemoChunkStatusEnum.DELETED:
                            color = this.deletedColor;
                            break;
                        case ProjectTaskMemoChunkStatusEnum.IDLE:
                            color = this.idleColor;
                            break;
                        case undefined:
                            color = this.requestedColor;
                            break;
                    }

                    const project = response.body.references.projects.find(p => p.id === ptm.projectId);
                    const task = response.body.references.tasks.find(t => t.id === ptm.taskId);

                    return {
                        ...ptm,
                        color,
                        // just a placeholder project/task for the case it's not sent by backend
                        project: project || {
                            id: rng.nextString(16, ALPHANUMERIC),
                            name: 'Undefined',
                            isDefault: false,
                            projectRoleOfCurrentUser: null,
                        },
                        task: task || { id: rng.nextString(16, ALPHANUMERIC), name: 'Undefined', isDefault: false },
                    };
                });
            }),
        );
    }

    getWindowData(params: DataRequestParamsInterface): Observable<Array<WindowChunkInterface>> {
        const url = environment.apiBaseUrl + '/trk/windows';

        let httpParams = new HttpParams()
            .set('from', `${Math.floor(params.from.toDate().getTime() / 1000)}`)
            .set('to', `${Math.floor(params.to.toDate().getTime() / 1000)}`);

        if (params.userId) {
            httpParams = httpParams.set('userId', params.userId);
        }
        if (params.projectId) {
            httpParams = httpParams.set('projectId', params.projectId);
        }
        if (params.taskId) {
            httpParams = httpParams.set('taskId', params.taskId);
        }

        return this.httpClient.get(url, { params: httpParams }).pipe(
            map((response: ResponseInterface<{ items: Array<WindowChunkInterface & { begin: number, end: number }> }>) => {
                return response.body.items.map((item) => {
                    return {
                        ...item,
                        begin: moment(item.begin * 1000),
                        end: moment(item.end * 1000),
                        effectivity: item.effectivity || WindowEffectivityEnum.NEUTRAL,
                        icon: item.icon ?? item.url ? '/assets/images/icons/site-icon.svg' : '/assets/images/icons/app-icon.svg',
                    };
                });
            }),
        );
    }

    getScreenshotData(params: DataRequestParamsInterface): Observable<Array<ScreenshotChunkInterface>> {
        const url = environment.apiBaseUrl + '/trk/screenshots';

        let httpParams = new HttpParams()
            .set('from', `${Math.floor(params.from.toDate().getTime() / 1000)}`)
            .set('to', `${Math.floor(params.to.toDate().getTime() / 1000)}`);

        if (params.userId) {
            httpParams = httpParams.set('userId', params.userId);
        }
        if (params.projectId) {
            httpParams = httpParams.set('projectId', params.projectId);
        }
        if (params.taskId) {
            httpParams = httpParams.set('taskId', params.taskId);
        }

        return this.httpClient.get(url, { params: httpParams }).pipe(
            map((response: ResponseInterface<{ items: Array<ScreenshotChunkInterface & { timestamp: number }> }>) => {
                return response.body.items.map((item) => {
                    return {
                        ...item,
                        timestamp: moment(item.timestamp * 1000),
                    };
                });
            }),
        );
    }
}
