export enum TrelloErrorsEnum {
    BOARD_NOT_FOUND = '[trello_board_not_found]',
    TOKEN_NOT_FOUND = '[trello_token_not_found]',
    BOARD_ALREADY_ATTACHED = '[trello_board_already_attached]',
    BOARD_NOT_ATTACHED = '[trello_board_not_attached]',
    BOARD_IN_SYNC = '[trello_board_in_sync]',
    CLIENT_ACCESS_DENIED = '[trello_client_access_denied]',
    CLIENT_ENTITY_NOT_FOUND = '[trello_client_entity_not_found]',
    CLIENT_SOMETHING_WRONG = '[trello_client_something_wrong]',
}
