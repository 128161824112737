export enum ActionTypes {
    GET_CATEGORY_LIST= '[Effectivity] Get category list',
    GET_CATEGORY_LIST_SUCCESS = '[Effectivity] Get category list success',
    GET_CATEGORY_LIST_FAILURE = '[Effectivity] Get category list failure',
    CREATE_CATEGORY = '[Effectivity] Create category',
    CREATE_CATEGORY_SUCCESS = '[Effectivity] Create category success',
    CREATE_CATEGORY_FAILURE = '[Effectivity] Create category failure',
    UPDATE_CATEGORY = '[Effectivity] Update category',
    UPDATE_CATEGORY_SUCCESS = '[Effectivity] Update category success',
    UPDATE_CATEGORY_FAILURE = '[Effectivity] Update category failure',
    REMOVE_CATEGORY = '[Effectivity] Remove category',
    REMOVE_CATEGORY_SUCCESS = '[Effectivity] Remove category success',
    REMOVE_CATEGORY_FAILURE = '[Effectivity] Remove category failure',

    CREATE_SUBCATEGORY = '[Effectivity] Create subcategory',
    CREATE_SUBCATEGORY_SUCCESS = '[Effectivity] Create subcategory success',
    CREATE_SUBCATEGORY_FAILURE = '[Effectivity] Create subcategory failure',
    UPDATE_SUBCATEGORY = '[Effectivity] Update subcategory',
    UPDATE_SUBCATEGORY_SUCCESS = '[Effectivity] Update subcategory success',
    UPDATE_SUBCATEGORY_FAILURE = '[Effectivity] Update subcategory failure',
    REMOVE_SUBCATEGORY = '[Effectivity] Remove subcategory',
    REMOVE_SUBCATEGORY_SUCCESS = '[Effectivity] Remove subcategory success',
    REMOVE_SUBCATEGORY_FAILURE = '[Effectivity] Remove subcategory failure',

    SET_APP_LIST_QUERY_PARAMS = '[Effectivity] Set app list query params',
    GET_APP_LIST = '[Effectivity] Get app list',
    GET_APP_LIST_SUCCESS = '[Effectivity] Get app list success',
    GET_APP_LIST_FAILURE = '[Efficiency] Get app list failure',
    BATCH_UPDATE_APP_SUBCATEGORY = '[Effectivity] Batch update app subcategory',
    BATCH_UPDATE_APP_SUBCATEGORY_SUCCESS = '[Effectivity] Batch update app subcategory success',
    BATCH_UPDATE_APP_SUBCATEGORY_FAILURE = '[Effectivity] Batch update app subcategory failure',
}
