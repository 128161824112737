import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import {
    approveTimeRequestAction,
    approveTimeRequestFailureAction,
    approveTimeRequestSuccessAction,
    getTimeRequestListAction,
    getTimeRequestListFailureAction,
    getTimeRequestListSuccessAction,
    rejectTimeRequestAction,
    rejectTimeRequestFailureAction,
    rejectTimeRequestSuccessAction,
} from '@app-client/store/time-request/actions/time-request.action';
import { timeRequestListQueryParamsSelector } from '@app-client/store/time-request/selectors/time-request.selector';
import { TimeRequestService } from '@app-client/store/time-request/services/time-request.service';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class TimeRequestEffect {
    getTimeRequestList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getTimeRequestListAction),
            withLatestFrom(this.store$.select(timeRequestListQueryParamsSelector)),
            switchMap(([, queryParams]) => {
                return this.timeRequestService.timeRequestList(queryParams).pipe(
                    map((response) => {
                        return getTimeRequestListSuccessAction({ response });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(getTimeRequestListFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getTimeRequestListFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getTimeRequestListFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('TIME_REQUESTS.NOTIFICATIONS.LIST.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    approveTimeRequest$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(approveTimeRequestAction),
            switchMap(({ id, time, user, successCallback }) => {
                return this.timeRequestService.approveTimeRequest(id).pipe(
                    map(() => {
                        return approveTimeRequestSuccessAction({ time, user, successCallback });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(approveTimeRequestFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    approveTimeRequestSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(approveTimeRequestSuccessAction),
            tap(({ time, user, successCallback }) => {
                this.toastr.success(
                    this.translate.instant('TIME_REQUESTS.NOTIFICATIONS.APPROVE.SUCCESS.ERROR', {
                        time,
                        user,
                    }),
                    this.translate.instant('TIME_REQUESTS.NOTIFICATIONS.APPROVE.SUCCESS.TITLE'),
                );

                if (successCallback && typeof successCallback === 'function') {
                    successCallback();
                }
            }),
        );
    }, { dispatch: false });

    approveTimeRequestFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(approveTimeRequestFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('TIME_REQUESTS.NOTIFICATIONS.APPROVE.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    rejectTimeRequest$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(rejectTimeRequestAction),
            switchMap(({ id, comment, successCallback }) => {
                return this.timeRequestService.rejectTimeRequest(id, comment).pipe(
                    map(() => {
                        return rejectTimeRequestSuccessAction({ successCallback });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(rejectTimeRequestFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    rejectTimeRequestSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(rejectTimeRequestSuccessAction),
            tap(({ successCallback }) => {
                this.toastr.success(
                    this.translate.instant('TIME_REQUESTS.NOTIFICATIONS.REJECT.SUCCESS.MESSAGE'),
                    this.translate.instant('TIME_REQUESTS.NOTIFICATIONS.REJECT.SUCCESS.TITLE'),
                );

                if (successCallback && typeof successCallback === 'function') {
                    successCallback();
                }
            }),
        );
    }, { dispatch: false });

    rejectTimeRequestFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(rejectTimeRequestFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('TIME_REQUESTS.NOTIFICATIONS.REJECT.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    constructor(
        private actions$: Actions,
        private store$: Store,
        private timeRequestService: TimeRequestService,
        private translate: TranslateService,
        private toastr: ToastrService,
    ) {}
}
