import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { skipWhile, switchMap, take } from 'rxjs/operators';

import { UserRoleEnum } from '@app-client/shared/types/user-role.enum';
import { currentUserSelector, isCurrentUserLoadingSelector } from '@app-client/store/auth/selectors/auth.selectors';


@Injectable()
export class SettingsRoleGuardService implements CanActivate {
    constructor(
        private store: Store,
        private router: Router,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return combineLatest([
            this.store.select(currentUserSelector),
            this.store.select(isCurrentUserLoadingSelector),
        ]).pipe(
            skipWhile(([, isUserLoading]) => isUserLoading || isUserLoading === null),
            take(1),
            switchMap(([user]) => {
                let path = '/company/effectivity-templates';

                switch (user.role) {
                    case UserRoleEnum.ROLE_ADMIN:
                        path = '/company/settings';
                        break;
                    default:
                        path = '/company/effectivity-categories';
                        break;
                }

                this.router.navigate([path]);
                return of(true);
            }),
        );
    }
}
