<div class="page-not-found">
    <div class="container">
        <div class="row">
            <div class="col m12 l5 z-index-upper">
                <div class="page-not-found__title">404</div>
                <div class="page-not-found__description">
                    <p>{{ 'PAGE_NOT_FOUND.STRING1' | translate }}</p>
                    <p>{{ 'PAGE_NOT_FOUND.STRING2' | translate }}</p>
                </div>
                <button
                    ui-button
                    size="large"
                    color="yellow"
                    routerLink="/"
                >
                    {{ 'PAGE_NOT_FOUND.GO_BACK_BUTTON' | translate }}
                </button>
            </div>
            <div class="col m12 l7">
                <img class="page-not-found__image" src="/assets/images/404.png" alt="404 page picture">
            </div>
        </div>
    </div>
</div>
