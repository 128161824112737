import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Moment } from 'moment-timezone';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';

import { PaymentPlanInterface } from '@app-client/payment/plans/types/payment-plan.interface';
import { marketplaceGetAccountAction } from '@app-client/store/marketplace/account/actions/marketplace-account.actions';
import {
    marketplaceAccountIsDataLoadingSelector, marketplaceAccountSelector,
} from '@app-client/store/marketplace/account/selectors/marketplace-account.selectors';
import {
    marketplaceModulesGetDetailsAction, marketplaceModulesGetListAction,
    marketplaceModulesInstallAction,
} from '@app-client/store/marketplace/modules/actions/marketplace-modules.actions';
import {
    marketplaceModulesDetailsSelector,
    marketplaceModulesIsDetailsLoadingSelector,
    marketplaceModulesIsInstallInProgressSelector,
    marketplaceModulesIsListLoadingSelector,
    marketplaceModulesListSelector,
} from '@app-client/store/marketplace/modules/selectors/marketplace-modules.selectors';
import { AppModulesEnum } from '@app-shared/types/app-modules.enum';


@Component({
    selector: 'app-plan-selector-plan',
    templateUrl: './plan-selector-plan.component.html',
    styleUrls: ['./plan-selector-plan.component.scss'],
})
export class PlanSelectorPlanComponent implements OnInit {
    plans$: Observable<Array<PaymentPlanInterface>>;
    isDataLoading$: Observable<boolean>;
    trialEnd$: Observable<Moment | null>;
    isInstallInProgress$: Observable<boolean>;

    constructor(
        private translate: TranslateService,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private store: Store,
    ) {
        this.iconRegistry.addSvgIcon(
            'include',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/include.svg'),
        );
        this.iconRegistry.addSvgIcon(
            'exclude',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/exclude.svg'),
        );
    }

    ngOnInit(): void {
        this.initializeVariables();
        this.fetchData();
    }

    private initializeVariables(): void {
        this.isDataLoading$ = combineLatest([
            this.store.select(marketplaceModulesIsDetailsLoadingSelector),
            this.store.select(marketplaceAccountIsDataLoadingSelector),
            this.store.select(marketplaceModulesIsListLoadingSelector),
        ]).pipe(
            map(([isDetailsLoading, isAccountLoading, isListLoading]) => {
                return isDetailsLoading || isAccountLoading || isListLoading;
            }),
        );
        this.trialEnd$ = this.store.select(marketplaceAccountSelector).pipe(
            map((account) => {
                return account.trialEnd === null ? null : moment(account.trialEnd);
            }),
            map((trialEnd) => {
                if (trialEnd === null || trialEnd.isBefore(moment())) {
                    return null;
                }

                return trialEnd;
            }),
        );
        this.plans$ = combineLatest([
            this.translate.stream('MARKETPLACE.PAYMENT.PLANS.PLANS'),
            this.store.select(marketplaceModulesDetailsSelector).pipe(
                withLatestFrom(this.store.select(marketplaceModulesIsDetailsLoadingSelector)),
                filter(([_, isLoading]) => {
                    return isLoading === false;
                }),
                map(([details, _]) => {
                    return details;
                }),
            ),
            this.store.select(marketplaceModulesListSelector).pipe(
                withLatestFrom(this.store.select(marketplaceModulesIsListLoadingSelector)),
                filter(([_, isLoading]) => {
                    return isLoading === false;
                }),
                map(([modules, _]) => {
                    return modules;
                }),
            ),
            this.trialEnd$.pipe(
                map((trialEnd) => {
                    return trialEnd === null;
                })
            ),
        ]).pipe(
            map(([PLANS, details, modules, isTrialEnded]) => {
                return [
                    {
                        id: 'core-basic',
                        name: PLANS.TITLES.BASIC(),
                        cost: details.tariffs.find((tariff) => {
                            return tariff.id === 'core-basic';
                        }).cost,
                        features: {
                            include:
                                Object.keys(PLANS.FEATURES)
                                    .slice(0, 6)
                                    .map((key) => {
                                        return PLANS.FEATURES[key]();
                                    }),
                            exclude:
                                Object.keys(PLANS.FEATURES)
                                    .slice(6)
                                    .map((key) => {
                                        return PLANS.FEATURES[key]();
                                    }),
                        },
                        modules: [
                            {
                                name: 'Trello',
                                cost: isTrialEnded
                                    ?
                                        +modules.find((module) => {
                                            return module.id === AppModulesEnum.TRELLO_SYNC;
                                        }).tariffs[0].cost / 100 + '$/' + PLANS.MODULES.PER.USER()
                                    :
                                        PLANS.MODULES.FREE_UNTIL_TRIAL()
                                ,
                            },
                            {
                                name: 'Jira',
                                cost: isTrialEnded
                                    ?
                                        +modules.find((module) => {
                                            return module.id === AppModulesEnum.JIRA_SYNC;
                                        }).tariffs[0].cost / 100 + '$/' + PLANS.MODULES.PER.USER()
                                    :
                                        PLANS.MODULES.FREE_UNTIL_TRIAL()
                                ,
                            },
                            {
                                name: PLANS.MODULES.NAMES.SCREENSHOTS(),
                                cost: isTrialEnded
                                    ?
                                        +modules.find((module) => {
                                            return module.id === AppModulesEnum.SCREENSHOTS;
                                        }).tariffs[0].cost / 100 + '$/' + PLANS.MODULES.PER.USER()
                                    :
                                        PLANS.MODULES.FREE_UNTIL_TRIAL()
                                ,
                            },
                            {
                                name: PLANS.MODULES.NAMES.INVOICES(),
                                cost: isTrialEnded
                                    ?
                                        1 + '$/' + PLANS.MODULES.PER.USER()
                                    :
                                        PLANS.MODULES.FREE_UNTIL_TRIAL()
                                ,
                            },
                            {
                                name: PLANS.MODULES.NAMES.DETAILED_SCREENSHOTS(),
                                cost: isTrialEnded
                                    ?
                                        +modules.find((module) => {
                                            return module.id === AppModulesEnum.SCREENSHOTS;
                                        }).tariffs[1].cost / 100 + '$/' + PLANS.MODULES.PER.USER()
                                    :
                                        PLANS.MODULES.FREE_UNTIL_TRIAL()
                                ,
                            },
                        ],
                        isInstalled: details.companyModule?.tariff.id === 'core-basic',
                        storeDataNumber: 3,
                        storeDataUnit: PLANS.STORE_DATA_UNIT.BASIC(),
                        storeScreenshotNumber: 3,
                        storeScreenshotUnit: PLANS.STORE_SCREENSHOT_UNIT.BASIC(),
                    },
                    {
                        id: 'core-pro',
                        name: PLANS.TITLES.PRO(),
                        cost: details.tariffs.find((tariff) => {
                            return tariff.id === 'core-pro';
                        }).cost,
                        features: {
                            include:
                                Object.keys(PLANS.FEATURES)
                                    .slice(0, 9)
                                    .map((key) => {
                                        return PLANS.FEATURES[key]();
                                    }),
                            exclude:
                                Object.keys(PLANS.FEATURES)
                                    .slice(9)
                                    .map((key) => {
                                        return PLANS.FEATURES[key]();
                                    }),
                        },
                        modules: [
                            {
                                name: 'Trello',
                                cost: PLANS.MODULES.FREE(),
                            },
                            {
                                name: 'Jira',
                                cost: PLANS.MODULES.FREE(),
                            },
                            {
                                name: PLANS.MODULES.NAMES.SCREENSHOTS(),
                                cost: PLANS.MODULES.FREE(),
                            },
                            {
                                name: PLANS.MODULES.NAMES.INVOICES(),
                                cost: isTrialEnded
                                    ?
                                        1 + '$/' + PLANS.MODULES.PER.USER()
                                    :
                                        PLANS.MODULES.FREE_UNTIL_TRIAL()
                                ,
                            },
                            {
                                name: PLANS.MODULES.NAMES.DETAILED_SCREENSHOTS(),
                                cost: isTrialEnded
                                    ?
                                        +modules.find((module) => {
                                            return module.id === AppModulesEnum.SCREENSHOTS;
                                        }).tariffs[1].cost / 100 + '$/' + PLANS.MODULES.PER.USER()
                                    :
                                        PLANS.MODULES.FREE_UNTIL_TRIAL()
                                ,
                            },
                        ],
                        isInstalled: details.companyModule?.tariff.id === 'core-pro',
                        storeDataNumber: 1,
                        storeDataUnit: PLANS.STORE_DATA_UNIT.PRO(),
                        storeScreenshotNumber: 6,
                        storeScreenshotUnit: PLANS.STORE_SCREENSHOT_UNIT.PRO(),
                    },
                    {
                        id: 'core-business',
                        name: PLANS.TITLES.BUSINESS(),
                        cost: details.tariffs.find((tariff) => {
                            return tariff.id === 'core-business';
                        }).cost,
                        features: {
                            include:
                                Object.keys(PLANS.FEATURES)
                                    .map((key) => {
                                        return PLANS.FEATURES[key]();
                                    }),
                            exclude: [],
                        },
                        modules: [
                            {
                                name: 'Trello',
                                cost: PLANS.MODULES.FREE(),
                            },
                            {
                                name: 'Jira',
                                cost: PLANS.MODULES.FREE(),
                            },
                            {
                                name: PLANS.MODULES.NAMES.SCREENSHOTS(),
                                cost: PLANS.MODULES.FREE(),
                            },
                            {
                                name: PLANS.MODULES.NAMES.INVOICES(),
                                cost: PLANS.MODULES.FREE(),
                            },
                            {
                                name: PLANS.MODULES.NAMES.DETAILED_SCREENSHOTS(),
                                cost: isTrialEnded
                                    ?
                                        +modules.find((module) => {
                                            return module.id === AppModulesEnum.SCREENSHOTS;
                                        }).tariffs[1].cost / 100 + '$/' + PLANS.MODULES.PER.USER()
                                    :
                                        PLANS.MODULES.FREE_UNTIL_TRIAL()
                                ,
                            },
                        ],
                        isInstalled: details.companyModule?.tariff.id === 'core-business',
                        storeDataNumber: 2,
                        storeDataUnit: PLANS.STORE_DATA_UNIT.BUSINESS(),
                        storeScreenshotNumber: 6,
                        storeScreenshotUnit: PLANS.STORE_SCREENSHOT_UNIT.BUSINESS(),
                    },
                ];
            }),
        );
        this.isInstallInProgress$ = this.store.select(marketplaceModulesIsInstallInProgressSelector);
    }

    private fetchData(): void {
        this.store.dispatch(marketplaceModulesGetDetailsAction({ id: 'core' }));
        this.store.dispatch(marketplaceGetAccountAction());
        this.store.dispatch(marketplaceModulesGetListAction());
    }

    selectPlan(id: string): void {
        this.store.dispatch(marketplaceModulesInstallAction({
            moduleId: 'core',
            tariffId: id,
            forAll: null,
        }));
    }
}
