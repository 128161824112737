<ng-container *ngIf="isShowLoader === false">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
        <ui-form-group [has-errors]="username.invalid && (username.dirty || username.touched)">
            <label ui-label for="username">
                {{ 'LOGIN.LOGIN_FORM.EMAIL.LABEL' | translate }}
            </label>
            <input
                ui-input
                formControlName="username"
                id="username"
                type="email"
                placeholder="{{ 'LOGIN.LOGIN_FORM.EMAIL.PLACEHOLDER' | translate }}"
                autocomplete="off"
                trim
            />
            <div
                ui-hint
                app-form-errors
                *ngIf="username.dirty || username.touched"
                [formControlRef]="username"
                controlName="{{ 'LOGIN.LOGIN_FORM.EMAIL.LABEL' | translate }}"
                controlNameGender="male"
            ></div>
        </ui-form-group>

        <ui-form-group [has-errors]="password.invalid && (password.dirty || password.touched)">
            <label ui-label for="password">{{ 'LOGIN.LOGIN_FORM.PASSWORD.LABEL' | translate }}</label>
            <input
                ui-input
                type="password"
                formControlName="password"
                id="password"
                placeholder="{{ 'LOGIN.LOGIN_FORM.PASSWORD.PLACEHOLDER' | translate }}"
                autocomplete="off"
            />
            <div
                ui-hint
                app-form-errors
                *ngIf="password.dirty || password.touched"
                [formControlRef]="password"
                controlName="{{ 'LOGIN.LOGIN_FORM.PASSWORD.LABEL' | translate }}"
                controlNameGender="male"
            ></div>
        </ui-form-group>

        <ui-form-group class="fg-errors" [has-errors]="true" *ngIf="(backendErrors$ | async) && form.pristine">
            <div ui-hint>{{ (backendErrors$ | async).message }}</div>
        </ui-form-group>

        <div class="forgot-password">
            <a class="link reduced-text" routerLink="/restore-password">
                {{ 'LOGIN.LOGIN_FORM.FORGOT_PASSWORD' | translate }}
            </a>
        </div>

        <ui-form-group>
            <button
                ui-button
                class="full-width"
                size="large"
                color="yellow"
                type="submit"
                [disabled]="((backendErrors$ | async) && form.pristine) || (isSubmitting$ | async) || (isUserLoading$ | async) || form.invalid"
            >
                {{ 'LOGIN.LOGIN_FORM.SIGN_IN' | translate }}
            </button>
        </ui-form-group>
    </form>

    <div class="buttons-separator">{{ 'LOGIN.OR' | translate }}</div>

    <ui-form-group>
        <button
            ui-button
            type="button"
            class="full-width"
            size="large"
            (click)="authorizeBy('google')"
            icon="https://assets-cdn.umbrellait.com/images/google-logo.svg"
        >
            {{ 'LOGIN.SIGN_IN_WITH_GOOGLE' | translate }}
        </button>
    </ui-form-group>

    <div class="want-to-join">
        <span>{{ 'LOGIN.WANT_TO_JOIN_US' | translate }}</span>&nbsp;<a class="link" routerLink="/request-access">{{ 'LOGIN.REQUEST_ACCESS' | translate }}</a>
    </div>
</ng-container>

<div *ngIf="isShowLoader === true" class="spinner-wrapper">
    <ui-spinner></ui-spinner>
</div>
