import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { TranslateModule } from "@ngx-translate/core";
import { NewYearSettingsComponent } from "./new-year-settings.component";
import { NewYearComponent } from "./new-year.component";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        MatCheckboxModule,
    ],
    declarations: [
        NewYearComponent,
        NewYearSettingsComponent,
    ],
    exports: [
        NewYearComponent,
        NewYearSettingsComponent,
    ],
})
export class NewYearModule {}