export enum ProjectPositionsActionTypes {
    SET_LIST_QUERY_PARAMS = '[ProjectPositions] Set list query params',

    GET_LIST = '[ProjectPositions] Get list',
    GET_LIST_SUCCESS = '[ProjectPositions] Get list success',
    GET_LIST_FAILURE = '[ProjectPositions] Get list failure',

    CREATE = '[ProjectPositions] Create',
    CREATE_SUCCESS = '[ProjectPositions] Create success',
    CREATE_FAILURE = '[ProjectPositions] Create failure',

    UPDATE = '[ProjectPositions] Update',
    UPDATE_SUCCESS = '[ProjectPositions] Update success',
    UPDATE_FAILURE = '[ProjectPositions] Update failure',

    REMOVE = '[ProjectPositions] Remove',
    REMOVE_SUCCESS = '[ProjectPositions] Remove success',
    REMOVE_FAILURE = '[ProjectPositions] Remove failure',
}
