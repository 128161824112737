import { createReducer, on } from '@ngrx/store';

import { ProjectMemberPermissionActionEnum } from '@app-client/project/types/project-member.interface';
import {
    createProjectMemberAction,
    createProjectMemberFailureAction,
    createProjectMemberSuccessAction,
    getProjectMemberAction,
    getProjectMemberFailureAction,
    getProjectMemberListAction,
    getProjectMemberListFailureAction,
    getProjectMemberListSuccessAction,
    getProjectMemberSuccessAction,
    projectMembersBatchUpdatePermissionAction,
    projectMembersBatchUpdatePermissionsFailureAction,
    projectMembersBatchUpdatePermissionsSuccessAction,
    removeProjectMemberAction,
    removeProjectMemberFailureAction,
    removeProjectMemberSuccessAction,
    setProjectMembersListQueryParamsAction,
    updateProjectMemberAction,
    updateProjectMemberFailureAction,
    updateProjectMemberSuccessAction,
} from '@app-client/store/project-members/actions/project-members.action';
import { ProjectMembersStateInterface } from '@app-client/store/project-members/project-members-state.interface';


const initialState: ProjectMembersStateInterface = {
    projectMemberList: [],
    isProjectMemberListLoading: false,
    hasMoreProjectMember: true,
    listQueryParams: null,
    isCreateProjectMemberInProgress: false,
    createProjectMemberBackendErrors: undefined,
    isRemoveProjectMemberInProgress: false,
    isUpdateProjectMemberInProgress: false,
    updateProjectMemberBackendErrors: undefined,
    projectMemberDetails: undefined,
    isProjectMemberDetailsLoading: false,
    projectMembersInUpdatePermissionIds: [],
};

const reducer = createReducer(
    initialState,
    on(
        getProjectMemberListAction,
        (state) => ({
            ...state,
            isProjectMemberListLoading: true,
            hasMoreProjectMember: true,
            projectMemberList: state.listQueryParams.page === 1 ? [] : [...state.projectMemberList],
        }),
    ),
    on(
        getProjectMemberListSuccessAction,
        (state, action) => ({
            ...state,
            isProjectMemberListLoading: false,
            hasMoreProjectMember: action.response.page < action.response.totalPages,
            projectMemberList: [...state.projectMemberList, ...action.response.items],
        }),
    ),
    on(
        getProjectMemberListFailureAction,
        (state) => ({
            ...state,
            isProjectMemberListLoading: false,
            hasMoreProjectMember: false,
        }),
    ),
    on(
        setProjectMembersListQueryParamsAction,
        (state, action) => ({
            ...state,
            listQueryParams: action.queryParams,
        }),
    ),
    on(
        createProjectMemberAction,
        (state) => ({
            ...state,
            isCreateProjectMemberInProgress: true,
        }),
    ),
    on(
        createProjectMemberSuccessAction,
        (state) => ({
            ...state,
            isCreateProjectMemberInProgress: false,
        }),
    ),
    on(
        createProjectMemberFailureAction,
        (state, action) => ({
            ...state,
            isCreateProjectMemberInProgress: false,
            createProjectMemberBackendErrors: action.backendErrors,
        })
    ),
    on(
        updateProjectMemberAction,
        (state) => ({
            ...state,
            isUpdateProjectMemberInProgress: true,
        }),
    ),
    on(
        updateProjectMemberSuccessAction,
        (state, action) => ({
            ...state,
            isUpdateProjectMemberInProgress: false,
            projectMemberDetails: action.response.body,
        }),
    ),
    on(
        updateProjectMemberFailureAction,
        (state, action) => ({
            ...state,
            isUpdateProjectMemberInProgress: false,
            updateProjectMemberBackendErrors: action.backendErrors,
        }),
    ),
    on(
        removeProjectMemberAction,
        (state) => ({
            ...state,
            isRemoveProjectMemberInProgress: true,
        }),
    ),
    on(
        removeProjectMemberSuccessAction,
        (state) => ({
            ...state,
            isRemoveProjectMemberInProgress: false,
        }),
    ),
    on(
        removeProjectMemberFailureAction,
        (state) => ({
            ...state,
            isRemoveProjectMemberInProgress: false,
        }),
    ),
    on(
        getProjectMemberAction,
        (state) => ({
            ...state,
            isProjectMemberDetailsLoading: true,
            projectMemberDetails: undefined,
        }),
    ),
    on(
        getProjectMemberSuccessAction,
        (state, action) => ({
            ...state,
            projectMemberDetails: action.response.body,
            isProjectMemberDetailsLoading: false,
        }),
    ),
    on(
        getProjectMemberFailureAction,
        (state) => ({
            ...state,
            isProjectMemberDetailsLoading: false,
        }),
    ),

    on(
        projectMembersBatchUpdatePermissionAction,
        (state, action) => ({
            ...state,
            projectMembersInUpdatePermissionIds: [...state.projectMembersInUpdatePermissionIds, ...action.ids],
        }),
    ),

    on(
        projectMembersBatchUpdatePermissionsSuccessAction,
        projectMembersBatchUpdatePermissionsFailureAction,
        (state, action) => ({
            ...state,
            projectMembersInUpdatePermissionIds: state.projectMembersInUpdatePermissionIds.filter((inUpdateId) => {
                return action.ids.every((id) => {
                    return id !== inUpdateId;
                });
            }),
        }),
    ),

    on(
        projectMembersBatchUpdatePermissionsSuccessAction,
        (state, action) => ({
            ...state,
            projectMemberList: state.projectMemberList.map((member) => {
                const isUpdated = action.ids.some((id) => {
                    return member.id === id;
                });

                if (isUpdated) {
                    if (action.action === ProjectMemberPermissionActionEnum.ADD) {
                        return {
                            ...member,
                            permissions: [...member.permissions, { id: action.permission }],
                        };
                    }

                    if (action.action === ProjectMemberPermissionActionEnum.REMOVE) {
                        return {
                            ...member,
                            permissions: member.permissions.filter((permission) => {
                                return permission.id !== action.permission;
                            }),
                        };
                    }
                }

                return member;
            }),
        }),
    ),
);

export const projectMembersReducer = (state, action) => reducer(state, action);
