export enum UserRequestActionType {
    GET_USER_REQUEST_LIST = '[UserRequests] Get user request list',
    GET_USER_REQUEST_LIST_SUCCESS = '[UserRequests] Get user request list success',
    GET_USER_REQUEST_LIST_FAILURE = '[UserRequests] Get user request list failure',

    DELETE_USER_REQUEST = '[UserRequests] Delete user request',
    DELETE_USER_REQUEST_SUCCESS = '[UserRequests] Delete user request success',
    DELETE_USER_REQUEST_FAILURE = '[UserRequests] Delete user request failure',

    LINK_USER_REQUEST = '[UserRequests] Link user request',
    LINK_USER_REQUEST_SUCCESS = '[UserRequests] Link user request success',
    LINK_USER_REQUEST_FAILURE = '[UserRequests] Link user request failure',

    CREATE_USER_FROM_USER_REQUEST = '[UserRequests] Create user from user request',
    CREATE_USER_FROM_USER_REQUEST_SUCCESS = '[UserRequests] Create user from user request success',
    CREATE_USER_FROM_USER_REQUEST_FAILURE = '[UserRequests] Create user from user request failure',

    SET_USER_REQUEST_LIST_QUERY_PARAMS = '[UserRequests] Set user request list query params',
}
