import { createAction, props } from '@ngrx/store';

import { ActionTypes } from '@app-client/store/auth/action-types';
import { CredentialsInterface } from '@app-client/store/auth/types/credentials.interface';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


export const loginAction = createAction(
    ActionTypes.LOGIN,
    props<{ username: string, password: string }>(),
);

export const loginSuccessAction = createAction(
    ActionTypes.LOGIN_SUCCESS,
    props<{ credentials: CredentialsInterface }>(),
);

export const loginFailureAction = createAction(
    ActionTypes.LOGIN_SUCCESS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const logoutAction = createAction(ActionTypes.LOGOUT);
