import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import {
    createProjectPositionAction,
    createProjectPositionFailureAction,
    createProjectPositionSuccessAction,
    getProjectPositionListAction,
    getProjectPositionListFailureAction,
    getProjectPositionListSuccessAction,
    removeProjectPositionAction,
    removeProjectPositionFailureAction,
    removeProjectPositionSuccessAction, setProjectPositionListQueryParamsAction,
    updateProjectPositionAction,
    updateProjectPositionFailureAction,
    updateProjectPositionSuccessAction,
} from '@app-client/store/project-posiitons/actions/project-positions.action';
import { projectPositionListQueryParamsSelector } from '@app-client/store/project-posiitons/selectors/project-positions.selector';
import { ProjectPositionsService } from '@app-client/store/project-posiitons/serivces/project-positions.service';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class ProjectPositionsEffects {

    constructor(
        private actions$: Actions,
        private store$: Store,
        private toastr: ToastrService,
        private translate: TranslateService,
        private projectPositionsService: ProjectPositionsService,
    ) {}

    getProjectPositionsList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getProjectPositionListAction),
            withLatestFrom(this.store$.select(projectPositionListQueryParamsSelector)),
            switchMap(([, queryParams]) => {
                return this.projectPositionsService.getProjectPositionsList(queryParams).pipe(
                    map((response) => {
                        return getProjectPositionListSuccessAction({ response });
                    }),
                    catchError( (httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(getProjectPositionListFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getProjectPositionsListFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getProjectPositionListFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('PROJECT_POSITIONS.NOTIFICATIONS.GET_PROJECT_POSITIONS_LIST.ERROR.TITLE')
                );
            }),
        );
    }, { dispatch: false });

    createProjectPosition$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createProjectPositionAction),
            switchMap(({ createProjectPosition, successCallback }) => {
                return this.projectPositionsService.createProjectPosition(createProjectPosition).pipe(
                    map(() => {
                        return createProjectPositionSuccessAction({ successCallback });
                    }),
                    catchError( (httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(createProjectPositionFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    createProjectPositionSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createProjectPositionSuccessAction),
            tap(({ successCallback }) => {
                this.toastr.success(
                    this.translate.instant('PROJECT_POSITIONS.NOTIFICATIONS.CREATE.SUCCESS.MESSAGE'),
                    this.translate.instant('PROJECT_POSITIONS.NOTIFICATIONS.CREATE.SUCCESS.TITLE')
                );
                successCallback();
            }),
        );
    }, { dispatch: false });


    createProjectPositionFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createProjectPositionFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('PROJECT_POSITIONS.NOTIFICATIONS.CREATE.ERROR.TITLE')
                );
            }),
        );
    }, { dispatch: false });

    updateProjectPosition$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateProjectPositionAction),
            switchMap(({ id, updateProjectPosition, successCallback }) => {
                return this.projectPositionsService.updateProjectPosition(id, updateProjectPosition).pipe(
                    map( () => {
                        return updateProjectPositionSuccessAction({ successCallback });
                    }),
                    catchError( (httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(updateProjectPositionFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    updateProjectPositionSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateProjectPositionSuccessAction),
            tap(({ successCallback }) => {
                this.toastr.success(
                    this.translate.instant('PROJECT_POSITIONS.NOTIFICATIONS.UPDATE.SUCCESS.MESSAGE'),
                    this.translate.instant('PROJECT_POSITIONS.NOTIFICATIONS.UPDATE.SUCCESS.TITLE')
                );
                successCallback();
            }),
        );
    }, { dispatch: false });


    updateProjectPositionFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateProjectPositionFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('PROJECT_POSITIONS.NOTIFICATIONS.UPDATE.ERROR.TITLE')
                );
            }),
        );
    }, { dispatch: false });

   removeProjectPosition$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(removeProjectPositionAction),
            switchMap(({ id }) => {
                return this.projectPositionsService.removeProjectPosition(id).pipe(
                    map( () => {
                        return removeProjectPositionSuccessAction();
                    }),
                    catchError( (httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(removeProjectPositionFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    removeProjectPositionSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(removeProjectPositionSuccessAction),
            withLatestFrom(this.store$.select(projectPositionListQueryParamsSelector)),
            switchMap(([, queryParams]) => {
                this.toastr.success(
                    this.translate.instant('PROJECT_POSITIONS.NOTIFICATIONS.DELETE.SUCCESS.MESSAGE'),
                    this.translate.instant('PROJECT_POSITIONS.NOTIFICATIONS.DELETE.SUCCESS.TITLE')
                );
                return of(
                    setProjectPositionListQueryParamsAction({
                        queryParams: {
                            ...queryParams,
                            page: 1,
                        }
                    }),
                    getProjectPositionListAction(),
                );
            }),
        );
    }, );


    removeProjectPositionFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(removeProjectPositionFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('PROJECT_POSITIONS.NOTIFICATIONS.DELETE.ERROR.TITLE')
                );
            }),
        );
    }, { dispatch: false });
}
