import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { UIModule } from '@umbrella-web/attrack-ui-module';
import { JoyrideModule } from 'ngx-joyride';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { DailyResultGuard } from '@app-client/daily-result/daily-result.guard';
import { HeaderComponent } from '@app-client/layout/components/header/header.component';
import { LayoutComponent } from '@app-client/layout/components/layout/layout.component';
import { PlanSelectorLayoutComponent } from '@app-client/layout/components/plan-selector/layout/plan-selector-layout.component';
import { PlanSelectorModulesComponent } from '@app-client/layout/components/plan-selector/modules/plan-selector-modules.component';
import { PlanSelectorPlanComponent } from '@app-client/layout/components/plan-selector/plan/plan-selector-plan.component';
import { SidebarComponent } from '@app-client/layout/components/sidebar/sidebar.component';
import { LoggedAsModule } from '@app-client/logged-as/logged-as.module';
import { NewYearModule } from '@app-client/new-year/new-year.module';
import { NotFoundModule } from '@app-client/not-found/not-found.module';
import { ProjectRoleEnum } from '@app-client/project/types/project-role.enum';
import { AuthGuardService } from '@app-client/shared/services/auth-guard.service';
import { CoreTariffGuardService } from '@app-client/shared/services/core-tariff-guard.service';
import { HappinessModuleGuardService } from '@app-client/shared/services/happiness-module-guard.service';
import { RoleGuardService } from '@app-client/shared/services/role-guard.service';
import { RoleGuardDataInterface } from '@app-client/shared/types/role-guard-data.interface';
import { UserRoleEnum } from '@app-client/shared/types/user-role.enum';
import { TourModule } from '@app-client/tour/tour.module';
import { CoreModuleTariffsEnum } from '@app-shared/types/app-modules.enum';


const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', redirectTo: '/activity', pathMatch: 'full' },
            { path: 'profile', loadChildren: () => import('@app-client/user-profile/user-profile.module').then(m => m.UserProfileModule) },
            { path: 'activity', loadChildren: () => import('@app-client/activity/activity.module').then(m => m.ActivityModule) },
            {
                path: 'effectivity',
                loadChildren: () => import('@app-client/effectivity/effectivity.module').then(m => m.EffectivityModule),
                canActivate: [CoreTariffGuardService],
                data: { coreTariff: CoreModuleTariffsEnum.BUSINESS },
            },
            {
                path: 'users',
                loadChildren: () => import('@app-client/user/user.module').then(m => m.UserModule),
                canActivate: [RoleGuardService],
                data: {
                    role: UserRoleEnum.ROLE_SYSADMIN,
                } as RoleGuardDataInterface,
            },
            {
                path: 'project-spaces',
                loadChildren: () => import('@app-client/project-space/project-space.module').then(m => m.ProjectSpaceModule),
                canActivate: [RoleGuardService],
                data: {
                    role: UserRoleEnum.ROLE_ADMIN,
                } as RoleGuardDataInterface,
            },
            {
                path: 'time-requests',
                loadChildren: () => import('@app-client/time-request/time-request.module').then(m => m.TimeRequestModule),
                canActivate: [RoleGuardService],
                data: {
                    role: [UserRoleEnum.ROLE_USER, UserRoleEnum.ROLE_SYSADMIN, UserRoleEnum.ROLE_HEAD],
                    projectRole: ProjectRoleEnum.ROLE_MANAGER,
                } as RoleGuardDataInterface,
            },
            {
                path: 'company',
                loadChildren: () => import('@app-client/company/company.module').then(m => m.CompanyModule),
                canActivate: [RoleGuardService],
                data: {
                    role: UserRoleEnum.ROLE_ADMIN,
                } as RoleGuardDataInterface,
            },
            {
                path: 'projects',
                loadChildren: () => import('@app-client/project/projects.module').then(m => m.ProjectsModule),
            },
            {
                path: 'modules',
                loadChildren: () => import('@app-client/marketplace/marketplace.module').then(m => m.MarketplaceModule),
                canActivate: [RoleGuardService],
                data: {
                    role: UserRoleEnum.ROLE_ADMIN,
                } as RoleGuardDataInterface,
            },
            {
                path: 'payment',
                loadChildren: () => import('@app-client/payment/payment.module').then(m => m.PaymentModule),
                canActivate: [RoleGuardService],
                data: {
                    role: UserRoleEnum.ROLE_ADMIN,
                } as RoleGuardDataInterface,
            },
            { path: 'report', loadChildren: () => import('@app-client/report/report.module').then(m => m.ReportModule) },
            {
                path: 'daily-result',
                loadChildren: () => import('@app-client/daily-result/daily-result.module').then(m => m.DailyResultMoudle),
                canActivate: [DailyResultGuard],
            },
            {
                path: 'invoicing',
                loadChildren: () => import('@app-client/invoicing/invoicing.module').then(m => m.InvoicingModule),
                canActivate: [RoleGuardService],
                data: {
                    role: [UserRoleEnum.ROLE_ADMIN, UserRoleEnum.ROLE_USER, UserRoleEnum.ROLE_HEAD, UserRoleEnum.ROLE_SYSADMIN],
                    projectRole: [ProjectRoleEnum.ROLE_MANAGER],
                } as RoleGuardDataInterface,
            },
            {
                path: 'happiness-index',
                loadChildren: () => import('@app-client/happiness-index/happpiness-index.module').then(m => m.HappinessIndexModule),
                canActivate: [HappinessModuleGuardService],
            },
            { path: '**', loadChildren: () => NotFoundModule }, // Eager loading
        ],
        canActivate: [AuthGuardService],
    },
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        UIModule,
        TranslateModule,
        NgScrollbarModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        MatDividerModule,
        LoggedAsModule,
        JoyrideModule.forRoot(),
        TourModule,
        NewYearModule,
    ],
    declarations: [
        LayoutComponent,
        HeaderComponent,
        SidebarComponent,
        PlanSelectorPlanComponent,
        PlanSelectorLayoutComponent,
        PlanSelectorModulesComponent,
    ],
    exports: [
        HeaderComponent,
        SidebarComponent,
    ],
    providers: [
        DailyResultGuard,
    ],
})
export class LayoutModule {}
