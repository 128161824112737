import { createReducer, on } from '@ngrx/store';

import {
    signatureGetDocumentAction,
    signatureGetDocumentFailureAction,
    signatureGetDocumentSuccessAction
} from './signature-document.actions';
import { SignatureDocumentInterface } from './signature-document.interface';


const initialState: SignatureDocumentInterface = {
    isDocumentLoading: false,
    document: {
        template: []
    }
};

const reducer = createReducer(
    initialState,
    on(
        signatureGetDocumentAction,
        (state) => ({
            ...state,
            isDocumentLoading: true,
        }),
    ),
    on(
        signatureGetDocumentSuccessAction,
        (state, { document }) => ({
            ...state,
            isDocumentLoading: false,
            document,
        }),
    ),
    on(
        signatureGetDocumentFailureAction,
        (state) => ({
            ...state,
            isDocumentLoading: false,
            list: null,
        }),
    ),
);

export const signatureDocumentReducer = (state, action) => reducer(state, action);
