import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UsersStateInterface } from '@app-client/store/users/users-state.interface';


export const usersFeatureSelector = createFeatureSelector<UsersStateInterface>('users');

export const usersSelector = createSelector(
    usersFeatureSelector,
    state => state.users,
);

export const isUsersListLoadingSelector = createSelector(
    usersFeatureSelector,
    state => state.isListLoading,
);

export const usersQueryConfigSelector = createSelector(
    usersFeatureSelector,
    state => state.queryConfig,
);

export const usersHasLoadingMoreSelector = createSelector(
    usersFeatureSelector,
    state => state.hasLoadingMore,
);

export const isCreateUserInProgressSelector = createSelector(
    usersFeatureSelector,
    state => state.isCreateInProgress,
);

export const isUpdateUserInProgressSelector = createSelector(
    usersFeatureSelector,
    state => state.isUpdateInProgress,
);

export const isDeleteUserInProgressSelector = createSelector(
    usersFeatureSelector,
    state => state.isDeleteInProgress,
);

export const userBackendErrorsSelector = createSelector(
    usersFeatureSelector,
    state => state.backendErrors,
);

export const isUserDetailsLoadingSelector = createSelector(
    usersFeatureSelector,
    state => state.isUserDetailsLoading,
);

export const userDetailsSelector = createSelector(
    usersFeatureSelector,
    state => state.userDetails,
);

export const usersTotalCountSelector = createSelector(
    usersFeatureSelector,
    state => state.totalCount,
);

export const usersIsTotalCountLoadingSelector = createSelector(
    usersFeatureSelector,
    state => state.isTotalCountLoading,
);

export const usersIsDeactivateInProgressSelector = createSelector(
    usersFeatureSelector,
    state => state.isDeactivateInProgress,
);

export const usersIsNeedForceDeactivateSelector = createSelector(
    usersFeatureSelector,
    state => state.isNeedForceDeactivate,
);

export const usersIsActivateInProgressSelector = createSelector(
    usersFeatureSelector,
    state => state.isActivateInProgress,
);

export const employeesSelector = createSelector(
    usersFeatureSelector,
    state => state.employees,
);

export const isEmployeesListLoadingSelector = createSelector(
    usersFeatureSelector,
    state => state.isEmployeesLoading,
);
