import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';

@Directive({
    selector: '[clickOutside]'
})
export class ClickOutsideDirective implements OnInit, OnDestroy{
    @Output() public clickOutside = new EventEmitter<MouseEvent>();

    constructor(
        private elementRef: ElementRef,
        @Inject(DOCUMENT) private document: Document,
    ) { }

    public ngOnInit() {
        this.document.addEventListener('click', ($event) => this.onClick($event), true);
    }

    public ngOnDestroy() {
        this.document.removeEventListener('click', ($event) => this.onClick($event), true);
    }

    public onClick(event$: MouseEvent): void {
        if (!this.elementRef.nativeElement.contains(event$.target)) {
            this.clickOutside.emit();
        }
    }
}
