import { createReducer, on } from '@ngrx/store';

import {
    archiveProjectSpaceAction,
    archiveProjectSpaceFailureAction,
    archiveProjectSpaceSuccessAction,
    createProjectSpaceAction,
    createProjectSpaceFailureAction,
    createProjectSpaceSuccessAction,
    getArchivedProjectSpaceAction,
    getArchivedProjectSpaceFailureAction,
    getArchivedProjectSpacesAction,
    getArchivedProjectSpacesFailureAction,
    getArchivedProjectSpacesSuccessAction,
    getArchivedProjectSpaceSuccessAction,
    getProjectSpaceAction,
    getProjectSpaceFailureAction,
    getProjectSpacesAction,
    getProjectSpacesFailureAction,
    getProjectSpacesSuccessAction,
    getProjectSpaceSuccessAction,
    restoreArchivedProjectSpaceAction,
    restoreArchivedProjectSpaceFailureAction,
    restoreArchivedProjectSpaceSuccessAction,
    setArchivedProjectSpacesQueryConfigAction,
    setProjectSpacesQueryConfigAction,
    updateProjectSpaceAction,
    updateProjectSpaceFailureAction,
    updateProjectSpaceSuccessAction,
} from '@app-client/store/project-spaces/actions/project-spaces.action';
import { ProjectSpacesStateInterface } from '@app-client/store/project-spaces/project-spaces-state.interface';


const initialState: ProjectSpacesStateInterface = {
    isArchivedProjectSpacesLoading: false,
    archivedProjectSpaces: [],
    archivedProjectSpacesQueryConfig: null,
    isArchivedProjectSpacesLoadingMore: true,
    isArchiveProjectSpaceLoading: false,
    isRestoreProjectSpaceInProgress: false,
    isProjectSpacesLoading: false,
    projectSpaces: [],
    projectSpacesQueryConfig: null,
    isProjectSpacesLoadingMore: true,
    isProjectSpaceLoading: false,
    isArchiveProjectSpaceInProgress: false,
    isCreatingProjectSpaceInProgress: false,
    creatingProjectSpaceErrors: null,
    isUpdatingProjectSpaceInProgress: false,
    updatingProjectSpaceErrors: null,
    projectSpaceDetails: null,
};

const reducer = createReducer(
    initialState,
    on(
        getArchivedProjectSpacesAction,
        state => ({
            ...state,
            isArchivedProjectSpacesLoading: true,
            archivedProjectSpaces:  state.archivedProjectSpacesQueryConfig.page === 1 ? [] : state.archivedProjectSpaces,
            isArchivedProjectSpacesLoadingMore: true,
        }),
    ),
    on(
        getArchivedProjectSpacesSuccessAction,
        (state, action) => ({
            ...state,
            archivedProjectSpaces: [...state.archivedProjectSpaces, ...action.response.items],
            isArchivedProjectSpacesLoading: false,
            isArchivedProjectSpacesLoadingMore: action.response.page < action.response.totalPages,
        }),
    ),
    on(
        getArchivedProjectSpacesFailureAction,
        state => ({
            ...state,
            isArchivedProjectSpacesLoading: false,
            isArchivedProjectSpacesLoadingMore: false,
        }),
    ),
    on(
        setArchivedProjectSpacesQueryConfigAction,
        (state, action) => ({
            ...state,
            archivedProjectSpacesQueryConfig: action.config,
        }),
    ),
    on(
        getArchivedProjectSpaceAction,
        state => ({
            ...state,
            isArchiveProjectSpaceLoading: true,
            projectSpaceDetails: null,
        }),
    ),
    on(
        getArchivedProjectSpaceSuccessAction,
        (state, action) => ({
            ...state,
            isArchiveProjectSpaceLoading: false,
            projectSpaceDetails: action.response.body,
        }),
    ),
    on(
        getArchivedProjectSpaceFailureAction,
        state => ({
            ...state,
            isArchiveProjectSpaceLoading: false,
        }),
    ),

    on(
        restoreArchivedProjectSpaceAction,
        state => ({
            ...state,
            isRestoreProjectSpaceInProgress: true,
        }),
    ),

    on(
        restoreArchivedProjectSpaceSuccessAction,
        state => ({
            ...state,
            isRestoreProjectSpaceInProgress: false,
        }),
    ),

    on(
        restoreArchivedProjectSpaceFailureAction,
        state => ({
            ...state,
            isRestoreProjectSpaceInProgress: false,
        }),
    ),
    on(
        getProjectSpacesAction,
        state => ({
            ...state,
            isProjectSpacesLoading: true,
            projectSpaces: state.projectSpacesQueryConfig.page === 1 ? [] : state.projectSpaces,
            isProjectSpacesLoadingMore: true,
        }),
    ),
    on(
        getProjectSpacesSuccessAction,
        (state, action) => ({
            ...state,
            projectSpaces: [...state.projectSpaces, ...action.response.body.items],
            isProjectSpacesLoading: false,
            isProjectSpacesLoadingMore: action.response.body.page < action.response.body.totalPages,
        }),
    ),
    on(
        getProjectSpacesFailureAction,
        state => ({
            ...state,
            isProjectSpacesLoading: false,
            isProjectSpacesLoadingMore: false,
        }),
    ),

    // ---set prams---
    on(
        setProjectSpacesQueryConfigAction,
        (state, action) => ({
            ...state,
            projectSpacesQueryConfig: action.config,
        }),
    ),

    // ---get one---
    on(
        getProjectSpaceAction,
        state => ({
            ...state,
            isProjectSpaceLoading: true,
            projectSpaceDetails: null,
        }),
    ),
    on(
        getProjectSpaceSuccessAction,
        (state, action) => ({
            ...state,
            isProjectSpaceLoading: false,
            projectSpaceDetails: action.response.body,
        }),
    ),
    on(
        getProjectSpaceFailureAction,
        state => ({
            ...state,
            isProjectSpaceLoading: false,
        }),
    ),

    // ---create---
    on(
        createProjectSpaceAction,
        state => ({
            ...state,
            isCreatingProjectSpaceInProgress: true,
        }),
    ),
    on(
        createProjectSpaceSuccessAction,
        state => ({
            ...state,
            isCreatingProjectSpaceInProgress: false,
        }),
    ),
    on(
        createProjectSpaceFailureAction,
        (state, action) => ({
            ...state,
            isCreatingProjectSpaceInProgress: false,
            creatingProjectSpaceErrors: action.backendErrors,
        }),
    ),

    // ---update---
    on(
        updateProjectSpaceAction,
        state => ({
            ...state,
            isUpdatingProjectSpaceInProgress: true,
        }),
    ),
    on(
        updateProjectSpaceSuccessAction,
        state => ({
            ...state,
            isUpdatingProjectSpaceInProgress: false,
        }),
    ),
    on(
        updateProjectSpaceFailureAction,
        (state, action) => ({
            ...state,
            isUpdatingProjectSpaceInProgress: false,
            updatingProjectSpaceErrors: action.backendErrors,
        }),
    ),

    // ---archive---
    on(
        archiveProjectSpaceAction,
        state => ({
            ...state,
            isArchiveProjectSpaceInProgress: true,
        }),
    ),
    on(
        archiveProjectSpaceSuccessAction,
        state => ({
            ...state,
            isArchiveProjectSpaceInProgress: false,
        }),
    ),
    on(
        archiveProjectSpaceFailureAction,
        state => ({
            ...state,
            isArchiveProjectSpaceInProgress: false,
        }),
    ),
);

export const projectSpacesReducer = (state, action) => reducer(state, action);
