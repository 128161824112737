import { createAction, props } from '@ngrx/store';

import { ReportUserActivityFiltersInterface } from '@app-client/report/user-activity/types/report-user-activity-filters.interface';
import { ReportUserActivityReportInterface } from '@app-client/report/user-activity/types/report-user-activity-report.interface';
import { ReportUserActivityActionTypes } from '@app-client/store/report/user-activity/report-user-activity-action-types';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


export const reportUserActivityGetReportAction = createAction(
    ReportUserActivityActionTypes.GET_REPORT,
    props<{ filters: ReportUserActivityFiltersInterface }>(),
);

export const reportUserActivityGetReportSuccessAction = createAction(
    ReportUserActivityActionTypes.GET_REPORT_SUCCESS,
    props<{ report: ReportUserActivityReportInterface, filters: ReportUserActivityFiltersInterface }>(),
);

export const reportUserActivityGetReportFailureAction = createAction(
    ReportUserActivityActionTypes.GET_REPORT_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const reportUserActivityClearData = createAction(
    ReportUserActivityActionTypes.CLEAR_DATA,
);

export const reportUserActivityExportGoogleSpreadsheetAction = createAction(
    ReportUserActivityActionTypes.EXPORT_GOOGLE_SPREADSHEET,
    props<{ filters: ReportUserActivityFiltersInterface }>(),
);

export const reportUserActivityExportGoogleSpreadsheetSuccessAction = createAction(
    ReportUserActivityActionTypes.EXPORT_GOOGLE_SPREADSHEET_SUCCESS,
);

export const reportUserActivityExportGoogleSpreadsheetFailureAction = createAction(
    ReportUserActivityActionTypes.EXPORT_GOOGLE_SPREADSHEET_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
