import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SignRequestType } from '@app-client/store/signature/unsiged-intervals/types/sign-request.type';
import { UnsignedIntervalType } from '@app-client/store/signature/unsiged-intervals/types/unsigned-interval.type';
import { PageResponseInterface } from '@app-shared/types/page-response.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';

import { environment } from '@env-client/environment';


@Injectable()
export class SignatureUnsignedIntervalsService {

    constructor(
        private http: HttpClient,
    ) {}

    getList(): Observable<Array<UnsignedIntervalType>> {
        const url = `${environment.apiBaseUrl}/signature/unsigned`;

        return this.http.get<ResponseInterface<PageResponseInterface<UnsignedIntervalType>>>(url).pipe(
            map((response) => {
                return response.body.items;
            }),
        );
    }

    sign(signRequest: SignRequestType): Observable<void> {
        const url = `${environment.apiBaseUrl}/signature/sign/${signRequest.id}`;
        const body = {
            fullName: signRequest.fullName,
        };

        return this.http.post<ResponseInterface<void>>(url, body).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

}
