import { createAction, props } from '@ngrx/store';

import { TimeActionTypes } from '@app-client/store/activity/time-action-types';
import { AddTimeRequestInterface } from '@app-client/store/activity/types/time-modify/add-time-request.interface';
import { DeleteTimeRequestInterface } from '@app-client/store/activity/types/time-modify/delete-time-request.interface';
import { EditTimeRequestInterface } from '@app-client/store/activity/types/time-modify/edit-time-request.interface';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


export const addTimeAction = createAction(
    TimeActionTypes.ADD_TIME,
    props<{ requestData: AddTimeRequestInterface, successCallback?: () => void }>(),
);

export const addTimeSuccessAction = createAction(
    TimeActionTypes.ADD_TIME_SUCCESS,
    props<{ userId?: string, successCallback?: () => void }>(),
);

export const addTimeFailureAction = createAction(
    TimeActionTypes.ADD_TIME_FAILURE,
    props<{ userId?: string, backendErrors: BackendErrorsInterface }>(),
);

export const editTimeAction = createAction(
    TimeActionTypes.EDIT_TIME,
    props<{ requestData: EditTimeRequestInterface, successCallback?: () => void }>(),
);

export const editTimeSuccessAction = createAction(
    TimeActionTypes.EDIT_TIME_SUCCESS,
    props<{ successCallback?: () => void }>(),
);

export const editTimeFailureAction = createAction(
    TimeActionTypes.EDIT_TIME_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const deleteTimeAction = createAction(
    TimeActionTypes.DELETE_TIME,
    props<{ requestData: DeleteTimeRequestInterface, successCallback?: () => void }>(),
);

export const deleteTimeSuccessAction = createAction(
    TimeActionTypes.DELETE_TIME_SUCCESS,
    props<{ successCallback?: () => void }>(),
);

export const deleteTimeFailureAction = createAction(
    TimeActionTypes.DELETE_TIME_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
