import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import {
    invoicingInvoicesDeleteAction,
    invoicingInvoicesDeleteFailureAction,
    invoicingInvoicesDeleteSuccessAction,
    invoicingInvoicesExportToGoogleSheetAction,
    invoicingInvoicesExportToGoogleSheetFailureAction,
    invoicingInvoicesExportToGoogleSheetSuccessAction,
    invoicingInvoicesGenerateAction,
    invoicingInvoicesGenerateFailureAction,
    invoicingInvoicesGenerateSuccessAction,
    invoicingInvoicesGetDetailsAction,
    invoicingInvoicesGetDetailsFailureAction,
    invoicingInvoicesGetDetailsSuccessAction,
    invoicingInvoicesGetListAction,
    invoicingInvoicesGetListFailureAction,
    invoicingInvoicesGetListSuccessAction,
    invoicingInvoicesRecalculateAction,
    invoicingInvoicesRecalculateFailureAction,
    invoicingInvoicesRecalculateSuccessAction,
    invoicingInvoicesSaveAction,
    invoicingInvoicesSaveFailureAction,
    invoicingInvoicesSaveSuccessAction,
    invoicingInvoicesUpdateAction,
    invoicingInvoicesUpdateFailureAction,
    invoicingInvoicesUpdateSuccessAction,
} from '@app-client/store/invoicing/invoices/actions/invoicing-invoices.actions';
import { InvoicingInvoicesService } from '@app-client/store/invoicing/invoices/services/invoicing-invoices.service';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class InvoicingInvoicesEffects {
    constructor(
        private router: Router,
        private actions$: Actions,
        private toastr: ToastrService,
        private translate: TranslateService,
        private invoicingInvoicesService: InvoicingInvoicesService,
    ) {}

    getListEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingInvoicesGetListAction),
            switchMap(({ requestParams }) => {
                return this.invoicingInvoicesService.getList(requestParams).pipe(
                    map((response) => {
                        return invoicingInvoicesGetListSuccessAction({ response });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(invoicingInvoicesGetListFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });
    getListFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingInvoicesGetListFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('INVOICING.INVOICES.NOTIFICATIONS.GET_LIST.FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    generateEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingInvoicesGenerateAction),
            switchMap(({ settings }) => {
                return this.invoicingInvoicesService.generate(settings).pipe(
                    map((response) => {
                        return invoicingInvoicesGenerateSuccessAction({ response, settings });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(invoicingInvoicesGenerateFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    recalculateEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingInvoicesRecalculateAction),
            switchMap(({ settings }) => {
                return this.invoicingInvoicesService.recalculate(settings).pipe(
                    map((response) => {
                        return invoicingInvoicesRecalculateSuccessAction({ response });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(invoicingInvoicesRecalculateFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    calculateSuccessEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingInvoicesGenerateSuccessAction, invoicingInvoicesRecalculateSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('INVOICING.INVOICES.NOTIFICATIONS.CALCULATE.SUCCESS.MESSAGE'),
                    this.translate.instant('INVOICING.INVOICES.NOTIFICATIONS.CALCULATE.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    calculateFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingInvoicesGenerateFailureAction, invoicingInvoicesRecalculateFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('INVOICING.INVOICES.NOTIFICATIONS.CALCULATE.FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    saveEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingInvoicesSaveAction),
            switchMap(({ body }) => {
                return this.invoicingInvoicesService.save(body).pipe(
                    map((response) => {
                        return invoicingInvoicesSaveSuccessAction({ response });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(invoicingInvoicesSaveFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });
    saveSuccessEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingInvoicesSaveSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('INVOICING.INVOICES.NOTIFICATIONS.SAVE.SUCCESS.MESSAGE'),
                    this.translate.instant('INVOICING.INVOICES.NOTIFICATIONS.SAVE.SUCCESS.TITLE'),
                );
            }),
            tap(({ response }) => {
                this.router.navigate(['invoicing', 'invoices', response.id]);
            }),
        );
    }, { dispatch: false });
    saveFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingInvoicesSaveFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('INVOICING.INVOICES.NOTIFICATIONS.SAVE.FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    updateEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingInvoicesUpdateAction),
            switchMap(({ id, body }) => {
                return this.invoicingInvoicesService.update(id, body).pipe(
                    map((response) => {
                        return invoicingInvoicesUpdateSuccessAction({ response });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(invoicingInvoicesUpdateFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });
    updateSuccessEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingInvoicesUpdateSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('INVOICING.INVOICES.NOTIFICATIONS.UPDATE.SUCCESS.MESSAGE'),
                    this.translate.instant('INVOICING.INVOICES.NOTIFICATIONS.UPDATE.SUCCESS.TITLE'),
                );
            }),
            tap(({ response }) => {
                this.router.navigate(['invoicing', 'invoices', response.id]);
            }),
        );
    }, { dispatch: false });
    updateFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingInvoicesUpdateFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('INVOICING.INVOICES.NOTIFICATIONS.UPDATE.FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    deleteEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingInvoicesDeleteAction),
            switchMap(({ id }) => {
                return this.invoicingInvoicesService.delete(id).pipe(
                    map(() => {
                        return invoicingInvoicesDeleteSuccessAction();
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(invoicingInvoicesDeleteFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });
    deleteSuccessEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingInvoicesDeleteSuccessAction),
            tap(() => {
                this.router.navigate(['invoicing', `invoices`]);
                this.toastr.success(
                    this.translate.instant('INVOICING.INVOICES.NOTIFICATIONS.DELETE.SUCCESS.MESSAGE'),
                    this.translate.instant('INVOICING.INVOICES.NOTIFICATIONS.DELETE.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });
    deleteFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingInvoicesDeleteFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('INVOICING.INVOICES.NOTIFICATIONS.DELETE.FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    getDetailsEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingInvoicesGetDetailsAction),
            switchMap(({ id }) => {
                return this.invoicingInvoicesService.getDetails(id).pipe(
                    map((response) => {
                        return invoicingInvoicesGetDetailsSuccessAction({ response });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(invoicingInvoicesGetDetailsFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    exportToGoogleSheet$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingInvoicesExportToGoogleSheetAction),
            switchMap(({id}) => {
                return this.invoicingInvoicesService.exportToGoogleSpreadsheet(id).pipe(
                    map((response) => {
                        return invoicingInvoicesExportToGoogleSheetSuccessAction();
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(invoicingInvoicesExportToGoogleSheetFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });
    exportToGoogleSuccessEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingInvoicesExportToGoogleSheetSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('INVOICING.INVOICES.NOTIFICATIONS.EXPORT_TO_GOOGLE.SUCCESS.MESSAGE'),
                    this.translate.instant('INVOICING.INVOICES.NOTIFICATIONS.EXPORT_TO_GOOGLE.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });
    exportToGoogleFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingInvoicesExportToGoogleSheetFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('INVOICING.INVOICES.NOTIFICATIONS.EXPORT_TO_GOOGLE.FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });
}
