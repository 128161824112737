import { createReducer, on } from '@ngrx/store';

import {
    reportUsingApplicationsClearData,
    reportUsingApplicationsExportGoogleSpreadsheetAction, reportUsingApplicationsExportGoogleSpreadsheetFailureAction,
    reportUsingApplicationsExportGoogleSpreadsheetSuccessAction,
    reportUsingApplicationsGetReportAction,
    reportUsingApplicationsGetReportFailureAction,
    reportUsingApplicationsGetReportSuccessAction,
} from '@app-client/store/report/using-applications/actions/report-using-applications.actions';
import { ReportUsingApplicationsStateInterface } from '@app-client/store/report/using-applications/report-using-applications-state.interface';


const initialState: ReportUsingApplicationsStateInterface = {
    isGetReportInProgress: false,
    report: null,
    savedFilters: null,
    isExportGoogleSpreadsheetsInProgress: false,
};

const reducer = createReducer(
    initialState,
    on(
        reportUsingApplicationsGetReportAction,
        (state) => ({
            ...state,
            isGetReportInProgress: true,
        }),
    ),
    on(
        reportUsingApplicationsGetReportSuccessAction,
        (state, action) => ({
            ...state,
            isGetReportInProgress: false,
            report: action.report,
            savedFilters: action.filters,
        }),
    ),
    on(
        reportUsingApplicationsGetReportFailureAction,
        (state) => ({
            ...state,
            isGetReportInProgress: false,
        }),
    ),
    on(
        reportUsingApplicationsClearData,
        (state) => ({
            ...state,
            report: null,
            savedFilters: null,
        }),
    ),
    on(
        reportUsingApplicationsExportGoogleSpreadsheetAction,
        (state) => ({
            ...state,
            isExportGoogleSpreadsheetsInProgress: true,
        }),
    ),
    on(
        reportUsingApplicationsExportGoogleSpreadsheetSuccessAction,
        reportUsingApplicationsExportGoogleSpreadsheetFailureAction,
        (state) => ({
            ...state,
            isExportGoogleSpreadsheetsInProgress: false,
        })
    )
);

export const reportUsingApplicationsReducer = (state, action) => reducer(state, action);
