import { createAction, props } from '@ngrx/store';

import { SignatureBioActionsEnum } from '@app-client/store/signature/bio/signature-bio-actions.enum';


export const signatureBioSetFirstNameAction = createAction(
    SignatureBioActionsEnum.SET_FIRST_NAME,
    props<{ value: string | null }>(),
);

export const signatureBioSetLastNameAction = createAction(
    SignatureBioActionsEnum.SET_LAST_NAME,
    props<{ value: string | null }>(),
);

export const signatureBioSetMiddleNameAction = createAction(
    SignatureBioActionsEnum.SET_MIDDLE_NAME,
    props<{ value: string | null }>(),
);

export const signatureBioSetBioAction = createAction(
    SignatureBioActionsEnum.SET_BIO,
    props<{ firstName: string | null, lastName: string | null, middleName: string | null }>(),
);
