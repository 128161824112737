import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CreateProjectPositionInterface } from '@app-client/project/types/create-project-position.interface';
import { ProjectPositionListQueryParamsInterface } from '@app-client/project/types/project-position-list-query-params.interface';
import { ProjectPositionInterface } from '@app-client/project/types/project-position.interface';
import { UpdateProjectPositionInterface } from '@app-client/project/types/update-project-position.interface';
import { PageResponseInterface } from '@app-shared/types/page-response.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';

import { environment } from '@env-client/environment';


@Injectable()
export class ProjectPositionsService {
    constructor(private http: HttpClient) {}

    public getProjectPositionsList(queryParams: ProjectPositionListQueryParamsInterface)
        : Observable<PageResponseInterface<ProjectPositionInterface>>
    {
        let params = new HttpParams();
        if ((queryParams.search || '').trim()) {
            params = params.set('search', (queryParams.search || '').trim());
        }
        params = params.set('page', queryParams.page.toString());
        params = params.set('limit', (queryParams.limit || 10).toString());
        params = params.set('orderBy', queryParams.orderBy);
        params = params.set('orderDirection', queryParams.orderDirection);
        if (queryParams.globalPositions !== undefined) {
            params = params.set('globalPositions', queryParams.globalPositions.toString());
        }
        if (queryParams.projectId !== undefined) {
            params = params.set('projectId', queryParams.projectId);
        }
        return this.http.get<ResponseInterface<PageResponseInterface<ProjectPositionInterface>>>(
        environment.apiBaseUrl + '/project-positions', { params }
        ).pipe(
            map(x => {
                return x.body;
            }),
        );
    }

    public createProjectPosition(createProjectPosition: CreateProjectPositionInterface)
        : Observable<ResponseInterface<ProjectPositionInterface>>
    {
        return this.http.post<ResponseInterface<ProjectPositionInterface>>(
            environment.apiBaseUrl + '/project-positions', createProjectPosition
        );
    }

    public updateProjectPosition(id: string, updateProjectPosition: UpdateProjectPositionInterface)
        : Observable<ResponseInterface<ProjectPositionInterface>>
    {
        return this.http.patch<ResponseInterface<ProjectPositionInterface>>(
            environment.apiBaseUrl + '/project-positions/' + id, updateProjectPosition
        );
    }

    public removeProjectPosition(id: string)
        : Observable<ResponseInterface<null>> {
        return this.http.delete<ResponseInterface<null>>(
            environment.apiBaseUrl + '/project-positions/' + id,
        );
    }
}
