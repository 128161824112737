import { NgModule } from '@angular/core';

import { ClickOutsideDirective } from '@app-client/shared/modules/directive/click-outside.directive';


@NgModule({
    declarations: [
        ClickOutsideDirective,
    ],
    imports: [],
    exports: [
        ClickOutsideDirective,
    ]
})
export class DirectiveModule {}
