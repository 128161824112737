import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'momentToSeconds',
    pure: true,
})
export class MomentToSecondsPipe implements PipeTransform {
    transform(value: moment.Moment): number {
        return value.hours() * 3600 + value.minutes() * 60 + value.seconds();
    }
}
