import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import {
    createUserFromUserRequestAction,
    createUserFromUserRequestFailureAction,
    createUserFromUserRequestSuccessAction,
    deleteUserRequestAction,
    deleteUserRequestFailureAction,
    deleteUserRequestSuccessAction,
    getUserRequestListAction,
    getUserRequestListFailureAction,
    getUserRequestListSuccessAction,
    linkUserRequestAction,
    linkUserRequestFailureAction,
    linkUserRequestSuccessAction,
    setUserRequestListQueryParamsAction,
} from '@app-client/store/users/actions/user-request.action';
import { userRequestQueryConfigSelector } from '@app-client/store/users/selectors/user-request.selector';
import { UserRequestService } from '@app-client/store/users/services/user-request.service';
import { UsersService } from '@app-client/store/users/services/users.service';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class UserRequestEffect {

    getUserRequestList$ = createEffect( () => {
        return this.actions$.pipe(
            ofType(getUserRequestListAction),
            withLatestFrom(this.store$.select(userRequestQueryConfigSelector)),
            switchMap(([, queryParams]) => {
                return this.userRequestService.getUserRequestList(queryParams).pipe(
                    map(response => {
                        return getUserRequestListSuccessAction({ response });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(getUserRequestListFailureAction({ backendErrors }));
                    }),
                );
            })
        );
    });

    deleteUserRequest$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteUserRequestAction),
            switchMap(({ id }) => {
                return this.userRequestService.deleteUserRequest(id).pipe(
                    map(() => {
                        return deleteUserRequestSuccessAction();
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(deleteUserRequestFailureAction({ backendErrors }));
                    }),
                );
            })
        );
    });

    deleteUserRequestSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteUserRequestSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('USERS.USER_REQUEST.NOTIFICATIONS.DELETE_USER_REQUEST.SUCCESS.MESSAGE'),
                    this.translate.instant('USERS.USER_REQUEST.NOTIFICATIONS.DELETE_USER_REQUEST.SUCCESS.TITLE'),
                );
            }),
            switchMap( () => {
                this.dialogRef.closeAll();
                return this.store$.select(userRequestQueryConfigSelector).pipe(
                    take(1),
                    switchMap(config => {
                        return [
                            setUserRequestListQueryParamsAction({
                                queryParams: {
                                    ...config,
                                    page: 1,
                                },
                            }),
                            getUserRequestListAction(),
                        ];
                    }),
                );
            })
        );
    }, );

    deleteUserRequestFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteUserRequestFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('USERS.USER_REQUEST.NOTIFICATIONS.DELETE_USER_REQUEST.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    linkUserRequest$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(linkUserRequestAction),
            switchMap(({ id, userId }) => {
                return this.userRequestService.linkUserRequest(id, userId).pipe(
                    map(() => {
                        return linkUserRequestSuccessAction();
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(linkUserRequestFailureAction({ backendErrors }));
                    }),
                );
            })
        );
    });

    linkUserSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(linkUserRequestSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('USERS.USER_REQUEST.NOTIFICATIONS.LINK_USER_REQUEST.SUCCESS.MESSAGE'),
                    this.translate.instant('USERS.USER_REQUEST.NOTIFICATIONS.LINK_USER_REQUEST.SUCCESS.TITLE'),
                );
            }),
            switchMap( () => {
                this.dialogRef.closeAll();
                return this.store$.select(userRequestQueryConfigSelector).pipe(
                    take(1),
                    switchMap(config => {
                        return [
                            setUserRequestListQueryParamsAction({
                                queryParams: {
                                    ...config,
                                    page: 1,
                                },
                            }),
                            getUserRequestListAction(),
                        ];
                    }),
                );
            })
        );
    });

    linkUserFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(linkUserRequestFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('USERS.USER_REQUEST.NOTIFICATIONS.LINK_USER_REQUEST.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    createUserFromUserRequest$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createUserFromUserRequestAction),
            switchMap(({ userData }) => {
                return this.userService.createUser(userData).pipe(
                    map((newUser) => {
                        return createUserFromUserRequestSuccessAction({ newUser });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(createUserFromUserRequestFailureAction({ backendErrors }));
                    }),
                );
            })
        );
    });

    createUserFromUserRequestSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createUserFromUserRequestSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('USERS.NOTIFICATIONS.CREATE_USER.SUCCESS.MESSAGE'),
                    this.translate.instant('USERS.NOTIFICATIONS.CREATE_USER.SUCCESS.TITLE'),
                );
            }),
            switchMap( () => {
                this.dialogRef.closeAll();
                return this.store$.select(userRequestQueryConfigSelector).pipe(
                    take(1),
                    switchMap(config => {
                        return [
                            setUserRequestListQueryParamsAction({
                                queryParams: {
                                    ...config,
                                    page: 1,
                                },
                            }),
                            getUserRequestListAction(),
                        ];
                    }),
                );
            })
        );
    });

    createUserFromUserRequestFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createUserFromUserRequestFailureAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('USERS.NOTIFICATIONS.CREATE_USER.SUCCESS.MESSAGE'),
                    this.translate.instant('USERS.NOTIFICATIONS.CREATE_USER.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    constructor(
        private actions$: Actions,
        private userRequestService: UserRequestService,
        private userService: UsersService,
        private store$: Store,
        private toastr: ToastrService,
        private translate: TranslateService,
        private dialogRef: MatDialog,
    ) {}
}
