import { createReducer, on } from '@ngrx/store';

import {
    batchUpdateAppSubcategorySuccessAction,
    createEffectivityCategoryAction,
    createEffectivityCategoryFailureAction,
    createEffectivityCategorySuccessAction,
    createEffectivitySubcategoryAction,
    createEffectivitySubcategoryFailureAction,
    createEffectivitySubcategorySuccessAction,
    getEffectivityAppListAction,
    getEffectivityAppListFailureAction,
    getEffectivityAppListSuccessAction,
    getEffectivityCategoryListAction,
    getEffectivityCategoryListFailureAction,
    getEffectivityCategoryListSuccessAction,
    removeEffectivityCategorySuccessAction,
    removeEffectivitySubcategorySuccessAction,
    setEffectivityAppQueryParamsAction,
    updateEffectivityCategoryAction,
    updateEffectivityCategoryFailureAction,
    updateEffectivityCategorySuccessAction,
    updateEffectivitySubcategoryAction,
    updateEffectivitySubcategoryFailureAction,
    updateEffectivitySubcategorySuccessAction,
} from '@app-client/store/effectivity-categories/actions/effectivity-category.actions';
import { EffectivityCategoryStateInterface } from '@app-client/store/effectivity-categories/effectivity-category-state.interface';

const initialState: EffectivityCategoryStateInterface = {
    effectivityAppList: [],
    effectivityAppListQueryParams: undefined,
    isEffectivityAppListLoading: false,
    createEffectivitySubcategoryBackendErrors: undefined,
    isCreateEffectivitySubcategoryInProgress: false,
    isUpdateEffectivitySubcategoryInProgress: false,
    updateEffectivitySubcategoryBackendErrors: undefined,
    createEffectivityCategoryBackendErrors: undefined,
    isCreateEffectivityCategoryInProgress: false,
    effectivityCategoryList: [],
    isEffectivityCategoryListLoading: false,
    isUpdateEffectivityCategoryInProgress: false,
    updateEffectivityCategoryBackendErrors: undefined
};

const reducer = createReducer(
    initialState,
    on(
        getEffectivityCategoryListAction,
        (state) => ({
            ...state,
            effectivityCategoryList: [],
            isEffectivityCategoryListLoading: true,
        }),
    ),
    on(
        getEffectivityCategoryListSuccessAction,
        (state, action) => ({
            ...state,
            effectivityCategoryList: action.response.body,
            isEffectivityCategoryListLoading: false,
        }),
    ),
    on(
        getEffectivityCategoryListFailureAction,
        (state) => ({
            ...state,
            isEffectivityCategoryListLoading: false,
        }),
    ),
    on(
        createEffectivityCategoryAction,
        (state) => ({
            ...state,
            isCreateEffectivityCategoryInProgress: true,
            createEffectivityCategoryBackendErrors: null,
        }),
    ),

    on(
       createEffectivityCategorySuccessAction,
        (state, action) => ({
           ...state,
           isCreateEffectivityCategoryInProgress: false,
            effectivityCategoryList: [...state.effectivityCategoryList, action.newEffectivityCategory],
        }),
    ),

    on(
        createEffectivityCategoryFailureAction,
        (state, action) => ({
            ...state,
            isCreateEffectivityCategoryInProgress: false,
            createEffectivityCategoryBackendErrors: action.backendErrors,
        }),
    ),

    on(
        updateEffectivityCategoryAction,
        (state) => ({
            ...state,
            isUpdateEffectivityCategoryInProgress: true,
            updateEffectivityCategoryBackendErrors: null,
        }),
    ),

    on(
        updateEffectivityCategorySuccessAction,
        (state, action) => {
            const categoryIndex = state.effectivityCategoryList.findIndex(x => x.id === action.updatedEffectivityCategory.id);
            const newList = [...state.effectivityCategoryList];
            newList[categoryIndex] = action.updatedEffectivityCategory;
            return {
                ...state,
                isUpdateEffectivityCategoryInProgress: false,
                effectivityCategoryList: newList,
            };
        },
    ),

    on(
        updateEffectivityCategoryFailureAction,
        (state, action) => ({
            ...state,
            isUpdateEffectivityCategoryInProgress: false,
            updateEffectivityCategoryBackendErrors: action.backendErrors,
        }),
    ),

    on(
        removeEffectivityCategorySuccessAction,
        (state, action) => {
            const categoryIndex = state.effectivityCategoryList.findIndex(x => x.id === action.removedEffectivityCategoryId);
            const removedSubcategories = state.effectivityCategoryList[categoryIndex].subcategories.map(x => x.id);
            const newAppList = state.effectivityAppList.map(app => {
                if (removedSubcategories.some(x => x === app.subcategory?.id)) {
                    return {
                        ...app,
                        subcategory: null,
                    };
                } else {
                    return app;
                }
            });
            const newList = [...state.effectivityCategoryList];
            newList.splice(categoryIndex, 1);
            return {
                ...state,
                effectivityCategoryList: newList,
                effectivityAppList: newAppList,
            };
        },
    ),

    on(
        createEffectivitySubcategoryAction,
        (state) => ({
            ...state,
            isCreateEffectivitySubcategoryInProgress: true,
        }),
    ),

    on(
        createEffectivitySubcategorySuccessAction,
        (state, action) => {
            const newList = state.effectivityCategoryList.map(category => {
                if (category.id === action.categoryId) {
                    return {
                        ...category,
                        subcategories: [...category.subcategories, action.createdSubcategory],
                    };
                } else {
                    return category;
                }
            });
            return {
                ...state,
                effectivityCategoryList: newList,
                isCreateEffectivitySubcategoryInProgress: false,
            };
        },
    ),

    on(
        createEffectivitySubcategoryFailureAction,
        (state) => ({
            ...state,
            isCreateEffectivitySubcategoryInProgress: false,
        }),
    ),

    on(
        updateEffectivitySubcategoryAction,
        (state) => ({
            ...state,
            isUpdateEffectivitySubcategoryInProgress: true,
        }),
    ),

    on(
        updateEffectivitySubcategorySuccessAction,
        (state, action) => {
            const newList = state.effectivityCategoryList.map(category => {
                return {
                    ...category,
                    subcategories: category.subcategories.filter(x => x.id !== action.updatedSubcategory.id),
                };
            }).map(category => {
                if (category.id === action.newCategoryId) {
                    return {
                        ...category,
                        subcategories: [...category.subcategories, action.updatedSubcategory],
                    };
                } else {
                    return category;
                }
            });
            return {
                ...state,
                isUpdateEffectivitySubcategoryInProgress: false,
                effectivityCategoryList: newList,
            };
        },
    ),

    on(
        updateEffectivitySubcategoryFailureAction,
        (state) => ({
            ...state,
            isUpdateEffectivitySubcategoryInProgress: false,
        }),
    ),

    on(
        removeEffectivitySubcategorySuccessAction,
        (state, action) => {
            const newList = state.effectivityCategoryList.map(category => {
                return {
                    ...category,
                    subcategories: category.subcategories.filter(subcategory => subcategory.id !== action.removedSubcategoryId),
                };
            });
            const newAppList = state.effectivityAppList.map(app => {
                if (app.subcategory?.id === action.removedSubcategoryId) {
                    return {
                        ...app,
                        subcategory: null,
                    };
                } else {
                    return app;
                }
            });
            return {
                ...state,
                effectivityCategoryList: newList,
                effectivityAppList: newAppList,
            };
        },
    ),

    on(
        setEffectivityAppQueryParamsAction,
        (state, action) => ({
            ...state,
            effectivityAppListQueryParams: action.params,
        }),
    ),

    on(
        getEffectivityAppListAction,
        (state) => ({
            ...state,
            isEffectivityAppListLoading: true,
            effectivityAppList: [],
        }),
    ),

    on(
        getEffectivityAppListSuccessAction,
        (state, action) => ({
            ...state,
            isEffectivityAppListLoading: false,
            effectivityAppList: action.response.body,
        }),
    ),

    on(
        getEffectivityAppListFailureAction,
        (state) => ({
            ...state,
            isEffectivityAppListLoading: false,
        }),
    ),

    on(
        batchUpdateAppSubcategorySuccessAction,
        (state, action) => {
            const newList = state.effectivityAppList.map(app => {
                if (!!action.apps.find(x => x === app.id)) {
                    const subcategory = action.subcategoryId ? { id: action.subcategoryId } : null;

                    return {
                        ...app,
                        subcategory,
                    };
                } else {
                    return app;
                }
            });

            return {
                ...state,
                effectivityAppList: newList,
            };
        },
    ),
);

export const effectivityCategoryReducer = (state, action) => reducer(state, action);
