import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, empty, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import {
    dropDailyStatisticsAction,
    getDailyStatisticsAction,
    getDailyStatisticsFailureAction,
    getDailyStatisticsSuccessAction,
    getMonthlyStatisticsAction,
    getMonthlyStatisticsFailureAction,
    getMonthlyStatisticsSuccessAction,
    getProjectTaskMemoDataAction,
    getProjectTaskMemoDataFailureAction,
    getProjectTaskMemoDataSuccessAction,
    getScreenshotDataAction,
    getScreenshotDataFailureAction,
    getScreenshotDataSuccessAction,
    getTimelineDataAction,
    getTimelineDataFailureAction,
    getTimelineDataSuccessAction,
    getWeeklyStatisticsAction,
    getWeeklyStatisticsFailureAction,
    getWeeklyStatisticsSuccessAction,
    getWindowDataAction,
    getWindowDataFailureAction,
    getWindowDataSuccessAction,
} from '@app-client/store/activity/actions/activity-data.action';
import { displayFiltersSelector } from '@app-client/store/activity/selectors/activity.selector';
import { ActivityService } from '@app-client/store/activity/services/activity.service';
import { MonthlyStatisticsInterface } from '@app-client/store/activity/types/statistics/monthly-statistics.interface';
import {
    currentCompanySelector,
    currentUserIsAdminSelector,
    currentUserIsManagerSelector,
    currentUserIsObserverSelector,
} from '@app-client/store/auth/selectors/auth.selectors';
import {
  marketplaceIsEnabledEffectivityFeatureSelector,
  marketplaceIsEnabledScreenshotsFeatureSelector,
} from '@app-client/store/marketplace/modules/selectors/marketplace-modules.selectors';
import { sharedDataRequestParamsSelector } from '@app-client/store/shared-data-filters/selectors/shared-data-filters.selector';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class ActivityEffect {
    getDailyStatistics$ = createEffect(() => this.actions$.pipe(
        ofType(getDailyStatisticsAction),
        withLatestFrom(this.store$.select(sharedDataRequestParamsSelector)),
        switchMap(([, params]) => {
            return this.activityService.getStatisticsByDay(params).pipe(
                map((statistics) => {
                    return getDailyStatisticsSuccessAction({ statistics, day: params.from.clone() });
                }),
                catchError((httpErrorResponse: HttpErrorResponse) => {
                    const backendErrors: BackendErrorsInterface = {
                        message: httpErrorResponse?.error?.message,
                        errors: httpErrorResponse?.error?.errors,
                    };

                    return of(getDailyStatisticsFailureAction({ backendErrors }));
                }),
            );
        }),
    ));

    getDailyStatisticsFailure$ = createEffect(() => this.actions$.pipe(
        ofType(getDailyStatisticsFailureAction),
        tap(({ backendErrors }) => {
            this.toastr.error(
                backendErrors.message,
                this.translate.instant('ACTIVITY.TIMELINE.DATA_RETRIEVING.STATISTICS.ERROR'),
            );
        }),
    ), { dispatch: false });

    getWeeklyStatistics$ = createEffect(() => this.actions$.pipe(
        ofType(getWeeklyStatisticsAction),
        withLatestFrom(this.store$.select(sharedDataRequestParamsSelector)),
        switchMap(([, params]) => {
            return this.activityService.getStatisticsByWeek(params).pipe(
                map((statistics) => {
                    return getWeeklyStatisticsSuccessAction({ statistics });
                }),
                catchError((httpErrorResponse: HttpErrorResponse) => {
                    const backendErrors: BackendErrorsInterface = {
                        message: httpErrorResponse?.error?.message,
                        errors: httpErrorResponse?.error?.errors,
                    };

                    return of(getWeeklyStatisticsFailureAction({ backendErrors }));
                }),
            );
        }),
    ));

    getWeeklyStatisticsFailure$ = createEffect(() => this.actions$.pipe(
        ofType(getWeeklyStatisticsFailureAction),
        tap(({ backendErrors }) => {
            this.toastr.error(
                backendErrors.message,
                this.translate.instant('ACTIVITY.TIMELINE.DATA_RETRIEVING.STATISTICS.ERROR'),
            );
        }),
    ), { dispatch: false });

    getMonthlyStatistics$ = createEffect(() => this.actions$.pipe(
        ofType(getMonthlyStatisticsAction),
        withLatestFrom(this.store$.select(sharedDataRequestParamsSelector)),
        switchMap(([, params]) => {
            const requests = new Array<Observable<MonthlyStatisticsInterface>>();
            requests.push(this.activityService.getStatisticsByMonth(params));
            if (params.from.clone().startOf('month').startOf('week').month() !== params.from.month()) {
                const prevMonthParams = {
                    ...params,
                    from: params.from.clone().startOf('month').startOf('week'),
                };
                requests.push(this.activityService.getStatisticsByMonth(prevMonthParams));
            }
            if (params.from.clone().endOf('month').endOf('week').month() !== params.from.month()) {
                const  nextMonthParams = {
                    ...params,
                    from: params.from.clone().endOf('month').endOf('week'),
                };
                requests.push(this.activityService.getStatisticsByMonth(nextMonthParams));
            }

            return combineLatest(requests).pipe(
                map((statistics) => {
                    return getMonthlyStatisticsSuccessAction({ statistics });
                }),
                catchError((httpErrorResponse: HttpErrorResponse) => {
                    const backendErrors: BackendErrorsInterface = {
                        message: httpErrorResponse?.error?.message,
                        errors: httpErrorResponse?.error?.errors,
                    };

                    return of(getMonthlyStatisticsFailureAction({ backendErrors }));
                }),
            );
        }),
    ));

    getMonthlyStatisticsFailure$ = createEffect(() => this.actions$.pipe(

        ofType(getMonthlyStatisticsFailureAction),
        tap(({ backendErrors }) => {
            this.toastr.error(
                backendErrors.message,
                this.translate.instant('ACTIVITY.TIMELINE.DATA_RETRIEVING.STATISTICS.ERROR'),
            );
        }),
    ), { dispatch: false });

    getProjectTaskMemoData$ = createEffect(() => this.actions$.pipe(
        ofType(getProjectTaskMemoDataAction),
        withLatestFrom(this.store$.select(sharedDataRequestParamsSelector)),
        switchMap(([, params]) => {
            return this.activityService.getProjectTaskMemoData(params).pipe(
                map((projectTaskMemoData) => {
                    return getProjectTaskMemoDataSuccessAction({ projectTaskMemoData });
                }),
                catchError((httpErrorResponse: HttpErrorResponse) => {
                    const backendErrors: BackendErrorsInterface = {
                        message: httpErrorResponse?.error?.message,
                        errors: httpErrorResponse?.error?.errors,
                    };

                    return of(getProjectTaskMemoDataFailureAction({ backendErrors }));
                }),
            );
        }),
    ));

    getProjectTaskMemoDataFailure$ = createEffect(() => this.actions$.pipe(
        ofType(getProjectTaskMemoDataFailureAction),
        tap(({ backendErrors }) => {
            this.toastr.error(
                backendErrors.message,
                this.translate.instant('ACTIVITY.TIMELINE.DATA_RETRIEVING.PROJECT_TASK_MEMO.ERROR'),
            );
        }),
    ), { dispatch: false });

    getWindowData$ = createEffect(() => this.actions$.pipe(
        ofType(getWindowDataAction),
        withLatestFrom(
          this.store$.select(sharedDataRequestParamsSelector),
          this.store$.select(marketplaceIsEnabledEffectivityFeatureSelector)
        ),
        switchMap(([, params, isEffectivityEnabled]) => {
            if (!isEffectivityEnabled) {
                return empty;
            }
            return this.activityService.getWindowData(params).pipe(
                map((windowData) => {
                    return getWindowDataSuccessAction({ windowData });
                }),
                catchError((httpErrorResponse: HttpErrorResponse) => {
                    const backendErrors: BackendErrorsInterface = {
                        message: httpErrorResponse?.error?.message,
                        errors: httpErrorResponse?.error?.errors,
                    };

                    return of(getWindowDataFailureAction({ backendErrors }));
                }),
            );
        }),
    ));

    getWindowDataFailure$ = createEffect(() => this.actions$.pipe(
        ofType(getWindowDataFailureAction),
        tap(({ backendErrors }) => {
            this.toastr.error(
                backendErrors.message,
                this.translate.instant('ACTIVITY.TIMELINE.DATA_RETRIEVING.WINDOWS.ERROR'),
            );
        }),
    ), { dispatch: false });

    getScreenshotData$ = createEffect(() => this.actions$.pipe(
        ofType(getScreenshotDataAction),
        withLatestFrom(
          this.store$.select(sharedDataRequestParamsSelector),
          this.store$.select(marketplaceIsEnabledScreenshotsFeatureSelector),
        ),
        switchMap(([, params, isScreenshotsEnabled]) => {
            if (!isScreenshotsEnabled) {
                return empty;
            }
            return this.activityService.getScreenshotData(params).pipe(
                map((screenshotData) => {
                    return getScreenshotDataSuccessAction({ screenshotData });
                }),
                catchError((httpErrorResponse: HttpErrorResponse) => {
                    const backendErrors: BackendErrorsInterface = {
                        message: httpErrorResponse?.error?.message,
                        errors: httpErrorResponse?.error?.errors,
                    };

                    return of(getScreenshotDataFailureAction({ backendErrors }));
                }),
            );
        }),
    ));

    getScreenshotDataFailure$ = createEffect(() => this.actions$.pipe(
        ofType(getScreenshotDataFailureAction),
        tap(({ backendErrors }) => {
            this.toastr.error(
                backendErrors.message,
                this.translate.instant('ACTIVITY.TIMELINE.DATA_RETRIEVING.SCREENSHOTS.ERROR'),
            );
        }),
    ), { dispatch: false });

    getTimelineData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getTimelineDataAction),
            withLatestFrom(
                this.store$.select(sharedDataRequestParamsSelector),
                this.store$.select(displayFiltersSelector),
                this.store$.select(currentCompanySelector),
                this.store$.select(currentUserIsAdminSelector),
                this.store$.select(currentUserIsManagerSelector),
                this.store$.select(currentUserIsObserverSelector),
                this.store$.select(marketplaceIsEnabledScreenshotsFeatureSelector),
                this.store$.select(marketplaceIsEnabledEffectivityFeatureSelector)
            ),
            switchMap(([
              ,
              params,
              filters,
              currentCompany,
              isAdmin,
              isManager,
              isObserver,
              isScreenshotsFeatureEnabled,
              isEffectivityFeatureEnabled,
            ]) => {
                const isShowWindows = (!currentCompany.useTimeslots || !isObserver || isAdmin || isManager) && isEffectivityFeatureEnabled;
                const isShowScreenshots = filters.screenshots && isScreenshotsFeatureEnabled;
                return forkJoin(
                    this.activityService.getStatisticsByDay(params),
                    this.activityService.getProjectTaskMemoData(params),
                    isShowWindows ? this.activityService.getWindowData(params) : of([]),
                    isShowScreenshots ? this.activityService.getScreenshotData(params) : of([]),
                ).pipe(
                    map(([dailyStatisticData, projectTaskMemoData, windowData, screenshotData]) => {
                        return getTimelineDataSuccessAction({ dailyStatisticData, projectTaskMemoData, windowData, screenshotData });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(getTimelineDataFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getTimelineDataFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getTimelineDataFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('ACTIVITY.TIMELINE.DATA_RETRIEVING.TIMELINE.ERROR'),
                );
            }),
        );
    }, { dispatch: false });

    dropDailyStatistics$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(dropDailyStatisticsAction),
            map(() => getMonthlyStatisticsAction()),
        );
    });

    constructor(
        private actions$: Actions,
        private store$: Store,
        private activityService: ActivityService,
        private toastr: ToastrService,
        private translate: TranslateService,
    ) {}
}
