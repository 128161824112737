import { HttpParams } from '@angular/common/http';

import { environment } from '@env-client/environment';


export class UrlHelper {
    public static getOauth2Link(provider: string, connectType: string): string {
        return `${environment.apiBaseUrl}/oauth2/connect?provider=${provider}&connectType=${connectType}`;
    }

    public static getQueryParam(paramName: string, url?: string): string {
        if (!url) {
            url = window.location.href;
        }

        let paramValue = null;
        if (url.includes('?')) {
            const httpParams = new HttpParams({ fromString: url.split('?')[1] });
            paramValue = httpParams.get(paramName);
        }

        return paramValue;
    }

    public static googleSpreadsheetUrl(spreadsheetId: string): string {
        return `https://docs.google.com/spreadsheets/d/${spreadsheetId}/edit`;
    }
}
