import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TimeRequestQueryParamsInterface } from '@app-client/time-request/types/time-request-query-params.interface';
import { TimeRequestInterface } from '@app-client/time-request/types/time-request.interface';
import { PageResponseInterface } from '@app-shared/types/page-response.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';
import { mapToVoid } from '@app-shared/utils/rxjs/map-to-void';

import { environment } from '@env-client/environment';


@Injectable()
export class TimeRequestService {
    constructor(private httpClient: HttpClient) {}

    timeRequestList(queryParams: TimeRequestQueryParamsInterface): Observable<PageResponseInterface<TimeRequestInterface>> {
        const url = environment.apiBaseUrl + '/trk/time-requests';

        let httpParams = new HttpParams()
            .set('orderBy', queryParams.orderBy)
            .set('orderDirection', queryParams.orderDirection)
            .set('page', queryParams.page.toString())
            .set('limit', (queryParams.limit || 10).toString());

        if (queryParams.project) {
            httpParams = httpParams.set('projectId', queryParams.project);
        }
        if (queryParams.user) {
            httpParams = httpParams.set('searchByUser', queryParams.user);
        }
        if (queryParams.status) {
            httpParams = httpParams.set('status', String(queryParams.status));
        }
        if (queryParams.from) {
            httpParams = httpParams.set('from', String(queryParams.from));
        }
        if (queryParams.to) {
            httpParams = httpParams.set('to', String(queryParams.to));
        }
        if (queryParams.onlyCanApprove) {
            httpParams = httpParams.set('onlyCanApprove', queryParams.onlyCanApprove ? '1' : '0');
        }

        return this.httpClient.get(url, { params: httpParams }).pipe(
            map((response: ResponseInterface<PageResponseInterface<TimeRequestInterface>>) => {
                return {
                    ...response.body,
                    items: response.body.items.map(item => {
                        return {
                            ...item,
                            ranges: item.ranges.map(r => ({
                                begin: moment(Number(r.begin) * 1000),
                                end: moment(Number(r.end) * 1000),
                            })),
                        };
                    }),
                };
            }),
        );
    }

    approveTimeRequest(id: number): Observable<void> {
        const url = environment.apiBaseUrl + '/trk/time-requests/' + id + '/approve';

        return this.httpClient.post(url, {}).pipe(mapToVoid);
    }

    rejectTimeRequest(id: number, comment: string): Observable<void> {
        const url = environment.apiBaseUrl + '/trk/time-requests/' + id + '/reject';

        return this.httpClient.post(url, { comment }).pipe(mapToVoid);
    }
}
