import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UserRequestStateInterface } from '@app-client/store/users/user-request-state.interface';


export const userRequestFeatureSelector = createFeatureSelector<UserRequestStateInterface>('userRequest');

export const userRequestListSelector = createSelector(
    userRequestFeatureSelector,
    state => state.userRequestList,
);

export const userRequestQueryConfigSelector = createSelector(
    userRequestFeatureSelector,
    state => state.userRequestQueryConfig,
);

export const isUserRequestListLoadingSelector = createSelector(
    userRequestFeatureSelector,
    state => state.isUserRequestListLoading,
);

export const userRequestListHasLoadingMoreSelector = createSelector(
    userRequestFeatureSelector,
    state => state.userRequestListHasLoadingMore
);

export const isLinkUserRequestInProgressSelector = createSelector(
    userRequestFeatureSelector,
    state => state.isLinkUserRequestInProgress
);

export const isCreateUserFormUserRequestInProgressSelector = createSelector(
    userRequestFeatureSelector,
    state => state.isCreateUserFromUserRequestInProgress
);
