<div class="row">
    <div class="col s12 center">
        <h1>{{ 'SELECTOR.PLAN.TITLE' | translate }}</h1>
    </div>
</div>

<div class="row">
    <ng-container *ngIf="(isDataLoading$ | async) === false">
        <div class="col s12">
            <div class="plans-list">
                <ng-container *ngFor="let plan of plans$ | async">
                    <div class="plan">
                        <div class="plan__title">
                            {{ plan.name }}
                        </div>
                        <div class="plan__price">
                            <ng-container *ngIf="(trialEnd$ | async) !== null">
                                <div
                                    class="price__trial"
                                    [class.price__trial--hidden]="plan.id === 'core-basic'"
                                    innerHTML="{{ 'SELECTOR.TRIAL.MULTILINE' | translate:{ date: (trialEnd$ | async).format('DD MMMM YYYY HH:mm') } }}"
                                ></div>
                            </ng-container>
                            <ng-container *ngIf="(trialEnd$ | async) === null">
                                <div class="price__cost">
                                    $
                                    <span class="cost__money">
                                    {{ plan.cost / 100 }}
                                </span>
                                    /{{ 'MARKETPLACE.PAYMENT.PLANS.PER_MONTH' | translate }}
                                </div>
                                <div class="price__per-user">
                                    {{ 'MARKETPLACE.PAYMENT.PLANS.PER_USER' | translate }}
                                </div>
                            </ng-container>
                        </div>
                        <div class="plan__features-list">
                            <ng-container *ngFor="let feature of plan.features.include">
                                <div class="feature">
                                    <mat-icon svgIcon="include"></mat-icon>
                                    {{ feature }}
                                </div>
                            </ng-container>
                            <ng-container *ngFor="let feature of plan.features.exclude">
                                <div class="feature">
                                    <mat-icon svgIcon="exclude"></mat-icon>
                                    {{ feature }}
                                </div>
                            </ng-container>
                            <div class="plan__store-conditions">
                                <div class="conditions">
                                    <span class="promo-text">
                                       {{ plan.storeDataNumber}} {{ plan.storeDataUnit }}
                                    </span>
                                    {{ 'MARKETPLACE.PAYMENT.PLANS.PLANS.STORE_DATA_DESCRIPTION' | translate }}
                                </div>
                                <div class="conditions">
                                    <span class="promo-text">
                                       {{ plan.storeScreenshotNumber}} {{ plan.storeScreenshotUnit }}
                                    </span>
                                    {{ 'MARKETPLACE.PAYMENT.PLANS.PLANS.STORE_SCREENSHOT_DESCRIPTION' | translate }}
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="plan.modules.length > 0">
                            <div class="modules__title">
                                {{ 'MARKETPLACE.PAYMENT.PLANS.PLANS.MODULES.TITLE' | translate }}
                            </div>
                            <div class="plan__modules-list">
                                <ng-container *ngFor="let planModule of plan.modules">
                                    <div class="plan__module">
                                        <div class="module__title"
                                             [innerHTML]="planModule.name"
                                        ></div>
                                        <div class="module__price"
                                            [innerHTML]="planModule.cost"
                                        ></div>
                                    </div>
                                </ng-container>
                            </div>
                        </ng-container>
                        <div class="plan__footer">
                            <div class="plan__install">
                                <button
                                    ui-button
                                    [color]="plan.isInstalled ? 'white' : 'yellow'"
                                    size="large"
                                    [disabled]="plan.isInstalled === true || (isInstallInProgress$ | async) === true"
                                    (click)="selectPlan(plan.id)"
                                >
                                    {{ (plan.isInstalled ? 'MARKETPLACE.PAYMENT.PLANS.PLANS.INSTALLED' : 'MARKETPLACE.PAYMENT.PLANS.PLANS.INSTALL') | translate }}
                                </button>
                            </div>

                            <div class="plan__can-install-modules">
                                {{ 'MARKETPLACE.PAYMENT.PLANS.PLANS.CAN_INSTALL_MODULES' | translate }}
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="(isDataLoading$ | async) === true">
        <div class="col s12 center">
            <ui-spinner></ui-spinner>
        </div>
    </ng-container>
</div>
