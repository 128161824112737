import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'objectValues',
    pure: false,
})
export class ObjectValuesPipe implements PipeTransform {
    transform<T>(value: Record<string, T>): Array<T> {
        return Object.values(value);
    }
}
