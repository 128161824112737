import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { currentLanguageChangedAction } from '@app-client/store/auth/actions/current-user-language.action';
import { currentTimezoneChangedAction } from '@app-client/store/auth/actions/current-user-timezone.action';
import { getCurrentUserAction } from '@app-client/store/auth/actions/get-current-user.action';
import { CurrentUserInterface } from '@app-client/store/auth/types/current-user.interface';
import {
    changePasswordAction,
    changePasswordFailureAction,
    changePasswordSuccessAction,
} from '@app-client/store/user-profile/actions/change-password.action';
import {
    updateEmailCancelAction,
    updateEmailCancelFailureAction,
    updateEmailCancelSuccessAction,
    updateEmailConfirmAction,
    updateEmailConfirmFailureAction,
    updateEmailConfirmSuccessAction,
    updateEmailRequestAction,
    updateEmailRequestFailureAction,
    updateEmailRequestSuccessAction,
} from '@app-client/store/user-profile/actions/update-email.action';
import {
    updateAvatarAction,
    updateAvatarFailureAction,
    updateAvatarSuccessAction,
} from '@app-client/store/user-profile/actions/upload-avatar.action';
import {
    updateUserProfileDataAction,
    updateUserProfileDataFailureAction,
    updateUserProfileDataSuccessAction,
} from '@app-client/store/user-profile/actions/user-profile-data.action';
import { UserProfileService } from '@app-client/store/user-profile/services/user-profile.service';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class UserProfileEffect {
    updateAvatar$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateAvatarAction),
            switchMap(({ avatar }) => {
                return this.userProfileService.updateAvatar(avatar).pipe(
                    map((currentUser: CurrentUserInterface) => {
                        return updateAvatarSuccessAction({ user: currentUser });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(updateAvatarFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    updateAvatarSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateAvatarSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('USER_PROFILE.AVATAR.NOTIFICATIONS.SUCCESS.MESSAGE'),
                    this.translate.instant('USER_PROFILE.AVATAR.NOTIFICATIONS.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    updateAvatarFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateAvatarFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('USER_PROFILE.AVATAR.NOTIFICATIONS.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    // -----------------------------------------------------------------------------------------------------------------

    updateProfileData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateUserProfileDataAction),
            switchMap(({ profileData }) => {
                return this.userProfileService.updateProfileData(profileData).pipe(
                    switchMap((currentUser) => {
                        const actions = [];
                        actions.push(updateUserProfileDataSuccessAction({ user: currentUser }));
                        if (profileData.language) {
                            actions.push(
                                currentLanguageChangedAction({ language: currentUser.language })
                            );
                        }
                        if (profileData.timezone) {
                            actions.push(
                                currentTimezoneChangedAction({ timezone: profileData.timezone })
                            );
                        }

                        return actions;
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(updateUserProfileDataFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    updateProfileSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateUserProfileDataSuccessAction),
            tap(({ user }) => {
                this.toastr.success(
                    this.translate.instant('USER_PROFILE.PROFILE_DATA.NOTIFICATIONS.SUCCESS.MESSAGE'),
                    this.translate.instant('USER_PROFILE.PROFILE_DATA.NOTIFICATIONS.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    updateProfileFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateUserProfileDataFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('USER_PROFILE.PROFILE_DATA.NOTIFICATIONS.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    // -----------------------------------------------------------------------------------------------------------------

    updateEmailRequest$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateEmailRequestAction),
            switchMap(({ newEmail }) => {
                return this.userProfileService.updateEmail(newEmail).pipe(
                    map((currentUser) => {
                        return updateEmailRequestSuccessAction({ user: currentUser });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(updateEmailRequestFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    updateEmailRequestSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateEmailRequestSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('USER_PROFILE.UPDATE_EMAIL_REQUEST.NOTIFICATIONS.SUCCESS.MESSAGE'),
                    this.translate.instant('USER_PROFILE.UPDATE_EMAIL_REQUEST.NOTIFICATIONS.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    updateEmailRequestFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateEmailRequestFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('USER_PROFILE.UPDATE_EMAIL_REQUEST.NOTIFICATIONS.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    // -----------------------------------------------------------------------------------------------------------------

    updateEmailConfirm$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateEmailConfirmAction),
            switchMap(({ token }) => {
                return this.userProfileService.updateEmailConfirm(token).pipe(
                    map((credentials) => {
                        return updateEmailConfirmSuccessAction({ credentials });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(updateEmailConfirmFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    updateEmailConfirmSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateEmailConfirmSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('USER_PROFILE.UPDATE_EMAIL_CONFIRM.NOTIFICATIONS.SUCCESS.MESSAGE'),
                    this.translate.instant('USER_PROFILE.UPDATE_EMAIL_CONFIRM.NOTIFICATIONS.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    updateEmailConfirmFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateEmailConfirmFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('USER_PROFILE.UPDATE_EMAIL_CONFIRM.NOTIFICATIONS.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    // -----------------------------------------------------------------------------------------------------------------

    updateEmailCancel$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateEmailCancelAction),
            switchMap(() => {
                return this.userProfileService.cancelUpdateEmail().pipe(
                    map((currentUser) => {
                        return updateEmailCancelSuccessAction({ user: currentUser });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(updateEmailCancelFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    updateEmailCancelSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateEmailCancelSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('USER_PROFILE.UPDATE_EMAIL_CANCEL.NOTIFICATIONS.SUCCESS.MESSAGE'),
                    this.translate.instant('USER_PROFILE.UPDATE_EMAIL_CANCEL.NOTIFICATIONS.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    updateEmailCancelFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateEmailCancelFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('USER_PROFILE.UPDATE_EMAIL_CANCEL.NOTIFICATIONS.ERROR.TITLE'),
                );
            }),
            map(() => getCurrentUserAction()),
        );
    });

    // -----------------------------------------------------------------------------------------------------------------

    changePassword$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(changePasswordAction),
            switchMap(({ plainPassword }) => {
                return this.userProfileService.updatePassword(plainPassword).pipe(
                    map(() => {
                        return changePasswordSuccessAction();
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(changePasswordFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    changePasswordSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(changePasswordSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('USER_PROFILE.CHANGE_PASSWORD.NOTIFICATIONS.SUCCESS.MESSAGE'),
                    this.translate.instant('USER_PROFILE.CHANGE_PASSWORD.NOTIFICATIONS.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    changePasswordFailureAction$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(changePasswordFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('USER_PROFILE.CHANGE_PASSWORD.NOTIFICATIONS.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    constructor(
        private actions$: Actions,
        private userProfileService: UserProfileService,
        private toastr: ToastrService,
        private translate: TranslateService,
    ) {}

}
