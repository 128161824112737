import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ProjectRoleEnum } from '@app-client/project/types/project-role.enum';
import { UserRoleEnum } from '@app-client/shared/types/user-role.enum';
import { AuthStateInterface } from '@app-client/store/auth/auth-state.interface';


export const authFeatureSelector = createFeatureSelector<AuthStateInterface>('auth');

export const currentCompanySelector = createSelector(
    authFeatureSelector,
    (state: AuthStateInterface) => state.currentCompany,
);

export const companyTrackingSettingsSelector = createSelector(
    authFeatureSelector,
    (state: AuthStateInterface) => state.companyTrackingSettings,
);

export const currentUserSelector = createSelector(
    authFeatureSelector,
    (state: AuthStateInterface) => state.currentUser,
);

export const currentUserIsAdminSelector = createSelector(
    authFeatureSelector,
    (state: AuthStateInterface) => state.currentUser?.role === UserRoleEnum.ROLE_ADMIN,
);

export const currentUserIsManagerSelector = createSelector(
    authFeatureSelector,
    (state: AuthStateInterface) => state.currentUser?.projectRoles.includes(ProjectRoleEnum.ROLE_MANAGER),
);

export const currentUserIsObserverSelector = createSelector(
    authFeatureSelector,
    (state: AuthStateInterface) => state.currentUser?.projectRoles.includes(ProjectRoleEnum.ROLE_OBSERVER),
);

export const currentUserIsHeadSelector = createSelector(
    authFeatureSelector,
    (state: AuthStateInterface) => state.currentUser?.role === UserRoleEnum.ROLE_HEAD,
);

export const currentUserIsSysadminSelector = createSelector(
    authFeatureSelector,
    (state: AuthStateInterface) => state.currentUser?.role === UserRoleEnum.ROLE_SYSADMIN,
);

export const isCompanyLoadingSelector = createSelector(
    authFeatureSelector,
    (state: AuthStateInterface) => state.isCompanyLoading,
);

export const isCurrentUserLoadingSelector = createSelector(
    authFeatureSelector,
    state => state.isCurrentUserLoading,
);

export const onboardingStatusSelector = createSelector(
    authFeatureSelector,
    state => state.currentUser?.onboardingStatus,
);

export const isSubmittingSelector = createSelector(
    authFeatureSelector,
    (state: AuthStateInterface) => state.isSubmitting,
);

export const backendErrorsSelector = createSelector(
    authFeatureSelector,
    (state: AuthStateInterface) => state.backendErrors,
);

export const userLanguageSelector = createSelector(
    authFeatureSelector,
    (state: AuthStateInterface) => state.currentUser?.language,
);

export const isForgotPasswordRequestInProgressSelector = createSelector(
    authFeatureSelector,
    state => state.isForgotPasswordRequestInProgress,
);

export const isForgotPasswordRequestSuccessSelector = createSelector(
    authFeatureSelector,
    state => state.isForgotPasswordRequestSuccess,
);

export const isForgotPasswordTokenValidateInProgressSelector = createSelector(
    authFeatureSelector,
    state => state.isForgotPasswordTokenValidateInProgress,
);

export const forgotPasswordRequestedForEmailSelector = createSelector(
    authFeatureSelector,
    state => state.forgotPasswordRequestedForEmail,
);

export const isForgotPasswordSaveNewPasswordInProgressSelector = createSelector(
    authFeatureSelector,
    state => state.isForgotPasswordSaveNewPasswordInProgress,
);

export const forgotPasswordBackendErrorsSelector = createSelector(
    authFeatureSelector,
    state => state.forgotPasswordBackendErrors,
);

export const forgotPasswordSaveNewPasswordSuccessSelector = createSelector(
    authFeatureSelector,
    state => state.forgotPasswordSaveNewPasswordSuccess,
);

export const isAccessRequestSendingInProgressSelector = createSelector(
    authFeatureSelector,
    state => state.isAccessRequestSendingInProgress,
);

export const accessRequestBackendErrorsSelector = createSelector(
    authFeatureSelector,
    state => state.accessRequestBackendErrors,
);

export const accessRequestSendingSuccessSelector = createSelector(
    authFeatureSelector,
    state => state.accessRequestSendingSuccess,
);

export const loginUnderUserCredentialsSelector = createSelector(
    authFeatureSelector,
    state => state.loginUnderUserCredentials,
);

export const loginUnderUserInProgressSelector = createSelector(
    authFeatureSelector,
    state => state.isLoginUnderUserInProgress,
);

export const isUseTimeslotsSelector = createSelector(
    authFeatureSelector,
    state => state.currentCompany.useTimeslots,
);
