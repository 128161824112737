export enum KaitenActionTypes {
    MODULE_DISABLED = '[Kaiten] Module disabled',

    GET_PROJECT_STATUS = '[Kaiten] Get project status',
    GET_PROJECT_STATUS_SUCCESS = '[Kaiten] Get project status success',
    GET_PROJECT_STATUS_FAILURE = '[Kaiten] Get project status failure',

    GET_USER_SPACES = '[Kaiten] Get user spaces',
    GET_USER_SPACES_SUCCESS = '[Kaiten] Get user spaces success',
    GET_USER_SPACES_FAILURE = '[Kaiten] Get user spaces failure',

    GET_USER_BOARDS = '[Kaiten] Get user boards',
    GET_USER_BOARDS_SUCCESS = '[Kaiten] Get user boards success',
    GET_USER_BOARDS_FAILURE = '[Kaiten] Get user boards failure',

    GET_BOARD_COLUMNS = '[Kaiten] Get board columns',
    GET_BOARD_COLUMNS_SUCCESS = '[Kaiten] Get board columns success',
    GET_BOARD_COLUMNS_FAILURE = '[Kaiten] Get board columns failure',

    GET_PROJECT_COLUMNS = '[Kaiten] Get project columns',
    GET_PROJECT_COLUMNS_SUCCESS = '[Kaiten] Get project columns success',
    GET_PROJECT_COLUMNS_FAILURE = '[Kaiten] Get project columns failure',

    ATTACH_SPACE_TO_PROJECT = '[Kaiten] Attach space to project',
    ATTACH_SPACE_TO_PROJECT_SUCCESS = '[Kaiten] Attach space to project success',
    ATTACH_SPACE_TO_PROJECT_FAILURE = '[Kaiten] Attach space to project failure',

    UPDATE_PROJECT_COLUMN_SETTINGS = '[Kaiten] Update project column settings',
    UPDATE_PROJECT_COLUMN_SETTINGS_SUCCESS = '[Kaiten] Update project column settings success',
    UPDATE_PROJECT_COLUMN_SETTINGS_FAILURE = '[Kaiten] Update project column settings failure',

    DETACH_SPACE_FROM_PROJECT = '[Kaiten] Detach space from project',
    DETACH_SPACE_FROM_PROJECT_SUCCESS = '[Kaiten] Detach space from project success',
    DETACH_SPACE_FROM_PROJECT_FAILURE = '[Kaiten] Detach space from project failure',

    START_SYNC = '[Kaiten] Start sync',
    START_SYNC_SUCCESS = '[Kaiten] Start sync success',
    START_SYNC_FAILURE = '[Kaiten] Start sync failure',

    STOP_SYNC = '[Kaiten] Stop sync',
    STOP_SYNC_SUCCESS = '[Kaiten] Stop sync success',
    STOP_SYNC_FAILURE = '[Kaiten] Stop sync failure',

    START_SPACE_SYNC = '[Kaiten] Start space sync',
    START_SPACE_SYNC_SUCCESS = '[Kaiten] Start space sync success',
    START_SPACE_SYNC_FAILURE = '[Kaiten] Start space sync failure',

    START_SPACE_BOARDS_SYNC = '[Kaiten] Start space boards sync',
    START_SPACE_BOARDS_SYNC_SUCCESS = '[Kaiten] Start space boards sync success',
    START_SPACE_BOARDS_SYNC_FAILURE = '[Kaiten] Start space boards sync failure',

    GET_USER_DATA = '[Kaiten] Get user data',
    GET_USER_DATA_SUCCESS = '[Kaiten] Get user data success',
    GET_USER_DATA_FAILURE = '[Kaiten] Get user data failure',

    UPDATE_KEY = '[Kaiten] Update key',
    UPDATE_KEY_SUCCESS = '[Kaiten] Update key success',
    UPDATE_KEY_FAILURE = '[Kaiten] Update key failure',

    DELETE_KEY = '[Kaiten] Delete key',
    DELETE_KEY_SUCCESS = '[Kaiten] Delete key success',
    DELETE_KEY_FAILURE = '[Kaiten] Delete key failure'
}
