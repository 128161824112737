export enum TrelloActionTypes {
    MODULE_DISABLED= '[Trello] Module disabled',

    AUTH_REQUEST = '[Trello] Auth request',
    AUTH_REQUEST_SUCCESS = '[Trello] Auth request success',
    AUTH_REQUEST_FAILURE = '[Trello] Auth request failure',

    AUTH_VERIFY = '[Trello] Auth verify',
    AUTH_VERIFY_SUCCESS = '[Trello] Auth verify success',
    AUTH_VERIFY_FAILURE = '[Trello] Auth verify failure',

    GET_USER_TOKEN_DATA = '[Trello] Get user token data',
    GET_USER_TOKEN_DATA_SUCCESS = '[Trello] Get user token data success',
    GET_USER_TOKEN_DATA_FAILURE = '[Trello] Get user token data failure',

    LOGOUT = '[Trello] Logout',
    LOGOUT_SUCCESS = '[Trello] Logout success',
    LOGOUT_FAILURE = '[Trello] Logout failure',

    GET_PROJECT_STATUS = '[Trello] Get project status',
    GET_PROJECT_STATUS_SUCCESS = '[Trello] Get project status success',
    GET_PROJECT_STATUS_FAILURE = '[Trello] Get project status failure',

    GET_USER_BOARDS = '[Trello] Get user boards',
    GET_USER_BOARDS_SUCCESS = '[Trello] Get user boards success',
    GET_USER_BOARDS_FAILURE = '[Trello] Get user boards failure',

    GET_BOARD_COLUMNS = '[Trello] Get board columns',
    GET_BOARD_COLUMNS_SUCCESS = '[Trello] Get board columns success',
    GET_BOARD_COLUMNS_FAILURE = '[Trello] Get board columns failure',

    GET_PROJECT_COLUMNS = '[Trello] Get project columns',
    GET_PROJECT_COLUMNS_SUCCESS = '[Trello] Get project columns success',
    GET_PROJECT_COLUMNS_FAILURE = '[Trello] Get project columns failure',

    ATTACH_BOARD_TO_PROJECT = '[Trello] Attach board to project',
    ATTACH_BOARD_TO_PROJECT_SUCCESS = '[Trello] Attach board to project success',
    ATTACH_BOARD_TO_PROJECT_FAILURE = '[Trello] Attach board to project failure',

    UPDATE_PROJECT_COLUMN_SETTINGS = '[Trello] Update project column settings',
    UPDATE_PROJECT_COLUMN_SETTINGS_SUCCESS = '[Trello] Update project column settings success',
    UPDATE_PROJECT_COLUMN_SETTINGS_FAILURE = '[Trello] Update project column settings failure',

    DETACH_BOARD_FROM_PROJECT = '[Trello] Detach board from project',
    DETACH_BOARD_FROM_PROJECT_SUCCESS = '[Trello] Detach board from project success',
    DETACH_BOARD_FROM_PROJECT_FAILURE = '[Trello] Detach board from project failure',

    START_SYNC = '[Trello] Start sync',
    START_SYNC_SUCCESS = '[Trello] Start sync success',
    START_SYNC_FAILURE = '[Trello] Start sync failure',

    STOP_SYNC = '[Trello] Stop sync',
    STOP_SYNC_SUCCESS = '[Trello] Stop sync success',
    STOP_SYNC_FAILURE = '[Trello] Stop sync failure',
}
