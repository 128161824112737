import { createReducer, on } from '@ngrx/store';

import {
    invoicingCustomersCreateAction,
    invoicingCustomersCreateFailureAction,
    invoicingCustomersCreateSuccessAction,
    invoicingCustomersDeleteAction, invoicingCustomersDeleteFailureAction, invoicingCustomersDeleteSuccessAction,
    invoicingCustomersGetDetailsAction,
    invoicingCustomersGetDetailsFailureAction,
    invoicingCustomersGetDetailsSuccessAction,
    invoicingCustomersGetListAction,
    invoicingCustomersGetListFailureAction,
    invoicingCustomersGetListSuccessAction, invoicingCustomersUpdateAction,
    invoicingCustomersUpdateFailureAction,
    invoicingCustomersUpdateSuccessAction,
} from '@app-client/store/invoicing/customers/actions/invoicing-customers.actions';
import { InvoicingCustomersStateInterface } from '@app-client/store/invoicing/customers/invoicing-customers-state.interface';



const initialState: InvoicingCustomersStateInterface = {
    list: [],
    isListLoading: false,
    hasMore: false,

    details: null,
    isDetailsLoading: false,

    isCreateInProgress: false,

    isUpdateInProgress: false,

    isDeleteInProgress: false,
};

const reducer = createReducer(
    initialState,
    on(
        invoicingCustomersGetListAction,
        (state, action) => ({
            ...state,
            isListLoading: true,
            list: 1 === action.requestParams.page ? [] : state.list,
        }),
    ),
    on(
        invoicingCustomersGetListSuccessAction,
        (state, action) => ({
            ...state,
            isListLoading: false,
            list: [...state.list, ...action.response.items],
            hasMore: action.response.page < action.response.totalPages,
        }),
    ),
    on(
        invoicingCustomersGetListFailureAction,
        (state) => ({
            ...state,
            isListLoading: false,
            hasMore: false,
        }),
    ),

    on(
        invoicingCustomersGetDetailsAction,
        (state) => ({
            ...state,
            details: null,
            isDetailsLoading: true,
        }),
    ),
    on(
        invoicingCustomersGetDetailsSuccessAction,
        (state, action) => ({
            ...state,
            details: action.response,
            isDetailsLoading: false,
        }),
    ),
    on(
        invoicingCustomersGetDetailsFailureAction,
        (state) => ({
            ...state,
            isDetailsLoading: false,
        }),
    ),

    on(
        invoicingCustomersCreateAction,
        (state) => ({
            ...state,
            isCreateInProgress: true,
        }),
    ),
    on(
        invoicingCustomersCreateSuccessAction,
        invoicingCustomersCreateFailureAction,
        (state) => ({
            ...state,
            isCreateInProgress: false,
        }),
    ),

    on(
        invoicingCustomersUpdateAction,
        (state) => ({
            ...state,
            isUpdateInProgress: true,
        }),
    ),
    on(
        invoicingCustomersUpdateSuccessAction,
        invoicingCustomersUpdateFailureAction,
        (state) => ({
            ...state,
            isUpdateInProgress: false,
        }),
    ),

    on(
        invoicingCustomersDeleteAction,
        (state) => ({
            ...state,
            isDeleteInProgress: true,
        }),
    ),
    on(
        invoicingCustomersDeleteSuccessAction,
        invoicingCustomersDeleteFailureAction,
        (state) => ({
            ...state,
            isDeleteInProgress: false,
        }),
    ),
);

export const invoicingCustomersReducer = (state, action) => reducer(state, action);
