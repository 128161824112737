import {
    animate,
    animateChild,
    AnimationTriggerMetadata,
    AUTO_STYLE,
    group,
    query,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';


export const collapseAnimation: AnimationTriggerMetadata = trigger('collapse', [
    state(
        '1',
        style({
            height: '0',
            visibility: 'hidden',
            overflow: 'hidden',
        }),
    ),
    state(
        '0',
        style({
            height: AUTO_STYLE,
            visibility: AUTO_STYLE,
            overflow: 'hidden',
        }),
    ),
    transition('0 => 1', [
        group([
            group([query('@*', animateChild(), { optional: true }), animate('500ms 0ms ease-in')]),
            query('@*', animateChild(), { optional: true }),

        ]),
        style({
            display: 'none'
        })
    ], {
        params: {
            delay: 0,
            duration: 500,
        },
    }),
    transition('1 => 0', [
        style({
            display: 'block'
        }),
        group([
            group([query('@*', animateChild(), { optional: true }), animate('500ms 0ms ease-out')]),
            query('@*', animateChild(), { optional: true }),
        ]),
    ], {
        params: {
            delay: 0,
            duration: 500,
        },
    }),
]);
