import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ProjectTasksStateInterface } from '@app-client/store/project-tasks/project-tasks-state.interface';


export const projectTaskFeatureSelector = createFeatureSelector<ProjectTasksStateInterface>('projectTasks');

export const projectTaskListSelector = createSelector(
    projectTaskFeatureSelector,
    state => state.projectTaskList,
);

export const isProjectTaskListLoadingSelector = createSelector(
    projectTaskFeatureSelector,
    state => state.isProjectTaskListLoading,
);

export const hasMoreProjectTaskSelector = createSelector(
    projectTaskFeatureSelector,
    state => state.hasMoreProjectTask,
);

export const projectTaskForEditTimeQueryParamsSelector = createSelector(
    projectTaskFeatureSelector,
    state => state.listQueryParamsForEditTime,
);

export const projectTaskListForEditTimeSelector = createSelector(
    projectTaskFeatureSelector,
    state => state.projectTaskListForEditTime,
);

export const isProjectTaskListForEditTimeLoadingSelector = createSelector(
    projectTaskFeatureSelector,
    state => state.isProjectTaskListForEditTimeLoading,
);

export const hasMoreProjectTaskForEditTimeSelector = createSelector(
    projectTaskFeatureSelector,
    state => state.hasMoreProjectTaskForEditTime,
);

export const projectTaskQueryParamsSelector = createSelector(
    projectTaskFeatureSelector,
    state => state.listQueryParams,
);

export const isCreateProjectTaskInProgressSelector = createSelector(
    projectTaskFeatureSelector,
    state => state.isCreateProjectTaskInProgress,
);

export const createProjectTaskBackendErrorsSelector = createSelector(
    projectTaskFeatureSelector,
    state => state.createProjectTaskBackendErrors,
);

export const isUpdateProjectTaskInProgressSelector = createSelector(
    projectTaskFeatureSelector,
    state => state.isUpdateProjectTaskInProgress,
);

export const updateProjectTaskBackendErrorsSelector = createSelector(
    projectTaskFeatureSelector,
    state => state.updateProjectTaskBackendErrors,
);

export const isRemoveProjectTaskInProgress = createSelector(
    projectTaskFeatureSelector,
    state => state.isRemoveProjectTaskInProgress,
);

export const isRestoreProjectTaskInProgress = createSelector(
    projectTaskFeatureSelector,
    state => state.isRestoreProjectTaskInProgress,
);
