import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CompanyPositionsStateInterface } from '@app-client/store/company/company-positions-state.interface';


export const companyPositionsFeatureSelector = createFeatureSelector<CompanyPositionsStateInterface>('companyPositions');

export const companyPositionsSelector = createSelector(
    companyPositionsFeatureSelector,
    state => state.companyPositions,
);

export const isCompanyPositionsListLoadingSelector = createSelector(
    companyPositionsFeatureSelector,
    state => state.isListLoading,
);

export const companyPositionsQueryConfigSelector = createSelector(
    companyPositionsFeatureSelector,
    state => state.queryConfig,
);

export const companyPositionsHasLoadingMoreSelector = createSelector(
    companyPositionsFeatureSelector,
    state => state.hasLoadingMore,
);

export const isCreateCompanyPositionInProgressSelector = createSelector(
    companyPositionsFeatureSelector,
    state => state.isCreateInProgress,
);

export const isUpdateCompanyPositionInProgressSelector = createSelector(
    companyPositionsFeatureSelector,
    state => state.isUpdateInProgress,
);

export const isDeleteCompanyPositionInProgressSelector = createSelector(
    companyPositionsFeatureSelector,
    state => state.isDeleteInProgress,
);

export const companyPositionBackendErrorsSelector = createSelector(
    companyPositionsFeatureSelector,
    state => state.backendErrors,
);
