import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import {
    invoicingContractorsCreateAction,
    invoicingContractorsCreateFailureAction,
    invoicingContractorsCreateSuccessAction,
    invoicingContractorsDeleteAction,
    invoicingContractorsDeleteFailureAction,
    invoicingContractorsDeleteHeaderFileAction,
    invoicingContractorsDeleteHeaderFileFailureAction,
    invoicingContractorsDeleteHeaderFileSuccessAction,
    invoicingContractorsDeleteSuccessAction,
    invoicingContractorsGetDetailsAction,
    invoicingContractorsGetDetailsFailureAction,
    invoicingContractorsGetDetailsSuccessAction,
    invoicingContractorsGetListAction,
    invoicingContractorsGetListFailureAction,
    invoicingContractorsGetListSuccessAction,
    invoicingContractorsUpdateAction,
    invoicingContractorsUpdateFailureAction,
    invoicingContractorsUpdateSuccessAction,
    invoicingContractorsUploadHeaderFileAction,
    invoicingContractorsUploadHeaderFileFailureAction,
    invoicingContractorsUploadHeaderFileSuccessAction,
} from '@app-client/store/invoicing/contractors/actions/invoicing-contractors.actions';
import { InvoicingContractorsService } from '@app-client/store/invoicing/contractors/services/invoicing-contractors.service';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class InvoicingContractorsEffects {
    constructor(
        private actions$: Actions,
        private invoicingContractorsService: InvoicingContractorsService,
        private router: Router,
        private translate: TranslateService,
        private toastr: ToastrService,
    ) {}

    createEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingContractorsCreateAction),
            switchMap(({ data, base64 }) => {
                return this.invoicingContractorsService.create(data).pipe(
                    map((id) => {
                        if (undefined === base64) {
                            return invoicingContractorsCreateSuccessAction({ id });
                        }
                        return invoicingContractorsUploadHeaderFileAction({
                            id,
                            base64,
                            dispatchAfter: invoicingContractorsCreateSuccessAction({ id }),
                        });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(invoicingContractorsCreateFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    createSuccessEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingContractorsCreateSuccessAction),
            tap(({ id }) => {
                this.router.navigate(['invoicing', 'contractors', id]);
                this.toastr.success(
                    this.translate.instant('INVOICING.CONTRACTORS.NOTIFICATIONS.CREATE.SUCCESS.MESSAGE'),
                    this.translate.instant('INVOICING.CONTRACTORS.NOTIFICATIONS.CREATE.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    createFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingContractorsCreateFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('INVOICING.CONTRACTORS.NOTIFICATIONS.CREATE.FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    getListEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingContractorsGetListAction),
            switchMap(({ requestParams }) => {
                return this.invoicingContractorsService.getList(requestParams).pipe(
                    map((response) => {
                        return invoicingContractorsGetListSuccessAction({ response });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(invoicingContractorsGetListFailureAction({ backendErrors }));
                    }),
                );
            })
        );
    });

    getListFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingContractorsGetListFailureAction),
            tap(({ backendErrors }) => {
                this.router.navigate(['invoicing', 'contractors']);
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('INVOICING.CONTRACTORS.NOTIFICATIONS.GET_LIST.FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    getDetailsEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingContractorsGetDetailsAction),
            switchMap(({ id }) => {
                return this.invoicingContractorsService.getDetails(id).pipe(
                    map((response) => {
                        return invoicingContractorsGetDetailsSuccessAction({ response });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(invoicingContractorsGetDetailsFailureAction({ backendErrors }));
                    }),
                );
            })
        );
    });

    getDetailsFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingContractorsGetDetailsFailureAction),
            tap(({ backendErrors }) => {
                this.router.navigate(['invoicing', 'contractors']);
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('INVOICING.CONTRACTORS.NOTIFICATIONS.GET_DETAILS.FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    updateEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingContractorsUpdateAction),
            switchMap(({ id, data, base64 }) => {
                return this.invoicingContractorsService.update(id, data).pipe(
                    map(() => {
                        if (undefined === base64) {
                            return invoicingContractorsUpdateSuccessAction({ id });
                        }
                        if (null === base64) {
                            return invoicingContractorsDeleteHeaderFileAction({
                                id,
                                dispatchAfter: invoicingContractorsUpdateSuccessAction({ id })
                            });
                        }
                        return invoicingContractorsUploadHeaderFileAction({
                            id,
                            base64,
                            dispatchAfter: invoicingContractorsUpdateSuccessAction({ id })
                        });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(invoicingContractorsUpdateFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    updateSuccessEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingContractorsUpdateSuccessAction),
            tap(({ id }) => {
                this.router.navigate(['invoicing', 'contractors', id]);
                this.toastr.success(
                    this.translate.instant('INVOICING.CONTRACTORS.NOTIFICATIONS.UPDATE.SUCCESS.MESSAGE'),
                    this.translate.instant('INVOICING.CONTRACTORS.NOTIFICATIONS.UPDATE.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    updateFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingContractorsUpdateFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('INVOICING.CONTRACTORS.NOTIFICATIONS.UPDATE.FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    deleteEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingContractorsDeleteAction),
            switchMap(({ id }) => {
                return this.invoicingContractorsService.delete(id).pipe(
                    map(() => {
                        return invoicingContractorsDeleteSuccessAction();
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(invoicingContractorsDeleteFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    deleteSuccessEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingContractorsDeleteSuccessAction),
            tap(() => {
                this.router.navigate(['invoicing', `contractors`]);
                this.toastr.success(
                    this.translate.instant('INVOICING.CONTRACTORS.NOTIFICATIONS.DELETE.SUCCESS.MESSAGE'),
                    this.translate.instant('INVOICING.CONTRACTORS.NOTIFICATIONS.DELETE.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    deleteFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingContractorsDeleteFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('INVOICING.CONTRACTORS.NOTIFICATIONS.DELETE.FAILURE.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    uploadHeaderFileEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingContractorsUploadHeaderFileAction),
            switchMap(({ id, base64, dispatchAfter }) => {
                return this.invoicingContractorsService.uploadHeaderFile(base64, id).pipe(
                    switchMap(() => {
                        return [
                            invoicingContractorsUploadHeaderFileSuccessAction(),
                            dispatchAfter,
                        ];
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(
                            invoicingContractorsUploadHeaderFileFailureAction({ backendErrors }),
                            dispatchAfter,
                        );
                    }),
                );
            }),
        );
    });

    deleteHeaderFileEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(invoicingContractorsDeleteHeaderFileAction),
            switchMap(({ id, dispatchAfter }) => {
                return this.invoicingContractorsService.deleteHeaderFile(id).pipe(
                    switchMap(() => {
                        return [
                            invoicingContractorsDeleteHeaderFileSuccessAction(),
                            dispatchAfter,
                        ];
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(
                            invoicingContractorsDeleteHeaderFileFailureAction({ backendErrors }),
                            dispatchAfter,
                        );
                    }),
                );
            }),
        );
    });
}
