export enum ActionTypes {
    UPDATE_AVATAR = '[UserProfile] Update avatar',
    UPDATE_AVATAR_SUCCESS = '[UserProfile] Update avatar success',
    UPDATE_AVATAR_FAILURE = '[UserProfile] Update avatar failure',

    UPDATE_EMAIL_REQUEST = '[UserProfile] Update email request',
    UPDATE_EMAIL_REQUEST_SUCCESS = '[UserProfile] Update email request success',
    UPDATE_EMAIL_REQUEST_FAILURE = '[UserProfile] Update email request failure',

    UPDATE_EMAIL_CONFIRM = '[UserProfile] Update email confirmation',
    UPDATE_EMAIL_CONFIRM_SUCCESS = '[UserProfile] Update email confirmation success',
    UPDATE_EMAIL_CONFIRM_FAILURE = '[UserProfile] Update email confirmation failure',

    UPDATE_EMAIL_CANCEL = '[UserProfile] Update email cancel',
    UPDATE_EMAIL_CANCEL_SUCCESS = '[UserProfile] Update email cancel success',
    UPDATE_EMAIL_CANCEL_FAILURE = '[UserProfile] Update email cancel failure',

    CHANGE_PASSWORD = '[UserProfile] Change password',
    CHANGE_PASSWORD_SUCCESS = '[UserProfile] Change password success',
    CHANGE_PASSWORD_FAILURE = '[UserProfile] Change password failure',

    UPDATE_PROFILE_DATA = '[UserProfile] Update profile data',
    UPDATE_PROFILE_DATA_SUCCESS = '[UserProfile] Update profile data success',
    UPDATE_PROFILE_DATA_FAILURE = '[UserProfile] Update profile data failure',

    CLEAR_BACKEND_ERRORS = '[UserProfile] Clear backend errors',
}
