import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { logoutAction } from '@app-client/store/auth/actions/login.action';
import { initLoggedAsAction, toggleLoggedAsAction } from '@app-client/store/logged-as/actions/logged-as.action';
import {
    loggedAsIsExpandedSelector,
    loggedAsIsVisibleSelector,
    loggedAsLoggedAsNameSelector,
} from '@app-client/store/logged-as/selectors/logged-as.selector';
import { clearSharedDataFiltersAction } from '@app-client/store/shared-data-filters/actions/shared-data-filters.action';


@Component({
    selector: 'app-logged-as',
    templateUrl: './logged-as.component.html',
    styleUrls: ['./logged-as.component.scss'],
})
export class LoggedAsComponent implements OnInit {
    isVisible$: Observable<boolean>;
    isExpanded$: Observable<boolean>;
    name$: Observable<string>;

    constructor(
        private store: Store,
    ) { }

    ngOnInit() {
        this.initializeValues();
        this.initializeComponentStore();
    }

    private initializeValues() {
        this.isVisible$ = this.store.select(loggedAsIsVisibleSelector);
        this.isExpanded$ = this.store.select(loggedAsIsExpandedSelector);
        this.name$ = this.store.select(loggedAsLoggedAsNameSelector);
    }

    private initializeComponentStore() {
        this.store.dispatch(initLoggedAsAction());
    }

    toggleExpand() {
        this.isExpanded$.pipe(
            take(1),
        ).subscribe((isExpanded) => {
            this.store.dispatch(toggleLoggedAsAction({ show: !isExpanded }));
        });
    }

    logout() {
        this.store.dispatch(logoutAction());
        this.store.dispatch(clearSharedDataFiltersAction());
    }
}
