import { Pipe, PipeTransform } from '@angular/core';


export interface MillisToTimePipeResponse {
    h: number;
    m: number;
    s: number;
}

export function millisToTimeFn(value: number): MillisToTimePipeResponse {
    const output = {
        h: 0,
        m: 0,
        s: 0,
    };

    if (!value) {
        return output;
    }

    return {
        h: Math.floor(value / 1000 / 60 / 60),
        m: Math.floor(value / 1000 / 60 % 60),
        s: Math.floor(value / 1000 % 60),
    };
}

@Pipe({
    name: 'millisToTime',
})
export class MillisToTimePipe implements PipeTransform {
    transform(value: number): MillisToTimePipeResponse {
        return millisToTimeFn(value);
    }
}
