import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserRequestListQueryInterface } from '@app-client/user/types/user-request-list-query.interface';
import { UserRequestInterface } from '@app-client/user/types/user-request.interface';
import { PageResponseInterface } from '@app-shared/types/page-response.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';

import { environment } from '@env-client/environment';


@Injectable()
export class UserRequestService {
    constructor(private httpClient: HttpClient) {}

    getUserRequestList(
        params: UserRequestListQueryInterface
    ): Observable<PageResponseInterface<UserRequestInterface>> {
        let httpParams = new HttpParams()
            .set('limit', (params.limit || 10).toString())
            .set('orderBy', params.orderBy)
            .set('orderDirection', params.orderDirection)
            .set('page', params.page.toString());

        if (params.search) {
            httpParams = httpParams.set('search', params.search);
        }

        const url = environment.apiBaseUrl + '/user-requests';

        return this.httpClient.get<ResponseInterface<PageResponseInterface<UserRequestInterface>>>(url, { params: httpParams }).pipe(
            map((response: ResponseInterface<PageResponseInterface<UserRequestInterface>>) => {
                return response.body;
            })
        );
    }

    deleteUserRequest(id: string) {
        const url = environment.apiBaseUrl + '/user-requests/' + id;

        return this.httpClient.delete(url);
    }

    linkUserRequest(id: string, userId: string) {
        const url = environment.apiBaseUrl + '/user-requests/' + id + '/link';

        return this.httpClient.post(url, { user: userId });
    }
}
