import { createFeatureSelector, createSelector } from '@ngrx/store';

import { MarketplaceAccountStateInterface } from '@app-client/store/marketplace/account/marketplace-account-state.interface';


export const marketplaceAccountFeatureSelector = createFeatureSelector<MarketplaceAccountStateInterface>('marketplaceAccount');

export const marketplaceAccountSelector = createSelector(
    marketplaceAccountFeatureSelector,
    state => state.account,
);

export const marketplaceAccountIsDataLoadingSelector = createSelector(
    marketplaceAccountFeatureSelector,
    state => state.isDataLoading,
);

export const marketplaceCardsListSelector = createSelector(
    marketplaceAccountFeatureSelector,
    state => state.cards,
);

export const marketplaceCardsIsListLoadingSelector = createSelector(
    marketplaceAccountFeatureSelector,
    state => state.isCardsLoading,
);

export const marketplaceCardsInitKeySelector = createSelector(
    marketplaceAccountFeatureSelector,
    state => state.initKey,
);

export const marketplaceAccountChargeSecretSelector = createSelector(
    marketplaceAccountFeatureSelector,
    state => state.chargeSecret,
);

export const marketplaceCardsRemoveInProgressSelector = createSelector(
    marketplaceAccountFeatureSelector,
    state => state.isCardRemoveInProgress,
);

export const marketplaceAccountPayInfoSelector = createSelector(
    marketplaceAccountFeatureSelector,
    state => state.payInfo
);
