import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { tap, withLatestFrom } from 'rxjs/operators';

import { currentLanguageChangedSuccessAction } from '@app-client/store/auth/actions/current-user-language.action';
import { getCurrentCompanySuccessAction } from '@app-client/store/auth/actions/get-current-company.action';
import { currentCompanySelector } from '@app-client/store/auth/selectors/auth.selectors';

const TRACKER_SUB_DOMAIN = 'tracker';
const ROMDO_FAVICON_HREF = '/assets/images/icons/romdo_favicon.png';

@Injectable()
export class TitleEffect {
    getCurrentCompanySuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getCurrentCompanySuccessAction, currentLanguageChangedSuccessAction),
            withLatestFrom(this.store$.select(currentCompanySelector)),
            tap(([, currentCompany]) => {
                if (TRACKER_SUB_DOMAIN === currentCompany.subDomain) {
                    this.title.setTitle('RomDo');
                    const favicon = document.querySelector('#favicon') as HTMLLinkElement;
                    favicon.href = ROMDO_FAVICON_HREF;
                }
                if (TRACKER_SUB_DOMAIN !== currentCompany.subDomain) {
                    this.translate.get('GLOBAL.PAGE_TITLE', { company: currentCompany?.name }).subscribe(text => {
                        this.title.setTitle(text);
                    });
                }
            }),
        );
    }, { dispatch: false });

    constructor(
        private actions$: Actions,
        private title: Title,
        private translate: TranslateService,
        private store$: Store,
    ) {}
}
