<div class="modal-form notranslate">
    <div class="modal-content">
        <div class="modal-header">
            <div class="modal-close" (click)="cancel()">
                <div class="material-icons">close</div>
            </div>
        </div>
        <div class="modal-body">
            <h3>
                {{ 'ACTIVITY.DEMO.RESTART_DEMO_DIALOG.START_DEMO' | translate }}
            </h3>
            <div class="text">
                <ul>
                    <li *ngFor="let section of (availableSections$ | async)">
                        <span *ngIf="isSectionDisabled(section, onboardingDataStatus)"
                              [class.disabled-section]="isSectionDisabled(section, onboardingDataStatus)"
                              [ui-tooltip]="{
                                    content: ('ACTIVITY.DEMO.RESTART_DEMO_DIALOG.ONBOADRING_DATA_NEEDED' | translate),
                                    zIndex: 99999
                              }"
                              class="section-link">
                            {{ section.name$ | async | translate }}
                        </span>

                        <span *ngIf="!isSectionDisabled(section, onboardingDataStatus)"
                              (click)="openSection(section)"
                              class="section-link">
                            {{ section.name$ | async | translate }}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
