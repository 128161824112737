import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { isArray } from 'rxjs/internal-compatibility';
import { skipWhile, switchMap, take } from 'rxjs/operators';

import { AppStateInterface } from '@app-client/shared/types/app-state.interface';
import {
    marketplaceEnabledCoreModuleTariffSelector,
    marketplaceModulesIsEnabledLoadingSelector
} from '@app-client/store/marketplace/modules/selectors/marketplace-modules.selectors';


@Injectable()
export class CoreTariffGuardService implements CanActivate {
    constructor(
        private router: Router,
        private store: Store<AppStateInterface>,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const allowedTariffs = isArray(route.data.coreTariff) ? route.data.coreTariff : [route.data.coreTariff];

        return combineLatest([
            this.store.select(marketplaceEnabledCoreModuleTariffSelector),
            this.store.select(marketplaceModulesIsEnabledLoadingSelector),
        ]).pipe(
            skipWhile(([, isEnabledModulesLoading]) => {
              return isEnabledModulesLoading;
            }),
            take(1),
            switchMap(([enabledCoreTariff]) => {
                const forbid = () => {
                    this.router.navigate(['/']);

                    return of(false);
                };

                const allow = () => {
                    return of(true);
                };

                if (!allowedTariffs.length) {
                    return allow();
                }

                if (!enabledCoreTariff) {
                    return forbid();
                }

                if (allowedTariffs.includes(enabledCoreTariff)) {
                    return allow();
                }

                return forbid();
            }),
        );
    }
}
