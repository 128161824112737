import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserTimeFormatEnum } from '@app-client/shared/types/user-time-format.enum';
import { CredentialsInterface } from '@app-client/store/auth/types/credentials.interface';
import { CurrentUserInterface } from '@app-client/store/auth/types/current-user.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';

import { environment } from '@env-client/environment';


export type NotificationsSettings = {
    addTimeRequestStatusChangeSubscription: boolean;
    addTimeRequestCreateSubscription: boolean;
};


@Injectable()
export class UserProfileService {
    constructor(private httpClient: HttpClient) {}

    updateAvatar(avatar: string): Observable<CurrentUserInterface> {
        const url = environment.apiBaseUrl + '/me/avatar';

        return this.httpClient.post(url, { avatar }).pipe(map((response: ResponseInterface<CurrentUserInterface>) => {
            return response.body;
        }));
    }

    updatePassword(plainPassword: { first: string, second: string }): Observable<void> {
        const url = environment.apiBaseUrl + '/me/password';

        return this.httpClient.patch(url, { plainPassword }).pipe(map((response: ResponseInterface<void>) => {
            return response.body;
        }));
    }

    updateEmail(newEmail: string): Observable<CurrentUserInterface> {
        const url = environment.apiBaseUrl + '/me/change-email/request';

        return this.httpClient.post(url, { newEmail }).pipe(map((response: ResponseInterface<CurrentUserInterface>) => {
            return response.body;
        }));
    }

    updateEmailConfirm(token: string): Observable<CredentialsInterface> {
        const url = environment.apiBaseUrl + '/me/change-email/confirm';

        return this.httpClient.post(url, {}, {
            params: { token },
        }).pipe(map((response: ResponseInterface<CredentialsInterface>) => {
            return response.body;
        }));
    }

    cancelUpdateEmail(): Observable<CurrentUserInterface> {
        const url = environment.apiBaseUrl + '/me/change-email/cancel';

        return this.httpClient.post(url, {}).pipe(map((response: ResponseInterface<CurrentUserInterface>) => {
            return response.body;
        }));
    }

    updateProfileData(profileData: {
        firstName?: string,
        lastName?: string,
        language?: string,
        timezone?: string,
        timeFormat?: UserTimeFormatEnum
    }): Observable<CurrentUserInterface> {
        const url = environment.apiBaseUrl + '/me';

        return this.httpClient.patch(url, { ...profileData }).pipe(
            map((response: ResponseInterface<CurrentUserInterface>) => {
                return response.body;
            }),
        );
    }

    updateNotificationsSettings(settings: NotificationsSettings): Observable<any> {
        const url = environment.apiBaseUrl + '/me/notification-settings';
        const body = {
            email: [],
        };
        if (true === settings.addTimeRequestCreateSubscription) {
            body.email.push('duplicateRequestToAddTime');
        }
        if (true === settings.addTimeRequestStatusChangeSubscription) {
            body.email.push('notifyAboutAddedTime');
        }

        return this.httpClient.patch(url, body);
    }

    getNotificationsSettings(): Observable<NotificationsSettings> {
        const url = environment.apiBaseUrl + '/me/notification-settings';

        return this.httpClient.get<ResponseInterface<{ email: Array<string> }>>(url)
            .pipe(
                map((response) => {
                    const result = {
                        addTimeRequestCreateSubscription: false,
                        addTimeRequestStatusChangeSubscription: false,
                    };

                    for (const subscription of response.body.email) {
                        switch (subscription) {
                            case 'duplicateRequestToAddTime':
                                result.addTimeRequestCreateSubscription = true;
                                break;
                            case 'notifyAboutAddedTime':
                                result.addTimeRequestStatusChangeSubscription = true;
                                break;
                        }
                    }

                    return result;
                }),
            );
    }
}
