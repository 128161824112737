export enum MarketplaceInvoicesActionsEnum {
    GET_LIST = '[Marketplace invoices] Get list',
    GET_LIST_SUCCESS = '[Marketplace invoices] Get list success',
    GET_LIST_FAILURE = '[Marketplace invoices] Get list failure',

    GET_DETAILS = '[Marketplace invoices] Get details',
    GET_DETAILS_SUCCESS = '[Marketplace invoices] Get details success',
    GET_DETAILS_FAILURE = '[Marketplace invoices] Get details failure',

    PAY = '[Marketplace invoices] Pay',
    PAY_SUCCESS = '[Marketplace invoices] Pay success',
    PAY_FAILURE = '[Marketplace invoices] Pay failure',

    PDF = '[Marketplace invoices] Pdf',
    PDF_SUCCESS = '[Marketplace invoices] Pdf success',
    PDF_FAILURE = '[Marketplace invoices] pdf failure'
}
