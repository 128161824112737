import { createAction, props } from '@ngrx/store';

import { JiraConnectionInterface } from '@app-client/jira/types/jira-connection.interface';
import { JiraAuthActionEnum } from '@app-client/store/jira/jira-auth-action.enum';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


export const jiraGetConnectionsAction = createAction(
    JiraAuthActionEnum.GET_CONNECTIONS,
);

export const jiraGetConnectionsSuccessAction = createAction(
    JiraAuthActionEnum.GET_CONNECTIONS_SUCCESS,
    props<{ connections: Array<JiraConnectionInterface> }>(),
);

export const jiraGetConnectionsFailureAction = createAction(
    JiraAuthActionEnum.GET_CONNECTIONS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const jiraGetCloudOauth2RedirectUrlAction = createAction(
    JiraAuthActionEnum.GET_CLOUD_OAUTH2_REDIRECT_URL,
    props<{ projectId: string }>(),
);

export const jiraGetCloudOauth2RedirectUrlSuccessAction = createAction(
    JiraAuthActionEnum.GET_CLOUD_OAUTH2_REDIRECT_URL_SUCCESS,
    props<{ url: string }>(),
);

export const jiraGetCloudOauth2RedirectUrlFailureAction = createAction(
    JiraAuthActionEnum.GET_CLOUD_OAUTH2_REDIRECT_URL_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const jiraVerifyCloudOauth2Action = createAction(
    JiraAuthActionEnum.VERIFY_CLOUD_OAUTH2,
    props<{ code: string | undefined, error: string | undefined, state: string }>(),
);

export const jiraVerifyCloudOauth2SuccessAction = createAction(
    JiraAuthActionEnum.VERIFY_CLOUD_OAUTH2_SUCCESS,
);

export const jiraVerifyCloudOauth2FailureAction = createAction(
    JiraAuthActionEnum.VERIFY_CLOUD_OAUTH2_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const jiraAttachCloudApiTokenAction = createAction(
    JiraAuthActionEnum.ATTACH_CLOUD_API_TOKEN,
    props<{ apiToken: string, connectionId: string }>(),
);

export const jiraAttachCloudApiTokenSuccessAction = createAction(
    JiraAuthActionEnum.ATTACH_CLOUD_API_TOKEN_SUCCESS,
);

export const jiraAttachCloudApiTokenFailureAction = createAction(
    JiraAuthActionEnum.ATTACH_CLOUD_API_TOKEN_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const jiraGetSelfHostedOauth1RedirectUrlAction = createAction(
    JiraAuthActionEnum.GET_SELF_HOSTED_OAUTH1_REDIRECT_URL,
    props<{ host: string, projectId: string }>(),
);

export const jiraGetSelfHostedOauth1RedirectUrlSuccessAction = createAction(
    JiraAuthActionEnum.GET_SELF_HOSTED_OAUTH1_REDIRECT_URL_SUCCESS,
    props<{ url: string }>(),
);

export const jiraGetSelfHostedOauth1RedirectUrlFailureAction = createAction(
    JiraAuthActionEnum.GET_SELF_HOSTED_OAUTH1_REDIRECT_URL_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const jiraVerifySelfHostedOauth1Action = createAction(
    JiraAuthActionEnum.VERIFY_SELF_HOSTED_OAUTH1,
    props<{ token: string, project: string, sessionState: string }>(),
);

export const jiraVerifySelfHostedOauth1SuccessAction = createAction(
    JiraAuthActionEnum.VERIFY_SELF_HOSTED_OAUTH1_SUCCESS,
);

export const jiraVerifySelfHostedOauth1FailureAction = createAction(
    JiraAuthActionEnum.VERIFY_SELF_HOSTED_OAUTH1_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const jiraAuthSelfHostedBasicAction = createAction(
    JiraAuthActionEnum.AUTH_SELF_HOSTED_BASIC,
    props<{ host: string, login: string, password: string, projectId: string }>(),
);

export const jiraAuthSelfHostedBasicSuccessAction = createAction(
    JiraAuthActionEnum.AUTH_SELF_HOSTED_BASIC_SUCCESS,
);

export const jiraAuthSelfHostedBasicFailureAction = createAction(
    JiraAuthActionEnum.AUTH_SELF_HOSTED_BASIC_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const jiraLogoutAction = createAction(
    JiraAuthActionEnum.LOGOUT,
    props<{ connectionId: string }>(),
);

export const jiraLogoutSuccessAction = createAction(
    JiraAuthActionEnum.LOGOUT_SUCCESS,
    props<{ connectionId: string }>(),
);

export const jiraLogoutFailureAction = createAction(
    JiraAuthActionEnum.LOGOUT_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
