export enum ProjectTasksActionTypes {
    SET_LIST_QUERY_PARAMS = '[ProjectTasks] Set list query params',

    GET_LIST = '[ProjectTasks] Get list',
    GET_LIST_SUCCESS = '[ProjectTasks] Get list success',
    GET_LIST_FAILURE = '[ProjectTasks] Get list failure',

    SET_LIST_QUERY_PARAMS_FOR_EDIT_TIME = '[ProjectTasks] Set list query params for edit time',

    GET_LIST_FOR_EDIT_TIME = '[ProjectTasks] Get list for edit time',
    GET_LIST_FOR_EDIT_TIME_SUCCESS = '[ProjectTasks] Get list for edit time success',
    GET_LIST_FOR_EDIT_TIME_FAILURE = '[ProjectTasks] Get list for edit time failure',

    CREATE = '[ProjectTasks] Create',
    CREATE_SUCCESS = '[ProjectTasks] Create success',
    CREATE_FAILURE = '[ProjectTasks] Create failure',

    UPDATE = '[ProjectTasks] Update',
    UPDATE_SUCCESS = '[ProjectTasks] Update success',
    UPDATE_FAILURE = '[ProjectTasks] Update failure',

    REMOVE = '[ProjectTasks] Remove',
    REMOVE_SUCCESS = '[ProjectTasks] Remove success',
    REMOVE_FAILURE = '[ProjectTasks] Remove failure',

    RESTORE = '[ProjectTasks] Restore',
    RESTORE_SUCCESS = '[ProjectTasks] Restore success',
    RESTORE_FAILURE = '[ProjectTasks] Restore failure',
}
