import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PaymentInvoiceDetailsResponseInterface } from '@app-client/payment/invoices/details/types/payment-invoice-details-response.interface';
import { PaymentInvoiceResponseInterface } from '@app-client/payment/invoices/list/types/payment-invoice-response.interface';
import { PaymentInvoicePdfResponseInterface } from '@app-client/payment/invoices/types/payment-invoice-pdf-response.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';

import { environment } from '@env-client/environment';


@Injectable()
export class MarketplaceInvoicesService {
    constructor(
        private http: HttpClient
    ) {}

    getList(): Observable<Array<PaymentInvoiceResponseInterface>> {
        const url = `${environment.apiBaseUrl}/market/invoices`;

        return this.http.get<ResponseInterface<Array<PaymentInvoiceResponseInterface>>>(url).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    getDetails(id: string): Observable<PaymentInvoiceDetailsResponseInterface> {
        const url = `${environment.apiBaseUrl}/market/invoices/${id}`;

        return this.http.get<ResponseInterface<PaymentInvoiceDetailsResponseInterface>>(url).pipe(
            map((response) => {
                return response.body;
            })
        );
    }

    pay(id: string): Observable<void> {
        const url = `${environment.apiBaseUrl}/market/invoices/${id}/pay`;

        return this.http.post<ResponseInterface<void>>(url, {}).pipe(
            map((response) => {
                return response.body;
            }),
        );
    }

    getPdf(id: string): Observable<PaymentInvoicePdfResponseInterface> {
        const url = `${environment.apiBaseUrl}/market/invoices/${id}/pdf`;

        return this.http.post<Blob>(url, {}, {
            observe: 'response',
            responseType: 'blob' as 'json'
        }).pipe(
            map((response) => {
                let disposition = response.headers.getAll('content-disposition').toString();
                disposition = disposition.split(';')[1].trim().split('=')[1].replace(/"/g, '');
                return {
                    data: response.body,
                    filename: disposition
                };
            }),
        );
    }
}
