import { createReducer, on } from '@ngrx/store';

import {
    accessRequestSend,
    accessRequestSendFailure,
    accessRequestSendSuccess,
    clearAccessRequestSend,
} from '@app-client/store/auth/actions/access-request.action';
import {
    clearForgotPasswordRequestAction,
    clearForgotPasswordSaveNewPasswordAction,
    forgotPasswordRequestAction,
    forgotPasswordRequestFailureAction,
    forgotPasswordRequestSuccessAction,
    forgotPasswordSaveNewPasswordAction,
    forgotPasswordSaveNewPasswordFailureAction,
    forgotPasswordSaveNewPasswordSuccessAction,
    forgotPasswordValidateTokenAction,
    forgotPasswordValidateTokenFailureAction,
    forgotPasswordValidateTokenSuccessAction,
} from '@app-client/store/auth/actions/forgot-password.action';
import {
    getCompanyTrackingSettingsAction,
    getCompanyTrackingSettingsFailureAction,
    getCompanyTrackingSettingsSuccessAction,
} from '@app-client/store/auth/actions/get-company-tracking-settings.action';
import {
    getCurrentCompanyAction,
    getCurrentCompanyFailureAction,
    getCurrentCompanySuccessAction,
} from '@app-client/store/auth/actions/get-current-company.action';
import {
    getCurrentUserAction,
    getCurrentUserFailureAction,
    getCurrentUserSuccessAction,
} from '@app-client/store/auth/actions/get-current-user.action';
import {
    loginUnderUserAction,
    loginUnderUserFailureAction,
    loginUnderUserSuccessAction,
} from '@app-client/store/auth/actions/login-under-user.action';
import { loginAction, loginFailureAction, loginSuccessAction, logoutAction } from '@app-client/store/auth/actions/login.action';
import { AuthStateInterface } from '@app-client/store/auth/auth-state.interface';
import { updateCompanySettingsSuccessAction } from '@app-client/store/company/actions/company-settings.action';
import {
    updateEmailCancelSuccessAction,
    updateEmailConfirmSuccessAction,
    updateEmailRequestSuccessAction,
} from '@app-client/store/user-profile/actions/update-email.action';
import { updateAvatarSuccessAction } from '@app-client/store/user-profile/actions/upload-avatar.action';
import { updateUserProfileDataSuccessAction } from '@app-client/store/user-profile/actions/user-profile-data.action';


const initialState: AuthStateInterface = {
    isSubmitting: false,
    isCompanyLoading: null,
    isCurrentUserLoading: false,
    isCompanyTrackingSettingsLoading: false,
    currentUser: null,
    credentials: null,
    currentCompany: null,
    companyTrackingSettings: null,
    backendErrors: null,

    isForgotPasswordRequestInProgress: false,
    isForgotPasswordRequestSuccess: false,
    isForgotPasswordTokenValidateInProgress: false,
    forgotPasswordRequestedForEmail: null,
    isForgotPasswordSaveNewPasswordInProgress: false,
    forgotPasswordSaveNewPasswordSuccess: false,
    forgotPasswordBackendErrors: null,

    isAccessRequestSendingInProgress: false,
    accessRequestSendingSuccess: false,
    accessRequestBackendErrors: null,

    loginUnderUserCredentials: null,
    isLoginUnderUserInProgress: false,
};

const reducer = createReducer(
    initialState,
    on(
        getCurrentUserAction,
        state => ({
            ...state,
            isCurrentUserLoading: true,
        }),
    ),
    on(
        getCurrentUserSuccessAction,
        (state, action) => ({
            ...state,
            currentUser: action.currentUser,
            isCurrentUserLoading: false,
        }),
    ),
    on(
        getCurrentUserFailureAction,
        state => ({
            ...state,
            currentUser: null,
            isCurrentUserLoading: false,
            credentials: null,
        }),
    ),
    on(
        getCurrentCompanyAction,
        state => ({
            ...state,
            isCompanyLoading: true,
        }),
    ),
    on(
        getCurrentCompanySuccessAction,
        (state, action) => ({
            ...state,
            currentCompany: action.currentCompany,
            isCompanyLoading: false,
        }),
    ),
    on(
        getCurrentCompanyFailureAction,
        state => ({
            ...state,
            currentCompany: null,
            isCompanyLoading: false,
        }),
    ),
    on(
        getCompanyTrackingSettingsAction,
        state => ({
            ...state,
            companyTrackingSettings: null,
            isCompanyTrackingSettingsLoading: true,
        }),
    ),
    on(
        getCompanyTrackingSettingsSuccessAction,
        (state, action) => ({
            ...state,
            isCompanyTrackingSettingsLoading: false,
            companyTrackingSettings: action.config,
        }),
    ),
    on(
        getCompanyTrackingSettingsFailureAction,
        state => ({
            ...state,
            isCompanyTrackingSettingsLoading: false,
        }),
    ),
    on(
        loginAction,
        state => ({
            ...state,
            isSubmitting: true,
            backendErrors: null,
        }),
    ),
    on(
        loginFailureAction,
        (state, action) => ({
            ...state,
            isSubmitting: false,
            backendErrors: action.backendErrors,
        }),
    ),
    on(
        loginSuccessAction,
        (state, action) => ({
            ...state,
            credentials: action.credentials,
            isSubmitting: false,
        }),
    ),
    on(
        logoutAction,
        state => ({
            ...state,
            credentials: null,
        }),
    ),
    on(
        updateAvatarSuccessAction,
        updateEmailRequestSuccessAction,
        updateEmailCancelSuccessAction,
        updateUserProfileDataSuccessAction,
        (state, action) => ({
            ...state,
            currentUser: action.user,
        }),
    ),
    on(
        updateEmailConfirmSuccessAction,
        (state, action) => ({
            ...state,
            credentials: action.credentials,
        }),
    ),

    on(
        forgotPasswordRequestAction,
        state => ({
            ...state,
            isForgotPasswordRequestInProgress: true,
            isForgotPasswordRequestSuccess: false,
            forgotPasswordBackendErrors: {
                ...state.forgotPasswordBackendErrors,
                email: null,
            },
        }),
    ),
    on(
        forgotPasswordRequestSuccessAction,
        state => ({
            ...state,
            isForgotPasswordRequestInProgress: false,
            isForgotPasswordRequestSuccess: true,
        }),
    ),
    on(
        forgotPasswordRequestFailureAction,
        (state, action) => ({
            ...state,
            isForgotPasswordRequestInProgress: false,
            isForgotPasswordRequestSuccess: false,
            forgotPasswordBackendErrors: {
                ...state.forgotPasswordBackendErrors,
                ...action.backendErrors,
            },
        }),
    ),
    on(
        clearForgotPasswordRequestAction,
        state => ({
            ...state,
            isForgotPasswordRequestInProgress: false,
            isForgotPasswordRequestSuccess: false,
            forgotPasswordBackendErrors: null,
        }),
    ),

    on(
        forgotPasswordValidateTokenAction,
        state => ({
            ...state,
            isForgotPasswordTokenValidateInProgress: true,
            forgotPasswordRequestedForEmail: null,
        }),
    ),
    on(
        forgotPasswordValidateTokenSuccessAction,
        (state, action) => ({
            ...state,
            isForgotPasswordTokenValidateInProgress: false,
            forgotPasswordRequestedForEmail: action.email,
        }),
    ),
    on(
        forgotPasswordValidateTokenFailureAction,
        state => ({
            ...state,
            isForgotPasswordTokenValidateInProgress: false,
        }),
    ),

    on(
        forgotPasswordSaveNewPasswordAction,
        state => ({
            ...state,
            isForgotPasswordSaveNewPasswordInProgress: true,
            forgotPasswordSaveNewPasswordSuccess: false,
            forgotPasswordBackendErrors: {
                ...state.forgotPasswordBackendErrors,
                plainPassword: null,
            },
        }),
    ),
    on(
        forgotPasswordSaveNewPasswordSuccessAction,
        state => ({
            ...state,
            isForgotPasswordSaveNewPasswordInProgress: false,
            forgotPasswordSaveNewPasswordSuccess: true,
        }),
    ),
    on(
        forgotPasswordSaveNewPasswordFailureAction,
        (state, action) => ({
            ...state,
            isForgotPasswordSaveNewPasswordInProgress: false,
            forgotPasswordSaveNewPasswordSuccess: false,
            forgotPasswordBackendErrors: {
                ...state.forgotPasswordBackendErrors,
                ...action.backendErrors,
            },
        }),
    ),
    on(
        clearForgotPasswordSaveNewPasswordAction,
        state => ({
            ...state,
            isForgotPasswordSaveNewPasswordInProgress: false,
            forgotPasswordSaveNewPasswordSuccess: false,
            forgotPasswordBackendErrors: null,
        }),
    ),

    on(
        accessRequestSend,
        state => ({
            ...state,
            isAccessRequestSendingInProgress: true,
            accessRequestSendingSuccess: false,
            accessRequestBackendErrors: null,
        }),
    ),
    on(
        accessRequestSendSuccess,
        state => ({
            ...state,
            isAccessRequestSendingInProgress: false,
            accessRequestSendingSuccess: true,
        }),
    ),
    on(
        accessRequestSendFailure,
        (state, action) => ({
            ...state,
            isAccessRequestSendingInProgress: false,
            accessRequestSendingSuccess: false,
            accessRequestBackendErrors: action.backendErrors,
        }),
    ),
    on(
        clearAccessRequestSend,
        state => ({
            ...state,
            isAccessRequestSendingInProgress: false,
            accessRequestSendingSuccess: false,
            accessRequestBackendErrors: null,
        }),
    ),

    on(
        loginUnderUserAction,
        state => ({
            ...state,
            loginUnderUserCredentials: null,
            isLoginUnderUserInProgress: true,
        }),
    ),
    on(
        loginUnderUserSuccessAction,
        (state, action) => ({
            ...state,
            loginUnderUserCredentials: action.credentials,
            isLoginUnderUserInProgress: false,
        }),
    ),
    on(
        loginUnderUserFailureAction,
        state => ({
            ...state,
            isLoginUnderUserInProgress: false,
        }),
    ),
    on(
        updateCompanySettingsSuccessAction,
        (state, action) => ({
            ...state,
            currentCompany: {
                ...state.currentCompany,
                timezone: action.companySettings.timezone,
                name: action.companySettings.name,
            },
        }),
    ),
);

export function authReducer(state, action) {
    return reducer(state, action);
}
