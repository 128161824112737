import { createAction, props } from '@ngrx/store';
import { Moment } from 'moment-timezone';

import { CompanyDomainInterface } from '@app-client/company/types/settings/company-domain.interface';
import { CompanySettingsInterface } from '@app-client/company/types/settings/company-settings.interface';
import { UpdateCompanySettingsInterface } from '@app-client/company/types/settings/update-company-settings.interface';
import { SettingsActionTypes } from '@app-client/store/company/settings-action-types';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


export const getCompanySettingsAction = createAction(
    SettingsActionTypes.GET_COMPANY_SETTINGS,
);
export const getCompanySettingsSuccessAction = createAction(
    SettingsActionTypes.GET_COMPANY_SETTINGS_SUCCESS,
    props<{ companySettings: CompanySettingsInterface }>(),
);
export const getCompanySettingsFailureAction = createAction(
    SettingsActionTypes.GET_COMPANY_SETTINGS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const updateCompanySettingsAction = createAction(
    SettingsActionTypes.UPDATE_COMPANY_SETTINGS,
    props<{ companySettings: UpdateCompanySettingsInterface }>(),
);
export const updateCompanySettingsSuccessAction = createAction(
    SettingsActionTypes.UPDATE_COMPANY_SETTINGS_SUCCESS,
    props<{ companySettings: CompanySettingsInterface }>(),
);
export const updateCompanySettingsFailureAction = createAction(
    SettingsActionTypes.UPDATE_COMPANY_SETTINGS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const updateCompanyAvatarAction = createAction(
    SettingsActionTypes.UPDATE_COMPANY_AVATAR,
    props<{ avatar: string }>(),
);
export const updateCompanyAvatarSuccessAction = createAction(
    SettingsActionTypes.UPDATE_COMPANY_AVATAR_SUCCESS,
    props<{ companySettings: CompanySettingsInterface }>(),
);
export const updateCompanyAvatarFailureAction = createAction(
    SettingsActionTypes.UPDATE_COMPANY_AVATAR_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const getCompanyDomainsAction = createAction(
    SettingsActionTypes.GET_COMPANY_DOMAINS,
);
export const getCompanyDomainsSuccessAction = createAction(
    SettingsActionTypes.GET_COMPANY_DOMAINS_SUCCESS,
    props<{ domains: Array<CompanyDomainInterface> }>(),
);
export const getCompanyDomainsFailureAction = createAction(
    SettingsActionTypes.GET_COMPANY_DOMAINS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const createCompanyDomainAction = createAction(
    SettingsActionTypes.CREATE_COMPANY_DOMAIN,
    props<{ name: string, successCallback?: () => void }>(),
);
export const createCompanyDomainSuccessAction = createAction(
    SettingsActionTypes.CREATE_COMPANY_DOMAIN_SUCCESS,
    props<{ createdDomain: CompanyDomainInterface, successCallback?: () => void }>(),
);
export const createCompanyDomainFailureAction = createAction(
    SettingsActionTypes.CREATE_COMPANY_DOMAIN_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const deleteCompanyDomainAction = createAction(
    SettingsActionTypes.DELETE_COMPANY_DOMAIN,
    props<{ id: string }>(),
);
export const deleteCompanyDomainSuccessAction = createAction(
    SettingsActionTypes.DELETE_COMPANY_DOMAIN_SUCCESS,
    props<{ id: string }>(),
);
export const deleteCompanyDomainFailureAction = createAction(
    SettingsActionTypes.DELETE_COMPANY_DOMAIN_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const getCompanyClosePeriodAction = createAction(
    SettingsActionTypes.GET_COMPANY_CLOSE_PERIOD,
);
export const getCompanyClosePeriodSuccessAction = createAction(
    SettingsActionTypes.GET_COMPANY_CLOSE_PERIOD_SUCCESS,
    props<{ closePeriod: Moment }>(),
);
export const getCompanyClosePeriodFailureAction = createAction(
    SettingsActionTypes.GET_COMPANY_CLOSE_PERIOD_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
