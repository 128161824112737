import { KaitenBoardColumnSettingsInterface } from '@app-client/kaiten/types/kaiten-board-column-settings.interface';

export interface KaitenBoard {
    boardId: string;
    boardName: string;
    columnFilteringType: KaitenColumnFilteringTypeEnum;
    columns: {
        columnName: string;
        columnId: string
    }[];
}

export interface  KaitenProjectStatusInterface {
    lastSyncTime?: Date;
    kaitenSpaceId?: string;
    kaitenSpaceName?: string;
    kaitenBoards?: KaitenBoard[];
    kaitenBoardName?: string;
    syncStatus?: number;
    columnSettings?: KaitenBoardColumnSettingsInterface;
    tokenStatus?: boolean;
}

export enum KaitenProjectSyncStatusEnum {
    IDLE = 0,
    IN_PROGRESS = 1,
}

export enum KaitenColumnFilteringTypeEnum {
    EXCLUDE = 1,
    INCLUDE = 2,
}
