export enum InvoicingInvoicesActionTypesEnum {
    GET_LIST = '[Invoicing/Invoices] Get list',
    GET_LIST_SUCCESS = '[Invoicing/Invoices] Get list success',
    GET_LIST_FAILURE = '[Invoicing/Invoices] Get list failure',

    GENERATE = '[Invoicing/Invoices] Generate',
    GENERATE_SUCCESS = '[Invoicing/Invoice] Generate success',
    GENERATE_FAILURE = '[Invoicing/Invoice] Generate failure',

    RECALCULATE = '[Invoicing/Invoices] Recalculate',
    RECALCULATE_SUCCESS = '[Invoicing/Invoice] Recalculate success',
    RECALCULATE_FAILURE = '[Invoicing/Invoice] Recalculate failure',

    CLEAR_CALCULATE = '[Invoicing/Invoices] Clear calculate',

    SAVE = '[Invoicing/Invoices] Save',
    SAVE_SUCCESS = '[Invoicing/Invoices] Save success',
    SAVE_FAILURE = '[Invoicing/Invoices] Save failure',

    DELETE = '[Invoicing/Invoices] Delete',
    DELETE_SUCCESS = '[Invoicing/Invoices] Delete success',
    DELETE_FAILURE = '[Invoicing/Invoices] Delete failure',

    GET_DETAILS = '[Invoicing/Invoices] Get details',
    GET_DETAILS_SUCCESS = '[Invoicing/Invoices] Get details success',
    GET_DETAILS_FAILURE = '[Invoicing/Invoices] Get details failure',

    EXPORT_TO_GOOGLE_SHEET = '[Invoicing/Invoices] Export to google sheet',
    EXPORT_TO_GOOGLE_SHEET_SUCCESS = '[Invoicing/Invoices] Export to google sheet success',
    EXPORT_TO_GOOGLE_SHEET_FAILURE = '[Invoicing/Invoices] Export to google sheet failure',

    UPDATE = '[Invoicing/Invoices] Update',
    UPDATE_SUCCESS = '[Invoicing/Invoices] Update success',
    UPDATE_FAILURE = '[Invoicing/Invoices] Update failure',
}
