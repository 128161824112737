import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import {
    currentUserIsAdminSelector,
    currentUserIsManagerSelector,
    currentUserIsObserverSelector,
} from '@app-client/store/auth/selectors/auth.selectors';


@Injectable()
export class UserIsObserverOnlyGuardService implements CanActivate {
    constructor(
        private store: Store,
        private router: Router,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return combineLatest([
            this.store.select(currentUserIsObserverSelector),
            this.store.select(currentUserIsAdminSelector),
            this.store.select(currentUserIsManagerSelector),
        ]).pipe(
            map(([isObserver, isAdmin, isManager]) => isObserver && !isAdmin && !isManager),
            switchMap((isUserObserverOnly: boolean) => {
                const forbid = () => {
                    this.router.navigate(['/']);

                    return of(false);
                };

                const allow = () => {
                    return of(true);
                };

                return isUserObserverOnly ? forbid() : allow();
            }),
        );
    }
}
