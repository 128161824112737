import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/browser';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';

import { currentUserSelector } from '@app-client/store/auth/selectors/auth.selectors';


@Injectable()
export class AppErrorHandler extends ErrorHandler {
    store$: Store;

    constructor(
        @Inject('SENTRY_DSN') dsn: string,
        injector: Injector,
    ) {
        super();

        setTimeout(() => {
            this.store$ = injector.get(Store);
        });

        Sentry.init({
            dsn,
        });
    }

    handleError(error: any): void {
        const chunkFailedMessage = /^Loading chunk/;

        if (chunkFailedMessage.test(error.message)) {
            window.location.reload();

            return;
        }

        combineLatest([
            this.store$.select(currentUserSelector),
            this.store$.select(currentUserSelector),
        ]).pipe(
            take(1),
        ).subscribe(([commonUser, adminUser]) => {
            const user = commonUser || adminUser || null;
            Sentry.captureException(error.originalError || error, {
                user: user ? {
                    email: user.email,
                    username: `${user.firstName} ${user.lastName}`,
                    id: user?.id,
                    role: user?.role,
                } : undefined,
            });
        });

        super.handleError(error);
    }
}
