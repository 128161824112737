import { createReducer, on } from '@ngrx/store';
import * as moment from 'moment';

import {
    clearSharedDataFiltersAction,
    setSharedDataFiltersAction,
    setSharedDataFiltersDayAction,
    setSharedDataFiltersProjectAction,
    setSharedDataFiltersTaskAction,
    setSharedDataFiltersUserAction,
} from '@app-client/store/shared-data-filters/actions/shared-data-filters.action';
import { SharedDataFiltersStateInterface } from '@app-client/store/shared-data-filters/shared-data-filters-state.interface';
import { deleteUserSuccessAction } from '@app-client/store/users/actions/users.action';


const initialState: SharedDataFiltersStateInterface = {
    project: null,
    user: null,
    day: moment().clone().startOf('day').toISOString(),
    task: null,
};

const reducer = createReducer(
    initialState,
    on(
        setSharedDataFiltersAction,
        (state, action) => ({
            ...state,
            user: action.user,
            project: action.project,
            task: action.task,
        }),
    ),

    on(
        setSharedDataFiltersUserAction,
        (state, action) => ({
            ...state,
            user: action.user,
        }),
    ),

    on(
        setSharedDataFiltersProjectAction,
        (state, action) => ({
            ...state,
            project: action.project,
        }),
    ),

    on(
        setSharedDataFiltersTaskAction,
        (state, action) => ({
            ...state,
            task: action.task,
        }),
    ),

    on(
       setSharedDataFiltersDayAction,
        (state, action) => ({
            ...state,
            day: action.day,
        }),
    ),

    on(
        clearSharedDataFiltersAction,
        (state) => ({
            ...state,
            user: null,
            project: null,
            task: null,
        }),
    ),
    on(
        deleteUserSuccessAction,
        (state, action) => {
            if (state.user?.id === action.id) {
                return {
                    ...state,
                    user: {
                        ...state.user,
                        archivedAt: moment().toISOString(),
                        fullName: `[ARCHIVED] ${state.user.fullName}`,
                    },
                };
            }

            return state;
        },
    ),
);

export const sharedDataFiltersReducer = (state, action) => reducer(state, action);
