import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import { switchMap, take } from 'rxjs/operators';

import { PersistenceService } from '@app-client/shared/services/persistence.service';
import {
    currentLanguageChangedAction,
    currentLanguageChangedSuccessAction,
} from '@app-client/store/auth/actions/current-user-language.action';

import { environment } from '@env-client/environment';


@Injectable()
export class LanguageChangeEffect {
    currentLanguageChanged$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(currentLanguageChangedAction),
            switchMap(({ language, dispatchAfter }) => {
                const supportedLangs = environment.locales;
                const browserLang = window.navigator.languages.map(lang => lang.slice(0, 2)).find(lang => supportedLangs.includes(lang));

                const newLanguage = language || browserLang || environment.defaultLocale || 'en';

                this.persistenceService.set('Accept-Language', newLanguage);
                document.documentElement.setAttribute('lang', newLanguage);
                moment.locale(newLanguage);

                this.dateAdapter.setLocale(newLanguage);

                // observable used to wait translations loaded and display success message with the new language
                return this.translate.use(newLanguage).pipe(
                    take(1),
                    switchMap(() => {
                        return [
                            ...(dispatchAfter ? [dispatchAfter] : []),
                            currentLanguageChangedSuccessAction({ language: newLanguage }),
                        ];
                    }),
                );
            }),
        );
    });

    constructor(
        private actions$: Actions,
        private persistenceService: PersistenceService,
        private translate: TranslateService,
        private dateAdapter: DateAdapter<any>,
    ) {}
}
