import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CreateProjectInterface } from '@app-client/project/types/create-project.interface';
import { ProjectListQueryParamsInterface } from '@app-client/project/types/project-list-query-params.interface';
import { ProjectInterface } from '@app-client/project/types/project.interface';
import { PageResponseInterface } from '@app-shared/types/page-response.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';

import { environment } from '@env-client/environment';


@Injectable()
export class ProjectsService{
    constructor(private http: HttpClient) {}

    getProjectsList(queryParams: ProjectListQueryParamsInterface): Observable<PageResponseInterface<ProjectInterface>> {
        let params = new HttpParams()
            .set('orderBy', queryParams.orderBy)
            .set('orderDirection', queryParams.orderDirection)
            .set('page', queryParams.page.toString())
            .set('limit', (queryParams.limit || 10).toString());
        if ((queryParams.search || '').trim()) {
            params = params.set('search', (queryParams.search || '').trim());
        }
        if (queryParams.status !== undefined) {
            params = params.set('status', queryParams.status.toString());
        }
        if (queryParams.searchByUser !== undefined && queryParams.searchByUser !== null) {
            const users = (Array.isArray(queryParams.searchByUser) ? queryParams.searchByUser : [queryParams.searchByUser])
            .filter(user => user !== 'undefined');
            if (users.filter(Boolean).length > 0) {
                users.forEach((user) => {
                    params = params.append('searchByUser[]', user);
                });
            }
        }
        if (queryParams._excludeObserved !== undefined) {
            params = params.set('_excludeObserved', queryParams._excludeObserved.toString());
        }
        if (queryParams.showArchivedMembers !== undefined) {
            params = params.set('showArchivedMembers', queryParams.showArchivedMembers.toString());
        }
        if (queryParams.whereImManagerOrObserver !== undefined) {
            params = params.set('whereImManagerOrObserver', queryParams.whereImManagerOrObserver.toString());
        }
        if (queryParams.whereImManager !== null && queryParams.whereImManager !== undefined) {
            params = params.set('whereImManager', queryParams.whereImManager.toString());
        }
        if (queryParams.projectSpace !== undefined && queryParams.projectSpace !== null) {
            const projectSpaces = (Array.isArray(queryParams.projectSpace) ? queryParams.projectSpace : [queryParams.projectSpace])
            .filter(projectSpace => projectSpace !== 'undefined');
            if (projectSpaces.filter(Boolean).length > 0) {
                projectSpaces.forEach((projectSpace) => {
                    params = params.append('projectSpace[]', projectSpace);
                });
            }
        }
        if (queryParams.archived !== undefined) {
            params = params.set('archived', queryParams.archived.toString());
        }
        if (queryParams.canUserWrite !== undefined) {
            params = params.set('canUserWrite', queryParams.canUserWrite);
        }
        if (queryParams.includeProjectRoleOfCurrentUser !== undefined) {
            params = params.set('includeProjectRoleOfCurrentUser', queryParams.includeProjectRoleOfCurrentUser ? '1' : '0');
        }
        if (queryParams.includePermissions !== undefined) {
            params = params.set('includePermissions', queryParams.includePermissions.toString());
        }
        if (undefined !== queryParams.customer) {
            params = params.set('searchByCustomer', queryParams.customer);
        }
        if (queryParams.whereImHead !== undefined) {
            params = params.set('whereImHead', queryParams.whereImHead.toString());
        }

        return this.http.get<ResponseInterface<PageResponseInterface<ProjectInterface>>>(
            environment.apiBaseUrl + '/projects', { params }
        ).pipe(
            map((x) => {
                return x.body;
            }),
        );
    }

    getProject(id: string, includePermissions?: 0 | 1): Observable<ResponseInterface<ProjectInterface>> {
        let params = new HttpParams();
        if (!!includePermissions) {
            params = params.set('includePermissions', includePermissions.toString());
        }
        return this.http.get<ResponseInterface<ProjectInterface>>(environment.apiBaseUrl + '/projects/' + id, { params });
    }

    createProject(project: CreateProjectInterface): Observable<ResponseInterface<ProjectInterface>> {
        return this.http.post<ResponseInterface<ProjectInterface>>(environment.apiBaseUrl + '/projects', project);
    }

    updateProject(id: string, project: CreateProjectInterface): Observable<ResponseInterface<ProjectInterface>> {
        return this.http.patch<ResponseInterface<ProjectInterface>>(environment.apiBaseUrl + '/projects/' + id, project);
    }

    archiveProject(id: string): Observable<ResponseInterface<null>> {
        return this.http.delete<ResponseInterface<null>>(environment.apiBaseUrl + '/projects/' + id);
    }

    restoreProject(id: string): Observable<ResponseInterface<null>> {
        return this.http.patch<ResponseInterface<null>>(environment.apiBaseUrl + '/projects/' + id + '/restore', {});
    }
}
