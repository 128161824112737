import { ActionReducer, createReducer, on } from '@ngrx/store';

import { OnboardingDataStatusEnum } from '@app-client/shared/types/onboarding-data-status.enum';
import {
    checkCompanyOnboardingDataSuccessAction,
    deleteCompanyOnboardingDataAction,
    deleteCompanyOnboardingDataFailedAction,
    deleteCompanyOnboardingDataSuccessAction,
    disableDailyResultSandbox,
    enableDailyResultSandbox,
    endTourAction,
    nextTourStepAction,
    onboardingStatusRestartedAction,
    sendOnboardingStatusFinishedAction,
    sendOnboardingStatusFinishedFailedAction,
    sendOnboardingStatusFinishedSuccessAction,
    sendOnboardingStatusStartedAction,
    sendOnboardingStatusStartedFailedAction,
    sendOnboardingStatusStartedSuccessAction,
    startTourSectionAction,
} from '@app-client/store/tour/actions/tour.action';
import { TourStateInterface } from '@app-client/store/tour/tour.state.interface';
import { localStorageSync } from 'ngrx-store-localstorage';


const initialState: TourStateInterface = {
    currentStep: null,
    currentSection: null,
    isInProgress: false,
    isTutorialCompleted: true,
    isOnboardingStatusLoading: false,
    onboardingDataStatus: OnboardingDataStatusEnum.NOT_LOADED,
    isOnboardingDataDeleting: false,
    isDailyResultSandboxDisabled: false,
};

const reducer = createReducer(
    initialState,
    on(
        startTourSectionAction,
        (state, action) => ({
            ...state,
            currentSection: action.section,
            currentStep: 0,
            isInProgress: true,
            isTutorialCompleted: false,
        }),
    ),
    on(
        nextTourStepAction,
        (state) => ({
            ...state,
            currentStep: (+state.currentStep + 1)
        }),
    ),
    on(
        endTourAction,
        (state) => ({
            ...state,
            isInProgress: false,
            isTutorialCompleted: true,
        })
    ),
    on(
        sendOnboardingStatusStartedAction,
        (state) => ({
            ...state,
            isInProgress: false,
            isTutorialCompleted: false,
            isOnboardingStatusLoading: true,
        })
    ),
    on(
        onboardingStatusRestartedAction,
        (state) => ({
            ...state,
            isInProgress: true,
            isTutorialCompleted: false,
            isOnboardingStatusLoading: false,
        })
    ),
    on(sendOnboardingStatusStartedSuccessAction,
        (state) => ({
            ...state,
            isInProgress: true,
            isTutorialCompleted: false,
            isOnboardingStatusLoading: false,
        })
    ),
    on(
        sendOnboardingStatusStartedFailedAction,
        (state) => ({
            ...state,
            currentSection: null,
            currentStep: null,
            isInProgress: false,
            isTutorialCompleted: true,
            isOnboardingStatusLoading: false,
        })
    ),
    on(
        sendOnboardingStatusFinishedAction,
        (state) => ({
            ...state,
            isOnboardingStatusLoading: false,
        })
    ),
    on(
        sendOnboardingStatusFinishedSuccessAction,
        (state) => ({
            ...state,
            isOnboardingStatusLoading: false,
        })
    ),
    on(
        sendOnboardingStatusFinishedFailedAction,
        (state) => ({
            ...state,
            currentSection: null,
            currentStep: null,
            isInProgress: false,
            isTutorialCompleted: true,
            isOnboardingStatusLoading: false,
        })
    ),
    on(
        checkCompanyOnboardingDataSuccessAction,
        (state, action) => ({
            ...state,
            onboardingDataStatus: action.status,
            isOnboardingStatusLoading: false,
        })
    ),
    on(
        deleteCompanyOnboardingDataAction,
        (state) => ({
            ...state,
            isOnboardingDataDeleting: true,
        })
    ),
    on(
        deleteCompanyOnboardingDataSuccessAction,
        deleteCompanyOnboardingDataFailedAction,
        (state) => ({
            ...state,
            isOnboardingDataDeleting: false,
        })
    ),
    on(
        disableDailyResultSandbox,
        (state) => ({
            ...state,
            isDailyResultSandboxDisabled: true,
        }),
    ),
    on(
        enableDailyResultSandbox,
        (state) => ({
            ...state,
            isDailyResultSandboxDisabled: false,
        }),
    ),
);

export const tourReducer = (state, action) => reducer(state, action);
export const tourLocalStorageSyncReducer = (r: ActionReducer<any>): ActionReducer<any> => {
    return localStorageSync({
        keys: [
            {
                tour: [
                    'isDailyResultSandboxDisabled',
                ],
            },
        ],
        rehydrate: true,
    })(r);
};
