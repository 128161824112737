import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, delay, filter, map, switchMap, tap } from 'rxjs/operators';

import { marketplaceModulesGetEnabledAction } from '@app-client/store/marketplace/modules/actions/marketplace-modules.actions';
import {
    trelloAttachBoardToProjectAction,
    trelloAttachBoardToProjectFailureAction,
    trelloAttachBoardToProjectSuccessAction,
    trelloAuthRequestAction,
    trelloAuthRequestFailureAction,
    trelloAuthRequestSuccessAction,
    trelloAuthVerifyAction,
    trelloAuthVerifyFailureAction,
    trelloAuthVerifySuccessAction,
    trelloDetachBoardFromProjectAction,
    trelloDetachBoardFromProjectFailureAction,
    trelloDetachBoardFromProjectSuccessAction,
    trelloGetBoardColumnsAction,
    trelloGetBoardColumnsFailureAction,
    trelloGetBoardColumnsSuccessAction,
    trelloGetProjectColumnsAction,
    trelloGetProjectColumnsFailureAction,
    trelloGetProjectColumnsSuccessAction,
    trelloGetProjectStatusAction,
    trelloGetProjectStatusFailureAction,
    trelloGetProjectStatusSuccessAction,
    trelloGetUserBoardsAction,
    trelloGetUserBoardsFailureAction,
    trelloGetUserBoardsSuccessAction,
    trelloGetUserTokenDataAction,
    trelloGetUserTokenDataFailureAction,
    trelloGetUserTokenDataSuccessAction,
    trelloLogoutAction,
    trelloLogoutFailureAction,
    trelloLogoutSuccessAction,
    trelloModuleDisabledAction,
    trelloStartSyncAction,
    trelloStartSyncFailureAction,
    trelloStartSyncSuccessAction,
    trelloStopSyncAction,
    trelloStopSyncFailureAction,
    trelloStopSyncSuccessAction,
    trelloUpdateProjectColumnSettingsAction,
    trelloUpdateProjectColumnSettingsFailureAction,
    trelloUpdateProjectColumnSettingsSuccessAction,
} from '@app-client/store/trello/actions/trello.action';
import { TrelloService } from '@app-client/store/trello/services/trello.service';
import { TrelloErrorsEnum } from '@app-client/store/trello/trello-errors.enum';
import { TrelloProjectSyncStatusEnum } from '@app-client/trello/types/trello-project-status.interface';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class TrelloEffect {
    trelloAuthRequest$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloAuthRequestAction),
            switchMap(({ redirectUrl }) => {
                return this.trelloService.authRequest(redirectUrl).pipe(
                    map(({ url }) => {
                        return trelloAuthRequestSuccessAction({ url });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        if (httpErrorResponse.status === 404) {
                            return of(trelloModuleDisabledAction());
                        }

                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(trelloAuthRequestFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    trelloAuthRequestSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloAuthRequestSuccessAction),
            tap(({ url }) => {
                window.location.assign(url);
            }),
        );
    }, { dispatch: false });

    trelloAuthRequestFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloAuthRequestFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('TRELLO.AUTH.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: true });

    trelloAuthVerify$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloAuthVerifyAction),
            switchMap(({ token }) => {
                return this.trelloService.authVerify(token).pipe(
                    map(() => {
                        return trelloAuthVerifySuccessAction();
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        if (httpErrorResponse.status === 404) {
                            return of(trelloModuleDisabledAction());
                        }

                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(trelloAuthVerifyFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    trelloAuthVerifySuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloAuthVerifySuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('TRELLO.AUTH.SUCCESS.MESSAGE'),
                    this.translate.instant('TRELLO.AUTH.SUCCESS.TITLE'),
                );
            }),
            map(() => {
                return trelloGetUserTokenDataAction();
            }),
        );
    });

    trelloAuthVerifyFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloAuthVerifyFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('TRELLO.AUTH.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    trelloGetUserTokenData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloGetUserTokenDataAction),
            switchMap(() => {
                return this.trelloService.getUserTokenData().pipe(
                    map((userTokenData) => {
                        return trelloGetUserTokenDataSuccessAction({ userTokenData });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        if (httpErrorResponse.status === 404) {
                            return of(trelloModuleDisabledAction());
                        }

                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(trelloGetUserTokenDataFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    trelloGetUserTokenDataFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloGetUserTokenDataFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('TRELLO.GET_USER_TOKEN_DATA.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    trelloLogout$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloLogoutAction),
            switchMap(() => {
                return this.trelloService.logout().pipe(
                    map(() => {
                        return trelloLogoutSuccessAction();
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        if (httpErrorResponse.status === 404) {
                            return of(trelloModuleDisabledAction());
                        }

                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(trelloLogoutFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    trelloLogoutSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloLogoutSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('TRELLO.LOGOUT.SUCCESS.MESSAGE'),
                    this.translate.instant('TRELLO.LOGOUT.SUCCESS.TITLE'),
                );
            }),
            map(() => {
                return trelloGetUserTokenDataAction();
            }),
        );
    });

    trelloLogoutFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloLogoutFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('TRELLO.LOGOUT.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    trelloGetProjectStatus$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloGetProjectStatusAction),
            switchMap(({ projectId }) => {
                return this.trelloService.getProjectStatus(projectId).pipe(
                    map((projectStatus) => {
                        return trelloGetProjectStatusSuccessAction({ projectStatus, projectId });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        if (httpErrorResponse.status === 404) {
                            return of(trelloModuleDisabledAction());
                        }

                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(trelloGetProjectStatusFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    trelloGetProjectStatusSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloGetProjectStatusSuccessAction),
            switchMap(({ projectId, projectStatus }) => {
                if (projectStatus?.syncStatus === TrelloProjectSyncStatusEnum.IN_PROGRESS) {
                    return of(trelloGetProjectStatusAction({ projectId })).pipe(delay(10 * 1000));
                }

                return [];
            }),
        );
    });

    trelloGetProjectStatusFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloGetProjectStatusFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('TRELLO.GET_PROJECT_STATUS.ERROR.TITLE'),
                );
            }),
            filter(({ backendErrors }) => {
                return TrelloErrorsEnum.TOKEN_NOT_FOUND === backendErrors.errors[0];
            }),
            map(() => {
                return trelloGetUserTokenDataAction();
            }),
        );
    });

    trelloGetUserBoards$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloGetUserBoardsAction),
            switchMap(() => {
                return this.trelloService.getUserTrelloBoards().pipe(
                    map((boards) => {
                        return trelloGetUserBoardsSuccessAction({ boards });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        if (httpErrorResponse.status === 404) {
                            return of(trelloModuleDisabledAction());
                        }

                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(trelloGetUserBoardsFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    trelloGetUserBoardsFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloGetUserBoardsFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('TRELLO.GET_USER_BOARDS.ERROR.TITLE'),
                );
            }),

            filter(({ backendErrors }) => {
                return TrelloErrorsEnum.TOKEN_NOT_FOUND === backendErrors.errors[0];
            }),
            map(() => {
                return trelloGetUserTokenDataAction();
            }),
        );
    });

    trelloGetBoardColumns$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloGetBoardColumnsAction),
            switchMap(({ boardId }) => {
                return this.trelloService.getBoardColumnsByUserBoard(boardId).pipe(
                    map((columns) => {
                        return trelloGetBoardColumnsSuccessAction({ columns });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        if (httpErrorResponse.status === 404) {
                            return of(trelloModuleDisabledAction());
                        }

                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(trelloGetBoardColumnsFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    trelloGetBoardColumnsFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloGetBoardColumnsFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('TRELLO.GET_BOARD_COLUMNS.ERROR.TITLE'),
                );
            }),
            switchMap(({ backendErrors }) => {
                const actions = [];
                const errorCode = backendErrors.errors[0];

                if (errorCode === TrelloErrorsEnum.TOKEN_NOT_FOUND) {
                    actions.push(trelloGetUserTokenDataAction());
                }
                if ([TrelloErrorsEnum.BOARD_NOT_FOUND, TrelloErrorsEnum.CLIENT_ACCESS_DENIED].includes(errorCode)) {
                    actions.push(trelloGetUserBoardsAction());
                }

                return actions;
            }),
        );
    });

    trelloGetProjectColumns$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloGetProjectColumnsAction),
            switchMap(({ projectId }) => {
                return this.trelloService.getBoardColumnsByProject(projectId).pipe(
                    map((columns) => {
                        return trelloGetProjectColumnsSuccessAction({ columns });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        if (httpErrorResponse.status === 404) {
                            return of(trelloModuleDisabledAction());
                        }

                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(trelloGetProjectColumnsFailureAction({ backendErrors, projectId }));
                    }),
                );
            }),
        );
    });

    trelloGetProjectColumnsFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloGetProjectColumnsFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('TRELLO.GET_PROJECT_COLUMNS.ERROR.TITLE'),
                );
            }),
            switchMap(({ backendErrors, projectId }) => {
                const actions = [];

                actions.push(trelloGetProjectStatusAction({ projectId }));

                if (TrelloErrorsEnum.TOKEN_NOT_FOUND === backendErrors.errors[0]) {
                    actions.push(trelloGetUserTokenDataAction());
                }

                return actions;
            }),
        );
    });

    trelloAttachBoardToProject$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloAttachBoardToProjectAction),
            switchMap(({ projectId, boardId, columnSettings }) => {
                return this.trelloService.attachTrelloBoardToProject(
                    projectId,
                    boardId,
                    columnSettings.columnFilteringType,
                    columnSettings.columns,
                ).pipe(
                    map(() => {
                        return trelloAttachBoardToProjectSuccessAction({ projectId });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        if (httpErrorResponse.status === 404) {
                            return of(trelloModuleDisabledAction());
                        }

                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(trelloAttachBoardToProjectFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    trelloAttachBoardToProjectSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloAttachBoardToProjectSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('TRELLO.ATTACH_BOARD_TO_PROJECT.SUCCESS.MESSAGE'),
                    this.translate.instant('TRELLO.ATTACH_BOARD_TO_PROJECT.SUCCESS.TITLE'),
                );
            }),
            map(({ projectId }) => {
                return trelloGetProjectStatusAction({ projectId });
            }),
        );
    });

    trelloAttachBoardToProjectFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloAttachBoardToProjectFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('TRELLO.ATTACH_BOARD_TO_PROJECT.ERROR.TITLE'),
                );
            }),
            switchMap(({ backendErrors }) => {
                const actions = [];
                const errorCode = backendErrors.errors[0];

                if (TrelloErrorsEnum.TOKEN_NOT_FOUND === errorCode) {
                    actions.push(trelloGetUserTokenDataAction());
                }
                if (TrelloErrorsEnum.BOARD_NOT_FOUND === errorCode) {
                    actions.push(trelloGetUserBoardsAction());
                }

                return actions;
            }),
        );
    });

    trelloUpdateColumnSettings$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloUpdateProjectColumnSettingsAction),
            switchMap(({ projectId, columnSettings }) => {
                return this.trelloService.updateColumnSettings(projectId, columnSettings).pipe(
                    map(() => {
                        return trelloUpdateProjectColumnSettingsSuccessAction({ projectId });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        if (httpErrorResponse.status === 404) {
                            return of(trelloModuleDisabledAction());
                        }

                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(trelloUpdateProjectColumnSettingsFailureAction({ projectId, backendErrors }));
                    }),
                );
            }),
        );
    });

    trelloUpdateColumnSettingsSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloUpdateProjectColumnSettingsSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('TRELLO.UPDATE_COLUMN_SETTINGS.SUCCESS.MESSAGE'),
                    this.translate.instant('TRELLO.UPDATE_COLUMN_SETTINGS.SUCCESS.TITLE'),
                );
            }),
            map(({ projectId }) => {
                return trelloGetProjectStatusAction({ projectId });
            }),
        );
    });

    trelloUpdateColumnSettingsFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloUpdateProjectColumnSettingsFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('TRELLO.UPDATE_COLUMN_SETTINGS.ERROR.TITLE'),
                );
            }),
            switchMap(({ backendErrors, projectId }) => {
                const actions = [];
                const errorCode = backendErrors.errors[0];

                if (TrelloErrorsEnum.TOKEN_NOT_FOUND === errorCode) {
                    actions.push(trelloGetUserTokenDataAction());
                }
                if (TrelloErrorsEnum.BOARD_NOT_FOUND === errorCode) {
                    actions.push(trelloGetProjectStatusAction({ projectId }));
                }

                return actions;
            }),
        );
    });

    trelloDetachBoardFromProject$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloDetachBoardFromProjectAction),
            switchMap(({ projectId }) => {
                return this.trelloService.detachBoardFromProject(projectId).pipe(
                    map(() => {
                        return trelloDetachBoardFromProjectSuccessAction({ projectId });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        if (httpErrorResponse.status === 404) {
                            return of(trelloModuleDisabledAction());
                        }

                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(trelloDetachBoardFromProjectFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    trelloDetachBoardFromProjectSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloDetachBoardFromProjectSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('TRELLO.DETACH_BOARD_FROM_PROJECT.SUCCESS.MESSAGE'),
                    this.translate.instant('TRELLO.DETACH_BOARD_FROM_PROJECT.SUCCESS.TITLE'),
                );
            }),
            switchMap(({ projectId }) => {
                return [
                    trelloGetProjectStatusAction({ projectId }),
                    trelloGetUserTokenDataAction(),
                ];
            }),
        );
    });

    trelloDetachBoardFromProjectFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloDetachBoardFromProjectFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('TRELLO.DETACH_BOARD_FROM_PROJECT.ERROR.TITLE'),
                );
            }),
            filter(({ backendErrors }) => {
                return TrelloErrorsEnum.TOKEN_NOT_FOUND === backendErrors.errors[0];
            }),
            map(() => {
                return trelloGetUserTokenDataAction();
            }),
        );
    });

    trelloStartSync$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloStartSyncAction),
            switchMap(({ projectId }) => {
                return this.trelloService.startSync(projectId).pipe(
                    map(() => {
                        return trelloStartSyncSuccessAction({ projectId });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        if (httpErrorResponse.status === 404) {
                            return of(trelloModuleDisabledAction());
                        }

                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(trelloStartSyncFailureAction({ projectId, backendErrors }));
                    }),
                );
            }),
        );
    });

    trelloStartSyncSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloStartSyncSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('TRELLO.START_SYNC.SUCCESS.MESSAGE'),
                    this.translate.instant('TRELLO.START_SYNC.SUCCESS.TITLE'),
                );
            }),
            map(({ projectId }) => {
                return trelloGetProjectStatusAction({ projectId });
            }),
        );
    });

    trelloStartSyncFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloStartSyncFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('TRELLO.START_SYNC.ERROR.TITLE'),
                );
            }),
            switchMap(({ backendErrors, projectId }) => {
                const actions = [];

                actions.push(trelloGetProjectStatusAction({ projectId }));

                if (TrelloErrorsEnum.TOKEN_NOT_FOUND === backendErrors.errors[0]) {
                    actions.push(trelloGetUserTokenDataAction());
                }

                return actions;
            }),
        );
    });

    trelloStopSync$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloStopSyncAction),
            switchMap(({ projectId }) => {
                return this.trelloService.stopSync(projectId).pipe(
                    map(() => {
                        return trelloStopSyncSuccessAction({ projectId });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        if (httpErrorResponse.status === 404) {
                            return of(trelloModuleDisabledAction());
                        }

                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };

                        return of(trelloStopSyncFailureAction({ backendErrors, projectId }));
                    }),
                );
            }),
        );
    });

    trelloStopSyncSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloStopSyncSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('TRELLO.STOP_SYNC.SUCCESS.MESSAGE'),
                    this.translate.instant('TRELLO.STOP_SYNC.SUCCESS.TITLE'),
                );
            }),
            map(({ projectId }) => {
                return trelloGetProjectStatusAction({ projectId });
            }),
        );
    });

    trelloStopSyncFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloStopSyncFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('TRELLO.STOP_SYNC.ERROR.TITLE'),
                );
            }),
            map(({ projectId }) => {
                return trelloGetProjectStatusAction({ projectId });
            }),
        );
    });

    trelloModuleDisabled$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trelloModuleDisabledAction),
            tap(() => {
                this.toastr.error(
                    this.translate.instant('TRELLO.MODULE_DISABLED_ERROR.MESSAGE'),
                    this.translate.instant('TRELLO.MODULE_DISABLED_ERROR.TITLE'),
                );
            }),
            map(() => {
                return marketplaceModulesGetEnabledAction();
            }),
        );
    });

    constructor(
        private actions$: Actions,
        private trelloService: TrelloService,
        private translate: TranslateService,
        private toastr: ToastrService,
    ) {}
}
