export enum ReportActionTypes {
    GET_REPORT = '[Report] Get Report',
    GET_REPORT_SUCCESS = '[Report] Get Report Success',
    GET_REPORT_FAILURE = '[Report] Get Report Fail',

    REQUEST_GOOGLE_SPREADSHEET_REPORT = '[Report] Request Google Spreadsheet Report',
    REQUEST_GOOGLE_SPREADSHEET_REPORT_SUCCESS = '[Report] Request Google Spreadsheet Report Success',
    REQUEST_GOOGLE_SPREADSHEET_REPORT_FAILURE = '[Report] Request Google Spreadsheet Report Fail',

    SAVE_REPORT_FILTERS = '[Report] Save report filters',

    CLEAR_REPORT_DATA = '[Report] Clear report data',

    SET_ADDITIONAL_FILTERS = '[Report] Set additional filters',
}
