import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'padStart',
})
export class PadStartPipe implements PipeTransform {
    transform(num: number, maxLength: number, fillString: string): string {
        return `${num}`.padStart(maxLength, fillString);
    }
}
