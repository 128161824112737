import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    CompanyConfigKaitenInterface,
    CompanyConfigScreenshotsInterface,
    CompanyConfigSignatureInterface
} from '@app-client/store/company/company-config.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';

import { environment } from '@env-client/environment';


@Injectable()
export class CompanyConfigService {
    constructor(private httpClient: HttpClient) {}

    getCompanyConfigScreenshots(): Observable<CompanyConfigScreenshotsInterface> {
        const url = environment.apiBaseUrl + '/company-settings/screenshots';

        return this.httpClient.get(url).pipe(map((response: ResponseInterface<CompanyConfigScreenshotsInterface>) => {
            return response.body;
        }));
    }

    updateCompanyConfigScreenshots(config: CompanyConfigScreenshotsInterface) {
        const url = environment.apiBaseUrl + '/company-settings/screenshots';

        return this.httpClient.post(url, config).pipe();
    }

    getCompanyConfigSignature(): Observable<CompanyConfigSignatureInterface> {
        const url = environment.apiBaseUrl + '/signature/settings';

        return this.httpClient.get(url).pipe(map((response: ResponseInterface<CompanyConfigSignatureInterface>) => {
            return response.body;
        }));
    }

    updateCompanyConfigSignature(config: CompanyConfigSignatureInterface) {
        const url = environment.apiBaseUrl + '/signature/settings';

        return this.httpClient.post(url, config).pipe();
    }

    getCompanyConfigKaiten(): Observable<CompanyConfigKaitenInterface> {
        const url = environment.apiBaseUrl + '/module/kaiten-sync/settings';

        return this.httpClient.get(url).pipe(map((response: ResponseInterface<CompanyConfigKaitenInterface>) => {
            return response.body;
        }));
    }

    updateCompanyConfigKaiten(config: CompanyConfigKaitenInterface) {
        const url = environment.apiBaseUrl + '/module/kaiten-sync/settings';

        return this.httpClient.post(url, config).pipe();
    }
}
