import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, filter, switchMap } from 'rxjs/operators';

import {
    ProjectTaskListQueryParamsInterface,
} from '@app-client/project/types/project-task-list-query-params.interface';
import { ProjectTaskInterface } from '@app-client/project/types/project-task.interface';
import { SelectorConstants } from '@app-client/shared/constants/selector.constants';
import { QueryArchivedEnum } from '@app-client/shared/types/query-archived.enum';
import { ProjectTasksService } from '@app-client/store/project-tasks/serivces/project-tasks.service';


export type TASKS_SELECTOR_ALL_ELEMENT_TYPE = 'ALL';
export const TASKS_SELECTOR_ALL_ELEMENT: TASKS_SELECTOR_ALL_ELEMENT_TYPE = 'ALL';

@UntilDestroy()
@Component({
    selector: 'tasks-selector',
    templateUrl: './tasks-selector.component.html',
    styleUrls: ['./tasks-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: TasksSelectorComponent
        }
    ],
})
export class TasksSelectorComponent implements ControlValueAccessor, AfterViewInit {
    readonly QueryArchivedEnum = QueryArchivedEnum;
    readonly TASKS_SELECTOR_ALL_ELEMENT = TASKS_SELECTOR_ALL_ELEMENT;

    @Input()
    isMultiple = true;
    @Input()
    emptyIsAll = false;
    @Input()
    set archived(archived: QueryArchivedEnum) {
        this.isAllowArchived = QueryArchivedEnum.INCLUDE === archived;
        this.listQueryParams$.next({
            ...this.listQueryParams$.value,
            archived,
            page: 1,
        });
    }
    @Input()
    set project(project: Array<string>) {
        this.listQueryParams$.next({
            ...this.listQueryParams$.value,
            project,
            page: 1,
        });
    }

    private onChange: any;

    isDisabled = false;

    get selected(): Array<ProjectTaskInterface | TASKS_SELECTOR_ALL_ELEMENT_TYPE> {
        return this.selectedStore;
    }
    set selected(value: Array<ProjectTaskInterface | TASKS_SELECTOR_ALL_ELEMENT_TYPE>) {
        if (false === this.isMultiple) {
            this.search('');
        }
        this.selectedStore = value;
        this.onChange(value);
    }
    private selectedStore = [] as Array<ProjectTaskInterface | TASKS_SELECTOR_ALL_ELEMENT_TYPE>;
    list: Array<ProjectTaskInterface | TASKS_SELECTOR_ALL_ELEMENT_TYPE>;
    isHasMore = true;
    isLoading = false;
    listQueryParams$ = new BehaviorSubject<ProjectTaskListQueryParamsInterface>({
        search: '',
        orderBy: 'name',
        orderDirection: 'ASC',
        page: 1,
        limit: 10,
        archived: QueryArchivedEnum.EXCLUDE,
        project: [],
    });
    isAllowArchived = false;

    constructor(
        private projectTasksService: ProjectTasksService,
        private changeDetectorRef: ChangeDetectorRef,
        private toastr: ToastrService,
        private translate: TranslateService,
    ) {}

    ngAfterViewInit(): void {
        this.listQueryParams$.pipe(
            untilDestroyed(this),
            debounceTime(SelectorConstants.DEBOUNCE_TIME),
            filter(() => {
                return false === this.isDisabled;
            }),
            switchMap((queryParams) => {
                if (1 === queryParams.page) {
                    this.list = true === this.emptyIsAll ? [TASKS_SELECTOR_ALL_ELEMENT] : [];
                }
                this.isLoading = true;
                return this.projectTasksService.getProjectTasksList(queryParams);
            }),
        ).subscribe((response) => {
            this.isHasMore = response.page < response.totalPages;
            this.list = [
                ...this.list,
                ...response.items,
            ];
            this.isLoading = false;
            this.changeDetectorRef.detectChanges();
        },
        (error) => {
            this.isHasMore = false;
            this.toastr.error(
                error,
                this.translate.instant('PROJECT_SPACES.NOTIFICATIONS.GET_CURRENT_FAILURE.TITLE'),
            );
            this.isLoading = false;
            this.changeDetectorRef.detectChanges();
        });
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {}

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    writeValue(obj: any): void {
        this.selectedStore = obj;
    }

    loadMore(): void {
        if (false === this.isHasMore) {
            return;
        }

        this.listQueryParams$.next({
            ...this.listQueryParams$.value,
            page: this.listQueryParams$.value.page + 1,
        });
    }

    toggleArchived(): void {
        this.listQueryParams$.next({
            ...this.listQueryParams$.value,
            archived: QueryArchivedEnum.INCLUDE === this.listQueryParams$.value.archived
                ? QueryArchivedEnum.EXCLUDE
                : QueryArchivedEnum.INCLUDE,
            page: 1,
        });
    }

    search(str: string): void {
        if (str !== this.listQueryParams$.value.search) {
            this.listQueryParams$.next({
                ...this.listQueryParams$.value,
                search: str,
                page: 1,
            });
        }
    }

    searchFn(): boolean {
        return true;
    }

    compareWith(a: any, b: any): boolean {
        return a?.id === b?.id;
    }
}

