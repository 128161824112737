import { createReducer, on } from '@ngrx/store';

import {
    marketplaceModulesGetActivatedUsersAction,
    marketplaceModulesGetActivatedUsersFailureAction,
    marketplaceModulesGetActivatedUsersSuccessAction,
    marketplaceModulesGetDetailsAction,
    marketplaceModulesGetDetailsSuccessAction,
    marketplaceModulesGetEnabledAction,
    marketplaceModulesGetEnabledFailureAction,
    marketplaceModulesGetEnabledSuccessAction,
    marketplaceModulesGetFreeTariffsAction,
    marketplaceModulesGetFreeTariffsSuccessAction,
    marketplaceModulesGetListAction,
    marketplaceModulesGetListFailureAction,
    marketplaceModulesGetListSuccessAction,
    marketplaceModulesInstallAction,
    marketplaceModulesInstallFailureAction,
    marketplaceModulesInstallSuccessAction,
    marketplaceModulesUninstallAction,
    marketplaceModulesUninstallFailureAction,
    marketplaceModulesUninstallSuccessAction,
    marketplaceModulesUpdatePlanAction,
    marketplaceModulesUpdatePlanFailureAction,
    marketplaceModulesUpdatePlanSuccessAction,
    marketplaceModulesUpdateSettingsAction,
    marketplaceModulesUpdateSettingsSuccessAction,
} from '@app-client/store/marketplace/modules/actions/marketplace-modules.actions';
import { MarketplaceModulesStateInterface } from '@app-client/store/marketplace/modules/marketplace-modules-state.interface';
import { AppModulesEnum } from '@app-shared/types/app-modules.enum';


const initialState: MarketplaceModulesStateInterface = {
    isModulesLoading: false,
    modules: [],
    isDetailsLoading: false,
    details: null,
    isActivatedUsersLoading: false,
    activatedUsers: [],
    isInstallInProgress: false,
    isUninstallInProgress: false,
    isUpdatePlanInProgress: false,
    isUpdateSettingsInProgress: false,
    enabled: [],
    isEnabledLoading: false,
    freeTariffs: null,
};

const reducer = createReducer(
    initialState,
    on(
        marketplaceModulesGetListAction,
        (state) => ({
            ...state,
            isModulesLoading: true,
        }),
    ),
    on(
        marketplaceModulesGetListSuccessAction,
        (state, action) => ({
            ...state,
            isModulesLoading: false,
            modules: [...action.modules].sort((arg1, arg2) => {
                if (arg1.id === AppModulesEnum.SCREENSHOTS) {
                    return -1;
                }
                if (arg2.id === AppModulesEnum.SCREENSHOTS) {
                    return 1;
                }
                return 0;
            }),
        }),
    ),
    on(
        marketplaceModulesGetListFailureAction,
        (state) => ({
            ...state,
            isModulesLoading: false,
            modules: [],
        }),
   ),
    on(
        marketplaceModulesGetDetailsAction,
        (state) => ({
            ...state,
            isDetailsLoading: true,
        }),
    ),
    on(
        marketplaceModulesGetDetailsSuccessAction,
        (state, action) => ({
            ...state,
            isDetailsLoading: false,
            details: action.details,
        }),
    ),
    on(
        marketplaceModulesGetListFailureAction,
        (state) => ({
            ...state,
            isDetailsLoading: false,
            details: null,
        }),
    ),
    on(
        marketplaceModulesInstallAction,
        (state) => ({
            ...state,
            isInstallInProgress: true,
        }),
    ),
    on(
        marketplaceModulesInstallSuccessAction,
        (state, action) => {
            const newState = {
                ...state,
                isInstallInProgress: false,
            };
            if (state.details?.id === action.moduleId) {
                newState.details = {
                    ...state.details,
                    companyModule: action.companyModule,
                };
            }
            newState.modules = state.modules.map((module) => {
                if (module.id !== action.moduleId) {
                    return module;
                } else {
                    return {
                        ...module,
                        companyModule: action.companyModule,
                    };
                }
            });

            return newState;
        },
    ),
    on(
        marketplaceModulesInstallFailureAction,
        (state) => ({
            ...state,
            isInstallInProgress: false,
        }),
    ),
    on(
        marketplaceModulesUninstallAction,
        (state) => ({
            ...state,
            isUninstallInProgress: true,
        }),
    ),
    on(
        marketplaceModulesUninstallSuccessAction,
        (state, action) => {
            const newState = {
                ...state,
                isUninstallInProgress: false,
            };
            if (state.details?.id === action.moduleId) {
                newState.details = {
                    ...state.details,
                    companyModule: null,
                };
                newState.activatedUsers = [];
            }
            newState.modules = state.modules.map((module) => {
                if (module.id !== action.moduleId) {
                    return module;
                } else {
                    return {
                        ...module,
                        companyModule: null,
                    };
                }
            });

            return newState;
        },
    ),
    on(
        marketplaceModulesUninstallFailureAction,
        (state) => ({
            ...state,
            isUninstallInProgress: false,
        }),
    ),

    on(
        marketplaceModulesUpdatePlanAction,
        (state) => ({
            ...state,
            isUpdatePlanInProgress: true,
        }),
    ),

    on(
        marketplaceModulesUpdatePlanSuccessAction,
        (state, action) => {
            const newState = {
                ...state,
                isUpdatePlanInProgress: false,
            };
            if (state.details?.id === action.moduleId) {
                newState.details = {
                    ...state.details,
                    companyModule: action.companyModule,
                };
            }
            newState.modules = state.modules.map((module) => {
                if (module.id !== action.moduleId) {
                    return module;
                } else {
                    return {
                        ...module,
                        companyModule: action.companyModule,
                    };
                }
            });

            return newState;
        },
    ),

    on(
        marketplaceModulesUpdatePlanFailureAction,
        (state) => ({
            ...state,
            isUpdatePlanInProgress: false,
        }),
    ),

    on(
        marketplaceModulesUpdateSettingsAction,
        (state) => ({
            ...state,
            isUpdateSettingsInProgress: true,
        }),
    ),
    on(
        marketplaceModulesUpdateSettingsSuccessAction,
        (state, action) => {
            const newState = {
                ...state,
                isUpdateSettingsInProgress: false,
            };
            if (state.details?.id === action.moduleId) {
                newState.details = {
                    ...state.details,
                    companyModule: action.companyModule,
                };
                newState.activatedUsers = [
                    ...newState.activatedUsers,
                    ...action.usersToAdd,
                ].filter((activatedUser) => {
                    return action.usersToDelete.every((userToDelete) => {
                        return activatedUser.id !== userToDelete.id;
                    });
                });
            }
            newState.modules = state.modules.map((module) => {
                if (module.id !== action.moduleId) {
                    return module;
                } else {
                    return {
                        ...module,
                        companyModule: {
                            ...module.companyModule,
                            createdAt: action.companyModule.createdAt,
                            updatedAt: action.companyModule.updatedAt,
                        },
                    };
                }
            });

            return newState;
        },
    ),
    on(
        marketplaceModulesUpdatePlanFailureAction,
        (state) => ({
            ...state,
            isUpdateSettingsInProgress: false,
        }),
    ),
    on(
        marketplaceModulesGetActivatedUsersAction,
        (state) => ({
            ...state,
            isActivatedUsersLoading: true,
            activatedUsers: [],
        }),
    ),
    on(
        marketplaceModulesGetActivatedUsersSuccessAction,
        (state, action) => ({
            ...state,
            isActivatedUsersLoading: false,
            activatedUsers: action.activatedUsers,
        }),
    ),
    on(
        marketplaceModulesGetActivatedUsersFailureAction,
        (state) => ({
            ...state,
            isActivatedUsersLoading: false,
            activatedUsers: [],
        }),
    ),
    on(
        marketplaceModulesGetEnabledAction,
        (state) => ({
            ...state,
            isEnabledLoading: true,
        }),
    ),
    on(
        marketplaceModulesGetEnabledSuccessAction,
        (state, action) => ({
            ...state,
            isEnabledLoading: false,
            enabled: action.enabled,
        }),
    ),
    on(
        marketplaceModulesGetEnabledFailureAction,
        (state) => ({
            ...state,
            isEnabledLoading: false,
            enabled: [],
        }),
    ),
    on(
        marketplaceModulesGetFreeTariffsAction,
        (state) => ({
            ...state,
            freeTariffs: null,
        }),
    ),
    on(
        marketplaceModulesGetFreeTariffsSuccessAction,
        (state, action) => ({
            ...state,
            freeTariffs: action.freeTariffs,
        }),
    ),
);

export const marketplaceModulesReducer = (state, action) => reducer(state, action);
