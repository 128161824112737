import { createReducer, on } from '@ngrx/store';

import {
    yearlyReportGetListAction,
    yearlyReportGetListFailureAction,
    yearlyReportGetListSuccessAction,
} from '@app-client/store/marketplace/modules/actions/yearly-report.actions';
import { YearlyReportStateInterface } from '@app-client/store/marketplace/modules/yearly-report-state.interface';

const initialState: YearlyReportStateInterface = {
    reports: [],
    isReportsLoading: false
};

const reducer = createReducer(
    initialState,
    on(
        yearlyReportGetListAction,
        (state) => ({
            ...state,
            isReportsLoading: true
        }),
    ),
    on(
        yearlyReportGetListSuccessAction,
        (state, action) => ({
            ...state,
            reports: action.pageResponse.items,
            isReportsLoading: false
        }),
    ),
    on(
        yearlyReportGetListFailureAction,
        (state) => ({
            ...state,
            isReportsLoading: false,
            reports: [],
        }),
    )
);

export const yearlyReportReducer = (state, action) => reducer(state, action);
