import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as moment from 'moment';

import { PROJECTS_SELECTOR_ALL_ELEMENT } from '@app-client/shared/components/projects-selector/projects-selector.component';
import { TASKS_SELECTOR_ALL_ELEMENT } from '@app-client/shared/components/tasks-selector/tasks-selector.component';
import { currentUserSelector } from '@app-client/store/auth/selectors/auth.selectors';
import { SharedDataFiltersStateInterface } from '@app-client/store/shared-data-filters/shared-data-filters-state.interface';


export const sharedDataFiltersFeatureSelector = createFeatureSelector<SharedDataFiltersStateInterface>('sharedDataFilters');

export const sharedDataFiltersUserSelector = createSelector(
    sharedDataFiltersFeatureSelector,
    currentUserSelector,
    (state, currentUser) => state.user || currentUser,
);

export const sharedDataFiltersProjectSelector = createSelector(
    sharedDataFiltersFeatureSelector,
    state => state.project || PROJECTS_SELECTOR_ALL_ELEMENT,
);

export const sharedDataFiltersTaskSelector = createSelector(
    sharedDataFiltersFeatureSelector,
    state => state.task || PROJECTS_SELECTOR_ALL_ELEMENT,
);

export const sharedDataFiltersDaySelector = createSelector(
    sharedDataFiltersFeatureSelector,
    state => state.day,
);

export const sharedDataRequestParamsSelector = createSelector(
    sharedDataFiltersUserSelector,
    sharedDataFiltersProjectSelector,
    sharedDataFiltersTaskSelector,
    sharedDataFiltersDaySelector,
    currentUserSelector,
    (user, project, task, day, currentUser) => {
        const normalizedDay = day ? moment(day) : moment();
        if (null !== currentUser) {
            normalizedDay.tz(currentUser.timezone);
        }

        return {
            userId: user?.id,
            projectId: PROJECTS_SELECTOR_ALL_ELEMENT === project ? null : project.id,
            taskId: TASKS_SELECTOR_ALL_ELEMENT === task ? null : task.id,
            from: normalizedDay.clone().startOf('day'),
            to: normalizedDay.clone().add(1, 'day').startOf('day'),
        };
    }
);
