import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import {
    reportUserActivityExportGoogleSpreadsheetAction,
    reportUserActivityExportGoogleSpreadsheetFailureAction,
    reportUserActivityExportGoogleSpreadsheetSuccessAction,
    reportUserActivityGetReportAction,
    reportUserActivityGetReportFailureAction,
    reportUserActivityGetReportSuccessAction,
} from '@app-client/store/report/user-activity/actions/report-user-activity.actions';
import { ReportUserActivityService } from '@app-client/store/report/user-activity/services/report-user-activity.service';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class ReportUserActivityEffect {
    constructor(
        private actions$: Actions,
        private reportUsersActivityService: ReportUserActivityService,
        private translate: TranslateService,
        private toastr: ToastrService,
    ) {}

    getReportEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(reportUserActivityGetReportAction),
            switchMap(({ filters }) => {
                return this.reportUsersActivityService.getReport(filters).pipe(
                    map((report) => {
                        return reportUserActivityGetReportSuccessAction({ report, filters });
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse.error.message,
                            errors: httpErrorResponse.error.errors,
                        };

                        return of(reportUserActivityGetReportFailureAction({ backendErrors }));
                    }),
                );
            }),
        );
    });

    getReportSuccessEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(reportUserActivityGetReportSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('REPORT.NOTIFICATIONS.GET_REPORT.SUCCESS.MESSAGE'),
                    this.translate.instant('REPORT.NOTIFICATIONS.GET_REPORT.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    getReportFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(reportUserActivityGetReportFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('REPORT.NOTIFICATIONS.GET_REPORT.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    exportGoogleSpreadsheetEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(reportUserActivityExportGoogleSpreadsheetAction),
            switchMap(({ filters }) => {
                return this.reportUsersActivityService.exportGoogleSpreadsheet(filters).pipe(
                    map(() => {
                        return reportUserActivityExportGoogleSpreadsheetSuccessAction();
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse.error.message,
                            errors: httpErrorResponse.error.errors,
                        };

                        return of(reportUserActivityExportGoogleSpreadsheetFailureAction({ backendErrors }));
                    }),
                );
            })
        );
    });


    exportGoogleSpreadsheetSuccessEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(reportUserActivityExportGoogleSpreadsheetSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('REPORT.NOTIFICATIONS.REQUEST_GOOGLE_REPORT.SUCCESS.MESSAGE'),
                    this.translate.instant('REPORT.NOTIFICATIONS.REQUEST_GOOGLE_REPORT.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    exportGoogleSpreadsheetFailureEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(reportUserActivityExportGoogleSpreadsheetFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('REPORT.NOTIFICATIONS.REQUEST_GOOGLE_REPORT.ERROR.TITLE'),
                );
            }),
        );
    }, { dispatch: false });
}
