import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap } from 'rxjs/operators';

import { PersistenceService } from '@app-client/shared/services/persistence.service';
import { getCurrentUserAction } from '@app-client/store/auth/actions/get-current-user.action';
import { logoutAction } from '@app-client/store/auth/actions/login.action';
import { initLoggedAsAction, showLoggedAsAction } from '@app-client/store/logged-as/actions/logged-as.action';

import { environment as adminEnvironment } from '@env-admin/environment';


@Injectable()
export class LoggedAsEffect {
    initLoggedAs$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(initLoggedAsAction),
            switchMap(() => {
                const loggedAsName = this.persistenceService.get('loggedAsName');

                if (loggedAsName) {
                    return [
                        showLoggedAsAction({ showLoggedAsParams: { loggedAsName } }),
                    ];
                }

                return [];
            }),
        );
    });

    showLoggedAs$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(showLoggedAsAction),
            tap(({ showLoggedAsParams }) => {
                this.persistenceService.set('loggedAsName', showLoggedAsParams.loggedAsName);
                if (showLoggedAsParams.previousAdminCredentials) {
                    this.persistenceService.set('adminCredentials', showLoggedAsParams.previousAdminCredentials);
                }
            }),
        );
    }, { dispatch: false });

    logoutToPreviousAdmin$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(logoutAction),
            switchMap(() => {
                const loggedAsName = this.persistenceService.get('loggedAsName');
                const adminCredentials = this.persistenceService.get('adminCredentials');

                if (loggedAsName && adminCredentials) {
                    this.router.navigate(['/']);
                    this.persistenceService.set('credentials', adminCredentials);

                    this.persistenceService.remove('loggedAsName');
                    this.persistenceService.remove('adminCredentials');

                    return [
                        getCurrentUserAction(),
                        initLoggedAsAction(),
                    ];
                }

                return [];
            }),
        );
    });

    redirectToSuperAdmin$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(logoutAction),
            tap(() => {
                if (this.persistenceService.get('loggedAsName') && !this.persistenceService.get('adminCredentials')) {
                    this.persistenceService.remove('credentials');
                    this.persistenceService.remove('loggedAsName');
                    this.persistenceService.remove('adminCredentials');

                    const splittedHost = window.location.host.split('.');
                    splittedHost[0] = adminEnvironment.domain;

                    window.location.replace('//' + splittedHost.join('.') + '/');
                }
            }),
        );
    }, { dispatch: false });

    constructor(
        private actions$: Actions,
        private router: Router,
        private persistenceService: PersistenceService,
    ) {}
}
