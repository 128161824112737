<div class="modal-form notranslate">
    <div class="modal-content">
        <div class="modal-header">
            <div class="welcome-demo-dialog-picture">
                <mat-icon svgIcon="attrack-welcome-demo-dialog-picture" aria-hidden="false"></mat-icon>
            </div>
            <div class="modal-close" (click)="cancel()">
                <div class="material-icons">close</div>
            </div>
        </div>
        <div class="modal-body">
            <h4>
                {{ 'ACTIVITY.DEMO.WELCOME_DEMO_DIALOG.WELCOME_MESSAGE' | translate }}
            </h4>

            <hr>

            <ng-container *ngIf="(isAdmin$ | async) === true">
                <h2>
                        <span *ngIf="(onboardingDataStatus$ | async) === OnboardingDataStatusEnum.NOT_LOADED">
                            {{ 'ACTIVITY.DEMO.WELCOME_DEMO_DIALOG.LOAD_DEMO_VALUES' | translate }}
                        </span>
                            <span *ngIf="(onboardingDataStatus$ | async) !== OnboardingDataStatusEnum.NOT_LOADED">
                            {{ 'ACTIVITY.DEMO.WELCOME_DEMO_DIALOG.DEMO_VALUES_READY' | translate }}
                        </span>
                </h2>
                <div class="text">
                    {{ 'ACTIVITY.DEMO.WELCOME_DEMO_DIALOG.TEXT' | translate }}
                </div>
            </ng-container>

            <ng-container *ngIf="(isAdmin$ | async) === false">
                <h2>
                    <span>{{ 'ACTIVITY.DEMO.WELCOME_DEMO_DIALOG.NOT_ADMIN_START_ONBOARDING' | translate }}</span>
                </h2>
                <div class="text">
                    {{ 'ACTIVITY.DEMO.WELCOME_DEMO_DIALOG.NOT_ADMIN_TEXT' | translate }}
                </div>
            </ng-container>
        </div>
        <div class="modal-footer">
            <div class="actions">
                <button
                    ui-button
                    (click)="submit()"
                    size="large"
                    color="yellow"
                >
                    <ng-container *ngIf="(isAdmin$ | async) === true">
                        <span *ngIf="(onboardingDataStatus$ | async) === OnboardingDataStatusEnum.NOT_LOADED">
                            {{ 'ACTIVITY.DEMO.WELCOME_DEMO_DIALOG.START' | translate }}
                        </span>
                        <span *ngIf="(onboardingDataStatus$ | async) !== OnboardingDataStatusEnum.NOT_LOADED">
                            {{ 'ACTIVITY.DEMO.WELCOME_DEMO_DIALOG.CONTINUE' | translate }}
                        </span>
                    </ng-container>

                    <ng-container *ngIf="(isAdmin$ | async) === false">
                        <span>{{ 'ACTIVITY.DEMO.WELCOME_DEMO_DIALOG.CONTINUE' | translate }}</span>
                    </ng-container>
                </button>
                <button
                    ui-button
                    (click)="cancel()"
                    size="large"
                    color="white"
                >
                    {{ 'ACTIVITY.DEMO.WELCOME_DEMO_DIALOG.CANCEL' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
