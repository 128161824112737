import { AfterViewInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, filter, switchMap } from 'rxjs/operators';

import { ProjectListQueryParamsInterface } from '@app-client/project/types/project-list-query-params.interface';
import { ProjectInterface } from '@app-client/project/types/project.interface';
import { SelectorConstants } from '@app-client/shared/constants/selector.constants';
import { QueryArchivedEnum } from '@app-client/shared/types/query-archived.enum';
import { ProjectsService } from '@app-client/store/projects/services/projects.service';

import { MY_DEPARTMENT_ELEMENT } from '../users-selector/users-selector.component';


export type PROJECTS_SELECTOR_ALL_ELEMENT_TYPE = 'ALL';
export const PROJECTS_SELECTOR_ALL_ELEMENT: PROJECTS_SELECTOR_ALL_ELEMENT_TYPE = 'ALL';

@UntilDestroy()
@Component({
    selector: 'projects-selector',
    templateUrl: './projects-selector.component.html',
    styleUrls: ['./projects-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: ProjectsSelectorComponent
        }
    ],
})
export class ProjectsSelectorComponent implements ControlValueAccessor, AfterViewInit {
    readonly QueryArchivedEnum = QueryArchivedEnum;
    readonly PROJECTS_SELECTOR_ALL_ELEMENT = PROJECTS_SELECTOR_ALL_ELEMENT;

    @Input()
    isMultiple = true;
    @Input()
    emptyIsAll = false;
    @Input()
    set archived(archived: QueryArchivedEnum) {
        this.isAllowArchived = QueryArchivedEnum.INCLUDE === archived;
        this.listQueryParams$.next({
            ...this.listQueryParams$.value,
            archived,
            page: 1,
        });
    }
    @Input()
    set searchByUser(searchByUser: Array<string>) {
        let whereImHead: 0 | 1 = 0;
        searchByUser = searchByUser.filter((user) => {
            if (user === MY_DEPARTMENT_ELEMENT) {
                whereImHead = 1;
            }

            return !whereImHead;
        });

        this.listQueryParams$.next({
            ...this.listQueryParams$.value,
            searchByUser,
            whereImHead,
            page: 1,
        });
    }
    @Input()
    set includeProjectRoleOfCurrentUser(includeProjectRoleOfCurrentUser: boolean) {
        this.listQueryParams$.next({
            ...this.listQueryParams$.value,
            includeProjectRoleOfCurrentUser,
            page: 1,
        });
    }
    @Input()
    set projectSpace(projectSpace: Array<string>) {
        this.listQueryParams$.next({
            ...this.listQueryParams$.value,
            projectSpace,
            page: 1,
        });
    }
    @Input()
    set _excludeObserved(excludeObserved: boolean) {
        this.listQueryParams$.next({
            ...this.listQueryParams$.value,
            _excludeObserved: true === excludeObserved ? 1 : 0,
            page: 1,
        });
    }
    @Input()
    set showArchivedMembers(showArchivedMembers: QueryArchivedEnum) {
        this.listQueryParams$.next({
            ...this.listQueryParams$.value,
            showArchivedMembers,
            page: 1,
        });
    }
    @Input()
    set whereImManagerOrObserver(status: boolean) {
        this.listQueryParams$.next({
            ...this.listQueryParams$.value,
            whereImManagerOrObserver: status ? 1 : 0,
            page: 1,
        });
    }
    @Input()
    set whereImManager(status: boolean) {
        this.listQueryParams$.next({
            ...this.listQueryParams$.value,
            whereImManager: status ? 1 : 0,
            page: 1,
        });
    }

    private onChange: any;

    isDisabled = false;

    get selected(): Array<ProjectInterface | PROJECTS_SELECTOR_ALL_ELEMENT_TYPE> {
        return this.selectedStore;
    }
    set selected(value: Array<ProjectInterface | PROJECTS_SELECTOR_ALL_ELEMENT_TYPE>) {
        if (false === this.isMultiple) {
            this.search('');
        }
        this.selectedStore = value;
        this.onChange(value);
    }
    private selectedStore = [] as Array<ProjectInterface | PROJECTS_SELECTOR_ALL_ELEMENT_TYPE>;
    list: Array<ProjectInterface | PROJECTS_SELECTOR_ALL_ELEMENT_TYPE>;
    isHasMore = true;
    isLoading = false;
    listQueryParams$ = new BehaviorSubject<ProjectListQueryParamsInterface>({
        search: '',
        orderBy: 'name',
        orderDirection: 'ASC',
        page: 1,
        limit: 10,
    });
    isAllowArchived = false;

    constructor(
        private projectsService: ProjectsService,
        private changeDetectorRef: ChangeDetectorRef,
        private toastr: ToastrService,
        private translate: TranslateService,
    ) { }

    ngAfterViewInit(): void {
        this.listQueryParams$.pipe(
            untilDestroyed(this),
            debounceTime(SelectorConstants.DEBOUNCE_TIME),
            filter(() => {
                return false === this.isDisabled;
            }),
            switchMap((queryParams) => {
                if (1 === queryParams.page) {
                    this.list = true === this.emptyIsAll ? [PROJECTS_SELECTOR_ALL_ELEMENT] : [];
                }
                this.isLoading = true;
                return this.projectsService.getProjectsList(queryParams);
            }),
        ).subscribe((response) => {
            this.isHasMore = response.page < response.totalPages;
            this.list = [
                ...this.list,
                ...response.items,
            ];
            this.isLoading = false;
            this.changeDetectorRef.detectChanges();
        },
            (error) => {
                this.isHasMore = false;
                this.toastr.error(
                    error,
                    this.translate.instant('PROJECT_SPACES.NOTIFICATIONS.GET_CURRENT_FAILURE.TITLE'),
                );
                this.isLoading = false;
                this.changeDetectorRef.detectChanges();
            });
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void { }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    writeValue(obj: any): void {
        this.selectedStore = obj;
    }

    loadMore(): void {
        if (false === this.isHasMore) {
            return;
        }

        this.listQueryParams$.next({
            ...this.listQueryParams$.value,
            page: this.listQueryParams$.value.page + 1,
        });
    }

    toggleArchived(): void {
        this.listQueryParams$.next({
            ...this.listQueryParams$.value,
            archived: QueryArchivedEnum.INCLUDE === this.listQueryParams$.value.archived
                ? QueryArchivedEnum.EXCLUDE
                : QueryArchivedEnum.INCLUDE,
            page: 1,
        });
    }

    search(str: string): void {
        if (str !== this.listQueryParams$.value.search) {
            this.listQueryParams$.next({
                ...this.listQueryParams$.value,
                search: str,
                page: 1,
            });
        }
    }

    searchFn(): boolean {
        return true;
    }

    compareWith(a: any, b: any): boolean {
        return a?.id === b?.id;
    }
}

