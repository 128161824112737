import { createAction, props } from '@ngrx/store';

import { UsersActionType } from '@app-client/store/users/users-action-type';
import { CreateUserInterface } from '@app-client/user/types/create-user.interface';
import { UpdateUserInterface } from '@app-client/user/types/update-user.interface';
import { UserListQueryInterface } from '@app-client/user/types/user-list-query.interface';
import { UserInterface } from '@app-client/user/types/user.interface';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';
import { PageResponseInterface } from '@app-shared/types/page-response.interface';
import { UserEmployeesQueryParamsInterface } from '@app-client/user-profile/types/user-employees-list-query.interface';


export const setUsersQueryParamsAction = createAction(
    UsersActionType.SET_USERS_QUERY_PARAMS,
    props<{ queryParams: UserListQueryInterface }>(),
);

export const getUsersAction = createAction(
    UsersActionType.GET_USERS,
);
export const getUsersSuccessAction = createAction(
    UsersActionType.GET_USERS_SUCCESS,
    props<{ pageResponse: PageResponseInterface<UserInterface> }>(),
);
export const getUsersFailureAction = createAction(
    UsersActionType.GET_USERS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const getUserDetailsAction = createAction(
    UsersActionType.GET_USER_DETAILS,
    props<{ id: string }>(),
);
export const getUserDetailsSuccessAction = createAction(
    UsersActionType.GET_USER_DETAILS_SUCCESS,
    props<{ userData: UserInterface }>(),
);
export const getUserDetailsFailureAction = createAction(
    UsersActionType.GET_USER_DETAILS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const createUserAction = createAction(
    UsersActionType.CREATE_USER,
    props<{ userData: CreateUserInterface }>(),
);
export const createUserSuccessAction = createAction(
    UsersActionType.CREATE_USER_SUCCESS,
    props<{ newUser: UserInterface }>(),
);
export const createUserFailureAction = createAction(
    UsersActionType.CREATE_USER_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const updateUserAction = createAction(
    UsersActionType.UPDATE_USER,
    props<{ id: string, userData: UpdateUserInterface }>(),
);
export const updateUserSuccessAction = createAction(
    UsersActionType.UPDATE_USER_SUCCESS,
    props<{ updatedUser: UserInterface }>(),
);
export const updateUserFailureAction = createAction(
    UsersActionType.UPDATE_USER_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const requiresDeleteUserConfirmationAction = createAction(
    UsersActionType.GET_REQUIRES_DELETE_USER_CONFIRMATION,
    props<{ id: string }>(),
);

export const deleteUserAction = createAction(
    UsersActionType.DELETE_USER,
    props<{ id: string }>(),
);
export const deleteUserSuccessAction = createAction(
    UsersActionType.DELETE_USER_SUCCESS,
    props<{ id: string }>(),
);
export const deleteUserFailureAction = createAction(
    UsersActionType.DELETE_USER_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const usersGetTotalCountAction = createAction(
    UsersActionType.GET_TOTAL_COUNT,
);

export const usersGetTotalCountSuccessAction = createAction(
    UsersActionType.GET_TOTAL_COUNT_SUCCESS,
    props<{ count: number }>(),
);

export const usersGetTotalCountFailureAction = createAction(
    UsersActionType.GET_TOTAL_COUNT_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const usersDeactivateAction = createAction(
    UsersActionType.DEACTIVATE,
    props<{ id: string, isForce: boolean }>(),
);
export const usersDeactivateSuccessAction = createAction(
    UsersActionType.DEACTIVATE_SUCCESS,
    props<{ id: string }>(),
);
export const usersDeactivateFailureAction = createAction(
    UsersActionType.DEACTIVATE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface, isNeedForce: boolean }>(),
);
export const usersClearIsNeedForceDeactivate = createAction(
    UsersActionType.CLEAR_IS_NEED_FORCE_DEACTIVATE,
);

export const usersActivateAction = createAction(
    UsersActionType.ACTIVATE,
    props<{ id: string }>(),
);
export const usersActivateSuccessAction = createAction(
    UsersActionType.ACTIVATE_SUCCESS,
    props<{ id: string }>(),
);
export const usersActivateFailureAction = createAction(
    UsersActionType.ACTIVATE_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const getEmployeesAction = createAction(
    UsersActionType.GET_EMPLOYEES,
    props<{ params?: UserEmployeesQueryParamsInterface }>(),
);
export const getEmployeesSuccessAction = createAction(
    UsersActionType.GET_EMPLOYEES_SUCCESS,
    props<{ employees: Array<UserInterface> }>(),
);
export const getEmployeesFailureAction = createAction(
    UsersActionType.GET_EMPLOYEES_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
