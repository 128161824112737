import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { UIModule } from '@umbrella-web/attrack-ui-module';

import { DirectiveModule } from '@app-client/shared/modules/directive/directive.module';
import { CompleteDemoDialogComponent } from '@app-client/tour/components/complete-demo-dialog/complete-demo-dialog.component';
import { DeleteDemoDialogComponent } from '@app-client/tour/components/delete-demo-dialog/delete-demo-dialog.component';
import { RestartDemoDialogComponent } from '@app-client/tour/components/restart-demo-dialog/restart-demo-dialog.component';
import { SkippedDemoDialogComponent } from '@app-client/tour/components/skipped-demo-dialog/skipped-demo-dialog.component';
import { TourTooltipComponent } from '@app-client/tour/components/tour-tooltip/tour-tooltip.component';
import { WelcomeDemoDialogComponent } from '@app-client/tour/components/welcome-demo-dialog/welcome-demo-dialog.component';


@NgModule({
    declarations: [
        TourTooltipComponent,
        WelcomeDemoDialogComponent,
        RestartDemoDialogComponent,
        SkippedDemoDialogComponent,
        CompleteDemoDialogComponent,
        DeleteDemoDialogComponent,
    ],
    exports: [
        TourTooltipComponent,
        WelcomeDemoDialogComponent,
        RestartDemoDialogComponent,
        SkippedDemoDialogComponent,
        CompleteDemoDialogComponent,
        DeleteDemoDialogComponent,
    ],
    imports: [
        MatIconModule,
        UIModule,
        TranslateModule,
        CommonModule,
        DirectiveModule,
    ],
})
export class TourModule {}
