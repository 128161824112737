import { createReducer, on } from '@ngrx/store';

import {
    addTimeAction,
    addTimeFailureAction,
    addTimeSuccessAction,
    deleteTimeAction,
    deleteTimeFailureAction,
    deleteTimeSuccessAction,
    editTimeAction,
    editTimeFailureAction,
    editTimeSuccessAction,
} from '@app-client/store/activity/actions/time.action';
import { TimeStateInterface } from '@app-client/store/activity/time-state.interface';


const initialState: TimeStateInterface = {
    isAddTimeInProgress: false,
    isEditTimeInProgress: false,
    isDeleteTimeInProgress: false,
};

const reducer = createReducer<TimeStateInterface>(
    initialState,

    on(
        addTimeAction,
        state => ({
            ...state,
            isAddTimeInProgress: true,
        }),
    ),
    on(
        addTimeSuccessAction,
        state => ({
            ...state,
            isAddTimeInProgress: false,
        }),
    ),
    on(
        addTimeFailureAction,
        state => ({
            ...state,
            isAddTimeInProgress: false,
        }),
    ),

    on(
        editTimeAction,
        state => ({
            ...state,
            isEditTimeInProgress: true,
        }),
    ),
    on(
        editTimeSuccessAction,
        state => ({
            ...state,
            isEditTimeInProgress: false,
        }),
    ),
    on(
        editTimeFailureAction,
        state => ({
            ...state,
            isEditTimeInProgress: false,
        }),
    ),

    on(
        deleteTimeAction,
        state => ({
            ...state,
            isDeleteTimeInProgress: true,
        }),
    ),
    on(
        deleteTimeSuccessAction,
        state => ({
            ...state,
            isDeleteTimeInProgress: false,
        }),
    ),
    on(
        deleteTimeFailureAction,
        state => ({
            ...state,
            isDeleteTimeInProgress: false,
        }),
    ),
);

export const timeReducer = (state, action) => reducer(state, action);
