<nav>
    <div class="nav-wrapper container">
        <a class="logo-container" routerLink="/">
            <img alt="AtTrack" [src]="logoUrl$ | async" />
        </a>
        <div class="group">
            <a
                class="download-app"
                [href]="appDownloadUrl"
                target="_blank"
                joyrideStep="download_desktop_client"
                [stepContent]="tourTooltip"
            >
                <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7056 1.56348C11.7056 1.25228 11.4534 1 11.1422 1C10.831 1 10.5787 1.25228 10.5787 1.56348V11.5782L7.29038 8.65522C7.05778 8.44847 6.70162 8.46942 6.49487 8.70202C6.28812 8.93461 6.30907 9.29077 6.54167 9.49752L10.7678 13.254C10.9812 13.4438 11.303 13.4438 11.5165 13.254L15.7426 9.49752C15.9751 9.29077 15.9961 8.93461 15.7893 8.70202C15.5826 8.46942 15.2264 8.44847 14.9938 8.65522L11.7056 11.5781V1.56348Z" fill="white"/>
                    <path d="M10.5787 11.5782L10.3794 11.8024L10.8787 12.2462V11.5782H10.5787ZM7.29038 8.65522L7.09107 8.87945L7.29038 8.65522ZM6.49487 8.70202L6.27065 8.50271H6.27065L6.49487 8.70202ZM6.54167 9.49752L6.74098 9.2733H6.74098L6.54167 9.49752ZM10.7678 13.254L10.9671 13.0298V13.0298L10.7678 13.254ZM11.5165 13.254L11.7158 13.4783H11.7158L11.5165 13.254ZM15.7426 9.49752L15.5432 9.2733H15.5432L15.7426 9.49752ZM15.7893 8.70202L16.0136 8.50271L16.0136 8.50271L15.7893 8.70202ZM14.9938 8.65522L14.7945 8.431V8.431L14.9938 8.65522ZM11.7056 11.5781H11.4056V12.2461L11.9049 11.8023L11.7056 11.5781ZM11.1422 1.3C11.2877 1.3 11.4056 1.41796 11.4056 1.56348H12.0056C12.0056 1.08659 11.619 0.7 11.1422 0.7V1.3ZM10.8787 1.56348C10.8787 1.41796 10.9966 1.3 11.1422 1.3V0.7C10.6653 0.7 10.2787 1.08659 10.2787 1.56348H10.8787ZM10.8787 11.5782V1.56348H10.2787V11.5782H10.8787ZM7.09107 8.87945L10.3794 11.8024L10.778 11.3539L7.48969 8.431L7.09107 8.87945ZM6.7191 8.90133C6.81577 8.79257 6.98231 8.78277 7.09107 8.87945L7.48969 8.431C7.13326 8.11417 6.58748 8.14628 6.27065 8.50271L6.7191 8.90133ZM6.74098 9.2733C6.63222 9.17662 6.62242 9.01009 6.7191 8.90133L6.27065 8.50271C5.95383 8.85914 5.98593 9.40492 6.34236 9.72174L6.74098 9.2733ZM10.9671 13.0298L6.74098 9.2733L6.34236 9.72174L10.5684 13.4783L10.9671 13.0298ZM11.3172 13.0298C11.2173 13.1186 11.0669 13.1186 10.9671 13.0298L10.5684 13.4783C10.8956 13.7691 11.3886 13.7691 11.7158 13.4783L11.3172 13.0298ZM15.5432 9.2733L11.3172 13.0298L11.7158 13.4783L15.9419 9.72174L15.5432 9.2733ZM15.5651 8.90133C15.6618 9.01009 15.652 9.17662 15.5432 9.2733L15.9419 9.72174C16.2983 9.40492 16.3304 8.85914 16.0136 8.50271L15.5651 8.90133ZM15.1932 8.87945C15.3019 8.78277 15.4684 8.79257 15.5651 8.90133L16.0136 8.50271C15.6967 8.14628 15.151 8.11417 14.7945 8.431L15.1932 8.87945ZM11.9049 11.8023L15.1932 8.87945L14.7945 8.431L11.5063 11.3539L11.9049 11.8023ZM11.4056 1.56348V11.5781H12.0056V1.56348H11.4056Z" fill="white"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.56348 12.2695C1.87468 12.2695 2.12696 12.5218 2.12696 12.833V15.0869C2.12696 15.3981 2.37923 15.6504 2.69043 15.6504H19.5948C19.906 15.6504 20.1583 15.3981 20.1583 15.0869V12.833C20.1583 12.5218 20.4105 12.2695 20.7217 12.2695C21.0329 12.2695 21.2852 12.5218 21.2852 12.833V15.0869C21.2852 16.0205 20.5284 16.7774 19.5948 16.7774H2.69043C1.75683 16.7774 1 16.0205 1 15.0869V12.833C1 12.5218 1.25228 12.2695 1.56348 12.2695Z" fill="white" stroke="white" stroke-width="0.6" stroke-linecap="round"/>
                </svg>
                {{ 'HEADER.DOWNLOAD' | translate }}
            </a>

            <div class="user-avatar">
                <a mat-icon-button [matMenuTriggerFor]="avatarMenu">
                    <img [src]="(currentUser$ | async)?.avatar || '/assets/images/empty-avatar.svg'" alt="avatar" />
                </a>
                <mat-menu
                    #avatarMenu="matMenu"
                    xPosition="before"
                    yPosition="below"
                    class="user-avatar-menu wrap menu-wrap"
                >
                    <a mat-menu-item routerLink="/profile">
                        <span>{{ 'USER_HEADER_MENU.PROFILE' | translate }}</span>
                        <mat-icon svgIcon="attrack-profile" aria-hidden="false"></mat-icon>
                    </a>
                    <a mat-menu-item routerLink="/profile/employees" *ngIf="isHead$ | async">
                        <span>{{ 'USER_HEADER_MENU.EMPLOYEES' | translate }}</span>
                        <mat-icon svgIcon="attrack-employees" class="employees" aria-hidden="false"></mat-icon>
                    </a>
                    <a mat-menu-item *ngIf="(isStartDemo$ | async)"
                       (click)="startDemo()">
                        <span>{{ 'USER_HEADER_MENU.START_DEMO' | translate }}</span>
                        <mat-icon svgIcon="attrack-demo" aria-hidden="false"></mat-icon>
                    </a>
                    <a mat-menu-item *ngIf="(isStartDemo$ | async) === false"
                       (click)="restartDemo()">
                        <span>{{ 'USER_HEADER_MENU.RESTART_DEMO' | translate }}</span>
                        <mat-icon svgIcon="attrack-demo" aria-hidden="false"></mat-icon>
                    </a>
                    <a mat-menu-item *ngIf="(isAdmin$ | async) && (isOnboardingDataLoaded$ | async)"
                       (click)="deleteDemoData()">
                        <span>{{ 'USER_HEADER_MENU.DELETE_DEMO_DATA' | translate }}</span>
                        <mat-icon svgIcon="attrack-delete-demo-data" aria-hidden="false"></mat-icon>
                    </a>
                    <mat-divider></mat-divider>
                    <button mat-menu-item (click)="logout()">
                        <span>{{ 'USER_HEADER_MENU.LOGOUT' | translate }}</span>
                        <mat-icon svgIcon="attrack-logout" aria-hidden="false"></mat-icon>
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>
</nav>

<ng-template #tourTooltip>
    <app-tour-tooltip></app-tour-tooltip>
</ng-template>
