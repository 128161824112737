import { createReducer, on } from '@ngrx/store';

import {
    jiraAttachCloudApiTokenAction,
    jiraAttachCloudApiTokenFailureAction,
    jiraAttachCloudApiTokenSuccessAction,
    jiraAuthSelfHostedBasicAction,
    jiraAuthSelfHostedBasicFailureAction,
    jiraAuthSelfHostedBasicSuccessAction,
    jiraGetCloudOauth2RedirectUrlAction,
    jiraGetCloudOauth2RedirectUrlFailureAction,
    jiraGetCloudOauth2RedirectUrlSuccessAction,
    jiraGetConnectionsAction,
    jiraGetConnectionsFailureAction,
    jiraGetConnectionsSuccessAction,
    jiraGetSelfHostedOauth1RedirectUrlAction,
    jiraGetSelfHostedOauth1RedirectUrlFailureAction,
    jiraGetSelfHostedOauth1RedirectUrlSuccessAction, jiraLogoutAction, jiraLogoutFailureAction, jiraLogoutSuccessAction,
    jiraVerifyCloudOauth2Action,
    jiraVerifyCloudOauth2FailureAction,
    jiraVerifyCloudOauth2SuccessAction,
    jiraVerifySelfHostedOauth1Action,
    jiraVerifySelfHostedOauth1FailureAction,
    jiraVerifySelfHostedOauth1SuccessAction,
} from '@app-client/store/jira/actions/jira-auth.actions';
import { JiraAuthStateInterface } from '@app-client/store/jira/jira-auth-state.interface';


const initialState: JiraAuthStateInterface = {
    connections: [],
    isConnectionsLoading: false,
    isGetCloudOauth2RedirectUrlInProgress: false,
    isVerifyCloudOauth2InProgress: false,
    isAttachCloudApiTokenInProgress: false,
    isGetSelfHostedOauth1RedirectUrlInProgress: false,
    isVerifySelfHostedOauth1InProgress: false,
    isAuthSelfHostedBasicInProgress: false,
    isLogoutInProgress: false
};

const reducer = createReducer(
    initialState,
    on(
        jiraGetConnectionsAction,
        (state) => ({
            ...state,
            connections: [],
            isConnectionsLoading: true,
        }),
    ),

    on(
        jiraGetConnectionsSuccessAction,
        (state, action) => ({
            ...state,
            connections: action.connections,
            isConnectionsLoading: false,
        }),
    ),

    on(
        jiraGetConnectionsFailureAction,
        (state) => ({
            ...state,
            isConnectionsLoading: false,
        }),
    ),

    on(
        jiraGetCloudOauth2RedirectUrlAction,
        (state) => ({
            ...state,
            isGetCloudOauth2RedirectUrlInProgress: true,
        }),
    ),

    on(
        jiraGetCloudOauth2RedirectUrlSuccessAction,
        jiraGetCloudOauth2RedirectUrlFailureAction,
        (state) => ({
            ...state,
            isGetCloudOauth2RedirectUrlInProgress: false,
        }),
    ),

    on(
        jiraVerifyCloudOauth2Action,
        (state) => ({
            ...state,
            isVerifyCloudOauth2InProgress: true,
        }),
    ),

    on(
        jiraVerifyCloudOauth2SuccessAction,
        jiraVerifyCloudOauth2FailureAction,
        (state) => ({
            ...state,
            isVerifyCloudOauth2InProgress: false,
        }),
    ),

    on(
        jiraAttachCloudApiTokenAction,
        (state) => ({
            ...state,
            isAttachCloudApiTokenInProgress: true,
        }),
    ),

    on(
        jiraAttachCloudApiTokenSuccessAction,
        jiraAttachCloudApiTokenFailureAction,
        (state) => ({
            ...state,
            isAttachCloudApiTokenInProgress: false,
        }),
    ),

    on(
        jiraGetSelfHostedOauth1RedirectUrlAction,
        (state) => ({
            ...state,
            isGetSelfHostedOauth1RedirectUrlInProgress: true,
        }),
    ),

    on(
        jiraGetSelfHostedOauth1RedirectUrlSuccessAction,
        jiraGetSelfHostedOauth1RedirectUrlFailureAction,
        (state) => ({
            ...state,
            isGetSelfHostedOauth1RedirectUrlInProgress: false,
        }),
    ),

    on(
        jiraVerifySelfHostedOauth1Action,
        (state) => ({
            ...state,
            isVerifyCloudOauth2InProgress: true,
        }),
    ),

    on(
        jiraVerifySelfHostedOauth1SuccessAction,
        jiraVerifySelfHostedOauth1FailureAction,
        (state) => ({
            ...state,
            isVerifySelfHostedOauth1InProgress: false,
        }),
    ),

    on(
        jiraAuthSelfHostedBasicAction,
        (state) => ({
            ...state,
            isAuthSelfHostedBasicInProgress: true,
        }),
    ),

    on(
        jiraAuthSelfHostedBasicSuccessAction,
        jiraAuthSelfHostedBasicFailureAction,
        (state) => ({
            ...state,
            isAuthSelfHostedBasicInProgress: false,
        })
    ),

    on(
        jiraLogoutAction,
        (state) => ({
            ...state,
            isLogoutInProgress: true,
        }),
    ),

    on(
        jiraLogoutSuccessAction,
        (state, action) => ({
            ...state,
            connections: state.connections.filter((connection) => {
                return connection.id !== action.connectionId;
            }),
            isLogoutInProgress: false,
        }),
    ),

    on(
        jiraLogoutFailureAction,
        (state) => ({
            ...state,
            isLogoutInProgress: false,
        }),
    ),
);

export const jiraAuthReducer = (state, action) => reducer(state, action);
