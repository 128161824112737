import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AddTimeRequestInterface } from '@app-client/store/activity/types/time-modify/add-time-request.interface';
import { DeleteTimeRequestInterface } from '@app-client/store/activity/types/time-modify/delete-time-request.interface';
import { EditTimeRequestInterface } from '@app-client/store/activity/types/time-modify/edit-time-request.interface';
import { mapToVoid } from '@app-shared/utils/rxjs/map-to-void';

import { environment } from '@env-client/environment';


@Injectable()
export class TimeService {
    constructor(private httpClient: HttpClient) {}

    addTime({ ranges, taskId, memo, userId }: AddTimeRequestInterface): Observable<void> {
        const url = environment.apiBaseUrl + '/trk/time-requests';

        return this.httpClient.post(url, {
            ranges: ranges.map(({ from, to }) => {
                return {
                    from: Math.floor(from.toDate().getTime() / 1000),
                    to: Math.floor(to.toDate().getTime() / 1000),
                };
            }),
            task: taskId,
            memo,
            user: userId,
        }).pipe(mapToVoid);
    }

    editTime({ ranges, memo, projectId, taskId, userId }: EditTimeRequestInterface): Observable<void> {
        const url = environment.apiBaseUrl + '/trk/time/batch/update';

        return this.httpClient.post(url, {
            ranges: ranges.map(({ from, to }) => {
                return {
                    from: Math.floor(from.toDate().getTime() / 1000),
                    to: Math.floor(to.toDate().getTime() / 1000),
                };
            }),
            projectId,
            taskId,
            memo: memo || undefined,
            userId,
        }).pipe(mapToVoid);
    }

    deleteTime({ ranges, userId }: DeleteTimeRequestInterface): Observable<void> {
        const url = environment.apiBaseUrl + '/trk/time/batch/delete';

        return this.httpClient.post(url, {
            ranges: ranges.map(({ from, to }) => {
                return {
                    from: Math.floor(from.toDate().getTime() / 1000),
                    to: Math.floor(to.toDate().getTime() / 1000),
                };
            }),
            userId,
        }).pipe(mapToVoid);
    }
}
