import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ProjectsStateInterface } from '@app-client/store/projects/projects-state.interface';


export const projectsFeatureSelector = createFeatureSelector<ProjectsStateInterface>('projects');

export const projectListQueryParamsSelector = createSelector(
    projectsFeatureSelector,
    state => state.listQueryParams,
);

export const projectListSelector = createSelector(
    projectsFeatureSelector,
    state => state.projectList,
);

export const isProjectListLoadingSelector = createSelector(
    projectsFeatureSelector,
    state => state.isProjectListLoading,
);

export const hasMoreProjectSelector = createSelector(
    projectsFeatureSelector,
    state => state.hasMoreProjects,
);

export const projectListForEditTimeQueryParamsSelector = createSelector(
    projectsFeatureSelector,
    state => state.listQueryParamsForEditTime,
);

export const projectListForEditTimeSelector = createSelector(
    projectsFeatureSelector,
    state => state.projectListForEditTime,
);

export const isProjectListForEditTimeLoadingSelector = createSelector(
    projectsFeatureSelector,
    state => state.isProjectListForEditTimeLoading,
);

export const hasMoreProjectsForEditTimeSelector = createSelector(
    projectsFeatureSelector,
    state => state.hasMoreProjectsForEditTime,
);

export const isProjectDetailsLoadingSelector = createSelector(
    projectsFeatureSelector,
    state => state.isProjectDetailsLoading,
);

export const isUpdateProjectInProgressSelector = createSelector(
    projectsFeatureSelector,
    state => state.isUpdateProjectInProgress,
);

export const updateProjectBackendErrorsSelector = createSelector(
    projectsFeatureSelector,
    state => state.updateProjectBackendErrors,
);

export const isCreateProjectInProgressSelector = createSelector(
    projectsFeatureSelector,
    state => state.isCreateProjectInProgress,
);

export const createProjectBackendErrorsSelector = createSelector(
    projectsFeatureSelector,
    state => state.createProjectBackendErrors,
);

export const isArchiveProjectInProgressSelector = createSelector(
    projectsFeatureSelector,
    state => state.isArchiveProjectInProgress,
);

export const isRestoreProjectInProgressSelector = createSelector(
    projectsFeatureSelector,
    state => state.isRestoreProjectInProgress,
);

export const projectDetailsSelector = createSelector(
    projectsFeatureSelector,
    state => state.projectDetails,
);
