import { createReducer, on } from '@ngrx/store';

import {
    createUserFromUserRequestAction,
    createUserFromUserRequestFailureAction,
    createUserFromUserRequestSuccessAction,
    deleteUserRequestAction,
    deleteUserRequestFailureAction,
    deleteUserRequestSuccessAction,
    getUserRequestListAction,
    getUserRequestListFailureAction,
    getUserRequestListSuccessAction,
    linkUserRequestAction,
    linkUserRequestFailureAction, linkUserRequestSuccessAction, setUserRequestListQueryParamsAction,
} from '@app-client/store/users/actions/user-request.action';
import { UserRequestStateInterface } from '@app-client/store/users/user-request-state.interface';


const initialState: UserRequestStateInterface = {
    isUserRequestListLoading: false,
    userRequestList: [],
    userRequestQueryConfig: null,
    userRequestListHasLoadingMore: false,
    isLinkUserRequestInProgress: false,
    isDeleteUserRequestInProgress: false,
    isCreateUserFromUserRequestInProgress: false,
};

const reducer = createReducer(
    initialState,
    on(
        getUserRequestListAction,
        state => ({
            ...state,
            isUserRequestListLoading: true,
            userRequestList: (state.userRequestQueryConfig?.page === 1) ? [] : state.userRequestList,
            userRequestListHasLoadingMore: true,
        })
    ),
    on(
        getUserRequestListSuccessAction,
        (state, action) => ({
            ...state,
            isUserRequestListLoading: false,
            userRequestList: [...state.userRequestList, ...action.response.items],
            userRequestListHasLoadingMore: action.response.page < action.response.totalPages,
        })
    ),
    on(
        getUserRequestListFailureAction,
        (state) => ({
            ...state,
            isUserRequestListLoading: false,
            userRequestListHasLoadingMore: false,
        })
    ),
    on(
        setUserRequestListQueryParamsAction,
        (state, actions) => ({
            ...state,
            userRequestQueryConfig: actions.queryParams,
        })
    ),
    on(
        linkUserRequestAction,
        (state) => ({
            ...state,
            isLinkUserRequestInProgress: true,
        })
    ),
    on(
        linkUserRequestFailureAction,
        (state) => ({
            ...state,
            isLinkUserRequestInProgress: false,
        })
    ),
    on(
        linkUserRequestSuccessAction,
        (state) => ({
            ...state,
            isLinkUserRequestInProgress: false,
        })
    ),
    on(
        deleteUserRequestAction,
        state => ({
            ...state,
            isDeleteUserRequestInProgress: true,
        })
    ),
    on(
        deleteUserRequestSuccessAction,
        state => ({
            ...state,
            isDeleteUserRequestInProgress: false,
        })
    ),
    on(
        deleteUserRequestFailureAction,
        state => ({
            ...state,
            isDeleteUserRequestInProgress: false,
        })
    ),
    on(
        createUserFromUserRequestAction,
        state => ({
            ...state,
            isCreateUserFromUserRequestInProgress: true,
        })
    ),
    on(
        createUserFromUserRequestSuccessAction,
        state => ({
            ...state,
            isCreateUserFromUserRequestInProgress: false,
        })
    ),
    on(
        createUserFromUserRequestFailureAction,
        state => ({
            ...state,
            isCreateUserFromUserRequestInProgress: false,
        })
    )
);

export const userRequestReducer = (state, action) => reducer(state, action);
