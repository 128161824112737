import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import {
    getCompanyConfigSignatureAction,
    getCompanyConfigSignatureFailureAction,
    getCompanyConfigSignatureSuccessAction,
    updateCompanyConfigSignatureAction,
    updateCompanyConfigSignatureFailureAction,
    updateCompanyConfigSignatureSuccessAction
} from '@app-client/store/company/actions/company-config-signature.action';
import { CompanyConfigService } from '@app-client/store/company/services/company-config.service';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


@Injectable()
export class CompanyConfigSignatureEffect {
    constructor(
        private actions: Actions,
        private companyConfigService: CompanyConfigService,
        private toastr: ToastrService,
        private translate: TranslateService,
    ) {}

    getCompanyConfigSignatureAction$ = createEffect(() => {
        return this.actions.pipe(
            ofType(getCompanyConfigSignatureAction),
            switchMap(() => {
                return this.companyConfigService.getCompanyConfigSignature().pipe(
                    map((companyConfigSignature) => {
                        return getCompanyConfigSignatureSuccessAction({ companyConfigSignature } );
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(getCompanyConfigSignatureFailureAction({ backendErrors }));
                    })
                );
            })
        );
    });

    getCompanyConfigSignatureFailureAction$ = createEffect(() => {
        return this.actions.pipe(
            ofType(getCompanyConfigSignatureFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('COMPANY_SETTINGS.COMPANY.COMPANY_DATA.NOTIFICATIONS.GET_COMPANY_DATA_FAIL.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    updateCompanyConfigSignatureAction$ = createEffect(() => {
        return this.actions.pipe(
            ofType(updateCompanyConfigSignatureAction),
            switchMap(({ companyConfigSignature }) => {
                return this.companyConfigService.updateCompanyConfigSignature(companyConfigSignature).pipe(
                    map(() => {
                        return updateCompanyConfigSignatureSuccessAction();
                    }),
                    catchError((httpErrorResponse: HttpErrorResponse) => {
                        const backendErrors: BackendErrorsInterface = {
                            message: httpErrorResponse?.error?.message,
                            errors: httpErrorResponse?.error?.errors,
                        };
                        return of(updateCompanyConfigSignatureFailureAction({ backendErrors }));
                    })
                );
            })
        );
    });

    updateCompanyConfigSignatureSuccessAction$ = createEffect(() => {
        return this.actions.pipe(
            ofType(updateCompanyConfigSignatureSuccessAction),
            tap(() => {
                this.toastr.success(
                    this.translate.instant('COMPANY_CONFIG.NOTIFICATIONS.SUCCESS.MESSAGE'),
                    this.translate.instant('COMPANY_CONFIG.NOTIFICATIONS.SUCCESS.TITLE'),
                );
            }),
        );
    }, { dispatch: false });

    updateCompanyConfigSignatureFailureAction$ = createEffect(() => {
        return this.actions.pipe(
            ofType(updateCompanyConfigSignatureFailureAction),
            tap(({ backendErrors }) => {
                this.toastr.error(
                    backendErrors.message,
                    this.translate.instant('COMPANY_SETTINGS.COMPANY.COMPANY_DATA.NOTIFICATIONS.GET_COMPANY_DATA_FAIL.TITLE'),
                );
            }),
        );
    }, { dispatch: false });
}
