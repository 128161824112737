import { createAction, props } from '@ngrx/store';

import { CompanyConfigActionEnum } from '@app-client/store/company/company-config-action.enum';
import { CompanyConfigScreenshotsInterface } from '@app-client/store/company/company-config.interface';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';

export const getCompanyConfigScreenshotsAction = createAction(
    CompanyConfigActionEnum.GET_COMPANY_CONFIG_SCREENSHOTS,
);

export const getCompanyConfigScreenshotsSuccessAction = createAction(
    CompanyConfigActionEnum.GET_COMPANY_SCREENSHOTS_SUCCESS,
    props<{ companyConfigScreenshots: CompanyConfigScreenshotsInterface }>(),
);

export const getCompanyConfigScreenshotsFailureAction = createAction(
    CompanyConfigActionEnum.GET_COMPANY_SCREENSHOTS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const updateCompanyConfigScreenshotsAction = createAction(
    CompanyConfigActionEnum.UPDATE_COMPANY_CONFIG_SCREENSHOTS,
    props<{ companyConfigScreenshots: CompanyConfigScreenshotsInterface }>(),
);

export const updateCompanyConfigScreenshotsSuccessAction = createAction(
    CompanyConfigActionEnum.UPDATE_COMPANY_SCREENSHOTS_SUCCESS
);

export const updateCompanyConfigScreenshotsFailureAction = createAction(
    CompanyConfigActionEnum.UPDATE_COMPANY_SCREENSHOTS_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);
