import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, withLatestFrom } from 'rxjs/operators';

import { currentUserSelector } from '@app-admin/store/auth/selectors/auth.selectors';
import { OnboardingDataStatusEnum } from '@app-client/shared/types/onboarding-data-status.enum';
import { OnboardingStatusEnum } from '@app-client/shared/types/onboarding-status.enum';
import { currentUserIsAdminSelector } from '@app-client/store/auth/selectors/auth.selectors';
import {
    loadCompanyOnboardingDataAction,
    openRestartDialogAction,
    sendOnboardingStatusStartedAction,
    startTourSectionAction,
} from '@app-client/store/tour/actions/tour.action';
import { onboardingStatusSelector } from '@app-client/store/tour/selectors/tour.selector';
import { TourService } from '@app-client/tour/services/tour.service';
import { TourSectionsEnum } from '@app-client/tour/types/tour-sections.enum';
import { collapseAnimation } from '@app-shared/animations/collapse.animation';


export interface WelcomeDemoDialogData {
    returnToRestartDemoDialog: boolean;
}

@UntilDestroy()
@Component({
    selector: 'app-welcome-demo-dialog',
    templateUrl: './welcome-demo-dialog.component.html',
    styleUrls: ['./welcome-demo-dialog.component.scss'],
    animations: [
        collapseAnimation,
    ],
})
export class WelcomeDemoDialogComponent implements OnInit {
    readonly OnboardingDataStatusEnum = OnboardingDataStatusEnum;
    onboardingDataStatus$: Observable<OnboardingDataStatusEnum>;
    isAdmin$: Observable<boolean>;

    constructor(
        public dialogRef: MatDialogRef<WelcomeDemoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: WelcomeDemoDialogData,
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private store: Store,
        private tour: TourService,
    ) {
        this.iconRegistry.addSvgIcon(
            'attrack-welcome-demo-dialog-picture',
            this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/welcome-demo-dialog-picture.svg'));
    }

    private initializeVariables() {
        this.onboardingDataStatus$ = this.store.select(onboardingStatusSelector);
        this.isAdmin$ = this.store.select(currentUserIsAdminSelector);
    }

    ngOnInit() {
        this.initializeVariables();
    }

    cancel(): void {
        this.tour.markAllSectionsAsCompleted();
        this.dialogRef.close(false);

        if (this.data?.returnToRestartDemoDialog) {
            this.store.dispatch(openRestartDialogAction());
        }
    }

    submit(): void {
        this.store.select(currentUserSelector)
            .pipe(
                take(1),
                withLatestFrom(
                    this.store.select(currentUserIsAdminSelector),
                    this.store.select(onboardingStatusSelector),
                ),
            )
            .subscribe(([currentUser, isAdmin, onboardingDataStatus]) => {
                if (isAdmin && onboardingDataStatus === OnboardingDataStatusEnum.NOT_LOADED) {
                    this.store.dispatch(loadCompanyOnboardingDataAction());
                    this.tour.setupOnboardingDataCheckTimer();
                }

                if (currentUser.onboardingStatus !== OnboardingStatusEnum.IN_PROGRESS) {
                    this.store.dispatch(sendOnboardingStatusStartedAction());
                }

                this.tour.clearCompletedSections();
                this.store.dispatch(startTourSectionAction({ section: TourSectionsEnum.OVERVIEW, showCompleteDialog: true }));
                this.dialogRef.close(false);
            });
    }

}
