import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EffectivityTemplateCreateInterface } from '@app-client/effectivity/types/template/effectivity-template-create.interface';
import { EffectivityTemplateDetailsInterface } from '@app-client/effectivity/types/template/effectivity-template-details.interface';
import { EffectivityTemplateListQueryParamsInterface } from '@app-client/effectivity/types/template/effectivity-template-list-query-params.interface';
import { EffectivityTemplateUpdateInterface } from '@app-client/effectivity/types/template/effectivity-template-update.interface';
import { EffectivityTemplateInterface } from '@app-client/effectivity/types/template/effectivity-template.interface';
import { PageResponseInterface } from '@app-shared/types/page-response.interface';
import { ResponseInterface } from '@app-shared/types/response.interface';

import { environment } from '@env-client/environment';


@Injectable()
export class EffectivityTemplateService {
    constructor(
        private http: HttpClient,
    ) {}

    getEffectivityTemplateList(queryParams: EffectivityTemplateListQueryParamsInterface)
        : Observable<PageResponseInterface<EffectivityTemplateInterface>> {
        let params = new HttpParams()
            .set('orderBy', queryParams.orderBy)
            .set('orderDirection', queryParams.orderDirection)
            .set('page', queryParams.page.toString())
            .set('limit', (queryParams.limit || 10).toString())
            .set('whereImAuthor', queryParams.whereImAuthor ? '1' : '0');
        if (!!queryParams.search) {
            params = params.set('search', queryParams.search);
        }
        return this.http.get<ResponseInterface<PageResponseInterface<EffectivityTemplateInterface>>>(
        environment.apiBaseUrl + '/effectivity/templates', { params }
        ).pipe(
            map(x => {
                return x.body;
            }),
        );
    }

    getEffectivityTemplate(id: string)
        : Observable<ResponseInterface<EffectivityTemplateDetailsInterface>> {
        return this.http.get<ResponseInterface<EffectivityTemplateDetailsInterface>>(
            environment.apiBaseUrl + '/effectivity/templates/' + id
        );
    }

    createEffectivityTemplate(effectivityTemplate: EffectivityTemplateCreateInterface)
        : Observable<ResponseInterface<EffectivityTemplateDetailsInterface>> {
        return this.http.post<ResponseInterface<EffectivityTemplateDetailsInterface>>(
            environment.apiBaseUrl + '/effectivity/templates', effectivityTemplate
        );
    }

    updateEffectivityTemplate(id: string, effectivityTemplate: EffectivityTemplateUpdateInterface)
        : Observable<ResponseInterface<EffectivityTemplateDetailsInterface>> {
        return this.http.patch<ResponseInterface<EffectivityTemplateDetailsInterface>>(
            environment.apiBaseUrl + '/effectivity/templates/' + id, effectivityTemplate
        );
    }

    deleteEffectivityTemplate(id: string): Observable<ResponseInterface<null>> {
        return this.http.delete<ResponseInterface<null>>(environment.apiBaseUrl + '/effectivity/templates/' + id);
    }
}
