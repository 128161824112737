import { ActionReducer, createReducer, on } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

import { logoutAction } from '@app-client/store/auth/actions/login.action';
import {
    clearReportDataAction,
    getReportAction,
    getReportFailureAction,
    getReportSuccessAction,
    requestGoogleSpreadsheetReportAction,
    requestGoogleSpreadsheetReportFailureAction,
    requestGoogleSpreadsheetReportSuccessAction,
    saveReportFiltersAction, setReportAdditionalFiltersAction,
} from '@app-client/store/report/by-project/actions/report.action';
import { ReportStateInterface } from '@app-client/store/report/by-project/report-state.interface';


const initialState: ReportStateInterface = {
    report: null,
    isGetReportInProgress: false,
    savedFilters: null,
    additionalFilters: null,
};

const reducer = createReducer(
    initialState,
    on(
        getReportAction,
        state => ({
            ...state,
            isGetReportInProgress: true,
            report: null,
        }),
    ),
    on(
        getReportSuccessAction,
        (state, action) => ({
            ...state,
            isGetReportInProgress: false,
            report: action.report,
        }),
    ),
    on(
        getReportFailureAction,
        (state) => ({
            ...state,
            isGetReportInProgress: false,
        }),
    ),
    on(
        requestGoogleSpreadsheetReportAction,
        state => ({
            ...state,
            isGetReportInProgress: true,
        }),
    ),
    on(
        requestGoogleSpreadsheetReportSuccessAction,
        state => ({
            ...state,
            isGetReportInProgress: false,
        }),
    ),
    on(
        requestGoogleSpreadsheetReportFailureAction,
        (state) => ({
            ...state,
            isGetReportInProgress: false,
        }),
    ),
    on(
        saveReportFiltersAction,
        (state, action) => ({
            ...state,
            savedFilters: action.filters,
        }),
    ),
    on(
        clearReportDataAction,
        (state) => ({
            ...state,
            savedFilters: null,
            report: null,
        }),
    ),
    on(
        setReportAdditionalFiltersAction,
        (state, action) => ({
            ...state,
            additionalFilters: action.additionalFilters,
        }),
    ),
    on(
        logoutAction,
        (state) => ({
            ...state,
            additionalFilters: null,
        }),
    ),
);

export const reportReducer = (state, action) => reducer(state, action);
export const reportLocalStorageSyncReducer = (r: ActionReducer<any>): ActionReducer<any> => {
    return localStorageSync({
        keys: [
            {
                report: [
                    'additionalFilters',
                ],
            },
        ],
        rehydrate: true,
    })(r);
};

