import { createAction, props } from '@ngrx/store';

import { AccessRequestInterface } from '@app-client/store/auth/access-request.interface';
import { ActionTypes } from '@app-client/store/auth/action-types';
import { BackendErrorsInterface } from '@app-shared/types/backend-errors.interface';


export const accessRequestSend = createAction(
    ActionTypes.ACCESS_REQUEST_SEND,
    props<{ accessRequestData: AccessRequestInterface }>(),
);

export const accessRequestSendSuccess = createAction(
    ActionTypes.ACCESS_REQUEST_SEND_SUCCESS,
);

export const accessRequestSendFailure = createAction(
    ActionTypes.ACCESS_REQUEST_SEND_FAILURE,
    props<{ backendErrors: BackendErrorsInterface }>(),
);

export const clearAccessRequestSend = createAction(
    ActionTypes.CLEAR_ACCESS_REQUEST_SEND,
);
