<div class="modal-form notranslate">
    <div class="modal-content">
        <div class="modal-header">
            <div class="welcome-demo-dialog-picture">
                <mat-icon svgIcon="attrack-complete-demo-dialog-picture" aria-hidden="false"></mat-icon>
            </div>
            <div class="modal-close" (click)="close()">
                <div class="material-icons">close</div>
            </div>
        </div>
        <div class="modal-body">
            <h4>
                {{ 'ACTIVITY.DEMO.COMPLETE_DEMO_DIALOG.HEADER' | translate }}
            </h4>

            <div class="text">
                <b>{{ 'ACTIVITY.DEMO.COMPLETE_DEMO_DIALOG.TEXT' | translate }}</b>
            </div>
        </div>
        <div *ngIf="(isAdmin$ | async) === true"
             class="modal-footer">
            <div class="actions">
                <button
                    ui-button
                    (click)="close()"
                    [disabled]="(onboardingDataStatus$ | async) === OnboardingDataStatus.LOADING"
                    size="large"
                    color="yellow"
                >
                    <span *ngIf="(onboardingDataStatus$ | async) === OnboardingDataStatus.LOADING">
                        {{ 'ACTIVITY.DEMO.PLEASE_WAIT_DATA_IS_LOADING' | translate }}
                    </span>

                    <span *ngIf="(onboardingDataStatus$ | async) === OnboardingDataStatus.LOADED
                                || (onboardingDataStatus$ | async) === OnboardingDataStatus.NOT_LOADED">
                        {{ 'ACTIVITY.DEMO.COMPLETE_DEMO_DIALOG.CLOSE_BUTTON' | translate }}
                    </span>
                </button>
            </div>
        </div>
    </div>
</div>
