export enum ReportWorkDayActionTypes {
    GET_REPORT = '[Report work day] Get report',
    GET_REPORT_SUCCESS = '[Report work day] Get report success',
    GET_REPORT_FAILURE = '[Report work day] Get report failure',

    EXPORT_GOOGLE_SPREADSHEET = '[Report work day] Export google spreadsheet',
    EXPORT_GOOGLE_SPREADSHEET_SUCCESS = '[Report work day] Export google spreadsheet success',
    EXPORT_GOOGLE_SPREADSHEET_FAILURE = '[Report work day] Export google spreadsheet failure',

    CLEAR_DATA = '[Report work day] Clear date',
}
