export enum MarketplaceModulesActionsEnum {
    GET_LIST = '[MarketplaceModules] Get list',
    GET_LIST_SUCCESS = '[MarketplaceModules] Get list success',
    GET_LIST_FAILURE = '[MarketplaceModules] Get list failure',

    GET_DETAILS = '[MarketplaceModules] Get details',
    GET_DETAILS_SUCCESS = '[MarketplaceModules] Get details success',
    GET_DETAILS_FAILURE = '[MarketplaceModules] Get details failure',

    GET_ACTIVATED_USERS = '[MarketplaceModules] Get activated users',
    GET_ACTIVATED_USERS_SUCCESS = '[MarketplaceModules] Get activated users success',
    GET_ACTIVATED_USERS_FAILURE = '[MarketplaceModules] Get activated users failure',

    INSTALL = '[MarketplaceModules] Install',
    INSTALL_SUCCESS = '[MarketplaceModules] Install success',
    INSTALL_FAILURE = '[MarketplaceModules] Install failure',

    UNINSTALL = '[MarketplaceModules] Uninstall',
    UNINSTALL_SUCCESS = '[MarketplaceModules] Uninstall success',
    UNINSTALL_FAILURE = '[MarketplaceModules] Uninstall failure',

    UPDATE_TARIFF = '[MarketplaceModules] Update tariff',
    UPDATE_TARIFF_SUCCESS = '[MarketplaceModules] Update tariff success',
    UPDATE_PLAN_FAILURE = '[MarketplaceModules] Update tariff failure',

    UPDATE_SETTINGS = '[MarketplaceModules] Update settings',
    UPDATE_SETTINGS_SUCCESS = '[MarketplaceModules] Update settings success',
    UPDATE_SETTINGS_FAILURE = '[MarketplaceModules] Update settings failure',

    GET_ENABLED = '[MarketplaceModules] Get enabled',
    GET_ENABLED_SUCCESS = '[MarketplaceModules] Get enabled success',
    GET_ENABLED_FAILURE = '[MarketplaceModules] Get enabled failure',

    GET_FREE_TARIFFS = '[MarketplaceModules] Get free tariffs',
    GET_FREE_TARIFFS_SUCCESS = '[MarketplaceModules] Get free tariffs success',
    GET_FREE_TARIFFS_FAILURE = '[MarketplaceModules] Get free tariffs failure',
}
