import { createFeatureSelector, createSelector } from '@ngrx/store';

import { LoggedAsStateInterface } from '@app-client/store/logged-as/logged-as-state.interface';


export const loggedAsFeatureSelector = createFeatureSelector<LoggedAsStateInterface>('loggedAs');

export const loggedAsIsVisibleSelector = createSelector(
    loggedAsFeatureSelector,
    state => state.isVisible,
);
export const loggedAsIsExpandedSelector = createSelector(
    loggedAsFeatureSelector,
    state => state.isExpanded,
);
export const loggedAsLoggedAsNameSelector = createSelector(
    loggedAsFeatureSelector,
    state => state.loggedAsName,
);
export const loggedAsPreviousCredentialsSelector = createSelector(
    loggedAsFeatureSelector,
    state => state.previousAdminCredentials,
);
